import { Component, Input, OnInit, signal } from '@angular/core';
import { Trust } from "../../../../models/trust";
import { IStep } from "../../../../models/step";
import { ButtonComponent } from "../../../components/common/button/button.component";
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  FormRecord,
  ReactiveFormsModule,
  ValidatorFn,
  Validators
} from "@angular/forms";
import { StepperFormComponent } from "../../../modals/stepper-form/stepper-form.component";
import { InputComponent } from "../../../components/common/input/input.component";
import { TextareaComponent } from "../../../components/common/textarea/textarea.component";
import { CustomFormValidators } from "../../../../custom-form-validators/custom-form-validators";
import { setControlDisabled } from "../../../../functions/set-control-disabled";
import {
  RelationshipFormGroup,
  RelationshipFormGroupComponent
} from "../../../components/reusable-form-groups/relationship-form-group/relationship-form-group.component";
import { Relationship } from "../../../../models/relationship";
import {
  CorporateRelationshipFormGroup,
  CorporateRelationshipFormGroupComponent
} from "../../../components/reusable-form-groups/corporate-relationship-form-group/corporate-relationship-form-group.component";
import { Address } from "../../../../models/address";
import { clearFormArray } from "../../../../functions/clear-form-array";
import { DividerComponent } from "../../../components/common/divider/divider.component";
import { SelectComponent } from "../../../components/common/select/select.component";
import { InputNumberComponent } from "../../../components/common/input-number/input-number.component";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ValidationErrorComponent } from "../../../components/common/validation-error/validation-error.component";
import { KeyValuePipe } from "@angular/common";
import { RadioComponent } from "../../../components/common/radio/radio.component";
import { SelectOption } from "../../../../models/selectOptions";
import {
  AgActionIconButtonComponent
} from "../../../components/common/grid/components/ag-action-icon-button/ag-action-icon-button.component";
import { startWith } from "rxjs";
import {
  BaseStepperFormComponent
} from "../../../modals/stepper-form/base-stepper-component/base-stepper-form.component";
import { Document } from "../../../../models/document";
import { IndividualCorporateRelationship, } from "../../../../models/trust-change-relationship";
import { Guid } from "../../../helpers/guid.helper";
import { EntityChangeData } from "../../../../models/entityChangeData";
import {
  DisctributionMeasurement,
  disctributionMeasurementSelectOptions,
  DistributionModel,
  DistributionType,
  distributionTypeSelectOptions,
  getFinancialYearEndingOptions,
  TrustDistribution,
  TrustDistributionStepsEnum
} from "./form-distribution-resolution-or-minutes.model";

export type TrustDistributionFormGroup = FormGroup<{
  distributionType: FormControl<DistributionType | null>;
  disctributionMeasurement: FormControl<DisctributionMeasurement | null>;
  beneficiaries: FormRecord<FormControl<number | null>>; // key - id, value - quantity
}>

@Component({
  selector: 'app-form-trust-distribution',
  standalone: true,
  imports: [
    ButtonComponent,
    RadioComponent,
    ReactiveFormsModule,
    StepperFormComponent,
    InputComponent,
    TextareaComponent,
    CorporateRelationshipFormGroupComponent,
    DividerComponent,
    RelationshipFormGroupComponent,
    SelectComponent,
    InputNumberComponent,
    ValidationErrorComponent,
    KeyValuePipe,
    AgActionIconButtonComponent,
  ],
  templateUrl: './form-trust-distribution.component.html',
  styleUrls: ['./../../../modals/stepper-form/base-stepper-component/base-stepper-form.component.scss']
})
export class FormTrustDistributionComponent extends BaseStepperFormComponent<TrustDistributionStepsEnum, TrustDistribution> implements OnInit {
  @Input() trustProfile!: Trust;

  readonly IndividualCorporateTrustee = IndividualCorporateRelationship;
  readonly financialYearEndingOptions = getFinancialYearEndingOptions(new Date().getFullYear());
  readonly distributionTypeSelectOptions = distributionTypeSelectOptions;
  readonly disctributionMeasurementSelectOptions = disctributionMeasurementSelectOptions;

  stepsSignal = signal<IStep<TrustDistributionStepsEnum>[]>([]);
  names: Record<string, string> = {};
  filteredDistributionTypes!: Record<DistributionType | '', SelectOption[]>;

  override stepperForm = new FormGroup({
    [TrustDistributionStepsEnum.TrustDetails]: new FormGroup({
      name: new FormControl<string | null>(''),
      trustDeedIncomeDetermination: new FormControl<string | null>('', [CustomFormValidators.maxLength(1000)]),
      powerToDistributeIncome: new FormControl<string | null>('', [CustomFormValidators.maxLength(1000)]),
    }),
    [TrustDistributionStepsEnum.TrusteeDetails]: new FormGroup({
      trusteeType: new FormControl<IndividualCorporateRelationship | null>(IndividualCorporateRelationship.Individual),
      individualTrustee: new FormArray<RelationshipFormGroup>([]),
      corporateTrustee: this.createCorporateTrusteeFormGroup(),
    }),
    [TrustDistributionStepsEnum.BeneficiaryDetails]: new FormGroup({
      beneficiaryType: new FormControl<IndividualCorporateRelationship | null>(IndividualCorporateRelationship.Individual),
      individualBeneficiaries: new FormArray<RelationshipFormGroup>([]),
      corporateBeneficiary: this.createCorporateTrusteeFormGroup(),
    }),
    [TrustDistributionStepsEnum.DistributionDetails]: new FormGroup({
      financialYear: new FormControl<string | number | null>(null, [Validators.required]),
      beneficiaries: new FormArray<TrustDistributionFormGroup>([])
    }),
  });

  constructor() {
    super();
    this.setupSteps(TrustDistributionStepsEnum);
    this.filteredDistributionTypes = { ['']: this.distributionTypeSelectOptions } as Record<any, any>;
    Object.keys(DistributionType)
      .filter((v) => !isNaN(Number(v)))
      .forEach((key) => {
        this.filteredDistributionTypes[key] = this.distributionTypeSelectOptions;
      });
  }

  ngOnInit(): void {
    this.fillReadonlyFields();
    this.updateSteps();
    this.addDistributionFormGroup();
    this.setupChange();
  }

  override afterSubmit(changes: EntityChangeData[]) {
    const change = changes.find((c) => c.$type === TrustDistribution.$type) as TrustDistribution;
    this.setupChange(change);
  }

  override setupChange(change: TrustDistribution = this.formModel) {
    if (!this.isEdit && change === this.formModel) return;

    this.trustDetailsForm.patchValue(change);
    this.distributionDetailsForm.patchValue({
      financialYear: change.financialYear,
    });

    const forms: TrustDistributionFormGroup[] = [];
    change.distributions.forEach((distribution) => {
      const beneficiariesForms = this.createDistributionFormGroup(change.beneficiaries);
      beneficiariesForms.patchValue(distribution);
      forms.push(beneficiariesForms);
    });

    this.distributionDetailsForm.setControl('beneficiaries', new FormArray<TrustDistributionFormGroup>(forms));

    if (!this.financialYearEndingOptions.find((option) => option.value === change.financialYear) && Number(change.financialYear)) {
      this.financialYearEndingOptions.push({ value: change.financialYear, label: change.financialYear.toString() });
    }

    this.stepperForm.updateValueAndValidity();
  }

  addDistributionFormGroup(): void {
    const distributionFormGroup = this.createDistributionFormGroup(this.trustProfile.beneficiaries);
    this.distributionDetailsForm.controls.beneficiaries.push(distributionFormGroup);
  }

  removeDistributionFormGroup(index: number): void {
    const selectedDistributionType = this.distributionDetailsForm.controls.beneficiaries.controls[index].controls.distributionType.value;
    this.distributionDetailsForm.controls.beneficiaries.removeAt(index);

    if (selectedDistributionType !== null) {
      Object.keys(this.filteredDistributionTypes).forEach((distributionType) => {
        const previousValue = this.filteredDistributionTypes[distributionType];
        this.filteredDistributionTypes[distributionType] = this.distributionTypeSelectOptions
          .filter((option) => {
            return previousValue.find((o) => o.value == option.value || option.value == selectedDistributionType);
          });
      });

      this.filteredDistributionTypes[''].push(this.distributionTypeSelectOptions.find((o) => o.value == selectedDistributionType)!);
      this.filteredDistributionTypes[''].sort((o1, o2) => Number(o1.value) - Number(o2.value));
    }

    this.distributionDetailsForm.controls.beneficiaries.updateValueAndValidity();
  }

  onSelectDistributionType(optionValue: unknown): void {
    const distributionType = optionValue as DistributionType;
    const addedDistributionTypes: DistributionType[] = [distributionType];
    this.distributionDetailsForm.controls.beneficiaries.updateValueAndValidity();
    const selectedInFormDistributionTypes = this.distributionDetailsForm.controls.beneficiaries.value
      .map((form) => form.distributionType)
      .filter(v => v !== null) as DistributionType[];
    const addDistributionTypeIfNotPresent = (type: DistributionType): void => {
      const typeIsNotAddedInForm = !selectedInFormDistributionTypes.includes(type);
      const typeIsNotAddedInFunction = !addedDistributionTypes.includes(type);
      if (typeIsNotAddedInForm && type !== optionValue && typeIsNotAddedInFunction) {
        const form = this.createDistributionFormGroup(this.trustProfile.beneficiaries);
        this.distributionDetailsForm.controls.beneficiaries.controls.push(form);
        addedDistributionTypes.push(type as DistributionType);
        form.patchValue({ distributionType: type });
      }
    };

    addDistributionTypeIfNotPresent(DistributionType.BalanceOfTrustIncome);

    switch (distributionType) {
      case DistributionType.DistributionOfCapitalGains:
      case DistributionType.DistributionOfDiscountedCapitalGains:
        addDistributionTypeIfNotPresent(DistributionType.DistributionOfCapitalGains);
        addDistributionTypeIfNotPresent(DistributionType.DistributionOfDiscountedCapitalGains);
        break;
      default: {
        if (distributionType !== null)
          addDistributionTypeIfNotPresent(distributionType);
      }
    }

    const selectedDistributionTypes: DistributionType[] = this.distributionDetailsForm.value.beneficiaries
      ?.map(({ distributionType }) => distributionType)
      ?.filter((value) => !Number.isNaN(Number(value))) as DistributionType[];

    Object.keys(this.filteredDistributionTypes)
      .forEach((distributionType) => {
        this.filteredDistributionTypes[distributionType] = this.distributionTypeSelectOptions
          .filter((optionToFilter) => {
            const asSameAsType = (distributionType as any as DistributionType) == optionToFilter.value && distributionType !== '';
            const includesInJustAddedTypes = !addedDistributionTypes.includes(optionToFilter.value);
            const includesAgoAddedTypes = !selectedDistributionTypes.includes(optionToFilter.value);
            return asSameAsType || (includesInJustAddedTypes && includesAgoAddedTypes);
          });
      });

    this.distributionDetailsForm.updateValueAndValidity();
    this.distributionDetailsForm.controls.beneficiaries.updateValueAndValidity();
  }

  fillReadonlyFields(): void {
    this.trustProfile.beneficiaries
      .forEach(r => this.names[r.relationshipId] = r.individualDataOverride?.fullName ?? r.entityDataOverride?.name ?? '-');

    // Trust Details
    this.trustDetailsForm.controls.name.setValue(this.trustProfile.name);

    // Trustee Details
    const trusteesToEdit = this.trustProfile.trustees;
    const corporateTrustee = trusteesToEdit?.find((trustee) => trustee.entityDataOverride) ?? null;

    if (corporateTrustee) {
      this.trusteeDetailsForm.patchValue({
        trusteeType: IndividualCorporateRelationship.Corporate,
        corporateTrustee: {
          name: corporateTrustee.entityDataOverride?.name,
          acn: corporateTrustee.entityDataOverride?.entityNumber,
          address: corporateTrustee.entityDataOverride?.registeredAddress,
        }
      });
    } else {
      this.trusteeDetailsForm.controls.trusteeType.setValue(IndividualCorporateRelationship.Individual);
      const individualTrustee = trusteesToEdit.filter((trustee) => trustee.individualDataOverride);
      clearFormArray(this.trusteeDetailsForm.controls.individualTrustee);

      individualTrustee.forEach((t) => {
        if (t.individualDataOverride) {
          this.trusteeDetailsForm.controls.individualTrustee.push(this.createIndividualTrusteeFormGroup(t));
        }
      });
    }

    // Beneficiary Details
    const beneficiariesToEdit = this.trustProfile.beneficiaries;
    const corporateBeneficiaries = beneficiariesToEdit?.find((trustee) => trustee.entityDataOverride) ?? null;

    if (corporateBeneficiaries) {
      this.beneficiaryDetailsForm.patchValue({
        beneficiaryType: IndividualCorporateRelationship.Corporate,
        corporateBeneficiary: {
          name: corporateBeneficiaries.entityDataOverride?.name,
          acn: corporateBeneficiaries.entityDataOverride?.entityNumber,
          address: corporateBeneficiaries.entityDataOverride?.registeredAddress,
        }
      });
    } else {
      this.beneficiaryDetailsForm.controls.beneficiaryType.setValue(IndividualCorporateRelationship.Individual);
      const individualTrustee = beneficiariesToEdit.filter((trustee) => trustee.individualDataOverride);
      clearFormArray(this.beneficiaryDetailsForm.controls.individualBeneficiaries);

      individualTrustee.forEach((t) => {
        if (t.individualDataOverride) {
          this.beneficiaryDetailsForm.controls.individualBeneficiaries.push(this.createIndividualTrusteeFormGroup(t));
        }
      });
    }

    this.setIsLoading(false);
  }

  setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading;

    setControlDisabled(this.stepperForm, isLoading);

    if (!isLoading) {
      setControlDisabled(this.trustDetailsForm.controls.name);
      setControlDisabled(this.trusteeDetailsForm);
      setControlDisabled(this.beneficiaryDetailsForm);
    }
  }

  override setCurrentStep(newStepIndex: number): void {
    this.currentStep = this.stepsSignal()[newStepIndex].step;
    this.currentStepIndex = newStepIndex;
  }

  override buildDocument(): Document | null {
    const distributions = this.distributionDetailsForm.controls.beneficiaries.controls
      .map((beneficiaryForm) => {
        return new DistributionModel({
          beneficiaries: beneficiaryForm.controls.beneficiaries.value as Record<string, number>,
          distributionType: beneficiaryForm.controls.distributionType.value!,
          disctributionMeasurement: beneficiaryForm.controls.disctributionMeasurement.value!,
        });
      });

    const change = new TrustDistribution({
      ...this.trustDetailsForm.value as Partial<TrustDistribution>,
      distributions,
      trustees: this.trustProfile.trustees,
      beneficiaries: this.trustProfile.beneficiaries,
      financialYear: +this.distributionDetailsForm.controls.financialYear.value!,
    });

    try {
      return new Document({
        changes: [change],
        type: 't:change',
        entityId: this.trustProfile.entityId,
        documentId: this.companyChangeData?.documentId ?? Guid.EmptyGuid,
      });

    } catch (error) {
      this.toastr.error('Failed to create Document.', 'Error');
      return null;
    }
  }

  private updateSteps(): void {
    this.stepsSignal.set(this.steps);
    this.setCurrentStep(0);
  }

  private createIndividualTrusteeFormGroup(individualTrustee: Partial<Relationship> = {}): RelationshipFormGroup {
    const form = RelationshipFormGroupComponent.defineForm();
    form.patchValue({
      name: individualTrustee?.individualDataOverride?.fullName ?? '',
      address: individualTrustee?.individualDataOverride?.address ?? new Address({ country: 'AU' })
    });
    return form;
  }

  private createCorporateTrusteeFormGroup(corporateTrustee: Partial<Relationship> = {}): CorporateRelationshipFormGroup {
    const form = CorporateRelationshipFormGroupComponent.defineForm();
    form.patchValue({
      name: corporateTrustee.entityDataOverride?.name ?? '',
      acn: corporateTrustee.entityDataOverride?.entityNumber ?? '',
      address: corporateTrustee.entityDataOverride?.registeredAddress ?? new Address({ country: 'AU' }),
    });
    return form;
  }

  private createDistributionFormGroup(relationships: Relationship[] = []): TrustDistributionFormGroup {
    const relationshipsRecord = relationships.reduce((controls, relationship, index) => {
      controls[relationship.relationshipId] = new FormControl<number | null>(0, [Validators.required]);
      return controls;
    }, {} as Record<string, FormControl<number | null>>);

    const distributionFormGroup = new FormGroup({
      distributionType: new FormControl<DistributionType | null>(null, [Validators.required]),
      disctributionMeasurement: new FormControl<DisctributionMeasurement | null>(DisctributionMeasurement.Percentage, [Validators.required]),
      beneficiaries: new FormRecord<FormControl<number | null>>(relationshipsRecord)
    });

    distributionFormGroup.controls.disctributionMeasurement.valueChanges
      .pipe(startWith(distributionFormGroup.controls.disctributionMeasurement.value ?? DisctributionMeasurement.Percentage), takeUntilDestroyed(this.destroyRef))
      .subscribe((distributionFormat) => {
        switch (distributionFormat) {
          case DisctributionMeasurement.Percentage: {
            distributionFormGroup.setValidators(this.percentValidator);
            break;
          }
          case DisctributionMeasurement.Amount: {
            distributionFormGroup.removeValidators(this.percentValidator);
            break;
          }
        }

        Object.values(distributionFormGroup.controls.beneficiaries.controls)
          .forEach((control) => { control.updateValueAndValidity(); });
        distributionFormGroup.updateValueAndValidity();
      });

    return distributionFormGroup;
  }

  private percentValidator: ValidatorFn = (form: AbstractControl) => {
    const distributionForm = form as TrustDistributionFormGroup;

    const summaryPercent = Object.values(distributionForm.controls.beneficiaries.controls)
      .reduce((sum, control) => {
        const quantity = control.value;

        if (typeof quantity === 'number')
          sum += quantity;

        return sum;
      }, 0);

    return summaryPercent === 100 ?
      null
      : { percentsAreNotFit: true };
  };

  get trustDetailsForm() {
    return this.stepperForm.controls[TrustDistributionStepsEnum.TrustDetails];
  }

  get trusteeDetailsForm() {
    return this.stepperForm.controls[TrustDistributionStepsEnum.TrusteeDetails];
  }

  get beneficiaryDetailsForm() {
    return this.stepperForm.controls[TrustDistributionStepsEnum.BeneficiaryDetails];
  }

  get distributionDetailsForm() {
    return this.stepperForm.controls[TrustDistributionStepsEnum.DistributionDetails];
  }
}
