import { Component } from '@angular/core';
import { ICellRendererParams } from "ag-grid-community";
import { CurrencyPipe, NgClass } from "@angular/common";

@Component({
  selector: 'app-ag-master-number-of-shares',
  standalone: true,
  imports: [
    CurrencyPipe,
    NgClass
  ],
  templateUrl: './ag-master-number-of-shares.component.html',
  styleUrl: './ag-master-number-of-shares.component.scss'
})
export class AgMasterNumberOfSharesComponent {
  protected params!: ICellRendererParams;
  numberOfShares = 0;
  numberOfSharesAsic = 0;
  hasIssue = false;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.numberOfShares = (this.params.value as number) ?? 0;
    this.hasIssue = (params.data as { hasIssue?: boolean })?.hasIssue ?? false;
    this.numberOfSharesAsic = (this.params.data as { numberOfSharesAsic?: number })?.numberOfSharesAsic ?? 0;
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    return true;
  }
}
