import { DatepickerHelper } from '../custom-form-validators/date-picker-form-validators';
import { Address } from "./address";
import { Alert } from "./alerts/alert";
import { ParsedName } from "./parsedName";

export class IndividualData{
    firstName: string;
    middleName1: string;
    middleName2: string;
    lastName: string;
    fullName: string;
    fullNameWithMiddle: string;
    formerName: ParsedName;
    dob?: Date;  // Date of Birth
    din: string; // Director Identification Number
    address: Address;
    birthCity: string;
    birthCountry: string;
    alerts?: Alert[];

    constructor(data: Partial<IndividualData> = {}) {
        this.firstName = data.firstName ?? '';
        this.middleName1 = data.middleName1 ?? '';
        this.middleName2 = data.middleName2 ?? '';
        this.lastName = data.lastName ?? '';
        this.fullName = this.buildFullName();
        this.fullNameWithMiddle = this.buildFullNameWithMiddle();
        this.formerName = data.formerName ? new ParsedName(data.formerName) : new ParsedName();
        this.din = data.din ?? '';
        this.dob = data.dob;
        this.address = data.address ? new Address(data.address) : new Address();
        this.birthCity = data.birthCity ?? '';
        this.birthCountry = data.birthCountry ?? '';
    }

    private buildFullName(): string {
      return this.buildFullNameWithMiddle();
    }

    private buildFullNameWithoutMiddle(): string {
      return this.lastName.includes(' ')
        ? `${ this.lastName }, ${ this.firstName }`
        : `${ this.firstName } ${ this.lastName }`;
    }

    private buildFullNameWithMiddle(): string {
      const fullNameWithMiddleParts = this.lastName.includes(' ')
        ? [`${ this.lastName },`, this.firstName, this.middleName1, this.middleName2]
        : [this.firstName, this.middleName1, this.middleName2, this.lastName];

      return fullNameWithMiddleParts.filter(Boolean).join(' ');
    }

    static prepareToRequest(individualData: IndividualData): IndividualData {
        individualData.dob = individualData.dob ? DatepickerHelper.buildDateString(new Date(individualData.dob)) : individualData.dob;
        return individualData;
    }
}
