<div class="card">
  <div class="card-header">
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <i class="ic-documents ic-md icon"></i>
        <h2 class="h2-heading mb-0 ml-3">{{pageTitle}}</h2>
      </div>

      <div class="button-container">
        @if (selectedDocuments.length) {
          <div ngbDropdown [placement]="['bottom-end', 'bottom-start', 'top-start', 'top-end']" class="dropdown">
            <app-button
              ngbDropdownToggle
              class="hide-button-text-thin-page"
              id="bulk-actions-dd"
              [text]="'Actions'"
              [iconLast]="true"
              [iconClass]="'icon ic-xs ic-select-icon'"
              [isDisabled]="modalOpened()"
            ></app-button>
            <div ngbDropdownMenu aria-labelledby="bulk-actions-dd" class="dropdown-menu dropdown-menu-right">
              <div class="dropdown-item" (click)="bulkSendForSigning()">
                <a class="dropdown-button">
                  <span>Send for Signing</span>
                </a>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  </div>

   <app-grid
     [defaultExcelExportParams]="excelExportParams"
     [defaultCsvExportParams]="csvExportParams"
     [colDefs]="$any(colDefs)"
     [rowData]="[]"
     [pageTitle]="pageTitle"
     [suppressRowClickSelection]="true"
     [rowModelType]="serverRowModelType"
     [serverSideDatasource]="dataSource"
     [getContextMenuItems]="$any(getContextMenuItems)"
     [getRowId]="getRowId"
     [pagination]="true"
     [paginationPageSize]="defaultPageSize"
     [cacheBlockSize]="cacheBlockSize"
     (search)="onSearch($event)"
     (gridReady)="onGridReady($event)"
     (itemSelect)="selectDocuments($event)"
   ></app-grid>
</div>

<app-notes
  [entity]="entityForNotes"
  [entityType]="EntityType.Company"
  [opened]="notesVisible"
  (close)="notesVisible = false"
></app-notes>
