<div class="share-joint-security-holder">
  <div class="header">Shareholders</div>
    <ul class="joint-holders-list ">
      @for (holder of value; track holder.relationshipId; let index = $index) {
        <li class="holder-item">
          <app-security-holder-icon-label [holder]="holder"></app-security-holder-icon-label>

          <button
            class="edit-icon"
            type="button"
            [disabled]="disabled"
            (click)="onEditHolder(holder)"
          >
            <i class="ic-edit icon ic-sm"></i>
          </button>

          <button
            class="delete-icon"
            type="button"
            [disabled]="disabled"
            (click)="onDeleteHolder(holder)"
          >
            <i class="ic-close icon ic-sm"></i>
          </button>
        </li>
      }
    </ul>

    <div class="addition-controls">
      <app-button
        iconClass="ic-plus icon ic-sm"
        text="Add individual"
        [isDisabled]="disabled"
        (clickBtn)="addIndividual()"
      ></app-button>
      <app-button
        iconClass="ic-plus icon ic-sm"
        text="Add corporate"
        [isDisabled]="disabled"
        (clickBtn)="addCorporate()"
      ></app-button>
    </div>
</div>
