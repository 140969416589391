import { Operation } from 'fast-json-patch';
import { formatDate } from "@angular/common";

export function filterPatchOperations(operations: Operation[]): Operation[] {
  const dateKeys = [
    'dob',
    'resignationLetterGiven',
    'marketBuyBackFrom',
    'marketBuyBackTo',
    'financialYearBegins',
    'financialYearEnds',
    'start',
    'end'
  ];

  return (operations || []).filter(operation => {
    //this path have to be removed from patchOperations for success patch
    //normalizedFullAddress not exist on the backend
    return (
      operation.path !== '/modifiedOn'
      && operation.path !== '/createdOn'
      && !operation.path.includes('normalizedFullAddress')
      && !operation.path.includes('fullName')
      && !operation.path.includes('checked')
      && !operation.path.includes('isDeleted')
      && operation.path !== '/changeAuthorisation/authorisingParty/details/$type'
      && operation.path !== '/changeAuthorisation/authorisingParty/details/isAlternativeDirector'
      && operation.path !== '/changeAuthorisation/authorisingParty/details/alternativeDirectorFor'
      && operation.path !== '/changeAuthorisation/authorisingParty/details/termsOfAppointment'
      && !operation.path.endsWith('/key')
    )
  }).map((o: Operation) => {
    const isDatePath = o.path.toLowerCase().includes('date') ||
      dateKeys.some((dateKey) => o.path.endsWith('/' + dateKey));

    if ((o as {value: string}).value && isDatePath) {
      (o as {value: string}).value = formatDate((o as {value: string}).value, 'yyyy-MM-dd', 'en-US');
    }

    return o;
  });
}
