import { Component, inject, Input, Signal, signal } from '@angular/core';
import { ButtonComponent } from "../../../components/common/button/button.component";
import { CommonModalFormComponent } from "../../common-modal-form/common-modal-form.component";
import { CurrencyPipe, DatePipe } from "@angular/common";
import { DividerComponent } from "../../../components/common/divider/divider.component";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  MultipleInputEmailComponent
} from "../../../components/common/multiple-input-email/multiple-input-email.component";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { RadioComponent } from "../../../components/common/radio/radio.component";
import { ShadowDomViewerComponent } from "../../../components/common/shadow-dom-viewer/shadow-dom-viewer.component";
import { Company } from "../../../../models/company";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { EmailTemplatesService } from "../../../../services/email-templates.service";
import { OrganisationService } from "../../../../services/organisation.service";
import { ACNPipe } from "../../../../pipes/acnPipe";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { SelectOption } from "../../../../models/selectOptions";
import { SendOption } from "../../../../models/enums/annualStatementEnums";
import { EmailTemplate } from "../../../../models/email-templates/emailTemplate";
import { EmailMultipleAddress } from "../../../../models/email-templates/emailMultipleAddress";
import { CustomFormValidators } from "../../../../custom-form-validators/custom-form-validators";
import { toSignal } from "@angular/core/rxjs-interop";
import { catchError, firstValueFrom, map, Observable, of, switchMap, tap } from "rxjs";
import { blobToBase64 } from "../../../helpers/blob-to-base64";
import { IEmailPreviewResponse } from "../../../../models/email-templates/IEmailPreviewResponse";
import { EmailTemplatesHelper } from "../../../../models/email-templates/emailTemplatesHelper";

@Component({
  selector: 'app-annual-document-reminder',
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModalFormComponent,
    CurrencyPipe,
    DatePipe,
    DividerComponent,
    FormsModule,
    MultipleInputEmailComponent,
    NgxSkeletonLoaderModule,
    RadioComponent,
    ReactiveFormsModule,
    ShadowDomViewerComponent
  ],
  providers: [EmailTemplatesService],
  templateUrl: './annual-document-reminder.component.html',
  styleUrl: './annual-document-reminder.component.scss'
})
export class AnnualDocumentReminderComponent {
  @Input() isAnnualReminder = true;
  @Input() companyProfile: Company | undefined;

  private fb: FormBuilder = inject(FormBuilder);
  private activeModal = inject(NgbActiveModal);
  private emailTemplatesService = inject(EmailTemplatesService);
  private organisationService = inject(OrganisationService);
  private acnPipe = inject(ACNPipe);
  private sanitizer = inject(DomSanitizer);
  private toastr = inject(ToastrService);

  public sendByOptions: SelectOption[] = [
    { label: 'Email', value: SendOption.Email },
    { label: 'SMS', value: SendOption.Sms },
  ];

  public attachAnnualStatementOptions: SelectOption[] = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  public currentDate = new Date();
  public isSendDebtReminder = false;
  public loading = false;

  public template!: EmailTemplate;
  private emailTemplates: EmailTemplate[] = [];
  form = new FormGroup({
    sendBy: new FormControl<SendOption>(SendOption.Email),
    attachInvoice: new FormControl<boolean>(true),
    recipientEmails: new FormControl<EmailMultipleAddress[]>([], CustomFormValidators.minArrayLength(1))
  });

  htmlContent = signal<SafeHtml | null>(null);
  logo = toSignal(this.organisationService.getSmallLogo()
    .pipe(map(b => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(b))))
    .pipe(catchError(() => of('assets/svg/bolt-logo-icon.svg'))));

  phoneNumber: Signal<string | undefined> = toSignal(
    this.organisationService.getProfile().pipe(
      map(profile => profile.phoneNumber ?? '')
    )
  );

  async ngOnInit(): Promise<void> {
    this.fillEmailsRecipient();

    this.loading = true;
    this.emailTemplates = await firstValueFrom(this.emailTemplatesService.loadEmailTemplates());
    this.setEmailPreview();
  }

  private fillEmailsRecipient(): void {
    if (!this.companyProfile?.billingContact?.email) return;

    const recipient: EmailMultipleAddress = {
      email: this.companyProfile.billingContact.email.trim(),
      fullName: `${ this.companyProfile.billingContact.firstName.trim() } ${ this.companyProfile.billingContact.lastName.trim() }`
    };

    this.form.controls.recipientEmails.setValue([recipient]);
  }

  private setEmailPreview(): void {
    const sendBy = this.form.controls.sendBy.value as SendOption;
    //TODO: Which template needs to be used here?
    if (this.isAnnualReminder && sendBy === SendOption.Email) {
      this.selectEmailTemplate('as-debt-reminder');
    } else if (!this.isAnnualReminder && sendBy === SendOption.Email) {
      this.selectEmailTemplate('asic-debt-reminder');
    }
  }

  private selectEmailTemplate(templateCode: string): void {
    const emailTemplate = this.emailTemplates.filter(template => template.code === templateCode);
    if (emailTemplate.length) {
      this.template = emailTemplate[0];
      this.template.subjectTemplate = this.template.subjectTemplate
        .replace('{{CompanyName}}', this.companyProfile?.name ?? '');
      this.loadPreview().subscribe(() => {
        this.loading = false;
      });
    }
  }

  private prepareTemplateString(text: string | null): string {
    return (text ?? '')
      .replaceAll('\n', '')
      .replaceAll('<br />', '\n')
      .replaceAll('</br>', '\n');
  }

  private loadPreview(): Observable<IEmailPreviewResponse> {
    const errorFormResponse = of({ subject: '', htmlContent: '' });

    return this.emailTemplatesService.loadPreview(EmailTemplatesHelper.buildEmailPreviewRequest(this.buildTemplate()))
      .pipe(
        tap({
          next: (res) => this.htmlContent.set(this.sanitizer.bypassSecurityTrustHtml(res.htmlContent) ?? ''),
          error: (error) => {
            console.warn('[loadPreview] error: ', error);
            this.toastr.error("Something happened while loading preview", "Error");
            this.loading = false;
          }
        }),
        catchError(() => errorFormResponse),
      );
  }

  private buildTemplate(): EmailTemplate {
    return new EmailTemplate({
      ...this.template,
      subjectTemplate: this.template?.subjectTemplate,
      bodyTemplate: this.replaceNewLine(this.prepareTemplateString(this.template.bodyTemplate)),
      footerTemplate: this.replaceNewLine(this.prepareTemplateString(this.template.footerTemplate)),
    });
  }

  private replaceNewLine(text: string | null): string {
    return text?.replaceAll('\n', '</br>') ?? '';
  }

  public sendReminder(): void {
  }

  public close(): void {
    this.activeModal.dismiss();
  }
}
