export class DeepCopy {
  static mergeDeep<T>(target: T, source: T): T {
    if (typeof target !== 'object' || target === null) {
      return target;
    }

    if (typeof source !== 'object' || source === null) {
      return target;
    }

    Object.keys(source).forEach(key => {
      const targetValue = target[key];
      const sourceValue = source[key];

      if (Array.isArray(sourceValue)) {
        target[key] = sourceValue.slice();
      } else if (typeof sourceValue === 'object') {
        target[key] = DeepCopy.mergeDeep(
          targetValue === undefined ? {} : targetValue,
          sourceValue
        );
      } else {
        target[key] = sourceValue;
      }
    });

    return target;
  }
}
