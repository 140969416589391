import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Company } from '../../../../../../models/company';
import { Document } from '../../../../../../models/document';
import { EntityChangeData } from '../../../../../../models/entityChangeData';
import { CompanyChangeData } from '../../../../../../models/enums/companyChangeData';
import { CompanySubtype } from '../../../../../../models/enums/companyEnums';
import { ChangeDictionaryHelper } from '../../../../../../models/shared/change-dictionary-helper.model';
import { B3CompanyStatusChangeComponent } from './b3-company-status-change.component';

export class CompanyChangeSpecialPurpose extends EntityChangeData {
  static override readonly $type = 'CompanyChangeSpecialPurpose';
  newCompanySubtype: CompanySubtype;

  constructor(data: Partial<CompanyChangeSpecialPurpose> = {}) {
    super(data);

    this.newCompanySubtype = data.newCompanySubtype ?? CompanySubtype.PROP;
  }

  override toDictionary(): { key: string; value: string; }[] {
    const dict = new ChangeDictionaryHelper();

    dict
      .add('The Change is', this.newCompanySubtype === CompanySubtype.PROP ? 'Cease' : 'Commerce')
      .add('New Company Subtype', CompanySubtypeLabels[this.newCompanySubtype])
      .addDate('Date of Change', this.changeDate);

    return dict.dictionary;
  }

  override fillModal(modalRef: NgbModalRef, document: Document, actualCompany: Company): NgbModalRef {
    modalRef = this.fillModalWithCompany(modalRef, actualCompany, true);
    (modalRef.componentInstance as B3CompanyStatusChangeComponent).companyChangeData.documentId = document.documentId;
    return modalRef;
  }

  fillModalWithCompany(modalRef: NgbModalRef, company: Company, isEdit = false): NgbModalRef {
    const instance = modalRef.componentInstance as B3CompanyStatusChangeComponent;

    instance.isEdit = isEdit;
    instance.companyChangeData = new CompanyChangeData(company);
    instance.formModel = structuredClone(this);

    return modalRef;
  }
}

const CompanySubtypeLabels: Record<CompanySubtype, string> = {
  [CompanySubtype.PROP]: 'Proprietary',
  [CompanySubtype.HUNT]: 'House Unit Company',
  [CompanySubtype.PSTC]: 'SMSF Trustee Company',
  [CompanySubtype.PNPC]: 'Non-profit',
  // only subtypes above are used for the preview
  [CompanySubtype.LISN]: 'Section 383',
  [CompanySubtype.LISS]: 'Superannuation Trustee Company',
  [CompanySubtype.LIST]: 'Listed Public Company',
  [CompanySubtype.STFI]: 'Small Transferring Financial Institutions',
  [CompanySubtype.NLTD]: 'Not Limited Public Company (section 150)',
  [CompanySubtype.ULSN]: 'Unlisted Public Non-profit Company',
  [CompanySubtype.ULSS]: 'Unlisted Superannuation Trustee',
  [CompanySubtype.ULST]: 'Unlisted Public'
};
