<app-stepper-form
  header="Appointment of Officeholder"
  subheader="Form 484B2"
  [showSteps]="false"
  [currentStepIndex]="currentStepIndex"
  [currentStep]="currentStep"
  [steps]="steps"
  [form]="stepperForm"
  [isLoading]="isLoading"
>
  <div class="step-content" [formGroup]="stepperForm">
    <app-stepper-form-description
      formKey="484 B2"
      [hidden]="currentStep !== StepsEnum.FormDescription"
    ></app-stepper-form-description>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.AppointOfficer"
      [hidden]="currentStep !== StepsEnum.AppointOfficer"
    >
      <h1 class="h1-heading">Appoint Officer</h1>

      <div class="form-group">
        <app-date-picker
          label="Date of Appointment"
          formControlName="changeDate"
          [minDate]="minDate"
          [maxDate]="maxDate"
          [customErrors]="customDatepickerErrors"
        ></app-date-picker>
      </div>

      <div class="form-group">
        <app-yes-no-control
          label="Is this an appointment of new officeholder?"
          formControlName="isNewOfficeholder"
        ></app-yes-no-control>
      </div>

      @if (isNewOfficeholderSignal() == false) {
        <div class="form-group">
          <app-autocomplete
            label="Appointed Officer"
            formControlName="appointedOfficerId"
            [options]="officersSelectOptions"
            [valuePrimitive]="true"
          ></app-autocomplete>
        </div>

        @if (form.value.appointedOfficerId) {
          <div class="form-group">
            <app-select
              label="Position"
              formControlName="type"
              [options]="allowedRelationshipTypesSignal()"
              [valuePrimitive]="true"
              (select)="form.controls.alternativeDirectorFor.reset()"
            ></app-select>
          </div>
        }
      }

      <div [hidden]="isNewOfficeholderSignal() !== true">
        <div class="form-group" >
          <app-individual-data-form-group
            [formGroup]="form.controls.newOfficeholder"
          ></app-individual-data-form-group>
        </div>

        @if (form.touched && form.errors?.['poBoxAddress']) {
          <div class="mb-4">
            <app-validation-error
              [control]="form"
              [customErrors]="addressesCustomErrors"
            ></app-validation-error>
          </div>
        }

        <div class="form-group">
          <app-select
            label="Position"
            formControlName="type"
            [options]="positionTypesOptions"
            [valuePrimitive]="true"
          ></app-select>
        </div>
      </div>

      @if (selectedPositionSignal() && selectedPositionSignal() !== OfficerAppointmentType.Secretary) {
        <div class="form-group">
          <app-din-masked-input
            formControlName="din"
          ></app-din-masked-input>
        </div>
      }

      @if (form.value.type === OfficerAppointmentType.AlternativeDirector) {
        <div class="form-group">
          <app-date-picker
            label='Expiry Date'
            formControlName="expiryDate"
            [isRequired]="false"
            [minDate]="minExpiryDate"
          ></app-date-picker>
        </div>

        <div class="form-group">
          <app-select
            label='The appointed "Alternate director" is alternate for'
            noOptionsText="No directors available"
            formControlName="alternativeDirectorFor"
            [options]="allowedDirectorsSignal()"
            [valuePrimitive]="true"
          ></app-select>
        </div>

        <div class="form-group">
          <app-textarea
            label="Terms of Appointment"
            formControlName="termsOfAppointment"
          ></app-textarea>
        </div>
      }
    </section>
  </div>
</app-stepper-form>
