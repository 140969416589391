<app-stepper-form
  header="Subdivision or Consolidation"
  subheader="Form 2205"
  [steps]="steps"
  [currentStep]="currentStep"
  [currentStepIndex]="currentStepIndex"
  [isLoading]="isLoading"
  [form]="stepperForm"
>
  <div class="step-content full-width" [formGroup]="stepperForm">
    <div class="step-content">
      <app-stepper-form-description
        formKey="2205"
        [hidden]="currentStep !== StepsEnum.FormDescription"
      ></app-stepper-form-description>
    </div>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.ResolutionSubject"
      [hidden]="currentStep !== StepsEnum.ResolutionSubject"
    >
      <h1 class="h1-heading">{{ steps[currentStepIndex].label }}</h1>

      @for (resolutionSubject of resolutionSubjects; track resolutionSubject) {
        <div class="form-group">
          <app-checkbox
            [label]="resolutionSubject.label"
            [formControlName]="resolutionSubject.key"
          ></app-checkbox>
        </div>
      }

      @if (resolutionSubjectForm.controls.otherSubject.value) {
        <app-divider margin="24px 0"></app-divider>

        <div class="form-group">
          <app-input-number
            label="Section Number"
            formControlName="sectionNumber"
          ></app-input-number>
        </div>

        <div class="form-group">
          <app-textarea
            label="Brief Description"
            formControlName="briefDescription"
          ></app-textarea>
        </div>
      }
    </section>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.SubdivisionConversionConsolidation"
      [hidden]="currentStep !== StepsEnum.SubdivisionConversionConsolidation"
    >
      <h1 class="h1-heading">{{ steps[currentStepIndex].label }}</h1>

      <div class="subdivision share-numbers-grid">
        <div class="form-group share-types">
          <app-share-security-holdings-dropdown
            formControlName="securityHoldingClass"
            [options]="securityRegistryRecordsHoldings"
            [hidePrice]="true"
          ></app-share-security-holdings-dropdown>
        </div>

        <div class="form-group">
          <app-input-number
            label="New Number of Shares"
            formControlName="newNumberShares"
            [customErrors]="{ mustBeNotEqual: 'Please use a number that is either larger or smaller.' }"
            [min]="-1000000000"
          ></app-input-number>
        </div>
      </div>

      <app-divider margin="24px 0"></app-divider>

      <h1 class="h1-heading">Details of Resolution</h1>

      <div class="form-group">
        <app-date-picker
          label="Date of Resolution"
          formControlName="dateOfResolution"
          [minDate]="minDate"
          [maxDate]="maxDate"
          [smallWidth]="true"
          [customErrors]="customDatepickerErrors"
        ></app-date-picker>
      </div>

      <div class="form-group">
        <app-textarea
          label="Resolution"
          formControlName="resolutionText"
          [rows]="3"
        ></app-textarea>
      </div>
    </section>

    <section
      class="step-section w-100"
      [formGroupName]="StepsEnum.Estimate"
      [hidden]="currentStep !== StepsEnum.Estimate"
    >
      <div>
        <h1 class="h1-heading">Share Classes Distribution Estimate</h1>

        <div [hidden]="isEstimateLoading">
          <app-list-grid
            [rowData]="shareClassesDistributionEstimate"
            [colDefs]="$any(shareClassesDistributionEstimateConfig)"
            [suppressRowClickSelection]="true"
            [pagination]="true"
            [paginationPageSize]="20"
            [paginationPageSizeSelector]="true"
            [suppressCellFocus]="true"
          ></app-list-grid>
        </div>

        <app-loader [loading]="isEstimateLoading"></app-loader>
      </div>

      <div>
        <h1 class="h1-heading">Share Types Distribution Estimate</h1>

        <div [hidden]="isEstimateLoading">
          <app-list-grid
            [rowData]="shareTypesDistributionEstimate"
            [colDefs]="$any(shareTypesDistributionEstimateConfig)"
            [suppressRowClickSelection]="true"
            [pagination]="true"
            [paginationPageSize]="20"
            [paginationPageSizeSelector]="true"
            [suppressCellFocus]="true"
          ></app-list-grid>
        </div>

        <app-loader [loading]="isEstimateLoading"></app-loader>
      </div>

      <h1 class="h1-heading">Shareholders + Share Type Distribution Estimate</h1>

      <app-share-estimate-table
        [isEstimateLoading]="isEstimateLoading"
        [estimatedSecurityRegistryRecords]="estimatedSecurityRegistryRecords"
        [exceptColumns]="['Beneficially Hold']"
        [hideSecurityTypeQuantity]="true"
      ></app-share-estimate-table>
    </section>
  </div>
</app-stepper-form>
