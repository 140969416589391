import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appDollar]',
  standalone: true
})
export class DollarDirective {
  @HostListener('input', ['$event'])
  onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9,$]*$/g, ''); //allow only digits, commas and $
    if (!input.value.startsWith('$')) {
      input.value = '$' + input.value.replace(/\$/g, '');
    }
  }
}
