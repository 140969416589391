<div class="generate-documents-form-container">
  <div class="title-container">
    <h3>Generate Documents</h3>
  </div>

  @if(!loading) {
    <div class="form-body-container">
      <div class="cover-letter">
        <app-select
          [label]="'Cover Letter Template'"
          [placeholder]="'Select Cover Letter Template'"
          [valuePrimitive]="true"
          [options]="coverLetterTemplateOptions"
          [(ngModel)]="selectedCoverLetterId"
          [ngModelOptions]="{standalone: true}"
          (ngModelChange)="onCoverLetterSelect($event)"
        ></app-select>
      </div>

<!--We are going to add it in the future-->
<!--      <div class="solvency">-->
<!--        <app-select-->
<!--          [label]="'Solvency Template'"-->
<!--          [placeholder]="'Select Solvency Template'"-->
<!--          [valuePrimitive]="true"-->
<!--          [options]="solvencyTemplateOptions"-->
<!--          [(ngModel)]="solvencyTemplate"-->
<!--          [ngModelOptions]="{standalone: true}"-->
<!--          (ngModelChange)="onSolvencySelect($event)"-->
<!--        ></app-select>-->
<!--      </div>-->

      @if(documentSelection && documentSelection.length > 1) {
        <app-document-selection
          [title]="'Choose Documents to Generate'"
          [documentSelectionList]="documentSelection"
          [documentationType]="documentationType"
          (documentSelectionChange)="onDocumentSelectionChange($event)"
        ></app-document-selection>
      }
    </div>
  } @else {
    <div class="skeleton-container">
      <ngx-skeleton-loader count="2" appearance="line" />
      <div class="d-flex gap-24">
        <ngx-skeleton-loader style="width: 50%" count="1" appearance="line" />
        <ngx-skeleton-loader style="width: 50%" count="1" appearance="line" />
      </div>
      <ngx-skeleton-loader count="2" appearance="line" />
      <div class="d-flex gap-24">
        <ngx-skeleton-loader style="width: 50%" count="1" appearance="line" />
        <ngx-skeleton-loader style="width: 50%" count="1" appearance="line" />
      </div>
      <ngx-skeleton-loader count="2" appearance="line" />
    </div>
  }
</div>
