<div class="page-title card-header">
  <ng-content select=".start"></ng-content>

  @if (backButtonPath !== null) {
    <div class="start back-btn-wrapper">
      <app-button
        btnColor="white"
        iconClass="icon ic-md ic-arrow-left"
        [text]="backButtonText"
        [routerLink]="backButtonPath"
        [relativeTo]="null"
      />
    </div>
  }

  <div class="title-wrapper">
    @if (iconClass) {
      <i [class]="iconClass + ' ic-md icon'"></i>
    }

    <div class="header-content">
      <h2 class="title" [class.small]="subheader">{{ header }}</h2>
      <h3 class="subtitle">{{ subheader }}</h3>
    </div>
  </div>

  <div class="button-container">
    <ng-content></ng-content>
  </div>
</div>
