import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { AuthService } from "../../services/auth.service";
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from "@ng-bootstrap/ng-bootstrap";
import { AsyncPipe, DatePipe, NgClass } from "@angular/common";
import { MenuService } from "../../services/menu.service";
import { OrganisationService } from '../../services/organisation.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { catchError, map, of, shareReplay, tap } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { SearchInputComponent } from "../components/common/search-input/search-input.component";
import { AlertsService } from "../../services/alert.service";
import { Router, RouterLink } from "@angular/router";
import { DividerComponent } from "../components/common/divider/divider.component";
import { ButtonComponent } from "../components/common/button/button.component";
import { AlertStatus } from "../../models/enums/alertEnums";

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    NgClass,
    RouterLink,
    DatePipe,
    SearchInputComponent,
    NgbDropdownToggle,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdown,
    ButtonComponent,
    DividerComponent,
    AsyncPipe,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {
  private authService = inject(AuthService);
  private menuService = inject(MenuService);
  private organisationService = inject(OrganisationService);
  private alertsService = inject(AlertsService);
  private router = inject(Router);
  private sanitizer = inject(DomSanitizer);

  readonly AlertStatus = AlertStatus;

  organisationName = computed(() => this.authService.currentUserProfile()?.organisationName);
  availableOrganisations = computed(() => this.authService.currentUserProfile()?.availableOrganisations);
  collapsedMenu = this.menuService.collapsedMenu;
  logo = this.authService.profile.organisationSetupRequired ? signal('assets/svg/bolt-logo-icon.svg') :
    toSignal(this.organisationService.headerLogoLoader
      .pipe(map(b => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(b))))
      .pipe(catchError(() => of('assets/svg/bolt-logo-icon.svg'))));

  alerts$ = this.alertsService.getBellInboxAlert().pipe(shareReplay(1));
  greenAlert$ = this.alerts$.pipe(
    map(alerts => alerts?.every((alert) => alert.indicator === 'green')),
    shareReplay(1)
  );

  ngOnInit():void {
    this.alertsService.readStatusUpdated$.pipe(
      tap(() => {
        this.alerts$ = this.alertsService.getBellInboxAlert().pipe(shareReplay(1));
        this.greenAlert$ = this.alerts$.pipe(
          map(alerts => alerts?.every((alert) => alert.indicator === 'green')),
          shareReplay(1)
        );
      }),
    ).subscribe();
  }

  async changeOrganization(organisationExternalId: string): Promise<void> {
    await this.authService.login(organisationExternalId);
  }

  displayAlert(alertId: string): void {
    void this.router.navigate(['alerts/inbox'], { queryParams: { alertId: alertId } });
  }

  displayInboxAlerts(): void {
    void this.router.navigate(['alerts/inbox']);
  }

  help(): void {
    console.log('Help click');
  }

  async logOut(): Promise<void> {
    await this.authService.logOut();
  }
}
