<app-stepper-form
  header="Company Name Reservation"
  subheader="Form 410B"
  [showSteps]="false"
  [isLoading]="isLoading"
  [steps]="stepsSignal()"
  [currentStep]="currentStep"
  [currentStepIndex]="currentStepIndex"
  [form]="stepperForm"
  [formGroup]="stepperForm"
>
  <div class="step-content">
    <app-stepper-form-description
      formKey="410B"
      [hidden]="currentStep !== StepsEnum.FormDetails"
    ></app-stepper-form-description>

    <section
      class="step-section"
      [hidden]="currentStep !== StepsEnum.CompanyNameDetails"
      [formGroupName]="StepsEnum.CompanyNameDetails"
    >
      <h1 class="h1-heading">{{ steps[currentStep].label }}</h1>

      <div class="form-group">
        <app-input
          label="Proposed new company name"
          formControlName="proposedCompanyName"
        ></app-input>
      </div>

      <div class="form-group">
        <app-yes-no-control
          label="Is proposed name identical to a registered business name?"
          formControlName="isProposedNameIdenticalToRegisteredBusinessName"
        ></app-yes-no-control>
      </div>

      @if (companyNameDetailsForm.controls.isProposedNameIdenticalToRegisteredBusinessName.value === true) {
        <div class="form-group">
          <div class="form-group">
            <app-checkbox
              formControlName="abnConfirmation"
              [label]="abnDeclarationMessage"
            />

            @if (companyNameDetailsForm.controls.abnConfirmation.touched && companyNameDetailsForm.controls.abnConfirmation.errors) {
              <app-validation-error [control]="companyNameDetailsForm.controls.abnConfirmation"/>
            }
          </div>
        </div>

        <div class="form-group">
          <app-abn-masked-input formControlName="businessNameHolderAbn"/>
        </div>
      }

      <div class="form-group">
        <app-radio
          label="Payment Method"
          formControlName="paymentMethod"
          [options]="paymentMethodOptions"
        ></app-radio>
      </div>

    </section>
    <section
      class="step-section"
      [formGroupName]="StepsEnum.ManualProcessing"
      [hidden]="currentStep !== StepsEnum.ManualProcessing">
      <h1 class="h1-heading">{{ steps[currentStep].label }}</h1>

      <div class="form-group">
        <app-yes-no-control
          label="Do you require manual review of your proposed change of company name?"
          formControlName="manualProcessing"
        ></app-yes-no-control>
      </div>

      <div class="form-group" [hidden]="manualProcessingForm.controls.manualProcessing.value !== true">
        <div class="form-group">
          <p class="sub-heading text-gray700">Please provide a reason for the manual review of the application in the
            text box below.</p>
        </div>

        <app-textarea
          label="Manual Processing Reason"
          formControlName="manualProcessingReason"
        ></app-textarea>
      </div>
    </section>
  </div>
</app-stepper-form>
