<div [hidden]="isEstimateLoading">
  <app-list-grid
    [rowData]="estimatedSecurityRegistryRecords"
    [colDefs]="$any(shareCancellationEstimatesColDefs)"
    [suppressRowClickSelection]="true"
    [pagination]="true"
    [paginationPageSize]="20"
    [paginationPageSizeSelector]="true"
    [suppressCellFocus]="true"
  ></app-list-grid>
</div>

<app-loader [loading]="isEstimateLoading"></app-loader>
