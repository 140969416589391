import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Entity } from '../../models/entity';
import { DocumentTypePipe } from '../../pipes/document-type.pipe';
import {
  AgAsicDocumentStatusComponent
} from '../components/common/grid/components/ag-asic-document-status/ag-asic-document-status.component';
import {
  AgDocumentActionsComponent
} from '../components/common/grid/components/ag-document-actions/ag-document-actions.component';
import { DocumentsService } from '../../services/documents.service';
import { DocumentRecord } from '../../models/documentRecord';
import { DocumentStatusPipe } from '../../pipes/enumsPipes/documentStatusPipe';
import { formatDate } from '@angular/common';
import { DocumentStatusEnum } from '../../models/enums/documentStatusEnum';
import { FormGroup } from "@angular/forms";
import {
  ColDef,
  CsvExportParams,
  ExcelExportParams,
  GetContextMenuItems,
  GetRowIdFunc,
  GetRowIdParams,
  GridApi,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  ProcessCellForExportParams,
  ValueFormatterParams
} from "ag-grid-community";
import { FilterOption } from "../../models/gridFilter";
import { CellDataType } from "../../models/enums/agGridEnums";
import { dateComparator } from "../components/common/grid/functions/date-comparator";
import { convertEnumToFilterOption } from "../helpers/enum-to-filter-option.helper";
import { AsicStatus } from "../../models/enums/asicStatus";
import { Router } from "@angular/router";
import { uniqueArrayToFilterOptionHelper } from "../helpers/unique-array-to-filter-option.helper";
import { formTypesList } from "../constants/forms";
import {
  AgDocumentStatusComponent
} from "../components/common/grid/components/ag-document-status/ag-document-status.component";
import { EntityType } from '../../models/enums/entityType';
import {
  AgDocumentNameComponent
} from "../components/common/grid/components/ag-document-name/ag-document-name.component";
import {
  CompanyMistakeCorrection,
  CompanyMistakeCorrectionType
} from "../modals/documents/asic-forms/form492-request-correction/request-correction-change.model";
import { ModalFormsService } from "../../services/modal-forms.service";
import { Company } from "../../models/company";
import {
  Form492RequestCorrectionComponent
} from "../modals/documents/asic-forms/form492-request-correction/form492-request-correction.component";
import { DocumentStepEnum } from '../../models/enums/documentStepEnum';
import { documentStatusToStep } from "../helpers/document-status-to-step";
import { GridComponent } from "../components/common/grid/components/grid/grid.component";
import { NotesComponent } from "../components/notes/notes.component";
import { ConfirmComponent} from "../modals/confirm/confirm.component";
import { catchError, of, switchMap, tap } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ToastrService } from "ngx-toastr";
import { downloadBase64File } from "../../functions/download-base64-file";
import { FilesService } from "../../services/files.service";
import { DocumentSubFolderName } from "../../models/enums/documentFolderNameEnum";
import { ButtonComponent } from "../components/common/button/button.component";

@Component({
  selector: 'app-documents',
  standalone: true,
  templateUrl: './documents.component.html',
  styleUrl: './documents.component.scss',
  imports: [
    GridComponent,
    NotesComponent,
    ButtonComponent,
    NgbDropdown,
    NgbDropdownMenu,
    NgbDropdownToggle
  ],
  providers: [DocumentStatusPipe],
})
export class DocumentsComponent implements OnInit {
  private modalService = inject(NgbModal);
  private documentsSerivice = inject(DocumentsService);
  private filesService = inject(FilesService);
  private documentStatusPipe = inject(DocumentStatusPipe);
  private modalFormsService = inject(ModalFormsService);
  private router = inject(Router);
  private destroyRef = inject(DestroyRef);
  private toastr = inject(ToastrService);

  form!: FormGroup;
  gridApi!: GridApi;
  colDefs: ColDef[] = [];
  excelExportParams = this.exportParams() as ExcelExportParams;
  csvExportParams = this.exportParams() as CsvExportParams;

  readonly pageTitle = 'Documents';
  readonly serverRowModelType: 'serverSide' | 'infinite' | 'clientSide' = 'serverSide';
  readonly defaultPageSize = 20;
  readonly cacheBlockSize = 20
  readonly EntityType = EntityType;
  DocumentStepEnum = DocumentStepEnum

  documentRecords: DocumentRecord[] = [];
  selectedDocuments: DocumentRecord[] = [];
  documentStatusFilterOptions: FilterOption[] = convertEnumToFilterOption(DocumentStatusEnum);
  documentAsicStatusFilterOptions: FilterOption[] = convertEnumToFilterOption(AsicStatus);
  documentFormTypeFilterOptions: FilterOption[] = uniqueArrayToFilterOptionHelper(formTypesList).map(o => {
    o.label = o.label.replace('C:', '');
    return o;
  });
  private readonly documentTypePipe = new DocumentTypePipe();

  notesVisible = false;
  entityForNotes: Entity | undefined | null = null;

  ngOnInit(): void {
    this.setGridConfig();
  }

  setGridConfig(): void {
    this.colDefs = [
      {
        headerName: 'Document Name',
        field: 'documentName',
        flex: 2,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        filter: 'agTextColumnFilter',
        cellRenderer: AgDocumentNameComponent,
        menuTabs: []
      },
      {
        headerName: 'Form',
        field: 'type',
        flex: 1,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.documentFormTypeFilterOptions,
          values: formTypesList
        },
        valueFormatter: (params: ValueFormatterParams<DocumentRecord, string>) => this.documentTypePipe.transform(params.data?.type),
        menuTabs: []
      },
      {
        headerName: 'Description',
        field: 'description',
        flex: 3,
        menuTabs: []
      },
      {
        headerName: 'Document Status',
        field: 'status',
        flex: 1,
        cellRenderer: AgDocumentStatusComponent,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.documentStatusFilterOptions,
          values: Object.values(DocumentStatusEnum).filter((v) => !isNaN(Number(v))),
        },
        menuTabs: []
      },
      {
        headerName: 'ASIC Status',
        field: 'asicStatus',
        flex: 1,
        cellRenderer: AgAsicDocumentStatusComponent,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.documentAsicStatusFilterOptions,
          values: Object.values(AsicStatus).filter((v) => !isNaN(Number(v))),
        },
        menuTabs: []
      },
      {
        headerName: 'Date Created',
        field: 'createdOn',
        flex: 1,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateComparator,
        },
        valueFormatter: (params: ValueFormatterParams<DocumentRecord, Date>) => {
          return formatDate(params.value ?? '', 'dd MMM yyyy', 'en-US');
        },
        cellDataType: CellDataType.DATE,
        menuTabs: []
      },
      {
        width: 120,
        sortable: false,
        cellRenderer: AgDocumentActionsComponent,
        cellRendererParams: {
          openDocument: this.openDocument.bind(this),
          openNotes: this.openNotes.bind(this),
          deleteDocument: this.deleteDocument.bind(this),
          openForm492: this.openForm492.bind(this),
          openForm902: this.openForm902.bind(this),
          downloadDocument: this.downloadDocument.bind(this),
        },
        suppressHeaderMenuButton: true,
        menuTabs: []
      }
    ];
  }

  dataSource: IServerSideDatasource = {
    getRows: (params: IServerSideGetRowsParams) => {
      const request = params.request;
      this.documentsSerivice.getDocumentList(request).subscribe({
        next: result => {
          params.success({
            rowData: result.records,
            rowCount: result.total,
          });
        },
        error: (err) => {
          console.error(err);
          params.fail();
        }
      });
    }
  };

  getRowId: GetRowIdFunc = (params: GetRowIdParams<DocumentRecord>) => params.data.documentId;

  get showMerge(): boolean {
    return this.documentRecords.filter(x => x.checked && x.type == "484").length > 1;
  }

  onSearch(searchText: string): void {
    this.dataSource = {
      getRows: (params: IServerSideGetRowsParams) => {
        const request = params.request;
        this.documentsSerivice.getDocumentList(request, searchText).subscribe({
          next: result => {
            params.success({
              rowData: result.records,
              rowCount: result.total,
            });
          },
          error: (err) => {
            console.error(err);
            params.fail();
          }
        });
      }
    };
  }

  onGridReady(gridApi: GridApi): void {
    this.gridApi = gridApi;
  }

  selectDocuments(documents: DocumentRecord[]): void {
    this.selectedDocuments = documents;
  }

  bulkSendForSigning(): void {
    const documents = JSON.stringify(this.selectedDocuments);
    void this.router.navigate(['bulk-send-documents-for-signing'], { queryParams: { documents }});
  }

  openNotes(document: DocumentRecord): void {
    if (this.modalOpened()) return;
    this.notesVisible = true;
    this.entityForNotes = new Entity({
      entityId: document.entityId,
      name: document.documentName,
      entityNumber: document.entityNumber,
    });
  }

  async openDocument(document: DocumentRecord): Promise<void> {
    const step = documentStatusToStep(document.status);
    await this.router.navigate(['/document', document.documentId],  { queryParams: { step } });
  }

  private deleteDocument(document: DocumentRecord): void {
    const modalRef = this.modalService.open(ConfirmComponent);
    (modalRef.componentInstance as ConfirmComponent).title = 'Delete document';
    (modalRef.componentInstance as ConfirmComponent).message = `Are you sure you would like to delete the selected document - ${document.documentName} ?`;
    (modalRef.componentInstance as ConfirmComponent).confirmText = 'Delete';
    (modalRef.componentInstance as ConfirmComponent).confirm = () => {
      return this.documentsSerivice.deleteDocumentChange(document.documentId).pipe(
        tap(() => {
          this.gridApi?.setServerSideDatasource(this.dataSource);
          this.toastr.success('Document deleted!', 'Success');
        }),
        catchError((error: HttpErrorResponse) => {
          console.error(error);
          this.toastr.error("Error while deleting document", "Error");
          return of(error);
        })
      );
    };

    modalRef.closed.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  downloadDocument(document: DocumentRecord): void {
    this.filesService.getFiles(document.documentId).pipe(
      switchMap(res => {
        if(res.final.length) {
          return this.filesService.downloadFolder(document.documentId, false, DocumentSubFolderName.Final).pipe(
            tap(res => {
              if(res) {
                const currentDate = formatDate(new Date, 'dd_MM_yyyy', 'en-US');
                const fileName = 'Documents_' + currentDate + '.zip';
                downloadBase64File(res, fileName);
                this.toastr.success("Documents have been success downloaded", "Success");
              }
            }),
            catchError((error: HttpErrorResponse) => {
              console.error(error);
              this.toastr.error("Download documents error", "Error");
              return of('ERR');
            })
          )
        }

        this.toastr.warning("No documents for download", "Warning");

        return of(res);
      }),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        this.toastr.error("Download documents error", "Error");
        return of('ERR');
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  private exportParams() {
    const documentStatusPipe = this.documentStatusPipe;

    return {
      processCellCallback(params: ProcessCellForExportParams): string {
        const value: unknown = params.value;
        const documentData = params.node?.data as DocumentRecord;
        if (params.column.getColId() === 'status') {
          return documentStatusPipe.transform(documentData.status);
        } else if (params.column.getColId() === 'createdOn') {
          return formatDate(documentData.createdOn ?? '', 'dd MMM yyyy', 'en-US');
        }

        return value as string;
      },
      sheetName: 'Documents',
      fileName: 'Documents ' + formatDate(new Date(), 'dd-MM-yyyy', 'en-US')
    }
  }

  getContextMenuItems(gridApi: GridApi & {defaultItems: unknown[]; node: {data: {documentId: string}}}): GetContextMenuItems {
    const items = gridApi.defaultItems.slice();

    return ([
      {
        name: 'Open Document Detail in New Tab',
        action: () => {
          const documentId = gridApi.node.data.documentId;
          const url = `/document/${documentId}`;
          window.open(url, '_blank');
        }
      },
      'separator',
      ...items,
    ]) as unknown as GetContextMenuItems
  }

  private openForm492(documentRecord: DocumentRecord): void {
    if (this.modalOpened()) return;
    const company = new Company({ entityId: documentRecord.entityId, })
    const instance = this.modalFormsService.openModalWithCompany(new CompanyMistakeCorrection(), company).componentInstance as Form492RequestCorrectionComponent;
    instance.documentId = documentRecord.documentId;
  }

  private openForm902(documentRecord: DocumentRecord): void {
    if (this.modalOpened()) return;
    const company = new Company({ entityId: documentRecord.entityId, })
    const instance = this.modalFormsService.openModalWithCompany(new CompanyMistakeCorrection({ correctionType: CompanyMistakeCorrectionType.F902 }), company).componentInstance as Form492RequestCorrectionComponent;
    instance.documentId = documentRecord.documentId;
  }

  get modalOpened() {
    return this.modalFormsService.modalOpened;
  }
}

