<app-stepper-form
  header="Request to Withdraw a Lodged Document"
  subheader="Form 106"
  [showSteps]="false"
  [isLoading]="isLoading"
  [steps]="steps"
  [currentStep]="currentStep"
  [currentStepIndex]="currentStepIndex"
  [form]="stepperForm"
  [formGroup]="stepperForm"
>
  <div class="step-content">
    <app-stepper-form-description
      formKey="106"
      [hidden]="currentStep !== WithdrawLodgedDocumentStepsEnum.FormDetails"
    ></app-stepper-form-description>

    <section
      class="step-section"
      [formGroupName]="WithdrawLodgedDocumentStepsEnum.DocumentDetails"
      [hidden]="currentStep !== WithdrawLodgedDocumentStepsEnum.DocumentDetails"
    >
      <h1 class="h1-heading">Company Details</h1>

      <div class="form-group">
        <app-input
          label="Document number of previously lodged document"
          formControlName="documentNumber"
          [customErrors]="documentNumberCustomError"
        ></app-input>
      </div>

      <div class="form-group">
        <app-date-picker
          label="Date of lodgement of document being amended"
          formControlName="documentLodgementDate"
          [minDate]="minDate"
          [maxDate]="maxDate"
        ></app-date-picker>
      </div>
    </section>
    <section
      class="step-section"
      [formGroupName]="WithdrawLodgedDocumentStepsEnum.AmendmentDetails"
      [hidden]="currentStep !== WithdrawLodgedDocumentStepsEnum.AmendmentDetails"
    >
      <h1 class="h1-heading">Amendment Details</h1>

      <div class="form-group">
        <app-select
          label="Reason why this document should be withdrawn"
          formControlName="reason"
          [options]="documentWithdrawReasonOptions"
          [valuePrimitive]="true"
        ></app-select>
      </div>

      <div [hidden]="this.stepperForm.controls[WithdrawLodgedDocumentStepsEnum.AmendmentDetails].controls.reason.value != DocumentWithdrawReason.Duplicated">
        <app-input
          label="Document number of previously lodged document"
          formControlName="duplicateDocumentNumber"
          [customErrors]="documentNumberCustomError"
        ></app-input>
      </div>

      <div [hidden]="this.stepperForm.controls[WithdrawLodgedDocumentStepsEnum.AmendmentDetails].controls.reason.value != DocumentWithdrawReason.Incorrect">
        <div class="form-group">
          <p class="sub-heading">
            Provide specific details below. You will need to address the following when providing
            these details:
          </p>
          <ul>
            <li>how did the error occur</li>
            <li>what processes or action was taken in determining that the lodged document was incorrect, and</li>
            <li>what contact has been made with all parties affected by the error?</li>
          </ul>
        </div>
        <div class="form-group">
          <app-textarea
            label="Specific Details"
            formControlName="withdrawalReasonDetails"
          ></app-textarea>
        </div>
      </div>
    </section>
  </div>
</app-stepper-form>

