import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Company } from '../../../../models/company';
import { Document } from '../../../../models/document';
import { CompanyChangeData } from '../../../../models/enums/companyChangeData';
import { EntitySecurityIssue } from '../../../../models/securityChange';
import { SecurityType } from '../../../../models/securityType';
import { SelectOption } from '../../../../models/selectOptions';
import { ChangeDictionaryHelper } from '../../../../models/shared/change-dictionary-helper.model';
import { SelectOptionGroup } from '../../../components/common/select-groups/select-groups.component';
import { ShareIssueComponent } from './share-issue.component';

export enum ShareIssueStepsEnum {
  FormDescription = 0,
  Shareholders = 1,
  ShareIssuesAndCertificateDetails = 2,
  Estimate = 3
}

export class CompanySecurityIssue extends EntitySecurityIssue<Company> {
  static override readonly $type = 'CompanySecurityIssue';

  issueForCashConsidarationOnly: boolean;
  nonCashConsiderationDetails: string;
  sharesWereWrittenUnderContract: boolean;
  consolidateCertificate: boolean;

  constructor(data: Partial<CompanySecurityIssue> = {}) {
    super(data);

    this.issueForCashConsidarationOnly = data.issueForCashConsidarationOnly ?? false;
    this.nonCashConsiderationDetails = data.nonCashConsiderationDetails ?? '';
    this.sharesWereWrittenUnderContract = data.sharesWereWrittenUnderContract ?? false;
    this.consolidateCertificate = data.consolidateCertificate ?? false;
  }

  override toDictionary(): { key: string; value: string }[] {
    const dict = new ChangeDictionaryHelper();

    // Date of Issue
    // Shareholder Name
    // Are the shares owned on behalf of another individual, company, trust or fund?
    // Shares are owned by
    // Share Type
    // Number of Shares
    // Total Amount Paid
    // Total Amount Unpaid
    // Consideration Type
    //   No cash consideration details
    // Were some or all of the shares issued under a written contract?
    // Certificate Consolidation
    // Certificate Number

    dict
      .addDate('Date of Issue', this.issue.transactionDate)
      .addEmptyLine()
      .addIds('Shareholder Name', this.issue.relationshipIds ?? [])
      .addWithBeneficialOwnerPrefix('Shares are owned on behalf of', this.issue.relationshipIds[0])
      .add('Share Type', SecurityType.buildIdentifier(this.issue.securityType))
      .add('Number of Shares', this.issue.numberIncrease)
      .addMoney('Total Amount Paid', this.issue.paidIncrease)
      .addMoney('Total Amount Unpaid', this.issue.unpaidIncrease)
      .add('Consideration Type', ConsiderationTypesOptions.find((option) => option.value === this.issueForCashConsidarationOnly)?.label);

    if (!this.issueForCashConsidarationOnly) {
      dict.add('No cash consideration details', this.nonCashConsiderationDetails);
    }

    dict
      .addYesNo('Were some or all of the shares issued under a written contract?', this.sharesWereWrittenUnderContract)
      .add('Certificate Consolidation', CertificateConsolidationOptions.find(option => option.value === this.consolidateCertificate)?.label)
      .add('Certificate Number', this.issue.shareCertificateNumber);

    return dict.dictionary;
  }

  override fillModal(modalRef: NgbModalRef, document: Document, actualCompany: Company): NgbModalRef {
    modalRef = this.fillModalWithCompany(modalRef, actualCompany, true);
    (modalRef.componentInstance as ShareIssueComponent).companyChangeData.documentId = document.documentId;
    return modalRef;
  }

  override fillModalWithCompany(modalRef: NgbModalRef, company: Company, isEdit: boolean): NgbModalRef {
    const instance = modalRef.componentInstance as ShareIssueComponent;
    instance.isEdit = isEdit;
    instance.companyChangeData = new CompanyChangeData(company);
    instance.formModel = this;

    return modalRef;
  }

  override prepareToRequest(): CompanySecurityIssue {
    return {
      ...this,
      ...super.prepareToRequest(),
      issue: this.issue.prepareToRequest()
    };
  }
}

export enum ShareholderTypesEnum {
  ExistingShareholder = 0,
  Individual = 1,
  Corporate = 2,
  JointShareholder = 3
}

export const newShareholderTypesSelectOptions: SelectOption[] = [
  {
    label: 'Individual',
    value: ShareholderTypesEnum.Individual,
  },
  {
    label: 'Corporate',
    value: ShareholderTypesEnum.Corporate,
  },
  {
    label: 'Joint Shareholder',
    value: ShareholderTypesEnum.JointShareholder,
  }
];

export const shareholderTypesOptionGroups: SelectOptionGroup[] = [
  {
    name: '',
    hideIcon: true,
    hideName: true,
    options: [
      {
        label: 'Existing Shareholder',
        value: ShareholderTypesEnum.ExistingShareholder,
      },
    ]
  },
  {
    name: 'New Shareholder',
    hideIcon: false,
    hideName: false,
    options: newShareholderTypesSelectOptions
  }
];

export const ConsiderationTypesOptions: SelectOption[] = [
  {
    label: 'Cash',
    value: true
  },
  {
    label: 'Other',
    value: false
  }
];

export const CertificateConsolidationOptions: SelectOption[] = [
  {
    label: 'Create a separate share certificate for the newly received shares',
    value: false
  },
  {
    label: 'Create a share certificate for the consolidated shares and cancel existing certificate',
    value: true
  }
];
