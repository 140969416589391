<div class="d-flex justify-content-between">
  <div [ngClass]="{'warning': hasIssue}">{{shareClass}}</div>

  @if(hasIssue) {
    <div
      class="warning-icon"
      container="body"
      [tooltipClass]="'share-tooltip'"
      [placement]="'top'"
      [ngbTooltip]="issueMessage"
    >
      <i class="icon ic-alert-triangle"></i>
    </div>
  }
</div>
