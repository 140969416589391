import { Pipe, PipeTransform } from '@angular/core';
import { CompanyStatus } from '../../models/enums/companyEnums';
@Pipe({
  standalone: true,
  name: 'companyStatus'
})
export class CompanyStatusPipe implements PipeTransform {
  transform(value: CompanyStatus | undefined): string {
    if(value != undefined)
      return CompanyStatus[value].replace('-', ' ');

    return '-';
  }
}
