<div
  class="asic-status-tag"
  [ngClass]="{
    'not-lodged': AsicStatus.NotLodged === status,
    'pending': AsicStatus.Pending === status,
    'waiting-for-asic': AsicStatus.WaitingForASIC === status,
    'validated': AsicStatus.Validated === status,
    'lodged': AsicStatus.Lodged === status,
    'future-lodgement': AsicStatus.FutureLodgement === status,
    'rejected': AsicStatus.Rejected === status,
  }"
>
  <span>{{ status | asicStatus }}</span>
</div>
