import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DatepickerHelper } from '../../../../../custom-form-validators/date-picker-form-validators';
import { setControlDisabled } from '../../../../../functions/set-control-disabled';
import { Document } from '../../../../../models/document';
import { WithdrawLodgedDocumentStepsEnum } from '../../../../../models/enums/WithdrawLodgedDocumentStepsEnum';
import { SelectOption } from '../../../../../models/selectOptions';
import {
  asicDocumentNumberValidators,
  documentNumberCustomError
} from '../../../../../validators/document-number.validator';
import { DatePickerComponent } from '../../../../components/common/date-picker/date-picker.component';
import { InputComponent } from '../../../../components/common/input/input.component';
import { SelectComponent } from '../../../../components/common/select/select.component';
import { TextareaComponent } from '../../../../components/common/textarea/textarea.component';
import { BaseStepperFormComponent } from '../../../stepper-form/base-stepper-component/base-stepper-form.component';
import {
  StepperFormDescriptionComponent
} from '../../../stepper-form/stepper-form-description/stepper-form-description.component';
import { StepperFormComponent } from '../../../stepper-form/stepper-form.component';
import {
  CompanyWithdrawLodgedDocumentChange,
  DocumentWithdrawReason,
  DocumentWithdrawReasonOptions,
} from './withdraw-lodged-document-form.model';

@Component({
  selector: 'app-form106-withdraw-lodged-document',
  templateUrl: './form106-withdraw-lodged-document.component.html',
  styleUrl: './form106-withdraw-lodged-document.component.scss',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    StepperFormComponent,
    StepperFormDescriptionComponent,
    InputComponent,
    DatePickerComponent,
    SelectComponent,
    TextareaComponent
  ]
})
export class Form106WithdrawLodgedDocumentComponent extends BaseStepperFormComponent<WithdrawLodgedDocumentStepsEnum, CompanyWithdrawLodgedDocumentChange> {
  WithdrawLodgedDocumentStepsEnum = WithdrawLodgedDocumentStepsEnum;
  DocumentWithdrawReason = DocumentWithdrawReason;

  minDate!: NgbDateStruct;
  maxDate!: NgbDateStruct;
  readonly documentWithdrawReasonOptions: SelectOption[] = DocumentWithdrawReasonOptions;
  readonly documentNumberCustomError = documentNumberCustomError;

  override stepperForm = new FormGroup({
    [WithdrawLodgedDocumentStepsEnum.FormDetails]: new FormGroup({}),
    [WithdrawLodgedDocumentStepsEnum.DocumentDetails]: new FormGroup({
      documentNumber: new FormControl<string>('', asicDocumentNumberValidators()),
      documentLodgementDate: new FormControl<Date | null>(null, [Validators.required]),
    }),
    [WithdrawLodgedDocumentStepsEnum.AmendmentDetails]: new FormGroup({
      reason: new FormControl<DocumentWithdrawReason | null>(null, [Validators.required]),
      duplicateDocumentNumber: new FormControl<string>('', asicDocumentNumberValidators()),
      withdrawalReasonDetails: new FormControl<string>('', [Validators.required]),
    }),
  });

  constructor() {
    super();
    this.setupSteps(WithdrawLodgedDocumentStepsEnum);
    this.redirectAfterSubmit = true;
  }

  ngOnInit(): void {
    this.minDate = DatepickerHelper.getStructFromDate(DatepickerHelper.getDateOf1990January());
    this.maxDate = DatepickerHelper.getNextNYearsStruct(10);
    this.companyDetailsForm.controls.documentNumber.setValue(this.formModel.documentNumber ?? null);
    this.companyDetailsForm.controls.documentLodgementDate.setValue(DatepickerHelper.getToday());


    this.listenDocumentWithdrawReasonChange();
    this.setupChange();
  }

  override setupChange(): void {
    if (!this.isEdit) {
      return;
    }

    this.companyDetailsForm.patchValue(this.formModel);
    this.amendmentDetailsForm.patchValue({
      ...this.formModel as any,
    });
  }

  override buildDocument(): Document | null {
    const documentLodgementDate = this.companyDetailsForm.value.documentLodgementDate as Date | string;
    const date = documentLodgementDate instanceof Date ? documentLodgementDate : new Date(documentLodgementDate);

    const change = new CompanyWithdrawLodgedDocumentChange({
      ...this.amendmentDetailsForm.value as Partial<CompanyWithdrawLodgedDocumentChange>,
      ...this.companyDetailsForm.value as Partial<CompanyWithdrawLodgedDocumentChange>,
      documentLodgementDate: DatepickerHelper.buildDateString(date),
      changeDate: DatepickerHelper.buildTodayDateString(),
      reason: this.amendmentDetailsForm.value.reason as DocumentWithdrawReason,
    });

    try {
      return new Document({
        changes: [change],
        type: 'c:106',
        entityId: this.companyChangeData.entityId,
        documentId: this.companyChangeData?.documentId,
      });
    } catch (error) {
      console.warn(error);
      return null;
    }
  }

  override afterSubmit(): void {
    this.amendmentDetailsForm.controls.reason.patchValue(this.amendmentDetailsForm.controls.reason.value ?? null);
  }

  private listenDocumentWithdrawReasonChange(): void {
    this.amendmentDetailsForm.controls.reason.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((reason) => {
        setControlDisabled(this.amendmentDetailsForm.controls.withdrawalReasonDetails, reason == DocumentWithdrawReason.Duplicated);
        setControlDisabled(this.amendmentDetailsForm.controls.duplicateDocumentNumber, reason == DocumentWithdrawReason.Incorrect);
      });
  }

  get companyDetailsForm() {
    return this.stepperForm.controls[WithdrawLodgedDocumentStepsEnum.DocumentDetails];
  }

  get amendmentDetailsForm() {
    return this.stepperForm.controls[WithdrawLodgedDocumentStepsEnum.AmendmentDetails];
  }
}
