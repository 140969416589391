import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appSpinner]',
  standalone: true
})
export class AppSpinnerDirective {

  private loader = "<i class=\"fad fa-spinner-third fa-spin\"></i>";
  private element: HTMLElement;
  private originalHtml?: string;

  constructor(private el: ElementRef) {
    this.element = (el.nativeElement as HTMLElement);
  }

  @Input('appSpinner')
  set appSpinner(value: boolean) {
    if (!this.originalHtml) {
      this.originalHtml = this.element.innerHTML;
    }

    if (value === true) {
      const width: number = this.element.offsetWidth;
      this.element.style.width = `${width}px`;
      this.element.innerHTML = this.loader;
      this.element.setAttribute("disabled", "");
    }

    if (value === false) {
      this.element.style.width = "auto";
      this.element.innerHTML = this.originalHtml;
      this.element.removeAttribute("disabled");
    }
  }
}
