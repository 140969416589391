import { Injectable, signal } from '@angular/core';
import { GridFilterModel } from "../models/gridFilter";

@Injectable({
  providedIn: 'root'
})
export class GridService {
  private gridFilterModelState = signal<GridFilterModel>({})

  constructor() { }

  getGridFilterModelState(): GridFilterModel {
    return this.gridFilterModelState();
  }

  setGridFilterModelState(filterModel: GridFilterModel): void {
    this.gridFilterModelState.set(filterModel);
  }
}
