<div class="uploaded-list">
  @for(document of documentsForSign; track $index) {
    <div class="uploaded-row">
      <div class="name-wrapper">
        <i class="icon ic-xs ic-file"></i>
        <div class="file-name" (click)="downloadFile(document.name)">{{document.name }}</div>
      </div>
      <div class="action-wrapper">
        <i class="icon ic-xs ic-download-file" (click)="downloadFile(document.name)"></i>
      </div>
    </div>
  }
</div>
