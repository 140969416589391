<app-masked-input
  mask="__ ___ ___ ___"
  [placeholder]="placeholder"
  [label]="label"
  [link]="link"
  [text]="text"
  [switchBtnText]="switchBtnText"
  [helperText]="helperText"
  [shouldShowErrors]="shouldShowErrors"
  [readonly]="readonly"
  [validationError]="validationError"
  [checkedState]="checkedState"
  [warningState]="warningState"
  [formControl]="control"
  [customErrors]="customErrors"
  (switchBtnClick)="switchBtnClick.emit($event)"
></app-masked-input>
