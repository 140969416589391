<div [class.changes-hidden]="!pendingButtonState">
  @if(!previousOfficeholderName && officeholderName && appointmentDate !== previousAppointmentDateDate && !cessationDate) {
    <div class="prop current">{{ officeholderName }}</div>
  } @else if(previousOfficeholderName && previousOfficeholderName !== officeholderName) {
    <div class="prop previous">{{ previousOfficeholderName }}</div>
    <div class="prop current">{{ officeholderName }}</div>
  } @else if(cessationDate !== previousCessationDate && previousOfficeholderName === officeholderName) {
    <div class="prop cease">{{ previousOfficeholderName }}</div>
  } @else {
    <div class="prop">{{ previousOfficeholderName | defaultValue }}</div>
  }
</div>
