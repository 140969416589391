<button
  class="attached-file-label no-style-button"
  [class.readonly]="readonly()"
  [class.disabled]="disabled()"
  [tabindex]="readonly() ? -1 : 0"
  (click)="fileLabelClick()"
>
  <div class="title">
    @if (isLoading()) {
      <div><i class="icon ic-sm ic-refresh"></i></div>
    } @else {
      <div><i class="icon ic-sm ic-file"></i></div>
    }
    <div class="file-name">{{ filename() }}</div>
  </div>
  <div class="controls">
    @if (!hideDownloadBtn()) {
      <app-ag-action-icon-button
        iconClass="ic-sm ic-download-file"
        [disabled]="disabled()"
        (action)="onDownloadFile(); $event.preventDefault(); $event.stopPropagation();"
      ></app-ag-action-icon-button>
    }

    @if (!readonly() && !hideDeleteBtn()) {
      <app-ag-action-icon-button
        iconClass="ic-sm ic-trash"
        [disabled]="disabled() || readonly()"
        (action)="onDeleteFile(); $event.preventDefault(); $event.stopPropagation();"
      ></app-ag-action-icon-button>
    }
  </div>
</button>
