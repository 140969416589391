import { Component, OnInit } from '@angular/core';
import { GetRowIdFunc, GetRowIdParams, IServerSideDatasource, ValueFormatterParams } from "ag-grid-community";
import { ButtonComponent } from "../../components/common/button/button.component";
import { GridComponent } from "../../components/common/grid/components/grid/grid.component";
import { BaseAnnualStatements } from "../base-annual-statements";
import { AnnualStatementPageFilter } from "../../../models/enums/annualStatementEnums";
import {
  AgAnnualNameAcnComponent
} from "../../components/common/grid/components/annual/ag-annual-name-acn/ag-annual-name-acn.component";
import { dateComparator } from "../../components/common/grid/functions/date-comparator";
import { AnnualRecord } from "../../../models/annualRecord";
import { formatDate } from "@angular/common";
import { CellDataType } from "../../../models/enums/agGridEnums";
import {
  AgCompanyDebtComponent
} from "../../components/common/grid/components/ag-company-debt/ag-company-debt.component";
import {
  AgAnnualActionsComponent
} from "../../components/common/grid/components/annual/ag-annual-actions/ag-annual-actions.component";
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-annual-not-received',
  standalone: true,
  imports: [
    ButtonComponent,
    GridComponent,
    NgbDropdown,
    NgbDropdownMenu,
    NgbDropdownToggle
  ],
  templateUrl: './annual-not-received.component.html',
  styleUrls: ['./annual-not-received.component.scss', '../base-annual-statements.scss']
})
export class AnnualNotReceivedComponent extends BaseAnnualStatements implements OnInit {
  readonly pageTitle = 'Not Received';

  override pageFilter = AnnualStatementPageFilter.NotReceived;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.setGridConfig();
  }

  override dataSource: IServerSideDatasource = this.getDataSource(this.pageFilter, '');

  override onSearch(searchText: string): void {
    this.dataSource = this.getDataSource(this.pageFilter, searchText);
  }

  setGridConfig(): void {
    this.colDefs = [
      {
        headerName: 'Company Name & ACN',
        exportColumnName: 'Company Name',
        field: 'companyName',
        width: 200,
        flex: 1,
        editable: false,
        sort: 'asc',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        cellRenderer: AgAnnualNameAcnComponent,
        menuTabs: ['generalMenuTab'],
      },
      {
        headerName: '',
        exportColumnName: 'ACN',
        field: 'acn',
        width: 100,
        flex: 0.5,
        menuTabs: [],
        hide: true
      },
      {
        headerName: 'Partner',
        field: 'partnerManagerName',
        flex: 0.5,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.partnerManagerFilterOptions,
          values: this.profileUserNameList,
        },
        menuTabs: [],
      },
      {
        headerName: 'Review Date',
        field: 'reviewDate',
        width: 100,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateComparator,
        },
        valueFormatter: (params: ValueFormatterParams<AnnualRecord, Date>) => {
          return formatDate(params.value ?? '', 'dd MMM yyyy', 'en-US');
        },
        cellDataType: CellDataType.DATE,
        menuTabs: [],
      },
      {
        headerName: 'Debt',
        field: 'debt',
        flex: 0.5,
        cellRenderer: AgCompanyDebtComponent,
        menuTabs: [],
      },
      {
        width: 150,
        sortable: false,
        suppressHeaderMenuButton: true,
        cellRenderer: AgAnnualActionsComponent,
        cellRendererParams: {
          view: this.view.bind(this),
          send: this.send.bind(this),
          edit: this.edit.bind(this),
          downloadDocuments: this.downloadDocuments.bind(this),
          archive: this.archive.bind(this),
          sendReminder: this.sendReminder.bind(this),
          sendPaymentReminder: this.sendPaymentReminder.bind(this),
          regenerate: this.regenerate.bind(this),
          markSent: this.markSent.bind(this),
          markSigned: this.markSigned.bind(this),
        },
        menuTabs: []
      }
    ];
  }
}
