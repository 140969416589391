export enum AlertStatus {
  New,
  Postponed,
  Archived,
  Ignored
}

export enum ResolvedStatus
{
	NotResolved,
	Resolved,
	ResolvedManually,
  Dismissed
}

export enum AlertSeverity {
  Low,
  Moderate,
  Critical
}

export enum AlertType {
  DebtReports,
	ValidationReport,
	AsicInvoice,
	ChangeOfNameCertificate,
	CompanyStatement,
	AsicDataIssue,
	IdentifiedExternalChanges,
	XpmSyncIssue,
	Others
}

export enum ActionType {
  AsicForm,
  ApiCall,
	IntegrationChange,
  Manual,
  UploadDocuments,
  AdjustSecurities
}
