<div class="card">
  <app-page-title
    header="Settings"
    iconClass="ic-settings"
  />

  <ul class="blocks-list list-unstyled">
    @for (linksBlock of linkBlocks; track linksBlock) {
      <li class="block-item">
        <p class="block-title hide-empty">{{ linksBlock.title }}</p>

        <ul class="links-list list-unstyled bulk-wrapper">
          @for (link of linksBlock.links; track link) {
            <li class="link-item">
              <a
                class="link bulk-item"
                [relativeTo]="null"
                [routerLink]="link.disabled ? null : link.path"
                [class.disabled]="link.disabled"
              >
                <div>
                  <i [class]="'icon ic-md ' + link.icon"></i>
                </div>

                <div class="d-flex flex-column gap-12">
                  <div class="title">{{ link.title }}</div>
                  <div class="description hide-empty">{{ link.description }}</div>
                </div>
              </a>
            </li>
          }
        </ul>
      </li>
    }
  </ul>
</div>
