export class AnnatureConnection {
   readonly Type: string = 'AnnatureConnection';
   accountId: string;
   clientId: string;
   clientKey: string;

   constructor(data: Partial<AnnatureConnection> = {}) {
      this.accountId = data.accountId ?? '';
      this.clientId = data.clientId ?? ''; 
      this.clientKey = data.clientKey ?? '';
   }
}