import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'as'
})
export class AsPipe implements PipeTransform {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transform<T>(value: unknown, _type: new () => T): T {
    return value as T;
  }

}