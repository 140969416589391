import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DatepickerHelper } from '../../../../../custom-form-validators/date-picker-form-validators';
import { enumToSelectOptions } from '../../../../../functions/enums-to-list-formatter';
import { Company } from '../../../../../models/company';
import { Document } from '../../../../../models/document';
import { EntityChangeData } from '../../../../../models/entityChangeData';
import { CompanyChangeData } from '../../../../../models/enums/companyChangeData';
import { SelectOption } from '../../../../../models/selectOptions';
import { ChangeDictionaryHelper } from '../../../../../models/shared/change-dictionary-helper.model';
import { FormDividendStatementComponent } from './form-dividend-statement.component';

export enum DividendStatementStepsEnum {
  FormDescription = 0,
  DistributionDetails = 1,
  DistributionAmount = 2,
  BeneficialOwnersDetails = 3,
  Estimate = 4,
}

export enum DividendType {
  Final = 0,
  Interim = 1
}

export enum PaymentType {
  Cash = 0,
  LoanAccountCredit = 1
}

export const paymentTypeOptions = enumToSelectOptions(PaymentType);

export const taxRateForFrankingPurposes: SelectOption[] = [0.25, 0.26, 0.275, 0.285, 0.30]
  .map((value) => ({
    label: `${ Math.ceil(value * 1000) / 10 }%`,
    value
  }));

export class CompanyDividendStatement extends EntityChangeData {
  static override readonly $type = 'Dividend Statement';
  declarationDate: Date;
  paymentDate: Date;
  dividendType: DividendType;
  paymentType: PaymentType;
  equalDividendsForAllShareClasses: boolean;
  dividendsPerShareClassIfNotEqual: Record<string, number> = {};
  percentageOfDividendsFrankedIfNotEqual: Record<string, number> = {};
  percentageOfDividendsFranked: number;
  amountOfDistribution: number;
  companyTaxApplied: number;

  constructor(data: Partial<CompanyDividendStatement> = {}) {
    super(data);

    this.declarationDate = data.declarationDate ?? new Date();
    this.paymentDate = data.paymentDate ?? new Date();
    this.dividendType = data.dividendType ?? DividendType.Final;
    this.paymentType = data.paymentType ?? PaymentType.Cash;
    this.equalDividendsForAllShareClasses = data.equalDividendsForAllShareClasses ?? false;
    this.dividendsPerShareClassIfNotEqual = data.dividendsPerShareClassIfNotEqual ?? {};
    this.percentageOfDividendsFrankedIfNotEqual = data.percentageOfDividendsFrankedIfNotEqual ?? {};
    this.percentageOfDividendsFranked = data.percentageOfDividendsFranked ?? 0;
    this.amountOfDistribution = data.amountOfDistribution ?? 0;
    this.companyTaxApplied = data.companyTaxApplied ?? 0;
  }

  toDictionary(): { key: string; value: string; }[] {
    const dict = new ChangeDictionaryHelper();

    dict
      .addDate('Date of Declaration', this.declarationDate)
      .addDate('Proposed Payment Date', this.paymentDate)
      .add('Payment Type', PaymentType[this.paymentType])
      .add('Dividend Type', DividendType[this.dividendType])
      .addYesNo('Are dividends split equally between all share classes?', this.equalDividendsForAllShareClasses);

    if (this.equalDividendsForAllShareClasses) {
      dict
        .add('Amount of Distribution', this.amountOfDistribution)
        .add('What percentage of the distribution is franked?', this.percentageOfDividendsFranked)
        .add('What company tax rate to be applied for franking purposes?', this.companyTaxApplied);
    }

    return dict.dictionary;
  }

  fillModal(modalRef: NgbModalRef, document: Document, actualCompany: Company): NgbModalRef {
    modalRef = this.fillModalWithCompany(modalRef, actualCompany, true);
    (modalRef.componentInstance as FormDividendStatementComponent).companyChangeData.documentId = document.documentId;
    return modalRef;
  }

  fillModalWithCompany(modalRef: NgbModalRef, company: Company, isEdit: boolean): NgbModalRef {
    const instance = modalRef.componentInstance as FormDividendStatementComponent;

    instance.isEdit = isEdit;
    instance.companyChangeData = new CompanyChangeData(company);
    instance.formModel = structuredClone(this);

    return modalRef;
  }

  override prepareToRequest(): CompanyDividendStatement {
    return {
      ...this,
      ...super.prepareToRequest(),
      declarationDate: DatepickerHelper.buildDateString(this.declarationDate),
      paymentDate: DatepickerHelper.buildDateString(this.paymentDate),
    };
  }
}
