import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Company } from '../../../models/company';
import { Document } from '../../../models/document';
import { EntityChangeData } from '../../../models/entityChangeData';
import { CompanyChangeData } from '../../../models/enums/companyChangeData';
import { SelectOption } from '../../../models/selectOptions';
import { ChangeDictionaryHelper } from '../../../models/shared/change-dictionary-helper.model';
import { Form281Component } from './form281.component';

export enum ShareBuyBackStepsEnum {
  FormDescription = 0,
  TheChange = 1,
}

export class CompanyForm281 extends EntityChangeData {
  static override readonly $type = 'CompanyForm281';
  shareBuyBackType: ShareBuyBackType;
  marketBuyBackFrom: Date | null;
  marketBuyBackTo: Date | null;
  proposedDateBuyBackAgreementEnter: Date | null;
  proposedDatePassingResolutionBuyBackApprove: Date | null;

  constructor(data: Partial<CompanyForm281> = {}) {
    super(data);

    // TODO: Add ChangeType
    this.shareBuyBackType = data.shareBuyBackType ?? ShareBuyBackType.EmployeeWithin;
    this.marketBuyBackFrom = data.marketBuyBackFrom ?? null;
    this.marketBuyBackTo = data.marketBuyBackTo ?? null;
    this.proposedDateBuyBackAgreementEnter = data.proposedDateBuyBackAgreementEnter ?? null;
    this.proposedDatePassingResolutionBuyBackApprove = data.proposedDatePassingResolutionBuyBackApprove ?? null;
  }


  override toDictionary(): { key: string; value: string; }[] {
    const dict = new ChangeDictionaryHelper();

    dict.add('Type of share buy-back',  shareBuyBackTypeDictionary[this.shareBuyBackType]);

    if (this.shareBuyBackType === ShareBuyBackType.EmployeeWithin) {
      // Employee share scheme buy-back (within 10/12 limit)

      dict.addDate('Proposed date for buy-back agreement to be entered into', this.proposedDateBuyBackAgreementEnter);
    } else if (this.shareBuyBackType === ShareBuyBackType.EmployeeOver) {
      // Employee share scheme buy-back (over 10/12 limit)

      dict
        .addDate('Proposed date for buy-back agreement to be entered into', this.proposedDateBuyBackAgreementEnter)
        .addDate('Proposed date for passing the resolution to approve the buy-back', this.proposedDatePassingResolutionBuyBackApprove);

    } else if (this.shareBuyBackType === ShareBuyBackType.MarketWithin) {
      // On-market buy-back (within 10/12 limit)

      dict
        .addDate('From date', this.marketBuyBackFrom)
        .addDate('To date', this.marketBuyBackTo);
    } else if (this.shareBuyBackType === ShareBuyBackType.MarketOver) {
      // On-market buy-back (over 10/12 limit)

      dict
        .addDate('From date', this.marketBuyBackFrom)
        .addDate('To date', this.marketBuyBackTo)
        .addDate('Proposed date for passing the resolution to approve the buy-back', this.proposedDatePassingResolutionBuyBackApprove);
    } else if (this.shareBuyBackType === ShareBuyBackType.EqualWithin) {
      // Equal access scheme buy-back (within 10/12 limit)

      dict.addDate('Proposed date for buy-back agreement to be entered into', this.proposedDateBuyBackAgreementEnter);
    } else if (this.shareBuyBackType === ShareBuyBackType.EqualOver) {
      // Equal access scheme buy-back (over 10/12 limit)

      dict
        .addDate('Proposed date for buy-back agreement to be entered into', this.proposedDateBuyBackAgreementEnter)
        .addDate('Proposed date for passing the resolution to approve the buy-back', this.proposedDatePassingResolutionBuyBackApprove);
    } else if (this.shareBuyBackType === ShareBuyBackType.Selective) {
      // Selective buy-back

      dict
        .addDate('Proposed date for buy-back agreement to be entered into', this.proposedDateBuyBackAgreementEnter)
        .addDate('Proposed date for passing the resolution to approve the buy-back', this.proposedDatePassingResolutionBuyBackApprove);
    }

    return dict.dictionary;
  }

  override fillModal(modalRef: NgbModalRef, document: Document, actualCompany: Company): NgbModalRef {
    modalRef = this.fillModalWithCompany(modalRef, actualCompany, true);
    (modalRef.componentInstance as Form281Component).companyChangeData.documentId = document.documentId;
    return modalRef;
  }

  override fillModalWithCompany(modalRef: NgbModalRef, company: Company, isEdit: boolean): NgbModalRef {
    const instance = modalRef.componentInstance as Form281Component;

    instance.isEdit = isEdit;
    instance.companyChangeData = new CompanyChangeData(company);
    instance.formModel = structuredClone(this);

    return modalRef;
  }
}

export enum ShareBuyBackType {
  EmployeeWithin = 0,
  EmployeeOver = 1,
  MarketWithin = 2,
  MarketOver = 3,
  EqualWithin = 4,
  EqualOver = 5,
  Selective = 6
}

export const shareBuyBackTypeDictionary: Record<ShareBuyBackType, string> = {
  [ShareBuyBackType.EmployeeWithin]: 'Employee share scheme buy-back (within 10/12 limit)',
  [ShareBuyBackType.EmployeeOver]: 'Employee share scheme buy-back (over 10/12 limit)',
  [ShareBuyBackType.MarketWithin]: 'On-market buy-back (within 10/12 limit)',
  [ShareBuyBackType.MarketOver]: 'On-market buy-back (over 10/12 limit)',
  [ShareBuyBackType.EqualWithin]: 'Equal access scheme buy-back (within 10/12 limit)',
  [ShareBuyBackType.EqualOver]: 'Equal access scheme buy-back (over 10/12 limit)',
  [ShareBuyBackType.Selective]: 'Selective buy-back'
};

export const shareBuyBackTypeOptions: SelectOption[] = Object.entries(shareBuyBackTypeDictionary)
  .map(([value, label]) => ({ label, value: +value }));
