import { IEdgeDocument } from './edgeDocument';
import { Entity } from './entity';
import { SecurityChange } from './securityChange';
import { CorporateHolderModel, IndividualHolderModel } from './securityRegistryRecord';
import { SecurityTransaction } from './securityTransaction';

export class EntitySecurityTransfer<TEntity extends Entity> extends SecurityChange<TEntity> implements IEdgeDocument {
  transferFrom: SecurityTransactionFrom;
  transferTo: SecurityTransactionTo;
  securityHoldersTo: (IndividualHolderModel | CorporateHolderModel)[];

  constructor(data: Partial<EntitySecurityTransfer<TEntity>> = {}) {
    super(data);

    this.transferFrom = new SecurityTransactionFrom(data.transferFrom);
    this.transferTo = new SecurityTransactionTo(data.transferTo);
    this.securityHoldersTo = data.securityHoldersTo?.map(holder => {
      switch (holder.$type) {
        case 'IndividualHolderModel':
          return new IndividualHolderModel(holder);
        case 'CorporateHolderModel':
          return new CorporateHolderModel(holder);
        default:
          throw new Error(`Unknown holder type: ${ holder.$type }`);
      }
    }) ?? [];
  }
}


export class SecurityTransactionFrom extends SecurityTransaction {
}

export class SecurityTransactionTo extends SecurityTransaction {
}
