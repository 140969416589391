import { Component } from '@angular/core';
import { ICellRendererParams } from "ag-grid-community";
import { AlertStatus } from '../../../../../../../models/enums/alertEnums';
import { AlertsTypePipe } from "../../../../../../../pipes/enumsPipes/alertsTypePipe";
import { SplitByCapitalLetterPipePipe } from "../../../../../../../pipes/split-by-capital-letter-pipe.pipe";
import { NgClass } from "@angular/common";
import { AlertsStatusPipe } from "../../../../../../../pipes/enumsPipes/alertsStatusPipe";
import { Alert } from "../../../../../../../models/alerts/alert";

@Component({
  selector: 'app-ag-alerts-status',
  standalone: true,
  imports: [
    AlertsTypePipe,
    SplitByCapitalLetterPipePipe,
    NgClass,
    AlertsStatusPipe
  ],
  templateUrl: './ag-alerts-status.component.html',
  styleUrl: './ag-alerts-status.component.scss'
})
export class AgAlertsStatusComponent {
  status: AlertStatus | undefined | null;
  isRead = false;
  protected readonly AlertStatus = AlertStatus;

  agInit(params: ICellRendererParams<AlertStatus, AlertStatus>): void {
    this.status = params.value;
    this.isRead = (params.data as unknown as Alert).isReaded;
  }

  refresh(params: ICellRendererParams<AlertStatus, AlertStatus>): boolean {
    this.status = params.value;
    return true;
  }
}
