import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'defaultValue'
})
export class DefaulValuePipe implements PipeTransform {
  transform(value: string | undefined | null, label = '-'): string {
    if (value != undefined && value != null && value?.toString()?.trim()?.length > 0)
      return value;
    return label;
  }
}
