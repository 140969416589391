import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { environment } from '../environments/environment';

export const devGuard: CanActivateFn = async () => {
  const router = inject(Router);


  if (environment.production) {
    await router.navigate(['/companies']);
    return false;
  }
  
  return true;
};
