import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Company } from '../../../../models/company';
import { Document } from '../../../../models/document';
import { CompanyChangeData } from '../../../../models/enums/companyChangeData';
import { EntitySecurityCancellation } from '../../../../models/securityChange';
import { SecurityType } from '../../../../models/securityType';
import { ChangeDictionaryHelper } from '../../../../models/shared/change-dictionary-helper.model';
import { IStep } from '../../../../models/step';
import { NumbersValidators } from '../../../../validators/numbers.validators';
import { SelectOptionGroup } from '../../../components/common/select-groups/select-groups.component';
import { CompanyForm2560, Form2560SectionSectionOptions, } from '../../form2560/CompanyForm2560.model';
import { buyBackDocumentTypeDictionary, buyBackTypeDictionary, CompanyForm280 } from '../../form280/form280.model';
import { CertificateConsolidationOptions, ConsiderationTypesOptions } from '../share-issue/share-issue.model';
import { ShareCancellationComponent } from './share-cancellation.component';

export enum ShareCancellationStepsEnum {
  FormDescription = 0,
  ShareholderAndCancellationDetails = 1,
  Estimate = 2,
  Form280 = 3,
  Form2560 = 4
}

export const ShareCancellationSteps: IStep<ShareCancellationStepsEnum>[] = [
  {
    label: 'Form Description',
    step: ShareCancellationStepsEnum.FormDescription
  },
  {
    label: 'Shareholder and Cancellation Details',
    step: ShareCancellationStepsEnum.ShareholderAndCancellationDetails
  },
  {
    label: 'Notification of share buy-back details',
    step: ShareCancellationStepsEnum.Form280
  },
  {
    label: 'Notification of reduction in share capital details',
    step: ShareCancellationStepsEnum.Form2560
  },
  {
    label: 'Estimated Shareholding after Transaction',
    step: ShareCancellationStepsEnum.Estimate
  },
];

export class CompanySecurityCancellation extends EntitySecurityCancellation<Company> {
  static override readonly $type = 'CompanySecurityCancellation';
  companyName = '';
  reason: CancellationReason;
  reasonDescription: string;
  section: string;
  issueForCashConsidarationOnly: boolean;
  nonCashConsiderationDetails: string;
  form280IfShareBuyBackOther: CompanyForm280 | null;
  form2560IfCapitalReduction: CompanyForm2560 | null;
  consolidateCertificate: boolean;

  constructor(data: Partial<CompanySecurityCancellation> = {}) {
    super(data);

    this.companyName = data.companyName ?? '';
    this.reason = data.reason ?? CancellationReason.BuyBackOther;
    this.reasonDescription = data.reasonDescription ?? '';
    this.section = data.section ?? '';
    this.issueForCashConsidarationOnly = data.issueForCashConsidarationOnly ?? false;
    this.nonCashConsiderationDetails = data.nonCashConsiderationDetails ?? '';
    this.form280IfShareBuyBackOther = data.form280IfShareBuyBackOther ? new CompanyForm280(data.form280IfShareBuyBackOther) : null;
    this.form2560IfCapitalReduction = data.form2560IfCapitalReduction ? new CompanyForm2560(data.form2560IfCapitalReduction) : null;
    this.consolidateCertificate = data.consolidateCertificate ?? false;
  }

  override toDictionary(): { key: string; value: string }[] {
    const dict = new ChangeDictionaryHelper();
    const reason = reasonsOfCancellationOptionGroups
      .flatMap(group => group.options)
      .find(option => option.value === this.reason)?.label;

    dict
      .addIds('Holders', this.cancellation.relationshipIds)
      .add('Security Type', SecurityType.buildIdentifier(this.cancellation.securityType))
      .add('Number of Shares Cancelled', (-this.cancellation.numberIncrease).toString())
      .addMoney('Consideration Paid', this.cancellation.considerationPaid)
      .addMoney('Total Amount Paid', -this.cancellation.paidIncrease)
      .addMoney('Total Amount Unpaid', -this.cancellation.unpaidIncrease)
      .add('Reason for Cancellation', reason);

    if (this.reason === CancellationReason.Other) {
      dict
        .add('Reason Description', this.reasonDescription)
        .add('Section Reference', this.section);
    }

    if (this.form280IfShareBuyBackOther) {
      dict
        .add('Type of share buy-back', buyBackTypeDictionary[this.form280IfShareBuyBackOther.buyBackType])
        .add('Documents attached to this form', buyBackDocumentTypeDictionary[this.form280IfShareBuyBackOther.attachedDocumentType]);
    }

    if (this.form2560IfCapitalReduction) {
      const form2560Section = Form2560SectionSectionOptions.find(option => option.value === this.form2560IfCapitalReduction?.section)?.label;
      dict
        .addDate('Date of Proposed Meeting', this.form2560IfCapitalReduction.dateOfProposedMeeting)
        .add('Section', form2560Section)
        .addYesNo('Is Listed Company', this.form2560IfCapitalReduction.isListedCompany)
        .addYesNo('Applies ss.249H(2)', this.form2560IfCapitalReduction.section249hAppliesForUnlistedCompany);
    }

    dict.add('Consideration Type', ConsiderationTypesOptions.find((option) => option.value === this.issueForCashConsidarationOnly)?.label);

    if (!this.issueForCashConsidarationOnly) {
      dict.add('No cash consideration details', this.nonCashConsiderationDetails);
    }

    dict
      .addIfValueIsNotEmpty('Certificate Number', this.cancellation.shareCertificateNumber)
      .add('Certificate Consolidation', CertificateConsolidationOptions.find(option => option.value === this.consolidateCertificate)?.label)
      .addDate('Date of Cancellation', this.cancellation.transactionDate);

    return dict.dictionary;
  }

  override fillModal(modalRef: NgbModalRef, document: Document, actualCompany: Company): NgbModalRef {
    modalRef = this.fillModalWithCompany(modalRef, actualCompany, true);
    (modalRef.componentInstance as ShareCancellationComponent).companyChangeData.documentId = document.documentId;
    return modalRef;
  }

  override fillModalWithCompany(modalRef: NgbModalRef, company: Company, isEdit: boolean): NgbModalRef {
    const instance = modalRef.componentInstance as ShareCancellationComponent;
    instance.isEdit = isEdit;
    instance.companyChangeData = new CompanyChangeData(company);
    instance.formModel = this;

    return modalRef;
  }

  override prepareToRequest(): CompanySecurityCancellation {
    return {
      ...this,
      ...super.prepareToRequest(),
      form280IfShareBuyBackOther: this.form280IfShareBuyBackOther ? this.form280IfShareBuyBackOther.prepareToRequest() : null,
      form2560IfCapitalReduction: this.form2560IfCapitalReduction ? this.form2560IfCapitalReduction.prepareToRequest() : null,
      cancellation: this.cancellation.prepareToRequest(),
    };
  }
}

export enum CancellationReason {
  RedeemedProfits,
  RedeemedProceeds,

  CapitalReductionSingleShareholder,
  CapitalReductionMultipleShareholder,

  BuyBackListed,
  BuyBackOther,

  ReturnedPublicSection651C,
  ReturnedPublicSection1325A,

  ForfeitedShares,
  Other
}

export const reasonsOfCancellationOptionGroups: SelectOptionGroup[] = [
  {
    name: 'Redeemable preference shares',
    hideIcon: false,
    hideName: false,
    options: [
      {
        label: 'Redeemed out of profits',
        value: CancellationReason.RedeemedProfits
      },
      {
        label: 'Redeemed out of proceeds of a fresh issue of shares',
        value: CancellationReason.RedeemedProceeds
      },
    ]
  },
  {
    name: 'Capital reduction',
    hideIcon: false,
    hideName: false,
    options: [
      {
        label: 'Single shareholder company',
        value: CancellationReason.CapitalReductionSingleShareholder
      },
      {
        label: 'Multiple shareholder company',
        value: CancellationReason.CapitalReductionMultipleShareholder
      },
    ]
  },
  {
    name: 'Share buy-back',
    hideIcon: false,
    hideName: false,
    options: [
      {
        label: 'Minimum holding buy-back by listed company',
        value: CancellationReason.BuyBackListed
      },
      {
        label: 'Other buy back type',
        value: CancellationReason.BuyBackOther
      },
    ]
  },
  {
    name: 'Share returned to a public company',
    hideIcon: false,
    hideName: false,
    options: [
      {
        label: 'Under section 651C, 724(2), 737 or 738',
        value: CancellationReason.ReturnedPublicSection651C
      },
      {
        label: 'Under section 1325A (court order)',
        value: CancellationReason.ReturnedPublicSection1325A
      },
    ]
  },
  {
    name: '',
    hideIcon: false,
    hideName: false,
    options: [
      {
        label: 'Forfeited shares',
        value: CancellationReason.ForfeitedShares
      },
      {
        label: 'Other',
        value: CancellationReason.Other
      },
    ]
  },
];

export const customErrorsOfShareCancellationNumbers = {
  paidChangeIsBiggerThan5Percent: 'The paid difference is bigger than 5%',
  unpaidChangeIsBiggerThan5Percent: 'The unpaid difference is bigger than 5%',
  paidUnpaidSumIsNotPositive: 'The sum of paid and unpaid share is not positive',
};

export const customSignErrors = {
  nonBeneficialOwnersNotSigned: 'Non beneficial owners must sign the document'
};
export const totalNumberOfSharesCustomErrors = {
  ...NumbersValidators.numbersValidatorErrors,
  max: 'Value must be less than or equal []'
};
