export enum CompanyChangeHoldingCompanyActionEnum {
  AP = 0, // Appoint
  NO = 1, // Cease
  CN = 2  // Change
}

export const CompanyChangeHoldingCompanyActionDictionary: Record<CompanyChangeHoldingCompanyActionEnum, string> = {
  [CompanyChangeHoldingCompanyActionEnum.AP]: 'There is a new ultimate holding company',
  [CompanyChangeHoldingCompanyActionEnum.NO]: 'Cease',
  [CompanyChangeHoldingCompanyActionEnum.CN]: 'Change of Name'
}
