import { TextMessageRecord } from "./textMessageRecord";

export class TextMessageModel extends TextMessageRecord {
  message: string;

  constructor(data: Partial<TextMessageModel> = {}) {
    super(data);
    this.message = data.message ?? '';
  }
}
