export enum AsicStatus {
  NotLodged,
  Pending,
  WaitingForASIC,
  Validated,
  ManualProcessing,
  Lodged,
  FutureLodgement,
  Rejected
}
