<app-stepper-form
  header="Change of Name"
  [showSteps]="false"
  [steps]="steps"
  [currentStep]="currentStep"
  [currentStepIndex]="currentStepIndex"
  [isLoading]="isLoading"
  [form]="stepperForm"
  (stepIndexChange)="onStepChange($event)"
>
  <div
    class="step-content"
    [formGroup]="stepperForm"
    [ngClass]="{'step-content-error': (currentStep === StepsEnum.Summary && rowHasError)}"
  >
    <app-stepper-form-description
      formKey="484 A2"
      [hidden]="currentStep !== StepsEnum.FormDescription"
    ></app-stepper-form-description>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.CompanyMemberName"
      [hidden]="currentStep !== StepsEnum.CompanyMemberName"
    >
      <h1 class="h1-heading">{{ steps[currentStep].label }}</h1>

      <div class="form-group">
        <app-date-picker
          label="Date of Change"
          formControlName="changeDate"
          [minDate]="minDate"
          [maxDate]="maxDate"
        ></app-date-picker>
      </div>

      <div class="form-group">
        <app-input
          label="Name change is applicable to"
          formControlName="name"
          [isDisabled]="true"
        ></app-input>
      </div>

      <div class="form-group">
        <app-input
          label="New name"
          formControlName="newName"
          [customErrors]="directorFullNameValidatorMsg"
        ></app-input>
      </div>

      @if(!trustOnly) {
        <div class="document-type mb-3">
          <app-radio
            formControlName="documentationType"
            [label]="'Choose the type of document to generate'"
            [options]="documentTypeOptions"
          ></app-radio>
        </div>
      }
    </section>

    <section
      class="step-section gap-32"
      [formGroup]="form"
      [hidden]="currentStep !== StepsEnum.Summary"
    >
      <div class="change">
        <h2 class="title">Change is applied for</h2>

        <app-list-grid
          class="change-grid"
          [rowData]="rows"
          [colDefs]="colDefs"
          [rowClassRules]="rowClassRules"
          [suppressRowClickSelection]="true"
          [pagination]="true"
          [paginationPageSizeSelector]="false"
          [suppressCellFocus]="true"
        ></app-list-grid>
      </div>
    </section>
  </div>
</app-stepper-form>
