import { CurrencyPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { ButtonComponent } from '../common/button/button.component';
import { DividerComponent } from '../common/divider/divider.component';
import { Trust } from '../../../models/trust';

@Component({
  selector: 'app-trust-profile-card',
  standalone: true,
  imports: [
    CurrencyPipe,
    DividerComponent,
    ButtonComponent,
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle
  ],
  templateUrl: './trust-profile-card.component.html',
  styleUrl: './trust-profile-card.component.scss'
})
export class TrustProfileCardComponent {
  @Input() trustProfile!: Trust;
  @Input() disableControls = false;
}
