import {
  CompanyChangeHoldingCompanyActionEnum
} from '../../../../../../models/enums/CompanyChangeHoldingCompanyActionEnum';
import { SelectOption } from '../../../../../../models/selectOptions';

export const allChangeDescriptionOptions: SelectOption[] = [
  {
    label: 'Change name',
    value: CompanyChangeHoldingCompanyActionEnum.CN
  },
  {
    label: 'Cease',
    value: CompanyChangeHoldingCompanyActionEnum.NO
  },
  {
    label: 'There is a new ultimate holding company',
    value: CompanyChangeHoldingCompanyActionEnum.AP
  }
];
