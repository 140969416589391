export function dateComparator(filterLocalDateAtMidnight: Date, cellValue: string) {
  // Input date format: yyyy-mm-dd

  if (cellValue == null) return -1;

  const dateParts = cellValue.split('-');
  const year = Number(dateParts[0]);
  const month = Number(dateParts[1]) - 1;
  const day = Number(dateParts[2]);
  const cellDate = new Date(year, month, day);

  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  } else if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  } else {
    return 0;
  }
}
