<div class="multiple-input-container">
  @if(label) {
    <div class="header">
      <div class="label">{{label}}</div>
    </div>
  }

  <form class="tags-container" [class.active]="focused" (ngSubmit)="onSubmit($event)">
    <button type="submit" hidden></button>

    @for(tag of value; track $index) {
      <div class="tag">
        <div class="tag-text">{{ tag }}</div>
        <button
          type="button"
          (click)="removeTag($index)"
          [disabled]="isDisabled"
        >
          <span class="icon ic-tiny-xs ic-close"></span>
        </button>
      </div>
    }

    <input
      appInputDirectorName
      class="input"
      type="text"
      name="directors-names"
      [disabled]="isDisabled"
      [placeholder]="placeholder"
      [(ngModel)]="inputValue"
      (blur)="!loading && onBlur()"
      (focus)="onFocus()"
      (keydown)="!loading && onKeyDown($event)"
      tabindex="0"
    >
  </form>

  @if(tip) {
    <div class="tip">{{tip}}</div>
  }

  @if(shouldShowErrors && displayValidationError) {
    <app-validation-error [customErrors]="customErrors"></app-validation-error>
  }

  @if(value.length > maxInputTags) {
    <div class="error">You can't input more than {{maxInputTags}} director names</div>
  }
</div>

