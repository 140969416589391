<app-stepper-form
  [header]="header"
  [subheader]="'Form ' + formNumber"
  [showSteps]="false"
  [isLoading]="isLoading"
  [steps]="steps"
  [currentStepIndex]="currentStepIndex"
  [currentStep]="currentStep"
  [form]="stepperForm"
>
  <div class="step-content" [formGroup]="stepperForm">
    <app-stepper-form-description
      [formKey]="formNumber"
      [hidden]="currentStep !== StepsEnum.FormDescription"
    ></app-stepper-form-description>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.RequestForCorrection"
      [hidden]="currentStep !== StepsEnum.RequestForCorrection"
    >
      <h1 class="h1-heading">Document being amended</h1>

      <div class="form-group">
        <app-input
          label="Document number of document being amended"
          formControlName="documentNumber"
          [customErrors]="documentNumberCustomError"
        ></app-input>
      </div>

      <div class="form-group">
        <app-date-picker
          label="Date of lodgement of document being amended"
          formControlName="lodgementDate"
          [minDate]="minDate"
          [maxDate]="maxDate"
          [customErrors]="customDatepickerErrors"
        ></app-date-picker>
      </div>

      <div class="form-group">
        <app-autocomplete
          label="Form code of document being amended"
          formControlName="formCode"
          [options]="allowedFormCodesOptions"
          [valuePrimitive]="true"
          [allowTypeAnyValue]="true"
        />
      </div>

      @if (showForm201AlertMessage) {
        <div class="form-group">
          <app-disclaimer>{{ alertMessageIfFormCode201 }}</app-disclaimer>
        </div>
      } @else if (pickedFormCodeNotFromList) {
        <div class="form-group">
          <app-disclaimer>{{ pickedFormCodeNotFromList }}</app-disclaimer>
        </div>
      }

      <div class="form-group">
        <app-input
          label="Document title of document being amended"
          formControlName="documentTitle"
        ></app-input>
      </div>

      <div class="form-group">
        <app-textarea
          label="Details of correction"
          formControlName="correctionDetails"
        ></app-textarea>
      </div>
    </section>
  </div>
</app-stepper-form>
