import { Component, Input } from '@angular/core';
import { TrustStatusPipe } from "../../../../../../pipes/enumsPipes/trustStatusPipe";
import { UserStatus } from "../../../../../../models/userRecord";
import { NgClass } from "@angular/common";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: 'app-ag-user-status',
  standalone: true,
  imports: [
    TrustStatusPipe,
    NgClass
  ],
  templateUrl: './ag-user-status.component.html',
  styleUrl: './ag-user-status.component.scss'
})
export class AgUserStatusComponent implements ICellRendererAngularComp {
  readonly UserStatus = UserStatus;
  @Input() status: UserStatus = UserStatus.Inactive;

  agInit(params: ICellRendererParams): void {
    this.status = params.value as number;
  }

  refresh(params: ICellRendererParams): boolean {
    this.status = params.value as number;
    return true;
  }
}
