<app-stepper-form
  header="Notification to Register, Change Details or Cease as an Agent"
  subheader="Form RA01"
  [steps]="stepsSignal()"
  [currentStep]="currentStep"
  [currentStepIndex]="currentStepIndex"
  [isLoading]="isLoading"
  [form]="stepperForm"
>
  <div class="step-content" [formGroup]="stepperForm">
    <app-stepper-form-description
      formKey="RA01"
      [hidden]="currentStep !== StepsEnum.FormDescription"
    ></app-stepper-form-description>

    <section
      class="step-section notification-type"
      [formGroupName]="StepsEnum.NotificationType"
      [hidden]="currentStep !== StepsEnum.NotificationType"
    >
      <h1 class="h1-heading">{{ stepsSignal()[currentStepIndex].label }}</h1>

      <div class="form-group">
        <app-select
          label="Type of Notification"
          formControlName="actionType"
          [options]="notificationTypeOptions"
          [valuePrimitive]="true"
        ></app-select>
      </div>
    </section>

    <section
      class="step-section register-an-agent"
      [formGroupName]="StepsEnum.RegisterAnAgent"
      [hidden]="currentStep !== StepsEnum.RegisterAnAgent"
    >
      <h1 class="h1-heading">{{ stepsSignal()[currentStepIndex].label }}</h1>

      <div>
        <div class="form-group">
          <app-select
            label="Select Agent Type"
            formControlName="isIndividual"
            [options]="isAgentIndividualOptions"
            [valuePrimitive]="true"
          ></app-select>
        </div>

        <!-- If Individual Selected-->
        <div
          class="form-group"
          [formGroup]="registrantForm"
          [hidden]="registrantForm.controls.isIndividual.value !== true"
        >
          <app-individual-data-form-group
            individualsName="Agents"
            [formGroup]="registrantForm.controls.registrantIfIndividual"
            [hiddenControls]="disabledIndividualControlKeys"
          />
        </div>

        <!-- If Company Selected-->
        <div
          [formGroup]="registrantForm.controls.registrantIfCompany"
          [hidden]="registrantForm.controls.isIndividual.value !== false"
        >
          <app-company-name-acn-form-group  [formGroup]="registrantForm.controls.registrantIfCompany"/>
        </div>

        <div class="form-group">
          <app-yes-no-control
            label="Is the applicant registered with the Tax Practitioners Board?"
            formControlName="isRegisteredWithTPB"
          ></app-yes-no-control>
        </div>

        <div class="form-group" [hidden]="registrantForm.controls.isRegisteredWithTPB.value !== true">
          <app-input
            label="Registration Number"
            formControlName="registrantTaxPractitionerNumber"
          ></app-input>
        </div>
      </div>
    </section>

    <section
      class="step-section address-details"
      [formGroupName]="StepsEnum.AddressDetails"
      [hidden]="currentStep !== StepsEnum.AddressDetails"
    >
      <h1 class="h1-heading">{{ stepsSignal()[currentStepIndex].label }}</h1>

      <div class="form-group" [formGroup]="stepperForm.controls[StepsEnum.AddressDetails]">
        <app-address-form-group
          label="Postal Address"
          [formGroup]="addressDetailsForm.controls.registrantPostalAddress"
        />
      </div>

      <div class="form-group">
        <app-yes-no-control
          label="Is street address the same as the postal address?"
          formControlName="registrantStreetAddressSameAsPostal"
        ></app-yes-no-control>
      </div>

      <div
        class="form-group"
        [formGroup]="stepperForm.controls[StepsEnum.AddressDetails]"
        [hidden]="addressDetailsForm.controls.registrantStreetAddressSameAsPostal.value"
      >
        <app-address-form-group
          label="Street Address"
          [formGroup]="addressDetailsForm.controls.registrantStreetAddress"
        />
      </div>
    </section>

    <section
      class="step-section responsible-person-and-contact-person"
      [formGroupName]="StepsEnum.ResponsiblePersonAndContactPerson"
      [hidden]="currentStep !== StepsEnum.ResponsiblePersonAndContactPerson"
    >
      <h1 class="h1-heading">{{ stepsSignal()[currentStepIndex].label }}</h1>

      <div class="form-group" [formGroup]="responsiblePersonAndContactPersonForm">
        <h3 class="h3-heading">Responsible Person</h3>

        <app-contact-form-group [formGroup]="responsiblePersonAndContactPersonForm.controls.responsiblePerson" />
      </div>

      <div class="form-group">
        <app-yes-no-control
          label="Is this person the same as the responsible person?"
          formControlName="contactPersonIsResponsiblePerson"
        ></app-yes-no-control>
      </div>

      <div
        class="form-group"
        [formGroup]="responsiblePersonAndContactPersonForm"
        [hidden]="responsiblePersonAndContactPersonForm.controls.contactPersonIsResponsiblePerson.value !== false"
      >
        <h3 class="h3-heading">Contact Person</h3>

        <app-contact-form-group [formGroup]="responsiblePersonAndContactPersonForm.controls.contactPerson" />
      </div>
    </section>

    <section
      class="step-section change-of-details"
      [formGroupName]="StepsEnum.ChangeOfDetails"
      [hidden]="currentStep !== StepsEnum.ChangeOfDetails"
    >
      <h1 class="h1-heading">{{ stepsSignal()[currentStepIndex].label }}</h1>

      <div class="agent-registration">
        <div class="form-group">
          <app-select
            label="What details are changing?"
            formControlName="changeType"
            [options]="RA01ChangeTypeOptions"
            [valuePrimitive]="true"
          ></app-select>
        </div>

        <div
          [hidden]="changeOfDetailsForm.controls.changeType.value != RA01ChangeType.isAddressChange"
          [formGroup]="changeOfDetailsForm.controls.addressChange"
        >
          <div class="form-group" [formGroup]="changeOfDetailsForm.controls.addressChange">

            <app-address-form-group
              label="Postal Address"
              [formGroup]="changeOfDetailsForm.controls.addressChange.controls.newPostalAddress"
            />
          </div>

          <div class="form-group">
            <app-yes-no-control
              label="Is street address the same as the postal address?"
              formControlName="newStreetAddressSameAsPostal"
            ></app-yes-no-control>
          </div>

          <div
            class="form-group"
            [formGroup]="changeOfDetailsForm.controls.addressChange"
            [hidden]="changeOfDetailsForm.controls.addressChange.controls.newStreetAddressSameAsPostal.value"
          >
            <app-address-form-group
              label="Street Address"
              [formGroup]="changeOfDetailsForm.controls.addressChange.controls.newStreetAddress"
            />
          </div>
        </div>

        <div [hidden]="changeOfDetailsForm.controls.changeType.value != RA01ChangeType.isResponsiblePersonChange">
          <div class="form-group" [formGroup]="changeOfDetailsForm">
            <h3 class="h3-heading">New Responsible Person</h3>

            <app-contact-form-group [formGroup]="changeOfDetailsForm.controls.newResponsiblePerson" />
          </div>

          <div class="form-group">
            <app-yes-no-control
              label="Is this person the same as the responsible person?"
              formControlName="isNewContactPersonResponsiblePerson"
            ></app-yes-no-control>
          </div>
        </div>

        <div [hidden]="
        !(changeOfDetailsForm.controls.changeType.value === RA01ChangeType.isResponsiblePersonChange
        && changeOfDetailsForm.controls.isNewContactPersonResponsiblePerson.value === false)
        && changeOfDetailsForm.controls.changeType.value !== RA01ChangeType.isContactPersonChange
        ">
          <div
            class="form-group"
            [formGroup]="changeOfDetailsForm"
          >
            <h3 class="h3-heading">New Contact Person</h3>

            <app-contact-form-group [formGroup]="changeOfDetailsForm.controls.newContactPerson" />
          </div>
        </div>
      </div>
    </section>

    <section
      class="step-section cease-an-agent"
      [formGroupName]="StepsEnum.CeaseAnAgent"
      [hidden]="currentStep !== StepsEnum.CeaseAnAgent"
    >
      <h1 class="h1-heading">{{ stepsSignal()[currentStepIndex].label }}</h1>

      <div class="form-group" [formGroup]="ceaseForm">
        <app-contact-form-group [formGroup]="ceaseForm.controls.cessationResponsiblePerson" />
      </div>

      <div class="form-group mt-3">
        <app-checkbox
          label="I declare I have completed the steps to cancel my registration as an ASIC Registered Agent"
          formControlName="declarationAccepted"
        ></app-checkbox>
      </div>
    </section>
  </div>
</app-stepper-form>
