<div class="modal-header">
    <p class="mb-0">Change individual address</p>
  </div>
  <div class="modal-body">
    <div class="no-animate" *ngIf="addressForm.preview !== true">
      <div class="form-group">
        <label for="address">Address</label>
        <input id="address" type="text" class="form-control" [(ngModel)]="addressForm.address">
      </div>
      <div class="form-group">
        <label for="date">Date of change</label>
        <input id="date" type="text" class="form-control" [(ngModel)]="addressForm.date">
      </div>
      <label class="font-weight-bold">Select which entities this applies to:</label>
      <div class="custom-check">
        <input id="check1" type="checkbox" class="custom-check-input" checked="true">
        <label for="check1" class="custom-check-label">Schumm, Casper and Wilderman Pty Ltd</label>
      </div>
      <div class="custom-check mt-2">
        <input id="check2" type="checkbox" class="custom-check-input">
        <label for="check2" class="custom-check-label">Price, Mante and Durgan Pty Ltd</label>
      </div>
      <div class="custom-check mt-2">
        <input id="check3" type="checkbox" class="custom-check-input">
        <label for="check3" class="custom-check-label">VonRueden, Kiehn and Powlowski Pty Ltd</label>
      </div>
      <div class="custom-check mt-2">
        <input id="check4" type="checkbox" class="custom-check-input">
        <label for="check4" class="custom-check-label">Gusikowski, Jacobson and Turner Pty Ltd</label>
      </div>
    </div>
    <div *ngIf="addressForm.preview === true">
      <p>Veniam fugiat consequat deserunt consectetur sint labore proident sed ut incididunt culpa non esse ut cillum.</p>
      <div style="border-radius: 0.25rem; padding: 1rem; background: rgba(252, 198, 11, 0.20);">
        <img src="assets/images/asic-draft.png" width="100%" height="auto">
      </div>
    </div>
    <div class="d-flex justify-content-center" style="margin-top: 1.5rem;">
      <button class="btn btn-default" (click)="activeModal.close('Close click')">Close</button>
      <button class="btn btn-primary no-animate" [appSpinner]="continuing" (click)="continue()" *ngIf="addressForm.preview !== true">Continue</button>
      <button class="btn btn-primary no-animate" [appSpinner]="continuing" (click)="passBack()" *ngIf="addressForm.preview === true">Generate documents</button>
    </div>
  </div>