import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Company } from './company';
import { Document } from './document';
import { IEdgeDocument } from './edgeDocument';
import { Entity } from './entity';
import { EntityChangeData } from './entityChangeData';
import { CorporateHolderModel, HolderModelBase, IndividualHolderModel } from './securityRegistryRecord';
import { SecurityTransactionIncremental } from './securityTransactionIncremental';

export class SecurityChange<TEntity extends Entity> extends EntityChangeData {
  constructor(data: Partial<SecurityChange<TEntity>> = {}) {
    super(data);
  }

  override toDictionary(entity: Entity): { key: string; value: string; }[] {
    throw new Error('Method not implemented.');
  }

  override fillModal(modalRef: NgbModalRef, document: Document, actualCompany: Company): NgbModalRef {
    throw new Error('Method not implemented.');
  }

  override fillModalWithCompany(modalRef: NgbModalRef, company: Company, isEdit: boolean): NgbModalRef {
    throw new Error('Method not implemented.');
  }
}

export abstract class EntitySecurityIssue<TEntity extends Entity> extends SecurityChange<TEntity> implements IEdgeDocument {
  issue!: SecurityTransactionIncremental;
  securityHolders: (IndividualHolderModel | CorporateHolderModel)[];

  constructor(data: Partial<EntitySecurityIssue<TEntity>> = {}) {
    super(data);
    this.issue = data.issue ? new SecurityTransactionIncremental(data.issue) : new SecurityTransactionIncremental();
    this.securityHolders = data.securityHolders?.filter(Boolean).map(holder => {
      switch (holder.$type) {
        case 'IndividualHolderModel':
          return new IndividualHolderModel(holder);
        case 'CorporateHolderModel':
          return new CorporateHolderModel(holder);
        default:
          throw new Error(`Unknown holder type: ${ holder.$type }`);
      }
    }) ?? [];
  }
}

export abstract class EntitySecurityCancellation<TEntity extends Entity> extends SecurityChange<TEntity> {
  cancellation!: SecurityTransactionIncremental;
  securityHolders: HolderModelBase[] = [];

  constructor(data: Partial<EntitySecurityCancellation<TEntity>> = {}) {
    super(data);
    this.cancellation = new SecurityTransactionIncremental(data.cancellation);
    this.securityHolders = data.securityHolders ?? [];
  }
}
