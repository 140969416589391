import { Component, inject, Input, OnInit, signal } from '@angular/core';
import { ButtonComponent } from "../../../components/common/button/button.component";
import {
  CorporateRelationshipFormGroup,
  CorporateRelationshipFormGroupComponent
} from "../../../components/reusable-form-groups/corporate-relationship-form-group/corporate-relationship-form-group.component";
import { DisclaimerComponent } from "../../../components/common/disclaimer/disclaimer.component";
import { DividerComponent } from "../../../components/common/divider/divider.component";
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import {
  RelationshipFormGroup,
  RelationshipFormGroupComponent
} from "../../../components/reusable-form-groups/relationship-form-group/relationship-form-group.component";
import { SelectComponent } from "../../../components/common/select/select.component";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyProfileService } from '../../../company-profile/company-profile.service';
import { Trust } from "../../../../models/trust";
import { SelectOption } from "../../../../models/selectOptions";
import { FormEditAppointorSteps } from "./form-change-of-appointor.model";
import { IStep } from "../../../../models/step";
import {
  AppointmentType,
  IndividualCorporateRelationship,
  individualCorporateTrusteeOptions,
  TrustChangeRelationship,
} from '../../../../models/trust-change-relationship';
import { Relationship } from "../../../../models/relationship";
import { Company } from "../../../../models/company";
import { RelationshipType } from "../../../../models/enums/relationshipTypeEnum";
import {
  FormIndividualRelationshipModalComponent
} from "../form-individual-realtionship-modal/form-individual-relationship-modal.component";
import { finalize } from "rxjs";
import { Guid } from "../../../helpers/guid.helper";
import { Address } from "../../../../models/address";
import { setControlDisabled } from "../../../../functions/set-control-disabled";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { clearFormArray } from "../../../../functions/clear-form-array";
import { EntityData } from "../../../../models/entityData";
import {
  BaseStepperAppliableComponent
} from "../../../modals/stepper-form/base-stepper-appliable/base-stepper-appliable.component";
import { EntityChangeData } from "../../../../models/entityChangeData";
import { Document } from "../../../../models/document";
import {
  StepperFormAppliableComponent
} from "../../../modals/stepper-form/stepper-form-appliable/stepper-form-appliable.component";
import { ValidationErrorComponent } from "../../../components/common/validation-error/validation-error.component";

@Component({
  selector: 'app-form-change-of-appointor',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    ButtonComponent,
    DividerComponent,
    DisclaimerComponent,
    SelectComponent,
    RelationshipFormGroupComponent,
    CorporateRelationshipFormGroupComponent,
    StepperFormAppliableComponent,
    ValidationErrorComponent
  ],
  templateUrl: './form-change-of-appointor.component.html',
  styleUrls: [
    './../../../modals/stepper-form/base-stepper-component/base-stepper-form.component.scss',
    '../form-edit-trustee/form-change-of-trustee.component.scss',
    './form-change-of-appointor.component.scss'
  ]
})
export class FormChangeOfAppointorComponent extends BaseStepperAppliableComponent<FormEditAppointorSteps, TrustChangeRelationship> implements OnInit {
  private modalService = inject(NgbModal);
  private companyProfileService = inject(CompanyProfileService);

  @Input() trustProfile!: Trust;

  readonly allowedMinimumOfCorporateAppointorDirector = 1;
  readonly allowedMaximumOfCorporateAppointorDirector = 15;
  readonly allowedMaximumOfIndividualAppointors = 6;
  readonly allowedMinimumOfIndividualAppointors = 1;
  readonly individualCorporateAppointorOptions: SelectOption[] = individualCorporateTrusteeOptions;
  readonly IndividualCorporateRelationship = IndividualCorporateRelationship;
  readonly customErrors = {
    notEnoughIndividualAppointors: 'At least [] individual appointors should be added.',
    tooManyIndividualAppointors: 'Maximum number of individual appointors is [].',
    notEnoughCorporateAppointorsDirectors: 'At least [] director should be present.',
    tooManyCorporateAppointorsDirectors: 'Maximum number of directors to add is []',
  };
  override readonly StepsEnum = FormEditAppointorSteps;

  stepsSignal = signal<IStep<FormEditAppointorSteps>[]>([]);
  linkedCompanyProfile: Company | null = null;

  newIndividualAppointors: Relationship[] = [];
  corporateAppointorDirectors: Relationship[] = [];

  override stepperForm = new FormGroup({
    [FormEditAppointorSteps.ExistingTrusteeDetails]: new FormGroup({
      trusteeType: new FormControl<IndividualCorporateRelationship | null>(IndividualCorporateRelationship.Individual),
      individualTrustee: new FormArray<RelationshipFormGroup>([]),
      corporateTrustee: this.createCorporateAppointorFormGroup(),
    }),
    [FormEditAppointorSteps.ExistingAppointorDetails]: new FormGroup({
      appointorType: new FormControl<IndividualCorporateRelationship | null>(IndividualCorporateRelationship.Individual),
      individualAppointors: new FormArray<RelationshipFormGroup>([]),
      corporateAppointor: this.createCorporateAppointorFormGroup(),
    }),
    [FormEditAppointorSteps.NewAppointorDetails]: new FormGroup({
      appointorType: new FormControl<IndividualCorporateRelationship | null>(null, [Validators.required]),
      individualAppointors: new FormArray<RelationshipFormGroup>([]),
      corporateAppointor: this.createCorporateAppointorFormGroup(),
    }),
  });

  constructor() {
    super();
    this.setupSteps(FormEditAppointorSteps);
  }

  ngOnInit(): void {
    this.updateSteps();
    this.setupValidators();
    this.setupExistingTrusteePreview();
    this.setupExistingAppointorPreview();
    this.listenAppointorTypeChange();
    this.setupChange();
  }

  override afterSubmit(changes: EntityChangeData[]) {
    setControlDisabled(this.existingTrusteesForm);
    setControlDisabled(this.existingAppointorForm);
    this.setupChange(changes[0] as TrustChangeRelationship);
  }

  override setupChange(change: TrustChangeRelationship = this.formModel) {
    if (!this.isEdit && change === this.formModel) return;
    const appointorType = change.relationshipsToAdd[0]?.entityDataOverride
      ? IndividualCorporateRelationship.Corporate
      : IndividualCorporateRelationship.Individual;

    this.newAppointorDetailsForm.controls.appointorType.patchValue(appointorType);
    if (appointorType === IndividualCorporateRelationship.Corporate) {
      const corporateAppointor = change.relationshipsToAdd[0];
      this.newAppointorDetailsForm.controls.corporateAppointor.patchValue({
        name: corporateAppointor.entityDataOverride?.name,
        acn: corporateAppointor.entityDataOverride?.entityNumber,
        address: corporateAppointor.entityDataOverride?.registeredAddress
      });
    } else {
      this.newIndividualAppointors = change.relationshipsToAdd;
    }
  }

  override setCurrentStep(newStepIndex: number): void {
    this.currentStep = this.stepsSignal()[newStepIndex].step;
    this.currentStepIndex = newStepIndex;
  }

  openCreateEditIndividualAppointorModal(index = -1): void {
    const modalRef = this.modalService.open(FormIndividualRelationshipModalComponent, { size: 'sm' });
    const instance = modalRef.componentInstance as FormIndividualRelationshipModalComponent;

    instance.individualRelationship = this.newIndividualAppointors[index] ?? null;
    instance.header = this.newIndividualAppointors[index] ? 'Edit Appointor' : 'New Appointor';
    instance.nameControlLabel = 'Appointor Name';

    modalRef.result.then(
      (individualAppointor: Relationship) => {
        individualAppointor.type = RelationshipType.Appointor;

        if (this.newIndividualAppointors[index]) {
          this.newIndividualAppointors[index] = individualAppointor;
        } else {
          this.newIndividualAppointors.push(new Relationship({
            ...individualAppointor,
            entityId: Guid.EmptyGuid,
            individualId: null,
            relationshipId: Guid.generate(),
            type: RelationshipType.Appointor,
          }));
        }
        this.newAppointorDetailsForm.controls.individualAppointors.updateValueAndValidity();
      },
      () => {
      });
  }

  openCreateEditCorporateAppointorDirectorModal(index = -1): void {
    const modalRef = this.modalService.open(FormIndividualRelationshipModalComponent, { size: 'sm' });
    const instance = modalRef.componentInstance as FormIndividualRelationshipModalComponent;

    instance.hideAddress = true;
    instance.header = this.corporateAppointorDirectors[index] ? 'Edit Corporate Appointor Director' : 'New Corporate Appointor Director';
    instance.individualRelationship = this.corporateAppointorDirectors[index] ?? null;
    instance.nameControlLabel = 'Director Name';

    modalRef.result.then(
      (corporateAppointorDirector: Relationship) => {
        corporateAppointorDirector.type = RelationshipType.Director;

        if (this.corporateAppointorDirectors[index]) {
          this.corporateAppointorDirectors[index] = corporateAppointorDirector;
        } else {
          this.corporateAppointorDirectors.push(new Relationship({
            ...corporateAppointorDirector,
            entityId: Guid.EmptyGuid,
            individualId: null,
            relationshipId: Guid.generate(),
            type: RelationshipType.Appointor,
          }));
        }
        this.newAppointorDetailsForm.controls.corporateAppointor.updateValueAndValidity();
      },
      () => {
      });
  }

  removeCorporateAppointorDirector(index: number): void {
    this.corporateAppointorDirectors.splice(index, 1);
    this.newAppointorDetailsForm.controls.corporateAppointor.updateValueAndValidity();
    this.newAppointorDetailsForm.updateValueAndValidity();
  }

  removeIndividualAppointor(index: number): void {
    this.newIndividualAppointors.splice(index, 1);
    this.newAppointorDetailsForm.controls.individualAppointors.updateValueAndValidity();
  }

  getCompanyProfile(entityId: string | null): void {
    this.linkedCompanyProfile = null;
    if (!entityId)
      return;

    this.isLoading = true;
    this.companyProfileService.getCompanyProfile(entityId)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: (companyProfile) => {
          this.linkedCompanyProfile = companyProfile;
          this.newAppointorDetailsForm.controls.corporateAppointor.controls.address.patchValue({
            ...companyProfile.registeredAddress,
            country: 'AU'
          });
          this.corporateAppointorDirectors = companyProfile.officers.filter((officer) => officer.type === RelationshipType.Director);
          this.newAppointorDetailsForm.controls.corporateAppointor.updateValueAndValidity();
        },
        error: (error) => {
          console.warn('Company is not found', error);
        }
      });
  }

  override buildDocument(): Document | null {
    const selectedAppointorTypeIsIndividual = this.newAppointorDetailsForm.controls.appointorType.value === IndividualCorporateRelationship.Individual;

    const relationshipsToAdd = selectedAppointorTypeIsIndividual
      ? this.newIndividualAppointors
      : [new Relationship({
        relationshipId: Guid.generate(),
        entityId: Guid.EmptyGuid,
        type: RelationshipType.Appointor,
        entityDataOverride: new EntityData({
          name: this.newAppointorDetailsForm.controls.corporateAppointor.controls.name.value ?? '',
          entityNumber: this.newAppointorDetailsForm.controls.corporateAppointor.controls.acn.value!,
          registeredAddress: new Address(this.newAppointorDetailsForm.controls.corporateAppointor.controls.address.value as Partial<Address>),
        })
      })];

    const change = new TrustChangeRelationship({
      appointmentType: AppointmentType.Appointor,
      relationshipsIdToRemove: this.trustProfile.appointors.map((r) => r.relationshipId),
      relationshipsToAdd
    });

    try {
      return new Document({
        changes: [change],
        type: 't:change',
        entityId: this.trustProfile.entityId,
        documentId: this.companyChangeData?.documentId ?? Guid.EmptyGuid,
      });
    } catch (error) {
      this.toastr.error('Failed to create Document.', 'Error');
      return null;
    }
  }

  private setupValidators(): void {
    const individualAppointorMaxQuantityValidators = () => {
      return this.newIndividualAppointors.length > this.allowedMaximumOfIndividualAppointors
        ? { tooManyIndividualAppointors: this.allowedMaximumOfIndividualAppointors }
        : null;
    };

    const individualAppointorMinQuantityValidators = () => {
      return this.newIndividualAppointors.length < this.allowedMinimumOfIndividualAppointors
        ? { notEnoughIndividualAppointors: this.allowedMinimumOfIndividualAppointors }
        : null;
    };

    const corporateAppointorDirectorsMaxQuantityValidators = () => {
      return this.corporateAppointorDirectors.length > this.allowedMaximumOfCorporateAppointorDirector
        ? { tooManyCorporateAppointorDirectors: this.allowedMaximumOfCorporateAppointorDirector }
        : null;
    };

    const corporateAppointorDirectorsMinQuantityValidators = () => {
      return this.corporateAppointorDirectors.length < this.allowedMinimumOfCorporateAppointorDirector
        ? { notEnoughCorporateAppointorsDirectors: this.allowedMinimumOfCorporateAppointorDirector }
        : null;
    };

    this.newAppointorDetailsForm.controls.individualAppointors.addValidators([
      individualAppointorMaxQuantityValidators,
      individualAppointorMinQuantityValidators,
    ]);

    this.newAppointorDetailsForm.controls.corporateAppointor.addValidators([
      corporateAppointorDirectorsMaxQuantityValidators,
      corporateAppointorDirectorsMinQuantityValidators,
    ]);
  }

  private setupExistingAppointorPreview(): void {
    const appointorsToEdit = this.trustProfile.appointors;
    const corporateAppointor = appointorsToEdit.find((appointor) => appointor.entityDataOverride) ?? null;

    if (corporateAppointor) {
      this.existingAppointorForm.patchValue({
        appointorType: IndividualCorporateRelationship.Corporate,
        corporateAppointor: {
          name: corporateAppointor.entityDataOverride?.name,
          acn: corporateAppointor.entityDataOverride?.entityNumber,
          address: corporateAppointor.entityDataOverride?.registeredAddress,
          isOverseasCompany: corporateAppointor.entityDataOverride?.registeredAddress.country !== 'AU'
        }
      });
    } else {
      this.existingAppointorForm.controls.appointorType.setValue(IndividualCorporateRelationship.Individual);
      const individualAppointor = appointorsToEdit.filter((appointor) => appointor.individualDataOverride);
      clearFormArray(this.existingAppointorForm.controls.individualAppointors);

      individualAppointor.forEach((t) => {
        if (t.individualDataOverride) {
          this.existingAppointorForm.controls.individualAppointors.push(this.createIndividualAppointorFormGroup(t));
        }
      });
    }

    setControlDisabled(this.existingAppointorForm.controls.individualAppointors);
    setControlDisabled(this.existingAppointorForm.controls.corporateAppointor);
    setControlDisabled(this.existingAppointorForm.controls.appointorType);
  }

  private setupExistingTrusteePreview(): void {
    const trusteesToEdit = this.trustProfile.trustees;
    const corporateTrustee = trusteesToEdit.find((trustee) => trustee.entityDataOverride) ?? null;

    if (corporateTrustee) {
      this.existingTrusteesForm.patchValue({
        trusteeType: IndividualCorporateRelationship.Corporate,
        corporateTrustee: {
          name: corporateTrustee.entityDataOverride?.name,
          acn: corporateTrustee.entityDataOverride?.entityNumber,
          address: corporateTrustee.entityDataOverride?.registeredAddress,
          isOverseasCompany: corporateTrustee.entityDataOverride?.registeredAddress.country !== 'AU'
        }
      });
    } else {
      this.existingTrusteesForm.controls.trusteeType.setValue(IndividualCorporateRelationship.Individual);
      const individualTrustee = trusteesToEdit.filter((trustee) => trustee.individualDataOverride);
      clearFormArray(this.existingTrusteesForm.controls.individualTrustee);

      individualTrustee.forEach((t) => {
        if (t.individualDataOverride) {
          this.existingTrusteesForm.controls.individualTrustee.push(this.createIndividualAppointorFormGroup(t));
        }
      });
    }

    setControlDisabled(this.existingTrusteesForm.controls.individualTrustee);
    setControlDisabled(this.existingTrusteesForm.controls.corporateTrustee);
    setControlDisabled(this.existingTrusteesForm.controls.trusteeType);
  }

  private updateSteps(): void {
    let steps = this.steps;

    if (!this.trustProfile.trustees.length)
      steps = steps.filter((s) => s.step != FormEditAppointorSteps.ExistingTrusteeDetails);

    if (!this.trustProfile.appointors.length)
      steps = steps.filter((s) => s.step != FormEditAppointorSteps.ExistingAppointorDetails);

    this.stepsSignal.set(steps);
    this.setCurrentStep(0);
  }

  private createIndividualAppointorFormGroup(individualAppointor: Partial<Relationship> = {}): RelationshipFormGroup {
    const form = RelationshipFormGroupComponent.defineForm();
    form.patchValue({
      name: individualAppointor?.individualDataOverride?.fullName ?? '',
      address: individualAppointor?.individualDataOverride?.address ?? new Address({ country: 'AU' })
    });
    return form;
  }

  private createCorporateAppointorFormGroup(corporateAppointor: Partial<Relationship> = {}): CorporateRelationshipFormGroup {
    const form = CorporateRelationshipFormGroupComponent.defineForm();
    form.patchValue({
      name: corporateAppointor.entityDataOverride?.name ?? '',
      acn: corporateAppointor.entityDataOverride?.entityNumber ?? corporateAppointor.entityDataOverride?.entityNumber ?? '',
      address: corporateAppointor.entityDataOverride?.registeredAddress ?? new Address({ country: 'AU' }),
      isOverseasCompany: false
    });
    return form;
  }

  private listenAppointorTypeChange(): void {
    this.newAppointorDetailsForm.controls.appointorType.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((appointorType) => {
        setControlDisabled(this.newAppointorDetailsForm.controls.individualAppointors, appointorType === IndividualCorporateRelationship.Corporate);
        setControlDisabled(this.newAppointorDetailsForm.controls.corporateAppointor, appointorType === IndividualCorporateRelationship.Individual);

        this.newAppointorDetailsForm.updateValueAndValidity();
      });
  }

  get existingTrusteesForm() {
    return this.stepperForm.controls[FormEditAppointorSteps.ExistingTrusteeDetails];
  }

  get existingAppointorForm() {
    return this.stepperForm.controls[FormEditAppointorSteps.ExistingAppointorDetails];
  }

  get newAppointorDetailsForm() {
    return this.stepperForm.controls[FormEditAppointorSteps.NewAppointorDetails];
  }

  get allowAddCorporateAppointorDirectors(): boolean {
    return this.corporateAppointorDirectors.length < this.allowedMaximumOfCorporateAppointorDirector || this.isLoading;
  }

  get allowAddIndividualAppointors(): boolean {
    return this.newIndividualAppointors.length < this.allowedMaximumOfIndividualAppointors || this.isLoading;
  }
}

