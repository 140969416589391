import { Injectable } from '@angular/core';
import { SelectOption } from '../models/selectOptions';
import * as countries from './../assets/countries/iso-3166-2.json';

export type ICountriesFile = Record<string, ICountryFile>;

export interface ICountryFile {
  name: string;
  divisions: Record<string, string>;
}

@Injectable({
  providedIn: 'root'
})
export class CountriesService {
  static countries = countries as unknown as ICountriesFile;
  readonly countriesOnTop = ['AU', 'NZ'];

  static getCountryName(countryCode: string): string {
    return CountriesService.countries[countryCode]?.name
      ?? CountriesService.countries['AU'].divisions[countryCode]
      ?? '';
  }

  static isAustralia(countryCode: string | null): boolean {
    countryCode = countryCode?.toLowerCase() ?? null;
    return countryCode === 'au' || countryCode === 'australia';
  }

  static isAustralianState(countryCode: string): boolean {
    return countryCode.startsWith('AU-') || !!CountriesService.countries['AU'].divisions[countryCode.toUpperCase()];
  }

  static isAustralianStateOrAustralia(countryCode: string): boolean {
    return CountriesService.isAustralianState(countryCode) || countryCode === 'AU';
  }

  getCountries(): SelectOption[] {
    const countriesOnTop: SelectOption[] = [];

    const countries = Object.entries(CountriesService.countries)
      .reduce((countryOptions: SelectOption[], [key, value]) => {
        const option: SelectOption = {
          label: `${ value.name }`,
          value: key,
        };

        if (this.countriesOnTop.includes(key)) {
          countriesOnTop.push(option);
        } else {
          countryOptions.push(option);
        }

        return countryOptions;
      }, [])
      .sort(this.sortCountries)
      .sort((a, b) => this.countriesOnTop.includes(a.value as string) ? -1 : (this.countriesOnTop.includes(b.value as string) ? 1 : 0));

    return [...countriesOnTop, ...countries];
  }

  getCountriesAndDivisions(): SelectOption[] {
    const australia = CountriesService.countries['AU'];
    const auDivisions: SelectOption[] = Object.entries(australia.divisions)
      .map(([key, division]) => ({
        label: division,
        value: key,
      }))
      .sort(this.sortCountries);

    const countriesOnTop = this.countriesOnTop.reduce((acc, country) => {
      const countryObj: ICountryFile = CountriesService.countries[country];
      if (country !== 'AU') {
        acc.push({
          label: countryObj.name,
          value: countryObj.name,
        });
      }

      return acc;
    }, [] as SelectOption[])
      .sort(this.sortCountries);

    const countries: SelectOption[] = Object.entries(CountriesService.countries)
      .map(([key, value]) => ({
        label: value.name,
        value: value.name,
      }))
      .filter(option => !this.countriesOnTop.includes(option.value))
      .sort(this.sortCountries);

    return [...auDivisions, ...countriesOnTop, ...countries];
  }

  private sortCountries(a: SelectOption, b: SelectOption) {
    return a.label?.toLowerCase().localeCompare(b.label?.toLowerCase());
  }
}
