import { Component, inject, Input, OnInit } from '@angular/core';
import {
  CommonModalWrapperComponent
} from "../../../components/common/common-modal-wrapper/common-modal-wrapper.component";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Trust } from "../../../../models/trust";
import { NgbActiveModal, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { CustomFormValidators } from "../../../../custom-form-validators/custom-form-validators";
import {
  AbnMaskedInputComponent
} from "../../../components/common/masked-input/specific-masked-inputs/abn-masked-input/abn-masked-input.component";
import { DatePickerComponent } from "../../../components/common/date-picker/date-picker.component";
import { InputComponent } from "../../../components/common/input/input.component";
import { SelectComponent } from "../../../components/common/select/select.component";
import { SelectGroupsComponent } from "../../../components/common/select-groups/select-groups.component";
import {
  DatePickerFormValidators,
  DatepickerHelper
} from "../../../../custom-form-validators/date-picker-form-validators";
import {
  AddressControlComponent,
  AddressFormGroup
} from "../../../components/common/address-control/address-control.component";
import { SelectOption } from "../../../../models/selectOptions";
import { JurisdictionType, JurisdictionTypeOptions } from "../../../../models/enums/trustEnum";
import { AuthService } from "../../../../services/auth.service";
import { DividerComponent } from "../../../components/common/divider/divider.component";
import { TrustsService } from "../../../../services/trustsService";
import { observe, Observer } from "fast-json-patch";
import { finalize } from "rxjs";
import {
  MultipleInputDatepickerComponent
} from "../../../components/common/multiple-input-datepicker/multiple-input-datepicker.component";
import {
  AddressFormGroupComponent
} from "../../../components/reusable-form-groups/address-form-group/address-form-group.component";

export interface EditTrustDetailsFormControls {
  name: FormControl<string | null>;
  entityNumber: FormControl<string | null>;
  dateOfEstablishment: FormControl<Date | null>; // Commencement Date
  jurisdiction: FormControl<JurisdictionType | null>;
  registeredAddress: AddressFormGroup,
  variationDates: FormControl<Date[] | null>,
  vestingDate: FormControl<Date | null>;
  partnerManagerId: FormControl<string | null>;
  accountManagerId: FormControl<string | null>;
  referenceNumber: FormControl<string | null>;
  familyGroup: FormControl<string | null>;
}

export type EditTrustDetailsFormGroup = FormGroup<EditTrustDetailsFormControls>;

@Component({
  selector: 'app-form-edit-trust-details',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModalWrapperComponent,
    DividerComponent,
    InputComponent,
    SelectComponent,
    SelectGroupsComponent,
    AbnMaskedInputComponent,
    DatePickerComponent,
    AddressControlComponent,
    MultipleInputDatepickerComponent,
    AddressFormGroupComponent,
  ],
  templateUrl: './form-edit-trust-details.component.html',
  styleUrl: './form-edit-trust-details.component.scss'
})
export class FormEditTrustDetailsComponent implements OnInit {
  private authService = inject(AuthService);
  private trustsService: TrustsService = inject(TrustsService);
  private activeModal = inject(NgbActiveModal);

  @Input() trustProfile!: Trust;

  readonly JurisdictionTypeOptions = JurisdictionTypeOptions;
  readonly vestingDateCustomErrors = {
    ...DatePickerFormValidators.errorMessages,
    tooSmallDate: 'Date of Vesting cannot be earlier or same as Commencement Date'
  };
  usersOption: SelectOption[] = [];
  observer!: Observer<Trust>;
  isLoading = false;

  minCommencementDate: NgbDateStruct = DatepickerHelper.getStructFromString('01-01-1920');
  minDate!: NgbDateStruct;
  maxDate: NgbDateStruct = DatepickerHelper.getNextNYearsStruct(100);

  form: EditTrustDetailsFormGroup = new FormGroup({
    name: new FormControl<string | null>(null, [Validators.required, CustomFormValidators.maxLength(50)]),
    entityNumber: new FormControl<string | null>(null),
    dateOfEstablishment: new FormControl<Date | null>(null),      // Commencement Date
    jurisdiction: new FormControl<JurisdictionType | null>(null, [Validators.required]),
    registeredAddress: AddressFormGroupComponent.defineForm(),
    variationDates: new FormControl<Date[] | null>([]),
    vestingDate: new FormControl<Date | null>(null),
    partnerManagerId: new FormControl<string | null>(null),
    accountManagerId: new FormControl<string | null>(null),
    referenceNumber: new FormControl<string | null>(null),
    familyGroup: new FormControl<string | null>(null),
  });

  ngOnInit(): void {
    this.minDate = this.trustProfile.dateOfEstablishment ? DatepickerHelper.getStructFromDate(this.trustProfile.dateOfEstablishment) : this.minCommencementDate;
    this.usersOption = (this.authService.currentUserProfile()?.users || []).map(user => {
      return {
        value: user.id,
        label: user.fullName
      };
    });
    this.setupChange();
  }

  setupChange(): void {
    this.form.patchValue(this.trustProfile);
  }

  save(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const currentTrust = new Trust(structuredClone(this.trustProfile));
    this.observer = observe<Trust>(currentTrust);

    Object.keys(this.form.controls).forEach((key) => {
      currentTrust[key] = this.form.controls[key].value;
    });

    this.isLoading = true;
    this.form.disable();

    this.trustsService.patchTrust(this.trustProfile.entityId, this.observer)
      .pipe(finalize(() => {
        this.isLoading = false;
        this.form.enable();
      }))
      .subscribe({
        next: (updatedTrust) => {
          this.activeModal.close(updatedTrust);
        },
        error: () => {

        }
      });
  }

  cancel(): void {
    this.activeModal.dismiss();
  }

  get defaultMinDateOrCommencementDate(): NgbDateStruct {
    return this.form.controls.dateOfEstablishment.value
      ? DatepickerHelper.getStructFromDate(this.form.controls.dateOfEstablishment.value)
      : this.minDate;
  }

  get defaultMinDateOrDayAfterCommencementDate(): NgbDateStruct {
    return this.form.controls.dateOfEstablishment.value
      ? DatepickerHelper.getStructFromDateDayAfter(this.form.controls.dateOfEstablishment.value)
      : this.minDate;
  }

  get defaultMaxDateOrVestingDate(): NgbDateStruct {
    return this.form.controls.vestingDate.value
      ? DatepickerHelper.getStructFromDate(this.form.controls.vestingDate.value)
      : this.maxDate;
  }

  get vestingDateErrors() {
    return this.defaultMinDateOrCommencementDate === this.minCommencementDate
      ? DatePickerFormValidators.errorMessages :
      this.vestingDateCustomErrors;
  }
}
