import { JsonPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { booleanAttribute, Component, EventEmitter, Input, Optional, Output } from '@angular/core';
import { NgControl } from '@angular/forms';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { SelectOption } from '../../../../models/selectOptions';
import { LinkComponent } from '../link/link.component';
import { ValidationErrorComponent } from '../validation-error/validation-error.component';

export interface SelectOptionGroup {
  name: string;
  options: SelectOption[];
  hideIcon: boolean;
  hideName: boolean;
}

@Component({
  selector: 'app-select-groups',
  standalone: true,
  imports: [
    LinkComponent,
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
    ValidationErrorComponent,
    NgClass,
    JsonPipe,
    NgTemplateOutlet
  ],
  templateUrl: './select-groups.component.html',
  styleUrls: ['../select/select.component.scss', './select-groups.component.scss']
})
export class SelectGroupsComponent {
  @Input() width = '100%';
  @Input() placeholder = ''
  @Input() label = 'Label'
  @Input() link = ''
  @Input() text = ''
  @Input() helperText = '';
  @Input() noOptionsText = 'There are no available options.';
  @Input({ required: true }) optionGroups: SelectOptionGroup[] = [];
  @Input({ transform: booleanAttribute }) disabled = false;
  @Input({ transform: booleanAttribute }) isDisabled = false;
  @Input({ transform: booleanAttribute }) valuePrimitive = false;

  @Output() select = new EventEmitter<SelectOption>();

  #value: SelectOption | null = null;
  dropdownOpen = false;
  selectedGroupIndex: number | null = null;

  constructor(@Optional() protected ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  get value(): SelectOption | null {
    return this.#value;
  }

  set value(value: SelectOption | null) {
    this.#value = value;
    this.onChange(this.#value?.value ?? null);
  }


  get displayValidationError(): boolean {
    return !!(this.ngControl && this.ngControl.touched && this.ngControl.errors);
  }

  selectOption(selectedOption: SelectOption, selectedGroupIndex: number): void {
    this.selectedGroupIndex = selectedGroupIndex;
    this.value = selectedOption;
    this.select.emit(selectedOption);
  }

  onChange: any = (): void => {};
  onTouched: any = (): void => {};

  writeValue(value: string): void {
    this.selectedGroupIndex = this.optionGroups.findIndex(optionGroup => optionGroup.options.find(option => option.value === value)) ?? null;
    this.value = this.optionGroups[this.selectedGroupIndex]?.options.find(option => option.value === value) ?? null;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  getDropdownState(dropdownState: boolean): void {
    this.dropdownOpen = dropdownState;
  }
}

