import { CurrencyPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { merge } from 'rxjs';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors, ValidatorFn,
  Validators
} from '@angular/forms';
import { ValueFormatterParams } from 'ag-grid-community';
import { ColDef } from 'ag-grid-enterprise';
import { DatepickerHelper } from '../../../../custom-form-validators/date-picker-form-validators';
import { Document } from '../../../../models/document';
import { EntityChangeData } from '../../../../models/entityChangeData';
import { SecurityHolding } from '../../../../models/securityHolding';
import { SecurityRegistryRecord } from '../../../../models/securityRegistryRecord';
import { NumbersValidators } from '../../../../validators/numbers.validators';
import { CheckboxComponent } from '../../../components/common/checkbox/checkbox.component';
import { DatePickerComponent } from '../../../components/common/date-picker/date-picker.component';
import { DividerComponent } from '../../../components/common/divider/divider.component';
import { ListGridComponent } from '../../../components/common/grid/components/list-grid/list-grid.component';
import { InputNumberComponent } from '../../../components/common/input-number/input-number.component';
import { LoaderComponent } from '../../../components/common/loader/loader.component';
import { SelectComponent } from '../../../components/common/select/select.component';
import { TextareaComponent } from '../../../components/common/textarea/textarea.component';
import {
  ShareSecurityHoldingsDropdownComponent
} from '../../../components/shares/share-security-holdings-dropdown/share-security-holdings-dropdown.component';
import { StepperFormComponent } from '../../stepper-form/stepper-form.component';
import { BaseShareFormComponent } from '../components/base-share-form/base-share-form.component';
import { ShareEstimateTableComponent } from '../components/share-estimate-table/share-estimate-table.component';
import {
  CompanySecurityConsolidationSubdivision,
  IShareTypesDistributionEstimate,
  resolutionSubjects,
  SubdivisionSteps,
  SubdivisionStepsEnum
} from './share-subdivision-conversion.model';
import { setControlDisabled } from "../../../../functions/set-control-disabled";
import { SecurityType } from "../../../../models/securityType";
import {
  StepperFormDescriptionComponent
} from "../../stepper-form/stepper-form-description/stepper-form-description.component";

@Component({
  selector: 'app-share-subdivision-conversion',
  standalone: true,
  imports: [
    StepperFormComponent,
    ReactiveFormsModule,
    CheckboxComponent,
    DividerComponent,
    InputNumberComponent,
    TextareaComponent,
    DatePickerComponent,
    SelectComponent,
    ShareSecurityHoldingsDropdownComponent,
    ShareEstimateTableComponent,
    ListGridComponent,
    LoaderComponent,
    StepperFormDescriptionComponent

  ],
  templateUrl: './share-subdivision-conversion.component.html',
  styleUrls: ['../share-cancellation/share-cancellation.component.scss', '../../stepper-form/base-stepper-component/base-stepper-form.component.scss']
})
export class ShareSubdivisionConversionComponent extends BaseShareFormComponent<SubdivisionStepsEnum, CompanySecurityConsolidationSubdivision> {
  currencyPipe = inject(CurrencyPipe);

  override steps = SubdivisionSteps;
  override readonly StepsEnum = SubdivisionStepsEnum;
  readonly resolutionSubjects = resolutionSubjects;

  // Subdivision Conversion Consolidation
  securityRegistryRecordsHoldings: SecurityHolding[] = [];
  selectedSecurityHoldingClass: SecurityHolding | null = null;

  // estimate
  shareClassesDistributionEstimate: IShareTypesDistributionEstimate[] = [];
  shareTypesDistributionEstimate: IShareTypesDistributionEstimate[] = [];

  override stepperForm = new FormGroup({
    [SubdivisionStepsEnum.FormDescription]: new FormGroup({}),
    [SubdivisionStepsEnum.ResolutionSubject]: new FormGroup({
      alterationToRights: new FormControl<boolean>(false),
      convertShares: new FormControl<boolean>(true),
      callsLimitedExternallyAdministered: new FormControl<boolean>(false),
      reductionInShareCapital: new FormControl<boolean>(false),
      finAssistanceApprovalByCompanysOwnShareholders: new FormControl<boolean>(false),
      finAssistanceApprovalByShareholdersListedHoldingCompany: new FormControl<boolean>(false),
      finAssistanceApprovalByShareholdersOfUltimateHoldingCompany: new FormControl<boolean>(false),
      otherSubject: new FormControl<boolean>(false),
      sectionNumber: new FormControl<string | null>(null),
      briefDescription: new FormControl<string | null>(null),
    }),
    [SubdivisionStepsEnum.SubdivisionConversionConsolidation]: new FormGroup({
      securityHoldingClass: new FormControl<string | null>(null, [Validators.required]),
      newNumberShares: new FormControl<number | null>(null, [Validators.required, NumbersValidators.isInteger]),
      dateOfResolution: new FormControl<Date | null>(DatepickerHelper.getToday(), [Validators.required]),
      resolutionText: new FormControl<string | null>(null, [Validators.required]),
    }),
    [SubdivisionStepsEnum.Estimate]: new FormGroup({}),
  });

  constructor() {
    super();
    this.setCurrentStep(0);
    this.disableFirstStepCheckboxes();
    this.redirectAfterSubmit = false;
  }

  override setupFormChangeListeners() {
    super.setupFormChangeListeners();
    this.listenSecurityHoldingClassChange();
    this.listenShareholderDetailsFormChange();
    this.listenSubdivisionConversionConversionFormChange();
  }

  override setCurrentStep(newStepIndex: number): void {
    this.currentStep = this.steps[newStepIndex].step;
    this.currentStepIndex = newStepIndex;

    if (this.currentStep === this.StepsEnum.Estimate && this.shouldLoadEstimate) {
      this.loadEstimates();
    }
  }

  override afterSubmit(changes: EntityChangeData[]) {
    this.setupChange(changes[0] as CompanySecurityConsolidationSubdivision);
  }

  override setupChange(change: CompanySecurityConsolidationSubdivision = this.formModel): void {
    if (!this.isEdit && change === this.formModel) {
      return;
    }

    this.resolutionSubjectForm.patchValue(change as any);
    this.subdivisionConversionConsolidationForm.patchValue(change as any);
    const selectedClass = Object.keys(change.modifiedClasses)?.[0] ?? '';

    if (selectedClass) {
      const securityHoldingClass = this.securityRegistryRecordsHoldings
        .find(holding => holding.securityType.class === selectedClass)
        ?.securityHoldingId ?? null;

      this.subdivisionConversionConsolidationForm.patchValue({
        securityHoldingClass,
        newNumberShares: change.modifiedClasses[selectedClass],
        resolutionText: change.resolutionText
      });
    }

    this.disableFirstStepCheckboxes();
  }

  override buildDocument(): Document | null {
    const companySecurityConsolidationSubdivision = this.buildShareFormChange();

    try {
      return new Document({
        changes: [companySecurityConsolidationSubdivision],
        entityId: this.companyChangeData?.entityId,
        type: 'c:2205',
        documentId: this.companyChangeData?.documentId,
      });
    } catch (error) {
      this.toastr.error('Failed to create Document.', 'Error');
      return null;
    }
  }

  override buildShareFormChange(): CompanySecurityConsolidationSubdivision {
    const changeDate = this.subdivisionConversionConsolidationForm.controls.dateOfResolution.value!;
    const classKey = this.subdivisionConversionConsolidationForm.controls.securityHoldingClass.value!;

    return new CompanySecurityConsolidationSubdivision({
      ...this.resolutionSubjectForm.value as Partial<CompanySecurityConsolidationSubdivision>,
      ...this.subdivisionConversionConsolidationForm.value as Partial<CompanySecurityConsolidationSubdivision>,
      changeDate,
      dateOfResolution: changeDate,
      modifiedClasses: {
        [classKey]: this.subdivisionConversionConsolidationForm.value.newNumberShares!
      }
    });
  }

  override onShareholdersLoadSuccess(shareholders: SecurityRegistryRecord[]) {
    this.securityRegistryRecords = shareholders
      .filter(holder => holder?.holders.length > 0)
      .map((record) => ({
        ...record,
        holdings: record.holdings.filter(holding => holding.number > 0)
      }) as SecurityRegistryRecord);

    const holdingsMap = new Map<string, SecurityHolding[]>();
    this.securityRegistryRecords
      .flatMap(record => record.holdings)
      .forEach(holding => {
        const securityTypeClass = holding.securityType.class;

        if (!holdingsMap.has(securityTypeClass)) {
          holdingsMap.set(securityTypeClass, [holding]);
        } else {
          holdingsMap.get(securityTypeClass)?.push(holding);
        }
      });

    this.securityRegistryRecordsHoldings = Array.from(holdingsMap.values())
      .map(holdings => {
        return new SecurityHolding({
          number: holdings.reduce((sum, holding) => sum + holding.number, 0),
          paid: holdings.reduce((sum, holding) => sum + holding.paid, 0),
          unpaid: holdings.reduce((sum, holding) => sum + holding.unpaid, 0),
          securityTypeId: holdings[0].securityType.securityTypeId,
          securityType: holdings[0].securityType,
          securityHoldingId: holdings[0].securityType.class
        });
      });

    this.cdr.detectChanges();
    this.setupChange();
  }

  override onEstimateLoadSuccess(estimatedSecurityRegistryRecords: SecurityRegistryRecord[]) {
    this.estimatedSecurityRegistryRecords = estimatedSecurityRegistryRecords
      .filter(record => record?.holders.length > 0);

    const allHoldings = estimatedSecurityRegistryRecords.flatMap(record => record.holdings);

    // Share Classes Distribution Estimate
    this.shareClassesDistributionEstimate = Array.from(allHoldings
      .reduce((classes, holding) => {
        const securityTypeClass = holding.securityType.class;

        if (!classes.has(securityTypeClass)) {
          classes.set(securityTypeClass, {
            class: securityTypeClass,
            identifier: '',
            numberOfShares: 0,
            nominalPrice: 0,
            nominalUnpaid: 0,
            totalPaid: 0,
            totalUnpaid: 0
          });
        }

        const record = classes.get(securityTypeClass)!;

        record.numberOfShares += holding.number;
        record.totalPaid += holding.paid;
        record.totalUnpaid +=  holding.unpaid;

        return classes;
      }, new Map<string, IShareTypesDistributionEstimate>()).values());

    // Share Types Distribution Estimate
    this.shareTypesDistributionEstimate = Array.from(allHoldings
      .reduce((classes, holding) => {
        const securityTypeId = holding.securityType.securityTypeId;

        if (!classes.has(securityTypeId)) {
          classes.set(securityTypeId, {
            class: '',
            identifier: SecurityType.buildIdentifier(holding.securityType),
            numberOfShares: 0,
            nominalPrice: 0,
            nominalUnpaid: 0,
            totalPaid: 0,
            totalUnpaid: 0
          });
        }

        const record = classes.get(securityTypeId)!;

        record.numberOfShares += holding.number;
        record.nominalPrice += holding.number * holding.securityType.nominalPrice;
        record.nominalUnpaid += holding.number * holding.unpaid;
        record.totalPaid += holding.paid;
        record.totalUnpaid += holding.unpaid;

        return classes;
      }, new Map<string, IShareTypesDistributionEstimate>()).values());
  }

  private listenSecurityHoldingClassChange(): void {
    this.subdivisionConversionConsolidationForm.controls.securityHoldingClass.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((securityHoldingClass) => {
        const selectedSecurityHoldingClass = this.securityRegistryRecordsHoldings.find(holding => holding.securityHoldingId === securityHoldingClass);
        this.selectedSecurityHoldingClass = selectedSecurityHoldingClass ?? null;

        if (selectedSecurityHoldingClass) {
          this.setupShareNumberValidator(selectedSecurityHoldingClass.number);
        }
      });
  }

  private listenShareholderDetailsFormChange(): void {
    this.stepperForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.shouldLoadEstimate = true);
  }

  private listenSubdivisionConversionConversionFormChange(): void {
      merge(
        this.subdivisionConversionConsolidationForm.controls.newNumberShares.valueChanges,
        this.subdivisionConversionConsolidationForm.controls.dateOfResolution.valueChanges,
        this.subdivisionConversionConsolidationForm.controls.securityHoldingClass.valueChanges,
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.fillResolutionText());
  }

  private disableFirstStepCheckboxes(): void {
    this.resolutionSubjects.forEach((subject) => setControlDisabled(this.resolutionSubjectForm.controls[subject.key]));
  }

  private toCurrency(value: number) {
    return this.currencyPipe.transform(value ?? '', '$', 'code', '1.2-2')!;
  }

  private setupShareNumberValidator(numberOfShares: number): void {
    const mustBeNotEqualValidator = (numberToBeNotEqual: number): ValidatorFn => (control: AbstractControl): ValidationErrors | null => {
      return control.value !== numberToBeNotEqual ? null : { mustBeNotEqual: control.value as number };
    };

    this.subdivisionConversionConsolidationForm.controls.newNumberShares.setValidators([
      Validators.required,
      NumbersValidators.isInteger,
      mustBeNotEqualValidator(numberOfShares)
    ]);
  }

  private fillResolutionText(): void {
    const numberOfShares = this.subdivisionConversionConsolidationForm.controls.newNumberShares.value ?? 0;
    const isConsolidation = (this.selectedSecurityHoldingClass?.number ?? 0) > numberOfShares;
    const changeType1 = isConsolidation ? 'consolidate' : 'subdivide';
    const changeType2 = isConsolidation ? 'consolidation' : 'subdivision';
    const shareClass = this.subdivisionConversionConsolidationForm.controls.securityHoldingClass.value;
    const date = DatepickerHelper.toString(this.subdivisionConversionConsolidationForm.controls.dateOfResolution.value);

    const resolutionText = `It was resolved that the company shall ${ changeType1 } its ${ shareClass } shares effective ${ date }. The new number of shares after ${ changeType2 } is ${ numberOfShares } ${ shareClass } shares.`;
    this.subdivisionConversionConsolidationForm.controls.resolutionText.setValue(resolutionText);
  }

  get resolutionSubjectForm() {
    return this.stepperForm.controls[SubdivisionStepsEnum.ResolutionSubject];
  }

  get subdivisionConversionConsolidationForm() {
    return this.stepperForm.controls[SubdivisionStepsEnum.SubdivisionConversionConsolidation];
  }

  readonly shareClassesDistributionEstimateConfig: ColDef[] = [
    {
      headerName: 'Share Class',
      field: 'class',
      flex: 1,
      autoHeight: true,
      cellClass: 'overflow-hidden pr-0',
      suppressHeaderMenuButton: true,
      minWidth: 200,
    },
    {
      headerName: 'Number of Shares',
      field: 'numberOfShares',
      autoHeight: true,
      cellClass: 'overflow-hidden text-end',
      suppressHeaderMenuButton: true,
    },
    {
      headerName: 'Total Paid',
      field: 'totalPaid',
      autoHeight: true,
      cellClass: 'overflow-hidden text-end',
      suppressHeaderMenuButton: true,
      valueFormatter: (params: ValueFormatterParams<unknown, string>) => {
        return this.currencyPipe.transform(params.value ?? '', '$', 'code', '1.2-2')!;
      },
    },
    {
      headerName: 'Total Unpaid',
      field: 'totalUnpaid',
      autoHeight: true,
      cellClass: 'overflow-hidden text-end',
      suppressHeaderMenuButton: true,
      valueFormatter: (params: ValueFormatterParams<unknown, string>) => {
        return this.currencyPipe.transform(params.value ?? '', '$', 'code', '1.2-2')!;
      },
    }
  ];

  readonly shareTypesDistributionEstimateConfig: ColDef[] = [
    {
      headerName: 'Share Type',
      field: 'identifier',
      flex: 1,
      autoHeight: true,
      cellClass: 'overflow-hidden pr-0',
      suppressHeaderMenuButton: true,
      minWidth: 200
    },
    {
      headerName: 'Number of Shares',
      field: 'numberOfShares',
      autoHeight: true,
      cellClass: 'overflow-hidden text-end',
      suppressHeaderMenuButton: true,
    },
    {
      headerName: 'Paid',
      field: 'totalPaid',
      autoHeight: true,
      cellClass: 'overflow-hidden text-end',
      suppressHeaderMenuButton: true,
      valueFormatter: (params: ValueFormatterParams<unknown, string>) => {
        return this.toCurrency(Number(params.value) ?? 0)!;
      },
    },
    {
      headerName: 'Unpaid',
      field: 'totalUnpaid',
      autoHeight: true,
      cellClass: 'overflow-hidden text-end',
      suppressHeaderMenuButton: true,
      valueFormatter: (params: ValueFormatterParams<unknown, string>) => {
        return this.toCurrency(Number(params.value) ?? 0)!;
      },
    },
  ];
}
