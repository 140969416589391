import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ExportTypeEnum } from '../../../../models/enums/exportTypeEnum';
import { ButtonComponent } from '../button/button.component';
import { RouterLink } from "@angular/router";

@Component({
  selector: 'app-page-title',
  standalone: true,
  imports: [
    ButtonComponent,
    RouterLink,
  ],
  templateUrl: './page-title.component.html',
  styleUrl: './page-title.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageTitleComponent {
  @Input() header = '';
  @Input() subheader = '';
  @Input() iconClass = '';
  @Input() backButtonText = 'Back';
  @Input() backButtonPath: string | null = null;
  protected readonly ExportTypeEnum = ExportTypeEnum;
}
