import { Component, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { AppCopyDirective } from "../../../../directives/copy.directive";
import { ButtonComponent } from "../../../components/common/button/button.component";
import { DefaulValuePipe } from "../../../../pipes/enumsPipes/defaultValuePipe";
import { LoaderComponent } from "../../../components/common/loader/loader.component";
import { PageTitleComponent } from "../../../components/common/page-title/page-title.component";
import { OrganisationService } from "../../../../services/organisation.service";
import { DomSanitizer, SafeHtml, SafeUrl } from "@angular/platform-browser";
import { catchError, combineLatest, debounceTime, filter, finalize, Observable, of, switchMap, take, tap } from "rxjs";
import { FileUploadComponent } from "../../../components/common/file-upload/file-upload.component";
import { bytesToMegabytes } from "../../../../functions/bytes-to-megabytes";
import { InputComponent } from "../../../components/common/input/input.component";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { CustomFormValidators } from "../../../../custom-form-validators/custom-form-validators";
import { SelectComponent } from "../../../components/common/select/select.component";
import { SelectOption } from "../../../../models/selectOptions";
import { ColorPickerComponent } from "../../../components/common/color-picker/color-picker.component";
import { NgTemplateOutlet } from "@angular/common";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { setControlDisabled } from "../../../../functions/set-control-disabled";
import { DisclaimerComponent } from "../../../components/common/disclaimer/disclaimer.component";
import { isHexColorValidator } from "../../../../validators/hex-color-code.validator";
import { RadioComponent } from "../../../components/common/radio/radio.component";
import { EmailTemplatesService } from "../../../../services/email-templates.service";
import { IEmailPreviewResponse } from "../../../../models/email-templates/IEmailPreviewResponse";
import { EmailTemplatesHelper } from "../../../../models/email-templates/emailTemplatesHelper";
import { ToastrService } from "ngx-toastr";
import { EmailTemplate } from "../../../../models/email-templates/emailTemplate";
import { ShadowDomViewerComponent } from "../../../components/common/shadow-dom-viewer/shadow-dom-viewer.component";
import { OrganisationBrandModel } from "../../../../models/OrganisationBrandModel";
import { toBase64 } from "../../../../functions/to-base64";
import { OrganisationModel } from "../../../../models/organisationModel";
import {
  AgActionIconButtonComponent
} from "../../../components/common/grid/components/ag-action-icon-button/ag-action-icon-button.component";
import { YesNoControlComponent } from "../../../components/common/yes-no-control-component/yes-no-control.component";

export enum BrandingPageModes {
  Loading = 0,
  NoLogo = 1,
  Preview = 2,
  Edit = 3
}

export enum CoverPageFooterTextVariant {
  NoFooterText = 0,
  DefaultFooterText = 1,
  CustomFooterText = 2,
}

@Component({
  selector: 'app-branding-page',
  standalone: true,
  providers: [EmailTemplatesService],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    NgTemplateOutlet,
    AppCopyDirective,
    DefaulValuePipe,
    ButtonComponent,
    InputComponent,
    RadioComponent,
    SelectComponent,
    LoaderComponent,
    AgActionIconButtonComponent,
    FileUploadComponent,
    ColorPickerComponent,
    PageTitleComponent,
    DisclaimerComponent,
    ShadowDomViewerComponent,
    YesNoControlComponent,
  ],
  templateUrl: './branding-page.component.html',
  styleUrls: ['./branding-page.component.scss', '../../settings-styles.scss']
})
export class BrandingPageComponent implements OnInit {
  private organisationService = inject(OrganisationService);
  private sanitizer = inject(DomSanitizer);
  private emailTemplatesService = inject(EmailTemplatesService);
  private toastr = inject(ToastrService);
  private destroyRef: DestroyRef = inject(DestroyRef);

  readonly BrandingPageModes = BrandingPageModes;
  readonly acceptedFormatsList = ['.png', '.svg'];
  readonly acceptedFormats = this.acceptedFormatsList.join(', ');
  readonly customStyle = `.container {margin: 0 !important;}`;
  readonly allowedMaxFileSizeMB = 5;
  readonly CoverPageFooterTextVariant = CoverPageFooterTextVariant;
  readonly coverPageFooterTextOptions: SelectOption[] = [
    { label: 'No Footer Text ', value: CoverPageFooterTextVariant.NoFooterText },
    { label: 'Default Footer Text ', value: CoverPageFooterTextVariant.DefaultFooterText },
    { label: 'Custom Footer Text ', value: CoverPageFooterTextVariant.CustomFooterText },
  ];
  readonly brandColors: SelectOption[] = ['#FFFFFF', '#FBE9E9', '#FFF2D9', '#EBF9F0', '#E9F0FD', '#EEE9FD', '#FDE9FD'].map((c) => ({
    label: c,
    value: c
  }));
  readonly previewVariants: SelectOption[] = [
    { label: 'Document Preview', value: 0 },
    { label: 'Email Preview', value: 1 },
  ];
  readonly EMAIL_PREVIEW_LOADING_DEBOUNCE_TIME = 2000;
  defaultFooterText = '';
  organisationBrandModel: OrganisationBrandModel = new OrganisationBrandModel();
  currentPageMode: BrandingPageModes = BrandingPageModes.Loading;
  isLoading = false;
  logoImg: SafeUrl | null = null;
  errorMessage = '';
  fileToUpload: File | null = null;
  fileToUploadString: string | null = null;
  url: SafeUrl | string | ArrayBuffer | null = null;
  formValueChanged = false;
  previewVariantControl = new FormControl<number | null>(0);
  emailTemplates: EmailTemplate[] = [];
  htmlContent = signal<SafeHtml | null>(null);

  form = new FormGroup({
    brandName: new FormControl<string | null>(null, [CustomFormValidators.maxLength(50)]),
    coverPageFooterTextVariant: new FormControl<CoverPageFooterTextVariant | null>(null),
    footerText: new FormControl<string | null>(null, [Validators.required, CustomFormValidators.maxLength(200)]),
    mainColor: new FormControl<string | null>(this.brandColors[0].value as string, [Validators.required, isHexColorValidator]),

    // TODO: provide saving for controls below after BE ready
    insertLogoOnAnnualStatements: new FormControl<boolean>(false),
    addCoverPageOnAsicForms: new FormControl<boolean>(false),
  });

  ngOnInit(): void {
    combineLatest([
      this.loadBrandingData(),
      this.loadEmailTemplates(),
      this.loadLogo(), // also loads email preview
      this.loadOrganisationData(),
    ])
      .pipe(finalize(() => this.isLoading = false))
      .subscribe();

    this.listenFooterVariantChanges();
    this.form.patchValue({ coverPageFooterTextVariant: CoverPageFooterTextVariant.NoFooterText });
    this.listenFormValueChange();
  }

  cancelEditing(): void {
    this.clearFiles();
    this.setErrorMessage();
    this.formValueChanged = false;
    this.form.reset({ mainColor: this.organisationBrandModel.mainColor as string });
    this.currentPageMode = this.logoImg
      ? BrandingPageModes.Preview
      : BrandingPageModes.NoLogo;
  }

  saveChanges(event?: Event): void {
    event?.preventDefault();
    event?.stopPropagation();

    if (!this.url && !confirm('Are you sure you would like to delete the logo?'))
      return;

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const newFileUploaded = this.fileToUpload && this.url;
    const requests: Observable<any>[] = [];

    if (newFileUploaded)
      requests.push(this.saveImage());
    if (!this.url && !this.fileToUpload)
      requests.push(this.deleteLogo());
    if (this.formValueChanged)
      requests.push(this.saveData());

    this.form.disable();


    combineLatest(requests)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.form.enable();
          this.form.controls.coverPageFooterTextVariant.patchValue(this.form.controls.coverPageFooterTextVariant.value);
          this.formValueChanged = false;
          this.currentPageMode = BrandingPageModes.Preview;
        })
      ).subscribe({
      next: () => this.loadLogo()
    });
  }

  handleFileInputEvent(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    if (this.isLoading) return;
    const files = Array.from((event.target as unknown as { files: File[] }).files) ?? [];
    (event.target as any).value = null;
    this.handleFileUpload(files);
  }

  async handleFileUpload(files: File[]) {
    const file = files[0];

    if (!this.acceptedFormatsList.some((fileExtension) => file.name.endsWith(fileExtension))) {
      this.setErrorMessage(`Uploaded file has not allowed file extension. Please use one of allowed file extensions: ${ this.acceptedFormats }`);
      return;
    }

    if (bytesToMegabytes(file.size) > this.allowedMaxFileSizeMB) {
      this.setErrorMessage(`File size should not exceed ${ this.allowedMaxFileSizeMB } MB`);
      return;
    }

    this.fileToUpload = file;
    this.fileToUploadString = await toBase64(file);
    this.loadPreview().subscribe();
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => this.url = reader.result;

    this.setErrorMessage();
  }

  startEditBranding(): void {
    this.currentPageMode = BrandingPageModes.Edit;
    this.url = this.logoImg;
    this.fillForm();
  }

  setErrorMessage(errorMessage = ''): void {
    this.errorMessage = errorMessage;
  }

  clearFiles(): void {
    this.fileToUpload = null;
    this.fileToUploadString = null;
    this.url = null;
  }

  private saveData(): Observable<OrganisationBrandModel> {
    this.isLoading = true;

    return this.organisationService.putOrganisationBranding(this.buildBrandPayload())
      .pipe(switchMap(() => this.loadBrandingData()));
  }

  private saveImage(): Observable<void> {
    const formData = new FormData();
    formData.append('logo', this.fileToUpload!);
    this.isLoading = true;
    this.logoImg = this.url;

    return this.organisationService.uploadLogo(formData)
      .pipe(tap({
        next: () => this.loadLogo(),
        error: () => {
        }
      }));
  }

  private loadLogo(): Observable<IEmailPreviewResponse> {
    this.clearFiles();
    this.isLoading = true;
    this.currentPageMode = BrandingPageModes.Loading;

    return this.organisationService.getLargeLogo()
      .pipe(
        tap((blob) => {
          const objectURL = URL.createObjectURL(blob);
          this.logoImg = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          this.url = this.logoImg;

          if (!this.logoImg)
            this.currentPageMode = BrandingPageModes.NoLogo;
          else
            this.currentPageMode = BrandingPageModes.Preview;
        }),
        switchMap(() => this.loadPreview())
      );
  }

  private loadBrandingData(): Observable<OrganisationBrandModel> {
    return this.organisationService.getOrganisationBranding()
      .pipe(tap((organisationBrandModel) => {
        this.organisationBrandModel = organisationBrandModel;
        this.fillForm();
      }));
  }

  private loadOrganisationData(): Observable<OrganisationModel> {
    return this.organisationService.getOrganisation()
      .pipe(tap({
        next: (organisation) => {
          this.defaultFooterText =
            [
              organisation.name,
              organisation.address?.normalizedFullAddress,
              organisation.phone,
              organisation.website
            ]
              .filter((str) => !!str?.trim())
              .join(' | ');
        }
      }));
  }

  private listenFooterVariantChanges(): void {
    this.form.controls.coverPageFooterTextVariant.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((variant) => {
        setControlDisabled(this.form.controls.footerText, variant != CoverPageFooterTextVariant.CustomFooterText);
      });
  }

  private listenFormValueChange(): void {
    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.formValueChanged = true);

    this.form.valueChanges
      .pipe(
        filter(() => this.previewVariantControl.value === 1),
        debounceTime(this.EMAIL_PREVIEW_LOADING_DEBOUNCE_TIME),
        switchMap(() => this.loadPreview()),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private loadPreview(): Observable<IEmailPreviewResponse> {
    const errorFormResponse = of({ subject: '', htmlContent: '' });

    if (!this.emailTemplates[0]?.code || this.form.invalid) return errorFormResponse;
    const emailPreviewRequest = EmailTemplatesHelper.buildEmailPreviewRequest(this.emailTemplates[0]);

    emailPreviewRequest.logo = this.fileToUploadString ?? null;
    emailPreviewRequest.brand = this.buildBrandPayload();

    return this.emailTemplatesService.loadPreview(emailPreviewRequest)
      .pipe(
        take(1),
        tap({
          next: (res) => this.htmlContent.set(this.sanitizer.bypassSecurityTrustHtml(res.htmlContent) ?? ''),
          error: (error) => {
            console.warn('[loadPreview] error: ', error);
            this.toastr.error("Something happened while loading preview", "Error");
          }
        }),
        catchError(() => errorFormResponse),
      );
  }

  private loadEmailTemplates(): Observable<any> {
    this.isLoading = true;

    return this.emailTemplatesService.loadEmailTemplates()
      .pipe(
        tap((templates) => this.emailTemplates = templates),
        switchMap(() => this.loadPreview()),
        finalize(() => this.isLoading = false),
      );
  }

  private deleteLogo(): Observable<void> {
    return this.organisationService.deleteLogo()
      .pipe(tap({ next: () => this.loadLogo() }));
  }

  private buildBrandPayload(): OrganisationBrandModel {
    return new OrganisationBrandModel({
      ...this.organisationBrandModel,
      ...this.form.value as Partial<OrganisationBrandModel>,
      brandName: this.form.controls.brandName.value!,
      mainColor: this.form.controls.mainColor.value!,
      footerText: this.editModeFooterText,
    });
  }

  private fillForm(): void {
    const footerText = this.organisationBrandModel.footerText.trim();
    let coverPageFooterTextVariant = CoverPageFooterTextVariant.NoFooterText;

    if (footerText === this.defaultFooterText.trim())
      coverPageFooterTextVariant = CoverPageFooterTextVariant.DefaultFooterText;
    else if (footerText)
      coverPageFooterTextVariant = CoverPageFooterTextVariant.CustomFooterText;

    this.form.patchValue({
      ...this.organisationBrandModel,
      coverPageFooterTextVariant,
    });
  }

  get editModeFooterText(): string {
    if (this.form.controls.coverPageFooterTextVariant.value == CoverPageFooterTextVariant.DefaultFooterText)
      return this.defaultFooterText;
    else if (this.form.controls.coverPageFooterTextVariant.value == CoverPageFooterTextVariant.CustomFooterText)
      return this.form.controls.footerText.value ?? '';
    return '';
  }
}
