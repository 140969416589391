import { SelectOption } from "../../../../../models/selectOptions";

export class DocumentSettingsModel {
  // documents
  receiveEmailsForDocs: NotifyWhenAllPartiesSignedDocument;

  // annual statement
  receiveEmailsForAS: NotifyWhenAllPartiesSignedDocument;
  insertDirectorSignatureInCompanyStatement: boolean;

  constructor(data: Partial<DocumentSettingsModel> = {}) {
    // documents
    this.receiveEmailsForDocs = data.receiveEmailsForDocs ?? NotifyWhenAllPartiesSignedDocument.DoNotSend;

    // annual statement
    this.receiveEmailsForAS = data.receiveEmailsForAS ?? NotifyWhenAllPartiesSignedDocument.DoNotSend;
    this.insertDirectorSignatureInCompanyStatement = data.insertDirectorSignatureInCompanyStatement ?? false;
  }
}

export enum NotifyWhenAllPartiesSignedDocument {
  SenderOnly,
  RecipientsOnly,
  SenderAndRecipients,
  DoNotSend,
}

export const NotifyWhenAllPartiesSignedDocumentLabelRecord: Record<NotifyWhenAllPartiesSignedDocument, string> = {
  [NotifyWhenAllPartiesSignedDocument.SenderOnly]: 'Sender Only',
  [NotifyWhenAllPartiesSignedDocument.RecipientsOnly]: 'Recipients Only',
  [NotifyWhenAllPartiesSignedDocument.SenderAndRecipients]: 'Sender and Recipients',
  [NotifyWhenAllPartiesSignedDocument.DoNotSend]: 'Do not Send',
};

export const NotifyWhenAllPartiesSignedDocumentOptions: SelectOption[] = [
  {
    label: NotifyWhenAllPartiesSignedDocumentLabelRecord[NotifyWhenAllPartiesSignedDocument.SenderOnly],
    value: NotifyWhenAllPartiesSignedDocument.SenderOnly
  },
  {
    label: NotifyWhenAllPartiesSignedDocumentLabelRecord[NotifyWhenAllPartiesSignedDocument.RecipientsOnly],
    value: NotifyWhenAllPartiesSignedDocument.RecipientsOnly
  },
  {
    label: NotifyWhenAllPartiesSignedDocumentLabelRecord[NotifyWhenAllPartiesSignedDocument.SenderAndRecipients],
    value: NotifyWhenAllPartiesSignedDocument.SenderAndRecipients
  },
  {
    label: NotifyWhenAllPartiesSignedDocumentLabelRecord[NotifyWhenAllPartiesSignedDocument.DoNotSend],
    value: NotifyWhenAllPartiesSignedDocument.DoNotSend
  },
];


