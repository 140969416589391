import { SelectOption } from "../../../../../models/selectOptions";

export const allowedFormCodes = [
  '201',
  '484',
  '370',
  '361'
];

export const allowedFormCodesLabels: Record<string, string> = {
  '201': 'Application for Registration as a Company',
  '484': 'Change to Company Details',
  '370': 'Notification by Officeholder of Resignation or Retirement',
  '361': 'Annual Return'
};

export const allowedFormCodesOptions: SelectOption[] = allowedFormCodes
  .map((formCode) => ({
    label: formCode,
    value: formCode
  }));
