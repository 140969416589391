import { Component, OnInit } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-reusable-form-group',
  standalone: true,
  template: '',
})
export class ReusableFormGroupComponent<T extends FormGroup> implements OnInit {
  form!: T;

  constructor(private controlContainer: ControlContainer) {
  }

  public ngOnInit(): void {
    if (!this.controlContainer) {
      throw new Error('[ReusableFormGroupComponent] controlContainer is required. Please define a form group.');
    }

    this.form = this.controlContainer.control as T;
  }

  static defineForm(): FormGroup {
    throw new Error('[ReusableFormGroupComponent] defineForm is not defined. Please define a defineForm static method.');
  }

  static defineFormControls() {
    throw new Error('[ReusableFormGroupComponent] defineFormControls is not defined. Please define a defineFormControls static method.');
  }
}
