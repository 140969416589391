import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { RemindersService } from "../../../../../../services/reminders.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { IReminderItem } from "../../reminders-page.component";
import { CommonModalFormComponent } from "../../../../../modals/common-modal-form/common-modal-form.component";
import { SelectOption } from "../../../../../../models/selectOptions";
import { ReminderSendingMethods } from "../ag-reminder-sending-method-label/ag-reminder-sending-method-label.component";
import { CheckboxComponent } from "../../../../../components/common/checkbox/checkbox.component";
import { DatepickerHelper } from "../../../../../../custom-form-validators/date-picker-form-validators";
import { RadioComponent } from "../../../../../components/common/radio/radio.component";
import {
  MultipleInputNumbersComponent
} from "../../../../../components/common/multiple-input-numbers/multiple-input-numbers.component";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { setControlDisabled } from "../../../../../../functions/set-control-disabled";
import { startWith } from "rxjs";
import { SelectComponent } from "../../../../../components/common/select/select.component";

export const reminderDaysPrefill: Record<string, number[]> = {
  'Annual Statement Payment Reminder': [7, 14, 28],
  'Company Debt Reminder': [7, 14, 28],
  'Signing Reminder for Annual Statement': [7, 14],
  'Signing Reminder for ASIC Forms': [7, 14],
};

@Component({
  selector: 'app-edit-reminder-modal',
  standalone: true,
  providers: [RemindersService],
  imports: [
    ReactiveFormsModule,
    CheckboxComponent,
    RadioComponent,
    MultipleInputNumbersComponent,
    CommonModalFormComponent,
    SelectComponent,
  ],
  templateUrl: './edit-reminder-modal.component.html',
  styleUrl: './edit-reminder-modal.component.scss'
})
export class EditReminderModalComponent implements OnInit {
  private remindersService = inject(RemindersService);
  private activeModal = inject(NgbActiveModal);
  private destroyRef = inject(DestroyRef);

  @Input() reminder!: IReminderItem;

  readonly sendingMethodOptions: SelectOption[] = [
    { label: 'SMS', value: ReminderSendingMethods.sms },
    { label: 'Email', value: ReminderSendingMethods.email },
    { label: 'SMS and Email', value: ReminderSendingMethods.smsAndEmail },
  ];
  readonly datesCustomErrors: Record<string, string> = {
    noEnoughItems: 'Field is empty',
    tooManyItems: 'There are too many items. The maximum quantity is []',
    someNumbersAreSmaller: 'Some dates are smaller than allowed minimum. The minimum date of days before is []',
    someNumbersAreBigger: 'The number of days cannot exceed []',
  };
  readonly datesLimits = {
    minQuantity: 1,
    minNumber: 1,
    maxNumber: 29,
  };
  readonly timeOfSendingOptions: SelectOption[] = this.getTimeOfSendingOptions();

  isLoading = false;
  form = new FormGroup({
    autoSending: new FormControl<boolean | null>(false),
    timeOfSending: new FormControl<string | null>('07:00 AM', [Validators.required]),
    sendingMethod: new FormControl<ReminderSendingMethods | null>(null, [Validators.required]),
    dates: new FormControl<(string | number)[]>([], [Validators.required]),
  });

  ngOnInit(): void {
    this.listenAutoSendingChange();

    this.form.patchValue({
      autoSending: this.reminder.autoSending ?? false,
      sendingMethod: this.reminder.sendingMethod ?? ReminderSendingMethods.sms,
      dates: this.reminder.dates.length ? this.reminder.dates : (reminderDaysPrefill[this.reminder.name] ?? []).map(v => v.toString()),
      timeOfSending: this.reminder.time ?? '07:00 AM'
    });
  }

  save(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const payload: IReminderItem = {
      ...this.reminder,
      dates: (this.form.value.dates ?? []).map((num) => +num),
      sendingMethod: this.form.value.sendingMethod ?? null,
      autoSending: this.form.controls.autoSending.value ?? false,
      time: this.form.value.timeOfSending ?? null
    };

    this.isLoading = true;
    this.form.disable();
    this.remindersService.saveReminderChanges(payload).subscribe({
      next: () => this.activeModal.close(payload),
      error: () => {
        this.isLoading = false;
        this.form.enable();
        this.form.controls.autoSending.setValue(this.form.controls.autoSending.value);
      }
    });
  }

  close(): void {
    this.activeModal.dismiss();
  }

  private listenAutoSendingChange(): void {
    this.form.controls.autoSending.valueChanges
      .pipe(startWith(false), takeUntilDestroyed(this.destroyRef))
      .subscribe((autoSending) => {
        setControlDisabled(this.form.controls.timeOfSending, !autoSending);
        setControlDisabled(this.form.controls.sendingMethod, !autoSending);
        setControlDisabled(this.form.controls.dates, !autoSending);
      });
  }

  private joinWithAnd(strings: string[]) {
    if (!strings.length) {
      return "";
    } else if (strings.length === 1) {
      return strings[0];
    } else if (strings.length === 2) {
      return strings.join(', ');
    } else {
      const lastIndex = strings.length - 1;
      return strings.slice(0, lastIndex).join(', ') + ' and ' + strings[lastIndex];
    }
  }

  private getTimeOfSendingOptions(): SelectOption[] {
    return (new Array(24)
      .fill(null)
      .map((_, index) => {
        const hour = index + 1;
        const time = `${ ((hour % 12) < 10 ? '0' : '') + hour % 12 }:00`;
        const timeString = `${ time } ${ index < 12 ? 'AM' : 'PM' }`;

        return {
          label: timeString,
          value: timeString,
        };
      }));
  }

  get deadlineMessage(): string {
    const baseDate = new Date(2024, 2, 1);
    const numbers = (this.form.controls.dates.value ?? []).map((value) => Number(value));
    const dates = numbers.map((num) => {
      const dateToHandle = new Date(baseDate);
      const date = dateToHandle.getDate();
      dateToHandle.setDate(date - num);
      return DatepickerHelper.toString(dateToHandle);
    }).reverse();

    return `
    Your reminder(s) will be sent <b>${ numbers.join(', ') }</b> days before the due date.<br/>
    Sample deadline: <b>01/03/2024</b>.<br/>
    Reminders will be sent on: <b>${ this.joinWithAnd(dates) }</b>.`;
  }
}
