<div class="modal-header">
  <div class="top">
    <div class="left-side">
      <i class="ic-form ic-md icon"></i>

      <div class="title">
        <h2 class="h2-heading mb-0" [title]="header">{{ header }}</h2>
        <p class="sub-heading" [title]="subheader">{{ subheader }}</p>
      </div>
    </div>
    <div class="right-side">
      <div class="steps-controls">
        <ng-content select=".step-control"></ng-content>

        <button
          class="btn btn-secondary"
          [hidden]="!showSaveAndCompleteLaterBtn || currentStepIndex === 0"
          [appSpinner]="isSaveAndCompleteLaterLoading && isLoading"
          [disabled]="isLoading"
          (click)="onSaveAndCompleteLater()"
        >Save and Complete Later
        </button>

        <app-button
          iconClass="ic-close ic-md icon"
          btnColor="white"
          [isDisabled]="isLoading"
          (clickBtn)="closeModal()"
        ></app-button>

        <app-button
          iconClass="ic-arrow-left ic-md icon"
          btnColor="white"
          [isDisabled]="isLoading || currentStepIndex === 0"
          (clickBtn)="setPreviousStep()"
        ></app-button>

        <div class="separator"></div>

        @if (currentStepIndex < (steps.length - 1)) {
          <app-button
            #nextStepButton
            class="next-step-button"
            iconClassLast="ic-arrow-right ic-md icon"
            text="Next Step"
            [isDisabled]="isCurrentStepInvalid"
            (clickBtn)="setNextStep()"
          ></app-button>
        } @else {
          <ng-content select=".submit-control"></ng-content>

          <app-button
            #submitButton
            class="submit-button"
            iconClass="ic-generate-documents ic-md icon mr-2"
            [isDisabled]="isFormInvalid || isLoading"
            [loading]="isLoading && !isSaveAndCompleteLaterLoading"
            [text]="submitButtonText"
            (clickBtn)="confirm.emit()"
          ></app-button>
        }
      </div>
    </div>
  </div>

  @if (showSteps) {
    <div class="bottom">
      <app-clickable-stepper
        [shouldControlStepsUsingInput]="true"
        [currentStepIndex]="currentStepIndex"
        [disableSteps]="isLoading"
        [steps]="steps"
        (stepIndexChange)="setCurrentStep($event)"
      ></app-clickable-stepper>
    </div>
  }
</div>
<div class="modal-body">
  <form id="stepper-body" (submit)="$event.preventDefault(); onSubmit()">
      <app-loader-standalone [loading]="isLoading"/>
      <div [hidden]="isLoading">
        <ng-content></ng-content>
      </div>
      <button class="hidden p-0" tabindex="-1" type="submit">submit</button>
  </form>

  <!-- a button which redirects focus -->
  <button class="hidden p-0" [disabled]="isLoading" (focusin)="returnFocus()"></button>
</div>

