import { Relationship } from './relationship';
import { SecurityType } from './securityType';

export class SecurityHolding {
  securityHoldingId: string;
  securityTypeId: string;
  securityHoldingAsicId: string;
  entityId: string;
  number: number;
  paid: number;
  unpaid: number;
  securityType: SecurityType;
  fullyPaid: boolean;
  isPosted: boolean;
  relationships: Relationship[] | null = null;

  constructor(data: Partial<SecurityHolding> = {}) {
    this.securityHoldingId = data.securityHoldingId ?? '';
    this.securityTypeId = data.securityTypeId ?? '';
    this.securityHoldingAsicId = data.securityHoldingAsicId ?? '';
    this.entityId = data.entityId ?? '';
    this.number = data.number ?? 0;
    this.paid = data.paid ?? 0;
    this.unpaid = data.unpaid ?? 0;
    this.securityType = new SecurityType(data.securityType) ?? new SecurityType();
    this.fullyPaid = data.fullyPaid ?? false;
    this.isPosted = data.isPosted ?? false;
    this.relationships = data.relationships ?? null;
  }
}
