<app-stepper-form
  header="Extension of Company Name Reservation"
  subheader="Form 410F"
  [showSteps]="false"
  [isLoading]="isLoading"
  [steps]="steps"
  [currentStep]="currentStep"
  [currentStepIndex]="currentStepIndex"
  [form]="stepperForm"
  [formGroup]="stepperForm"
>
  <div class="step-content">
    <app-stepper-form-description
      formKey="410F"
      [hidden]="currentStep !== StepsEnum.FormDetails"
    ></app-stepper-form-description>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.CompanyNameDetails"
      [hidden]="currentStep !== StepsEnum.CompanyNameDetails"
    >
      <h1 class="h1-heading">{{ steps[currentStep].label }}</h1>

      <div class="form-group">
        <app-input
          label="Proposed new company name"
          formControlName="proposedCompanyName"
        ></app-input>
      </div>

      <div class="form-group">
        <app-input
          label="Name reservation document number"
          formControlName="reservationDocumentNumber"
          [customErrors]="reservationNumberCustomErrors"
        ></app-input>
      </div>

      <div class="form-group">
        <app-date-picker
          label="Date of reservation expiry"
          formControlName="existingExpiryDate"
          [minDate]="minDate"
          [maxDate]="maxDate"
          [customErrors]="customDatepickerErrors"
        ></app-date-picker>
      </div>

      <div class="form-group">
        <app-radio
          label="Payment Method"
          formControlName="paymentMethod"
          [options]="paymentMethodOptions"
        ></app-radio>
      </div>
    </section>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.ExtensionDetails"
      [hidden]="currentStep !== StepsEnum.ExtensionDetails"
    >
      <h1 class="h1-heading">{{ steps[currentStep].label }}</h1>

      <div class="form-group">
        <p class="sub-heading text-gray900">
          Provide the reason for the name reservation extension in the textbox below. Note that the extension can only be requested twice, and reasons must be provided each time.
        </p>
      </div>

      <div class="form-group">
        <app-textarea
          label="Extension Reason"
          formControlName="extensionReason"
        ></app-textarea>
      </div>
    </section>
  </div>
</app-stepper-form>
