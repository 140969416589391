import { Component } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from 'ag-grid-community';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from "@ng-bootstrap/ng-bootstrap";
import { UserRecord } from "../../../../../../models/userRecord";
import { AgActionIconButtonComponent } from "../ag-action-icon-button/ag-action-icon-button.component";

type ActionEvent = (data: UserRecord | undefined) => void;

type CellRendererParams = ICellRendererParams<UserRecord> & {
  edit: ActionEvent,
  delete: ActionEvent,
  resetPassword: ActionEvent,
  resetMFA: ActionEvent,
};

@Component({
  selector: 'app-account-management-grid-actions',
  standalone: true,
  imports: [
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
    AgActionIconButtonComponent
  ],
  templateUrl: './account-management-grid-actions.html',
})
export class AccountManagementGridActions implements ICellRendererAngularComp {
  protected params!: CellRendererParams;

  agInit(params: CellRendererParams): void {
    this.params = params;
  }

  refresh(params: CellRendererParams): boolean {
    this.params = params;
    return true;
  }

  onEdit(): void {
    this.params.edit(this.params.data);
  }

  onResetMFA(): void {
    this.params.resetMFA(this.params.data);
  }

  onResetPassword(): void {
    this.params.resetPassword(this.params.data);
  }

  onDelete(): void {
    this.params.delete(this.params.data);
  }
}
