export enum SecurityTransactionType {
  //Incremental
  Cancellation,
  Issue,

  //Pair
  TransferFrom,
  TransferTo,
  SubdivisionFrom,
  SubdivisionTo,
  ConsolidationFrom,
  ConsolidationTo,
  ChangeAmountPaidFrom,
  ChangeAmountPaidTo,
  ConversionFrom,
  ConversionTo,

  //Balance
  Balance
}
