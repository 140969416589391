import { Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from "@ng-bootstrap/ng-bootstrap";
import { Relationship } from "../../../models/relationship";
import { DatePipe, NgForOf, NgIf } from "@angular/common";
import { OfficerStatusPipe } from "../../../pipes/officerStatusPipe";
import { RelationshipTypePipe } from "../../../pipes/enumsPipes/relationshipTypePipe";
import { ListGridComponent } from "../common/grid/components/list-grid/list-grid.component";
import { NgArrayPipesModule } from "ngx-pipes";
import { ColDef } from "ag-grid-community";
import {
  AgOfficeholdersStatusComponent
} from "../common/grid/components/ag-officeholders-status/ag-officeholders-status.component";
import {
  AgOpenActionComponent
} from "../common/grid/components/ag-open-action/ag-open-action.component";
import { OfficeholderProfileComponent } from "../officeholder-profile/officeholder-profile.component";
import { HighlightOfficeholder } from "../../../models/highlightOfficeholder";
import {
  AgOfficeholderHighlightNameComponent
} from "../common/grid/components/ag-officeholder-highlight-name/ag-officeholder-highlight-name.component";
import { DocumentRecord } from "../../../models/documentRecord";

@Component({
  selector: 'app-officeholders',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
    OfficerStatusPipe,
    RelationshipTypePipe,
    ListGridComponent,
    NgArrayPipesModule,
    OfficeholderProfileComponent,
  ],
  providers: [OfficerStatusPipe, RelationshipTypePipe],
  templateUrl: './officeholders.component.html',
  styleUrl: './officeholders.component.scss'
})
export class OfficeholdersComponent implements OnInit, OnChanges {
  private relationshipTypePipe: RelationshipTypePipe = inject(RelationshipTypePipe);
  private datePipe: DatePipe = inject(DatePipe);

  @Input() entityId!: string;
  @Input() pendingButtonState = true;
  @Input() previousOfficeholders!: Relationship[];
  @Input() officeholders!: Relationship[];
  @Input() officeholderRows!: HighlightOfficeholder[];
  @Input() pendingDocuments!: DocumentRecord[];
  @Input() disableControls = false;
  @Output() profileViewChange = new EventEmitter<boolean>();
  @Output() ceaseRegisteredAgent = new EventEmitter<Relationship[]>();
  @Output() companyChangePartyName = new EventEmitter<Relationship[]>();
  @Output() changeOfficerAddress = new EventEmitter<Relationship[]>();
  @Output() selectOfficeholders = new EventEmitter<Relationship[]>();
  @Output() editOfficer = new EventEmitter<Relationship>();

  colDefs: ColDef[] = [
    {
      headerName: 'Officeholder',
      field: 'individualDataOverride.fullName',
      width: 254,
      flex: 2,
      cellRenderer: AgOfficeholderHighlightNameComponent,
      editable: false,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: 'Status',
      field: 'end',
      width: 130,
      flex: 1,
      cellRenderer: AgOfficeholdersStatusComponent,
    },
    {
      headerName: 'Position',
      field: 'type',
      width: 180,
      flex: 1,
      valueFormatter: params => this.position(params.value)
    },
    {
      headerName: 'Appointment Date',
      field: 'start',
      width: 150,
      flex: 1,
      valueFormatter: params => this.formatDate(params.value)
    },
    {
      headerName: 'Cessation Date',
      field: 'end',
      width: 150,
      flex: 1,
      valueFormatter: params => this.formatDate(params.value)
    },
    {
      field: '',
      width: 50,
      sortable: false,
      cellClass: 'actions-button-cell',
      cellRenderer: AgOpenActionComponent,
      cellRendererParams: {
        open: this.openOfficeholdersProfile.bind(this),
      },
    }
  ];

  rowSelection: 'single' | 'multiple' = 'multiple';
  checkedOfficeholders: Relationship[] = [];
  openedOfficeholder?: Relationship;
  openedPreviousOfficeholder?: Relationship;
  openedOfficeholderId: string | null = '';
  readonly defaultPageSize = 20;

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['officeholders']?.currentValue) {
      const openedOfficeholder = (changes['officeholders']?.currentValue as Relationship[])?.find(item => {
        return item.relationshipId === this.openedOfficeholderId
      });

      if(openedOfficeholder) {
        this.openedOfficeholder = openedOfficeholder;
      }
    }

    if(changes['previousOfficeholders']?.currentValue) {
      const openedPreviousOfficeholder = (changes['previousOfficeholders']?.currentValue as Relationship[])?.find(item => {
        return item.relationshipId === this.openedOfficeholderId
      });

      if(openedPreviousOfficeholder) {
        this.openedPreviousOfficeholder = openedPreviousOfficeholder;
      }
    }
  }

  ngOnInit(): void {
    this.profileViewChange.subscribe((isProfileView: boolean) => {
      if (!isProfileView) {
        this.openedOfficeholder = undefined;
        this.openedPreviousOfficeholder = undefined;
        this.openedOfficeholderId = null;
      }
    });
  }

  onSelectOfficeholders(officeholders: Relationship[]): void {
    this.checkedOfficeholders = officeholders;
    this.selectOfficeholders.emit(officeholders);
  }

  openOfficeholdersProfile(officeholder: Relationship): void {
    this.openedOfficeholder = officeholder;
    this.openedPreviousOfficeholder = this.previousOfficeholders.find(item => {
      return item.relationshipId === officeholder.relationshipId
    });
    this.openedOfficeholderId = officeholder.relationshipId;
    this.profileViewChange.emit(true);
  }

  private position(relationshipType: number): string {
    return this.relationshipTypePipe.transform(relationshipType) || '-';
  }

  private formatDate(dateValue: string): string {
    return this.datePipe.transform(dateValue, 'dd MMM yyyy') ?? '-';
  }
}
