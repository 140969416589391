import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import {
  NgbAccordionBody,
  NgbAccordionCollapse,
  NgbAccordionDirective,
  NgbAccordionHeader,
  NgbAccordionItem,
  NgbAccordionToggle,
  NgbTooltip
} from '@ng-bootstrap/ng-bootstrap';
import { INavMenuItem } from '../nav-menu-item.interface';

@Component({
  selector: 'app-nav-menu-link',
  standalone: true,
  imports: [
    RouterLink,
    NgClass,
    NgbTooltip,
    RouterLinkActive,
    NgbAccordionDirective,
    NgbAccordionItem,
    NgbAccordionHeader,
    NgbAccordionToggle,
    NgbAccordionCollapse,
    NgbAccordionBody
  ],
  templateUrl: './nav-menu-link.component.html',
  styleUrl: './nav-menu-link.component.scss',
})
export class NavMenuLinkComponent {
  @Input() navMenuitemData!: INavMenuItem;
  @Input() collapsedMenu!: boolean;
  @Input() isTopLevelElement = false;

  isActive = false;
}
