import { Component, inject, OnInit } from '@angular/core';
import { PageTitleComponent } from "../../../components/common/page-title/page-title.component";
import { ListGridComponent } from "../../../components/common/grid/components/list-grid/list-grid.component";
import { ColDef } from "ag-grid-community";
import {
  AgReminderSendingMethodLabelComponent, ReminderSendingMethods
} from "./components/ag-reminder-sending-method-label/ag-reminder-sending-method-label.component";
import {
  AgEmailTemplateActionsComponent
} from "../email-templates-page/components/ag-email-template-actions/ag-email-template-actions.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RemindersService } from "../../../../services/reminders.service";
import { finalize } from "rxjs";
import { EditReminderModalComponent } from "./components/edit-reminder-modal/edit-reminder-modal.component";
import { ToastrService } from "ngx-toastr";

export interface IReminderItem {
  id: string;
  name: string;
  dates: number[];
  sendingMethod: ReminderSendingMethods | null;
  autoSending: boolean;
  time: string | null;
}

@Component({
  selector: 'app-reminders-page',
  standalone: true,
  providers: [RemindersService],
  imports: [
    PageTitleComponent,
    ListGridComponent
  ],
  templateUrl: './reminders-page.component.html',
  styleUrls: ['./reminders-page.component.scss', '../../settings-styles.scss']
})
export class RemindersPageComponent implements OnInit {
  private remindersService = inject(RemindersService);
  private modalService = inject(NgbModal);
  private toastr = inject(ToastrService);

  isLoading = false;
  editRemindersModalOpened = false;
  reminders: IReminderItem[] = [];
  colDefs: ColDef[] = [];

  ngOnInit(): void {
    this.setupColDefs();
    this.loadReminders();
  }

  edit(reminder: IReminderItem): void {
    if (this.editRemindersModalOpened) return;
    this.editRemindersModalOpened = true;
    const modalRef = this.modalService.open(EditReminderModalComponent, { size: 'sm' });
    const instance = modalRef.componentInstance as EditReminderModalComponent;

    instance.reminder = structuredClone(reminder);

    modalRef.result.then(
      (updatedReminder: IReminderItem) => {
        // TODO: replace when API ready
        const foundIndex = this.reminders.findIndex((reminder) => reminder.id === updatedReminder.id);
        if (foundIndex >= 0) {
          this.reminders[foundIndex] = updatedReminder;
        }

        this.reminders = this.reminders.map((v) => v);
        this.toastr.success('Reminder settings saved.', 'Success');
      },
      () => {}
    ).finally(() => this.editRemindersModalOpened = false);
  }

  private loadReminders(): void {
    this.isLoading = true;

    this.remindersService.loadReminders()
      .pipe(finalize(() => this.isLoading = false))
      .subscribe((reminders) => this.reminders = reminders);
  }

  private setupColDefs(): void {
    this.colDefs = [
      {
        headerName: 'Reminders',
        field: 'name',
        sort: 'asc',
        flex: 4,
      },
      {
        headerName: 'Method of Sending',
        width: 230,
        minWidth: 100,
        cellRenderer: AgReminderSendingMethodLabelComponent,
        flex: 2,
      },
      {
        headerName: 'Date of sending before the due date',
        field: 'dates',
        valueGetter: (params) => ((params.data as IReminderItem).dates ?? []).map((day) => `${ day } days`),
        flex: 3,
      },
      {
        headerName: 'Time',
        field: 'time',
        width: 100,
        minWidth: 100,
        flex: 2,
      },
      {
        cellRenderer: AgEmailTemplateActionsComponent,
        cellRendererParams: {
          edit: this.edit.bind(this)
        },
        sortable: false,
        width: 60,
        minWidth: 60,
        suppressHeaderMenuButton: true,
      }
    ];
  }
}
