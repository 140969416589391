@if(isDisabled) {
  <app-button
    [text]="'Group by'"
    [btnColor]="'white'"
    [iconClass]="'icon ic-group-by'"
    [isDisabled]="isDisabled"
  ></app-button>
} @else if(selectedGroupByIndex > -1) {
  <div class="grid-search-filter" ngbDropdown #dropdown="ngbDropdown">
    <div class="filter-tag" ngbDropdownToggle>
      <i class="icon ic-group-by"></i>
      <div class="column-name">Group by</div>
      <i class="fa fa-sm fa-chevron-right"></i>
      <div class="filter-name">{{ groupByOptions[selectedGroupByIndex].label }}</div>
      <i class="icon ic-tiny ic-close" (click)="onClearGroupBy()"></i>
    </div>

    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
      @for (option of groupByOptions; let groupByOptionIndex = $index; track groupByOptionIndex) {
        <div class="column-option d-flex">
          <app-checkbox
            [label]="option.label"
            [value]="option.active"
            (change)="onChangeGroupByOption(groupByOptionIndex)"
          ></app-checkbox>
        </div>
        @if($first) {
          <app-divider></app-divider>
        }
      }
    </div>
  </div>
} @else {
  <div class="grid-search-filter" ngbDropdown #dropdown="ngbDropdown">
    <app-button
      ngbDropdownToggle
      [text]="'Group by'"
      [btnColor]="'white'"
      [iconClass]="'icon ic-group-by'"
    ></app-button>

    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
      @for (option of groupByOptions; let groupByOptionIndex = $index; track groupByOptionIndex) {
        <div class="column-option d-flex">
          <app-checkbox
            [label]="option.label"
            [value]="option.active"
            (change)="onChangeGroupByOption(groupByOptionIndex)"
          ></app-checkbox>
        </div>
        @if($first) {
          <app-divider></app-divider>
        }
      }
    </div>
  </div>
}
