import { ChangeDetectorRef, Component, DestroyRef, inject, Input, OnInit, Optional } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  ReactiveFormsModule,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CustomFormValidators } from '../../../../custom-form-validators/custom-form-validators';
import { DatepickerHelper } from '../../../../custom-form-validators/date-picker-form-validators';
import { setControlDisabled } from '../../../../functions/set-control-disabled';
import { Address } from '../../../../models/address';
import {
  A1CompanyAddressChangeFormGroupControls
} from '../../../modals/documents/asic-forms/484-forms/a1-company-address-change/a1-company-address-change.component';
import { AddressControlComponent, AddressFormGroup, } from '../address-control/address-control.component';
import { DatePickerComponent } from '../date-picker/date-picker.component';
import { InputComponent } from '../input/input.component';
import { RadioControl } from '../radio/radio.component';
import { YesNoControlComponent } from '../yes-no-control-component/yes-no-control.component';

export interface AddressAndOccupierFormGroupControls {
  address: AddressFormGroup; // sets in the child component
  occupiesTheAddress: RadioControl<boolean>;
  occupierNameIfDoesntOccupy: FormControl<string | null>;
  companyHaveOccupiersConsent: RadioControl<boolean>;
  changeDate: FormControl<Date | null>;
}

export type AddressAndOccupierFormGroup = FormGroup<AddressAndOccupierFormGroupControls>;

@Component({
  selector: 'app-address-and-occupier',
  standalone: true,
  imports: [
    AddressControlComponent,
    ReactiveFormsModule,
    DatePickerComponent,
    InputComponent,
    YesNoControlComponent
  ],
  templateUrl: './address-and-occupier.component.html',
  styleUrl: './address-and-occupier.component.scss',
})
export class AddressAndOccupierComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  cdr = inject(ChangeDetectorRef);

  @Input() useInternationalAddresses = false;
  @Input() address: Address | null = null;
  @Input() occupiesTheAddress: boolean | null = null;
  @Input() occupierNameIfDoesntOccupy: string | null = null;
  @Input() expandedAddressForm = false;
  @Input() minDateOfAddressChange: NgbDateStruct = DatepickerHelper.getTodayStruct();
  @Input() maxDateOfAddressChange: NgbDateStruct = DatepickerHelper.getNextYearStruct();
  @Input('occupierPartHidden') set setIfOccupierPartVisible(value: boolean) {
    this.occupierPartHidden = value;
    this.form.controls.occupiesTheAddress.patchValue(this.occupiesTheAddress);
    setControlDisabled(this.form.controls.occupiesTheAddress, value);
    if(!value) {
      this.form.controls.occupierNameIfDoesntOccupy.addValidators(Validators.required);
    }

    this.cdr.detectChanges();
  }

  form: AddressAndOccupierFormGroup = new UntypedFormGroup({
    occupiesTheAddress: new FormControl<boolean | null>(true, [Validators.required]),
    occupierNameIfDoesntOccupy: new FormControl<string | null>(null),
    companyHaveOccupiersConsent: new FormControl<boolean | null>(null, [CustomFormValidators.valueMatchValidator(true)]),
    changeDate: new FormControl<Date | null>(DatepickerHelper.getToday()),
  }) as AddressAndOccupierFormGroup;

  occupierPartHidden = false

  constructor(@Optional() private formGroupDirective: FormGroupDirective) {
  }

  ngOnInit(): void {
    this.listenOccupiesTheAddress();
    this.form.controls.occupiesTheAddress.setValue(this.occupiesTheAddress);
    this.form.controls.occupierNameIfDoesntOccupy.patchValue(this.occupierNameIfDoesntOccupy);
    this.form.controls.companyHaveOccupiersConsent.patchValue(true);

    if (this.formGroupDirective.form) {
      // sets the controls of this component's form to the closest [formGroup] in the template of the parent
      Object.entries(this.form.controls)
        .forEach(([key, control]) => this.formGroupDirective.form.setControl(key as keyof A1CompanyAddressChangeFormGroupControls, control));
    }
  }

  private listenOccupiesTheAddress(): void {
    this.form.controls.occupiesTheAddress.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((occupiesTheAddress) => {
        setControlDisabled(this.form.controls.occupierNameIfDoesntOccupy, !!occupiesTheAddress);
        setControlDisabled(this.form.controls.companyHaveOccupiersConsent, !!occupiesTheAddress);
      });
  }

  get occupierNameHidden(): boolean {
    return this.form.controls.occupiesTheAddress.value !== false;
  }
}
