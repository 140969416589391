import { Tag } from "./tag";
import { FileCreateDto } from "./files";

export class CoverLetter {
  id: string;
  organisationId: string;
  title: string;
  fileName: string;
  tags: Tag[];
  isDefault: boolean;
  createdOn: Date;

  constructor(data: Partial<CoverLetter>) {
    this.id = data.id ?? '';
    this.organisationId = data.organisationId ?? '';
    this.title = data.title ?? '';
    this.fileName = data.fileName ?? '';
    this.tags = data.tags?.map(t => new Tag(t)) ?? [];
    this.isDefault = data.isDefault ?? false;
    this.createdOn = data.createdOn ? new Date(data.createdOn) : new Date();
  }
}

export class CoverLetterCreateModel {
  title: string;
  file: FileCreateDto;
  tagIds: string[];

  constructor(data: Partial<CoverLetterCreateModel> = {}) {
    this.title = data.title ?? '';
    this.tagIds = data.tagIds ?? [];
    this.file = data.file!;
  }
}
