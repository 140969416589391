import { ChangeDetectionStrategy, Component, EventEmitter, input, Output } from '@angular/core';
import {
  AgActionIconButtonComponent
} from '../../../common/grid/components/ag-action-icon-button/ag-action-icon-button.component';

@Component({
  selector: 'app-attached-file-label',
  standalone: true,
  imports: [AgActionIconButtonComponent],
  templateUrl: './attached-file-label.component.html',
  styleUrl: './attached-file-label.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachedFileLabelComponent {
  filename = input<string>();
  readonly = input<boolean>(true);
  disabled = input<boolean>(false);
  isLoading = input<boolean>(false);
  hideDeleteBtn = input<boolean>(false);
  hideDownloadBtn = input<boolean>(false);

  @Output() downloadFile = new EventEmitter<void>();
  @Output() deleteFile = new EventEmitter<void>();

  onDownloadFile(): void {
    this.downloadFile.emit();
  }

  onDeleteFile(): void {
    this.deleteFile.emit();
  }

  fileLabelClick(): void {
    if (!this.readonly())
      return;

    this.onDownloadFile();
  }
}
