import { Pipe, PipeTransform } from '@angular/core';
import { CompanyType } from '../../models/enums/companyEnums';
@Pipe({
  standalone: true,
  name: 'companyType'
})
export class CompanyTypePipe implements PipeTransform {
  transform(value: CompanyType | undefined): string {
    if(value != undefined)
      return CompanyType[value];
    return '-';
  }
}