<app-common-offcanvas [opened]="opened()" (close)="closeOffcanvas()">
  <div class="header">
    <h2 class="h2-heading m-0 p-0">
      @if (!isOuterLoading) {
        {{ emailRecord?.subject }}
      }
    </h2>

    <div class="alert-pagination-container">
      <div class="pages">{{ currentEmailMessageItemNumber }} of {{ totalRows }}</div>
      <div class="arrow-btn-container">
        <app-ag-action-icon-button
          iconClass="ic-arrow-left"
          tooltipText="Previous Email"
          [disabled]="currentEmailMessageItemNumber <= 1 || isMessageActionLoading || isOuterLoading"
          (action)="openPreviousEmail()"
        />

        <app-ag-action-icon-button
          iconClass="ic-arrow-right"
          tooltipText="Next Email"
          [disabled]="currentEmailMessageItemNumber >= totalRows || isMessageActionLoading || isOuterLoading"
          (action)="openNextEmail()"
        />
      </div>
    </div>
  </div>

  @if (emailRecord && !isOuterLoading) {
    <div class="content-wrapper">
      <div class="content">
        @if (emailRecord.errorMessage) {
          <div class="error-message-wrapper">
            <app-disclaimer color="red" icon="ic-circle-cancel-20">
              {{ emailRecord.errorMessage }}
            </app-disclaimer>
          </div>
        }
        <div class="email-date-status">
          <div>
            <div class="title">Date & Time</div>
            <div class="text-gray900 font-weight-bold">
              {{ emailRecord.createdOn | date : 'dd MMM yyyy HH:mm' : 'en-US' }}
            </div>
          </div>

          <div>
            <div class="title">Status</div>
            <div>
              <app-ag-outgoing-message-status [status]="emailRecord.status"/>
            </div>
          </div>
        </div>

        <div class="recipients">
          <app-email-recipients [emailRecord]="emailRecord" [showSender]="true"/>
        </div>

        <div class="email-preview-wrapper preview-wrapper">
          @if (isEmailModelLoading) {
            <div class="email-body template-wrapper">
              <div class="d-flex flex-column gap-24">
                <ngx-skeleton-loader count="3" appearance="line" />
                <div class="d-flex gap-24">
                  <ngx-skeleton-loader style="width: 50%" count="10" appearance="line" />
                  <ngx-skeleton-loader style="width: 50%" count="10" appearance="line" />
                </div>
              </div>
            </div>
          } @else if (htmlContent()) {
            <div class="email-body">
              <app-shadow-dom-viewer [customStyle]="customStyle" [htmlContent]="htmlContent()"/>
            </div>
          }
        </div>
      </div>

      <div class="controls">
        @if (resentEmailDate) {
          <div>
            <div class="title">Email was resent on</div>
            <div>
              {{ resentEmailDate | date : 'dd MMM yyyy HH:mm' : 'en-US' }}
            </div>
          </div>
        }

        @if (emailRecord.status === EmailStatus.Failed) {
          <app-button
            text="Resend"
            iconClass="icon ic-sent-mail"
            [isDisabled]="isMessageActionLoading"
            (clickBtn)="resendEmail()"
          />
        }

        <!-- available only for admins -->
        <app-button
          btnColor="white"
          text="Delete"
          iconClass="icon ic-trash"
          [isDisabled]="isMessageActionLoading"
          (clickBtn)="deleteEmail()"
        />
      </div>
    </div>
  } @else if (isOuterLoading) {
    <app-loader-standalone [loading]="true"/>
  }
</app-common-offcanvas>
