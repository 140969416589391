import { Component } from '@angular/core';
import { ICellRendererParams } from "ag-grid-community";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { Trust } from '../../../../../../models/trust';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { AgActionIconButtonComponent } from '../../components/ag-action-icon-button/ag-action-icon-button.component';

type ActionEvent = (data: Trust | undefined) => void;

type CellRendererParams = ICellRendererParams<Trust> & {
  openTrustProfile: ActionEvent,
  appointAccountManager: ActionEvent,
  exportTrustProfile: ActionEvent,
  managePins: ActionEvent,
  openAlerts: ActionEvent,
  openNotes: ActionEvent,
};

@Component({
  selector: 'app-ag-trust-actions',
  standalone: true,
  imports: [
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
    AgActionIconButtonComponent
  ],
  templateUrl: './ag-trust-actions.component.html',
  styleUrl: './ag-trust-actions.component.scss'
})
export class AgTrustActionsComponent implements ICellRendererAngularComp {
  protected params!: CellRendererParams;
  protected numberOfAlerts = 0;

  agInit(params: CellRendererParams): void {
    this.params = params;
    this.numberOfAlerts = this.params.data?.alerts?.length ?? 0;
  }

  refresh(params: CellRendererParams): boolean {
    this.params = params;
    return true;
  }

  onOpenTrustProfile(): void {
    this.params.openTrustProfile(this.params.data);
  }

  onAppointAccountManager(): void {
    this.params.appointAccountManager(this.params.data);
  }

  onExportTrustProfile(): void {
    this.params.exportTrustProfile(this.params.data);
  }

  onManagePins(): void {
    this.params.managePins(this.params.data);
  }

  onOpenAlerts(): void {
    this.params.openAlerts(this.params.data);
  }

  onOpenNotes(): void {
    this.params.openNotes(this.params.data);
  }
}

