@if (acn || name.includes('Ltd') || name.includes('ltd')) {
  <app-entity-name-number-record
    class="trust-name-abn"
    [showIcon]="true"
    [name]="name"
    [numberFormatted]="acn | acn"
  ></app-entity-name-number-record>
} @else if (abn || name.includes('Trust') || name.includes('trust')) {
  <app-entity-name-number-record
    class="trust-name-abn"
    [showIcon]="true"
    [iconClass]="'ic-trusts ic-md icon'"
    [name]="name"
    [numberFormatted]="abn | abn"
  ></app-entity-name-number-record>
} @else {
  <app-entity-name-number-record
    class="trust-name-abn"
    [name]="name"
  ></app-entity-name-number-record>
}
