import { Component, EventEmitter, inject, Input, Optional, Output } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, ReactiveFormsModule, Validators } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { setControlDisabled } from '../../../../functions/set-control-disabled';
import { AuxiliaryService } from '../../../../services/auxiliary.service';
import { acnValidator } from '../../../../validators/acn.validator';
import { companyNameValidator } from '../../../../validators/compny-name.validator';
import { InputComponent } from '../input/input.component';
import { MaskedInputComponent } from '../masked-input/masked-input.component';

export interface CompanyNameAcnFormControls {
  name: FormControl<string | null>;
  acn: FormControl<string | null>;
}

export type CompanyNameAcnForm = FormGroup<CompanyNameAcnFormControls>;

@Component({
  selector: 'app-company-name-acn',
  templateUrl: './company-name-acn.component.html',
  styleUrl: './company-name-acn.component.scss',
  imports: [
    ReactiveFormsModule,
    MaskedInputComponent,
    InputComponent
  ],
  standalone: true
})
export default class CompanyNameAcnComponent {
  abnLookupService = inject(AuxiliaryService);

  @Input() set companyName(value: string | null | undefined) {
    this.companyNameControl.setValue(value ?? null);
  }

  @Input() set acn(value: string | null | undefined) {
    this.companyAcn.setValue(value ?? null);
  }

  @Input() companyNameFormControlName = 'companyName';
  @Input() acnFormControlName = 'companyAcn';
  @Input() acnTitle = 'ACN';
  @Input() nameTitle = 'Company Name';
  @Input() hideAcn = false;
  @Input() shouldLookForCompanyName = true;

  @Input() set disable(value: boolean) {
    setControlDisabled(this.companyAcn, value);
    setControlDisabled(this.companyNameControl, value);
  }

  @Output() formSubmit = new EventEmitter<{ name: string, acn: string }>();

  cantFindCompanyName = false;

  companyAcn = new FormControl('', { updateOn: 'blur', validators: [Validators.required, acnValidator()] });
  companyNameControl = new FormControl('', [Validators.required, Validators.minLength(7), companyNameValidator()]);

  constructor(@Optional() private formGroupDirective: FormGroupDirective) {
  }

  ngOnInit(): void {
    if (this.formGroupDirective.form) {
      if (this.acnFormControlName) {
        this.formGroupDirective.form.setControl(this.acnFormControlName, this.companyAcn);
      }

      if (this.companyNameFormControlName) {
        this.formGroupDirective.form.setControl(this.companyNameFormControlName, this.companyNameControl);
      }
    }
  }

  async onAcnInputBlur() {
    if (this.shouldLookForCompanyName && this.companyAcn && this.companyAcn.valid) {
      try {
        const result = await firstValueFrom(this.abnLookupService.getCompanyNameByACn(String(this.companyAcn?.value)));

        if (!this.shouldLookForCompanyName)
          return;

        this.companyNameControl?.setValue(result.entityName);
        this.cantFindCompanyName = false;
      } catch (error) {
        this.cantFindCompanyName = true;
      }
    } else if (this.shouldLookForCompanyName)
      this.companyNameControl?.setValue('');
  }
}
