import { Injectable, signal } from '@angular/core';
import { ColumnWithExportName, ExtendedColGroupDef } from "../models/columnWithExportName";
import {
  ColDef,
  ColGroupDef, ExcelCell, ExcelRow, ExcelStyle,
  GridApi,
  ICellRendererParams,
  ProcessCellForExportParams, ProcessHeaderForExportParams, ProcessRowGroupForExportParams,
  ValueFormatterParams
} from "ag-grid-community";
import {
  AgShareActionsComponent
} from "../app/components/common/grid/components/shares/ag-share-actions/ag-share-actions.component";
import { CurrencyPipe, formatDate } from "@angular/common";
import {
  AgShareTransactionTypeComponent
} from "../app/components/common/grid/components/shares/ag-share-transaction-type/ag-share-transaction-type.component";
import {
  AgShareHolderComponent
} from "../app/components/common/grid/components/shares/ag-share-holder/ag-share-holder.component";
import { FilterOption } from "../models/gridFilter";
import { CellDataType } from "../models/enums/agGridEnums";
import {
  AgCompanyRegistrationDateComponent
} from "../app/components/common/grid/components/ag-company-registration-date/ag-company-registration-date.component";
import { convertEnumToFilterOption } from "../app/helpers/enum-to-filter-option.helper";
import { SecurityTransactionType } from "../models/enums/securityTransactionTypeEnum";
import { uniqueArrayToFilterOptionHelper } from "../app/helpers/unique-array-to-filter-option.helper";
import { ShareTypePipe } from "../pipes/share-type.pipe";
import { ClientSecurityTransaction } from "../models/clientSecurityTransaction";
import {
  AgShareGroupHeaderComponent
} from "../app/components/common/grid/components/shares/ag-share-group-header/ag-share-group-header.component";
import { IndividualHolderModel } from "../models/securityRegistryRecord";
import {
  AgShareWarningTooltipComponent
} from "../app/components/common/grid/components/shares/ag-share-warning-tooltip/ag-share-warning-tooltip.component";
import {
  AgMasterNumberOfSharesComponent
} from "../app/components/common/grid/components/shares/ag-master-number-of-shares/ag-master-number-of-shares.component";
import {
  AgMasterPaidAmountComponent
} from "../app/components/common/grid/components/shares/ag-master-paid-amount/ag-master-paid-amount.component";

@Injectable({
  providedIn: 'root'
})
export class SharesService {
  companyId = signal<string>('');
  shareDateFilterOptions = signal<FilterOption[]>([]);
  shareTransactionTypeFilterOptions = signal<FilterOption[]>(convertEnumToFilterOption(SecurityTransactionType));
  shareholderNameFilterOptions = signal<FilterOption[]>([]);
  shareTypeIdentifierFilterOptions = signal<FilterOption[]>([]);
  shareTypeFilterOptions = signal<FilterOption[]>([]);
  colDefs = signal<(ColumnWithExportName | ExtendedColGroupDef)[]>([]);
  numberColumnForExport = signal(0);
  readonly autoSizeColumnList = ['numberIncrease', 'numberBalance', 'paidIncrease', 'paidBalance', 'unpaidIncrease', 'unpaidBalance'];

  readonly groupByColDefsBase: (ColumnWithExportName | ColGroupDef)[] = [
    {
      headerName: 'Number of Shares',
      field: "numberOfShares",
      cellRenderer: AgMasterNumberOfSharesComponent,
      flex: 1,
      menuTabs: ['generalMenuTab'],
    },
    {
      headerName: 'Paid Amount',
      field: "paidAmount",
      cellRenderer: AgMasterPaidAmountComponent,
      flex: 1,
      menuTabs: ['generalMenuTab'],
    },
    {
      headerName: 'Unpaid Amount',
      field: "unpaidAmount",
      flex: 1,
      cellStyle: (params: { data: { hasIssue?: boolean }}) => {
        if (params?.data?.hasIssue) {
          return { backgroundColor: '#FBE9E9', color: '#CE5252' };
        } else {
          return { backgroundColor: '#FFFFFF', color: '#000000' };
        }
      },
      valueFormatter: (params: ValueFormatterParams<unknown, string>) => {
        return this.currencyPipe.transform(params.value ?? '', '$', 'code', '1.2-2')!;
      },
      menuTabs: ['generalMenuTab'],
    },
  ];

  groupByShareholderColumnDefs = signal<(ColumnWithExportName | ColGroupDef)[]>([
    {
      field: '',
      width: 50,
      sortable: false,
      headerComponent: AgShareGroupHeaderComponent,
      cellRenderer: "agGroupCellRenderer",
      menuTabs: [],
    },
    {
      headerName: 'Shareholder',
      field: 'shareholder',
      flex: 3,
      cellRenderer: AgShareHolderComponent,
      menuTabs: ['generalMenuTab'],
    },
    ...this.groupByColDefsBase,
  ]);

  groupByShareholderPlusShareTypeColumnDefs = signal<(ColumnWithExportName | ColGroupDef)[]>([
    {
      field: '',
      width: 50,
      sortable: false,
      headerComponent: AgShareGroupHeaderComponent,
      cellRenderer: "agGroupCellRenderer",
      menuTabs: [],
    },
    {
      headerName: 'Shareholder',
      field: 'shareholder',
      flex: 3,
      cellRenderer: AgShareHolderComponent,
      menuTabs: ['generalMenuTab'],
    },
    {
      headerName: 'Share Type',
      field: 'shareType',
      flex: 1,
      cellRenderer: (params: ICellRendererParams<unknown, string>) => {
        return this.shareTypePipe.transform(params.value)
      },
      menuTabs: ['generalMenuTab'],
    },
    ...this.groupByColDefsBase,
  ]);

  groupByShareholderPlusShareClassColumnDefs = signal<(ColumnWithExportName | ColGroupDef)[]>([
    {
      field: '',
      width: 50,
      sortable: false,
      headerComponent: AgShareGroupHeaderComponent,
      cellRenderer: "agGroupCellRenderer",
      menuTabs: [],
    },
    {
      headerName: 'Shareholder',
      field: 'shareholder',
      flex: 3,
      cellRenderer: AgShareHolderComponent,
      menuTabs: ['generalMenuTab'],
    },
    {
      headerName: 'Share Class',
      field: 'shareClass',
      flex: 1,
      menuTabs: ['generalMenuTab'],
    },
    ...this.groupByColDefsBase,
  ]);

  groupByShareClassColumnDefs = signal<(ColumnWithExportName | ColGroupDef)[]>([
    {
      field: '',
      width: 50,
      sortable: false,
      headerComponent: AgShareGroupHeaderComponent,
      cellRenderer: "agGroupCellRenderer",
      menuTabs: [],
    },
    {
      headerName: 'Share Class',
      field: 'shareClass',
      cellRenderer: AgShareWarningTooltipComponent,
      flex: 1,
      menuTabs: ['generalMenuTab'],
    },
    ...this.groupByColDefsBase,
  ]);

  gridApi!: GridApi;

  constructor(private shareTypePipe: ShareTypePipe, private currencyPipe: CurrencyPipe) {
  }

  setSharesOptions(sharesList: ClientSecurityTransaction[]): void {
    if(!sharesList?.length) {
      this.colDefs.set([]);
      return;
    }

    const dateList = sharesList.filter(share => share.transactionDate)?.map(share => share.transactionDate);
    this.shareDateFilterOptions.set([...new Set(dateList)].map(date => {
      return {
        label: formatDate(date ?? '','dd MMM yyyy', 'en'),
        value: date ?? '',
        active: false
      }
    }));

    const shareholderNameList = sharesList.filter(share => share?.holder?.name)?.map(share => share.holder.name);
    this.shareholderNameFilterOptions.set(uniqueArrayToFilterOptionHelper([...new Set(shareholderNameList)]));

    const shareTypeIdentifierList = sharesList.filter(share => share.securityType.identifier)?.map(share => share.securityType.identifier);
    const shareTypeIdentifierFilterOptions = uniqueArrayToFilterOptionHelper([...new Set(shareTypeIdentifierList)]).map(item => {
        item.label = this.shareTypePipe.transform(item.label, false);
        return item;
    });
    this.shareTypeIdentifierFilterOptions.set(shareTypeIdentifierFilterOptions);

    const shareTypeList = sharesList.filter(share => share.securityType.class)?.map(share => share.securityType.class);
    this.shareTypeFilterOptions.set(uniqueArrayToFilterOptionHelper([...new Set(shareTypeList)]));
    this.setGridConfig();
  }

  setGridConfig(): void {
    this.colDefs.set([
      {
        headerName: 'Date',
        field: 'transactionDate',
        width: 80,
        flex: 0.5,
        suppressSizeToFit: true,
        editable: false,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.shareDateFilterOptions(),
        },
        cellDataType: CellDataType.DATE_STRING,
        cellRenderer: AgCompanyRegistrationDateComponent,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        menuTabs: ['generalMenuTab'],
      },
      {
        headerName: 'Type',
        field: 'transactionType',
        width: 80,
        flex: 0.5,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.shareTransactionTypeFilterOptions(),
        },
        cellRenderer: AgShareTransactionTypeComponent,
        menuTabs: ['generalMenuTab'],
      },
      {
        headerName: 'Shareholder',
        field: 'holder',
        width: 80,
        flex: 0.5,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.shareholderNameFilterOptions(),
        },
        filterValueGetter: (params: { data: { holder: { name: string} }}) => params?.data?.holder ? params.data.holder.name : '',
        cellRenderer: AgShareHolderComponent,
        menuTabs: ['generalMenuTab'],
      },
      {
        headerName: 'Share Type',
        field: 'securityType.identifier',
        width: 80,
        flex: 0.5,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.shareTypeIdentifierFilterOptions(),
        },
        cellRenderer: (params: ICellRendererParams<unknown, string>) => {
          return this.shareTypePipe.transform(params.value)
        },
        menuTabs: ['generalMenuTab'],
      },
      {
        headerName: "Number of Shares",
        groupId: "numberOfSharesGroup",
        children: [
          {
            headerName: 'Inc',
            exportColumnName: 'Number of Share Increase',
            field: 'numberIncrease',
            width: 80,
            flex: 0.3,
            hide: false,
            valueFormatter: (params: ValueFormatterParams<unknown, string>) => {
              return this.currencyPipe.transform(params.value ?? '', '', '', '1.0')!;
            },
            menuTabs: [],
          },
          {
            headerName: 'Bal',
            exportColumnName: 'Number of Shares Balance',
            field: 'numberBalance',
            width: 80,
            flex: 0.3,
            hide: false,
            valueFormatter: (params: ValueFormatterParams<unknown, string>) => {
              return this.currencyPipe.transform(params.value ?? '', '', '', '1.0')!;
            },
            menuTabs: [],
          },
        ],
        hide: false,
      },
      {
        headerName: "Paid Amount",
        groupId: "paidAmountGroup",
        children: [
          {
            headerName: 'Inc',
            exportColumnName: 'Paid Increase',
            field: 'paidIncrease',
            width: 80,
            flex: 0.3,
            hide: false,
            valueFormatter: (params: ValueFormatterParams<unknown, string>) => {
              return this.currencyPipe.transform(params.value ?? '', '$', 'code', '1.2-2')!;
            },
            menuTabs: [],
          },
          {
            headerName: 'Bal',
            exportColumnName: 'Paid Balance',
            field: 'paidBalance',
            width: 80,
            flex: 0.3,
            hide: false,
            valueFormatter: (params: ValueFormatterParams<unknown, string>) => {
              return this.currencyPipe.transform(params.value ?? '', '$', 'code', '1.2-2')!;
            },
            menuTabs: [],
          },
        ],
        hide: false,
      },
      {
        headerName: "Unpaid Amount",
        groupId: "unpaidAmountGroup",
        children: [
          {
            headerName: 'Inc',
            exportColumnName: 'Unpaid Increase',
            field: 'unpaidIncrease',
            width: 80,
            flex: 0.3,
            hide: false,
            valueFormatter: (params: ValueFormatterParams<unknown, string>) => {
              return this.currencyPipe.transform(params.value ?? '', '$', 'code', '1.2-2')!;
            },
            menuTabs: [],
          },
          {
            headerName: 'Bal',
            exportColumnName: 'Unpaid of Balance',
            field: 'unpaidBalance',
            width: 80,
            flex: 0.3,
            hide: false,
            valueFormatter: (params: ValueFormatterParams<unknown, string>) => {
              return this.currencyPipe.transform(params.value ?? '', '$', 'code', '1.2-2')!;
            },
            menuTabs: [],
          },
        ],
        hide: false,
      },
      {
        headerName: 'Certificate Number',
        field: 'shareCertificateNumber',
        width: 80,
        flex: 0.3,
        suppressSizeToFit: true,
        menuTabs: ['generalMenuTab'],
      },
      {
        headerName: 'Share Class',
        field: 'securityType.class',
        width: 80,
        flex: 0.5,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.shareTypeFilterOptions(),
        },
        hide: true,
        menuTabs: ['generalMenuTab'],
      },
      {
        headerName: 'Counterparty',
        field: 'counterparty',
        width: 80,
        flex: 0.5,
        suppressSizeToFit: true,
        hide: true,
        menuTabs: ['generalMenuTab'],
      },
      {
        field: '',
        width: 120,
        suppressSizeToFit: true,
        sortable: false,
        cellClass: 'actions-button-cell',
        cellRenderer: AgShareActionsComponent,
        // cellRendererParams: {
        //   annotation: this.annotation.bind(this),
        //   downloadCertificate: this.downloadCertificate.bind(this),
        //   edit: this.edit.bind(this),
        //   delete: this.delete.bind(this)
        // },
        suppressHeaderMenuButton: true,
      }
    ]);
  }

  exportParamsXls() {
    return {
      ...this.exportParams(),
      prependContent: this.getExcelTitleRows(this.numberColumnForExport())
    };
  }

  exportParamsCsv() {
    return this.exportParams();
  }

  exportParams() {
    const shareTypePipe = this.shareTypePipe;

    return {
      processCellCallback(params: ProcessCellForExportParams): string {
        const value: unknown = params.value;
        const shareData = params.node?.data as ClientSecurityTransaction;
        if (params.column.getColId() === 'transactionDate') {
          return formatDate(value as string ?? '', 'dd MMM yyyy', 'en-US');
        } else if(params.column.getColId() === 'transactionType') {
          return SecurityTransactionType[value as number];
        } else if(params.column.getColId() === 'holder') {
          return shareData?.holder ? shareData.holder.name : '';
        } else if(params.column.getColId() === 'securityType.identifier') {
          return shareTypePipe.transform(value as string, false);
        }

        return value as string;
      },
      processHeaderCallback(params: ProcessHeaderForExportParams) {
        const colDef = params.column.getColDef() as ColumnWithExportName
        return colDef?.exportColumnName ?? colDef.headerName;
      },
      sheetName: 'Share',
      fileName: 'Share ' + formatDate(new Date(), 'dd-MM-yyyy', 'en-US')
    }
  }

  getGroupRows = (params: ProcessRowGroupForExportParams) => {
    const selectedColumns = this.gridApi.getAllDisplayedColumns()
      .map((col: { getColDef: () => ColumnWithExportName }) => col?.getColDef())
      .filter(col => col?.field);

    const headerNameCells = selectedColumns.map((col) => this.cell((col?.headerName ?? ''), 'header'));
    const headerValueCells = selectedColumns.map((col) => {
      const field = col?.field ?? '';
      const value = ((params.node.data as Record<string, unknown>)[field] ?? '') as string;
      if (field === 'shareholder') {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return this.cell((params.node.data.shareholder?.individualData?.fullName ?? '') as string, 'headerValue');
      }

      return this.cell(value, 'headerValue');
    });

    const colDefsDetail = (this.colDefs() as (ColDef & ColGroupDef)[]).flatMap(col => {
      if(col?.children?.length) {
        return col.children.map((child: ColDef) => child);
      }
      return col;
    }).filter(col => !col.hide);

    const detailRows = (params.node.data as { callRecords: ClientSecurityTransaction[] }).callRecords.map((record) => {
      return [
        {
          outlineLevel: 1,
          cells: [
            this.cell(''),
            ...colDefsDetail.map(col => {
              const field = col?.field ?? '';
              if (field === 'holder') {
                return this.cell(((record?.[field] as IndividualHolderModel)?.individualData?.fullName ?? ''), 'body');
              } else if(field === 'transactionType') {
                return this.cell(SecurityTransactionType[record.transactionType as number], 'body');
              } else if(field === 'securityType.identifier') {
                return this.cell(record.securityType.identifier, 'body');
              } else if(field === 'transactionDate') {
                const transactionDate = formatDate(record[field as string] as string ?? '', 'dd MMM yyyy', 'en-US')
                return this.cell(transactionDate, 'body');
              }

              return this.cell(record[field] as string, 'body');
            })
          ],
        },
      ]
    });

    const rows = [
      {
        outlineLevel: 1,
        cells: headerNameCells
      },
      {
        outlineLevel: 1,
        cells: headerValueCells
      },
      {
        outlineLevel: 1,
        cells: [this.cell(''), ...colDefsDetail.map((col: ColumnWithExportName) => this.cell(col?.exportColumnName ?? col.headerName ?? '', "header"))],
      },
    ].concat(
      ...detailRows
    );

    return rows;
  };

  cell(text: string, styleId?: string): ExcelCell {
    return {
      styleId: styleId,
      data: {
        type: /^\d+$/.test(text) ? "Number" : "String",
        value: text,
      },
    };
  }

  public getExcelTitleRows: (numberColumnForExport: number) => ExcelRow[] = (numberColumnForExport) => [
    {
      cells: [
        {
          data: {
            value: 'Shares Report',
            type: "String",
          },
          mergeAcross: numberColumnForExport,
          styleId: 'titleStyle',
        },
      ],
    },
    {
      cells: [
        {
          data: {
            value: formatDate(new Date, 'dd/MM/yyyy', 'en-US'),
            type: "String",
          },
          mergeAcross: numberColumnForExport,
          styleId: 'dateStyle',
        },
      ],
    },
  ];

  public readonly excelStyles: ExcelStyle[] = [
    {
      id: 'titleStyle',
      font: {
        size: 18,
        color: '#FFC82C',
        bold: true,
      },
      alignment: {
        horizontal: 'Center',
      },
    },
    {
      id: 'dateStyle',
      font: {
        size: 14,
        color: '#FFC82C',
      },
      alignment: {
        horizontal: 'Center',
      },
    },
    {
      id: "header",
      interior: {
        color: "#aaaaaa",
        pattern: "Solid",
      },
      font: {
        size: 11,
        bold: true,
      },
    },
  ];
}
