import { EmailPreviewRequest } from "./emailPreviewRequest";

export class EmailSendRequest extends EmailPreviewRequest {
  to: string;

  constructor(data: Partial<EmailSendRequest> = {}) {
    super(data);
    this.to = data.to ?? '';
  }
}
