export class AsicBankData {
  bankName: string;
  bankBSB: string;
  bankAccountName: string;
  bankAccountNumber: string;
  dailyCreditLimit: number;

  constructor(data: Partial<AsicBankData> = {}) {
    this.bankName = data.bankName ?? '';
    this.bankBSB = data.bankBSB ?? '';
    this.bankAccountName = data.bankAccountName ?? '';
    this.bankAccountNumber = data.bankAccountNumber ?? '';
    this.dailyCreditLimit = data.dailyCreditLimit ?? 0;
  }
}
