import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'din',
  standalone: true
})
export class DinPipe implements PipeTransform {

  transform(value: string | null | undefined, ...args: unknown[]): string {
    if (!value) {
      return '';
    }

    let str = value.replaceAll(' ', '').replaceAll('\xa0', '');

    if (/^[0-9]+$/.test(str) && str.length === 15) {
      return `${ str.substring(0, 3) } ${ str.substring(3, 8) } ${ str.substring(8, 13) } ${ str.substring(13, 15) }`;
    }

    return '-';
  }

}
