<div class="modal-header">
    <p class="mb-0">Individuals</p>
</div>
<div class="modal-body">
    <table class="table table-middle">
        <thead>
          <tr>
            <th><span class="cursor-pointer">Name <i class="fas fa-sort"></i></span></th>
            <th><span class="cursor-pointer">Date of Birth<i class="fas fa-sort"></i></span></th>
            <th><span class="cursor-pointer">Address <i class="fas fa-sort"></i></span></th>
            <th><span class="cursor-pointer">Director ID <i class="fas fa-sort"></i></span></th>
  
            <th width="1px"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let individual of individualList">
            <td class="text-gray800">{{individual.fullName}}</td>
            <td class="text-gray800">{{individual.dob}}</td>
            <td class="text-gray800">{{individual.address.normalizedFullAddress}}</td>
            <td>{{individual.din}}</td>
            <td><a (click)="select(individual.individualId)" class="btn btn-default">Select</a></td>
          </tr>
        </tbody>
      </table>

    <div class="d-flex justify-content-center" style="margin-top: 1.5rem;">
        <button class="btn btn-default" (click)="close()">Close</button>
    </div>
</div>