import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { SearchInputComponent } from "../components/common/search-input/search-input.component";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { AddressControlComponent } from "../components/common/address-control/address-control.component";
import { AddressAutocompleteComponent } from "../components/common/address-autocomplete/address-autocomplete.component";
import { autocompleteServiceToken } from "../../services/autocomplete.service";
import { AuxiliaryService } from "../../services/auxiliary.service";
import { catchError, delay, filter, forkJoin, map, of, switchMap, tap } from "rxjs";
import { AddressStatus } from "../../models/enums/addressEnums";
import { Address } from "../../models/address";
import { ButtonComponent } from "../components/common/button/button.component";
import {
  BulkCompanyChangeAddress,
  ChangeAddressCompanyList
} from "../modals/bulk-changes/bulk-change-address-form/BulkCompanyChangeAddress.model";
import { Company } from "../../models/company";
import { PageSizeService } from "../components/common/grid/services/page-size.service";
import { ModalFormsService } from "../../services/modal-forms.service";
import { ColDef } from "ag-grid-community";
import { ListGridComponent } from "../components/common/grid/components/list-grid/list-grid.component";
import { BulkChangeData, BulkMatches, MatchesSelected } from "../../models/bulkChangeData";
import {
  AgEntityNameAcnAbnComponent
} from "../components/common/grid/components/ag-entity-name-acn-abn/ag-entity-name-acn-abn.component";
import { AgBulkMatchesComponent } from "../components/common/grid/components/ag-bulk-matches/ag-bulk-matches.component";
import { BulkService } from "../../services/bulk.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Relationship } from "../../models/relationship";
import { CompaniesService } from "../../services/companies.service";
import { RelationshipType } from "../../models/enums/relationshipTypeEnum";

@Component({
  selector: 'app-bulk-changes',
  standalone: true,
  imports: [
    SearchInputComponent,
    ReactiveFormsModule,
    RouterLink,
    AddressControlComponent,
    AddressAutocompleteComponent,
    ButtonComponent,
    ListGridComponent
  ],
  providers: [
    { provide: autocompleteServiceToken, useClass: AuxiliaryService },
  ],
  templateUrl: './bulk-changes.component.html',
  styleUrl: './bulk-changes.component.scss'
})
export class BulkChangesComponent implements OnInit {
  public pageSizeService = inject(PageSizeService);
  private fb: FormBuilder = inject(FormBuilder);
  private addressService = inject(AuxiliaryService);
  private bulkService = inject(BulkService);
  private companiesService = inject(CompaniesService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private modalFormsService = inject(ModalFormsService);
  private destroyRef = inject(DestroyRef);

  addressStatus: AddressStatus | undefined;
  isMatchesSelected = false;
  selectedTrustOnly = false;
  readonly pageTitle = 'Bulk Changes';
  readonly pageInfo = 'This page will allow users to change the address for ' +
    'multiple entities (companies, trusts, and individuals). To begin, enter the entity name or address in the search field.';

  bulkChanges = [
    {
      title: 'Change of Name',
      description: 'Change the name of an individual for multiple entities in one step. To begin, click here and search for the individual.',
      link: '/individuals'
    },
    {
      title: 'Appointment of Officeholder',
      description: 'Appoint an officeholder for multiple companies in one step. Click here, choose the companies, and click Actions.',
      link: '/companies'
    },
    {
      title: 'Cessation of Officeholder',
      description: 'Cease an officeholder for multiple companies in one step. Click here, choose the companies, and click Actions.',
      link: '/companies'
    },
    {
      title: 'Cease as a Registered Agent',
      description: 'To stop being the registered agent for multiple companies at once, click here, select the companies, and click Actions.',
      link: '/companies'
    },
  ];

  form!: FormGroup;
  isDisabled = false;
  bulkRowData: BulkChangeData[] = [];
  bulkMatchesSelected: MatchesSelected[] = [];

  colDefs: ColDef[] = [
    {
      headerName: 'Name',
      field: 'name',
      sort: 'asc',
      flex: 1,
      cellRenderer: AgEntityNameAcnAbnComponent,
      menuTabs: []
    },
    {
      headerName: 'Matches',
      field: 'matches',
      flex: 2,
      cellRenderer: AgBulkMatchesComponent,
      cellRendererParams: {
        matchesSelected: this.matchesSelected.bind(this),
      },
      menuTabs: []
    },
  ];

  ngOnInit(): void {
    this.form = this.fb.group({
      search: [''],
    });

    this.route.queryParams.pipe(
      filter(params => !!params['companiesList'] || !!params['address']),
      switchMap(params => {
        if(params['companiesList']) {
          const companyList: ChangeAddressCompanyList[] = JSON.parse(params['companiesList'] as string) as ChangeAddressCompanyList[] ?? [];
          const getOfficeHolders = companyList.map(c => this.companiesService.officeHolders(c.entityId));

          return forkJoin(getOfficeHolders)
            .pipe(tap(officeHolders => {
              this.bulkRowData = companyList.map((c, index) => {
                const matches = officeHolders[index].map(o => {
                  return {
                    label: o.relationship.individualDataOverride?.fullName ?? '',
                    value: false,
                    type: RelationshipType[o.relationship.type],
                    address: o.relationship.individualDataOverride?.address?.normalizedFullAddress,
                    relationshipId: o.relationship.relationshipId,
                    relationship: o.relationship
                  };
                });

                return {
                  entityId: c.entityId,
                  name: c.name,
                  acn: c.entityNumber,
                  dateOfEstablishment: c.dateOfEstablishment ?? '',
                  matches
                };
              }) as unknown as BulkChangeData[];
            }));
        } else {
          const address = params['address'] as string;
          return this.bulkService.searchByAddress(address).pipe(
            tap(bulkData => {
              this.bulkRowData = bulkData;
            })
          )
        }
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(() => {
      void this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {},
      });
    });
  }

  matchesSelected(dataMatches: BulkMatches[]): void {
    const bulkRowData = this.bulkRowData.map(bulk => {
      const relationshipIds: string[] = [];
      const relationships: Relationship[] = [];
      const matches = bulk.matches.filter(item => item.value && item.address);
      bulk.matches.forEach(item => {
        if(item.value && item.relationshipId) {
          relationshipIds.push(item.relationshipId);
          if (item.relationship) {
            relationships.push(item.relationship);
          }
        }
      });

      return {
        entityId: bulk.entityId,
        acn: bulk.acn ?? '',
        abn: bulk.abn ?? '',
        name: bulk.name ?? '',
        dateOfEstablishment: bulk.dateOfEstablishment ?? '',
        matches,
        relationshipIds,
        relationships
      };
    });

    const selectedBulkRowData = bulkRowData.filter(item => item.relationshipIds.length || item.matches.length);
    this.selectedTrustOnly = selectedBulkRowData.every(item => item.abn);
    this.bulkMatchesSelected = selectedBulkRowData;

    this.isMatchesSelected = !!this.bulkMatchesSelected.length;
  }

  parseAddress(value: Address | string | null): void {
    if (!value || value instanceof Address) {
      this.addressStatus = undefined;
      return;
    }
    this.addressService.getParseAddress(value)
      .pipe(
        filter(({ address }) => !!address),
        map(({ address }) => ({ ...address, country: address.country === 'Australia' ? 'AU' : address.country })),
        tap(address => {
          if (address === null) {
            this.addressStatus = AddressStatus.WARNING;
          } else {
            this.addressStatus = AddressStatus.CHECKED;
          }
        }),
        switchMap(address => {
          if(!address?.normalizedFullAddress) {
            return [];
          }
          return this.bulkService.searchByAddress(address.normalizedFullAddress);
        }),
        catchError((error) => {
          console.error(error);
          return of([]);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(bulkData => {
        this.bulkRowData = bulkData;
      });
  }

  textChange(searchText: string): void {
    if(!searchText) {
      this.isMatchesSelected = false;
    }

    this.form.get('search')?.patchValue(searchText);

    this.bulkService.searchByName(searchText)
      .pipe(
        delay(1000),
        catchError((error) => {
          console.error(error);
          return of([]);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(res => {
        this.bulkRowData = res;
      });
  }

  public bulkChangeAddress(): void {
    if (this.modalOpened()) return;
    const modalRef = this.modalFormsService.openModalWithBulkCompany(
      new BulkCompanyChangeAddress({ bulkMatchesSelected: this.bulkMatchesSelected, trustOnly: this.selectedTrustOnly }),
      new Company(),
      true,
      {},
      {}
    );
    void modalRef.result.then();
  }

  get modalOpened() {
    return this.modalFormsService.modalOpened;
  }
}
