import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DatepickerHelper } from '../../../../custom-form-validators/date-picker-form-validators';
import { Company } from '../../../../models/company';
import { Document } from '../../../../models/document';
import { Entity } from '../../../../models/entity';
import { CompanyChangeData } from '../../../../models/enums/companyChangeData';
import { SecurityChange } from '../../../../models/securityChange';
import { ChangeDictionaryHelper } from '../../../../models/shared/change-dictionary-helper.model';
import { IStep } from '../../../../models/step';
import { ShareSubdivisionConversionComponent } from './share-subdivision-conversion.component';

export enum SubdivisionStepsEnum {
  FormDescription = 0,
  ResolutionSubject = 1,
  SubdivisionConversionConsolidation = 2,
  Estimate = 3
}

export abstract class EntitySecurityConsolidationSubdivision<TEntity extends Entity> extends SecurityChange<TEntity> {
  modifiedClasses: Record<string, number>;

  constructor(data: Partial<EntitySecurityConsolidationSubdivision<TEntity>> = {}) {
    super(data);
    this.modifiedClasses = data.modifiedClasses ?? {};
  }
}

export class CompanySecurityConsolidationSubdivision extends EntitySecurityConsolidationSubdivision<Company> {
  static override readonly $type = 'CompanySecurityConsolidationSubdivision';
  alterationToRights: boolean;
  convertShares: boolean;
  callsLimitedExternallyAdministered: boolean;
  reductionInShareCapital: boolean;
  finAssistanceApprovalByCompanysOwnShareholders: boolean;
  finAssistanceApprovalByShareholdersListedHoldingCompany: boolean;
  finAssistanceApprovalByShareholdersOfUltimateHoldingCompany: boolean;
  otherSubject: boolean;
  sectionNumber: number | null;
  briefDescription: string;
  dateOfResolution: Date;
  resolutionText: string;

  constructor(data: Partial<CompanySecurityConsolidationSubdivision> = {}) {
    super(data);

    this.alterationToRights = data.alterationToRights ?? false;
    this.convertShares = data.convertShares ?? false;
    this.callsLimitedExternallyAdministered = data.callsLimitedExternallyAdministered ?? false;
    this.reductionInShareCapital = data.reductionInShareCapital ?? false;
    this.finAssistanceApprovalByCompanysOwnShareholders = data.finAssistanceApprovalByCompanysOwnShareholders ?? false;
    this.finAssistanceApprovalByShareholdersListedHoldingCompany = data.finAssistanceApprovalByShareholdersListedHoldingCompany ?? false;
    this.finAssistanceApprovalByShareholdersOfUltimateHoldingCompany = data.finAssistanceApprovalByShareholdersOfUltimateHoldingCompany ?? false;
    this.otherSubject = data.otherSubject ?? false;
    this.sectionNumber = data.sectionNumber ?? null;
    this.briefDescription = data.briefDescription ?? '';
    this.dateOfResolution = DatepickerHelper.toDate(data.dateOfResolution);
    this.resolutionText = data.resolutionText ?? '';
  }

  override toDictionary(): { key: string; value: string }[] {
    const dict = new ChangeDictionaryHelper();
    const selectedClass = Object.keys(this.modifiedClasses ?? {})?.[0];

    if (selectedClass) {
      dict
        .add('Changed Share Class', selectedClass)
        .add('Number Increase', this.modifiedClasses?.[selectedClass]?.toString());
    }

    resolutionSubjects.forEach(subject => dict.addYesNo(subject.label, this[subject.key]));

    if (this.otherSubject) {
      dict
        .add('Section Number', this.sectionNumber?.toString())
        .add('Brief Description', this.briefDescription);
    }

    dict
      .addDate('Date of Resolution', this.dateOfResolution)
      .add('Resolution', this.resolutionText);

    return dict.dictionary;
  }

  override fillModal(modalRef: NgbModalRef, document: Document, actualCompany: Company): NgbModalRef {
    modalRef = this.fillModalWithCompany(modalRef, actualCompany, true);
    (modalRef.componentInstance as ShareSubdivisionConversionComponent).companyChangeData.documentId = document.documentId;
    return modalRef;
  }

  override fillModalWithCompany(modalRef: NgbModalRef, company: Company, isEdit: boolean): NgbModalRef {
    const instance = modalRef.componentInstance as ShareSubdivisionConversionComponent;
    instance.isEdit = isEdit;
    instance.companyChangeData = new CompanyChangeData(company);
    instance.formModel = this;

    return modalRef;
  }

  override prepareToRequest(): CompanySecurityConsolidationSubdivision {
    return {
      ...this,
      ...super.prepareToRequest(),
      dateOfResolution: DatepickerHelper.buildDateString(this.dateOfResolution),
    };
  }
}

export interface IShareTypesDistributionEstimate {
  class: string;
  identifier: string;
  numberOfShares: number;
  nominalPrice: number;
  nominalUnpaid: number;
  totalPaid: number;
  totalUnpaid: number;
}

export const SubdivisionSteps: IStep<SubdivisionStepsEnum>[] = [
  { label: 'Form Description', step: SubdivisionStepsEnum.FormDescription },
  { label: 'Subject(s) of Resolution', step: SubdivisionStepsEnum.ResolutionSubject },
  { label: 'Subdivision / Consolidation', step: SubdivisionStepsEnum.SubdivisionConversionConsolidation },
  { label: 'Estimate', step: SubdivisionStepsEnum.Estimate },
];

export const resolutionSubjects = [
  {
    label: 'Alteration to rights of issues or unissued shares',
    key: 'alterationToRights'
  },
  {
    label: 'Convert shares into larger or smaller number (consolidation/subdivision)',
    key: 'convertShares'
  },
  {
    label: 'Calls may be limited to when company is externally administrated',
    key: 'callsLimitedExternallyAdministered'
  },
  {
    label: 'Reduction in share capital',
    key: 'reductionInShareCapital'
  },
  {
    label: 'Financial assistance by a company for acquiring shares in the company or holding company - approval by the company’s own shareholder',
    key: 'finAssistanceApprovalByCompanysOwnShareholders'
  },
  {
    label: 'Financial assistance by a company for acquiring shares in the company or holding company - approval by shareholders of listed holding company.',
    key: 'finAssistanceApprovalByShareholdersListedHoldingCompany'
  },
  {
    label: 'Financial assistance by a company for acquiring shares in the company or holding company - approval by shareholders of the ultimate Australian holidng company',
    key: 'finAssistanceApprovalByShareholdersOfUltimateHoldingCompany'
  },
  {
    label: 'Other',
    key: 'otherSubject'
  },
];

