import { booleanAttribute, Component, Input, OnInit, Optional } from '@angular/core';
import { ControlValueAccessor, FormControl, FormsModule, NgControl, ReactiveFormsModule } from '@angular/forms';
import { SelectOption } from '../../../../models/selectOptions';
import { RadioComponent } from '../radio/radio.component';

@Component({
  selector: 'app-yes-no-control',
  standalone: true,
  imports: [
    FormsModule,
    RadioComponent,
    ReactiveFormsModule
  ],
  templateUrl: './yes-no-control.component.html'
})
export class YesNoControlComponent implements ControlValueAccessor, OnInit {
  @Input() label = '';
  @Input() link = '';
  @Input() text = '';
  @Input() helperText = '';
  @Input() displayValidationError = false;
  @Input() horizontalAlign = true;
  @Input({ transform: booleanAttribute }) disabled = false;
  @Input({ transform: booleanAttribute }) reversedValues = false;
  @Input() customErrors: Record<string, string> = { required: 'Field is required' };

  yesNoSelectOptions: SelectOption[] = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  #value: boolean | null = null;

  constructor(@Optional() protected ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    if (this.reversedValues) {
      this.yesNoSelectOptions = this.yesNoSelectOptions.map(option => ({
        ...option,
        value: !option.value
      }));
    }
  }

  get control(): FormControl {
    return this.ngControl.control as FormControl;
  }

  get value(): boolean | null {
    return this.#value;
  }

  set value(value: boolean | null) {
    this.#value = value;
    this.onChange(this.#value);
  }

  writeValue(value: boolean): void {
    this.value = value;
  }

  onChange: any = (): void => {
  };
  onTouched: any = (): void => {
  };

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
