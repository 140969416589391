import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../environments/environment";
import { ExternalSourceType } from "../models/enums/externalEntityEnums";

@Injectable({
    providedIn: 'root'
  })
  export class EntityIdentityService {
    constructor(private api: HttpClient) { }

    public refresh(entityId: string, externalEntityId: string, sourceType: ExternalSourceType) {
        let params = new HttpParams();
        params = params.append('externalEntityId', externalEntityId);
        params = params.append('sourceType', sourceType);
    
        return this.api.get<void>(`${environment.api_url}/identities/${entityId}/refresh`, { params: params });
    }
    
    public unlink(entityId: string, sourceType :ExternalSourceType) {
        let params = new HttpParams();
        params = params.append('sourceType', sourceType);

        return this.api.get<void>(`${environment.api_url}/identities/${entityId}/unlink`, { params: params });
    }

    public sync(sourceType: ExternalSourceType) {
        return this.api.get<void>(`${environment.api_url}/identities/${sourceType}/sync`);
    }
}