import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IStep } from '../../../../models/step';

@Component({
  selector: 'app-clickable-stepper',
  standalone: true,
  imports: [],
  templateUrl: './clickable-stepper.component.html',
  styleUrl: './clickable-stepper.component.scss'
})
export class ClickableStepperComponent {
  @Input() steps: IStep[] = [];
  @Input() currentStepIndex = 0;
  @Input() shouldControlStepsUsingInput = false;
  @Input() disableSteps = false;

  @Output() stepIndexChange = new EventEmitter<number>();

  onStepChange(index: number): void {
    if (!this.shouldControlStepsUsingInput) {
      this.currentStepIndex = index;
    }
    this.stepIndexChange.emit(index);
  }
}
