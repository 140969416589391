<app-common-modal-wrapper
  header="Manage Tags"
  saveBtnText="Apply Changes"
  [isLoading]="isLoading"
  (close)="onModalClose()"
  (submitModal)="onModalSubmit()"
>
  <div class="manage-tags-modal-content" [formGroup]="form">
    <div class="controls">
      <app-select
        label="Action"
        formControlName="action"
        [options]="ManageTagsModalActionsOptions"
        [valuePrimitive]="true"
      ></app-select>

      <div
        #selectTagsDropdown="ngbDropdown"
        ngbDropdown
        class="tags-input-wrapper"
      >
        <div class="tags-input-label">Tags</div>
        <div ngbDropdownAnchor class="tags-input">
          <ul class="selected-tags-list" (click)="tagsSearchInput.focus()">
            @for (tag of actionTags; track tag) {
              <li class="selected-tag">
                <app-tag [tag]="tag"></app-tag>
              </li>
            }

            <input
              #tagsSearchInput
              ngbDropdownToggle
              formControlName="search"
              class="tags-search-input"
              type="text"
              (input)="onSearchInputChange($event); selectTagsDropdown?.open()"
              (keydown)="removeLastElement($event)"
            >
          </ul>

          <div ngbDropdownMenu class="p-0">
            <app-select-tags-dropdown
              formControlName="search"
              [showOnlyAllTags]="true"
              [searchVisible]="false"
              [tags]="actionTags"
              [allowAddNewTag]="form.controls.action.value != TagActionEnum.Unassign"
              [notFoundMessage]="notFoundMessage"
              (assignTag)="assignTag($event)"
              (addNewTag)="addNewTag()"
            ></app-select-tags-dropdown>
          </div>
        </div>
      </div>
    </div>

    <div class="entities-tags">
      <app-list-grid
        [rowData]="entities"
        [colDefs]="colDefConfigSignal()"
        [suppressRowClickSelection]="true"
        [pagination]="true"
        [paginationPageSize]="20"
        [paginationPageSizeSelector]="false"
        [suppressCellFocus]="true"
      ></app-list-grid>
    </div>
  </div>
</app-common-modal-wrapper>
