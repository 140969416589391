import { AbstractControl, ValidationErrors } from '@angular/forms';

/*

* Standard Company Rules - Name Reservation via Form 410
*
* When reserving a company name prior to the registration of a company an ASIC Form 410 (Name
* Reservation) is required to be lodged. Once the form is lodged, ASIC will provide a reservation number
* which consists of 8 digits and one alphanumeric character (e.g. 2E1234567).
*
* */

export function reservationNumberValidator(control: AbstractControl): ValidationErrors | null {
  const letterAndDigitsPattern = /^[0-9]*[a-zA-Z][0-9]*$/;
  const isValid = letterAndDigitsPattern.test(control.value) && control.value.toString()?.length === 9;

  return isValid ? null : { invalidReservationNumber: { value: control.value } };
}

export const reservationNumberCustomErrors = {
  invalidReservationNumber: 'A reservation number consists of 9 digits and one alphanumeric character (e.g. 2E1234567).'
};
