import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Tag } from '../../../../../../../models/tag';
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { AgActionIconButtonComponent } from "../../ag-action-icon-button/ag-action-icon-button.component";

type ActionEvent = (data: Tag | undefined) => void;
type CellRendererParams = ICellRendererParams<Tag> & { edit: ActionEvent, delete: ActionEvent };

@Component({
  selector: 'app-ag-manage-tags-actions',
  standalone: true,
  imports: [NgbTooltip, AgActionIconButtonComponent],
  templateUrl: './ag-manage-tags-actions.component.html',
})
export class AgManageTagsActionsComponent implements ICellRendererAngularComp {
protected params!: CellRendererParams;

  agInit(params: CellRendererParams): void {
    this.params = params;
  }

  refresh(params: CellRendererParams): boolean {
    this.params = params;
    return true;
  }

  editTag(): void {
    this.params.edit(this.params.data);
  }

  deleteTag(): void {
    this.params.delete(this.params.data);
  }
}
