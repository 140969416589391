import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { ShareholderRelationshipDetails } from '../../../../models/relationship';
import { finalize } from 'rxjs';
import { SecurityService } from '../../../../services/security.service';
import { ToastrService } from 'ngx-toastr';
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { MultipleInputComponent } from "../multiple-input/multiple-input.component";
import { CustomFormValidators } from "../../../../custom-form-validators/custom-form-validators";
import { ButtonComponent } from "../button/button.component";
import { ValidationErrorComponent } from "../validation-error/validation-error.component";

@Component({
  selector: 'app-auth-signatory',
  standalone: true,
  templateUrl: './auth-signatory.component.html',
  styleUrls: ['./auth-signatory.component.scss', '../../common/input/input.component.scss'],
  imports: [
    ReactiveFormsModule,
    ButtonComponent,
    MultipleInputComponent,
    ValidationErrorComponent,
  ]
})
export class AuthSignatoryComponent implements OnInit {
  private securityService = inject(SecurityService);
  private toastr = inject(ToastrService);

  @Input() authSignatoriesControl = new FormControl<string[]>([], { nonNullable: true });
  @Input({ required: true }) details!: ShareholderRelationshipDetails;
  @Input({ required: true }) relationshipId!: string;
  @Input() controlLike = false;
  @Input() tip = '';
  @Input() customErrors = {
    startsWithDigit: 'The name must start with a letter',
    oneLetterPartName: 'Initials aren\'t allowed'
  };

  @Output() onDataSaved = new EventEmitter<string[]>();

  isEdit = false;
  isLoading = false;

  ngOnInit(): void {
    this.authSignatoriesControl.addValidators([
      Validators.required,
      CustomFormValidators.directorNameValidator,
      CustomFormValidators.directorOneLetterNameValidator
    ]);
  }

  save(): void {
    if (this.authSignatoriesControl.invalid || this.isLoading) {
      this.authSignatoriesControl.markAsTouched();
      return;
    }

    this.isLoading = true;
    const authSignatories = this.authSignatoriesControl.value ?? [];

    this.securityService.updateAuthSignatories(this.relationshipId, authSignatories)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: () => {
          this.toastr.success('Authorised signatories updated');
          this.details.authSignatories = authSignatories;
          this.onDataSaved.emit(authSignatories);
          this.isEdit = false;
        },
        error: () => this.toastr.error('Failed to update authorised signatories')
      });
  }

  startEditing(): void {
    if (this.isLoading) return;
    this.authSignatoriesControl.setValue([...(this.details.authSignatories ?? [])]);
    this.isEdit = true;
  }

  cancelEditing(): void {
    if (this.isLoading) return;
    this.authSignatoriesControl.setValue([...(this.details.authSignatories ?? [])]);
    this.isEdit = false;
  }

  get authSignatories(): string {
    return this.details.authSignatories?.length
      ? this.details.authSignatories?.join('; ')
      : '-';
  }
}
