import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'insertLineBreak',
  standalone: true
})
export class InsertLineBreakPipe implements PipeTransform {
  transform(str: string, word: string): string {
    if(str && word && str.includes(word)) {
      const pattern = new RegExp(`(${word})`, 'g');
      const result = str.replace(pattern, '<br>$1');
      return result.replace('<br>', '');
    }

    return str;
  }
}
