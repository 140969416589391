<app-common-modal-wrapper
  closeBtnText="Cancel"
  saveBtnText="Reset"
  header="Reset EDGE Password"
  icon="ic-key"
  [formGroup]="form"
  (submitModal)="save()"
  (close)="cancel()"
>
  <app-input-password
    label="New EDGE Password"
    formControlName="edgeFuturePassword"
  ></app-input-password>
</app-common-modal-wrapper>
