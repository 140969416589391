import { Component, DestroyRef, EventEmitter, inject, Input, Output } from '@angular/core';
import { catchError, of, Subject } from "rxjs";
import { DocumentsService } from "../../../../services/documents.service";
import { NotificationComponent } from "../../../components/common/notification/notification.component";
import { ButtonComponent } from "../../../components/common/button/button.component";
import { Document } from "../../../../models/document";
import {
  CompanyMistakeCorrection
} from "../../../modals/documents/asic-forms/form492-request-correction/request-correction-change.model";
import { ModalFormsService } from "../../../../services/modal-forms.service";
import {
  CompanyWithdrawLodgedDocumentChange
} from "../../../modals/documents/asic-forms/form106-withdraw-lodged-document/withdraw-lodged-document-form.model";
import { AsicStatus } from "../../../../models/enums/asicStatus";
import { ReplacePipe } from "../../../../pipes/replace.pipe";
import { ReplaceStrPipe } from "../../../../pipes/replace-str.pipe";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ConfirmComponent } from "../../../modals/confirm/confirm.component";
import { HttpErrorResponse } from "@angular/common/http";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";

@Component({
  selector: 'app-asic-response',
  standalone: true,
  imports: [
    NotificationComponent,
    ButtonComponent,
    ReplacePipe,
    ReplaceStrPipe
  ],
  templateUrl: './asic-response.component.html',
  styleUrl: './asic-response.component.scss'
})
export class AsicResponseComponent {
  @Input() document!: Document;
  @Input() confirmAction$: Subject<boolean> | undefined;
  @Output() nextStep = new EventEmitter<boolean>();
  @Output() editDocument = new EventEmitter<boolean>();

  private documentsService = inject(DocumentsService);
  private modalFormsService = inject(ModalFormsService);
  private modalService = inject(NgbModal);
  private router = inject(Router);
  private toastr = inject(ToastrService);
  private destroyRef = inject(DestroyRef);

  protected readonly AsicStatus = AsicStatus;
  readonly successMsg = 'The form has been successfully lodged on. ASIC document number for your reference is:'
  readonly rejectedMsg = 'ASIC rejected the lodgement due to this reason:';
  readonly notLodgedFormMsg = 'The form you have prepared cannot be lodged electronically. Please ensure that' +
    ' you print the form and submit it manually to ASIC. It is your responsibility to review the form for accuracy ' +
    'before lodging, as any errors may result in processing delays or additional fees.\n' +
    'For details on how to manually lodge this form, including the correct submission method and any applicable fees,' +
    ' please refer to the ASIC website or contact ASIC directly for guidance.';
  readonly notBeLodgedFormTypeList = ['c:ra01', 'c:ra04', 'c:106', 'c:211', 'c:280', 'c:281', 'c:2205', 'Dividend Statement'];
  notLodgedForm = false;

  constructor() {
    this.toastr.toastrConfig.positionClass = 'toast-top-right';
  }

  ngOnInit(): void {
    this.documentsService.disabledHeaderBtn.set(true);
    this.notLodgedForm = this.notBeLodgedFormTypeList.includes(this.document.type);
  }

  requestCorrection(): void {
    const company = this.document.company;
    this.modalFormsService.openModalWithCompany(new CompanyMistakeCorrection(), company, false, {},{});
  }

  withdraw(): void {
    const company = this.document.company;
    this.modalFormsService.openModalWithCompany(new CompanyWithdrawLodgedDocumentChange(), company, false, {},{});
  }

  editInitialDocument(): void {
    this.editDocument.emit(true);
  }

  deleteDocument(): void {
    const modalRef = this.modalService.open(ConfirmComponent);
    (modalRef.componentInstance as ConfirmComponent).title = 'Delete document';
    (modalRef.componentInstance as ConfirmComponent).message = `Are you sure you want to delete document?`;
    (modalRef.componentInstance as ConfirmComponent).confirmText = 'Delete';
    (modalRef.componentInstance as ConfirmComponent).confirm = () => this.documentsService.deleteDocumentChange(this.document.documentId).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        this.toastr.error("Error while deleting document", "Error");
        return of('ERR');
      })
    );

    modalRef.closed.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((res) => {
      if(res !== 'ERR') {
        this.toastr.success('Document deleted!', 'Success');
        void this.router.navigate(['/documents']);
      }
    });
  }
}
