import { Component, Input, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IndividualRecord } from '../../../models/IndividualRecord';
import { IndividualService } from '../../../services/individual.service';
import { ToastrService } from 'ngx-toastr';
import { NgForOf } from "@angular/common";

@Component({
  selector: 'app-individual-pick',
  standalone: true,
  imports: [
    NgForOf
  ],
  templateUrl: './individual-pick.component.html',
  styleUrl: './individual-pick.component.scss'
})
export class IndividualPickComponent {
  activeModal = inject(NgbActiveModal);
  individualService = inject(IndividualService);
  toastr = inject(ToastrService);

  @Input() individualList: IndividualRecord[] = [];


  ngOnInit() {
    this.activeModal.update({ size: 'lg' });
  }

  close() {
    this.activeModal.close();
  }

  select(individualId: string ) {
    this.activeModal.close(individualId);
  }
}
