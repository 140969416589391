<div class="card settings-page">
  <app-page-title
    header="Cover Letter Templates"
    iconClass="ic-file"
    backButtonPath="settings"
  >
    <app-button
      id="add-cover-letter-dd"
      text="Add Template"
      [iconClass]="'icon ic-xs ic-plus'"
      [isDisabled]="isLoading || addDocumentTemplateModalOpened"
      (clickBtn)="createEditDocumentTemplate()"
    ></app-button>
  </app-page-title>

  <div class="card-body">
    @if (!isLoading && coverLetters.length) {
      <app-list-grid
        [rowData]="coverLetters"
        [colDefs]="colDefs"
        [suppressRowClickSelection]="true"
        [pagination]="true"
        [paginationPageSize]="pageSizeService.getPageSize"
        [paginationPageSizeSelector]="true"
        [suppressCellFocus]="true"
      ></app-list-grid>
    } @else if (!isLoading && !coverLetters.length) {
      <div class="no-templates-message">
        Currently, there are no custom templates available. The default template will be used. You may <a
        (click)="createEditDocumentTemplate()">add a new template</a> or
        <a
          href="javascript:void(0)"
          (click)="downloadDefaultTemplate()"
        >download the default one</a> to proceed.
      </div>
    }

    <app-loader [loading]="isLoading"></app-loader>
  </div>
</div>
