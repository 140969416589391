import { inject, Injectable, Signal, signal } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap/modal/modal-config';
import { CompanyNameChangeComponent } from '../app/modals/company-name-change/company-name-change.component';
import { CompanyChangeName } from '../app/modals/company-name-change/CompanyChangeName';
import {
  A1CompanyAddressChangeComponent
} from '../app/modals/documents/asic-forms/484-forms/a1-company-address-change/a1-company-address-change.component';
import {
  CompanyChangeAddress
} from '../app/modals/documents/asic-forms/484-forms/a1-company-address-change/CompanyChangeAddress.model';
import {
  A2CompanyChangePartyNameComponent
} from '../app/modals/documents/asic-forms/484-forms/a2-company-member-name-change/a2-company-change-party-name.component';
import {
  CompanyChangePartyName
} from '../app/modals/documents/asic-forms/484-forms/a2-company-member-name-change/CompanyChangePartyName';
import {
  A3HoldingCompanyChangeComponent
} from '../app/modals/documents/asic-forms/484-forms/a3-holding-company-change/a3-holding-company-change.component';
import {
  CompanyChangeHoldingCompany
} from '../app/modals/documents/asic-forms/484-forms/a3-holding-company-change/company-change-holding-company.model';
import {
  B1CeaseCompanyOfficeholderComponent
} from '../app/modals/documents/asic-forms/484-forms/b1-cease-company-officeholder/b1-cease-company-officeholder.component';
import {
  B3CompanyStatusChangeComponent
} from '../app/modals/documents/asic-forms/484-forms/b3-company-status-change/b3-company-status-change.component';
import {
  CompanyChangeSpecialPurpose
} from '../app/modals/documents/asic-forms/484-forms/b3-company-status-change/company-status-change-form.model';
import {
  CompanyDividendStatement
} from '../app/modals/documents/asic-forms/form-divident-statement/CompanyDividendStatement';
import {
  FormDividendStatementComponent
} from '../app/modals/documents/asic-forms/form-divident-statement/form-dividend-statement.component';
import {
  Form106WithdrawLodgedDocumentComponent
} from '../app/modals/documents/asic-forms/form106-withdraw-lodged-document/form106-withdraw-lodged-document.component';
import {
  CompanyWithdrawLodgedDocumentChange
} from '../app/modals/documents/asic-forms/form106-withdraw-lodged-document/withdraw-lodged-document-form.model';
import { CompanyForm361 } from '../app/modals/documents/asic-forms/form361-registered-agent-ceasing/CompanyForm361';
import {
  Form361RegisteredAgentCeasingComponent
} from '../app/modals/documents/asic-forms/form361-registered-agent-ceasing/form361-registered-agent-ceasing.component';
import {
  CompanyChangeOfficerResignation
} from '../app/modals/documents/asic-forms/form370-notification-by-officeholder-of-resignation-or-retirement/CompanyChangeOfficerResignation.model';
import {
  Form370OfficeholderResignationOrRetirementComponent
} from '../app/modals/documents/asic-forms/form370-notification-by-officeholder-of-resignation-or-retirement/form370-officeholder-resignation-or-retirement.component';
import { Form410BComponent } from '../app/modals/documents/asic-forms/form410b/form410b.component';
import { Form410BModel } from '../app/modals/documents/asic-forms/form410b/form410B.model';
import { Form410FComponent } from '../app/modals/documents/asic-forms/form410f/form410f.component';
import { Form410FModel } from '../app/modals/documents/asic-forms/form410f/form410F.model';
import {
  Form492RequestCorrectionComponent
} from '../app/modals/documents/asic-forms/form492-request-correction/form492-request-correction.component';
import {
  CompanyMistakeCorrection
} from '../app/modals/documents/asic-forms/form492-request-correction/request-correction-change.model';
import {
  Form6010VoluntaryDeregistrationComponent
} from '../app/modals/documents/asic-forms/form6010-voluntary-deregistration/form6010-voluntary-deregistration.component';
import {
  CompanyChangeDeregister
} from '../app/modals/documents/asic-forms/form6010-voluntary-deregistration/form6010.model';
import {
  Ra01RegisterCeaseChangeAgentComponent
} from '../app/modals/documents/asic-forms/RA-forms/ra01-register-cease-change-agent/ra01-register-cease-change-agent.component';
import {
  RegisterCeaseChangeAgentForm
} from '../app/modals/documents/asic-forms/RA-forms/ra01-register-cease-change-agent/register-cease-change-agent-form.model';
import {
  Ra04DirectDebitRequestComponent
} from '../app/modals/documents/asic-forms/RA-forms/ra04-direct-debit-request/ra04-direct-debit-request.component';
import {
  AsicFormRA04
} from '../app/modals/documents/asic-forms/RA-forms/ra04-direct-debit-request/ra04-direct-debit-request.model';
import {
  Form362AppointCeaseAgentComponent
} from '../app/modals/documents/asic-forms/ra362-appoint-cease-agent/form362-appoint-cease-agent.component';
import { Form206Component } from '../app/modals/form206/form206.component';
import { ChangeCompanyType } from '../app/modals/form206/form206.model';
import { Form280Component } from '../app/modals/form280/form280.component';
import { CompanyForm280 } from '../app/modals/form280/form280.model';
import { Form281Component } from '../app/modals/form281/form281.component';
import { CompanyForm281 } from '../app/modals/form281/form281.model';
import { Form388Component } from '../app/modals/form388/form388.component';
import { CopyFinancialStatementsAndReports } from '../app/modals/form388/form388.model';
import { OfficerAppointmentComponent } from '../app/modals/officer-appointment/officer-appointment.component';
import { ShareCancellationComponent } from '../app/modals/share/share-cancellation/share-cancellation.component';
import {
  CompanySecurityCancellation
} from '../app/modals/share/share-cancellation/company-security-cancellation.model';
import { ShareIssueComponent } from '../app/modals/share/share-issue/share-issue.component';
import { CompanySecurityIssue } from '../app/modals/share/share-issue/share-issue.model';
import {
  ShareSubdivisionConversionComponent
} from '../app/modals/share/share-subdivision-conversion/share-subdivision-conversion.component';
import {
  CompanySecurityConsolidationSubdivision
} from '../app/modals/share/share-subdivision-conversion/share-subdivision-conversion.model';
import { ShareTransferComponent } from '../app/modals/share/share-transfer/share-transfer.component';
import { CompanySecurityTransfer } from '../app/modals/share/share-transfer/share-transfer.model';
import {
  BaseStepperFormComponent
} from '../app/modals/stepper-form/base-stepper-component/base-stepper-form.component';
import { DatepickerHelper } from '../custom-form-validators/date-picker-form-validators';
import { Company } from '../models/company';
import { CompanyAppointChange } from '../models/companyAppointChange';
import { Document } from '../models/document';
import { EntityChangeData } from '../models/entityChangeData';
import { CompanyChangeOfficerType } from '../models/enums/companyChangeOfficerType';
import { CompanyChangeOfficer } from '../models/сompanyChangeOfficer';
import {
  BulkChangeAddressFormComponent
} from "../app/modals/bulk-changes/bulk-change-address-form/bulk-change-address-form.component";
import {
  BaseBulkStepperFormComponent
} from "../app/modals/stepper-form/base-bulk-stepper-form/base-bulk-stepper-form.component";
import {
  BulkChangeNameFormComponent
} from "../app/modals/bulk-changes/bulk-change-name-form/bulk-change-name-form.component";
import {
  BulkAppointmentOfficeholderComponent
} from "../app/modals/bulk-changes/bulk-appointment-officeholder/bulk-appointment-officeholder.component";
import {
  FormCompanyStandardComponent
} from "../app/modals/documents/asic-forms/form-company-standard/form-company-standard.component";
import { CompanyRegistration } from "../models/company-registration";
import {
  BulkCeaseOfficeholderComponent
} from "../app/modals/bulk-changes/bulk-cease-officeholder/bulk-cease-officeholder.component";
import {
  BulkCeaseRegisteredAgentComponent
} from "../app/modals/bulk-changes/bulk-cease-registered-agent/bulk-cease-registered-agent.component";
import {
  AppointmentType,
  TrustChangeRelationship
} from "../models/trust-change-relationship";
import {
  FormChangeOfTrusteeComponent
} from "../app/trusts/components/form-edit-trustee/form-change-of-trustee.component";
import {
  FormChangeOfAppointorComponent
} from "../app/trusts/components/form-change-of-appointor/form-change-of-appointor.component";
import {
  TrustDistribution
} from "../app/trusts/components/form-distribution-resolution-or-minutes/form-distribution-resolution-or-minutes.model";
import {
  FormTrustDistributionComponent
} from "../app/trusts/components/form-distribution-resolution-or-minutes/form-trust-distribution.component";

@Injectable({
  providedIn: 'root'
})
export class ModalFormsService {
  private modalService = inject(NgbModal);

  static readonly commonCloseConfirmationMessage = 'Are you sure you want to close the form? The data will be cleared.';
  static readonly asicFormsModalClasses = {
    windowClass: 'asic-form-modal-window',
    modalDialogClass: 'asic-form-modal-dialog'
  };

  private _modalOpened = signal(false);
  private openedForms: NgbModalRef[] = [];

  static formsDictionary: Record<string, (change: EntityChangeData) => new () => BaseStepperFormComponent<unknown, EntityChangeData>> = {
    [CompanyChangeAddress.$type.toString()]: () => A1CompanyAddressChangeComponent,
    [CompanyChangeHoldingCompany.$type.toString()]: () => A3HoldingCompanyChangeComponent,
    [CompanyChangeName.$type.toString()]: () => CompanyNameChangeComponent,
    [CompanyChangePartyName.$type.toString()]: () => A2CompanyChangePartyNameComponent,
    [CompanyChangeOfficer.$type.toString()]: (change) => (change as CompanyChangeOfficer).actionType === CompanyChangeOfficerType.Appointment ? OfficerAppointmentComponent : B1CeaseCompanyOfficeholderComponent,
    [CompanyChangeSpecialPurpose.$type.toString()]: () => B3CompanyStatusChangeComponent,
    [RegisterCeaseChangeAgentForm.$type.toString()]: () => Ra01RegisterCeaseChangeAgentComponent,
    [AsicFormRA04.$type.toString()]: () => Ra04DirectDebitRequestComponent,
    [CompanyDividendStatement.$type.toString()]: () => FormDividendStatementComponent,
    [CompanyWithdrawLodgedDocumentChange.$type.toString()]: () => Form106WithdrawLodgedDocumentComponent,
    [CompanyForm361.$type.toString()]: () => Form361RegisteredAgentCeasingComponent,
    [CompanyChangeOfficerResignation.$type.toString()]: () => Form370OfficeholderResignationOrRetirementComponent,
    [Form410BModel.$type.toString()]: () => Form410BComponent,
    [Form410FModel.$type.toString()]: () => Form410FComponent,
    [CompanyMistakeCorrection.$type.toString()]: () => Form492RequestCorrectionComponent,
    [CompanyChangeDeregister.$type.toString()]: () => Form6010VoluntaryDeregistrationComponent,
    [CompanyAppointChange.$type.toString()]: () => Form362AppointCeaseAgentComponent,
    [ChangeCompanyType.$type.toString()]: () => Form206Component,
    [CompanyForm280.$type.toString()]: () => Form280Component,
    [CompanyForm281.$type.toString()]: () => Form281Component,
    [CopyFinancialStatementsAndReports.$type.toString()]: () => Form388Component,
    [CompanySecurityCancellation.$type.toString()]: () => ShareCancellationComponent,
    [CompanySecurityIssue.$type.toString()]: () => ShareIssueComponent,
    [CompanySecurityTransfer.$type.toString()]: () => ShareTransferComponent,
    [CompanySecurityConsolidationSubdivision.$type.toString()]: () => ShareSubdivisionConversionComponent,
    [CompanyRegistration.$type.toString()]: () => FormCompanyStandardComponent,
  };

  static formsBulkDictionary: Record<string, (change: EntityChangeData) => new () => BaseBulkStepperFormComponent<unknown, EntityChangeData>> = {
    [CompanyChangeAddress.$type.toString()]: () => BulkChangeAddressFormComponent,
    [CompanyChangePartyName.$type.toString()]: () => BulkChangeNameFormComponent,
    [CompanyChangeOfficer.$type.toString()]: (change) => (change as CompanyChangeOfficer).actionType === CompanyChangeOfficerType.Appointment ? BulkAppointmentOfficeholderComponent : BulkCeaseOfficeholderComponent,
    [CompanyForm361.$type.toString()]: () => BulkCeaseRegisteredAgentComponent,
  };

  static trustsFormsDictionary: Record<string, (change: EntityChangeData) => new () => BaseStepperFormComponent<unknown, EntityChangeData>> = {
    [TrustDistribution.$type]: () => FormTrustDistributionComponent,
    [TrustChangeRelationship.$type]: (change) => {
      const trustChangeRelationshipChange = change as TrustChangeRelationship;

      switch (trustChangeRelationshipChange.appointmentType) {
        case AppointmentType.Trustees:
          return FormChangeOfTrusteeComponent;
        case AppointmentType.Appointor:
          return FormChangeOfAppointorComponent;
        // case AppointmentType.Settlor:
        default:
          throw new Error('[trustsFormsDictionary.TrustChangeRelationship] Wrong appointment type');
      }
    },
  };

  public openModalWithCompany<T>(
    change: EntityChangeData,    // instance of changes in form
    company: Company,            // instance of company profile
    isEdit = false,
    props: Partial<T> = {},      // props for form component
    modalOptions: NgbModalOptions = {}
  ): NgbModalRef {
    const modalRef = change.fillModalWithCompany(
      this.openModal(ModalFormsService.formsDictionary[change.$type](change), { ...ModalFormsService.asicFormsModalClasses, ...modalOptions }),
      company,
      isEdit
    );

    const instance = modalRef.componentInstance as T;

    Object.entries(props).forEach(([key, value]) => {
      instance[key] = value;
    });

    return modalRef;
  }

  public openModalWithDocument<T>(
    document: Document,          // instance of document
    actualCompany: Company,
    change: EntityChangeData,
    props: Partial<T> = {},      // props for form component
    modalOptions: NgbModalOptions = {}
  ): NgbModalRef {
    change.changeDate = DatepickerHelper.toDate(change.changeDate);
    const modalRef = change.fillModal(
      this.openModal(ModalFormsService.formsDictionary[change.$type](change), { ...ModalFormsService.asicFormsModalClasses, ...modalOptions }),
      document,
      actualCompany
    );

    const instance = modalRef.componentInstance as T;

    Object.entries(props).forEach(([key, value]) => {
      instance[key] = value;
    });

    return modalRef;
  }

  public openModal<T>(componentClass: new () => T, options: NgbModalOptions = {}, closeConfirmationMessage = ModalFormsService.commonCloseConfirmationMessage): NgbModalRef {
    this._modalOpened.set(true);
    let modalRef!: NgbModalRef;

    const beforeDismiss = () => {
      const instance = modalRef.componentInstance as BaseStepperFormComponent<any, any>;
      return instance.forceClose || !instance.isLoading && confirm(closeConfirmationMessage);
    };

    modalRef = this.modalService.open(
      componentClass,
      {
        beforeDismiss,
        ...options
      });

    this.openedForms.push(modalRef);
    modalRef.result
      .then(() => this._modalOpened.set(false), () => this._modalOpened.set(false))
      .finally(() => this.openedForms = this.openedForms.filter((ref) => ref.componentInstance));

    return modalRef;
  }

  public openModalWithBulkCompany<T>(
    change: EntityChangeData,    // instance of changes in form
    company: Company,            // instance of company profile
    isEdit = false,
    props: Partial<T> = {},      // props for form component
    modalOptions: NgbModalOptions = {}
  ): NgbModalRef {
    const modalRef = change.fillModalWithCompany(
      this.openBulkModal(ModalFormsService.formsBulkDictionary[change.$type](change), modalOptions),
      company,
      isEdit
    );

    const instance = modalRef.componentInstance as T;

    Object.entries(props).forEach(([key, value]) => {
      instance[key] = value;
    });

    return modalRef;
  }

  public openBulkModal<T>(componentClass: new () => T, options: NgbModalOptions = {}): NgbModalRef {
    let modalRef!: NgbModalRef;

    const beforeDismiss = () => {
      const instance = modalRef.componentInstance as BaseBulkStepperFormComponent<any, any>;

      if ('forceClose' in instance && instance.forceClose)
        return instance.forceClose;

      return !instance.isLoading && confirm('All provided data will be deleted. Are you certain you want to close the form?');
    };

    modalRef = this.modalService.open(
      componentClass,
      {
        beforeDismiss,
        ...options
      });

    return modalRef;
  }

  public openTrustModalWithCompany<T>(
    change: EntityChangeData,    // instance of changes in form
    company: Company,            // instance of company profile
    isEdit = false,
    props: Partial<T> = {},      // props for form component
    modalOptions: NgbModalOptions = {},
    closeConfirmationMessage = ModalFormsService.commonCloseConfirmationMessage
  ): NgbModalRef {
    const modalRef = change.fillModalWithCompany(
      this.openModal(ModalFormsService.trustsFormsDictionary[change.$type](change), modalOptions, closeConfirmationMessage),
      company,
      isEdit
    );

    const instance = modalRef.componentInstance as T;

    Object.entries(props).forEach(([key, value]) => {
      instance[key] = value;
    });

    return modalRef;
  }

  public openTrustModalWithDocument<T>(
    document: Document,          // instance of document
    actualCompany: Company,
    change: EntityChangeData,
    props: Partial<T> = {},      // props for form component
    modalOptions: NgbModalOptions = {},
    closeConfirmationMessage = ''
  ): NgbModalRef {
    change.changeDate = DatepickerHelper.toDate(change.changeDate);
    const modalRef = change.fillModal(
      this.openModal(ModalFormsService.trustsFormsDictionary[change.$type](change), modalOptions, closeConfirmationMessage),
      document,
      actualCompany
    );

    const instance = modalRef.componentInstance as T;

    Object.entries(props).forEach(([key, value]) => {
      instance[key] = value;
    });

    return modalRef;
  }

  public openEntityModalWithDocument<T>(
    document: Document,          // instance of document
    actualCompany: Company,
    change: EntityChangeData,
    props: Partial<T> = {},      // props for form component
    modalOptions: NgbModalOptions = {}
  ): NgbModalRef {

    switch (document.type[0]) {
      case 'c':
        return this.openModalWithDocument(document, actualCompany, change, props, modalOptions);
      case 't':
        return this.openTrustModalWithDocument(document, actualCompany, change, props, modalOptions);
      default:
        throw new Error('Unknown Document type: ' + document.type);
    }
  }

  public closeAllFormsModals(): void {
    this.openedForms = this.openedForms.filter((ref) => ref.componentInstance);

    if (!this.openedForms.length)
      return;

    this.openedForms.forEach((form) => {
      form.componentInstance['forceClose'] = true;
      form.dismiss();
    });
    this.modalService.dismissAll();
  }

  public get modalOpened(): Signal<boolean> {
    return this._modalOpened.asReadonly();
  }
}
