<div class="address-form" [formGroup]="form">
  <div [hidden]="!useInternationalAddresses">
    <app-autocomplete
      formControlName="country"
      [label]="addressFormLabels.country"
      [options]="countryOptions"
      [valuePrimitive]="true"
    />
  </div>

  <div [hidden]="expandedAddressForm">
    <app-address-autocomplete
      class="d-block mt-2"
      placeholder="Type Address"
      formControlName="normalizedFullAddress"
      [label]="label || addressFormLabels.normalizedFullAddress"
      [clearStatusAfter]="5000"
      [isDisabled]="isDisabled"
      [isAustraliaSelected]="isAustraliaSelected()"
      [useInternationalAddresses]="useInternationalAddresses"
      [country]="form.value.country ?? ''"
      (input)="onTypingAddress($event.target?.['value'] ?? '')"
      (focus)="onFocusSearchControl()"
      (blur)="onBlurAddressSearch()"
      (onSelect)="parseAddress($event)"
      (onTextChange)="textChange($event)"
      (dataStatusChange)="dataStatusChange($event)"
      (switchBtnClick)="switchAddressForm()"
      [suggestedAddresses]="suggestedAddresses"
      [(dataStatus)]="addressStatus"
    />
  </div>

  <div [hidden]="!expandedAddressForm">
    @if (addressStatus === AddressStatus.WARNING) {
      <div class="warning-message mt-2">
        <i class="icon ic-circle-info"></i>
        <span>Address was not validated and most likely to be rejected</span>
      </div>
    }

    <div class="mt-2">
      <app-input
        formControlName="street"
        placeholder="Type Address"
        switchBtnText="Find Address"
        [label]="addressFormLabels.street"
        (switchBtnClick)="switchAddressForm()"
        [isDisabled]="isDisabled"
      />
    </div>

    <div class="mt-2">
      <app-input
        formControlName="line2"
        placeholder="Type level, unit or shop number"
        [label]="addressFormLabels.line2"
        [isDisabled]="isDisabled"
      />
    </div>

    @if (isAustraliaSelected()) {
      <div class="mt-2">
        <app-suburbs-autocomplete
          formControlName="suburb"
          (selectSuburb)="selectSuburb($event)"
        />
      </div>
    } @else {
      <div class="mt-2">
        <app-input
          class="d-block mt-2"
          formControlName="suburb"
          placeholder="Type Suburb"
          [label]="isAustraliaSelected() ? 'Suburb' : 'City or Locality'"
          [isDisabled]="isDisabled"
        />
      </div>
    }

    <div [class.state-code]="!useInternationalAddresses">
      <div class="mt-2">
        <app-select
          formControlName="state"
          placeholder="Select State"
          [label]="addressFormLabels.state"
          [options]="stateOptions"
          [valuePrimitive]="true"
          [isDisabled]="isDisabled"
          [hidden]="useInternationalAddresses && !isAustraliaSelected()"
        />
      </div>

      <div class="mt-2">
        <app-input
          formControlName="postcode"
          placeholder="Type Postcode"
          [label]="addressFormLabels.postcode"
          [isDisabled]="isDisabled"
        />
      </div>
    </div>
  </div>
</div>
