import { Component } from '@angular/core';
import { ICellRendererParams } from "ag-grid-community";
import { CurrencyPipe, NgClass } from "@angular/common";

@Component({
  selector: 'app-ag-master-paid-amount',
  standalone: true,
  imports: [
    CurrencyPipe,
    NgClass
  ],
  templateUrl: './ag-master-paid-amount.component.html',
  styleUrl: './ag-master-paid-amount.component.scss'
})
export class AgMasterPaidAmountComponent {
  protected params!: ICellRendererParams;
  paidAmount = 0;
  paidAmountAsic = 0;
  hasIssue = false;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.paidAmount = (this.params.value as number) ?? 0;
    this.hasIssue = (params.data as { hasIssue?: boolean })?.hasIssue ?? false;
    this.paidAmountAsic = (this.params.data as { paidAmountAsic?: number })?.paidAmountAsic ?? 0;
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    return true;
  }
}
