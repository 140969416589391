import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ACNPipe } from '../../../../pipes/acnPipe';

@Component({
  selector: 'app-entity-name-number-record',
  standalone: true,
  imports: [
    ACNPipe
  ],
  templateUrl: './entity-name-number-record.component.html',
  styleUrl: './entity-name-number-record.component.scss'
})
export class EntityNameNumberRecordComponent {
  @Input() name = '';
  @Input() numberFormatted? = '';
  @Input() prefix = '';
  @Input() numberLablel = 'ACN';
  @Input() showIcon = false;
  @Input() iconClass = 'icon ic-md ic-companies';
  @Output() handleClick = new EventEmitter<MouseEvent>();

  onHandleClick(e: MouseEvent): void {
    this.handleClick.emit(e);
  }
}
