import { NgClass } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Observable, tap } from 'rxjs';
import { ShareholderRelationshipDetails } from '../../../../../../models/relationship';
import {
  CorporateHolderModel,
  IndividualHolderModel,
  SecurityRegistryRecord
} from '../../../../../../models/securityRegistryRecord';
import { AsPipe } from '../../../../../../pipes/asPipe';
import { SecurityService } from '../../../../../../services/security.service';
import { BeneficialOwnerComponent } from '../../../../../components/common/beneficial-owner/beneficial-owner.component';
import { ButtonComponent } from '../../../../../components/common/button/button.component';
import {
  InlineEditInputComponent
} from '../../../../../components/common/inline-edit-input/inline-edit-input.component';
import { InputComponent } from '../../../../../components/common/input/input.component';
import { ValidationErrorComponent } from '../../../../../components/common/validation-error/validation-error.component';
import { DividendStatementStepsEnum } from '../CompanyDividendStatement';

export const customSignErrors = {
  notEnoughSigns: 'Beneficial owners have to be supplied.'
};

@Component({
  selector: 'nbo-beneficial-owners-group',
  standalone: true,
  imports: [
    AsPipe,
    BeneficialOwnerComponent,
    ValidationErrorComponent,
    InputComponent,
    ButtonComponent,
    InlineEditInputComponent,
    NgClass,
    ReactiveFormsModule
  ],
  templateUrl: './beneficial-owners-group.component.html',
  styleUrl: './beneficial-owners-group.component.scss'
})
export class BeneficialOwnersGroupComponent implements OnInit {
  securityService = inject(SecurityService);
  toastr = inject(ToastrService);

  @Input({ required: true }) holders!: SecurityRegistryRecord[];
  @Input({ required: true }) formGroup!: FormGroup;
  @Input() customErrors: Record<string, string> = customSignErrors;

  @Output() dataSaved = new EventEmitter<{ index: number, newBOName: string }>();

  protected readonly ShareholderRelationshipDetails = ShareholderRelationshipDetails;

  ngOnInit() {
    if (this.formGroup && this.holders.length) {
      this.formGroup.addValidators(((): ValidationErrors | null => {

        if (!this.holders.every(owner => owner.holders.every(holder => holder.details.beneficialOwner))) {
          return { notEnoughSigns: true };
        }

        return null;
      }));
    }
  }

  updateBeneficialOwners(holders: (IndividualHolderModel | CorporateHolderModel)[], index: number): (newBOName: string) => Observable<unknown> {
    const relationshipIds = holders.map(holder => holder.relationshipId);
    return (newBOName: string) => combineLatest(relationshipIds.map(relationshipId => this.securityService.updateBeneficialOwner(relationshipId, newBOName)))
      .pipe(tap({
        next: () => {
          this.toastr.success('Beneficial owner updated');
          this.dataSaved.emit({ index, newBOName });
        },
        error: () => {
          this.toastr.error('Failed to update beneficial owner');
        }
      }));
  }

  protected readonly StepsEnum = DividendStatementStepsEnum;
}
