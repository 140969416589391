import { Component } from '@angular/core';
import { ICellRendererParams } from "ag-grid-community";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { NgClass } from "@angular/common";

@Component({
  selector: 'app-ag-share-warning-tooltip',
  standalone: true,
  imports: [
    NgbTooltip,
    NgClass
  ],
  templateUrl: './ag-share-warning-tooltip.component.html',
  styleUrl: './ag-share-warning-tooltip.component.scss'
})
export class AgShareWarningTooltipComponent {
  shareClass = '';
  hasIssue = false;
  issueMessage = '';

  agInit(params: ICellRendererParams): void {
    if (params.value !== null && params.value !== undefined) {
      this.shareClass = params.value as string;
    }

    this.hasIssue = (params.data as { hasIssue?: boolean })?.hasIssue ?? false;
    this.issueMessage = (params.data as { issueMessage?: string })?.issueMessage ?? '';
  }

  refresh(params: ICellRendererParams): boolean {
    if (params.value !== null && params.value !== undefined) {
      this.shareClass = params.value as string;
    }

    return true;
  }
}
