import { Pipe, PipeTransform } from '@angular/core';
import { SecurityTransactionType } from "../../models/enums/securityTransactionTypeEnum";

@Pipe({
  name: 'securityTransactionType',
  standalone: true
})
export class SecurityTransactionTypePipe implements PipeTransform {
  transform(value?: SecurityTransactionType | null | undefined): string {
    if(value !== undefined && value !== null)
      return SecurityTransactionType[value];

    return '';
  }
}
