export class OrganisationBrandModel {
  brandName: string;
  brandDescription: string;
  footerText: string;
  mainColor: string;
  secondaryColor: string;

  constructor(data: Partial<OrganisationBrandModel> = {}) {
    this.brandName = data.brandName ?? '';
    this.brandDescription = data.brandDescription ?? '';
    this.footerText = data.brandDescription ?? '';
    this.mainColor = data.mainColor ?? '';
    this.secondaryColor = data.secondaryColor ?? '';
    this.footerText = data.footerText ?? '';
  }
}
