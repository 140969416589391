import { Component, inject } from '@angular/core';
import { Router } from "@angular/router";
import { ICellRendererParams } from "ag-grid-community";
import { IndividualRecord } from "../../../../../../models/IndividualRecord";

type CellRendererParams = ICellRendererParams<IndividualRecord>;

@Component({
  selector: 'app-ag-individual-name',
  standalone: true,
  imports: [],
  templateUrl: './ag-individual-name.component.html',
  styleUrl: './ag-individual-name.component.scss'
})
export class AgIndividualNameComponent {
  private router = inject(Router);

  id = '';
  name = '';
  protected params!: CellRendererParams;

  agInit(params: CellRendererParams): void {
    this.params = params;
    this.name = this.params.data?.fullName ?? '';
    this.id = this.params.data?.individualId ?? '';
  }

  refresh(params: CellRendererParams): boolean {
    this.params = params;
    return true;
  }

  onOpenIndividual(e: MouseEvent): void {
    if (e.altKey) {
      e.preventDefault();
      window.open(`/individual/${this.id}`, '_blank');
    } else {
      void this.router.navigate(['/individual', this.id]);
    }
  }
}
