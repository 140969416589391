import { Component, DestroyRef, inject, Input, signal, ViewChild } from '@angular/core';
import { Alert } from "../../../models/alerts/alert";
import { PageSizeService } from "../common/grid/services/page-size.service";
import { ColDef, GridApi, ValueFormatterParams } from "ag-grid-community";
import { formatDate, NgClass } from "@angular/common";
import { AgAlertsTitleComponent } from "../common/grid/components/alerts/ag-alerts-title/ag-alerts-title.component";
import {
  AgAlertsCategoryComponent
} from "../common/grid/components/alerts/ag-alerts-category/ag-alerts-category.component";
import { AgAlertsStatusComponent } from "../common/grid/components/alerts/ag-alerts-status/ag-alerts-status.component";
import { AgTagEntityTitleComponent } from "../common/grid/components/ag-tag-entity-title/ag-tag-entity-title.component";
import { CommonOffcanvasComponent } from "../common/common-offcanvas/common-offcanvas.component";
import {
  CreateEditNoteControlComponent
} from "../notes/components/create-edit-note-control/create-edit-note-control.component";
import { LoaderStandaloneComponent } from "../common/loader-standalone/loader-standalone.component";
import { NoteListElementComponent } from "../notes/components/note-list-element/note-list-element.component";
import { ScrollLoadDirective } from "../../../directives/scroll-load.directive";
import { Guid } from "../../helpers/guid.helper";
import { Entity } from "../../../models/entity";
import { Company } from "../../../models/company";
import { Trust } from "../../../models/trust";
import { Individual } from "../../../models/individual";
import { IndividualRecord } from "../../../models/IndividualRecord";
import { EntityType } from "../../../models/enums/entityType";
import { ITagsEntity } from "../../settings/components/tags/manage-tags-modal/manage-tags-modal.component";
import { ListGridComponent } from "../common/grid/components/list-grid/list-grid.component";
import { CompanyNameLinkComponent } from "../common/company-name-link/company-name-link.component";
import { AlertDetailsComponent } from "../../alerts/alert-details/alert-details.component";
import { ButtonComponent } from "../common/button/button.component";
import { AlertsService } from "../../../services/alert.service";

@Component({
  selector: 'app-alerts-list',
  standalone: true,
  imports: [
    AgTagEntityTitleComponent,
    CommonOffcanvasComponent,
    CreateEditNoteControlComponent,
    LoaderStandaloneComponent,
    NoteListElementComponent,
    ScrollLoadDirective,
    ListGridComponent,
    CompanyNameLinkComponent,
    AlertDetailsComponent,
    NgClass,
    ButtonComponent
  ],
  templateUrl: './alerts-list.component.html',
  styleUrl: './alerts-list.component.scss'
})
export class AlertsListComponent extends CommonOffcanvasComponent {
  public pageSizeService = inject(PageSizeService);
  protected alertService = inject(AlertsService);
  protected destroyRef = inject(DestroyRef);

  @ViewChild(CreateEditNoteControlComponent) createEditNoteControl!: CreateEditNoteControlComponent;

  @Input({ required: true }) set alerts(value: Alert[]) {
    if (value?.length) {
      this.alertsList = value;
      this.totalRows = value.length;
    }
  }

  @Input() set entityId(value: string | null | undefined) {
    if (value && value !== Guid.EmptyGuid) {
      this._entityId = value;
    }
  }

  @Input() set entity(value: Entity | Company | Trust | undefined | null) {
    if (value && value.entityId !== Guid.EmptyGuid) {
      this._entityId = value.entityId;
      this._entityOrIndividual = value;
      this.setGridConfig();
    }
  }

  @Input() set individual(value: Individual | IndividualRecord | null | undefined) {
    if (value && value.individualId !== Guid.EmptyGuid) {
      this._individualId = value.individualId;
      this._entityOrIndividual = value;
    }
  }

  @Input() entityType: EntityType | null = null;


  $selectedAlert = signal<Alert | undefined>(undefined);

  private _entityId: string | null = null;
  private _individualId: string | null = null;
  private _entityOrIndividual: Entity | Company | Trust | Individual | IndividualRecord | null = null

  gridApi!: GridApi;
  alertsList: Alert[] = [];
  totalRows = 0;
  currentAlertIndex = 0;
  readonly alertContainerWidth = '856px';
  readonly defaultPageSize = 20;
  colDefs: ColDef[] = [];

  setGridConfig(): void {
    this.colDefs = [
      {
        headerName: 'Date',
        sort: 'desc',
        field: 'date',
        flex: 0.5,
        valueFormatter: (params: ValueFormatterParams<Alert, Date>) => {
          return formatDate(params.value ?? '', 'dd MMM yyyy', 'en-US');
        },
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: 'Description',
        field: 'modelDetailTitle',
        width: 280,
        cellRenderer: AgAlertsTitleComponent,
        cellRendererParams: {
          openAlert: this.openAlert.bind(this),
        },
        menuTabs: ['filterMenuTab']
      },
      {
        headerName: 'Type',
        field: 'type',
        flex: 1,
        cellRenderer: AgAlertsCategoryComponent,
        menuTabs: ['filterMenuTab']
      },
      {
        headerName: 'State',
        field: 'status',
        flex: 0.5,
        cellRenderer: AgAlertsStatusComponent,
        menuTabs: ['filterMenuTab']
      },
    ];
  }

  onGridReady(gridApi: GridApi): void {
    this.gridApi = gridApi;
  }

  openAlert(data: { alert: Alert, index: number }): void {
    this.currentAlertIndex = data.index;
    this.$selectedAlert.set(data.alert);
  }

  backToAlerts(): void {
    this.$selectedAlert.set(undefined);
  }

  closeAlerts(): void {
    this.closeOffcanvas();
  }

  previousAlert(): void {
    if(this.currentAlertIndex > 0) {
      this.currentAlertIndex--;
      this.updateSelectedAlert();
    }
  }

  nextAlert(): void {
    if(this.currentAlertIndex < this.totalRows){
      this.currentAlertIndex++;
      this.updateSelectedAlert();
    }
  }

  private updateSelectedAlert(): void {
    this.$selectedAlert.set(this.alertsList[this.currentAlertIndex]);
  }

  updateAlert(alert: Alert): void {
    this.alertsList[this.currentAlertIndex] = alert;
    this.gridApi.applyTransactionAsync({ update: [alert] });
    this.$selectedAlert.set(alert);
  }

  get entity(): ITagsEntity | null {
    if (this.entityType !== null) {
      return this._entityOrIndividual;
    }

    return null;
  }
}
