import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { SecurityRegistryRecord } from '../../../../../models/securityRegistryRecord';
import { BeneficialOwnerComponent } from '../../../../components/common/beneficial-owner/beneficial-owner.component';
import { InputNumberComponent } from '../../../../components/common/input-number/input-number.component';
import { ValidationErrorComponent } from '../../../../components/common/validation-error/validation-error.component';
import {
  SelectShareholderComponent
} from '../../../../components/shares/select-shareholder/select-shareholder.component';
import { customSignErrors } from '../../share-cancellation/company-security-cancellation.model';

export interface ShareholderWithSignControls {
  securityRegistryRecordIndex: FormControl<number | null>;
}

@Component({
  selector: 'app-shareholder-with-nbo-sign',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    SelectShareholderComponent,
    InputNumberComponent,
    BeneficialOwnerComponent,
    ValidationErrorComponent
  ],
  templateUrl: './shareholder-with-nbo-sign.component.html',
  styleUrl: './shareholder-with-nbo-sign.component.scss',
})
export class ShareholderWithNboSignComponent implements OnInit {
  @Input() formGroup: FormGroup<ShareholderWithSignControls> = ShareholderWithNboSignComponent.defineForm();
  @Input() securityRegistryRecords: SecurityRegistryRecord[] = [];

  ngOnInit(): void {
    this.setSignValidator();
  }

  signDataSaved(): void {
  }

  private setSignValidator(): void {
    this.formGroup.controls.securityRegistryRecordIndex.setValidators([((control: FormControl<number | null>) => {
      const securityRegistryRecordIndex = control.value;
      if (securityRegistryRecordIndex === null) {
        return null;
      }

      const everyNBOHoldersHaveSigns = this.securityRegistryRecords[securityRegistryRecordIndex]?.holders
        .filter(holder => !holder.details.isBeneficialOwner)
        .every(holder => holder.details.beneficialOwner);

      return everyNBOHoldersHaveSigns ? null : { nonBeneficialOwnersNotSigned: true };
    }) as ValidatorFn]);
  }

  get beneficialOwnersSignsErrorMessageHidden(): boolean {
    return this.formGroup.touched && this.formGroup.controls.securityRegistryRecordIndex.errors?.['nonBeneficialOwnersNotSigned'];
  }

  static defineForm(): FormGroup<ShareholderWithSignControls> {
    return new FormGroup({
      securityRegistryRecordIndex: new FormControl<number | null>(null, [Validators.required]),
    });
  }

  protected readonly customSignErrors = customSignErrors;
}
