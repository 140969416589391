<app-stepper-form
  header="Change of Company Type"
  subheader="Form 206"
  [showSteps]="false"
  [steps]="steps"
  [currentStep]="currentStep"
  [currentStepIndex]="currentStepIndex"
  [isLoading]="isLoading"
  [form]="stepperForm"
>
  <div class="step-content" [formGroup]="stepperForm">
    <app-stepper-form-description
      formKey="206"
      [hidden]="currentStep !== StepsEnum.FormDescription"
    ></app-stepper-form-description>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.ChangeOfType"
      [hidden]="currentStep !== StepsEnum.ChangeOfType"
    >
      <h1 class="h1-heading">{{ steps[currentStepIndex].label }}</h1>

      <div class="form-group">
        <app-date-picker
          label="Date of Change"
          formControlName="changeDate"
          [minDate]="minDate"
          [maxDate]="maxDate"
          [customErrors]="customDatepickerErrors"
        ></app-date-picker>
      </div>

      <div class="form-group">
        <app-select
          label="Company Type"
          formControlName="companyType"
          [options]="companyTypeOptions"
          [valuePrimitive]="true"
        ></app-select>
      </div>

      @if (form.value.companyType !== null) {
        <div class="form-group">
          <app-select
            label="Company Subtype"
            formControlName="companySubtype"
            [options]="companySubtypeOptions"
            [valuePrimitive]="true"
          ></app-select>
        </div>
      }
    </section>
  </div>
</app-stepper-form>
