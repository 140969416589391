import { Component, computed, EventEmitter, inject, Input, Output } from '@angular/core';
import { ButtonComponent } from "../../components/common/button/button.component";
import { CheckboxComponent } from "../../components/common/checkbox/checkbox.component";
import { DividerComponent } from "../../components/common/divider/divider.component";
import { ExportTypeEnum } from "../../../models/enums/exportTypeEnum";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ColDef } from "ag-grid-enterprise";
import { ColGroupDef } from "ag-grid-community";

interface CustomColDef extends ColDef {
  field?: string;
  headerName?: string;
  hide?: boolean;
}

@Component({
  selector: 'app-column-for-export',
  standalone: true,
  imports: [
    ButtonComponent,
    CheckboxComponent,
    DividerComponent,
  ],
  templateUrl: './column-for-export.component.html',
  styleUrl: './column-for-export.component.scss'
})
export class ColumnForExportComponent {
  @Input() title = '';
  @Input() subTitle = '';
  @Input() colDefs: (CustomColDef & ColGroupDef)[] = [];
  @Input() numberOfCompanies = 0;
  @Input() exportType: ExportTypeEnum = ExportTypeEnum.EXCEL;
  @Output() confirm = new EventEmitter<string[]>();

  modal = inject(NgbActiveModal);

  selectAll = false;

  disableSelection = computed(() => this.colDefs.filter((c) => !c?.hide && c?.field).length === 1);

  get numberSelectedColumns(): number {
    return this.colDefs?.filter(c => !c.hide && c.headerName)?.length ?? 0;
  }

  onExport(): void {
    const columnsForExport: string[] = [];
    this.colDefs?.forEach(c => {
      if(!c.hide && !c.children?.length && c.headerName && c.field) {
        columnsForExport.push(c.field);
      } else if(c?.children?.length) {
        c.children.forEach(child => {
          if(!(child as ColDef).hide) {
            const field = (child as ColDef).field ?? '';
            columnsForExport.push(field);
          }
        });
      }
    });

    this.confirm.emit(columnsForExport);
    this.modal.close();
  }

  onClose(): void {
    this.modal.close();
  }

  onCheckboxChange(index: number): void {
    if(this.colDefs?.length) {
      this.colDefs[index].hide = !this.colDefs[index].hide;
    }

    const columnWithHeaderName = this.colDefs?.filter(c => c.headerName) ?? [];
    const selectedColumnNumber = columnWithHeaderName?.filter(c => !c.hide)?.length ?? 0;

    this.selectAll = columnWithHeaderName?.length === selectedColumnNumber;
  }

  onGroupCheckboxChange(column: ColDef & ColGroupDef): void {
    column.hide = !column.hide;
    column.children.forEach((c: ColDef) => c.hide = column?.hide);
  }

  onGroupChildCheckboxChange(column: ColDef & ColGroupDef, childIndex: number): void {
    (column.children[childIndex] as ColDef).hide = !(column.children[childIndex] as ColDef).hide;
    column.hide = !column.children.every((c: ColDef) => c.hide === false);
  }

  onSelectAllChange(selected: boolean): void {
    this.colDefs?.forEach(c => {
      if(c.headerName) {
        c.hide = !selected;
      }

      if(c?.children?.length) {
        c.children.forEach((child: ColDef) => child.hide = !selected);
      }
    });

    this.selectAll = selected;
  }
}
