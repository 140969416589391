import { Component, ElementRef, inject, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModalFormComponent } from "../../../../../modals/common-modal-form/common-modal-form.component";
import {
  NgbActiveModal,
  NgbDropdown,
  NgbDropdownAnchor,
  NgbDropdownMenu,
  NgbDropdownToggle
} from "@ng-bootstrap/ng-bootstrap";
import { CoverLettersService } from "../../../../../../services/cover-letters.service";
import { CoverLetter, CoverLetterCreateModel } from "../../../../../../models/cover-letter";
import { ButtonComponent } from "../../../../../components/common/button/button.component";
import { toBase64 } from "../../../../../../functions/to-base64";
import { bytesToMegabytes } from "../../../../../../functions/bytes-to-megabytes";
import {
  FileCreateDtoWithSize
} from "../../../../../components/notes/components/create-edit-note-control/create-edit-note-control.component";
import { DisclaimerComponent } from "../../../../../components/common/disclaimer/disclaimer.component";
import {
  AttachedFileLabelComponent
} from "../../../../../components/notes/components/attached-file-label/attached-file-label.component";
import { downloadBase64File } from "../../../../../../functions/download-base64-file";
import {
  AgActionIconButtonComponent
} from "../../../../../components/common/grid/components/ag-action-icon-button/ag-action-icon-button.component";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { InputComponent } from "../../../../../components/common/input/input.component";
import { finalize } from "rxjs";
import { setControlDisabled } from "../../../../../../functions/set-control-disabled";
import { FileUploadComponent } from "../../../../../components/common/file-upload/file-upload.component";
import { SelectComponent } from "../../../../../components/common/select/select.component";
import {
  SelectTagsDropdownComponent
} from "../../../../../components/company-profile-tags/select-tags-dropdown/select-tags-dropdown.component";
import { TagComponent } from "../../../../../components/common/tag/tag.component";
import { Tag } from "../../../../../../models/tag";
import { CustomFormValidators } from "../../../../../../custom-form-validators/custom-form-validators";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-add-document-template-modal',
  standalone: true,
  providers: [CoverLettersService],
  imports: [
    CommonModalFormComponent,
    ButtonComponent,
    DisclaimerComponent,
    AttachedFileLabelComponent,
    AgActionIconButtonComponent,
    InputComponent,
    ReactiveFormsModule,
    FileUploadComponent,
    NgbDropdown,
    NgbDropdownAnchor,
    NgbDropdownMenu,
    NgbDropdownToggle,
    SelectComponent,
    SelectTagsDropdownComponent,
    TagComponent
  ],
  templateUrl: './add-document-template-modal.component.html',
  styleUrls: [
    './add-document-template-modal.component.scss',
    '../../../tags/manage-tags-modal/manage-tags-modal.component.scss'
  ]
})
export class AddDocumentTemplateModalComponent implements OnInit {
  private activeModal = inject(NgbActiveModal);
  private coverLetterService = inject(CoverLettersService);
  private toastr = inject(ToastrService);

  @ViewChild('fileUploadInput') fileUploadInput!: ElementRef<HTMLInputElement>;

  @Input() editedCoverLetter: CoverLetter | null = null;

  readonly allowedMaxFileSizeMB = 1;
  readonly acceptedFormatsList = ['.docx'];
  readonly acceptedFormats = this.acceptedFormatsList.join(', ');
  readonly deleteConfirmMessage = 'Deleting will permanently remove this document template. Do you want to proceed?';
  readonly notFoundMessage = 'No tags have been selected.';
  readonly selectTagsInputPlaceholder = 'Start typing a tag...';
  readonly titleControlCustomErrors = { maxLength: 'You\'ve exceeded the 100-character limit' };
  subheader = 'Cover Letter Template';
  isLoading = false;
  errorMessage = '';
  fileToUpload: FileCreateDtoWithSize | null = null;
  selectedTags: Tag[] = [];
  titleFormControl = new FormControl('', [Validators.required, CustomFormValidators.maxLength(100)]);
  search = new FormControl<string | null>('');

  ngOnInit(): void {
    this.activeModal.update({ modalDialogClass: 'common-modal-form-dialog' });
    this.subheader = (this.editedCoverLetter ? 'Edit ' : 'New ') + this.subheader;

    if (this.editedCoverLetter) {
      this.titleFormControl.setValue(this.editedCoverLetter.title);
      this.selectedTags = this.editedCoverLetter.tags;
      this.fileToUpload = {
        fileName: this.editedCoverLetter.fileName,
        base64FileContent: '',
        size: 0
      };
    }
  }

  confirm(): void {
    if (this.titleFormControl.invalid) {
      this.titleFormControl.markAsTouched();
      return;
    }

    if (!this.fileToUpload) {
      this.setErrorMessage('Please select a file to upload.');
      return;
    }

    if (this.editedCoverLetter) {
      this.patchTemplate();
    } else {
      this.createNewCoverLetter();
    }
  }

  async onFilesLoaded(files: File[]): Promise<void> {
    const file = files[0];

    if (!this.acceptedFormatsList.some((fileExtension) => file.name.endsWith(fileExtension))) {
      this.setErrorMessage(`Uploaded file has not allowed file extension. Please use one of allowed file extensions: ${ this.acceptedFormats }`);
      return;
    }

    if (bytesToMegabytes(file.size) > this.allowedMaxFileSizeMB) {
      this.setErrorMessage(`Uploaded file has not allowed file is too big. Max file size is ${ this.allowedMaxFileSizeMB }`);
      return;
    }

    this.fileToUpload = {
      fileName: file.name,
      base64FileContent: await toBase64(file),
      size: bytesToMegabytes(file.size)
    };

    this.setErrorMessage();
  }

  downloadFile(): void {
    if (this.editedCoverLetter) {
      this.setIsLoading(true);
      this.coverLetterService.downloadCoverLetterTemplate(this.editedCoverLetter.id, this.editedCoverLetter.fileName)
        .pipe(finalize(() => this.setIsLoading(false)))
        .subscribe((file) => downloadBase64File(file, this.editedCoverLetter!.fileName));
    } else if (!this.editedCoverLetter && this.fileToUpload?.base64FileContent)
      downloadBase64File(this.fileToUpload.base64FileContent, this.fileToUpload.fileName);
  }

  close(): void {
    this.activeModal.dismiss();
  }

  setErrorMessage(errorMessage = ''): void {
    this.errorMessage = errorMessage;
  }

  deleteTemplate(): void {
    if (this.editedCoverLetter && confirm(this.deleteConfirmMessage)) {
      this.coverLetterService.deleteCoverLetterTemplate(this.editedCoverLetter.id)
        .subscribe({
          next: () => this.activeModal.close(),
          error: (err) => console.warn('[deleteTemplate] ', err)
        });
    }
  }

  onSearchInputChange(e: Event): void {
    const event = e as KeyboardEvent;
    const text = event.target?.['value'] as string;
    this.search.setValue(text ?? '');
  }

  removeLastTagElement(event: KeyboardEvent): void {
    const text = (event.target?.['value'] || '') as string;

    if (
      !text.trim().length
      && this.selectedTags.length
      && (event.key === 'Backspace' || event.key === 'Delete')
    ) {
      this.assignTag({ tag: this.selectedTags[this.selectedTags.length - 1], assign: false });
    }
  }

  assignTag({ tag, assign }: { tag: Tag, assign: boolean }): void {
    if (assign && !this.selectedTags.find(t => t.tagId === tag.tagId)) {
      this.selectedTags.push(tag);
    } else {
      this.selectedTags = this.selectedTags.filter(t => t.tagId !== tag.tagId);
    }

    this.search.setValue('');
  }

  private patchTemplate(): void {
    this.coverLetterService.patchCoverLetter(new CoverLetter({
      ...this.editedCoverLetter,
      title: this.titleFormControl.value!,
      tags: this.selectedTags
    })).subscribe({
      next: () => {
        this.activeModal.close();
        this.toastr.success('Data saved successfully', 'Success');
      },
      error: (err) => console.warn('[patchTitle] ', err)
    });
  }

  private createNewCoverLetter(): void {
    const coverLetterCreateModel = new CoverLetterCreateModel({
      title: this.titleFormControl.value!,
      file: this.fileToUpload!,
      tagIds: this.selectedTags.map((tag) => tag.tagId),
    });

    this.coverLetterService.createCoverLetterTemplate(coverLetterCreateModel)
      .subscribe({
        next: () => {
          this.activeModal.close();
          this.toastr.success('Data saved successfully', 'Success');
        },
        error: (err) => console.warn('[createNewCoverLetter] ', err)
      });
  }

  private setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
    setControlDisabled(this.titleFormControl, isLoading);
  }
}
