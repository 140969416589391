export class CompanyDebt {
    amountOwing: number;
    nowDue: number;
    futureDue: number;
    overdue0_30: number;
    overdue31_60: number;
    overdue61_90: number;
    overdue90plus: number;

    constructor(debt: Partial<CompanyDebt> = {}) {
        this.amountOwing = debt.amountOwing ?? 0;
        this.nowDue = debt.nowDue ?? 0;
        this.futureDue = debt.futureDue  ?? 0;
        this.overdue0_30 = debt.overdue0_30  ?? 0;
        this.overdue31_60 = debt.overdue31_60  ?? 0;
        this.overdue61_90 = debt.overdue61_90  ?? 0;
        this.overdue90plus = debt.overdue90plus  ?? 0;
    }
}
