<div class="change-applied-container">
  <div class="change-applied-container-section">
    <div class="section-title-container">
      <span class="section-title">Change is applied for</span>
    </div>

    <div class="changes-table">
      <span class="changes-table-header table-left-border border-right-400">Entity Name</span>
      <span class="changes-table-header table-right-border">Authorising Officeholder / Offices Chairperson</span>

      @for (item of CHANGE_IS_APPLIED_FOR; track item.firstColumnValue) {
        <span class="changes-table-data-cell border-right-300">{{item.firstColumnValue}}</span>
        <span class="changes-table-data-cell">{{item.secondColumnValue}}</span>
      }
    </div>
  </div>

  <div class="change-applied-container-section">
    <div class="section-title-container">
      <span class="section-title">Documents to generate</span>
    </div>

    <div class="documents-to-generate-list">
      @for (document of DOCUMENTS_TO_GENERATE; track document; let first = $first) {
        <div class="document-to-generate-item d-flex align-items-center border-bottom-300" [ngClass]="{'border-top-300': first}">
          <span class="icon ic ic-file"></span>
          <span class="document-to-generate-item-label">{{ document }}</span>
        </div>
      }
    </div>
  </div>


</div>
