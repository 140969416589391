<div class="action-container">
  @if(type !== AnnualSignGroup.Signatories) {
    @if(disabled) {
      <i class="icon ic-sm ic-three-dots"></i>
    } @else {
      <div
        ngbDropdown
        [placement]="['bottom-end', 'bottom-start', 'top-start', 'top-end']"
        class="dropdown d-inline-flex"
        container="body"
      >
        <i class="icon ic-sm ic-three-dots" ngbDropdownToggle></i>

        <div ngbDropdownMenu class="annual-menu dropdown-menu dropdown-menu-right">
          @for (item of menuActions; track $index) {
            <div ngbDropdownItem class="dropdown-item" (click)="item.action()">
              <a class="menu-action-button">
                <i class="{{item.iconClass}}"></i>
                <span>{{item.name}}</span>
              </a>
            </div>
          }
        </div>
      </div>
    }
  }
</div>
