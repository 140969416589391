import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, from, lastValueFrom, tap } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return from(this.handle(request, next));
  }

  async handle(request: HttpRequest<unknown>, next: HttpHandler) {
    if (request.url.indexOf('addressvalidation.googleapis.com') >= 0) {
      return lastValueFrom(next.handle(request));
    }

    const token = await this.authService.getToken();

    const authRequest = request.clone({
      setHeaders: {
        Authorization: "Bearer " + token
      }
    })

    return await lastValueFrom(next.handle(authRequest).pipe(tap({
      error: error => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            void this.authService.logOut();
          }
        }
      }
    })));
  }
}
