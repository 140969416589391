import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Contact } from "../models/contact";
import { environment } from "../environments/environment";
import { map, Observable } from 'rxjs';
import { RelationshipType } from "../models/enums/relationshipTypeEnum";

@Injectable({
  providedIn: 'root'
})
export class ContactsService {
  constructor(private api: HttpClient) { }

  public createOrUpdateContact(contact: Contact) {
    return this.api.post<string>(`${ environment.api_url }/contacts`, contact, { observe: 'response' })
      .pipe(map((response) => response?.body as string));
  }

  public deleteContact(contactId: string) {
    return this.api.delete(`${environment.api_url}/contacts/${contactId}`);
  }

  public getContact(contactId: string): Observable<Contact> {
    return this.api.get<Contact>(`${ environment.api_url }/contacts/${contactId}`);
  }

  public getContacts(entityId: string, relationshipTypes?: RelationshipType[]): Observable<Contact[]> {
    if(relationshipTypes?.length) {
      const relationshipTypesParam = relationshipTypes.map(type => `relationshipTypes=${type}`).join('&');
      return this.api.get<Contact[]>(`${ environment.api_url }/contacts/${entityId}/entity?${relationshipTypesParam}`);
    } else {
      return this.api.get<Contact[]>(`${ environment.api_url }/contacts/${entityId}/entity`);
    }
  }
}
