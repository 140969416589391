import { Component, inject, Input } from '@angular/core';
import { Router } from "@angular/router";
import { ACNPipe } from "../../../../../../../pipes/acnPipe";
import {
  EntityNameNumberRecordComponent
} from "../../../../entity-name-number-record/entity-name-number-record.component";
import { ICellRendererParams } from "ag-grid-community";
import { AnnualRecord } from "../../../../../../../models/annualRecord";

type CellRendererParams = ICellRendererParams<AnnualRecord>

@Component({
  selector: 'app-ag-annual-name-acn',
  standalone: true,
  imports: [
    ACNPipe,
    EntityNameNumberRecordComponent
  ],
  templateUrl: './ag-annual-name-acn.component.html',
  styleUrl: './ag-annual-name-acn.component.scss'
})
export class AgAnnualNameAcnComponent {
  @Input() name = '';
  @Input() acn = '';

  private router = inject(Router);
  protected params!: CellRendererParams;

  agInit(params: CellRendererParams): void {
    this.params = params;
    this.name = this.params.data?.companyName ?? '';
    this.acn =  this.params.data?.acn ?? '';
  }

  refresh(params: CellRendererParams): boolean {
    this.params = params;
    return true;
  }

  onOpenCompanyProfile(e: MouseEvent): void {
    const documentId = this.params.data?.documentId ?? '';

    if (e.ctrlKey) {
      e.preventDefault();
      window.open(`/annual-statement/${documentId}`, '_blank');
    } else {
      void this.router.navigate(['/annual-statement', documentId]);
    }
  }
}
