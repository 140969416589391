<div class="select-container" [style.width]="width">
  <div class="header">
    @if (label) {
      <div class="label">{{ label }}</div>
    }

    @if (link && text) {
      <div>
        <app-link [link]="link" [text]="text"></app-link>
      </div>
    }
  </div>

  @if (isDisabled) {
    <div class="dropdown">
      <button
        type="button"
        class="input-wrapper input"
        [ngClass]="{'opened': dropdownOpen}"
        [disabled]="isDisabled"
      >
        @if (value) {
          <ng-container *ngTemplateOutlet="shareTemplate; context: { securityType: value }"></ng-container>
        } @else {
          <div class="select-placeholder">{{ placeholder }}</div>
        }
        <span class="icon ic-select-icon"></span>
      </button>
    </div>
  } @else {
    <div ngbDropdown class="dropdown" (openChange)="getDropdownState($event)">
      <button
        type="button"
        class="input-wrapper input"
        [ngClass]="{'opened': dropdownOpen}"
        ngbDropdownToggle
      >
        @if (value) {
          <ng-container *ngTemplateOutlet="shareTemplate; context: { securityType: value }"></ng-container>
        } @else {
          <div class="select-placeholder">{{ placeholder }}</div>
        }
        <span class="icon ic-select-icon"></span>
      </button>
      <div ngbDropdownMenu class="dropdown-menu">
        @if (options.length) {
          @for (securityType of options; track securityType) {
            <button
              ngbDropdownItem
              type="button"
              class="dropdown-item"
              [class.selected]="securityType === value"
              (click)="selectOption(securityType)"
            >
              <div class="dropdown-button">
                <ng-container *ngTemplateOutlet="shareTemplate; context: { securityType }"></ng-container>
                <span class="icon ic-circle-check"></span>
              </div>
            </button>
          }
        } @else {
          <div class="no-options">{{ noOptionsText }}</div>
        }

        <div [hidden]="!allowAddNewShareType">
          <app-divider margin="8px 0"></app-divider>

          <button
            ngbDropdownItem
            type="button"
            class="dropdown-item"
            (click)="openCreateShareTypeModal()"
          >
            <div class="dropdown-button add-share-type-button">
              <span class="icon ic-plus ic-xs"></span>
              <span>Add Share Type</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  }

  @if (helperText) {
    <div class="helper">{{ helperText }}</div>
  }

  @if (displayValidationError) {
    <app-validation-error></app-validation-error>
  }
</div>

<ng-template #shareTemplate let-securityType="securityType">
  <app-share-class-type-label [securityType]="securityType"></app-share-class-type-label>
</ng-template>
