import { Component, Input, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DatepickerHelper } from '../../../../../../custom-form-validators/date-picker-form-validators';
import { Address } from '../../../../../../models/address';
import { Contact } from '../../../../../../models/contact';
import { Document } from '../../../../../../models/document';
import { IndividualData } from '../../../../../../models/individualData';
import { SelectOption } from '../../../../../../models/selectOptions';
import { IStep } from '../../../../../../models/step';
import { BaseStepperFormComponent } from '../../../../stepper-form/base-stepper-component/base-stepper-form.component';
import {
  isAgentIndividualOptions,
  RA01ActionType,
  ra01ActionTypeOptions,
  RA01ChangeType,
  RA01ChangeTypeDictionary,
  RA01ChangeTypeOptions,
  RA01TypesStepsDictionary,
  RegisterCeaseChangeAgentForm,
  RegisterCeaseChangeAgentStepsEnum
} from './register-cease-change-agent-form.model';
import {
  AddressFormGroupComponent
} from "../../../../../components/reusable-form-groups/address-form-group/address-form-group.component";
import { StepperFormComponent } from "../../../../stepper-form/stepper-form.component";
import {
  StepperFormDescriptionComponent
} from "../../../../stepper-form/stepper-form-description/stepper-form-description.component";
import { DatePickerComponent } from "../../../../../components/common/date-picker/date-picker.component";
import { SelectComponent } from "../../../../../components/common/select/select.component";
import {
  IndividualDataFormGroupComponent, IndividualDataFormGroupControls
} from "../../../../../components/reusable-form-groups/individual-data-form-group/individual-data-form-group.component";
import {
  CompanyNameAcnFormGroupComponent
} from "../../../../../components/reusable-form-groups/company-name-acn-form-group/company-name-acn-form-group.component";
import {
  YesNoControlComponent
} from "../../../../../components/common/yes-no-control-component/yes-no-control.component";
import { InputComponent } from "../../../../../components/common/input/input.component";
import {
  ContactFormGroupComponent
} from "../../../../../components/reusable-form-groups/contact-form-group/contact-form-group.component";
import { CheckboxComponent } from "../../../../../components/common/checkbox/checkbox.component";
import { EntityData } from "../../../../../../models/entityData";
import { parseFullName } from "../../../../../../functions/parse-fullname";
import { EntityChangeData } from "../../../../../../models/entityChangeData";

@Component({
  selector: 'app-ra01-register-cease-change-agent',
  standalone: true,
  templateUrl: './ra01-register-cease-change-agent.component.html',
  styleUrl: '../../../../stepper-form/base-stepper-component/base-stepper-form.component.scss',
  imports: [
    ReactiveFormsModule,
    StepperFormComponent,
    StepperFormDescriptionComponent,
    DatePickerComponent,
    SelectComponent,
    IndividualDataFormGroupComponent,
    CompanyNameAcnFormGroupComponent,
    YesNoControlComponent,
    InputComponent,
    AddressFormGroupComponent,
    ContactFormGroupComponent,
    CheckboxComponent,
  ]
})
export class Ra01RegisterCeaseChangeAgentComponent extends BaseStepperFormComponent<RegisterCeaseChangeAgentStepsEnum, RegisterCeaseChangeAgentForm> implements OnInit {
  @Input() billingContact?: Contact;
  @Input() signingContact?: Contact;

  minDate!: NgbDateStruct;
  maxDate!: NgbDateStruct;

  readonly notificationTypeOptions: SelectOption[] = ra01ActionTypeOptions;
  readonly isAgentIndividualOptions: SelectOption[] = isAgentIndividualOptions;
  readonly RA01ChangeTypeOptions: SelectOption[] = RA01ChangeTypeOptions;
  readonly disabledIndividualControlKeys: (keyof IndividualDataFormGroupControls)[] = ['address'];
  readonly RA01ChangeType = RA01ChangeType;

  override readonly StepsEnum = RegisterCeaseChangeAgentStepsEnum;

  override stepperForm = new FormGroup({
    [RegisterCeaseChangeAgentStepsEnum.FormDescription]: new FormGroup({}),
    [RegisterCeaseChangeAgentStepsEnum.NotificationType]: new FormGroup({
      changeDate: new FormControl<Date | null>(DatepickerHelper.getToday(), [Validators.required]),
      actionType: new FormControl<RA01ActionType | null>(null, [Validators.required]),
    }),
    [RegisterCeaseChangeAgentStepsEnum.RegisterAnAgent]: new FormGroup({
      isIndividual: new FormControl<boolean | null>(null, [Validators.required]),

      registrantIfIndividual: IndividualDataFormGroupComponent.defineForm(), // IndividualData without address
      registrantIfCompany: CompanyNameAcnFormGroupComponent.defineForm(),    // ACN and company name

      isRegisteredWithTPB: new FormControl<boolean | null>(null, [Validators.required]),
      registrantTaxPractitionerNumber: new FormControl<string | null>(null, [Validators.required]),
    }),
    [RegisterCeaseChangeAgentStepsEnum.AddressDetails]: new FormGroup({
      registrantStreetAddressSameAsPostal: new FormControl<boolean | null>(null, [Validators.required]),
      registrantPostalAddress: AddressFormGroupComponent.defineForm(),
      registrantStreetAddress: AddressFormGroupComponent.defineForm(),
    }),
    [RegisterCeaseChangeAgentStepsEnum.ResponsiblePersonAndContactPerson]: new FormGroup({
      responsiblePerson: ContactFormGroupComponent.defineForm(),
      contactPersonIsResponsiblePerson: new FormControl<boolean | null>(null, [Validators.required]),
      contactPerson: ContactFormGroupComponent.defineForm(),
    }),
    [RegisterCeaseChangeAgentStepsEnum.ChangeOfDetails]: new FormGroup({
      changeType: new FormControl<RA01ChangeType | null>(null, [Validators.required]),

      addressChange: new FormGroup({
        newPostalAddress: AddressFormGroupComponent.defineForm(),
        newStreetAddressSameAsPostal: new FormControl<boolean | null>(null, [Validators.required]),
        newStreetAddress: AddressFormGroupComponent.defineForm(),
      }),

      newResponsiblePerson: ContactFormGroupComponent.defineForm(),
      isNewContactPersonResponsiblePerson: new FormControl<boolean | null>(true, [Validators.required]),
      newContactPerson: ContactFormGroupComponent.defineForm(),
    }),
    [RegisterCeaseChangeAgentStepsEnum.CeaseAnAgent]: new FormGroup({
      cessationResponsiblePerson: ContactFormGroupComponent.defineForm(),
      declarationAccepted: new FormControl<boolean>(false, [Validators.requiredTrue]),
    }),
  });

  stepsSignal = signal<IStep<RegisterCeaseChangeAgentStepsEnum>[]>([]);

  constructor() {
    super();
    this.setupSteps(RegisterCeaseChangeAgentStepsEnum);
    this.updateSteps();
    this.redirectAfterSubmit = true;
  }

  ngOnInit(): void {
    this.minDate = DatepickerHelper.getDateOfEstablishmentMinDate(this.companyChangeData.dateOfEstablishment);
    this.maxDate = DatepickerHelper.getNextNYearsStruct(10);

    // generic listeners
    this.listenActionTypeChanges();

    // registrant listeners
    this.listenIsIndividualChanges();
    this.listenIsRegisteredWithTPBChanges();
    this.listenRegistrantStreetAddressSameAsPostalChanges();
    this.listenContactPersonIsResponsiblePerson();

    // change listeners
    this.listenChangeTypeChanges();
    this.listenNewStreetAddressSameAsPostalChanges();
    this.listenIsNewContactPersonResponsiblePersonChanges();
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.setDefaultValues();
    this.setupChange();
  }

  override afterSubmit(changes: EntityChangeData[]) {
    this.setupChange(changes.find((change) => change.$type === RegisterCeaseChangeAgentForm.$type) as RegisterCeaseChangeAgentForm);
  }

  override setupChange(change: RegisterCeaseChangeAgentForm = this.formModel) {
    if (!this.isEdit && change === this.formModel)
      return;

    Object.values(this.stepperForm.controls).forEach(stepFormGroup => stepFormGroup.patchValue(change as any));

    this.stepperForm.controls[this.StepsEnum.NotificationType].controls.actionType.patchValue(change.actionType);

    if (change.actionType === RA01ActionType.Registration) {
      this.registrantForm.controls.isRegisteredWithTPB.patchValue(change.registrantTaxPractitionerNumber !== null);
      this.registrantForm.controls.registrantTaxPractitionerNumber.patchValue(change.registrantTaxPractitionerNumber);

      const isIndividualSelected = change.registrantIfIndividual !== null;
      const isCompanySelected = change.registrantIfCompany !== null;
      this.registrantForm.controls.isIndividual.setValue(
        isIndividualSelected
          ? true
          : isCompanySelected
            ? false
            : null
      );
    } else if (change.actionType === RA01ActionType.ChangeOfDetails) {
      const changeTypeKey = Object.keys(RA01ChangeTypeDictionary).find((key) => this.formModel[key]);

      if (changeTypeKey) {
        this.changeOfDetailsForm.controls.changeType.patchValue(changeTypeKey as RA01ChangeType ?? null);
      }
    }
  }

  override setCurrentStep(newStepIndex: number): void {
    this.currentStep = this.stepsSignal()[newStepIndex].step;
    this.currentStepIndex = newStepIndex;
  }

  override buildDocument(): Document | null {
    const changePropsDictionaty: Record<RA01ChangeType, boolean> = Object.keys(RA01ChangeTypeDictionary).reduce((acc, key) => {
      acc[key] = this.changeOfDetailsForm.value.changeType === key;

      return acc;
    }, {} as Record<RA01ChangeType, boolean>);

    const change = new RegisterCeaseChangeAgentForm({
      ...this.stepperForm.controls[RegisterCeaseChangeAgentStepsEnum.NotificationType].value as Partial<RegisterCeaseChangeAgentForm>,
      ...this.registrantForm.value as Partial<RegisterCeaseChangeAgentForm>,
      ...this.ceaseForm.value as Partial<RegisterCeaseChangeAgentForm>,
      registrantStreetAddressSameAsPostal: this.addressDetailsForm.controls.registrantStreetAddressSameAsPostal.value!,

      // Section registrant
      registrantIfIndividual: this.registrantForm.value.isIndividual ? new IndividualData({
        ...parseFullName(this.registrantForm.controls.registrantIfIndividual.controls.fullName.value ?? ''),
        dob: DatepickerHelper.buildDateString(this.registrantForm.value.registrantIfIndividual?.dob as Date)
      }) : null,
      registrantIfCompany: new EntityData({
        name: this.registrantForm.controls.registrantIfCompany.controls.name.value!,
        entityNumber: this.registrantForm.controls.registrantIfCompany.controls.acn.value!,
      }),
      registrantPostalAddress: new Address(this.addressDetailsForm.value.registrantPostalAddress as Partial<Address>),
      registrantStreetAddress: new Address(this.addressDetailsForm.value.registrantStreetAddress as Partial<Address>),
      responsiblePerson: ContactFormGroupComponent.buildContact(this.responsiblePersonAndContactPersonForm.controls.responsiblePerson),
      contactPerson: ContactFormGroupComponent.buildContact(this.responsiblePersonAndContactPersonForm.controls.contactPerson),

      // Change of Details
      ...this.changeOfDetailsForm.value as Partial<RegisterCeaseChangeAgentForm>,
      ...changePropsDictionaty,
      newPostalAddress: new Address(this.changeOfDetailsForm.value.addressChange?.newPostalAddress as Partial<Address>),
      newStreetAddress: new Address(this.changeOfDetailsForm.value.addressChange?.newStreetAddress as Partial<Address>),
      newResponsiblePerson: ContactFormGroupComponent.buildContact(this.changeOfDetailsForm.controls.newResponsiblePerson),
      newContactPerson: ContactFormGroupComponent.buildContact(this.changeOfDetailsForm.controls.newContactPerson),

      // Cease
      cessationResponsiblePerson: ContactFormGroupComponent.buildContact(this.ceaseForm.controls.cessationResponsiblePerson),
    });

    try {
      return new Document({
        changes: [change],
        entityId: this.companyChangeData?.entityId,
        type: 'c:ra01',
      });
    } catch (error) {
      this.toastr.error('Failed to create Document.', 'Error');
      return null;
    }
  }

  private updateSteps(notificationType: RA01ActionType | null = null): void {
    this.stepsSignal.set(this.steps.filter(({ step }) => RA01TypesStepsDictionary[`${ notificationType }`].includes(+step)));
  }

  private setDefaultValues(): void {
    this.registrantForm.controls.isRegisteredWithTPB.patchValue(false);
    this.responsiblePersonAndContactPersonForm.controls.contactPersonIsResponsiblePerson.patchValue(true);
    this.addressDetailsForm.controls.registrantStreetAddressSameAsPostal.patchValue(true);

    this.changeOfDetailsForm.controls.isNewContactPersonResponsiblePerson.patchValue(true);
    this.changeOfDetailsForm.controls.addressChange.controls.newStreetAddressSameAsPostal.patchValue(true);
  }

  // General
  private listenActionTypeChanges(): void {
    this.notificationTypeControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((actionType) => {
        this.updateRegistrantDisability(actionType === RA01ActionType.Registration);
        this.updateChangeOfDetailsDisability(actionType === RA01ActionType.ChangeOfDetails);
        this.updateCeaseDisability(actionType === RA01ActionType.Cessation);
        this.updateSteps(actionType);
      });
  }

  // Section registrant
  private listenIsIndividualChanges(): void {
    this.registrantForm.controls.isIndividual.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isIndividual) => {
        if (isIndividual === true) {
          this.registrantForm.controls.registrantIfCompany.disable();
          this.registrantForm.controls.registrantIfIndividual.enable();
          this.disabledIndividualControlKeys.forEach((key) => {
            this.registrantForm.controls.registrantIfIndividual.controls[key]?.disable();
          });
        } else if (isIndividual === false) {
          this.registrantForm.controls.registrantIfIndividual.disable();
          this.registrantForm.controls.registrantIfCompany.enable();
        }
      });
  }

  private listenIsRegisteredWithTPBChanges(): void {
    this.registrantForm.controls.isRegisteredWithTPB.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isRegisteredWithTPB) => {
        this.registrantForm.controls.registrantTaxPractitionerNumber[isRegisteredWithTPB ? 'enable' : 'disable']();
      });
  }

  private listenRegistrantStreetAddressSameAsPostalChanges(): void {
    this.addressDetailsForm.controls.registrantStreetAddressSameAsPostal.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isSame) => {
        this.addressDetailsForm.controls.registrantStreetAddress?.[isSame ? 'disable' : 'enable']();
      });
  }

  private listenContactPersonIsResponsiblePerson(): void {
    this.responsiblePersonAndContactPersonForm.controls.contactPersonIsResponsiblePerson.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((contactPersonIsResponsiblePerson) => {
        this.responsiblePersonAndContactPersonForm.controls.contactPerson[contactPersonIsResponsiblePerson ? 'disable' : 'enable']();
      });
  }

  // Change of Details
  private listenChangeTypeChanges(): void {
    this.changeOfDetailsForm.controls.changeType.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((changeType) => {
        this.updateAddressChange(changeType === RA01ChangeType.isAddressChange);

        if (changeType === RA01ChangeType.isAddressChange) {
          this.changeOfDetailsForm.controls.newResponsiblePerson.disable();
          this.changeOfDetailsForm.controls.isNewContactPersonResponsiblePerson.disable();
          this.changeOfDetailsForm.controls.newContactPerson.disable();
        } else if (changeType === RA01ChangeType.isResponsiblePersonChange) {
          this.changeOfDetailsForm.controls.newResponsiblePerson.enable();
          this.changeOfDetailsForm.controls.isNewContactPersonResponsiblePerson.enable();
        } else if (changeType === RA01ChangeType.isContactPersonChange) {
          this.changeOfDetailsForm.controls.newContactPerson.enable();
          this.changeOfDetailsForm.controls.isNewContactPersonResponsiblePerson.disable();
          this.changeOfDetailsForm.controls.newResponsiblePerson.disable();
        }
      });
  }

  private listenNewStreetAddressSameAsPostalChanges(): void {
    this.changeOfDetailsForm.controls.addressChange.controls.newStreetAddressSameAsPostal.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isSame) => {
        const action = isSame ? 'disable' : 'enable';
        this.changeOfDetailsForm.controls.addressChange.controls.newStreetAddress?.[action]();
      });
  }

  private listenIsNewContactPersonResponsiblePersonChanges(): void {
    this.changeOfDetailsForm.controls.isNewContactPersonResponsiblePerson.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isNewContactPersonResponsiblePerson) => {
        const action = (!(this.changeOfDetailsForm.value?.changeType === RA01ChangeType.isResponsiblePersonChange
            && isNewContactPersonResponsiblePerson === false)
          && this.changeOfDetailsForm.value?.changeType !== RA01ChangeType.isContactPersonChange) ? 'disable' : 'enable';

        this.changeOfDetailsForm.controls.newContactPerson?.[action]();
      });
  }

  private updateRegistrantDisability(isRegistration: boolean): void {
    const action = isRegistration ? 'enable' : 'disable';

    this.registrantForm?.[action]();
    this.addressDetailsForm?.[action]();
    this.responsiblePersonAndContactPersonForm?.[action]();

    this.registrantForm.controls.isIndividual.patchValue(this.registrantForm.controls.isIndividual.value);
    this.registrantForm.controls.isRegisteredWithTPB.patchValue(this.registrantForm.controls.isRegisteredWithTPB.value);
    this.addressDetailsForm.controls.registrantStreetAddressSameAsPostal.patchValue(this.addressDetailsForm.controls.registrantStreetAddressSameAsPostal.value);
    this.responsiblePersonAndContactPersonForm.controls.contactPersonIsResponsiblePerson.patchValue(this.responsiblePersonAndContactPersonForm.controls.contactPersonIsResponsiblePerson.value);
  }

  private updateChangeOfDetailsDisability(isChangeOfDetails: boolean): void {
    const action = isChangeOfDetails ? 'enable' : 'disable';

    this.changeOfDetailsForm?.[action]();

    this.changeOfDetailsForm.controls.changeType.patchValue(this.changeOfDetailsForm.controls.changeType.value);
    this.changeOfDetailsForm.controls.newResponsiblePerson.patchValue(this.changeOfDetailsForm.controls.newResponsiblePerson.value);
    this.changeOfDetailsForm.controls.addressChange.controls.newStreetAddressSameAsPostal.patchValue(this.changeOfDetailsForm.controls.addressChange.controls.newStreetAddressSameAsPostal.value ?? true);
  }

  private updateAddressChange(isAddressChange: boolean): void {
    const action = isAddressChange ? 'enable' : 'disable';

    this.changeOfDetailsForm.controls.addressChange?.[action]();

    this.changeOfDetailsForm.controls.addressChange.controls.newStreetAddressSameAsPostal.patchValue(this.changeOfDetailsForm.value.addressChange?.newStreetAddressSameAsPostal ?? true);
  }

  private updateCeaseDisability(isCease: boolean): void {
    const action = isCease ? 'enable' : 'disable';

    this.ceaseForm?.[action]();
  }

  get notificationTypeControl() {
    return this.stepperForm.controls[RegisterCeaseChangeAgentStepsEnum.NotificationType].controls.actionType;
  }

  get registrantForm() {
    return this.stepperForm.controls[RegisterCeaseChangeAgentStepsEnum.RegisterAnAgent];
  }

  get addressDetailsForm() {
    return this.stepperForm.controls[RegisterCeaseChangeAgentStepsEnum.AddressDetails];
  }

  get responsiblePersonAndContactPersonForm() {
    return this.stepperForm.controls[RegisterCeaseChangeAgentStepsEnum.ResponsiblePersonAndContactPerson];
  }

  get changeOfDetailsForm() {
    return this.stepperForm.controls[RegisterCeaseChangeAgentStepsEnum.ChangeOfDetails];
  }

  get ceaseForm() {
    return this.stepperForm.controls[RegisterCeaseChangeAgentStepsEnum.CeaseAnAgent];
  }
}
