<app-stepper-form-appliable
  header="Change of Trustee"
  [showSteps]="false"
  [showSaveAndCompleteLaterBtn]="false"
  [steps]="stepsSignal()"
  [currentStep]="currentStep"
  [currentStepIndex]="currentStepIndex"
  [form]="stepperForm"
  [isLoading]="isLoading"
>
  <div class="step-content" [formGroup]="stepperForm">
    <section
      class="step-section"
      [formGroupName]="StepsEnum.ExistingTrusteeDetails"
      [hidden]="currentStep !== StepsEnum.ExistingTrusteeDetails"
    >
      <h1 class="h1-heading">{{ stepsSignal()[currentStepIndex].label }}</h1>

      <ul class="list-unstyled">
        @if (existingTrusteesForm.controls.trusteeType.value === IndividualCorporateTrustee.Individual) {
          @for (trusteeFormGroup of existingTrusteesForm.controls.individualTrustee.controls; track trusteeFormGroup; let index = $index; let last = $last) {
            <li>
              <app-relationship-form-group
                nameControlLabel="Name of Trustee"
                [formGroup]="trusteeFormGroup"
              ></app-relationship-form-group>
            </li>

            @if (!last) {
              <li>
                <app-divider margin="24px 0"></app-divider>
              </li>
            }
          }
        } @else if (existingTrusteesForm.controls.trusteeType.value === IndividualCorporateTrustee.Corporate) {
          <app-corporate-relationship-form-group
            [hideIsOverseasCompany]="true"
            [formGroup]="existingTrusteesForm.controls.corporateTrustee"
          ></app-corporate-relationship-form-group>
        }
      </ul>
    </section>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.NewTrusteeDetails"
      [hidden]="currentStep !== StepsEnum.NewTrusteeDetails"
    >
      <h1 class="h1-heading">{{ stepsSignal()[currentStepIndex].label }}</h1>

      <div class="form-group">
        <app-select
          label="Trustee Type"
          formControlName="trusteeType"
          [options]="individualCorporateTrusteeOptions"
          [valuePrimitive]="true"
        ></app-select>
      </div>

      @if (newTrusteeDetailsForm.controls.trusteeType.value == IndividualCorporateTrustee.Individual) {
        <div>Trustee(s)</div>

        <ul class="individual-trustees-list" (click)="openCreateEditIndividualTrusteeModal()">
          @for (individualTrustee of newIndividualTrustees; track individualTrustee; let index = $index) {
            <li class="individual-trustee" (click)="$event.stopPropagation()">
              <i class="icon ic-my-account"></i>
              <span>{{ individualTrustee.individualDataOverride?.fullName }}</span>

              <button
                class="edit-icon"
                type="button"
                [disabled]="isLoading"
                (click)="openCreateEditIndividualTrusteeModal(index)"
              >
                <i class="ic-edit icon ic-sm"></i>
              </button>

              <button
                class="delete-icon"
                type="button"
                [disabled]="isLoading"
                (click)="$event.stopPropagation(); removeIndividualTrustee(index)"
              >
                <i class="ic-close icon ic-sm"></i>
              </button>
            </li>
          }
        </ul>

        @if (newTrusteeDetailsForm.controls.individualTrustee.touched && newTrusteeDetailsForm.controls.individualTrustee.errors) {
          <div class="form-group">
            <app-validation-error
              [control]="newTrusteeDetailsForm.controls.individualTrustee"
              [customErrors]="customErrors"
            />
          </div>
        }

        <app-button
          iconClass="ic-plus ic-xs icon"
          text="Add Trustee"
          [isDisabled]="!allowAddIndividualTrustees"
          (clickBtn)="openCreateEditIndividualTrusteeModal()"
        ></app-button>
      } @else if (newTrusteeDetailsForm.controls.trusteeType.value == IndividualCorporateTrustee.Corporate) {
        <app-corporate-relationship-form-group
          [hideIsOverseasCompany]="true"
          [formGroup]="newTrusteeDetailsForm.controls.corporateTrustee"
          (foundEntityId)="getCompanyProfile($event)"
        ></app-corporate-relationship-form-group>

        <div>
          <div>Director(s)</div>

          <ul class="corporate-trustees-directors-list" (click)="openCreateEditCorporateTrusteeDirectorModal()">
            @for (director of corporateTrusteeDirectors; track director; let index = $index) {
              <li class="corporate-trustee-director" (click)="$event.stopPropagation()">
                <i class="icon ic-my-account"></i>
                <span>{{ director.individualDataOverride?.fullName }}</span>

                <button
                  class="edit-icon"
                  type="button"
                  [disabled]="isLoading"
                  (click)="openCreateEditCorporateTrusteeDirectorModal(index)"
                >
                  <i class="ic-edit icon ic-sm"></i>
                </button>

                <button
                  class="delete-icon"
                  type="button"
                  [disabled]="isLoading"
                  (click)="$event.stopPropagation(); removeCorporateTrusteeDirector(index)"
                >
                  <i class="ic-close icon ic-sm"></i>
                </button>
              </li>
            }
          </ul>

          @if (newTrusteeDetailsForm.controls.corporateTrustee.touched && newTrusteeDetailsForm.controls.corporateTrustee.errors) {
            <div class="form-group">
              <app-validation-error
                [control]="newTrusteeDetailsForm.controls.corporateTrustee"
                [customErrors]="customErrors"
              />
            </div>
          }

          <app-button
            iconClass="ic-plus ic-xs icon"
            text="Add Director"
            [isDisabled]="!allowAddCorporateTrusteeDirectors"
            (click)="openCreateEditCorporateTrusteeDirectorModal()"
          ></app-button>
        </div>
      }
    </section>
  </div>
</app-stepper-form-appliable>
