import { Component, computed, EventEmitter, input, Output } from '@angular/core';
import { GridApi } from "ag-grid-enterprise";
import { ButtonComponent } from "../common/button/button.component";
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle } from "@ng-bootstrap/ng-bootstrap";
import { CheckboxComponent } from "../common/checkbox/checkbox.component";
import { AsyncPipe } from "@angular/common";
import { ColDef, ColGroupDef } from "ag-grid-community";

@Component({
  selector: 'app-list-grid-columns-filter',
  standalone: true,
  imports: [
    ButtonComponent,
    NgbDropdownMenu,
    NgbDropdown,
    NgbDropdownToggle,
    CheckboxComponent,
    AsyncPipe,
  ],
  templateUrl: './grid-columns-filter.component.html',
  styleUrl: './grid-columns-filter.component.scss'
})
export class GridColumnsFilterComponent {
  colDefs = input.required<(ColDef & ColGroupDef)[]>();
  gridApi = input.required<GridApi>();
  isRowData = input<boolean>(false);
  loading = input<boolean>();

  @Output() checkboxChange = new EventEmitter<ColDef>();
  @Output() groupCheckboxChange = new EventEmitter<ColGroupDef>();

  disableSelection = computed(() => this.colDefs().filter((c) => !c?.hide && c?.field).length === 1);

  onCheckboxChange(column: ColDef): void {
    this.checkboxChange.emit(column);
  }

  onGroupCheckboxChange(column: ColDef & ColGroupDef): void {
    column.hide = !column.hide;
    column.children.forEach((c: ColDef) => c.hide = column?.hide);
    this.groupCheckboxChange.emit(column);
  }

  onGroupChildCheckboxChange(column: ColDef & ColGroupDef, childIndex: number): void {
    (column.children[childIndex] as ColDef).hide = !(column.children[childIndex] as ColDef).hide;
    column.hide = !column.children.every((c: ColDef) => c.hide === false);
    this.groupCheckboxChange.emit(column);
  }
}
