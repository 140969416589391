import { NgClass, UpperCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IDictionaryItem } from '../../../../../models/shared/change-dictionary-helper.model';
import { DocumentTypePipe } from '../../../../../pipes/document-type.pipe';
import { DividerComponent } from '../../../../components/common/divider/divider.component';

export interface IDocumentChangePreview {
  expanded: boolean;
  type: string;
  description: string;
  dictionary: IDictionaryItem[];
  preview: IDictionaryItem[];
}

@Component({
  selector: 'app-document-changes-preview-details',
  standalone: true,
  imports: [
    NgClass,
    DocumentTypePipe,
    UpperCasePipe,
    DividerComponent,
  ],
  templateUrl: './document-changes-preview-details.component.html',
  styleUrl: './document-changes-preview-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentChangesPreviewDetailsComponent {
  @Input({ required: true }) change!: IDocumentChangePreview;
  @Input() showChevron = false;

  @Output() toggleDocumentChangeState = new EventEmitter<void>();
  @Output() downloadPreview = new EventEmitter<void>();
  @Output() editDocumentChange = new EventEmitter<void>();

  onToggleDocumentChangeState(): void {
    this.toggleDocumentChangeState.emit();
  }

  onDownloadPreview(): void {
    this.downloadPreview.emit();
  }

  onEditDocumentChange(): void {
    this.editDocumentChange.emit();
  }
}
