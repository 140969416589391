import { Component, inject, OnInit } from '@angular/core';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs';
import { AppCopyDirective } from '../../../../directives/copy.directive';
import { AsicAgentData } from '../../../../models/AsicAgentData.model';
import { DefaulValuePipe } from '../../../../pipes/enumsPipes/defaultValuePipe';
import { PhonePipe } from '../../../../pipes/phonePipe';
import { SettingsService } from '../../../../services/settings.service';
import { ButtonComponent } from '../../../components/common/button/button.component';
import { DividerComponent } from '../../../components/common/divider/divider.component';
import {
  AgActionIconButtonComponent
} from '../../../components/common/grid/components/ag-action-icon-button/ag-action-icon-button.component';
import { LoaderComponent } from '../../../components/common/loader/loader.component';
import { PageTitleComponent } from '../../../components/common/page-title/page-title.component';
import {
  ASICMailbox,
  EditAgentProfileModalComponent
} from './edit-agent-profile-modal/edit-agent-profile-modal.component';
import {
  EditAgentResetEdgePasswordModalComponent
} from './edit-agent-reset-edge-password-modal/edit-agent-reset-edge-password-modal.component';
import { ToastrService } from "ngx-toastr";
import { SubMenuItem } from "../../../../models/sectionFormMenu";
import { ModalFormsService } from "../../../../services/modal-forms.service";
import {
  AsicFormRA04
} from "../../../modals/documents/asic-forms/RA-forms/ra04-direct-debit-request/ra04-direct-debit-request.model";
import {
  RegisterCeaseChangeAgentForm
} from "../../../modals/documents/asic-forms/RA-forms/ra01-register-cease-change-agent/register-cease-change-agent-form.model";
import { Company } from "../../../../models/company";

@Component({
  selector: 'app-agent-settings',
  standalone: true,
  imports: [
    ButtonComponent,
    LoaderComponent,
    PageTitleComponent,
    AppCopyDirective,
    DefaulValuePipe,
    DividerComponent,
    AgActionIconButtonComponent,
    PhonePipe,
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle
  ],
  templateUrl: './agent-settings.component.html',
  styleUrls: [
    './agent-settings.component.scss',
    '../../../components/details/details.component.scss',
    '../../settings-styles.scss',
  ]
})
export class AgentSettingsComponent implements OnInit {
  private modalFormsService = inject(ModalFormsService);
  private modalService = inject(NgbModal);
  private settingsService = inject(SettingsService);
  private toastr = inject(ToastrService);

  readonly firstASICConnectionAlertMessage = 'We are currently connecting your account to ASIC. Please close this message and wait for further updates on the Alerts page.';
  readonly ASICMailbox = ASICMailbox;

  public agentSettingsFormMenu: SubMenuItem[] = [
    {
      title: 'Notification to Register, Change Details or Cease as an Agent',
      formName: 'Form RA01',
      action: () => this.openFormRa01(),
    },
    {
      title: 'Registered Agent Direct Debit Request',
      formName: 'Form RA04',
      action: () => this.openRa04DirectDebitRequestForm(),
    },
  ];

  agent: AsicAgentData = new AsicAgentData();
  isLoading = false;
  editAgentDetailsModalOpened = true;
  edgePasswordVisible = false;
  mailbox = ASICMailbox.Primary;

  ngOnInit(): void {
    this.loadAgentDetails();
  }

  editAgent(): void {
    if (this.editAgentDetailsModalOpened || this.isLoading) return;
    this.editAgentDetailsModalOpened = true;
    const beforeDismiss = () => confirm('Changes will not be saved. Do you want to proceed?');
    const modalRef = this.modalService.open(EditAgentProfileModalComponent, { size: 'lg', beforeDismiss });
    (modalRef.componentInstance as EditAgentProfileModalComponent).setupAgentSettings(structuredClone(this.agent), structuredClone(this.mailbox));

    modalRef.result.then(
      (agent: AsicAgentData) => {
        this.toastr.success('Data saved successfully', 'Success');
        this.agent = agent;

        if (this.isFirstASICConnection) {
          alert(this.firstASICConnectionAlertMessage);
        }

        this.loadAgentDetails();
      },
      () => this.editAgentDetailsModalOpened = false
    );
  }

  resetEdgePassword(): void {
    if (this.resetEdgePasswordButtonDisabled)
      return;

    const modalRef = this.modalService.open(EditAgentResetEdgePasswordModalComponent, { size: 'sm', centered: true });

    modalRef.result
      .then(
        () => alert('We are currently connecting your account to ASIC. Please close this message and wait for further updates on the Alerts page.'),
        () => {}
      );
  }

  private loadAgentDetails(): void {
    this.isLoading = true;

    this.settingsService.getAgentSettings()
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: agent => {
          this.agent.patch(agent);
          this.editAgentDetailsModalOpened = false;
        },
        error: (error) => console.warn('[AgentSettingsComponent.getAgentSettings] error: ', error)
      });
  }

  get isFirstASICConnection(): boolean {
    return (!this.agent.edgeLogin.length && !this.agent.edgePassword.length);
  }

  get resetEdgePasswordButtonDisabled(): boolean {
    return this.isLoading || this.isFirstASICConnection;
  }

  get modalOpened() {
    return this.modalFormsService.modalOpened;
  }

  public openFormRa01(): void {
    this.modalFormsService.openModalWithCompany(new RegisterCeaseChangeAgentForm(), new Company());
  }

  public openRa04DirectDebitRequestForm(): void {
    this.modalFormsService.openModalWithCompany(new AsicFormRA04(), new Company());
  }
}

