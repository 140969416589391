import { Component } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { EmailTemplate } from "../../../../../../models/email-templates/emailTemplate";
import {
  AgActionIconButtonComponent
} from "../../../../../components/common/grid/components/ag-action-icon-button/ag-action-icon-button.component";

type ActionEvent = (data: EmailTemplate | undefined) => void;
type CellRendererParams = ICellRendererParams<EmailTemplate> & {
  edit: ActionEvent
};

@Component({
  selector: 'app-ag-email-template-actions',
  standalone: true,
  imports: [AgActionIconButtonComponent],
  templateUrl: './ag-email-template-actions.component.html',
})
export class AgEmailTemplateActionsComponent implements ICellRendererAngularComp {
  params!: CellRendererParams;

  agInit(params: CellRendererParams): void {
    this.params = params;
  }

  refresh(params: CellRendererParams): boolean {
    this.params = params;
    return true;
  }

  edit(): void {
    this.params.edit(this.params.data);
  }
}
