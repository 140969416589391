import { NgClass, NgTemplateOutlet } from '@angular/common';
import { booleanAttribute, Component, EventEmitter, Input, Optional, Output } from '@angular/core';
import { NgControl } from '@angular/forms';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { SecurityRegistryRecord } from '../../../../models/securityRegistryRecord';
import {
  SecurityHolderIconLabelComponent
} from '../../../modals/share/components/security-holder-icon-label/security-holder-icon-label.component';
import { LinkComponent } from '../../common/link/link.component';
import { ValidationErrorComponent } from '../../common/validation-error/validation-error.component';

@Component({
  selector: 'app-select-shareholder',
  standalone: true,
  imports: [
    LinkComponent,
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
    ValidationErrorComponent,
    NgClass,
    NgTemplateOutlet,
    SecurityHolderIconLabelComponent
  ],
  templateUrl: './select-shareholder.component.html',
  styleUrls: ['../../../components/common/select/select.component.scss', 'select-shareholder.component.scss']
})
export class SelectShareholderComponent {
  @Input() width = '100%';
  @Input() placeholder = ''
  @Input() label = ''
  @Input() link = ''
  @Input() text = ''
  @Input() helperText = '';
  @Input() noOptionsText = 'There are no available options.';
  @Input({ required: true }) securityRegistryRecords: SecurityRegistryRecord[] = [];
  @Input({ transform: booleanAttribute }) disabled = false;
  @Input({ transform: booleanAttribute }) isDisabled = false;

  @Output() select = new EventEmitter<number>();

  #value: number | null = null;
  dropdownOpen = false;

  constructor(@Optional() protected ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  get value(): number | null {
    return this.#value;
  }

  set value(value: number | null) {
    this.#value = value;
    this.onChange(this.#value);
  }


  get displayValidationError(): boolean {
    return !!(this.ngControl && this.ngControl.touched && this.ngControl.errors);
  }

  selectOption(index: number): void {
    this.value = index;
    this.select.emit(index);
  }

  onChange: any = (): void => {};
  onTouched: any = (): void => {};

  writeValue(value: number): void {
    this.value = value;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  getDropdownState(dropdownState: boolean): void {
    this.dropdownOpen = dropdownState;
  }
}
