import { Pipe, PipeTransform } from '@angular/core';
import { TrustSubType, TrustSubtypesLabelsDictionary } from '../../models/enums/trustEnum';

@Pipe({
  standalone: true,
  name: 'trustSubType'
})
export class TrustSubTypePipe implements PipeTransform {
  transform(value: TrustSubType | undefined | null): string {
    if (value === undefined || value === null)
      return '-';
    return TrustSubtypesLabelsDictionary[value];
  }
}
