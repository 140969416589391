import { Component, EventEmitter, inject, Input, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, finalize, of, switchMap } from 'rxjs';
import { ScrollLoadDirective } from '../../../directives/scroll-load.directive';
import { Company } from '../../../models/company';
import { Entity } from '../../../models/entity';
import { EntityType } from '../../../models/enums/entityType';
import { Individual } from '../../../models/individual';
import { IndividualRecord } from '../../../models/IndividualRecord';
import { Note, NoteRecord } from '../../../models/note';
import { Trust } from '../../../models/trust';
import { User } from '../../../models/user';
import { AuthService } from '../../../services/auth.service';
import { Guid } from '../../helpers/guid.helper';
import { ITagsEntity } from '../../settings/components/tags/manage-tags-modal/manage-tags-modal.component';
import { CommonOffcanvasComponent } from '../common/common-offcanvas/common-offcanvas.component';
import { AgTagEntityTitleComponent } from '../common/grid/components/ag-tag-entity-title/ag-tag-entity-title.component';
import { LoaderStandaloneComponent } from '../common/loader-standalone/loader-standalone.component';
import { AttachedFileLabelComponent } from './components/attached-file-label/attached-file-label.component';
import {
  CreateEditNoteControlComponent
} from './components/create-edit-note-control/create-edit-note-control.component';
import { NoteListElementComponent } from './components/note-list-element/note-list-element.component';
import { NotesService } from './services/notes.service';

@Component({
  selector: 'app-notes',
  standalone: true,
  imports: [
    CommonOffcanvasComponent,
    CreateEditNoteControlComponent,
    AttachedFileLabelComponent,
    NoteListElementComponent,
    LoaderStandaloneComponent,
    ScrollLoadDirective,
    AgTagEntityTitleComponent
  ],
  templateUrl: './notes.component.html',
  styleUrl: './notes.component.scss'
})
export class NotesComponent extends CommonOffcanvasComponent {
  private toastr = inject(ToastrService);
  private notesService = inject(NotesService);
  private authService = inject(AuthService);

  @ViewChild(CreateEditNoteControlComponent) createEditNoteControl!: CreateEditNoteControlComponent;

  @Input() set entityId(value: string | null | undefined) {
    if (value && value !== Guid.EmptyGuid) {
      this._entityId = value;
      this.loadEntityNotes();
    }
  }

  @Input() set individualId(value: string | null | undefined) {
    if (value && value !== Guid.EmptyGuid) {
      this._individualId = value;
      this.loadEntityNotes();
    }
  }

  @Input() set entity(value: Entity | Company | Trust | undefined | null) {
    if (value && value.entityId !== Guid.EmptyGuid) {
      this._entityId = value.entityId;
      this._entityOrIndividual = value;
      this.loadEntityNotes();
    }
  }

  @Input() set individual(value: Individual | IndividualRecord | null | undefined) {
    if (value && value.individualId !== Guid.EmptyGuid) {
      this._individualId = value.individualId;
      this._entityOrIndividual = value;
      this.loadEntityNotes();
    }
  }

  @Input() entityType: EntityType | null = null;

  @Output() notesTotalChanged = new EventEmitter<1 | -1>(); // emits +1 when create a note and -1 when delete a note

  readonly notesContainerWidth = '620px';
  readonly loadNotesPerPage = 10;
  readonly closeNotesConfirmMessage = 'Before closing the page, please note that any unsaved changes will be lost. Do you want to proceed?';
  readonly deleteNoteConfirmationMessage = 'Are you sure you would like to delete this note?';
  isLoading = false;
  total = 0;
  notes: Note[] = [];
  noteForEdit: Note | null = null;
  private _entityId: string | null = null;
  private _individualId: string | null = null;
  private _entityOrIndividual: Entity | Company | Trust | Individual | IndividualRecord | null = null;

  submitNote(note: NoteRecord): void {
    this.isLoading = true;
    note.entityId = this._entityId;
    note.individualId = this._individualId;
    note.userId = this.authService.currentUserProfile()?.userId ?? null;

    this.notesService.createEditNote(note)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: (newNote) => {
          const note = new Note({ ...newNote, user: new User({ ...this.authService.currentUserProfile() }) });

          if (this.noteForEdit) {
            const index = this.notes.findIndex(n => n.id === newNote.id);
            this.notes[index] = note;
          } else {
            this.total++;
            this.notes.unshift(note);
            this.notesTotalChanged.emit(1);
          }

          this.notes = this.sortNotesByDate(this.notes);
          this.clearNotes();
        },
        error: (error) => {
          console.warn('[NotesComponent] createEditNote: ', error);
          this.toastr.error('Failed to create note');
        }
      });
  }

  loadEntityNotes(): void {
    this.notes = [];
    this.total = 0;
    this.noteForEdit = null;
    this.loadNotes();
  }

  loadNotes(skip = 0, take = this.loadNotesPerPage): void {
    this.isLoading = true;
    of(null)
      .pipe(
        debounceTime(100),
        switchMap(() =>
          this.notesService.getNotes(this._entityId ?? this._individualId!, skip, take)
            .pipe(finalize(() => this.isLoading = false))
        ))
      .subscribe({
        next: (response) => {
          this.total = response.total;
          this.notes = this.sortNotesByDate([...this.notes, ...response.records]);
        }
      });
  }

  loadMoreNotes(): void {
    this.loadNotes(this.notes.length, this.notes.length + this.loadNotesPerPage);
  }

  editNote(note: Note): void {
    this.noteForEdit = note;
  }

  deleteNote(noteId: string): void {
    if (!confirm(this.deleteNoteConfirmationMessage))
      return;

    this.notesService.deleteNote(noteId)
      .subscribe({
        next: () => {
          this.notes = this.notes.filter(n => n.id !== noteId);
          this.notesTotalChanged.emit(-1);
        },
        error: (error) => {
          console.warn('[NotesComponent] deleteNote: ', error);
          this.toastr.error('Failed to delete note');
        }
      });
  }

  downloadFile(filename: string, note: Note): void {
    this.notesService.downloadNoteFile(note.id, filename).subscribe();
  }

  deleteFile(filename: string, note: Note): void {
    this.notesService.deleteNoteFile(note.id, filename)
      .subscribe({
        next: () => {
          const note = this.notes.find(n => n.id === note.id);

          if (note?.attachemntsName) {
            note.attachemntsName = note.attachemntsName.filter(f => f !== filename);
          }
        },
        error: (error) => {
          console.warn('[NotesComponent] downloadFile: ', error);
          this.toastr.error(`Failed to download file "${ filename }"`);
        }
      });
  }

  closeNotes(): void {
    if (!this.noteForEdit && this.createEditNoteControl.isFormEmpty || confirm(this.closeNotesConfirmMessage)) {
      this.closeOffcanvas();
      this.clearNotes();
    }
  }

  private clearNotes(): void {
    this.createEditNoteControl.clearForm(true);
    this.noteForEdit = null;
  }

  private sortNotesByDate(notes: Note[]): Note[] {
    const notesIds = new Set();
    return notes
      .filter(note => {
        if (notesIds.has(note.id))
          return false;

        notesIds.add(note.id);
        return true;
      })
      .sort((a, b) => new Date(b.modifiedOn).getTime() - new Date(a.modifiedOn).getTime());
  }

  get allowLoadMoreNotes(): boolean {
    return this.total > this.loadNotesPerPage && this.notes.length < this.total;
  }

  get entity(): ITagsEntity | null {
    if (this.entityType !== null) {
      return this._entityOrIndividual;
    }

    return null;
  }

  get currentEntityId(): string | null {
    return this._entityId || this._individualId;
  }
}
