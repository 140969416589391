<div
  class="status-tag"
  [ngClass]="{
    'active': CompanyStatus.active === status,
    'archived': CompanyStatus.archived === status,
    'non-agent': CompanyStatus['non-agent'] === status,
    'deregistered': CompanyStatus.deregistered === status,
    'strike-off': CompanyStatus['strike-off'] === status,
    'administration': CompanyStatus['administration'] === status
  }"
>
  <span>{{ status | companyStatus }}</span>
</div>
