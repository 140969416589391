import { Component, inject, Input, OnInit, Signal, signal } from '@angular/core';
import { CommonModalFormComponent } from "../../common-modal-form/common-modal-form.component";
import { DividerComponent } from "../../../components/common/divider/divider.component";
import { RadioComponent } from "../../../components/common/radio/radio.component";
import { ButtonComponent } from "../../../components/common/button/button.component";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { SelectOption } from "../../../../models/selectOptions";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {
  MultipleInputEmailComponent
} from "../../../components/common/multiple-input-email/multiple-input-email.component";
import { EmailTemplatesService } from "../../../../services/email-templates.service";
import { EmailTemplate } from "../../../../models/email-templates/emailTemplate";
import { SendOption } from "../../../../models/enums/annualStatementEnums";
import { catchError, firstValueFrom, map, Observable, of, switchMap, tap } from "rxjs";
import { IEmailPreviewResponse } from "../../../../models/email-templates/IEmailPreviewResponse";
import { EmailTemplatesHelper } from "../../../../models/email-templates/emailTemplatesHelper";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { toSignal } from "@angular/core/rxjs-interop";
import { OrganisationService } from "../../../../services/organisation.service";
import { CurrencyPipe, DatePipe, formatDate } from "@angular/common";
import { Company } from "../../../../models/company";
import { CustomFormValidators } from "../../../../custom-form-validators/custom-form-validators";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import jsPDF from "jspdf";
import autoTable, { Styles } from "jspdf-autotable";
import { ACNPipe } from "../../../../pipes/acnPipe";
import { blobToBase64 } from "../../../helpers/blob-to-base64";
import { EmailMultipleAddress } from "../../../../models/email-templates/emailMultipleAddress";
import { ShadowDomViewerComponent } from "../../../components/common/shadow-dom-viewer/shadow-dom-viewer.component";

@Component({
  selector: 'app-annual-payment-reminders',
  standalone: true,
  imports: [
    CommonModalFormComponent,
    DividerComponent,
    RadioComponent,
    ButtonComponent,
    ReactiveFormsModule,
    MultipleInputEmailComponent,
    DatePipe,
    CurrencyPipe,
    NgxSkeletonLoaderModule,
    ShadowDomViewerComponent
  ],
  providers: [EmailTemplatesService],
  templateUrl: './annual-payment-reminders.component.html',
  styleUrl: './annual-payment-reminders.component.scss'
})
export class AnnualPaymentRemindersComponent implements OnInit {
  @Input() isAnnualReminder = true;
  @Input() companyProfile: Company | undefined;

  private fb: FormBuilder = inject(FormBuilder);
  private activeModal = inject(NgbActiveModal);
  private emailTemplatesService = inject(EmailTemplatesService);
  private organisationService = inject(OrganisationService);
  private acnPipe = inject(ACNPipe);
  private sanitizer = inject(DomSanitizer);
  private toastr = inject(ToastrService);

  private boldColumnStyles: Record<string, Partial<Styles>> = {
    key: {
      fontStyle: 'bold',
      cellWidth: 110,
      fillColor: 'white'
    },
    value: {
      fontStyle: 'bold',
      cellWidth: 90,
      fillColor: 'white'
    }
  };

  private normalColumnStyles: Record<string, Partial<Styles>> = {
    key: {
      fontStyle: 'normal',
      cellWidth: 110,
      fillColor: 'white'
    },
    value: {
      fontStyle: 'normal',
      cellWidth: 90,
      fillColor: 'white'
    }
  };

  readonly titleDelimiter = '=======================================================================';

  public sendByOptions: SelectOption[] = [
    { label: 'Email', value: SendOption.Email },
    { label: 'SMS', value: SendOption.Sms },
  ];

  public attachInvoiceOptions: SelectOption[] = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  public currentDate = new Date();
  public isSendDebtReminder = false;
  public loading = false;

  public template!: EmailTemplate;
  private emailTemplates: EmailTemplate[] = [];
  form = new FormGroup({
    sendBy: new FormControl<SendOption>(SendOption.Email),
    attachInvoice: new FormControl<boolean>(true),
    recipientEmails: new FormControl<EmailMultipleAddress[]>([], CustomFormValidators.minArrayLength(1))
  });

  htmlContent = signal<SafeHtml | null>(null);
  logo = toSignal(this.organisationService.getSmallLogo()
    .pipe(map(b => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(b))))
    .pipe(catchError(() => of('assets/svg/bolt-logo-icon.svg'))));

  logoPDF: Signal<string | undefined> = toSignal(
    this.organisationService.getSmallLogo().pipe(
      switchMap(logo => blobToBase64(logo)),
      catchError(() => of(''))
    )
  );

  phoneNumber: Signal<string | undefined> = toSignal(
    this.organisationService.getProfile().pipe(
      map(profile => profile.phoneNumber ?? '')
    )
  );

  async ngOnInit(): Promise<void> {
    this.fillEmailsRecipient();

    this.loading = true;
    this.emailTemplates = await firstValueFrom(this.emailTemplatesService.loadEmailTemplates());
    this.setEmailPreview();
  }

  private fillEmailsRecipient(): void {
    if (!this.companyProfile?.billingContact?.email) return;

    const recipient: EmailMultipleAddress = {
      email: this.companyProfile.billingContact.email.trim(),
      fullName: `${ this.companyProfile.billingContact.firstName.trim() } ${ this.companyProfile.billingContact.lastName.trim() }`
    };

    this.form.controls.recipientEmails.setValue([recipient]);
  }

  private setEmailPreview(): void {
    const sendBy = this.form.controls.sendBy.value as SendOption;
    //TODO: Which template needs to be used here?
    if (this.isAnnualReminder && sendBy === SendOption.Email) {
      this.selectEmailTemplate('as-debt-reminder');
    } else if (!this.isAnnualReminder && sendBy === SendOption.Email) {
      this.selectEmailTemplate('asic-debt-reminder');
    }
  }

  private selectEmailTemplate(templateCode: string): void {
    const emailTemplate = this.emailTemplates.filter(template => template.code === templateCode);
    if (emailTemplate.length) {
      this.template = emailTemplate[0];
      this.template.subjectTemplate = this.template.subjectTemplate
        .replace('{{CompanyName}}', this.companyProfile?.name ?? '');
      this.loadPreview().subscribe(() => {
        this.loading = false;
      });
    }
  }

  private prepareTemplateString(text: string | null): string {
    return (text ?? '')
      .replaceAll('\n', '')
      .replaceAll('<br />', '\n')
      .replaceAll('</br>', '\n');
  }

  private loadPreview(): Observable<IEmailPreviewResponse> {
    const errorFormResponse = of({ subject: '', htmlContent: '' });

    return this.emailTemplatesService.loadPreview(EmailTemplatesHelper.buildEmailPreviewRequest(this.buildTemplate()))
      .pipe(
        tap({
          next: (res) => this.htmlContent.set(this.sanitizer.bypassSecurityTrustHtml(res.htmlContent) ?? ''),
          error: (error) => {
            console.warn('[loadPreview] error: ', error);
            this.toastr.error("Something happened while loading preview", "Error");
            this.loading = false;
          }
        }),
        catchError(() => errorFormResponse),
      );
  }

  private buildTemplate(): EmailTemplate {
    return new EmailTemplate({
      ...this.template,
      subjectTemplate: this.template?.subjectTemplate,
      bodyTemplate: this.replaceNewLine(this.prepareTemplateString(this.template.bodyTemplate)),
      footerTemplate: this.replaceNewLine(this.prepareTemplateString(this.template.footerTemplate)),
    });
  }

  private replaceNewLine(text: string | null): string {
    return text?.replaceAll('\n', '</br>') ?? '';
  }

  public payDebt(): void {
  }

  public refreshDebt(): void {
  }

  public sendReminder(): void {
    if (this.form.controls.attachInvoice.value) {
      //TODO: change to attach PDF when sending email
      this.createPDF();
    }
  }

  public close(): void {
    this.activeModal.dismiss();
  }

  private createPDF(): void {
    const pdf = new jsPDF();

    this.createHeader(pdf);
    this.createBody(pdf);

    pdf.save('Payslip ' + formatDate(new Date(), 'dd-MM-yyyy', 'en-US') + '.pdf');
  }

  private createHeader(pdf: jsPDF): void {
    const currentProfileUser = this.organisationService.getCurrentUser();
    const headerText = 'Inquiries';
    const headerEmail = currentProfileUser?.email;
    const headerPhone = this.phoneNumber();
    const headerContent = `${ headerText }\n\n${ headerEmail }\n\n${ headerPhone }`;

    const companyName = this.companyProfile?.name ?? '';
    const companyAcn = this.acnPipe.transform(this.companyProfile?.acn ?? '');
    const companyAddress = this.companyProfile?.registeredAddress.normalizedFullAddress ?? '';
    const issueDate = 'Issue Date: ' + formatDate(new Date(), 'd MMMM yyyy', 'en-US');

    const img = new Image();
    img.src = this.logoPDF() ?? '';

    autoTable(pdf, {
      columnStyles: this.boldColumnStyles,
      didDrawCell: (data) => {
        if (data.column.index === 0 && data.row.index === 0) {
          pdf.addImage(this.logoPDF() ?? '', 'PNG', data.cell.x, data.cell.y, img.width, 15);
        }
      },
      body: [
        [
          { content: '', styles: { minCellHeight: 15, cellWidth: 110, fillColor: "white" } },
          {
            content: headerContent,
            styles: { cellWidth: 90, fillColor: "white", fontStyle: 'bold', overflow: 'visible' }
          },
        ],
      ],
    });

    autoTable(pdf, {
      startY: (pdf as unknown as { lastAutoTable: { finalY: number } }).lastAutoTable.finalY - 1,
      columnStyles: this.boldColumnStyles,
      body: [{ key: companyName, value: '' }],
    });

    autoTable(pdf, {
      startY: (pdf as unknown as { lastAutoTable: { finalY: number } }).lastAutoTable.finalY - 1,
      columnStyles: this.normalColumnStyles,
      body: [
        { key: companyAcn, value: '' },
        { key: companyAddress, value: '' },
      ],
    });

    autoTable(pdf, {
      columnStyles: this.boldColumnStyles,
      body: [{ key: 'Invoice Statement', value: '' }],
    });

    autoTable(pdf, {
      startY: (pdf as unknown as { lastAutoTable: { finalY: number } }).lastAutoTable.finalY - 1,
      columnStyles: this.normalColumnStyles,
      body: [{ key: issueDate, value: '' }],
    });

    autoTable(pdf, {
      startY: (pdf as unknown as { lastAutoTable: { finalY: number } }).lastAutoTable.finalY + 1,
      columnStyles: {
        key: {
          fontSize: 12,
          fontStyle: 'bold',
          fillColor: 'white'
        }
      },
      body: [{ key: this.titleDelimiter }],
    });
  }

  private createBody(pdf: jsPDF): void {
    const disclaimer = 'The fee specified in this invoice is based on the recent debt report' +
      ' and is subject to change based on ASIC.';
    const reason = 'ASIC Fee';
    const paidAmount = '$' + this.companyProfile?.companyDebt?.amountOwing;

    autoTable(pdf, {
      startY: (pdf as unknown as { lastAutoTable: { finalY: number } }).lastAutoTable.finalY + 1,
      columnStyles: this.boldColumnStyles,
      body: [{ key: 'Description', value: 'Price' }],
    });

    autoTable(pdf, {
      startY: (pdf as unknown as { lastAutoTable: { finalY: number } }).lastAutoTable.finalY + 1,
      columnStyles: this.normalColumnStyles,
      body: [{ key: reason, value: paidAmount }],
    });

    autoTable(pdf, {
      startY: (pdf as unknown as { lastAutoTable: { finalY: number } }).lastAutoTable.finalY + 1,
      columnStyles: this.boldColumnStyles,
      body: [{ key: 'Total', value: paidAmount }],
    });

    autoTable(pdf, {
      startY: (pdf as unknown as { lastAutoTable: { finalY: number } }).lastAutoTable.finalY + 1,
      columnStyles: {
        key: {
          fillColor: 'white'
        },
      },
      body: [{ key: '' }, { key: '' }, { key: '' }, { key: disclaimer }],
    });
  }
}
