import { Component, Input } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { TextMessageStatus } from "../../../../models/enums/textMessageStatus";
import { NgClass } from "@angular/common";
import { TextMessageRecord } from "../../../../models/textMessageRecord";

@Component({
  selector: 'app-ag-outgoing-text-message-status',
  standalone: true,
  imports: [NgClass],
  templateUrl: 'ag-outgoing-text-message-status.component.html',
  styleUrl: '../ag-outgoing-message-status/ag-outgoing-email-message-status.component.scss'
})
export class AgOutgoingTextMessageStatusComponent implements ICellRendererAngularComp {
  @Input() status!: TextMessageStatus | null;

  agInit(params: ICellRendererParams<TextMessageRecord>): void {
    this.status = params.data?.status ?? null;
  }

  refresh(params: ICellRendererParams<TextMessageRecord>): boolean {
    this.status = params.data?.status ?? null;
    return true;
  }

  get statusText(): string {
    return this.status !== null && this.status in TextMessageStatus ? TextMessageStatus[this.status] as string : '';
  }
}
