<app-stepper-form
  [header]="'Bulk Change of Address'"
  [steps]="steps"
  [currentStep]="currentStep"
  [currentStepIndex]="currentStepIndex"
  [form]="stepperForm"
  [isLoading]="isLoading"
  [showSteps]="false"
  (stepIndexChange)="onStepChange($event)"
>
  <div class="step-content" [ngClass]="{'step-content-error': (currentStep === StepsEnum.Summary && rowHasError)}">
    <app-stepper-form-description
      formKey="484 A1"
      [hidden]="currentStep !== StepsEnum.FormDescription"
    ></app-stepper-form-description>

    @if(currentStep === StepsEnum.FormDescription && this.formModel.isIndividual && this.formModel.bulkMatchesSelected.length > 1) {
      <div class="info">
        <div><i class="icon ic-circle-info"></i></div>
        <div class="text">
          We found that the current address is being used by {{this.formModel.bulkMatchesSelected.length}} other entities.
          Do you need to update their addresses to the new one? Click
          <a [routerLink]="'/bulk-changes'" [queryParams]="{ address: this.formModel.individualAddress }">here</a>
          to view the list and begin the process.
        </div>
      </div>
    }

    <section
      class="step-section"
      [formGroup]="form"
      [hidden]="currentStep !== StepsEnum.NewAddress"
    >
      <div class="mb-3">
        <app-address-and-occupier
          [minDateOfAddressChange]="minDate"
          [maxDateOfAddressChange]="maxDate"
          [address]="formModel.address"
          [useInternationalAddresses]="true"
          [occupiesTheAddress]="formModel.occupiesTheAddress"
          [occupierNameIfDoesntOccupy]="formModel.occupierNameIfDoesntOccupy"
          [occupierPartHidden]="trustOnly || !!formModel.isIndividual"
        ></app-address-and-occupier>
      </div>

      @if(!trustOnly) {
        <div class="document-type mb-3">
          <app-radio
            [label]="'Choose the type of document to generate'"
            [options]="documentTypeOptions"
            [(ngModel)]="formModel.documentationType"
            [ngModelOptions]="{standalone: true}"
          ></app-radio>
        </div>

        @if(!isAustraliaSelected) {
          <div class="info">
            <div><i class="icon ic-circle-info"></i></div>
            <div class="text">{{addressInfo}}</div>
          </div>
        }
      }
    </section>

    <section
      class="step-section gap-32"
      [formGroup]="form"
      [hidden]="currentStep !== StepsEnum.Summary"
    >
      <div class="change">
        <h2 class="title">Change of Address</h2>

        <app-list-grid
          class="change-grid"
          [rowData]="rows"
          [colDefs]="colDefs"
          [rowClassRules]="rowClassRules"
          [suppressRowClickSelection]="true"
          [pagination]="true"
          [paginationPageSizeSelector]="false"
          [suppressCellFocus]="true"
        ></app-list-grid>
      </div>
    </section>
  </div>
</app-stepper-form>
