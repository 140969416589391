import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, EMPTY, startWith, tap } from 'rxjs';
import { Company } from '../../../models/company';
import { Contact } from '../../../models/contact';
import { RelationshipType } from '../../../models/enums/relationshipTypeEnum';
import { Guid } from '../../helpers/guid.helper';
import { ContactsService } from '../../../services/contacts.service';
import { getOfficerByRoles } from '../../helpers/relatioship.helper';
import { CheckboxComponent } from "../../components/common/checkbox/checkbox.component";
import { DividerComponent } from "../../components/common/divider/divider.component";
import {
  CommonModalWrapperComponent
} from "../../components/common/common-modal-wrapper/common-modal-wrapper.component";
import { SelectOption } from "../../../models/selectOptions";
import { SelectComponent } from "../../components/common/select/select.component";
import { InputComponent } from "../../components/common/input/input.component";
import { InputPhoneNumberComponent } from "../../components/common/input-phone-number/input-phone-number.component";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ToastrService } from "ngx-toastr";
import { CompanyProfileService } from "../../company-profile/company-profile.service";
import { setControlDisabled } from "../../../functions/set-control-disabled";
import { CustomFormValidators } from "../../../custom-form-validators/custom-form-validators";
import { emailValidatorMsg } from "../../../validators/validatorMessages/custom-form-validators-messages";
import { SigningBillingContactBody } from "../../../models/signingBillingContact";

@Component({
  selector: 'app-billing-signing-contact',
  standalone: true,
  templateUrl: './billing-signing-contact.component.html',
  imports: [
    ReactiveFormsModule,
    CommonModalWrapperComponent,
    CheckboxComponent,
    SelectComponent,
    InputComponent,
    InputPhoneNumberComponent,
    DividerComponent
  ],
  styleUrl: './billing-signing-contact.component.scss'
})
export class BillingSigningContactComponent implements OnInit {
  @Input() editModel!: Company;
  @Input() directorContacts: Contact[] | undefined;

  private activeModal = inject(NgbActiveModal);
  private contactService = inject(ContactsService);
  private profileService = inject(CompanyProfileService);
  private toastr = inject(ToastrService);
  private destroyRef = inject(DestroyRef);

  protected readonly emailValidatorMsg = emailValidatorMsg;

  readonly Guid = Guid;
  allContacts: Contact[] = [];
  existingContacts: Contact[] = [];
  contactsOptions: SelectOption[] = [];
  billingContactsOptions: SelectOption[] = [];
  isLoading = false;

  form = new FormGroup({
    billingIsTheSame: new FormControl(false),
    billingContactId: new FormControl(null),
    billingContact: new FormGroup({
      firstName: new FormControl<string | null>(null, Validators.required),
      lastName: new FormControl<string | null>(null, Validators.required),
      email: new FormControl<string | null>(null, CustomFormValidators.emailValidator),
      phone: new FormControl<string | null>(null)
    }),
    signingContactId: new FormControl(null),
    signingContact: new FormGroup({
      firstName: new FormControl<string | null>(null),
      lastName: new FormControl<string | null>(null),
      email: new FormControl<string | null>(null, CustomFormValidators.emailValidator),
      phone: new FormControl<string | null>(null)
    }),
  });

  ngOnInit(): void {
    this.activeModal.update({ size: 'lg' });
    this.fillContactOptions();

    this.listenBillingIsTheSameChanges();
    this.listenBillingContactIdChanges();
    this.listenSigningContactIdChanges();

    this.form.patchValue({
      ...this.editModel as any,
      organisationId: this.editModel.organisationId ?? Guid.EmptyGuid,
      billingIsTheSame: this.editModel.signingContactId === this.editModel.billingContactId && this.editModel.billingContactId !== null
    });
  }

  close(): void {
    this.activeModal.dismiss();
  }

  fillContactOptions(): void {
    const existingContacts: Contact[] = [];
    const newContacts: Contact[] = [];

    // if(this.directorContacts?.length) {
    //   this.directorContacts.forEach(contact => {
    //     existingContacts.push(contact);
    //   })
    // }

    const directors = getOfficerByRoles(this.editModel.officers, [RelationshipType.Director, RelationshipType.AlternativeDirector])
      .filter(director => !director.end);

    directors.forEach((director) => {
      if (director.individual?.contacts?.length) {
        existingContacts.push(director.individual?.contacts[0]);
        return;
      }

      const directorContact = new Contact({
        ...director.individualDataOverride,
        individualId: director.individualId!,
        contactId: Guid.generate()
      });

      newContacts.push(directorContact);
    });

    if (this.editModel.signingContact && !this.existingContacts.some((contact) => contact.contactId === this.editModel.signingContact?.contactId))
      existingContacts.push(this.editModel.signingContact);

    const contactsDuplicates = new Set<string>();
    this.existingContacts = existingContacts.filter((contact) => {
      if (!contactsDuplicates.has(contact.contactId)) {
        contactsDuplicates.add(contact.contactId);
        return true;
      }

      return false;
    });
    this.allContacts = [...this.existingContacts, ...newContacts];
    this.contactsOptions = this.allContacts
      .map((directorContact) => ({
        label: `${ directorContact?.firstName } ${ directorContact?.lastName }`,
        value: directorContact?.contactId
      }));

    this.billingContactsOptions = [...this.contactsOptions];
    if (this.editModel.billingContact && !this.allContacts.some((contact) => contact.contactId === this.editModel.billingContact?.contactId)) {
      this.existingContacts.push(this.editModel.billingContact);
      this.billingContactsOptions.push({
        label: `${this.editModel.billingContact.firstName} ${this.editModel.billingContact.lastName}`,
        value: this.editModel.billingContactId
      })
    }

    this.billingContactsOptions.push({
      label: 'New Contact',
      value: Guid.EmptyGuid
    });
  }

  submit(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const body: SigningBillingContactBody = {
      signingContact: null,
      billingContact: null,
      signingContactIsBillingContact: !!this.form.controls.billingIsTheSame.value
    }

    const existingSigningContact = this.existingContacts
      .find((contact) => contact.contactId === this.form.controls.signingContactId.value);

    if (existingSigningContact) {
      existingSigningContact.email = this.form.controls.signingContact.controls.email.value!;
      existingSigningContact.phone = this.form.controls.signingContact.controls.phone.value!;
    }

    const newSigningContact = new Contact(this.form.controls.signingContact.value as Partial<Contact>);
    newSigningContact.organisationId = this.editModel.organisationId ?? Guid.EmptyGuid;
    body.signingContact = existingSigningContact ?? newSigningContact;

    if (!this.form.controls.billingIsTheSame.value) {
      const existingBillingContact = this.existingContacts
        .find((contact) => contact.contactId === this.form.controls.billingContactId.value);

      if (existingBillingContact) {
        existingBillingContact.email = this.form.controls.billingContact.controls.email.value!;
        existingBillingContact.phone = this.form.controls.billingContact.controls.phone.value!;
      }

      const newBillingContact = new Contact(this.form.controls.billingContact.value as Partial<Contact>);
      newBillingContact.organisationId = this.editModel.organisationId ?? Guid.EmptyGuid;
      body.billingContact = existingBillingContact ?? newBillingContact;
    }

    this.isLoading = true;
    this.form.disable();
    this.profileService.updateSigningBillingContact(this.editModel.entityId, body).pipe(
      catchError((error) => {
        console.error(error);
        this.form.enable();
        this.isLoading = false;
        this.toastr.error('Error happened while saving contacts', 'Error');
        return EMPTY;
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(res => {
      this.activeModal.close();
      this.form.enable();
      this.isLoading = false;
    });
  }

  private listenBillingIsTheSameChanges(): void {
    this.form.controls.billingIsTheSame.valueChanges
      .pipe(
        startWith(this.editModel.billingContactId === this.editModel.signingContactId && this.editModel.signingContactId !== null),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((billingIsTheSame) => {
        setControlDisabled(this.form.controls.billingContact, billingIsTheSame);
        setControlDisabled(this.form.controls.billingContactId, billingIsTheSame);
      });
  }

  private listenBillingContactIdChanges(): void {
    this.form.controls.billingContactId.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((billingContactId) => {
        if (!billingContactId) return;

        if (billingContactId === Guid.EmptyGuid) {
          this.form.controls.billingContact.reset();
        } else {
          const selectedBillingContact = this.allContacts.find((contact) => contact.contactId === billingContactId);

          if (selectedBillingContact) {
            this.form.controls.billingContact.patchValue(selectedBillingContact);
          }
        }
      });
  }

  private listenSigningContactIdChanges(): void {
    this.form.controls.signingContactId.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((billingContactId) => {
        if (!billingContactId) return;

        if (billingContactId === Guid.EmptyGuid) {
          this.form.controls.signingContact.reset();
        } else {
          const selectedBillingContact = this.allContacts.find((contact) => contact.contactId === billingContactId);

          if (selectedBillingContact) {
            this.form.controls.signingContact.patchValue(selectedBillingContact);
          }
        }
      });
  }

  get signingContactLabel(): string {
    return this.form.controls.billingIsTheSame.value
      ? 'Signing and Billing Contact'
      : 'Signing Contact';
  }
}
