import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Company } from '../../../models/company';
import { Document } from '../../../models/document';
import { EntityChangeData } from '../../../models/entityChangeData';
import { CompanyChangeData } from '../../../models/enums/companyChangeData';
import { CompanySubtype, CompanyType } from '../../../models/enums/companyEnums';
import { SelectOption } from '../../../models/selectOptions';
import { ChangeDictionaryHelper } from '../../../models/shared/change-dictionary-helper.model';
import { Form206Component } from './form206.component';

export enum ChangeCompanyTypeStepsEnum {
  FormDescription = 0,
  ChangeOfType = 1,
}

export const companyTypeOptions: SelectOption[] = [
  { value: 1, label: 'Proprietary company limited by shares' },
  { value: 2, label: 'Unlimited proprietary company' },
  { value: 3, label: 'Public company limited by shares' },
  { value: 4, label: 'Company limited by guarantee' },
  { value: 5, label: 'Unlimited public company' },
  { value: 6, label: 'Public no liability company' },
  { value: 7, label: 'Company limited by both shares and guarantee' },
];

export const companySubtypeDictionaryOptions: Record<number, SelectOption[]> = {
  [1]: [
    { value: 2, label: 'Unlimited proprietary company' },
    { value: 4, label: 'Unlimited public company' },
    { value: 3, label: 'Public company limited by shares' },
  ],
  [2]: [
    { value: 1, label: 'Proprietary company limited by shares' },
    { value: 3, label: 'Public company limited by shares' },
    { value: 4, label: 'Unlimited public company' },
  ],
  [3]: [
    { value: 4, label: 'Unlimited public company' },
    { value: 2, label: 'Unlimited proprietary company' },
    { value: 1, label: 'Proprietary company limited by shares' },
    { value: 6, label: 'Public no liability company' },
  ],
  [4]: [
    { value: 3, label: 'Public company limited by shares' },
    { value: 4, label: 'Unlimited public company' },
    { value: 1, label: 'Proprietary company limited by shares' },
    { value: 2, label: 'Unlimited proprietary company' },
  ],
  [5]: [
    { value: 3, label: 'Public company limited by shares' },
    { value: 1, label: 'Proprietary company limited by shares' },
    { value: 2, label: 'Unlimited proprietary company' },
  ],
  [6]: [
    { value: 3, label: 'Public company limited by shares' },
    { value: 1, label: 'Proprietary company limited by shares' },
  ],
  [7]: [
    { value: 1, label: 'Proprietary company limited by shares' },
    { value: 3, label: 'Public company limited by shares' },
    { value: 4, label: 'Company limited by guarantee' },
  ],
};

export class ChangeCompanyType extends EntityChangeData {
  static override readonly $type = 'ChangeCompanyType';
  companyType: CompanyType;
  companySubtype: CompanySubtype;

  constructor(data: Partial<ChangeCompanyType> = {}) {
    super(data);

    // TODO: Add ChangeType
    this.changeType = 'c:206';
    this.companyType = data.companyType!;
    this.companySubtype = data.companySubtype!;
  }

  override toDictionary(): { key: string; value: string; }[] {
    const dict = new ChangeDictionaryHelper();

    dict
      .add('Date of Change', this.changeDate)
      .add('Company Type', CompanyType[this.companyType])
      .add('Company Subtype', CompanySubtype[this.companySubtype])

    return dict.dictionary;
  }

  override fillModal(modalRef: NgbModalRef, document: Document, actualCompany: Company): NgbModalRef {
    modalRef = this.fillModalWithCompany(modalRef, actualCompany, true);
    (modalRef.componentInstance as Form206Component).companyChangeData.documentId = document.documentId;
    return modalRef;
  }

  override fillModalWithCompany(modalRef: NgbModalRef, company: Company, isEdit: boolean): NgbModalRef {
    const instance = modalRef.componentInstance as Form206Component;

    instance.isEdit = isEdit;
    instance.companyChangeData = new CompanyChangeData(company);
    instance.formModel = structuredClone(this);

    return modalRef;
  }
}
