import { DestroyRef, inject } from "@angular/core";
import { AnnualStatementsService } from "../../services/annual-statements.service";
import { ModalFormsService } from "../../services/modal-forms.service";
import {
  CsvExportParams,
  ExcelExportParams, GetContextMenuItems,
  GetRowIdFunc,
  GetRowIdParams,
  GridApi,
  IServerSideDatasource, IServerSideGetRowsParams,
} from "ag-grid-community";
import { ColumnWithExportName } from "../../models/columnWithExportName";
import { AnnualRecord } from "../../models/annualRecord";
import { formatDate } from "@angular/common";
import { ExportCompanyListComponent } from "../modals/export-company-list/export-company-list.component";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ExportTypeEnum } from "../../models/enums/exportTypeEnum";
import { AnnualStatementPageFilter, AnnualStatementStatus, SigningStatus } from "../../models/enums/annualStatementEnums";
import { AnnualViewComponent } from "../modals/annual/annual-view/annual-view.component";
import {
  AnnualPaymentRemindersComponent
} from "../modals/annual/annual-payment-reminders/annual-payment-reminders.component";
import { Router } from "@angular/router";
import { FilterOption, FilterParams } from "../../models/gridFilter";
import { uniqueArrayToFilterOptionHelper } from "../helpers/unique-array-to-filter-option.helper";
import { OrganisationService } from "../../services/organisation.service";
import { convertEnumToFilterOption } from "../helpers/enum-to-filter-option.helper";
import { TagsService } from "../../services/tags.service";
import { deepClone } from "fast-json-patch/commonjs/core";
import { ToastrService } from "ngx-toastr";
import { tap } from "rxjs";
import {AnnualSentComponent} from "./annual-sent/annual-sent.component";
import {
  AnnualDocumentReminderComponent
} from "../modals/annual/annual-document-reminder/annual-document-reminder.component";

export class BaseAnnualStatements {
  public annualStatementsService = inject(AnnualStatementsService);
  protected tagsService = inject(TagsService);
  protected destroyRef = inject(DestroyRef);
  private organisationService = inject(OrganisationService);
  private router = inject(Router);
  private toastr = inject(ToastrService);
  private modalFormsService = inject(ModalFormsService);

  gridApi!: GridApi;
  colDefs: ColumnWithExportName[] = [];
  pageFilter: AnnualStatementPageFilter = 0;
  totalRows = 0;
  currentAnnualIndex = 0;
  gridOptions = {
    overlayNoRowsTemplate: '<span class="no-rows-overlay">No data available</span>',
  };
  excelExportParams = this.annualStatementsService.exportParamsXls() as ExcelExportParams;
  excelStyles = this.annualStatementsService.excelStyles;

  readonly serverRowModelType: 'serverSide' | 'infinite' | 'clientSide' = 'serverSide';
  readonly defaultPageSize = 20;
  readonly cacheBlockSize = 20
  protected readonly ExportTypeEnum = ExportTypeEnum;
  AnnualStatementPageFilter = AnnualStatementPageFilter;

  checkedAnnualStatements: AnnualRecord[] = [];
  annualRows: AnnualRecord[] = [];

  profileUserNameList: string[] = this.organisationService.getCachedProfileUsers().map(u => u?.fullName);
  accountManagerFilterOptions: FilterOption[] = uniqueArrayToFilterOptionHelper(this.profileUserNameList);
  partnerManagerFilterOptions: FilterOption[] = uniqueArrayToFilterOptionHelper(this.profileUserNameList);
  statusFilterOptions: FilterOption[] = convertEnumToFilterOption(AnnualStatementStatus);
  signingStatusFilterOption: FilterOption[] = convertEnumToFilterOption(SigningStatus);

  dataSource: IServerSideDatasource = this.getDataSource(0,'');

  getRowId: GetRowIdFunc = (params: GetRowIdParams<AnnualRecord>) => params.data.documentId;

  onGridReady(gridApi: GridApi): void {
    this.gridApi = gridApi;
    this.gridApi?.addEventListener('modelUpdated', () => {
      const totalPages = (this.gridApi as unknown as { paginationProxy: { totalPages: number }}).paginationProxy.totalPages;
      if (!totalPages) {
        this.gridApi.showNoRowsOverlay();
      } else {
        this.gridApi.hideOverlay();
      }
    });
  }

  protected view(companyId: string): void {
    void this.router.navigate(['/company-profile', companyId]);
  }

  protected send(documentId: string | null) : void {
    if(!documentId) {
      this.toastr.error('Document id not found', 'Error')
      return;
    }

    const componentInstance = this.modalFormsService.openModal(AnnualViewComponent, {
    }).componentInstance as AnnualViewComponent;

    componentInstance.documentId = documentId;
    componentInstance.partnerManagerName = this.annualRows.find(row => row.documentId)?.partnerManagerName ?? '';
  }

  protected edit(documentId: string): void {
    if(!documentId) {
      this.toastr.error('Document id not found', 'Error')
      return;
    }

    void this.router.navigate(['/annual-statement', documentId]);
  }

  protected downloadDocuments(companyId: string): void {
  }

  protected archive(companyId: string): void {
  }

  protected sendReminder(companyId: string): void {
    const componentInstance = this.modalFormsService.openModal(AnnualDocumentReminderComponent).componentInstance;
  }

  protected sendPaymentReminder(companyId: string): void {
    const componentInstance = this.modalFormsService.openModal(AnnualPaymentRemindersComponent).componentInstance;
  }

  protected regenerate(companyId: string): void {
  }

  protected markSent(companyId: string): void {
  }

  protected markSigned(companyId: string): void {
  }

  getDataSource(pageFilter: AnnualStatementPageFilter, searchText: string, startRow?: number, endRow?: number): IServerSideDatasource {
    return {
      getRows: (params: IServerSideGetRowsParams) => {
        this.annualStatementsService.pageFilter.set(pageFilter);
        const request = params.request;
        this.annualStatementsService.getAnnualList(request, pageFilter, searchText, startRow, endRow).subscribe({
          next: result => {
            this.annualRows = result.records;
            this.totalRows = result.total;
            params.success({
              rowData: result.records,
              rowCount: result.total,
            });
          },
          error: (err) => {
            console.error(err);
            params.fail();
          }
        });
      }
    };
  }

  selectAnnualStatements(annual: AnnualRecord[]): void {
    this.checkedAnnualStatements = annual;
  }

  onSearch(searchText: string): void {
    this.dataSource = this.getDataSource(this.pageFilter, searchText);
  }

  exportSelectedAnnualStatementsToXls(): void {
    this.exportAnnualList(true, ExportTypeEnum.EXCEL);
  }

  exportSelectedAnnualStatementsToCsv(): void {
    this.exportAnnualList(true, ExportTypeEnum.CSV);
  }

  getSelectedFilterOptions(columnId: string): string {
    if(!this.gridApi) { return ''; }
    const filterParams = this.gridApi.getColumn(columnId)?.getColDef().filterParams as FilterParams;
    const filterOptions = filterParams?.filterOptions ?? [];
    const selectedFilterOptions = filterOptions.filter(o => o.active).map(o => o.label).join(', ');
    return selectedFilterOptions;
  }

  exportAnnualList(isBulkExport: boolean, exportType: ExportTypeEnum): void {
    if (this.modalOpened()) return;
    const previousPaginationPageSize = this.gridApi.paginationGetPageSize();
    this.gridApi.updateGridOptions({ paginationPageSize: this.totalRows });
    const componentInstance = this.modalFormsService.openModal(ExportCompanyListComponent, {
      modalDialogClass: 'export-company-list'
    }).componentInstance as ExportCompanyListComponent;

    componentInstance.title = 'Export Annual Statements';
    componentInstance.subTitle = 'annual statements selected';
    componentInstance.colDefs = deepClone(this.colDefs) as ColumnWithExportName[];
    componentInstance.numberOfCompanies = isBulkExport ? this.checkedAnnualStatements.length : this.totalRows;
    componentInstance.exportType = exportType;
    componentInstance.confirm.pipe(
      tap(() => this.gridApi.updateGridOptions({ paginationPageSize: this.totalRows })),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((columnForExport: string[]) => {
      const nameIndex = columnForExport.findIndex(field => field === 'companyName');
      if(nameIndex >= 0) {
        columnForExport.splice(nameIndex + 1, 0, 'acn');
      }

      this.annualStatementsService.numberColumnForExport.set(columnForExport.length - 1);

      const fileName = this.getSelectedFilterOptions('status')
        ? this.getSelectedFilterOptions('status') + ' Annual Statements ' + formatDate(new Date(), 'dd-MM-yyyy', 'en-US')
        : 'Annual Statements ' + formatDate(new Date(), 'dd-MM-yyyy', 'en-US');

      if (exportType === ExportTypeEnum.EXCEL) {
        const params: ExcelExportParams = isBulkExport ? { columnKeys: columnForExport, onlySelected: true } : { columnKeys: columnForExport };
        const exportParamsXls = this.annualStatementsService.exportParamsXls() as ExcelExportParams;
        this.gridApi.exportDataAsExcel({ ...exportParamsXls, ...params, fileName });
      } else if (exportType === ExportTypeEnum.CSV) {
        const params: CsvExportParams = isBulkExport ? { columnKeys: columnForExport, onlySelected: true } : { columnKeys: columnForExport };
        this.gridApi.exportDataAsCsv({ ...params, fileName });
      }

      this.gridApi.updateGridOptions({ paginationPageSize: previousPaginationPageSize });
    });

    componentInstance.resetPagination.pipe(
      tap(() => this.gridApi.updateGridOptions({ paginationPageSize: previousPaginationPageSize })),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  getContextMenuItems(gridApi: GridApi & {defaultItems: unknown[]; node: {data: {documentId: string}}}): GetContextMenuItems {
    const items = gridApi.defaultItems.slice();

    return ([
      {
        name: 'Open Customize and Send in New Tab',
        action: () => {
          const documentId = gridApi.node.data.documentId;
          if(documentId) {
            const url = `/annual-statement/${documentId}`;
            window.open(url, '_blank');
          }
        }
      },
      'separator',
      ...items,
    ]) as unknown as GetContextMenuItems
  }

  get modalOpened() {
    return this.modalFormsService.modalOpened;
  }
}
