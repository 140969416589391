import { AnnualStatementEmailPayload } from "./AnnualStatementEmailPayload";

export class AnnualStatementEsignEmailPayload extends AnnualStatementEmailPayload {
  static override $type = 'AnnualStatementEsignEmailPayload';

  signUrl: string;

  constructor(data: Partial<AnnualStatementEsignEmailPayload> = {}) {
    super(data);
    this.signUrl = data.signUrl ?? '';
  }

}
