<div class="card settings-page">
  <app-page-title
    header="Reminders"
    iconClass="ic-bell"
    backButtonPath="settings"
  >
  </app-page-title>

  <div class="card-body">
    <app-list-grid
      [rowData]="reminders"
      [colDefs]="colDefs"
      [suppressRowClickSelection]="true"
      [suppressCellFocus]="true"
    />
  </div>
</div>
