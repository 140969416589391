import {
  booleanAttribute,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormsModule } from "@angular/forms";
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
  NgbDatepicker, NgbDateStruct,
  NgbInputDatepicker
} from "@ng-bootstrap/ng-bootstrap";
import { NgxMaskDirective, NgxMaskService } from "ngx-mask";
import { formatDate } from "@angular/common";
import { DateRange } from "../../../../models/gridFilter";

const DATE_SEPARATOR = '-';

@Component({
  selector: 'app-date-range-picker',
  standalone: true,
  imports: [
    FormsModule,
    NgbInputDatepicker,
    NgbDatepicker,
    NgxMaskDirective,
  ],
  templateUrl: './date-range-picker.component.html',
  styleUrl: './date-range-picker.component.scss',
})
export class DateRangePickerComponent implements OnInit {
  @Input() width = '100%';
  @Input() placeholder = 'Select Date'
  @Input() label = ''
  @Input() helperText = '';
  @Input() startDate: Date | undefined;
  @Input() endDate: Date | undefined;
  @Input() minDate: NgbDateStruct = { year: 1900, month: 1, day: 1 };
  @Input() maxDate: NgbDateStruct = { year: 2100, month: 12, day: 31 };
  @Input({ transform: booleanAttribute }) showActions = true;
  @Output() dateRangeSelected = new EventEmitter<DateRange>();

  calendar = inject(NgbCalendar);
  formatter = inject(NgbDateParserFormatter);
  elementRef: ElementRef = inject(ElementRef);

  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null = this.calendar.getToday();
  toDate: NgbDate | null = this.calendar.getToday();
  value: string | undefined;
  prevValue: string | undefined;
  protected readonly mask = 'd0/M0/0000 - d0/M0/0000';

  @HostListener('document:click', ['$event'])
  clickOut(event: MouseEvent): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.value = this.prevValue;
    }
  }

  constructor(private maskService: NgxMaskService) {}

  ngOnInit(): void {
    if (this.startDate) {
      this.fromDate = NgbDate.from({
        year: this.startDate.getFullYear(),
        month: this.startDate.getMonth() + 1,
        day: this.startDate.getDate()
      }) ?? this.calendar.getToday();
    }

    if (this.endDate) {
      this.toDate = NgbDate.from({
        year: this.endDate.getFullYear(),
        month: this.endDate.getMonth() + 1,
        day: this.endDate.getDate()
      });
    }
    if (!this.startDate && !this.endDate) {
      this.value = this.initialDateValue;
      this.prevValue = this.initialDateValue;
    }
    if (this.startDate && this.endDate) {
      this.value = formatDate(this.startDate, 'ddMMyyyy', 'en-US') + formatDate(this.endDate, 'ddMMyyyy', 'en-US');
      this.prevValue = this.value;
    }
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    const from = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
    const to = this.toDate ? new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day) : null;

    if (from && to) {
      this.value = formatDate(from, 'ddMMyyyy', 'en-US') + formatDate(to, 'ddMMyyyy', 'en-US');
      this.prevValue = this.value;
      this.dateRangeSelected.emit({ from, to });
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ??
      (this.toDate && date.equals(this.toDate)) ??
      this.isInside(date) ??
      this.isHovered(date)
    );
  }

  onEnterPress(): void {
    if (this.value) {
      if (this.value.match(/^[0-9]/gm) && this.value.length === 16) {
        this.value = this.maskService.applyMask(this.value, this.mask);
      }
      this.prevValue = this.value;
      const [from, to] = this.value.split(DATE_SEPARATOR);
      if (this.testValue(from.trim()) && this.testValue(to.trim())) {
        this.dateRangeSelected.emit({
          from: this.createDateObject(this.parseValue(from)),
          to: this.createDateObject(this.parseValue(to)),
        })
      }
    }
  }

  testValue(value: string): boolean {
    const parsed = this.formatter.parse(value);
    const isValid = this.calendar.isValid(NgbDate.from(parsed));
    return !!parsed && isValid;
  }

  parseValue(value: string): NgbDate {
    return NgbDate.from(this.formatter.parse(value))!;
  }

  createDateObject({ year, month, day }: NgbDate): Date {
    return new Date(year, month - 1, day);
  }

  get initialDateValue(): string {
    return Array.from({ length: 2 }).fill(formatDate(new Date(), 'ddMMyyyy', 'en-US')).join('');
  }

  onActionButtonClick(apply = true): void {
    if (apply) {
      this.onEnterPress();
    } else {
      this.value = '';
    }
  }
}
