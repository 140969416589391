@if(!loading) {
  @if (isSomeFilterTag) {
    <div class="grid-search-filter-container">
      @if(groupByOptions.length) {
        <app-group-by-btn
          [groupByOptions]="groupByOptions"
          [isDisabled]="!rowData.length"
          (clearGroupBy)="clearGroupBy()"
          (changeGroupByOption)="onChangeGroupByOption($event)"
        ></app-group-by-btn>
      }

      @for (column of colDefs; track column.headerName; let columnIndex = $index) {
        @if (column.filterTagShowed) {
          <div class="grid-search-filter" ngbDropdown #dropdown="ngbDropdown" (click)="column.filterTagSelected = !!(dropdown.openChange)">
            <div
              class="filter-tag"
              ngbDropdownToggle
              placement="bottom"
              [ngbTooltip]="dropdown.isOpen()
              ? ''
              : column.cellDataType !== CellDataType.DATE_STRING
                && column.cellDataType !== CellDataType.NUMBER
                && isActiveOption(column.filterParams.filterOptions) ? tooltipContent : ''"
            >
              <ng-template #tooltipContent>
                @for (option of column.filterParams.filterOptions; track option.label) {
                  @if (option.active) {
                    <div class="popup-item">{{option.label}}</div>
                  }
                }
              </ng-template>

              <div class="column-name">{{ column?.headerName || column?.exportColumnName}}</div>
              <i class="fa fa-sm fa-chevron-right"></i>
              <div class="filter-name">
                @if(column?.cellDataType === CellDataType.DATE_STRING) {
                  {{ (column.filterParams.dateRangeSelected?.from | date) + ' to ' + (column.filterParams.dateRangeSelected?.to | date) }}
                } @else if(column?.cellDataType === CellDataType.NUMBER) {
                  {{ column.filterParams.numberRangeSelected?.min + '-' + column.filterParams.numberRangeSelected?.max }}
                } @else if(column?.cellDataType === CellDataType.TEXT) {
                  {{ column.filterParams.searchText }}
                } @else {
                  {{ showTagInfo(column.filterParams.filterOptions) }}
                }
              </div>
              <i class="icon ic-tiny ic-close" (click)="closeFilterTag(columnIndex)"></i>
            </div>

            <div
              ngbDropdownMenu
              aria-labelledby="dropdownBasic1"
              [ngClass]="{'date-container': (column?.cellDataType === CellDataType.DATE_STRING)}"
            >
              <app-search-filter-list
                [column]="column"
                [groupByOptions]="groupByOptions"
                (dateRangeSelected)="onDateRangeSelected(columnIndex, $event)"
                (numberRangeSelected)="onNumberRangeSelected(columnIndex, $event)"
                (filterOptionChange)="onFilterOptionChange(columnIndex, $event)"
                (filterSearch)="onFilterSearch(columnIndex, $event)"
                (searchByOption)="onSearchByOption(columnIndex, $event)"
              ></app-search-filter-list>
            </div>
          </div>
        }
      }

      @if(numberOfNotShowedTags) {
        <div class="grid-search-filter" ngbDropdown #dropdown="ngbDropdown">
          <app-button
            ngbDropdownToggle
            [btnColor]="(dropdown.openChange | async) ? 'gray' : 'white'"
            [iconClass]="'icon ic-plus'"
            (clickBtn)="resetSelectedColumn()"
          ></app-button>

          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" [ngClass]="{'date-container': isDateColumn}">
            @if(selectedColIndex === -1) {
              @for (column of colDefs; track column.field) {
                @if (!column.filterTagShowed && (column?.headerName || column?.exportColumnName) && (column?.filter === 'agSetColumnFilter') && column.filterParams.filterOptions.length) {
                  <div class="column-option d-flex" (click)="onSelectColumn($index)">
                    <div class="filter-option-name">{{ column?.headerName || column?.exportColumnName }}</div>
                  </div>
                }
              }
            } @else {
              <app-search-filter-list
                [column]="colDefs[selectedColIndex]"
                [groupByOptions]="groupByOptions"
                (dateRangeSelected)="onDateRangeSelected(selectedColIndex, $event)"
                (numberRangeSelected)="onNumberRangeSelected(selectedColIndex, $event)"
                (filterOptionChange)="onFilterOptionChange(selectedColIndex, $event)"
                (filterSearch)="onFilterSearch(selectedColIndex, $event)"
                (searchByOption)="onSearchByOption(selectedColIndex, $event)"
              ></app-search-filter-list>
            }

          </div>
        </div>
      }
    </div>
  } @else {
    <div class="grid-search-filter-container">
      @if(!rowData.length) {
        <app-button
          [btnColor]="'white'"
          [text]="'Filter'"
          [iconClass]="'icon ic-filter'"
          [isDisabled]="!rowData.length"
        ></app-button>
      } @else {
        @if(groupByOptions.length) {
          <app-group-by-btn
            [groupByOptions]="groupByOptions"
            [isDisabled]="!rowData.length"
            (clearGroupBy)="clearGroupBy()"
            (changeGroupByOption)="onChangeGroupByOption($event)"
          ></app-group-by-btn>
        }

        <div class="grid-search-filter dropdown-selector" ngbDropdown #startBtnDropdown="ngbDropdown">
          <app-button
            ngbDropdownToggle
            [btnColor]="(startBtnDropdown.openChange | async) ? 'gray' : 'white'"
            [text]="'Filter'"
            [iconClass]="'icon ic-filter'"
            (clickBtn)="onClickFilter()"
          ></app-button>

          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" [ngClass]="{'date-container': isDateColumn}">
            @if(!isSomeFilterOptions) {
              <div class="column-option d-flex">No filters</div>
            }

            @if(selectedColIndex === -1) {
              @for (column of colDefs; track column.field) {
                @if ((column?.headerName || column?.exportColumnName) && (column?.filter === 'agSetColumnFilter') && column.filterParams.filterOptions.length) {
                  <div class="column-option d-flex" (click)="onSelectColumn($index)">
                    <div class="filter-option-name">{{column?.headerName || column?.exportColumnName }}</div>
                  </div>
                }
              }
            } @else {
              <app-search-filter-list
                [column]="colDefs[selectedColIndex]"
                [groupByOptions]="groupByOptions"
                (dateRangeSelected)="onDateRangeSelected(selectedColIndex, $event)"
                (numberRangeSelected)="onNumberRangeSelected(selectedColIndex, $event)"
                (filterOptionChange)="onFilterOptionChange(selectedColIndex, $event)"
                (filterSearch)="onFilterSearch(selectedColIndex, $event)"
                (searchByOption)="onSearchByOption(selectedColIndex, $event)"
              ></app-search-filter-list>
            }
          </div>
        </div>
      }

      @if (selectedColIndex > -1 && isSomeActiveFilterOptions(selectedColIndex)) {
        <div class="grid-search-filter">
          <app-button
            [btnColor]="'white'"
            [iconClass]="'icon ic-plus'"
            (clickBtn)="addFilterToTag(selectedColIndex)"
          ></app-button>
        </div>
      }
    </div>
  }
} @else {
  <div class="grid-search-filter">
    <app-button
      [btnColor]="'white'"
      [text]="'Filter'"
      [iconClass]="'icon ic-filter'"
      [isDisabled]="loading"
    ></app-button>
  </div>
}
