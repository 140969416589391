<div class="note-list-element" [class.disabled]="disabled">
  <p class="text">{{ note.text }}</p>

  <ul class="attached-files" [hidden]="!note.attachemntsName.length">
    @for (filename of note.attachemntsName; track filename) {
    <li>
      <app-attached-file-label
        [filename]="filename"
        [readonly]="true"
        [disabled]="disabled"
        (downloadFile)="onDownloadFile(filename)"
        (deleteFile)="onDeleteFile(filename)"
      ></app-attached-file-label>
    </li>
    }
  </ul>

  <div class="note-footer">
    <div class="credentials">
      <div class="author">{{ note.user?.fullName }}</div>
      <div class="date">
        {{ note.modifiedOn | date: 'dd MMMM hh:mm a' }}
      </div>
    </div>

    <div class="controls">
      <app-ag-action-icon-button
        iconClass="ic-sm ic-edit"
        [disabled]="disabled"
        (action)="onEditNote()"
      ></app-ag-action-icon-button>

      <app-ag-action-icon-button
        iconClass="ic-sm ic-trash"
        [disabled]="disabled"
        (action)="onDeleteNote()"
      ></app-ag-action-icon-button>
    </div>
  </div>
</div>
