import { Company } from '../company';

export class CompanyChangeData {
  companyName: string;
  companyAcn: string;
  entityId: string;
  organisationId: string;
  dateOfEstablishment: string;
  isPublic: boolean;

  documentId?: string;

  constructor(company: Partial<Company> = {}) {
    this.companyName = company.name ?? '';
    this.companyAcn = company.acn ?? '';
    this.entityId = company.entityId ?? '';
    this.dateOfEstablishment = company.dateOfEstablishment as unknown as string;
    this.isPublic = company.isPublic ?? false;
    this.organisationId = company.organisationId ?? '';
  }
}
