import { Component, EventEmitter, inject, Input, OnInit, Optional, Output } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CorporateHolderModel, IndividualHolderModel } from '../../../../../models/securityRegistryRecord';
import { ButtonComponent } from '../../../../components/common/button/button.component';
import {
  AddCorporateSecurityHolderModalComponent
} from '../add-corporate-security-holder-modal/add-corporate-security-holder-modal.component';
import {
  AddIndividualSecurityHolderModalComponent
} from '../add-individual-security-holder-modal/add-individual-security-holder-modal.component';
import { SecurityHolderIconLabelComponent } from '../security-holder-icon-label/security-holder-icon-label.component';
import { Relationship } from "../../../../../models/relationship";
import { IndividualData } from "../../../../../models/individualData";
import { Guid } from "../../../../helpers/guid.helper";
import { Address } from "../../../../../models/address";

@Component({
  selector: 'app-share-joint-security-holder',
  standalone: true,
  imports: [
    SecurityHolderIconLabelComponent,
    ButtonComponent
  ],
  templateUrl: './share-joint-security-holder.component.html',
  styleUrl: './share-joint-security-holder.component.scss'
})
export class ShareJointSecurityHolderComponent implements OnInit, ControlValueAccessor {
  private modalService = inject(NgbModal);

  @Input() securityHolders: (IndividualHolderModel | CorporateHolderModel)[] = [];
  @Input() suggestedAddresses: Address[] = [];
  @Input() fullNameSuggestions: string[] = [];

  @Output() addSecurityHolder = new EventEmitter<IndividualHolderModel | CorporateHolderModel>();
  @Output() editHolder = new EventEmitter<IndividualHolderModel | CorporateHolderModel>();
  @Output() deleteHolder = new EventEmitter<IndividualHolderModel | CorporateHolderModel>();

  disabled = false;
  #value: (IndividualHolderModel | CorporateHolderModel)[] | null = null;

  constructor(@Optional() protected ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.value = this.value ?? this.securityHolders;
  }

  onEditHolder(holder: IndividualHolderModel | CorporateHolderModel): void {
    if (holder.$type === IndividualHolderModel.$type) {
      this.addIndividual((holder as IndividualHolderModel));
    } else if (holder.$type === CorporateHolderModel.$type) {
      this.addCorporate((holder as CorporateHolderModel));
    }
  }

  onDeleteHolder(holder: IndividualHolderModel | CorporateHolderModel): void {
    const index = this.value.findIndex(h => h === holder);
    this.value = this.value.filter((_, i) => i !== index);
    this.deleteHolder.emit(holder);
  }

  addIndividual(individualHolderModel: IndividualHolderModel | null = null): NgbModalRef {
    const modalRef = this.openModal(AddIndividualSecurityHolderModalComponent);
    modalRef.componentInstance.individualHolderModel = individualHolderModel;
    modalRef.componentInstance.suggestedAddresses = this.suggestedAddresses;
    modalRef.componentInstance.fullNameSuggestions = this.fullNameSuggestions;
    modalRef.result.then(
      (newIndividualSecurityHolder: IndividualHolderModel) => {
        this.addOrReplaceHolder(newIndividualSecurityHolder);
      },
      () => {
      }
    );

    return modalRef;
  }

  addCorporate(corporateHolderModel: CorporateHolderModel | null = null): NgbModalRef {
    const modalRef = this.openModal(AddCorporateSecurityHolderModalComponent);
    modalRef.componentInstance.corporateHolderModel = corporateHolderModel;
    modalRef.componentInstance.suggestedAddresses = this.suggestedAddresses;
    modalRef.result.then(
      (newCorporateSecurityHolder: CorporateHolderModel) => {
        this.addOrReplaceHolder(newCorporateSecurityHolder);
      },
      () => {
      }
    );

    return modalRef;
  }

  addOrReplaceHolder(newHolder: IndividualHolderModel | CorporateHolderModel): void {
    const existingCorporateSecurityHolderIndex = this.value.findIndex(holder => holder?.relationshipId && newHolder?.relationshipId === holder?.relationshipId);

    if (existingCorporateSecurityHolderIndex >= 0) {
      this.value[existingCorporateSecurityHolderIndex] = Object.assign(this.value[existingCorporateSecurityHolderIndex], newHolder);
    } else {
      this.value.push(newHolder);
    }

    this.ngControl.control?.updateValueAndValidity();
  }

  private openModal<T>(component: new () => T): NgbModalRef {
    return this.modalService.open(component, {
      centered: true,
      size: 'sm',
      modalDialogClass: 'modal-sm-plus'
    });
  }

  get value(): (IndividualHolderModel | CorporateHolderModel)[] {
    return this.#value ?? [];
  }

  set value(value: (IndividualHolderModel | CorporateHolderModel)[] | null) {
    this.#value = value ?? [];
    this.onChange(this.#value);
  }

  get displayValidationError(): boolean {
    return !!(this.ngControl && this.ngControl.touched && this.ngControl.errors);
  }

  onChange: any = (): void => {
  };
  onTouched: any = (): void => {
  };

  writeValue(value: (IndividualHolderModel | CorporateHolderModel)[] | null): void {
    this.value = value;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  static toRelationships(holders: (IndividualHolderModel | CorporateHolderModel)[]): Relationship[] {
    return holders.map((holderModel) => {
      if (holderModel.$type === 'CorporateHolderModel') {
        holderModel = holderModel as CorporateHolderModel;
        return new Relationship({
          entityDataOverride: holderModel.entityData,
          relationshipId: Guid.generate(),
        });
      } else {
        holderModel = holderModel as IndividualHolderModel;
        return new Relationship({
          individualDataOverride: new IndividualData(holderModel.individualData),
          relationshipId: Guid.generate(),
        });
      }
    });
  }
}
