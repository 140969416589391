import { Component, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { distinctUntilChanged, finalize, Observable, tap } from 'rxjs';
import { DatepickerHelper } from '../../../../../custom-form-validators/date-picker-form-validators';
import { enumToSelectOptions } from '../../../../../functions/enums-to-list-formatter';
import { Document } from '../../../../../models/document';
import { ShareholderRelationshipDetails } from '../../../../../models/relationship';
import {
  SecurityRegistryRecord
} from '../../../../../models/securityRegistryRecord';
import { SelectOption } from '../../../../../models/selectOptions';
import { IStep } from '../../../../../models/step';
import { AsPipe } from '../../../../../pipes/asPipe';
import { SecurityService } from '../../../../../services/security.service';
import { NumbersValidators } from '../../../../../validators/numbers.validators';
import { BeneficialOwnerComponent } from '../../../../components/common/beneficial-owner/beneficial-owner.component';
import CompanyNameAcnComponent from '../../../../components/common/company-name-acn/company-name-acn.component';
import { DatePickerComponent } from '../../../../components/common/date-picker/date-picker.component';
import { InputNumberComponent } from '../../../../components/common/input-number/input-number.component';
import { InputComponent } from '../../../../components/common/input/input.component';
import { RadioComponent } from '../../../../components/common/radio/radio.component';
import { SelectComponent } from '../../../../components/common/select/select.component';
import { ValidationErrorComponent } from '../../../../components/common/validation-error/validation-error.component';
import { YesNoControlComponent } from '../../../../components/common/yes-no-control-component/yes-no-control.component';
import { BaseStepperFormComponent } from '../../../stepper-form/base-stepper-component/base-stepper-form.component';
import {
  StepperFormDescriptionComponent
} from '../../../stepper-form/stepper-form-description/stepper-form-description.component';
import { StepperFormComponent } from '../../../stepper-form/stepper-form.component';
import {
  CompanyDividendStatement,
  DividendStatementStepsEnum,
  DividendType,
  PaymentType,
  paymentTypeOptions,
  taxRateForFrankingPurposes
} from './CompanyDividendStatement';
import { BeneficialOwnersGroupComponent } from './nbo-sings-group/beneficial-owners-group.component';
import { setControlDisabled } from "../../../../../functions/set-control-disabled";
import { SecurityType } from "../../../../../models/securityType";
import { Guid } from "../../../../helpers/guid.helper";
import { DividerComponent } from "../../../../components/common/divider/divider.component";
import { EntityChangeData } from "../../../../../models/entityChangeData";

export type DividendStatementShareSplitForm = FormGroup<{
  amountOfDistribution: FormControl<number | null>;
  percentageOfDistributionFranked: FormControl<number | null>;
}>;

export interface IShareDistributionEstimate {
  shareholder: string;
  numberOfShares: number;
  totalDividends: number;
  unfranked: number;
  franked: number;
  imputedCredit: number;
}

export const dividendStatementSteps: IStep<DividendStatementStepsEnum>[] = [
  { step: DividendStatementStepsEnum.FormDescription, label: 'Form Description' },
  { step: DividendStatementStepsEnum.DistributionDetails, label: 'Distribution Details' },
  { step: DividendStatementStepsEnum.DistributionAmount, label: 'Distribution Amount' },
  { step: DividendStatementStepsEnum.BeneficialOwnersDetails, label: 'Beneficial Owners Details' },
  { step: DividendStatementStepsEnum.Estimate, label: 'Estimate' }
];

@Component({
  selector: 'app-form-dividend-statement',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    AsPipe,
    StepperFormComponent,
    StepperFormDescriptionComponent,
    DatePickerComponent,
    SelectComponent,
    YesNoControlComponent,
    InputComponent,
    InputNumberComponent,
    ValidationErrorComponent,
    CompanyNameAcnComponent,
    BeneficialOwnerComponent,
    BeneficialOwnersGroupComponent,
    RadioComponent,
    DividerComponent,
  ],
  templateUrl: './form-dividend-statement.component.html',
  styleUrls: [
    './form-dividend-statement.component.scss',
    '../../../stepper-form/base-stepper-component/base-stepper-form.component.scss'
  ]
})
export class FormDividendStatementComponent extends BaseStepperFormComponent<DividendStatementStepsEnum, CompanyDividendStatement> implements OnInit {
  securityService = inject(SecurityService);

  readonly ShareholderRelationshipDetails = ShareholderRelationshipDetails;
  readonly dividendTypeOptions: SelectOption[] = enumToSelectOptions(DividendType);
  readonly paymentTypeOptions: SelectOption[] = paymentTypeOptions;
  readonly taxRateForFrankingPurposesOptions = taxRateForFrankingPurposes;

  override readonly StepsEnum = DividendStatementStepsEnum;
  override steps = dividendStatementSteps;

  holders: SecurityRegistryRecord[] = [];
  shareTypes: SecurityType[] = [];
  minDate!: NgbDateStruct;
  maxDate!: NgbDateStruct;

  override currentStepIndex = 0;
  override currentStep = this.steps[this.currentStepIndex].step;
  override stepperForm = new FormGroup({
    [DividendStatementStepsEnum.FormDescription]: new FormGroup({}),
    [DividendStatementStepsEnum.DistributionDetails]: new FormGroup({
      declarationDate: new FormControl<Date | null>(DatepickerHelper.getToday()),
      paymentDate: new FormControl<Date | null>(DatepickerHelper.getToday()),
      paymentType: new FormControl<PaymentType | null>(PaymentType.Cash, [Validators.required]),
      dividendType: new FormControl<DividendType | null>(DividendType.Final, [Validators.required]),
    }),
    [DividendStatementStepsEnum.DistributionAmount]: new FormGroup({
      equalDividendsForAllShareClasses: new FormControl<boolean | null>(null, [Validators.required]),

      amountOfDistribution: new FormControl<number | null>(null, [Validators.required, Validators.min(0)]),
      percentageOfDividendsFranked: new FormControl<number | null>(null, [Validators.required, NumbersValidators.percentRangeValidator]),

      companyTaxApplied: new FormControl<number | null>(null, [Validators.required]),

      dividendsPerShareClassIfNotEqual: new FormArray<DividendStatementShareSplitForm>([])
    }),
    [DividendStatementStepsEnum.BeneficialOwnersDetails]: new FormGroup({}),
    [DividendStatementStepsEnum.Estimate]: new FormGroup({}),
  });

  stepsSignal: WritableSignal<IStep<DividendStatementStepsEnum>[]> = signal(dividendStatementSteps);

  constructor() {
    super();
    this.redirectAfterSubmit = true;
  }

  ngOnInit() {
    this.minDate = DatepickerHelper.getDateOfEstablishmentMinDate(this.companyChangeData.dateOfEstablishment);
    this.maxDate = DatepickerHelper.getNextNYearsStruct(10);

    this.updateFormSteps();
    this.clearFormArray(this.distributionAmountForm.controls.dividendsPerShareClassIfNotEqual);
    this.listenDividendsSplitEquallyChanges();
    this.loadNboHolders().subscribe(() => this.setupChange());
  }

  updateSignValue(index: number, newBOName: string): void {
    this.holders[index].holders.forEach(holder => holder.details.beneficialOwner = newBOName);
    this.stepperForm.controls[DividendStatementStepsEnum.BeneficialOwnersDetails].updateValueAndValidity();
  }

  override afterSubmit(changes: EntityChangeData[]) {
    this.setupChange(changes[0] as CompanyDividendStatement);
  }

  override setupChange(change: CompanyDividendStatement = this.formModel): void {
    if (!this.isEdit && change === this.formModel)
      return;

    this.distributionDetailsForm.patchValue({
      ...this.formModel,
      declarationDate: this.formModel?.changeDate,
    });

    this.distributionAmountForm.patchValue({
      ...this.formModel,
      dividendsPerShareClassIfNotEqual: []
    });

    this.clearFormArray(this.distributionAmountForm.controls.dividendsPerShareClassIfNotEqual);
    Object.keys(this.formModel.dividendsPerShareClassIfNotEqual).forEach((key) => {
      this.distributionAmountForm.controls.dividendsPerShareClassIfNotEqual.push(new FormGroup({
        amountOfDistribution: new FormControl<number | null>(this.formModel.dividendsPerShareClassIfNotEqual[key], [Validators.required, Validators.min(0)]),
        percentageOfDistributionFranked: new FormControl<number | null>(this.formModel.percentageOfDividendsFrankedIfNotEqual[key], [Validators.required, Validators.min(0)]),
      }));
    });
  }

  override buildDocument(): Document | null {
    const dividendsPerShareClassIfNotEqual = {};
    const percentageOfDividendsFrankedIfNotEqual = {};

    if (this.distributionAmountForm.value.equalDividendsForAllShareClasses === false && this.shareTypes.length) {
      this.distributionAmountForm.value.dividendsPerShareClassIfNotEqual!.forEach((sharesPerClass, index) => {
        dividendsPerShareClassIfNotEqual[this.shareTypes[index].class] = sharesPerClass.amountOfDistribution;
        percentageOfDividendsFrankedIfNotEqual[this.shareTypes[index].class] = sharesPerClass.percentageOfDistributionFranked;
      });
    }

    const companyDividendStatementChange = new CompanyDividendStatement({
      ...this.distributionDetailsForm.value as Partial<CompanyDividendStatement>,
      ...this.distributionAmountForm.value as Partial<CompanyDividendStatement>,
      dividendsPerShareClassIfNotEqual,
      percentageOfDividendsFrankedIfNotEqual,
      changeDate: this.distributionDetailsForm.controls.declarationDate.value!
    });

    try {
      return new Document({
        changes: [companyDividendStatementChange],
        entityId: this.companyChangeData?.entityId,
        type: CompanyDividendStatement.$type,
        documentId: this.companyChangeData?.documentId,
      });
    } catch (error) {
      this.toastr.error('Failed to create Document.', 'Error');
      return null;
    }
  }

  private listenDividendsSplitEquallyChanges(): void {
    this.distributionAmountForm.controls.equalDividendsForAllShareClasses.valueChanges
      .pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
      .subscribe((dividendsSplitEqually) => {
        setControlDisabled(this.distributionAmountForm.controls.dividendsPerShareClassIfNotEqual, dividendsSplitEqually === true);
        setControlDisabled(this.distributionAmountForm.controls.amountOfDistribution, dividendsSplitEqually !== true);
        setControlDisabled(this.distributionAmountForm.controls.percentageOfDividendsFranked, dividendsSplitEqually !== true);
      });
  }

  private updateFormSteps(): void {
    const steps = dividendStatementSteps.filter((step) => {

      if (step.step === DividendStatementStepsEnum.BeneficialOwnersDetails) {
        return this.holders.length;
      }

      return true;
    });

    this.stepsSignal.set(steps);
  }

  private loadNboHolders(): Observable<SecurityRegistryRecord[]> {
    this.isLoading = true;
    return this.securityService.getSecurityRegistry(this.companyChangeData.entityId)
      .pipe(
        finalize(() => this.isLoading = false),
        tap((securityHolderRecords) => {
        this.holders = securityHolderRecords.filter(record => !record.isBeneficialOwner);

        const allSecurityTypes = securityHolderRecords.flatMap(shareholder => shareholder.holdings.map(holding => holding.securityType));
        this.shareTypes = [...new Map<string, SecurityType>(allSecurityTypes.map(type => [type.class, type])).values()]
          .filter(type => type.entityId && type.entityId !== Guid.EmptyGuid);
        this.updateFormSteps();

        // setup controls for shares of the company shareholders
        this.shareTypes.forEach(() => this.distributionAmountForm.controls.dividendsPerShareClassIfNotEqual.push(new FormGroup({
          amountOfDistribution: new FormControl<number | null>(null, [Validators.required, Validators.min(0)]),
          percentageOfDistributionFranked: new FormControl<number | null>(null, [Validators.required, NumbersValidators.percentRangeValidator]),
        })));
      }));
  }

  private clearFormArray(formArray: FormArray) {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }

  get distributionDetailsForm() {
    return this.stepperForm.controls[DividendStatementStepsEnum.DistributionDetails];
  }

  get distributionAmountForm() {
    return this.stepperForm.controls[DividendStatementStepsEnum.DistributionAmount];
  }
}
