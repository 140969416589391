import { AnnualStatementFeeReminderTextMessagePayload } from './payload/AnnualStatementFeeReminderTextMessagePayload';
import { TextMessagePayloadBase } from './payload/TextMessagePayloadBase';
import {
  AnnualStatementSigningReminderTextMessagePayload
} from './payload/AnnualStatementSigningReminderTextMessagePayload';
import { AsicDocumentSigningReminderTextMessagePayload } from './payload/AsicDocumentSigningReminderTextMessagePayload';
import { CompanyDebtReminderTextMessagePayload } from './payload/CompanyDebtReminderTextMessagePayload';
import { TextMessageTemplate } from './textMessageTemplate';
import { TextMessageSendRequest } from './TextMessageSendRequest';
import { fullMockedData } from '../email-templates/emailTemplatesHelper';

export interface TemplateCodePreviewItem {
  key: string,
  description: string
}

export type TemplateCodePreview = TemplateCodePreviewItem[];

export class TextMessagesHelper {
  static TemplateHeadersRecord: Record<string, string> = {
    'as-fee-reminder': 'Annual Statement Fee Reminder',
    'as-signing-reminder': 'Annual Statement Signing Reminder',
    'asic-document-siging-reminder': 'ASIC Document Signing Reminder',
    'company-debt-reminder': 'Company Debt Reminder Reminder',
  };

  static TextMessageCodeToType: Record<string, string> = {
    'as-fee-reminder': 'AnnualStatementFeeReminderTextMessagePayload',
    'as-signing-reminder': 'AnnualStatementSigningReminderTextMessagePayload',
    'asic-document-siging-reminder': 'AsicDocumentSigningReminderTextMessagePayload',
    'company-debt-reminder': 'CompanyDebtReminderTextMessagePayload',
  };

  static TextMessageTemplateTypeToModel: Record<string, (data: Partial<TextMessagePayloadBase>) => TextMessagePayloadBase> = {
    [AnnualStatementFeeReminderTextMessagePayload.$type]: (data) => new AnnualStatementFeeReminderTextMessagePayload(data),
    [AnnualStatementSigningReminderTextMessagePayload.$type]: (data) => new AnnualStatementSigningReminderTextMessagePayload(data),
    [AsicDocumentSigningReminderTextMessagePayload.$type]: (data) => new AsicDocumentSigningReminderTextMessagePayload(data),
    [CompanyDebtReminderTextMessagePayload.$type]: (data) => new CompanyDebtReminderTextMessagePayload(data),
  };

  static buildTextMessageSendRequest(template: TextMessageTemplate, to: string): TextMessageSendRequest {
    const payloadModel = TextMessagesHelper.buildTextMessageTemplatePayload(template.code);
    return new TextMessageSendRequest({ template, payloadModel, to });
  }

  static buildTextMessageTemplatePayload(code: string): TextMessagePayloadBase {
    const payloadModel = TextMessagesHelper.TextMessageTemplateTypeToModel[TextMessagesHelper.TextMessageCodeToType[code]](fullMockedData);
    if (!payloadModel) throw new Error(`No payload model for code '${ code }'`);
    return payloadModel;
  }

  static buildPreview(payload: TextMessagePayloadBase): TemplateCodePreview {
    try {
      const templateCodePreview: TemplateCodePreview = [];
      Object.keys(payload).forEach((key) => {
        if (this.smsTemplateCodesRecord[key])
          templateCodePreview.push(this.smsTemplateCodesRecord[key]);
      });

      return templateCodePreview;
    } catch (e) {
      return [];
    }
  }

  static smsTemplateCodesRecord: Record<string, TemplateCodePreviewItem> = {
    organisationName: { key: '{{OrganisationName}}', description: 'Account name' },
    organisationEmail: { key: '{{OrganisationEmail}}', description: 'Account email address' },
    organisationPhone: { key: '{{OrganisationPhone}}', description: 'Phone number' },
    tenDaysBeforeLodgementDeadline: { key: '{{TenDaysBeforeLodgementDeadline}}', description: 'A date that is ten days before the actual lodgement deadline' },
    tenDaysBeforeLodgementDeadlineShortFormat: { key: '{{TenDaysBeforeLodgementDeadlineShortFormat}}', description: 'A date that is ten days before the actual lodgement deadline' },
    documentName: { key: '{{DocumentName}}', description: 'Type of ASIC Form that was sent to the director(s)' },
    lodgementDeadline: { key: '{{LodgementDeadline}}', description: 'Lodgement deadline of the ASIC form' },
    partner: { key: '{{Partner}}', description: 'Name of the Partner assigned to the company' },
    asicContact: { key: '{{AsicContact}}', description: 'Name of the assigned ASIC contact in the Agent Details section' },
    accountManager: { key: '{{AccountManager}}', description: 'Name of the Account Manager' },
    billingContactFirstName: { key: '{{BillingContactFirstName}}', description: 'First name of the billing contact assigned in the company profile' },
    billingContactFullName: { key: '{{BillingContactFullName}}', description: 'Full name of the billing contact assigned in the company profile' },
    payUrl: { key: '{{PayUrl}}', description: 'The “Pay Now” button should route end users to the AusPost Billpay page' },
    companyName: { key: '{{CompanyName}}', description: 'Name of company' },
    companyACN: { key: '{{CompanyACN}}', description: 'ACN' },
    annualReviewFee: { key: '{{AnnualReviewFee}}', description: 'Annual review fee' },
    annualReviewFeeDeadline: { key: '{{AnnualReviewFeeDeadline}}', description: 'Payment deadline for the annual review fee' },
    annualReviewFeeDeadlineShortFormat: { key: '{{AnnualReviewFeeDeadlineShortFormat}}', description: 'Annual review fee due date' },
    debt: { key: '{{Debt}}', description: 'Debt in the company profile page' },
    dueDate: { key: '{{dueDate}}', description: 'Due date of the ASIC fee' },
    dueDateShortFormat: { key: '{{DueDateShortFormat}}', description: 'Due date of the ASIC fee' },
    directorFirstName: { key: '{{DirectorFirstName}}', description: 'First name of the director who needs to sign the docs' },
    directorFullName: { key: '{{DirectorFullName}}', description: 'Full name of the director who needs to sign the docs' },
    documentType: { key: '{{DocumentType}}', description: 'Type of document' },
    lodgementDeadlineShortFormat: { key: '{{LodgementDeadlineShortFormat}}', description: 'Lodgement deadline of the ASIC form' }
  };
}
