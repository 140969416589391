<section class="step-section" [hidden]="hidden">
  <h1 class="h1-heading">Form Description</h1>

  @if (formKey && description.length) {
    @for (line of description; track line.key) {

      <div class="form-group">
        <p class="sub-heading">
          {{ line.key }}
        </p>
        <p class="description" [innerHTML]="line.value">
        </p>
      </div>
    }
  } @else {
    <div class="form-group">Description is empty</div>
  }
</section>
