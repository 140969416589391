<div
  id="sidebar-container"
  [ngClass]="{'expand-menu': !collapsedMenu(), 'collapse-menu': collapsedMenu()}"
>
  <div class="logo-wrapper">
    <i class="icon ic-bolt-logo"></i>
  </div>

  <div class="menu-container">
    <div class="menu-wrapper">
      <div>
        @for(item of menuItems(); track $index) {
          <app-nav-menu-link
            [isTopLevelElement]="true"
            [navMenuitemData]="item"
            [collapsedMenu]="collapsedMenu()"
          ></app-nav-menu-link>
        }
      </div>

      <div class="menu-heading">{{collapsedMenu() ? '' : 'Dev'}}</div>

      @if(!environment.production) {
        <div>
          @for(item of devMenuItems; track $index) {
            <app-nav-menu-link
              [isTopLevelElement]="true"
              [navMenuitemData]="item"
              [collapsedMenu]="collapsedMenu()"
            ></app-nav-menu-link>
          }
        </div>
      }
    </div>

    <div class="toggle-btn-container">
      <div class="menu-item" (click)="toggleMenu()">
        <div class="menu-icon">
          <i class="icon ic-md" [ngClass]="{'ic-collapse': !collapsedMenu(), 'ic-expand': collapsedMenu()}"></i>
        </div>
      </div>
    </div>
  </div>
</div>
