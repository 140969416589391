import { Component, Input } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors
} from "@angular/forms";
import { CorporateHolderModel, SecurityRegistryRecord } from "../../../../../../models/securityRegistryRecord";
import { MultipleInputComponent } from "../../../../../components/common/multiple-input/multiple-input.component";
import { AuthSignatoryComponent } from "../../../../../components/common/auth-signatory/auth-signatory.component";
import { CustomFormValidators } from "../../../../../../custom-form-validators/custom-form-validators";
import {
  ReusableFormGroupComponent
} from "../../../../../components/reusable-form-groups/reusable-form-group/reusable-form-group.component";

export interface IAuthorisedSignatoriesFormGroupControls {
  corporateHoldersAuthorisedSignatories: FormArray<FormControl<string[]>>;
}
export type IAuthorisedSignatoriesFormGroup = FormGroup<IAuthorisedSignatoriesFormGroupControls>;


@Component({
  selector: 'app-authorised-signatories-step',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MultipleInputComponent,
    AuthSignatoryComponent
  ],
  templateUrl: './authorised-signatories-step.component.html',
  styleUrl: './authorised-signatories-step.component.scss'
})
export class AuthorisedSignatoriesStepComponent extends ReusableFormGroupComponent<FormGroup> {
  @Input() title = '';

  @Input({
    alias: 'securityRegistryRecords',
    required: true
  }) set securityRegistryRecordSetter(value: SecurityRegistryRecord[]) {
    this.securityRegistryRecords = value.filter((record) => record.holders.length === 1 && record.holders[0].$type === CorporateHolderModel.$type);
    this.corporateHolders = this.securityRegistryRecords
      .flatMap((record) => record.holders) as CorporateHolderModel[];

    this.setupAuthorisedSignatoriesForm(this.corporateHolders);
  }

  readonly authSignatoriesCustomErrors = {
    startsWithDigit: 'The name must start with a letter.',
    oneLetterPartName: 'Initials aren\'t allowed.',
    atLeastOneSign: 'The authorised signatories must contain at least one sign.',
    restrictEmptySigns: 'The authorised signatories must not contain empty signs.',
  };

  securityRegistryRecords: SecurityRegistryRecord[] = [];
  corporateHolders: CorporateHolderModel[] = [];
  override form: IAuthorisedSignatoriesFormGroup = AuthorisedSignatoriesStepComponent.defineForm();

  updateValidation(): void {
    this.form.updateValueAndValidity();
    this.form.controls.corporateHoldersAuthorisedSignatories.updateValueAndValidity();
  }

  private setupAuthorisedSignatoriesForm(corporateHolders: CorporateHolderModel[]): void {
    const signsControls = corporateHolders.map((holder) => this.createAuthorisedSignatoryForm(holder.details.authSignatories ?? []));
    this.form.setControl('corporateHoldersAuthorisedSignatories', new FormArray<FormControl<string[]>>(signsControls));
  }

  private createAuthorisedSignatoryForm(value: string[]): FormControl<string[]> {
    return this.setupAuthorisedSignatoryFormValidator(new FormControl<string[]>(value, { nonNullable: true }));
  }

  private setupAuthorisedSignatoryFormValidator(control: FormControl<string[]>): FormControl<string[]> {
    const atLeastOneSignValidator = (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as string[] ?? [];
      return value.length > 0 ? null : { atLeastOneSign: true };
    };

    const restrictEmptySigns = (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as string[] ?? [];
      const includesEmptySigns = value.every((sign) => sign.trim());
      return includesEmptySigns ? null : { restrictEmptySigns: true };
    };

    control.setValidators([
      CustomFormValidators.directorNameValidator,
      CustomFormValidators.directorOneLetterNameValidator,
      atLeastOneSignValidator,
      restrictEmptySigns,
    ]);

    return control;
  }

  get control(): FormGroup | null {
    return this.form.get('corporateHoldersAuthorisedSignatories') as FormGroup ?? null;
  }

  static override defineForm(): IAuthorisedSignatoriesFormGroup {
    return new FormGroup(AuthorisedSignatoriesStepComponent.defineFormControls());
  }

  static override defineFormControls(): IAuthorisedSignatoriesFormGroupControls {
    return { corporateHoldersAuthorisedSignatories: new FormArray<FormControl<string[]>>([]) };
  }
}
