import { EmailPayloadBase } from "../emailPayloadBase";

export class UserInviteEmailPayload extends EmailPayloadBase {
  static override $type = 'UserInviteEmailPayload';

  firstName: string;
  fullName: string;
  username: string;
  loginUrl: string;

  constructor(data: Partial<UserInviteEmailPayload> = {}) {
    super(data);
    this.firstName = data.firstName ?? '';
    this.fullName = data.fullName ?? '';
    this.username = data.username ?? '';
    this.loginUrl = data.loginUrl ?? '';
  }
}
