<div>
  <i
    class="icon mr-2"
    [ngClass]="{
      'ic-companies': shareHolder.$type === 'CorporateHolderModel',
      'ic-my-account': shareHolder.$type === 'IndividualHolderModel'
      }"
  ></i>
  <span>{{ shareHolder.name }}</span>
</div>
