import {
  AfterViewInit,
  Component, DestroyRef, EventEmitter,
  inject,
  Input, Output,
  ViewChild
} from '@angular/core';
import { GridApi } from "ag-grid-community";
import { GridColumnsFilterComponent } from "../grid-columns-filter/grid-columns-filter.component";
import { ButtonComponent } from "../common/button/button.component";
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle, NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { SearchInputComponent } from "../common/search-input/search-input.component";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { DividerComponent } from "../common/divider/divider.component";
import { AsyncPipe, DatePipe, NgClass } from "@angular/common";
import { CheckboxComponent } from "../common/checkbox/checkbox.component";
import {
  ColDefCommunityExtended, DateRange,
  FilterOption,
  GridFilterModel, GroupByOption, NumberRange
} from "../../../models/gridFilter";
import { NgPipesModule } from "ngx-pipes";
import { DatePickerComponent } from "../common/date-picker/date-picker.component";
import { DateRangePickerComponent } from "../common/date-range-picker/date-range-picker.component";
import { CellDataType } from "../../../models/enums/agGridEnums";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { NumberRangeInputComponent } from "../common/number-range-input/number-range-input.component";
import { SearchFilterListComponent } from "./search-filter-list/search-filter-list.component";
import { GridService } from "../../../services/grid.service";
import { GroupByBtnComponent } from "./group-by-btn/group-by-btn.component";

@Component({
  selector: 'app-grid-column-search-filter',
  standalone: true,
  imports: [
    GridColumnsFilterComponent,
    ButtonComponent,
    NgbDropdown,
    NgbDropdownMenu,
    NgbDropdownToggle,
    SearchInputComponent,
    ReactiveFormsModule,
    DividerComponent,
    AsyncPipe,
    CheckboxComponent,
    NgPipesModule,
    DatePickerComponent,
    DateRangePickerComponent,
    DatePipe,
    NgClass,
    NgbTooltip,
    NgbDropdownItem,
    NumberRangeInputComponent,
    SearchFilterListComponent,
    GroupByBtnComponent,
  ],
  templateUrl: './grid-column-search-filter.component.html',
  styleUrl: './grid-column-search-filter.component.scss'
})
export class GridColumnSearchFilterComponent implements AfterViewInit {
  @ViewChild('dropdown') dropdown!: NgbDropdown;
  @ViewChild('startBtnDropdown') startBtnDropdown: NgbDropdown | undefined;

  @Input() colDefs: ColDefCommunityExtended[] = [];
  @Input() rowData: unknown[] = [];
  @Input() gridApi: GridApi | undefined;
  @Input() groupByOptions: GroupByOption[] = [];
  @Input() loading = false;
  @Output() selectGroupByOption = new EventEmitter<number>();
  @Output() clearGroupByOption = new EventEmitter<boolean>();
  @Output() applyGroupFilter = new EventEmitter<boolean>()

  private gridService = inject(GridService);
  #destroyRef: DestroyRef = inject(DestroyRef);

  public form!: FormGroup;
  public CellDataType = CellDataType;
  public selectedColIndex = -1;
  public filterStateIndex: number | undefined;
  public colDefsCopy: ColDefCommunityExtended[] = [];
  public isFilterApplied = false;

  ngAfterViewInit(): void {
    this.startBtnDropdown?.openChange
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(open => {
        if (this.selectedColIndex > -1) {
          const isActiveOption = !!this.colDefs[this.selectedColIndex]?.filterParams?.filterOptions
            .filter(option => option.active)?.length;

          if(!open && isActiveOption) {
            this.addFilterToTag(this.selectedColIndex);
          } else {
            this.resetSelectedColumn();
          }
        }
      });
  }

  get isSomeFilterTag(): boolean {
    if (this.filterStateIndex && this.filterStateIndex > -1 && this.startBtnDropdown && !this.startBtnDropdown.isOpen()) {
      this.colDefs[this.filterStateIndex].filterTagShowed = true;
    }
    return this.colDefs.some(col => col.filterTagShowed);
  }

  get numberOfNotShowedTags(): number {
    return this.colDefs.filter(col => {
      return !col.filterTagShowed && col?.headerName
        && (col?.filter === 'agSetColumnFilter' || col?.filter === 'agDateColumnFilter');
    }).length;
  }

  get isDateColumn(): boolean {
    if (!this.isFilterApplied) {
      this.resetDropDownStatus();
    }
    return this.selectedColIndex > -1 && this.colDefs[this.selectedColIndex].cellDataType === CellDataType.DATE_STRING;
  }

  get isSomeFilterOptions(): boolean {
    return this.colDefs.some(c => c?.filterParams?.filterOptions?.length);
  }

  isActiveOption(filterOptions: FilterOption[] | undefined): boolean {
    return (filterOptions?.filter(option => option.active)?.length ?? 0) > 1;
  }

  isSomeActiveFilterOptions(index: number): boolean {
    return this.colDefs[index].filterParams.filterOptions.some(option => option.active);
  }

  onSelectColumn(index: number): void {
    this.selectedColIndex = index;
  }

  resetSelectedColumn(): void {
    this.selectedColIndex = -1;
    this.isFilterApplied = true;
  }

  closeFilterTag(index: number): void {
    this.filterStateIndex = -1;
    this.selectedColIndex = -1;
    this.colDefs[index].filterTagShowed = false;
    this.colDefs[index].filterParams?.filterOptions?.forEach(option => option.active = false);

    const filterModel: GridFilterModel = this.getFilterModel();
    this.gridService.setGridFilterModelState(filterModel);

    if(this.groupByOptions?.some(o => o.active)) {
      this.applyGroupFilter.emit(true);
    } else {
      this.gridApi?.setFilterModel(filterModel);
    }
  }

  onSearchByOption(index: number, searchText: string): void {
    if (!this.colDefsCopy.length) {
      this.colDefsCopy = JSON.parse(JSON.stringify(this.colDefs)) as ColDefCommunityExtended[];
    }

    if (this.colDefs[index]?.filterParams?.filterOptions) {
      const originalOptions = this.colDefsCopy[index]?.filterParams?.filterOptions;
      this.colDefs[index].filterParams.filterOptions = [...originalOptions];
    }

    let filterOptions = this.colDefs[index]?.filterParams.filterOptions;

    if (filterOptions?.length) {
      filterOptions = filterOptions.filter((item) => (item.label.toString().toLowerCase().includes(searchText.toLowerCase())));
      this.colDefs[index].filterParams.filterOptions = filterOptions;
    }
  }

  onFilterOptionChange(index: number, { indexOption, active }: { indexOption: number, active: boolean }): void {
    this.filterStateIndex = index;
    this.colDefs[index].filterParams.filterOptions[indexOption].active = active;
    const filterOptions = this.colDefs[index].filterParams.filterOptions.filter(option => option.active);
    const someTagShowed = this.colDefs.filter(c => c.filterTagShowed)?.length;
    if (someTagShowed) {
      this.colDefs[index].filterTagShowed = !!filterOptions?.length;
    }

    if(this.colDefs.every(c => !c.filterTagShowed) && !filterOptions.length) {
      this.closeFilterTag(index);
    } else {
      const filterModel: GridFilterModel = this.getFilterModel();
      this.gridService.setGridFilterModelState(filterModel);

      if(this.groupByOptions?.some(o => o.active)) {
        this.applyGroupFilter.emit(true);
      } else {
        this.gridApi?.setFilterModel(filterModel);
      }
    }
  }

  showTagInfo(filterOptions: FilterOption[] | undefined): string | number {
    const activeFilterOptions = filterOptions?.filter(option => option?.active) ?? [];
    return activeFilterOptions.length > 1 ? activeFilterOptions.length : activeFilterOptions?.[0]?.label ?? '';
  }

  addFilterToTag(index: number): void {
    this.colDefs[index].filterTagShowed = true;
  }

  onDateRangeSelected(index: number, { from, to }: DateRange): void {
    this.colDefs[index].filterParams.dateRangeSelected = { from, to };

    this.colDefs[index].filterParams.filterOptions.forEach(option => {
      option.active = (from && to) ? (new Date(option.value) >= from && new Date(option.value) <= to) : false;
    });

    this.colDefs[index].filterTagShowed = !!from && !!to;

    const filterModel: GridFilterModel = this.getFilterModel();
    const field = this.colDefs[index].field;
    if (!filterModel[field]?.values?.length) {
      filterModel[field] = { filterType: 'set', values: from ? [from.toISOString().split('T')[0]] : null };
    }

    this.gridService.setGridFilterModelState(filterModel);

    if(this.groupByOptions?.some(o => o.active)) {
      this.applyGroupFilter.emit(true);
    } else {
      this.gridApi?.setFilterModel(filterModel);
    }
  }

  onNumberRangeSelected(index: number, { min, max }: NumberRange): void {
    this.colDefs[index].filterParams.numberRangeSelected = { min, max };
    this.colDefs[index].filterTagShowed = true;
    this.colDefs[index].filterParams.filterOptions.forEach(option => {
      option.active = (+option.value) >= min && (+option.value) <= max;
    });

    const filterModel: GridFilterModel = this.getFilterModel();
    this.gridService.setGridFilterModelState(filterModel);

    if(this.groupByOptions?.some(o => o.active)) {
      this.applyGroupFilter.emit(true);
    } else {
      this.gridApi?.setFilterModel(filterModel);
    }
  }

  onFilterSearch(index: number, searchText: string): void {
    this.colDefs[index].filterParams.searchText = searchText;

    if (searchText) {
      this.colDefs[index]?.filterParams?.filterOptions.forEach(option => {
        option.active = option.label.toLowerCase().includes(searchText.toLowerCase());
      });

      this.colDefs[index].filterTagShowed = true;
    } else {
      this.colDefs[index]?.filterParams?.filterOptions.forEach(option => {
        option.active = false;
      });

      this.colDefs[index].filterTagShowed = false;
    }

    const filterModel: GridFilterModel = this.getFilterModel();
    const field = this.colDefs[index].field;
    if (!filterModel[field]?.values?.length) {
      filterModel[field] = { filterType: 'set', values: searchText ? [searchText] : null };
    }

    this.gridService.setGridFilterModelState(filterModel);

    if(this.groupByOptions?.some(o => o.active)) {
      this.applyGroupFilter.emit(true);
    } else {
      this.gridApi?.setFilterModel(filterModel);
    }
  }

  getFilterModel(): GridFilterModel {
    const filterModel: GridFilterModel = {};

    this.colDefs.forEach((c: ColDefCommunityExtended) => {
      const field = c.field ?? '';
      const options: FilterOption[] = c.filterParams?.filterOptions;
      const activeOptions = options?.filter(option => option?.active);
      const modelValues = activeOptions?.map(option => option.value);

      if (activeOptions?.length) {
        filterModel[field] = { filterType: 'set', values: modelValues };
      }
    });

    return filterModel;
  }

  onChangeGroupByOption(index: number): void {
    this.selectGroupByOption.emit(index);
  }

  clearGroupBy(): void {
    this.clearGroupByOption.emit();
  }

  resetDropDownStatus(): void {
    if (this.startBtnDropdown && !this.startBtnDropdown.isOpen()) {
      this.selectedColIndex = -1;
    }
  }

  onClickFilter(): void {
    this.isFilterApplied = false;
  }
}
