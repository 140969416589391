<ul class="signs">
  @for (nbo of holders; track nbo; let index = $index) {
    <li class="sign-item">
      <p class="sign-owner">
        {{ nbo.name || 'N/A' }}
      </p>

      <app-inline-edit-input
        [isLarge]="true"
        [ngClass]="{'text-danger' : !nbo.holders[0].details.beneficialOwner && !nbo.holders[0].details.isBeneficialOwner }"
        [value]="nbo.holders[0].details.beneficialOwner"
        [onSave]="updateBeneficialOwners(nbo.holders, index)"
      >
        <input
          type="text"
          class="input"
          [disabled]="true"
          [value]="nbo.holders[0].details.beneficialOwner"
        >
      </app-inline-edit-input>
    </li>
  }
</ul>

<div class="form-group">
  <app-validation-error
    [control]="formGroup"
    [customErrors]="customErrors"
  ></app-validation-error>
</div>
