import { Component, OnInit, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { startWith } from 'rxjs';
import { DatepickerHelper } from '../../../../../custom-form-validators/date-picker-form-validators';
import { setControlDisabled } from '../../../../../functions/set-control-disabled';
import { Document } from '../../../../../models/document';
import { EntityChangeData } from '../../../../../models/entityChangeData';
import { SelectOption } from '../../../../../models/selectOptions';
import { companyNameValidator } from '../../../../../validators/compny-name.validator';
import { PaymentMethod, paymentMethodOptions } from '../../../company-name-change/CompanyChangeName';
import { BaseStepperFormComponent } from '../../../stepper-form/base-stepper-component/base-stepper-form.component';
import { Form410BModel, Form410BStepsEnum } from './form410B.model';
import { IStep } from "../../../../../models/step";
import { InputComponent } from "../../../../components/common/input/input.component";
import {
  StepperFormDescriptionComponent
} from "../../../stepper-form/stepper-form-description/stepper-form-description.component";
import { StepperFormComponent } from "../../../stepper-form/stepper-form.component";
import { YesNoControlComponent } from "../../../../components/common/yes-no-control-component/yes-no-control.component";
import {
  AbnMaskedInputComponent
} from "../../../../components/common/masked-input/specific-masked-inputs/abn-masked-input/abn-masked-input.component";
import { RadioComponent } from "../../../../components/common/radio/radio.component";
import { TextareaComponent } from "../../../../components/common/textarea/textarea.component";
import { abnDeclarationMessage } from "../../../../constants/abn-declaration-message.constant";
import { CheckboxComponent } from "../../../../components/common/checkbox/checkbox.component";
import { ValidationErrorComponent } from "../../../../components/common/validation-error/validation-error.component";

@Component({
  selector: 'app-form410b',
  standalone: true,
  templateUrl: './form410b.component.html',
  imports: [
    ReactiveFormsModule,
    InputComponent,
    RadioComponent,
    YesNoControlComponent,
    TextareaComponent,
    AbnMaskedInputComponent,
    StepperFormDescriptionComponent,
    StepperFormComponent,
    CheckboxComponent,
    ValidationErrorComponent,
  ],
  styleUrl: '../../../stepper-form/base-stepper-component/base-stepper-form.component.scss'
})
export class Form410BComponent extends BaseStepperFormComponent<Form410BStepsEnum, Form410BModel> implements OnInit {
  override readonly StepsEnum = Form410BStepsEnum;
  readonly paymentMethodOptions: SelectOption[] = paymentMethodOptions;
  readonly abnDeclarationMessage = abnDeclarationMessage;

  stepsSignal: WritableSignal<IStep<Form410BStepsEnum>[]> = signal(this.steps);
  override stepperForm = new FormGroup({
    [Form410BStepsEnum.FormDetails]: new FormGroup({}),
    [Form410BStepsEnum.CompanyNameDetails]: new FormGroup({
      proposedCompanyName: new FormControl<string>('', [Validators.required, Validators.minLength(7), companyNameValidator()]),
      isProposedNameIdenticalToRegisteredBusinessName: new FormControl<boolean>(false, [Validators.required]),
      abnConfirmation: new FormControl<boolean | null>(false, [Validators.requiredTrue]),
      businessNameHolderAbn: new FormControl<string>(''),
      paymentMethod: new FormControl<PaymentMethod>(PaymentMethod.INV, [Validators.required]),
    }),
    [Form410BStepsEnum.ManualProcessing]: new FormGroup({
      manualProcessing: new FormControl<boolean>(false, [Validators.required]),
      manualProcessingReason: new FormControl<string>('', [Validators.required])
    }),
  });

  constructor() {
    super();
    this.setupSteps(Form410BStepsEnum);
    this.redirectAfterSubmit = true;
    this.stepsSignal.set(this.steps);
  }

  ngOnInit(): void {
    this.listenIsBusinessNameChanges();
    this.listenManualProcessingChanges();
    this.setupChange();
  }

  override afterSubmit(changes: EntityChangeData[]) {
    this.setupChange(changes[0] as Form410BModel);
  }

  override setupChange(change: Form410BModel = this.formModel): void {
    this.stepperForm.patchValue(this.stepperForm.value);
    this.companyNameDetailsForm.controls.abnConfirmation.setValue(this.isEdit);

    if (!this.isEdit) {
      return;
    }

    this.stepperForm.patchValue({
      [Form410BStepsEnum.CompanyNameDetails]: {
        ...change,
      },
      [Form410BStepsEnum.ManualProcessing]: {
        ...change,
      }
    });
  }

  override buildDocument(): Document | null {
    const companyNameDetailsFormValue = this.stepperForm.value[Form410BStepsEnum.CompanyNameDetails];
    const manualProcessingFormValue = this.stepperForm.value[Form410BStepsEnum.ManualProcessing];

    const changes = new Form410BModel({
      ...companyNameDetailsFormValue as Partial<Form410BModel>,
      ...manualProcessingFormValue as Partial<Form410BModel>,
      changeDate: DatepickerHelper.buildDateString(DatepickerHelper.getToday()),
    });

    try {
      return new Document({
        changes: [changes],
        entityId: this.companyChangeData.entityId,
        type: 'c:410b',
        documentId: this.companyChangeData?.documentId,
      });
    } catch (error) {
      console.warn(error);
      this.toastr.error('Failed to create Document.', 'Error');
      return null;
    }
  }

  private updateSteps(showManualProcessingStep: boolean): void {
    if (showManualProcessingStep) {
      this.stepsSignal.set(this.steps);
    } else {
      this.stepsSignal.set(this.steps.filter(({ step }) => step !== Form410BStepsEnum.ManualProcessing));
    }
  }

  private listenIsBusinessNameChanges(): void {
    this.companyNameDetailsForm.controls.isProposedNameIdenticalToRegisteredBusinessName.valueChanges
      .pipe(startWith(false), takeUntilDestroyed(this.destroyRef))
      .subscribe((isProposedNameIdenticalToRegisteredBusinessName) => {
        setControlDisabled(this.companyNameDetailsForm.controls.businessNameHolderAbn, !isProposedNameIdenticalToRegisteredBusinessName);
        setControlDisabled(this.companyNameDetailsForm.controls.abnConfirmation, !isProposedNameIdenticalToRegisteredBusinessName);
        this.updateSteps(!!isProposedNameIdenticalToRegisteredBusinessName);
      });
  }

  private listenManualProcessingChanges(): void {
    this.manualProcessingForm.controls.manualProcessing.valueChanges
      .pipe(startWith(false), takeUntilDestroyed(this.destroyRef))
      .subscribe((isManualProcessing) => {
        setControlDisabled(this.manualProcessingForm.controls.manualProcessingReason, !isManualProcessing);
      });
  }

  get companyNameDetailsForm() {
    return this.stepperForm.controls[Form410BStepsEnum.CompanyNameDetails];
  }

  get manualProcessingForm() {
    return this.stepperForm.controls[Form410BStepsEnum.ManualProcessing];
  }
}

