import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function abnValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const abn = control.value;
    if (!abn) {
      return null; // If the value is empty, consider it as valid
    }

    // Remove any spaces or hyphens from the ABN
    const cleanedAbn = abn.replace(/\s+/g, '').replace(/-/g, '');

    // Check if the ABN is 11 digits long
    if (cleanedAbn.length !== 11 || isNaN(parseInt(cleanedAbn, 10))) {
      return { abn: true };
    }

    // Subtract 1 from the first digit
    const adjustedAbn = cleanedAbn.replace(/^\d/, (firstDigit) => (parseInt(firstDigit, 10) - 1).toString());

    // Calculate the sum of products
    let sum = 0;
    for (let i = 0; i < adjustedAbn.length; i++) {
      const digit = parseInt(adjustedAbn[i], 10);
      const weight = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19][i];
      sum += digit * weight;
    }

    // Check if the remainder after dividing by 89 is zero
    if (sum % 89 !== 0) {
      return { abn: true };
    }

    return null; // ABN is valid
  };
}
