import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { catchError, filter, firstValueFrom, map, of, switchMap } from 'rxjs';
import { NotesService } from '../components/notes/services/notes.service';
import { IndividualAddressComponent } from '../modals/individual-address/individual-address.component';
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Individual } from '../../models/individual';
import { IndividualService } from '../../services/individual.service';
import { Company } from '../../models/company';
import { ToastrService } from 'ngx-toastr';
import { IndividualPickComponent } from '../modals/individual-pick/individual-pick.component';
import { EntityType } from "../../models/enums/entityType";
import { ModalFormsService } from "../../services/modal-forms.service";
import { Relationship } from "../../models/relationship";
import { BulkCompanyChangeName } from "../modals/bulk-changes/bulk-change-name-form/BulkCompanyChangeName.model";
import { BulkService } from "../../services/bulk.service";
import {
  BulkCompanyChangeAddress
} from "../modals/bulk-changes/bulk-change-address-form/BulkCompanyChangeAddress.model";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { AuxiliaryService } from "../../services/auxiliary.service";
import { BulkSearchResult } from "../../models/bulkChangeData";
import { NotesComponent } from "../components/notes/notes.component";
import { AlertsListComponent } from "../components/alerts-list/alerts-list.component";
import { DatePipe } from "@angular/common";
import { EntityProfileTagsComponent } from "../components/company-profile-tags/entity-profile-tags.component";
import { TabButtonComponent } from "../components/common/tab-button/tab-button.component";
import { AppSpinnerDirective } from "../../directives/spinner.directive";
import { FormsModule } from "@angular/forms";
import { RelationshipTypePipe } from "../../pipes/enumsPipes/relationshipTypePipe";
import { CountryPipe } from "../../pipes/countryPipe";
import { DefaulValuePipe } from "../../pipes/enumsPipes/defaultValuePipe";
import { PageTitleComponent } from "../components/common/page-title/page-title.component";

@Component({
  selector: 'app-individual',
  standalone: true,
  templateUrl: './individual.component.html',
  styleUrl: './individual.component.scss',
  imports: [
    FormsModule,
    DatePipe,
    RelationshipTypePipe,
    CountryPipe,
    DefaulValuePipe,
    RouterLink,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    AppSpinnerDirective,
    NotesComponent,
    AlertsListComponent,
    EntityProfileTagsComponent,
    TabButtonComponent,
    PageTitleComponent,
  ]
})
export class IndividualComponent implements OnInit {

  private modalService = inject(NgbModal);
  private route = inject(ActivatedRoute);
  private router: Router = inject(Router);
  private individualService = inject(IndividualService);
  private notesService = inject(NotesService);
  private modalFormsService = inject(ModalFormsService);
  private addressService = inject(AuxiliaryService);
  private bulkService = inject(BulkService);
  private destroyRef = inject(DestroyRef);

  protected readonly EntityType = EntityType;
  toastr = inject(ToastrService);
  individual!: Individual;
  isSpliting = false;
  isSpitMode = false;
  alertsVisible = false;
  notesVisible = false;
  notesTotal = 0;

  async ngOnInit() {
    const individualId = this.route.snapshot.paramMap.get('id');

    if (individualId != null)
      await this.loadIndividual(individualId);
  }

  async loadIndividual(individualId: string) {
    this.individual = await firstValueFrom(this.individualService.get(individualId));
    this.loadNotesTotal();
  }

  getTags() {
    return this.individual.tags.concat(this.individual.systemTags);
  }

  edit() {
    return null;
  }

  getCompanies(): Company[] {
    const companyList = this.individual.relationships.map(x => x.entity as Company);
    return companyList;
  }

  getCompanytype(enitity) {
    if (enitity)
      return enitity as Company;
    return null;
  }

  address() {
    if (this.modalOpened()) return;
    const modalRef = this.modalService.open(IndividualAddressComponent);
    //(modalRef.componentInstance as IndividualAddressComponent).addressForm = structuredClone(this.individual.address);
    // modalRef.closed.subscribe(value => {
    //   this.addressForm = value as AddressForm;
    //   this.addressForm.preview = false;
    // });
  }

  async openCompanyProfile(id: string) {
    await this.router.navigate(['/company-profile', id]);
  }

  setSplitMode(value: boolean) {
    this.isSpitMode = value;
  }

  async splitIndidviduals(mode) {
    if (this.modalOpened()) return;
    this.isSpliting = true;
    const relationshipsId = this.individual.relationships.filter(r => r.checked);

    if (relationshipsId.length == 0) {
      this.toastr.warning("Please select relationship to split");
      this.isSpliting = false;
      return;
    }

    if (relationshipsId.length == this.individual.relationships.length) {
      this.toastr.warning("You can't split all relationships of individual");
      this.isSpliting = false;
      return;
    }

    try {
      if (mode === 'new')
        await firstValueFrom(this.individualService.split(this.individual.individualId, relationshipsId.map(x => x.relationshipId), undefined));
      else {
        const assignTo = await this.assignToExisting();
        if (assignTo)
          await firstValueFrom(this.individualService.split(this.individual.individualId, relationshipsId.map(x => x.relationshipId), assignTo));

      }
      this.individual = await firstValueFrom(this.individualService.get(this.individual.individualId));

    } catch (e) {
      this.toastr.error("Error while spliting individuals");
    }
    this.isSpitMode = false;
    this.isSpliting = false;
  }

  async assignToExisting() {
    const individualList = await firstValueFrom(this.individualService.list());

    const modalRef = this.modalService.open(IndividualPickComponent);
    const instance = (modalRef.componentInstance as IndividualPickComponent);
    instance.individualList = individualList.filter(x => x.fullName == this.individual.fullName && x.individualId != this.individual.individualId);

    const result = await firstValueFrom<string>(modalRef.closed);
    return result;
  }

  private loadNotesTotal(): void {
    this.notesService.getNotesTotal(this.individual.individualId)
      .subscribe({ next: (total) => this.notesTotal = total });
  }

  get notesLabel(): string {
    if (this.notesTotal === 0) {
      return 'Notes';
    } else if (this.notesTotal === 1) {
      return '1 Note';
    }
    return `${ this.notesTotal } Notes`;
  }

  get alertsLabel(): string {
    const alertsTotal = this.individual?.alerts?.length ?? 0;
    if (alertsTotal === 0) {
      return 'Alerts';
    } else if (alertsTotal === 1) {
      return '1 Alert';
    }
    return `${alertsTotal} Alerts`;
  }

  bulkChangeName(relationships: Relationship[]): void {
    if (this.modalOpened()) return;
    const relationshipIds = relationships.map(r => r.relationshipId);
    const modalRef = this.modalFormsService.openModalWithBulkCompany(new BulkCompanyChangeName({ relationshipIds, relationships }), this.getCompanies()[0], true, {}, {});
    void modalRef.result.then();
  }

  bulkChangeAddress(address: string): void {
    if(address) {
      this.addressService.getParseAddress(address)
        .pipe(
          filter(address => !!address),
          map(({ address }) => ({ ...address, country: address.country === 'Australia' ? 'AU' : address.country })),
          switchMap(address => {
            if(!address?.normalizedFullAddress) {
              return [];
            }
            return this.bulkService.bulkSearchByAddress(address.normalizedFullAddress);
          }),
          catchError((error) => {
            console.error(error);
            return of({ companies: [], trusts: [] });
          }),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe((res: BulkSearchResult) => {
          const { companies, trusts } = res;
          if(!companies.length && !trusts.length) {
            this.toastr.error('No data found for address change', 'Error');
            return;
          }

          const companyMatches = companies.map(c => ({
            entityId: c.entityId,
            relationshipIds: c.relationsdhips?.map(r => r.relationshipId) ?? [],
            relationships: c.relationsdhips ?? [],
            dateOfEstablishment: c.dateOfEstablishment as unknown as string,
            name: c.name,
            acn: c.entityNumber,
          }));

          const trustMatches = trusts.map(t => ({
            entityId: t.entityId,
            relationshipIds: t.relationsdhips?.map(r => r.relationshipId) ?? [],
            relationships: t.relationsdhips ?? [],
            dateOfEstablishment: t.dateOfEstablishment as unknown as string,
            name: t.name,
            abn: t.entityNumber,
          }));

          const bulkMatchesSelected = [...companyMatches, ...trustMatches];

          const modalRef = this.modalFormsService.openModalWithBulkCompany(
            new BulkCompanyChangeAddress({ bulkMatchesSelected, isIndividual: true, individualAddress: address }),
            new Company(),
            true,
            {},
            {}
          );
          void modalRef.result.then();
        });
    }
  }

  get modalOpened() {
    return this.modalFormsService.modalOpened;
  }
}
