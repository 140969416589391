import { EmailRecord } from "./emailRecord";

export class EmailModel extends EmailRecord {
  html: string;
  attachments: string[];

  constructor(data: Partial<EmailModel>) {
    super(data);

    this.html = data.html ?? '';
    this.attachments = data.attachments ?? [];
  }
}
