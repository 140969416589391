import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PageTitleComponent } from "../../../components/common/page-title/page-title.component";

@Component({
  selector: 'app-email-settings-page',
  standalone: true,
  imports: [PageTitleComponent],
  templateUrl: './email-settings-page.component.html',
  styleUrl: '../document-templates-page/document-templates-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailSettingsPageComponent {
  readonly boltEmailAddress = 'noreply@bolt.com.au';
}
