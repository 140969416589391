import { Injectable } from '@angular/core';
import * as formsDescription from './../../../assets/forms-description/forms-description.json';

export type IFormDescription = Record<string, { key: string; value: string; }[]>;

@Injectable()
export class StepperFormDescriptionHelperService {
  private formsDescription: IFormDescription = formsDescription as IFormDescription;

  getDescription(formKey: string): { key: string; value: string; }[] {
    return this.formsDescription[formKey] || [];
  }
}
