import { NgClass } from '@angular/common';
import { booleanAttribute, Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormSwitchButtonComponent } from '../form-switch-button/form-switch-button.component';
import { LinkComponent } from '../link/link.component';
import { ValidationErrorComponent } from '../validation-error/validation-error.component';
import { MaskedInputComponent } from "../masked-input/masked-input.component";
import { CustomFormValidators } from "../../../../custom-form-validators/custom-form-validators";
import { phoneValidatorMsg } from "../../../../validators/validatorMessages/custom-form-validators-messages";
import { TooltipComponent } from "../tooltip/tooltip.component";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-input-phone-number',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    FormSwitchButtonComponent,
    LinkComponent,
    ValidationErrorComponent,
    NgClass,
    TooltipComponent,
    NgbTooltip,
  ],
  templateUrl: './input-phone-number.component.html',
  styleUrl: '../input/input.component.scss'
})
export class InputPhoneNumberComponent extends MaskedInputComponent implements OnInit, ControlValueAccessor {
  @Input({ transform: booleanAttribute }) usedInsideGrid = false;
  @Input() override label = 'Phone Number';
  @Input() override customErrors = phoneValidatorMsg;

  private readonly regex: RegExp = new RegExp(/^[0-9+\s-]*$/);
  private readonly specialKeys = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'];

  ngOnInit(): void {
    if (this.ngControl.control) {
      this.ngControl.control.addValidators(
        [
          CustomFormValidators.minLength(6),
          CustomFormValidators.maxLength(12),
          CustomFormValidators.phoneNumberValidator
        ]
      );
    }
  }

  override onInput(event: Event): void {
    const target = event.target as HTMLInputElement;
    let value = target.value;

    // Remove non-digit characters
    value = value?.replace(/\D/g, '');

    const currentCursorPositionStart = target.selectionStart ?? 0;

    const inputEvent = event as InputEvent;
    const isUserDeleteCharacter = inputEvent.data === null && (inputEvent.inputType === 'deleteContentBackward' || inputEvent.inputType === 'deleteContentForward');

    if (isUserDeleteCharacter) {
      const maskedValue = this.applyMask(value);
      this.value = maskedValue.replaceAll('_', '').trimEnd();
      target.value = maskedValue.replaceAll('_', '');
      target.setSelectionRange(currentCursorPositionStart, currentCursorPositionStart);
    } else {
      // Apply mask only if user is writing a character
      const maskedValue = this.applyMask(value);

      const cursorPosition = maskedValue.charAt(currentCursorPositionStart) === ' '
        ? currentCursorPositionStart + 1
        : currentCursorPositionStart;

      // Update input value and cursor position
      this.value = maskedValue.replaceAll('_', '');
      target.value = maskedValue.replaceAll('_', '');

      if (currentCursorPositionStart > 0 && maskedValue[currentCursorPositionStart - 1] === ' ') {
        target.setSelectionRange(currentCursorPositionStart + 1, currentCursorPositionStart + 1);
      } else if (inputEvent.data && !Number.isNaN(Number(inputEvent.data))) {
        target.setSelectionRange(cursorPosition, cursorPosition);
      } else {
        target.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
      }
    }
  }

  override applyMask(value: string): string {
    let maskedValue = '';
    let index = 0;
    this.mask = this.getMask(value);

    for (let i = 0; i < this.mask.length; i++) {
      if (this.mask.charAt(i) === '_') {
        if (index < value.length) {
          maskedValue += value.charAt(index++);
        } else {
          maskedValue += '_';
        }
      } else {
        maskedValue += this.mask.charAt(i);
      }
    }

    return maskedValue;
  }

  private getMask(value: string | null): string {
    return InputPhoneNumberComponent.getPhoneMask(value);
  }

  static getPhoneMask(value: string | null): string {
    const phone = (value ?? '').replace(/\D/g, '').trim();
    const landlineNumbersMarkers: string[] = ['02', '03', '07', '08'];
    let mask = '____________';

    if (phone.startsWith('04') && phone.length <= 10) {
      // Mobile number.
      // Number starts with '04'
      // e.g.: 0400 000 000
      mask = '____ ___ ___';
    } else if (landlineNumbersMarkers.some((marker) => phone.startsWith(marker)) && phone.length <= 10) {
      // Landline number
      // Number starts with '02', '03', '07', or '08'
      // e.g.: 07 0000 0000
      mask = '__ ____ ____';
    } else if (phone.startsWith('1300') && phone.length <= 10) {
      // The '1300' number
      // Number starts with '1300'
      // e.g.: 1300 000 000
      mask = '____ ___ ___';
    } else if (phone.startsWith('13') && !phone.startsWith('130') && phone.length <= 6) {
      // The '13' numbers
      // Number starts with '13' and followed by a number except 0
      // e.g.: 13 xx xx
      mask = '__ __ __';
    }

    return mask;
  }

  onKeyPressed(e: KeyboardEvent): void {
    if(this.usedInsideGrid) {
      e.stopPropagation();
    }
  }

  getValidationErrorMsg(): string {
    const errors = this.ngControl.control?.errors;

    if (!errors) {
      return '';
    }

    for (const errorKey of Object.keys(errors)) {
      if (this.customErrors[errorKey]) {
        return this.customErrors[errorKey] as string;
      }
    }

    return '';
  }
}
