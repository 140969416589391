import { Entity } from "./entity";
import { TrustStatus, TrustSubType, TrustType } from "./enums/trustEnum";
import { Relationship } from "./relationship";
import { DatepickerHelper } from "../custom-form-validators/date-picker-form-validators";
import { Guid } from "../app/helpers/guid.helper";

export class Trust extends Entity {
  abn: string;
  trustType: TrustType | null;
  trustSubType: TrustSubType | null;
  status: TrustStatus | null;
  vestingDate: Date | null;
  variationDates: Date[];
  variationClause: string;
  appointors: Relationship[];
  settlor: Relationship | null;
  successor: Relationship | null;
  trustees: Relationship[];
  beneficiaries: Relationship[];
  unitHolders: Relationship[];
  relationships: Relationship[];

  constructor(data: Partial<Trust> = {}) {
    super(data);

    this.organisationId = data.organisationId ?? Guid.EmptyGuid;

    this.abn = data.abn ?? '';
    this.status = data.status ?? TrustStatus.Active;
    this.trustType = data.trustType ?? null;
    this.trustSubType = data.trustSubType ?? null;
    this.vestingDate = data.vestingDate ? new Date(data.vestingDate) : null;
    this.variationDates = (data.variationDates ?? []).map((date) => new Date(date));
    this.variationClause = data.variationClause ?? '';
    this.settlor = data.settlor ?? null;
    this.successor = data.successor ?? null;
    this.appointors = data.appointors?.map((r) => new Relationship(r)) ?? [];
    this.trustees = data.trustees?.map((r) => new Relationship(r)) ?? [];
    this.beneficiaries = data.beneficiaries?.map((r) => new Relationship(r)) ?? [];
    this.unitHolders = data.unitHolders?.map((r) => new Relationship(r)) ?? [];
    this.relationships = data.relationships?.map((r) => new Relationship(r)) ?? [];
  }

  getAllRelationships(): Relationship[] {
    let list = new Array<Relationship>();
    if (this.appointors) list.push(...this.appointors);
    if (this.settlor) list.push(this.settlor);
    if (this.successor) list.push(this.successor);
    if (this.trustees) list.push(...this.trustees);
    if (this.beneficiaries) list.push(...this.beneficiaries);
    return list;
  }

  prepareToRequest(): Trust {
    const trust = {
      ...this,
      vestingDate: this.vestingDate ? DatepickerHelper.buildDateString(this.vestingDate) : null,
      dateOfEstablishment: this.dateOfEstablishment ? DatepickerHelper.buildDateString(this.dateOfEstablishment) : null,
      variationDates: this.variationDates.map(date => DatepickerHelper.buildDateString(date)),
      appointors: this.appointors.map((relationship) => Relationship.prepareToRequest(relationship)),
      settlor: this.settlor ? Relationship.prepareToRequest(this.settlor) : null,
      successor: this.successor ? Relationship.prepareToRequest(this.successor) : null,
      trustee: this.trustees.map((relationship) => Relationship.prepareToRequest(relationship)),
      beneficiaries: this.beneficiaries.map((relationship) => Relationship.prepareToRequest(relationship)),
      unitHolders: this.unitHolders.map((relationship) => Relationship.prepareToRequest(relationship)),
      securityholders: this.securityholders.map((relationship) => Relationship.prepareToRequest(relationship)),
      relationships: this.relationships.map((relationship) => Relationship.prepareToRequest(relationship)),
      entityNumber: this.entityNumber ? this.entityNumber : '',
      abn: this.abn ? this.abn : null,
    };

    delete (trust as any).abn;

    return trust;
  }
}
