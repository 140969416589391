<app-common-modal-form
  [header]="'Edit officer'"
  [icon]="'icon ic-note'"
  [confirmText]="'Save'"
  [closeText]="'Cancel'"
  [isInvalid]="form.invalid || !checkAtLeastOneFieldFilled"
  (close)="close()"
  (confirm)="save()"
>
  <div class="d-flex flex-column gap-24" [formGroup]="form">
    <div class="form-group">
      <app-din-masked-input
        formControlName="din"
      ></app-din-masked-input>
    </div>

    <div class="form-group">
      <app-input
        formControlName="email"
        [label]="'Email Address'"
        [customErrors]="emailValidatorMsg"
      ></app-input>
    </div>

    <div class="form-group">
      <app-input-phone-number
        formControlName="phone"
        [label]="'Phone Number'"
      ></app-input-phone-number>
    </div>
  </div>
</app-common-modal-form>
