import { Component } from '@angular/core';
import { ICellRendererParams } from "ag-grid-community";
import { ApproverStatus } from "../../../../../../../models/enums/approverStatus";
import { SignRow } from "../../../../../../../models/signDocuments";
import { SplitByCapitalLetterPipePipe } from "../../../../../../../pipes/split-by-capital-letter-pipe.pipe";
import { DatePipe, NgClass } from "@angular/common";
import { AnnualSignGroup } from "../../../../../../../models/enums/annualStatementEnums";
import { SignatureStatus } from "../../../../../../../models/enums/signatureStatus";

@Component({
  selector: 'app-ag-annual-signatory-status',
  standalone: true,
  imports: [
    SplitByCapitalLetterPipePipe,
    NgClass,
    DatePipe
  ],
  templateUrl: './ag-annual-signatory-status.component.html',
  styleUrl: './ag-annual-signatory-status.component.scss'
})
export class AgAnnualSignatoryStatusComponent {
  protected status: ApproverStatus | SignatureStatus | null | undefined;
  protected signedOn = '';
  protected approvedOn = '';
  protected approverAdded = false;
  protected approverApproved = false;
  protected type: AnnualSignGroup | undefined;
  protected readonly SignatureStatus = SignatureStatus;
  protected readonly ApproverStatus = ApproverStatus;
  protected readonly AnnualSignGroup = AnnualSignGroup;

  agInit(params: ICellRendererParams<SignRow, ApproverStatus | SignatureStatus>): void {
    this.status = params.value;
    this.type = params.data?.type;
    this.signedOn = params.data?.signedOn ?? '';
    this.approvedOn = params.data?.approvedOn ?? '';
    this.approverApproved = params.data?.approverApproved ?? false;
    this.approverAdded = params.data?.approverAdded ?? false;
  }

  refresh(params: ICellRendererParams<SignRow, ApproverStatus | SignatureStatus>): boolean {
    this.status = params.value;
    this.type = params.data?.type;
    this.signedOn = params.data?.signedOn ?? '';
    this.approvedOn = params.data?.approvedOn ?? '';
    this.approverApproved = params.data?.approverApproved ?? false;
    this.approverAdded = params.data?.approverAdded ?? false;
    return true;
  }
}
