import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { map, Observable } from 'rxjs';
import { Entity } from '../models/entity';
import { SecurityChange } from '../models/securityChange';
import { SecurityRegistryRecord } from '../models/securityRegistryRecord';
import { SecurityTransaction } from "../models/securityTransaction";
import { SecurityTransactionType } from "../models/enums/SecurityTransactionType";

@Injectable({
  providedIn: 'root'
})
export class SecurityService {
  constructor(private http: HttpClient) { }

  getSecurityRegistry(id: string, groupByAsic = true, excludedDocumentId = '', applyIncompleteDocuments = true): Observable<SecurityRegistryRecord[]> {
    const options = { params: { excludedDocumentId, groupByAsic, applyIncompleteDocuments }};
    return this.http.get<SecurityRegistryRecord[]>(`${environment.api_url}/securities/${id}/`, options)
      .pipe(map((data) => data.map(record => new SecurityRegistryRecord(record))));
  }

  getSecurityEstimate<T extends Entity>(entityId: string, change: SecurityChange<T>[], excludedDocumentId = ''): Observable<SecurityRegistryRecord[]> {
    const options = { params: { excludedDocumentId }};
    return this.http.post<SecurityRegistryRecord[]>(`${ environment.api_url }/securities/${ entityId }/estimate`, change, options)
      .pipe(map((data) => data.map(record => new SecurityRegistryRecord(record))));
  }

  getSecurityTransactions(companyId: string): Observable<SecurityTransaction[]> {
    return this.http.get<SecurityTransaction[]>(`${environment.api_url}/securities/${companyId}/transactions`)
      .pipe(
        map((data) => this.calculateBalance(data))
      );
  }

  calculateBalance(data: SecurityTransaction[]): SecurityTransaction[] {
    const sortedData = data
      .sort((a, b) => (a.transactionDate as unknown as string).localeCompare(b.transactionDate as unknown as string))
      .map((item, i) => {
        return {
          ...item,
          numberBalance: i === 0 ? item.numberIncrease : 0,
          paidBalance: i === 0 ? item.paidIncrease : 0,
          unpaidBalance: i === 0 ? item.unpaidIncrease : 0
        };
      });

    for(let i = 1; i < sortedData.length; i++) {
      if (sortedData[i].transactionType === SecurityTransactionType.Balance) {
        sortedData[i].numberBalance = sortedData[i].numberIncrease;
        sortedData[i].paidBalance = sortedData[i].paidIncrease;
        sortedData[i].unpaidBalance = sortedData[i].unpaidIncrease;
      } else {
        if(sortedData[i-1].securityHoldingId === sortedData[i].securityHoldingId) {
          sortedData[i].numberBalance = sortedData[i-1].numberBalance + sortedData[i].numberIncrease;
          sortedData[i].paidBalance = sortedData[i-1].paidBalance + sortedData[i].paidIncrease;
          sortedData[i].unpaidBalance = sortedData[i-1].unpaidBalance + sortedData[i].unpaidIncrease;
        } else {
          sortedData[i].numberBalance = sortedData[i].numberIncrease;
          sortedData[i].paidBalance = sortedData[i].paidIncrease;
          sortedData[i].unpaidBalance = sortedData[i].unpaidIncrease;
        }
      }
    }

    // @ts-ignore
    return sortedData.reverse();
  }

  updateBeneficialOwner(relationshipId: string, beneficialOwnerName: string) {
    return this.http.post<void>(`${environment.api_url}/securities/${relationshipId}/beneficial-owner`, `"${beneficialOwnerName}"`, { headers: { 'Content-Type': 'application/json' } });
  }

  updateAuthSignatories(relationshipId: string, newAuthSignatories: string[]) {
    return this.http.post<void>(`${environment.api_url}/securities/${relationshipId}/auth-signatories`, newAuthSignatories, { headers: { 'Content-Type': 'application/json' } });
  }
}
