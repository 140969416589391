import { NgIf } from '@angular/common';
import {
  Component,
  Input,
} from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  standalone: true,
  selector: 'app-span-with-open-icon',
  templateUrl: './span-with-open-icon.component.html',
  styleUrl: './span-with-open-icon.component.scss',
  imports: [ RouterModule, NgIf ]
})
export class SpanWithOpenIconComponent {
  @Input() route?: string[];
}
