import { Component, Input, OnInit } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { MaskedInputComponent } from '../../masked-input.component';

@Component({
  selector: 'app-din-masked-input',
  standalone: true,
  imports: [
    MaskedInputComponent,
    ReactiveFormsModule
  ],
  templateUrl: './din-masked-input.component.html'
})
export class DinMaskedInputComponent extends MaskedInputComponent implements OnInit {
  @Input() override mask = '___ _____ _____ __';
  @Input() override placeholder = '___ _____ _____ __';
  @Input() override label = 'Director ID';

  ngOnInit(): void {
    this.ngControl.control?.setValidators([
      Validators.minLength(15),
      Validators.maxLength(15)
    ]);
  }
}
