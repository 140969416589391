import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize, take } from 'rxjs';
import { TrustsService } from '../../services/trustsService';
import { ModalFormsService } from '../../services/modal-forms.service';
import {
  CsvExportParams,
  ExcelExportParams,
  GetContextMenuItems,
  GridApi,
  ProcessCellForExportParams,
  ProcessHeaderForExportParams
} from 'ag-grid-community';
import { Trust } from '../../models/trust';
import { Tag } from '../../models/tag';
import { TrustStatus } from '../../models/enums/trustEnum';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FilterParams } from '../../models/gridFilter';
import { ButtonComponent } from '../components/common/button/button.component';
import { GridComponent } from '../components/common/grid/components/grid/grid.component';
import { NotesComponent } from '../components/notes/notes.component';
import { formatDate } from '@angular/common';
import { AgTrustActionsComponent } from '../components/common/grid/trusts/ag-trust-actions/ag-trust-actions.component';
import { ManageTagsModalComponent } from '../settings/components/tags/manage-tags-modal/manage-tags-modal.component';
import { EntityType } from '../../models/enums/entityType';
import { ExportCompanyListComponent } from "../modals/export-company-list/export-company-list.component";
import { ExportTypeEnum } from "../../models/enums/exportTypeEnum";
import { ColumnWithExportName } from "../../models/columnWithExportName";
import { Alert } from "../../models/alerts/alert";
import { AlertsListComponent } from "../components/alerts-list/alerts-list.component";
import { ABNPipe } from "../../pipes/abnPipe";
import { FormAddTrustComponent } from "./components/form-add-trust/form-add-trust.component";
import { OrganisationService } from "../../services/organisation.service";
import { User } from "../../models/user";
import { AlertStatus } from "../../models/enums/alertEnums";
import { deepClone } from "fast-json-patch/commonjs/core";

@Component({
  selector: 'app-trusts',
  templateUrl: './trusts.component.html',
  styleUrl: './trusts.component.scss',
  standalone: true,
  imports: [
    GridComponent,
    ButtonComponent,
    NotesComponent,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    AlertsListComponent,
  ],
  providers: [ABNPipe]
})
export class TrustsComponent implements OnInit {
  private router: Router = inject(Router);
  private trustsService: TrustsService = inject(TrustsService);
  private modalFormsService = inject(ModalFormsService);
  private modalService = inject(NgbModal);
  private organisationService = inject(OrganisationService);
  private abnPipe = inject(ABNPipe);
  #destroyRef: DestroyRef = inject(DestroyRef);

  gridApi!: GridApi;
  colDefs = this.trustsService.colDefs;
  loading = false;
  actionColumn!: ColumnWithExportName;

  readonly pageTitle = 'Trusts';
  readonly defaultPageSize = 20;
  readonly EntityType = EntityType;
  protected readonly ExportTypeEnum = ExportTypeEnum;

  excelExportParams = this.exportParamsXls() as ExcelExportParams;
  csvExportParams = this.exportParamsCsv() as CsvExportParams;
  excelStyles = this.trustsService.excelStyles;

  trustList: Trust[] = [];
  checkedTrust: Trust[] = [];
  alerts: Alert[] = [];

  alertsVisible = false;
  entityForAlerts: Trust | undefined | null = null;

  notesVisible = false;
  entityForNotes: Trust | undefined | null = null;

  ngOnInit() {
    this.actionColumn = {
      field: '',
      width: 150,
      sortable: false,
      cellClass: 'actions-button-cell',
      cellRenderer: AgTrustActionsComponent,
      cellRendererParams: {
        openTrustProfile: this.openTrustProfile.bind(this),
        appointAccountManager: this.appointAccountManager.bind(this),
        exportTrustProfile: this.exportTrustProfile.bind(this),
        managePins: this.managePins.bind(this),
        openAlerts: this.openAlerts.bind(this),
        openNotes: this.openNotes.bind(this),
      },
      suppressHeaderMenuButton: true,
    }

    this.loadTrustList();
    this.trustsService.trustsListUpdated$
    .pipe(takeUntilDestroyed(this.#destroyRef))
    .subscribe(() => this.trustsService.setTrustOptions(this.trustList, this.actionColumn))
  }

  loadTrustList(): void {
    this.loading = true;
    this.trustsService.list()
      .pipe(finalize(() => this.loading = false))
      .subscribe(trusts => {
        const users = this.organisationService.getCachedProfileUsers();
        this.trustList = trusts.map(t => {
          users.forEach(u => {
            if (u.id === t.accountManagerId) {
              t.accountManager = (u as unknown as User);
            }

            if (u.id === t.partnerManagerId) {
              t.partnerManager = (u as unknown as User);
            }
          })

          return t;
        });

        this.trustList = trusts;
        this.trustsService.setTrustOptions(this.trustList, this.actionColumn);
      });
  }

  addTrust(): void {
    if (this.modalOpened()) return;
    const modalRef = this.modalFormsService.openModal(FormAddTrustComponent);

    modalRef.result.then(
      (trust: Trust) => this.router.navigate(['trust-profile', trust.entityId]),
      () => {}
    )
  }

  appointAccountManager(trust: Trust): void {
    console.log(trust)
  }

  managePins(trust: Trust): void {
    console.log(trust)
  }

  async openTrustProfile(trust: Trust): Promise<void> {
    await this.router.navigate(['/trust-profile', trust.entityId]);
  }

  selectTrust(trusts: Trust[]): void {
    this.checkedTrust = trusts;
  }

  onGridReady(gridApi: GridApi): void {
    this.gridApi = gridApi;
    this.trustsService.gridApi = gridApi;
  }

  exportSelectedTrustsToXls(): void {
    this.exportTrustList(true, ExportTypeEnum.EXCEL);
  }

  exportSelectedTrustsToCsv(): void {
    this.exportTrustList(true, ExportTypeEnum.CSV);
  }

  exportTrustProfile(trust: Trust): void {
    const columnForExport = this.colDefs()?.filter(c => !c.hide && c.headerName)
      .map(c => c.field) as string[];

    const nameIndex = columnForExport.findIndex(field => field === 'name');
    if(nameIndex >= 0) {
      columnForExport.splice(nameIndex + 1, 0, 'abn');
    }
    const tagIndex = columnForExport.findIndex(field => field === 'tags');
    if(tagIndex >= 0) {
      columnForExport.splice(tagIndex + 1, 0, 'systemTags');
    }

    this.trustsService.numberColumnForExport.set(columnForExport.length - 1);
    const exportParamsXls = this.exportParamsXls() as ExcelExportParams;
    this.gridApi.exportDataAsExcel({ ...exportParamsXls, columnKeys: columnForExport, onlySelected: true });
  }

  getSelectedFilterOptions(columnId: string): string {
    if(!this.gridApi) { return ''; }
    const filterParams = this.gridApi.getColumn(columnId)?.getColDef().filterParams as FilterParams;
    const filterOptions = filterParams?.filterOptions ?? [];
    const selectedFilterOptions = filterOptions.filter(o => o.active).map(o => o.label).join(', ');
    return selectedFilterOptions;
  }

  exportTrustList(isBulkExport: boolean, exportType: ExportTypeEnum): void {
    if (this.modalOpened()) return;
    const componentInstance = this.modalFormsService.openModal(ExportCompanyListComponent, {
      modalDialogClass: 'export-company-list'
    }).componentInstance as ExportCompanyListComponent;

    componentInstance.colDefs = deepClone(this.colDefs()) as ColumnWithExportName[];
    componentInstance.title = 'Export Trust List';
    componentInstance.subTitle = 'trust selected';
    componentInstance.numberOfCompanies = isBulkExport ? this.checkedTrust.length : this.trustList.length;
    componentInstance.exportType = exportType;
    componentInstance.confirm.pipe(
      takeUntilDestroyed(this.#destroyRef)
    ).subscribe((columnForExport: string[]) => {
      const nameIndex = columnForExport.findIndex(field => field === 'name');
      if(nameIndex >= 0) {
        columnForExport.splice(nameIndex + 1, 0, 'abn');
      }
      const tagIndex = columnForExport.findIndex(field => field === 'tags');
      if(tagIndex >= 0) {
        columnForExport.splice(tagIndex + 1, 0, 'systemTags');
      }

      this.trustsService.numberColumnForExport.set(columnForExport.length - 1);

      const fileName = this.getSelectedFilterOptions('status')
        ? this.getSelectedFilterOptions('status') + ' Trusts ' + formatDate(new Date(), 'dd-MM-yyyy', 'en-US')
        : 'Trusts ' + formatDate(new Date(), 'dd-MM-yyyy', 'en-US');
      if (exportType === ExportTypeEnum.EXCEL) {
        const params: ExcelExportParams = isBulkExport ? { columnKeys: columnForExport, onlySelected: true } : { columnKeys: columnForExport };
        const exportParamsXls = this.exportParamsXls() as ExcelExportParams;
        this.gridApi.exportDataAsExcel({ ...exportParamsXls, ...params, fileName });
      } else if (exportType === ExportTypeEnum.CSV) {
        const params: CsvExportParams = isBulkExport ? { columnKeys: columnForExport, onlySelected: true } : { columnKeys: columnForExport };
        this.gridApi.exportDataAsCsv({ ...params, fileName });
      }
    });
  }

  manageTags(): void {
    if (this.modalOpened()) return;
    const modalRef = this.modalService.open(ManageTagsModalComponent,
      {
        windowClass: 'manage-tags-modal',
        size: 'xl',
        centered: true
      });
    const instance = modalRef.componentInstance as ManageTagsModalComponent;

    instance.entities = this.checkedTrust;
    instance.confirm
      .pipe(take(1), takeUntilDestroyed(this.#destroyRef))
      .subscribe(() => {
        this.loadTrustList();
        this.selectTrust([]);
      });
  }

  exportParamsXls() {
    return {
      ...this.exportParams(),
      prependContent: this.trustsService.getExcelTitleRows(this.trustsService.numberColumnForExport())
    };
  }

  exportParamsCsv() {
    return this.exportParams();
  }

  exportParams() {
    const abnPipe = this.abnPipe;
    return {
      processCellCallback(params: ProcessCellForExportParams): string {
        const value: unknown = params.value;
        const companyData = params.node?.data as Trust;
        if (params.column.getColId() === 'name') {
          return companyData.name;
        } else if (params.column.getColId() === 'abn') {
          return abnPipe.transform(companyData.abn);
        } else if (params.column.getColId() === 'status') {
          return TrustStatus[value as TrustStatus];
        } else if (params.column.getColId() === 'tags') {
          return (value as Tag[] ?? []).map((tag: Tag) => tag.name).join(', ')
        } else if (params.column.getColId() === 'systemTags') {
          return (value as Tag[] ?? []).map((tag: Tag) => tag.name).join(', ')
        }
        return value as string;
      },
      processHeaderCallback(params: ProcessHeaderForExportParams) {
        const colDef = params.column.getColDef() as ColumnWithExportName
        return colDef?.exportColumnName ?? colDef.headerName;
      },
      sheetName: 'Trusts',
    }
  }

  getContextMenuItems(gridApi: GridApi & {defaultItems: unknown[]; node: {data: {entityId: string}}}): GetContextMenuItems {
    const items = gridApi.defaultItems.slice();

    return ([
      {
        name: 'Open Trust Detail in New Tab',
        action: () => {
          const trustId = gridApi.node.data.entityId;
          const url = `/trust-profile/${trustId}`;
          window.open(url, '_blank');
        }
      },
      'separator',
      ...items,
    ]) as unknown as GetContextMenuItems
  }

  openAlerts(trust: Trust): void {
    if (this.modalOpened()) return;
    this.alertsVisible = true;
    this.alerts = trust.alerts?.filter(alert => {
      return alert.status !== AlertStatus.Archived && alert.status !== AlertStatus.Ignored
    });
    this.entityForAlerts = trust;
  }

  openNotes(trust: Trust): void {
    if (this.modalOpened()) return;
    this.notesVisible = true;
    this.entityForNotes = trust;
  }

  get modalOpened() {
    return this.modalFormsService.modalOpened;
  }
}
