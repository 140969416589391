import { Component, Input } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { NgClass } from "@angular/common";
import { EmailRecord } from "../../../../models/email-templates/emailRecord";
import { EmailStatus } from "../../../../models/enums/emailStatus";

@Component({
  selector: 'app-ag-outgoing-message-status',
  standalone: true,
  imports: [NgClass],
  templateUrl: 'ag-outgoing-email-message-status.component.html',
  styleUrl: 'ag-outgoing-email-message-status.component.scss'
})
export class AgOutgoingEmailMessageStatusComponent implements ICellRendererAngularComp {
  @Input() status!: EmailStatus | null;

  agInit(params: ICellRendererParams<EmailRecord>): void {
    this.status = params.data?.status ?? null;
  }

  refresh(params: ICellRendererParams<EmailRecord>): boolean {
    this.status = params.data?.status ?? null;
    return true;
  }

  get statusText(): string {
    return this.status !== null && this.status in EmailStatus ? EmailStatus[this.status] as string : '';
  }
}
