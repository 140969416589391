<app-stepper-form
  header="Cessation of Officeholder"
  subheader="Form 484B1"
  [showSteps]="false"
  [steps]="steps"
  [currentStep]="currentStep"
  [currentStepIndex]="currentStepIndex"
  [isLoading]="isLoading"
  [form]="stepperForm"
>
  <div class="step-content" [formGroup]="stepperForm">
    <app-stepper-form-description
      formKey="484 B1"
      [hidden]="currentStep !== StepsEnum.FormDescription"
    ></app-stepper-form-description>

    <section
      class="step-section"
      [hidden]="currentStep !== StepsEnum.CeaseCompanyOfficeholder"
      [formGroupName]="StepsEnum.CeaseCompanyOfficeholder"
    >
      <h1 class="h1-heading">{{ steps[currentStepIndex].label }}</h1>

      <div class="form-group">
        <app-date-picker
          label="Date of Cessation"
          formControlName="changeDate"
          [minDate]="minDate"
          [maxDate]="maxDate"
          [customErrors]="customDatepickerErrors"
        ></app-date-picker>
      </div>

      <div class="form-group">
        <app-select
          label="Cease Officer"
          formControlName="appointedOfficerId"
          [options]="officersSelectOptions"
          [valuePrimitive]="true"
          (select)="form.controls.cessationReason.reset()"
        ></app-select>
      </div>

      <div class="form-group" formArrayName="types" [hidden]="!form.controls.appointedOfficerId.value">
        <div class="label">Positions</div>

        <ul class="positions-list my-3 pl-3">
          @for (officerPositionTypesOption of officerPositionTypesOptions; track officerPositionTypesOption; let index = $index) {
            <li class="positions-list-item">
              <app-checkbox
                [label]="officerPositionTypesOption.label"
                [formControlName]="index"
              ></app-checkbox>
            </li>
          }
        </ul>

        @if (form.controls.types.length && form.controls.types.touched) {
          <app-validation-error
            [control]="form.controls.types"
            [customErrors]="{requiredAtLeastOne: 'At least one officer position type must be selected'}"
          ></app-validation-error>
        }

        @if(!this.form.controls.moreThanOneDirector.value && currentStep === StepsEnum.CeaseCompanyOfficeholder) {
          <app-notification class='mr-3' [text]="ceaseWarningMsg" [color]="'red'"></app-notification>
        }
      </div>

      <div class="form-group">
        <app-select
          label="Reason"
          formControlName="cessationReason"
          [options]="reasonOfCessationOptions"
          [valuePrimitive]="true"
        ></app-select>
      </div>

      <div class="form-group" [hidden]="noticeOfResignationHidden">
        <app-textarea
          label="Notice of Resignation"
          formControlName="noticeOfResignation"
          [rows]="3"
        ></app-textarea>
      </div>
    </section>
  </div>
</app-stepper-form>


