import { Component, inject, Input, OnInit } from '@angular/core';
import { CommonModalFormComponent } from "../../../../modals/common-modal-form/common-modal-form.component";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { AppCopyDirective } from "../../../../../directives/copy.directive";
import { DefaulValuePipe } from "../../../../../pipes/enumsPipes/defaultValuePipe";
import { LoaderComponent } from "../../../../components/common/loader/loader.component";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SelectComponent } from "../../../../components/common/select/select.component";
import { CheckboxComponent } from "../../../../components/common/checkbox/checkbox.component";
import { RadioComponent } from "../../../../components/common/radio/radio.component";
import {
  DocumentSettingsModel,
  NotifyWhenAllPartiesSignedDocument,
  NotifyWhenAllPartiesSignedDocumentOptions
} from "./edit-document-settings-modal.constant";
import { ToastrService } from "ngx-toastr";
import { YesNoControlComponent } from "../../../../components/common/yes-no-control-component/yes-no-control.component";

@Component({
  selector: 'app-edit-document-settings-modal',
  standalone: true,
  imports: [
    CommonModalFormComponent,
    AppCopyDirective,
    DefaulValuePipe,
    LoaderComponent,
    SelectComponent,
    CheckboxComponent,
    RadioComponent,
    ReactiveFormsModule,
    YesNoControlComponent
  ],
  templateUrl: './edit-document-settings-modal.component.html',
  styleUrl: './edit-document-settings-modal.component.scss'
})
export class EditDocumentSettingsModalComponent implements OnInit {
  private activeModal = inject(NgbActiveModal);
  private toastr = inject(ToastrService);

  @Input() documentSettings = new DocumentSettingsModel();

  readonly NotifyWhenAllPartiesSignedDocumentOptions = NotifyWhenAllPartiesSignedDocumentOptions;

  isLoading = false;
  form = new FormGroup({
    // documents
    receiveEmailsForDocs: new FormControl<NotifyWhenAllPartiesSignedDocument | null>(NotifyWhenAllPartiesSignedDocument.DoNotSend),
    // annual statement

    receiveEmailsForAS: new FormControl<NotifyWhenAllPartiesSignedDocument | null>(NotifyWhenAllPartiesSignedDocument.DoNotSend),
    insertDirectorSignatureInCompanyStatement: new FormControl<boolean | null>(false),
  });

  ngOnInit() {
    this.activeModal.update({ modalDialogClass: 'common-modal-form-dialog overflow-y-auto' });
    this.form.patchValue(this.documentSettings);
  }

  confirm(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.toastr.success('Data saved successfully', 'Success');
    this.activeModal.close();
  }

  close(): void {
    this.activeModal.dismiss();
  }
}
