@if(currentStepIndex !== DocumentStepEnum.AsicResponse) {
  <div class="generate-documents-header">
    <div class="button-container-default">
      @if (currentStepIndex === DocumentStepEnum.GenerateDocuments) {
        <app-button
          [text]="'Save and Complete Later'"
          [btnColor]="'white'"
          [loading]="loading || saveLoading()"
          [isDisabled]="loading || patchLoading() || saveLoading()"
          (clickBtn)="save()"
        ></app-button>
      }

      @if(currentStepIndex === DocumentStepEnum.SignDocuments) {
        @if(displayLodgeNowBtn) {
          <app-button
            [text]="'Lodge now'"
            [btnColor]="'white'"
            [loading]="lodgeNowBtnLoading"
            [isDisabled]="lodgeNowBtnLoading || sendSignLoading()"
            (clickBtn)="onLodgeDocumentNow()"
          ></app-button>
        }

        <app-button
          [text]="getButtonText()"
          [loading]="signDocumentsBtnLoading"
          [iconLast]="true"
          [iconClass]="'icon ic-arrow-right'"
          [isDisabled]="signDocumentsBtnDisabled"
          (clickBtn)="getButtonAction()"
        ></app-button>
      } @else {
        <app-button
          [text]="confirmBtnName"
          [loading]="loading || patchLoading()"
          [iconLast]="true"
          [iconClass]="'icon ic-arrow-right'"
          [isDisabled]="loading || patchLoading() || disabledHeaderBtn()"
          (clickBtn)="confirm()"
        ></app-button>
      }
    </div>

<!--    <div class="button-container-alternate">-->
<!--      @if (currentStepIndex === DocumentStepEnum.GenerateDocuments) {-->
<!--        <app-tooltip [customContent]="true">-->
<!--          <app-button-->
<!--            container="body"-->
<!--            [ngbTooltip]="'Save and Complete Later'"-->
<!--            [placement]="'bottom'"-->
<!--            [tooltipClass]="'tooltip-info width-auto'"-->
<!--            [disableTooltip]="loading || patchLoading() || saveLoading()"-->
<!--            [iconClass]="'icon ic-documents'"-->
<!--            [btnColor]="'white'"-->
<!--            [loading]="loading || saveLoading()"-->
<!--            [isDisabled]="loading || patchLoading() || saveLoading()"-->
<!--            (clickBtn)="save()"-->
<!--          ></app-button>-->
<!--        </app-tooltip>-->
<!--      }-->

<!--      <app-tooltip [customContent]="true">@if(currentStepIndex === DocumentStepEnum.SignDocuments) {-->
<!--        @if(displayLodgeNowBtn) {-->
<!--          <app-button-->
<!--            class="mr-10"-->
<!--            container="body"-->
<!--            [ngbTooltip]="'Lodge now'"-->
<!--            [placement]="'bottom'"-->
<!--            [tooltipClass]="'tooltip-info width-auto'"-->
<!--            [disableTooltip]="lodgeNowBtnLoading || sendSignLoading()"-->
<!--            [btnColor]="'white'"-->
<!--            [iconClass]="'icon ic-clock'"-->
<!--            [loading]="lodgeNowBtnLoading"-->
<!--            [isDisabled]="lodgeNowBtnLoading || sendSignLoading()"-->
<!--            (clickBtn)="onLodgeDocumentNow()"-->
<!--          ></app-button>-->
<!--        }-->

<!--        <app-button-->
<!--          container="body"-->
<!--          [ngbTooltip]="getButtonText()"-->
<!--          [placement]="'bottom'"-->
<!--          [tooltipClass]="'tooltip-info width-auto'"-->
<!--          [disableTooltip]="signDocumentsBtnDisabled"-->
<!--          [loading]="signDocumentsBtnLoading"-->
<!--          [iconLast]="true"-->
<!--          [iconClass]="'icon ic-arrow-right'"-->
<!--          [isDisabled]="signDocumentsBtnDisabled"-->
<!--          (clickBtn)="getButtonAction()"-->
<!--        ></app-button>-->
<!--      } @else {-->
<!--        <app-button-->
<!--          container="body"-->
<!--          [ngbTooltip]="confirmBtnName"-->
<!--          [placement]="'bottom'"-->
<!--          [tooltipClass]="'tooltip-info width-auto'"-->
<!--          [disableTooltip]="loading || patchLoading() || disabledHeaderBtn()"-->
<!--          [loading]="loading || patchLoading()"-->
<!--          [iconLast]="true"-->
<!--          [iconClass]="'icon ic-arrow-right'"-->
<!--          [isDisabled]="loading || patchLoading() || disabledHeaderBtn()"-->
<!--          (clickBtn)="confirm()"-->
<!--        ></app-button>-->
<!--      }-->
<!--      </app-tooltip>-->
<!--    </div>-->
  </div>
}
