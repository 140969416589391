<div class="entity-name-number-container">
  @if(showIcon) {
    <div>
      <i class="{{iconClass}}"></i>
    </div>
  }

  <div class="entity-name-wrapper">
    <div class="name" (click)="onHandleClick($event)">{{ name }}</div>
    <div class="number">{{ prefix }}{{ numberFormatted ?? '-' }}</div>
  </div>
</div>
