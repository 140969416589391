<div class="action-container">
  <div
    ngbDropdown
    container="body"
    [dropdownClass]="'meeting'"
    [placement]="['bottom-end', 'bottom-start', 'top-start', 'top-end']"
    class="dropdown d-inline-flex"
  >
    @if (status === undefined) {
      <div ngbDropdownToggle class="select-status">Select Status</div>
    } @else {
      <div ngbDropdownToggle class="status-tag" [ngClass]="MinutesMeetingPresence[status]">
        <span>{{ status | minutesMeetingPresenceStatus }}</span>
        <i class="icon ic-tiny ic-edit ml-1"></i>
      </div>
    }

    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
      @for(option of selectOptions; track $index) {
        <div ngbDropdownItem class="dropdown-item" (click)="onSelectDirectorMeetingStatus(option)">
          <a class="menu-action-button">
            <span>{{option | minutesMeetingPresenceStatus }}</span>
          </a>
        </div>
      }
    </div>
  </div>
</div>
