import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { delay, distinctUntilChanged, finalize } from 'rxjs';
import { CustomFormValidators } from '../../../../../custom-form-validators/custom-form-validators';
import { enumToSelectOptions } from '../../../../../functions/enums-to-list-formatter';
import { Address } from '../../../../../models/address';
import { AsicAgentData } from '../../../../../models/AsicAgentData.model';
import { SelectOption } from '../../../../../models/selectOptions';
import { UsersService } from '../../../../../services/users.service';
import { edgeLoginNumberErrorMessage, edgeLoginNumberValidator } from '../../../../../validators/edge-login.validator';
import { NumbersValidators } from '../../../../../validators/numbers.validators';
import {
  CommonModalWrapperComponent
} from '../../../../components/common/common-modal-wrapper/common-modal-wrapper.component';
import { DividerComponent } from '../../../../components/common/divider/divider.component';
import { InputPasswordComponent } from '../../../../components/common/input-password/input-password.component';
import {
  InputPhoneNumberComponent
} from '../../../../components/common/input-phone-number/input-phone-number.component';
import { InputComponent } from '../../../../components/common/input/input.component';
import { RadioComponent } from '../../../../components/common/radio/radio.component';
import { SelectComponent } from '../../../../components/common/select/select.component';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  AddressFormGroupComponent
} from "../../../../components/reusable-form-groups/address-form-group/address-form-group.component";
import { SettingsService } from "../../../../../services/settings.service";
import { generate, observe, Observer } from "fast-json-patch";
import { ToastrService } from "ngx-toastr";

export enum ASICMailbox {
  Primary,
  Secondary
}

@Component({
  selector: 'app-edit-agent-profile-modal',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputComponent,
    SelectComponent,
    DividerComponent,
    RadioComponent,
    InputPasswordComponent,
    InputPhoneNumberComponent,
    AddressFormGroupComponent,
    CommonModalWrapperComponent,
  ],
  templateUrl: './edit-agent-profile-modal.component.html',
  styleUrl: './edit-agent-profile-modal.component.scss'
})
export class EditAgentProfileModalComponent implements OnInit {
  private activeModal = inject(NgbActiveModal);
  private usersService = inject(UsersService);
  private settingsService = inject(SettingsService);
  private toastr = inject(ToastrService);
  private destroyRef = inject(DestroyRef);

  @Input() agent = new AsicAgentData();

  readonly asicMailboxOptions: SelectOption[] = enumToSelectOptions(ASICMailbox);
  readonly edgeLoginTip = 'The Edge login number must begin with the letter A, followed by five digits.';
  readonly agentNumberTip = 'Only numeric characters are allowed, with a length of 2 to 5 digits.';
  readonly edgeLoginCustomErrors = { edgeLoginNumber: edgeLoginNumberErrorMessage };
  readonly agentNumberCustomErrors = {
    isNumber: this.agentNumberTip,
    maxLength: this.agentNumberTip,
    minLength: this.agentNumberTip,
    isNaN: 'The value must be a number'
  };
  asicContacts: SelectOption[] = [];
  isLoading = false;
  observer!: Observer<AsicAgentData>;

  form = new FormGroup({
    firmName: new FormControl<string | null>('', [Validators.required, CustomFormValidators.maxLength(50)]),
    agentNumber: new FormControl<string | null>('', [Validators.required, CustomFormValidators.minLength(2), CustomFormValidators.maxLength(5), NumbersValidators.isNumber]),
    contactPerson: new FormControl<string | null>('', [Validators.required]),
    address: AddressFormGroupComponent.defineForm(),
    phone: new FormControl<string | null>('', []),
    email: new FormControl<string | null>('', [Validators.required, Validators.email]),
    edgeLogin: new FormControl<string | null>('', [Validators.required, edgeLoginNumberValidator()]),
    edgePassword: new FormControl<string | null>('', [Validators.required]),
    mailbox: new FormControl<ASICMailbox>(ASICMailbox.Primary, [Validators.required, NumbersValidators.isNumber]),
  });

  ngOnInit() {
    this.loadUsers();
    this.restrictStringLengthInput(this.form.controls.agentNumber, 5);
    this.restrictStringLengthInput(this.form.controls.edgeLogin, 6);
  }

  setupAgentSettings(agent: AsicAgentData, mailbox: ASICMailbox): void {
    this.agent = new AsicAgentData(agent);
    const contactPerson = this.asicContacts.find(person => person.label === this.agent.contactPerson)?.value ?? null;
    this.form.patchValue({
      ...agent,
      mailbox,
      contactPerson: this.form.controls.contactPerson.value ?? contactPerson as string | null,
      address: agent.postalAddress,
    });
  }

  save(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.isLoading = true;
    const contactPerson = this.asicContacts.find((personOption) => personOption.value === this.form.controls.contactPerson.value)?.label ?? '';
    const currentAgent = new AsicAgentData(structuredClone(this.agent));
    const agent = new AsicAgentData({
      ...this.agent,
      ...this.form.value as Partial<AsicAgentData>,
      contactPerson,
      postalAddress: new Address(this.form.controls.address.value as Partial<Address>),
    });
    this.observer = observe(currentAgent);
    currentAgent.patch(agent);

    this.settingsService.patchAgentChanges(generate(this.observer))
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: () => this.activeModal.close(agent),
        error: (error) => {
          console.warn('[patchChanges] error: ', error);
          this.toastr.error('Error happen while saving agent settings.');
        }
      });
  }

  cancel(): void {
    this.activeModal.dismiss();
  }

  private loadUsers(): void {
    this.isLoading = true;

    this.usersService.getUsers()
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: (users) => {
          this.asicContacts = users.map((user) => ({
            label: user.fullName,
            value: user.externalId,
          }));

          if (this.agent.contactPerson) {
            setTimeout(() => {
              const contactPerson = this.asicContacts.find(person => person.label === this.agent.contactPerson)?.value ?? null;
              this.form.controls.contactPerson.setValue(contactPerson as string | null);
            });
          }
        },
        error: (error) => console.warn('Can\'t load contacts', error)
      });
  }

  private restrictStringLengthInput(control: FormControl<string | null>, length: number): void {
    control.valueChanges
      .pipe(
        distinctUntilChanged(),
        delay(1),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((value) => {
        if (value?.length && value.length > length)
          control.setValue(value.slice(0, length));
      });
  }
}
