import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { CommonModule } from '@angular/common';
import { NgbDateParserFormatter, NgbDropdownModule, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { AppSpinnerDirective } from '../directives/spinner.directive';
import { AppRoutingModule } from './app.routing.module';
import { AppPipesModule } from './app.pipes.module';
import { AuthInterceptor } from '../services/auth.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { NgPipesModule } from 'ngx-pipes';
import { AppCopyDirective } from '../directives/copy.directive';
import { HeaderComponent } from './header/header.component';
import 'ag-grid-enterprise';
import { NgbDateCustomParserFormatter } from "../providers/ngb-custom-date-formatter";
import { NgxMaskDirective, provideNgxMask } from "ngx-mask";
import { DragulaModule } from "ng2-dragula";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import * as Sentry from "@sentry/angular-ivy";
import { Router } from "@angular/router";
import { SentryInterceptor } from '../services/sentry.interceptor';

@NgModule({
  declarations: [AppComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SentryInterceptor, multi: true},
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    { provide: ErrorHandler, useValue: Sentry.createErrorHandler({ showDialog: true })},
    { provide: Sentry.TraceService, deps: [Router] },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    { provide: APP_INITIALIZER, useFactory: () => () => {}, deps: [Sentry.TraceService], multi: true },
    provideNgxMask()
  ],
  bootstrap: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,

    NgbDropdownModule,
    NgPipesModule,
    AppPipesModule,
    ToastrModule.forRoot(),
    GoogleMapsModule,
    NgxSkeletonLoaderModule,
    DragulaModule.forRoot(),

    NgbTooltip,
    AppSpinnerDirective,
    AppCopyDirective,
    NgxMaskDirective,

    NavMenuComponent,
    HeaderComponent,
  ]
})
export class AppModule { }
