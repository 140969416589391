  <div class="action-container">
  <i class="icon ic-md ic-eye" (click)="openAlert()"></i>

  <div ngbDropdown container="body" [placement]="['bottom-end', 'bottom-start', 'top-start', 'top-end']" class="dropdown d-inline-flex">
    <i class="icon ic-md ic-three-dots" ngbDropdownToggle></i>

    <div ngbDropdownMenu aria-labelledby="bulk-actions-dd" class="dropdown-menu dropdown-menu-right">
      @if(!isRead) {
        <div class="dropdown-item" (click)="markRead()">
          <a class="dropdown-button">
            <i class="icon ic-circle-check"></i>
            <span>Mark as Read</span>
          </a>
        </div>
      }

      @if(!needAttention) {
        <div class="dropdown-item" (click)="markRequireAttention()">
          <a class="dropdown-button">
            <i class="icon ic-circle-info"></i>
            <span>Mark as Require Attention</span>
          </a>
        </div>
      }

      @if(status !== AlertStatus.Archived) {
        <div class="dropdown-item" (click)="archive()">
          <a class="dropdown-button">
            <i class="icon ic-archive"></i>
            <span>Archive</span>
          </a>
        </div>
      }

      @if(status !== AlertStatus.Ignored && allowChangeToIgnoredOrPostponed) {
        <div class="dropdown-item" (click)="ignore()">
          <a class="dropdown-button">
            <i class="icon ic-ignore"></i>
            <span>Ignore</span>
          </a>
        </div>
      }

      @if(status !== AlertStatus.Postponed && allowChangeToIgnoredOrPostponed) {
        <div ngbDropdown [placement]="['bottom-end', 'bottom-start', 'top-start', 'top-end']" class="dropdown">
          <a ngbDropdownToggle class="dropdown-button pad-18">
            <i class="icon ic-clock"></i>
            <span>Postpone</span>
            <i class="icon ic-xs ic-chevron-down"></i>
          </a>

          <div ngbDropdownMenu aria-labelledby="bulk-actions-dd" class="dropdown-menu dropdown-menu-right">
            @for(item of postponeMenu; track $index) {
              <div class="dropdown-item" (click)="postpone(item.value)">
                <a class="dropdown-button">
                  <span>{{item.label}}</span>
                </a>
              </div>
            }
          </div>
        </div>
      }

      @if(showDownloadBtn) {
        <div class="dropdown-item" (click)="download()">
          <a class="dropdown-button">
            <i class="icon ic-download-file"></i>
            <span>Download</span>
          </a>
        </div>
      }
    </div>
  </div>
</div>
