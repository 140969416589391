import { enumToSelectOptions } from "../../functions/enums-to-list-formatter";
import { stateLabelsDictionary } from "../../app/constants/au-states.constant";
import { SelectOption } from "../selectOptions";

export enum TrustStatus {
  Active,
  Inactive,
  Archived,
  Vested
}

export enum TrustType {
  Discretionary,
  Unit
}

export enum TrustSubType {
  Discretionary,
  DiscretionaryCapitalReserved,
  DiscretionaryFullyReserved,
  Fixed,
  NonFixed,
  NSWLandTax,
  Unit
}

export enum JurisdictionType {
  NSW,
  VIC,
  QLD,
  TAS,
  WA,
  SA,
  ACT,
  NT
}

export const TrustTypesLabelsDictionary: Record<TrustType, string> = {
  [TrustType.Discretionary]: 'Discretionary Trust',
  [TrustType.Unit]: 'Unit Trust',
};

export const TrustSubtypesLabelsDictionary: Record<TrustSubType, string> = {
  [TrustSubType.Discretionary]: 'Discretionary Trust',
  [TrustSubType.DiscretionaryCapitalReserved]: 'Discretionary Trust (Capital Reserved Lineal Descendant)',
  [TrustSubType.DiscretionaryFullyReserved]: 'Discretionary Trust (Fully Reserved Lineal Descendant)',
  [TrustSubType.Fixed]: 'Fixed Unit Trust',
  [TrustSubType.NSWLandTax]: 'NSW Land Tax Unit Trust',
  [TrustSubType.Unit]: 'Unit Trust',
  [TrustSubType.NonFixed]: 'Non Fixed Unit Trust',
};

export const JurisdictionTypeOptions: SelectOption[] = enumToSelectOptions(JurisdictionType, false).map((option) => ({
  value: option.label,
  label: stateLabelsDictionary[option.label]
}));
