<div class="modal-header">
  <div class="left">
    @if (icon) {
      <i class="ic-form ic-md icon" [ngClass]="icon"></i>
    }

    <div class="title">
      <h2 class="h2-heading mb-0" [title]="header">{{ header }}</h2>
      <p class="sub-heading" [title]="subheader">{{ subheader }}</p>
    </div>
  </div>

  <div class="right">
    <app-ag-action-icon-button iconClass="ic-close" (action)="onClose()"/>
  </div>
</div>

<div class="modal-body">
  <form (ngSubmit)="onConfirm()">
    <ng-content></ng-content>
    <button type="submit" hidden></button>
  </form>
</div>

@if(isCustomFooter) {
  <ng-content select=".custom-footer"></ng-content>
} @else {
  <div class="modal-footer" [ngClass]="footerClass">
    <div class="controls">
      <app-button
        btnColor="white"
        [isDisabled]="isLoading"
        [text]="closeText"
        (clickBtn)="onClose()"
      />

      @if (confirmText) {
        <app-button
          btnColor="orange"
          [loading]="isLoading"
          [isDisabled]="isLoading || isInvalid"
          [text]="confirmText"
          (clickBtn)="onConfirm()"
        />
      }
    </div>

    <ng-content select=".confirm-button"></ng-content>
  </div>
}
