<div
  class="status-tag"
  [ngClass]="{
    'active': UserStatus.Active === status,
    'inactive': UserStatus.Inactive === status,
    'archived': UserStatus.Archived === status,
  }"
>
  <span>{{ UserStatus[status] }}</span>
</div>
