<div>
  @if (openedOfficeholder) {
    <app-officeholder-profile
      [disableControls]="disableControls"
      [officeholder]="openedOfficeholder"
      [pendingButtonState]="pendingButtonState"
      [openedPreviousOfficeholder]="openedPreviousOfficeholder"
      (profileViewChange)="profileViewChange.emit($event)"
      (ceaseRegisteredAgent)="ceaseRegisteredAgent.emit($event)"
      (companyChangePartyName)="companyChangePartyName.emit($event)"
      (changeOfficerAddress)="changeOfficerAddress.emit($event)"
      (editOfficer)="editOfficer.emit($event)"
    ></app-officeholder-profile>
  } @else {
    <div class="card-body">
      <app-list-grid
        [rowData]="officeholderRows"
        [colDefs]="$any(colDefs)"
        [rowSelection]="rowSelection"
        [suppressRowClickSelection]="true"
        [suppressCellFocus]="true"
        [pagination]="true"
        [paginationPageSize]="defaultPageSize"
        [paginationPageSizeSelector]="true"
        (select)="onSelectOfficeholders($event)"
      ></app-list-grid>
    </div>
  }
</div>
