import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, computed, effect, EventEmitter, Input, input, Output } from '@angular/core';

@Component({
  selector: 'app-common-offcanvas',
  standalone: true,
  imports: [],
  templateUrl: './common-offcanvas.component.html',
  styleUrl: './common-offcanvas.component.scss',
  animations: [
    trigger('expandOffcanvasAnimation', [
      state('alert-collapsed, alert-void', style({ width: '0px' })),
      state('alert-expanded', style({ width: '*' })),
      transition('alert-expanded <=> alert-collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('alert-expanded <=> alert-void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('fadeAnimation', [
      state('transparent', style({ opacity: 0 })),
      state('opaque', style({ opacity: 0.24 })),
      transition('opaque <=> transparent', [animate('225ms')])
    ]),
    trigger('fadeAnimation', [
      state('transparent', style({ with: '0px' })),
      state('opaque', style({ opacity: '*' })),
      transition('opaque <=> transparent', [animate('225ms')])
    ]),
  ]
})
export class CommonOffcanvasComponent {
  opened = input<boolean>(false);

  expandOffcanvasAnimation = computed(() => this.opened() ? 'alert-expanded' : 'alert-collapsed');
  fadeAnimation = computed(() => this.opened() ? 'opaque' : 'transparent');

  @Input() width = '620px';
  @Output() close = new EventEmitter<void>();

  closeOffcanvas(): void {
    this.close.emit();
  }
}
