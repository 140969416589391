import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export function asicDocumentNumberValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value: string = control.value;
    const asicDocumentNumberPattern: RegExp = /^[a-zA-Z0-9]{9}$/;

    if (!asicDocumentNumberPattern.test(value)) {
      return { 'asicDocumentNumber': true };
    }

    return null;
  };
}

export function asicDocumentNumberValidators(): ValidatorFn[] {
  return [
    Validators.required,
    Validators.minLength(9),
    Validators.maxLength(9),
    asicDocumentNumberValidator()
  ];
}

export const documentNumberCustomError = {
  asicDocumentNumber: 'Invalid ASIC document number. An ASIC document number is nine digits and can include letters or numbers'
};
