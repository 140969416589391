import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

export type DisclaimerColors = 'orange' | 'gray' | 'red';
export type DisclaimerIcons = 'ic-alert-triangle' | 'ic-info-circle' | 'ic-circle-cancel-20';

@Component({
  selector: 'app-disclaimer',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './disclaimer.component.html',
  styleUrl: './disclaimer.component.scss'
})
export class DisclaimerComponent {
  @Input() icon: DisclaimerIcons = 'ic-alert-triangle';
  @Input() color: DisclaimerColors = 'orange';
}
