@if(type !== AnnualSignGroup.CarbonCopy) {
  <app-input-phone-number
    #input
    class="ag-input-field-input ag-text-field-input"
    [label]="''"
    [placeholder]="'Enter phone *'"
    [disabled]="disabled"
    [validationError]="validationError"
    [usedInsideGrid]="true"
    [(ngModel)]="value"
    (ngModelChange)="onChangeValue($event)"
  />
}

