import { AnnualStatementEmailPayload } from "./payloads/AnnualStatementEmailPayload";
import { AnnualStatementEsignEmailPayload } from "./payloads/AnnualStatementEsignEmailPayload";
import { AnnualStatementDebtReminderEmailPayload } from "./payloads/AnnualStatementDebtReminderEmailPayload";
import { AsicDebtReminderEmailPayload } from "./payloads/AsicDebtReminderEmailPayload";
import { AsicDocumentsEmailPayload } from "./payloads/AsicDocumentsEmailPayload";
import { AsicDocumentsEsignEmailPayload } from "./payloads/AsicDocumentsEsignEmailPayload";
import { AsicDocumentsSignReminderEmailPayload } from "./payloads/AsicDocumentsSignReminderEmailPayload";
import { AnnualStatementSignReminderEmailPayload } from "./payloads/AnnualStatementSignReminderEmailPayload";
import { AnnualStatementEsignReminderEmailPayload } from "./payloads/AnnualStatementEsignReminderEmailPayload";
import { AsicDocumentsEsignReminderEmailPayload } from "./payloads/AsicDocumentsEsignReminderEmailPayload";
import { AsicDocumentsEsignCompletedEmailPayload } from "./payloads/AsicDocumentsEsignCompletedEmailPayload";
import { AsicDocumentsReviewEmailPayload } from "./payloads/AsicDocumentsReviewEmailPayload";
import { DirectorIdNotificationEmailPayload } from "./payloads/DirectorIdNotificationEmailPayload";
import { EmailTemplate } from "./emailTemplate";
import { EmailPreviewRequest } from "./emailPreviewRequest";
import { EmailPayloadBase } from "./emailPayloadBase";
import { EmailSendRequest } from "./emailSendRequest";
import {
  AnnualStatementFeeReminderTextMessagePayload
} from "../text-messages/payload/AnnualStatementFeeReminderTextMessagePayload";
import {
  AnnualStatementSigningReminderTextMessagePayload
} from "../text-messages/payload/AnnualStatementSigningReminderTextMessagePayload";
import {
  AsicDocumentSigningReminderTextMessagePayload
} from "../text-messages/payload/AsicDocumentSigningReminderTextMessagePayload";
import { CompanyDebtReminderTextMessagePayload } from "../text-messages/payload/CompanyDebtReminderTextMessagePayload";
import { TemplateCodePreview, TemplateCodePreviewItem } from "../text-messages/textMessagesHelper";
import { UserInviteEmailPayload } from "./payloads/UserInviteEmailPayload";

export class EmailTemplatesHelper {
  static TemplateHeadersRecord: Record<string, string> = {
    'as-email': 'Annual Statement (Email)',
    'as-esign': 'E-signing Annual Statement',
    'as-debt-reminder': 'Annual Statement Debt Reminder',
    'asic-debt-reminder': 'Debt Reminder',
    'asic-documents': 'ASIC Form(s) and Documents (Email)',
    'asic-documents-esign': 'E-signing ASIC Form(s) and Documents',
    'asic-documents-sign-reminder': 'ASIC Form(s) and Documents Signing Reminder (Email)',
    'as-sign-reminder': 'Annual Statement Signing Reminder (Email)',
    'as-esign-reminder': 'Annual Statement  E-signing Reminder',
    'asic-documents-esign-reminder': 'ASIC Form(s) and Documents E-signing Reminder',
    'asic-documents-esign-completed': 'Completed E-signing Documents',
    'asic-documents-review': 'Document Review',
    'director-id-notification': 'Director ID Notification',
    'user-invite': 'User Invitation',
  };

  static emailTemplateCodesRecord: Record<string, TemplateCodePreviewItem> = {
    organisationName: { key: '{{OrganisationName}}', description: 'Account name' },
    organisationEmail: { key: '{{OrganisationEmail}}', description: 'Account email address' },
    organisationPhone: { key: '{{OrganisationPhone}}', description: 'Phone number' },
    tenDaysBeforeLodgementDeadline: { key: '{{TenDaysBeforeLodgementDeadline}}', description: 'A date that is ten days before the actual lodgement deadline' },
    tenDaysBeforeLodgementDeadlineShortFormat: { key: '{{TenDaysBeforeLodgementDeadlineShortFormat}}', description: 'A date that is ten days before the actual lodgement deadline' },
    documentName: { key: '{{DocumentName}}', description: 'Type of ASIC Form that was sent to the director(s)' },
    lodgementDeadline: { key: '{{LodgementDeadline}}', description: 'Lodgement deadline of the ASIC form' },
    partner: { key: '{{Partner}}', description: 'Name of the Partner assigned to the company' },
    asicContact: { key: '{{AsicContact}}', description: 'Name of the assigned ASIC contact in the Agent Details section' },
    accountManager: { key: '{{AccountManager}}', description: 'Name of the Account Manager' },
    billingContactFirstName: { key: '{{BillingContactFirstName}}', description: 'First name of the billing contact assigned in the company profile' },
    billingContactFullName: { key: '{{BillingContactFullName}}', description: 'Full name of the billing contact assigned in the company profile' },
    payUrl: { key: '{{PayUrl}}', description: 'The “Pay Now” button should route end users to the AusPost Billpay page' },
    companyName: { key: '{{CompanyName}}', description: 'Name of company' },
    companyACN: { key: '{{CompanyACN}}', description: 'ACN' },
    annualReviewFee: { key: '{{AnnualReviewFee}}', description: 'Annual review fee' },
    annualReviewFeeDeadline: { key: '{{AnnualReviewFeeDeadline}}', description: 'Payment deadline for the annual review fee' },
    annualReviewFeeDeadlineShortFormat: { key: '{{AnnualReviewFeeDeadlineShortFormat}}', description: 'Annual review fee due date' },
    debt: { key: '{{Debt}}', description: 'Debt in the company profile page' },
    dueDate: { key: '{{dueDate}}', description: 'Due date of the ASIC fee' },
    dueDateShortFormat: { key: '{{DueDateShortFormat}}', description: 'Due date of the ASIC fee' },
    directorFirstName: { key: '{{DirectorFirstName}}', description: 'First name of the director who needs to sign the docs' },
    directorFullName: { key: '{{DirectorFullName}}', description: 'Full name of the director who needs to sign the docs' },
    documentType: { key: '{{DocumentType}}', description: 'Type of document' },
    lodgementDeadlineShortFormat: { key: '{{LodgementDeadlineShortFormat}}', description: 'Lodgement deadline of the ASIC form' },
    year: { key: '{{Year}}', description: 'The year the annual statement was issued by ASIC' },
    lateFeeForOneMonth: { key: '{{LateFeeForOneMonth}}', description: 'Late fee for up to one month late' },
    lateFeeForMoreThanOneMonth: { key: '{{LateFeeForMoreThanOneMonth}}', description: 'Late fee for more than one month' },
    username: { key: '{{Username}}', description: 'Name of the Bolt user who sends the annual statement' },
    signUrl: { key: '{{SignUrl}}', description: 'The “Sign Now” button should route the user to the page where to sign the documents' },
    reviewerName: { key: '{{ReviewerName}}', description: 'Name of the Approver for sent documents' },
    reviewUrl: { key: '{{ReviewUrl}}', description: 'The “Review” button should route the user to the page where to review the sent documents' },
    companyNames: { key: '{{CompanyNames}}', description: 'Names of companies that person is a director for' },
    firstName: { key: '{{FirstName}}', description: 'First name of the user who receives the invitation' },
    fullName: { key: '{{FullName}}', description: 'First name of the user who receives the invitation' },
    loginUrl: { key: '{{LoginUrl}}', description: 'The “Login” button should route the user to the page where to fill he\'s data.' },
  };

  static buildEmailSendRequest(template: EmailTemplate, to = ''): EmailSendRequest {
    const payloadModel = EmailTemplatesHelper.buildEmailTemplatePayload(template.code);
    return new EmailSendRequest({ template, payloadModel, to });
  }

  static buildEmailPreviewRequest(template: EmailTemplate): EmailPreviewRequest {
    const payloadModel = EmailTemplatesHelper.buildEmailTemplatePayload(template.code);
    return new EmailPreviewRequest({ template, payloadModel });
  }

  static buildEmailTemplatePayload(code: string): EmailPayloadBase {
    const payload = EmailTemplateTypesToModels[EmailTemplateCodesToTypes[code]](fullMockedData);
    if (!payload) throw new Error(`No payload model for code "${ code }"`);
    return payload;
  }

  static buildPreview(payload: EmailPayloadBase): TemplateCodePreview {
    try {
      const templateCodePreview: TemplateCodePreview = [];
      Object.keys(payload).forEach((key) => {
        if (this.emailTemplateCodesRecord[key])
          templateCodePreview.push(this.emailTemplateCodesRecord[key]);
      });

      return templateCodePreview;
    } catch (e) {
      return [];
    }
  }

  static replaceNewLine(text: string | null): string {
    return text?.replaceAll('\n', '</br>') ?? '';
  }

  static prepareTemplateString(text: string | null): string {
    return (text ?? '')
      .replaceAll('\n', '')
      .replaceAll('<br />', '\n')
      .replaceAll('</br>', '\n');
  }
}

export type IEmailTemplateMockedData =
  AnnualStatementEmailPayload
  & AnnualStatementEsignEmailPayload
  & AnnualStatementDebtReminderEmailPayload
  & AsicDebtReminderEmailPayload
  & AsicDocumentsEmailPayload
  & AsicDocumentsEsignEmailPayload
  & AsicDocumentsSignReminderEmailPayload
  & AnnualStatementSignReminderEmailPayload
  & AnnualStatementEsignReminderEmailPayload
  & AsicDocumentsEsignReminderEmailPayload
  & AsicDocumentsEsignCompletedEmailPayload
  & AsicDocumentsReviewEmailPayload
  & DirectorIdNotificationEmailPayload
  & AnnualStatementFeeReminderTextMessagePayload
  & AnnualStatementSigningReminderTextMessagePayload
  & AsicDocumentSigningReminderTextMessagePayload
  & CompanyDebtReminderTextMessagePayload
  & UserInviteEmailPayload;

export const fullMockedData: IEmailTemplateMockedData = {
  $type: 'EmailTemplate',
  organisationName: 'Sample Organisation',
  organisationEmail: 'sample@organisation.com',
  organisationPhone: '+1234567890',

  companyName: 'Test Company Pty Ltd',
  companyACN: '123456789',
  directorFirstName: 'John',
  directorFullName: 'John Doe',
  year: new Date().getFullYear().toString(),
  annualReviewFee: '28$',
  //01 January 2024 format
  annualReviewFeeDeadline: '01 January 2024',
  //01/01/2024 format
  annualReviewFeeDeadlineShortFormat: '01/01/2024',
  dueDate: '01/01/2024',
  dueDateShortFormat: '01/01/2024',
  debt: '1000.00',
  lateFeeForOneMonth: '50.00',
  lateFeeForMoreThanOneMonth: '100.00',
  username: 'johndoe123',
  partner: 'Partner Name',
  asicContact: 'ASIC Contact',
  accountManager: 'Account Manager',
  billingContactFirstName: 'Jane',
  billingContactFullName: 'Jane Smith',
  payUrl: 'https://example.com/pay',
  firstName: 'Jane',
  fullName: 'Jane Smith',
  loginUrl: 'https://example.com/pay',

  documentName: 'Test Document',
  lodgementDeadline: '31 December 2024',
  lodgementDeadlineShortFormat: '31/12/2024',
  tenDaysBeforeLodgementDeadline: '21 December 2024',
  tenDaysBeforeLodgementDeadlineShortFormat: '21/12/2024',
  signUrl: 'https://example.com/sign',

  reviewerName: 'Reviewing Officer',
  reviewUrl: 'https://example.com/review',

  companyNames: ['Company A', 'Company B'],
  documentType: 'c:362'
};

export const EmailTemplateCodesToTypes: Record<string, string> = {
  ['as-email']: 'AnnualStatementEmailPayload',
  ['as-esign']: 'AnnualStatementEsignEmailPayload',
  ['as-debt-reminder']: 'AnnualStatementDebtReminderEmailPayload',
  ['asic-debt-reminder']: 'AsicDebtReminderEmailPayload',
  ['asic-documents']: 'AsicDocumentsEmailPayload',
  ['asic-documents-esign']: 'AsicDocumentsEsignEmailPayload',
  ['asic-documents-sign-reminder']: 'AsicDocumentsSignReminderEmailPayload',
  ['as-sign-reminder']: 'AnnualStatementSignReminderEmailPayload',
  ['as-esign-reminder']: 'AnnualStatementEsignReminderEmailPayload',
  ['asic-documents-esign-reminder']: 'AsicDocumentsEsignReminderEmailPayload',
  ['asic-documents-esign-completed']: 'AsicDocumentsEsignCompletedEmailPayload',
  ['asic-documents-review']: 'AsicDocumentsReviewEmailPayload',
  ['director-id-notification']: 'DirectorIdNotificationEmailPayload',
  ['user-invite']: 'UserInviteEmailPayload',
};

export const EmailTemplateTypesToModels: Record<string, (data: Partial<EmailPayloadBase>) => EmailPayloadBase> = {
  [AnnualStatementEmailPayload.$type]: (data) => new AnnualStatementEmailPayload(data),
  [AnnualStatementEsignEmailPayload.$type]: (data) => new AnnualStatementEsignEmailPayload(data),
  [AnnualStatementDebtReminderEmailPayload.$type]: (data) => new AnnualStatementDebtReminderEmailPayload(data),
  [AsicDebtReminderEmailPayload.$type]: (data) => new AsicDebtReminderEmailPayload(data),
  [AsicDocumentsEmailPayload.$type]: (data) => new AsicDocumentsEmailPayload(data),
  [AsicDocumentsEsignEmailPayload.$type]: (data) => new AsicDocumentsEsignEmailPayload(data),
  [AsicDocumentsSignReminderEmailPayload.$type]: (data) => new AsicDocumentsSignReminderEmailPayload(data),
  [AnnualStatementSignReminderEmailPayload.$type]: (data) => new AnnualStatementSignReminderEmailPayload(data),
  [AnnualStatementEsignReminderEmailPayload.$type]: (data) => new AnnualStatementEsignReminderEmailPayload(data),
  [AsicDocumentsEsignReminderEmailPayload.$type]: (data) => new AsicDocumentsEsignReminderEmailPayload(data),
  [AsicDocumentsEsignCompletedEmailPayload.$type]: (data) => new AsicDocumentsEsignCompletedEmailPayload(data),
  [AsicDocumentsReviewEmailPayload.$type]: (data) => new AsicDocumentsReviewEmailPayload(data),
  [DirectorIdNotificationEmailPayload.$type]: (data) => new DirectorIdNotificationEmailPayload(data),
  [UserInviteEmailPayload.$type]: (data) => new UserInviteEmailPayload(data),
};
