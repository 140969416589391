import { CurrencyPipe } from '@angular/common';
import { booleanAttribute, Component, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { SecurityHolding } from '../../../../../../models/securityHolding';
import { ShareClassTypeLabelComponent } from './share-class-type-label/share-class-type-label.component';

@Component({
  selector: 'app-ag-share-class-type',
  standalone: true,
  imports: [
    CurrencyPipe,
    ShareClassTypeLabelComponent
  ],
  templateUrl: './ag-share-class-type.component.html',
})
export class AgShareClassTypeComponent implements ICellRendererAngularComp {
  @Input({ transform: booleanAttribute }) hideQuantity = false;
  @Input({ transform: booleanAttribute }) hidePrice = false;
  shares!: SecurityHolding[];

  agInit(params: ICellRendererParams): void {
    this.shares = params.data.holdings as SecurityHolding[] ?? [];
    this.hideQuantity = params?.['hideQuantity'] ?? false;
    this.hidePrice = params?.['hidePrice'] ?? false;
  }

  refresh(params: ICellRendererParams): boolean {
    this.shares = params.data.holdings as SecurityHolding[] ?? [];
    this.hideQuantity = params?.['hideQuantity'] ?? false;
    this.hidePrice = params?.['hidePrice'] ?? false;
    return true;
  }
}
