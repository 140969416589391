<ul class="steps-list">
  @for (step of steps;
    track step;
    let last = $last;
    let index = $index;
    ) {
    <li
      class="step"
      [class.current]="index === currentStepIndex"
      [class.filled]="index < currentStepIndex"
      [title]="step.label"
    >
      <button
        tabIndex="-1"
        class="step-button"
        [disabled]="disableSteps || step.disabled"
        (click)="onStepChange(index)"
      >
        <div class="marker"></div>
        <div>
          <div class="step-number">Step {{ index + 1 }}</div>
          <div class="step-label">{{ step.label }}</div>
        </div>
      </button>
    </li>

    @if (!last) {
      <li class="arrow">
        <i class="ic-arrow-right ic-md icon"></i>
      </li>
    }
  }
</ul>
