import { NgClass } from '@angular/common';
import { booleanAttribute, Component, EventEmitter, inject, Input, Optional, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, FormsModule, NgControl } from '@angular/forms';
import { FormSwitchButtonComponent } from '../form-switch-button/form-switch-button.component';
import { LinkComponent } from '../link/link.component';
import { ValidationErrorComponent } from '../validation-error/validation-error.component';

@Component({
  selector: 'app-masked-input',
  standalone: true,
  imports: [
    FormsModule,
    FormSwitchButtonComponent,
    LinkComponent,
    ValidationErrorComponent,
    NgClass
  ],
  templateUrl: './masked-input.component.html',
  styleUrls: ['../input/input.component.scss'],
})
export class MaskedInputComponent implements ControlValueAccessor {
  @Optional() protected ngControl = inject(NgControl);

  @Input() mask = '';
  @Input() placeholder = '';
  @Input() label = '';
  @Input() link = '';
  @Input() text = '';
  @Input() switchBtnText = '';
  @Input() helperText = '';
  @Input() shouldShowErrors = true;
  @Input({ transform: booleanAttribute }) readonly = false;
  @Input({ transform: booleanAttribute }) validationError = false;
  @Input({ transform: booleanAttribute }) checkedState = false;
  @Input({ transform: booleanAttribute }) warningState = false;
  @Input() customErrors: Record<string, string> = {};
  @Output() switchBtnClick = new EventEmitter<boolean>();
  @Output() focusin = new EventEmitter<FocusEvent>();

  disabled = false;
  #value = '';

  constructor() {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  onClickBtn(): void {
    this.switchBtnClick.emit(true);
  }

  get value(): string {
    return this.#value;
  }

  set value(value: string) {
    this.#value = value;
    this.onChange(this.#value?.replace(/\D/g, ''));
  }

  get displayValidationError(): boolean {
    return !!(this.ngControl && this.ngControl.touched && this.ngControl.errors);
  }

  onChange: any = (): void => {
  };
  onTouched: any = (): void => {
  };

  writeValue(value: any): void {
    if (value) {
      this.value = this.applyMask(value);
    } else {
      this.value = value;
    }
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onInput(event: any): void {
    let value = event.target.value;
    const cursorPosition = this.mask.charAt(event.target.selectionStart) === '_'
      ? event.target.selectionStart
      : event.target.selectionStart + 1;

    // Remove non-digit characters
    value = value?.replace(/\D/g, '');

    const isUserDeleteCharacter = event.data === null && (event.inputType === 'deleteContentBackward' || event.inputType === 'deleteContentForward');
    if (isUserDeleteCharacter) {
      this.value = event.target.value;
    } else {
      // Apply mask only if user is writing a character
      const maskedValue = this.applyMask(value);

      // Update input value and cursor position
      this.value = maskedValue;
      event.target.value = maskedValue;
      event.target.setSelectionRange(cursorPosition, cursorPosition);
    }
  }

  applyMask(value: string): string {
    return MaskedInputComponent.applyMask(value, this.mask);
  }

  get control(): FormControl {
    return this.ngControl.control as FormControl;
  }

  static applyMask(value: string, mask: string): string {
    let maskedValue = '';
    let index = 0;

    for (let i = 0; i < mask.length; i++) {
      if (mask.charAt(i) === '_') {
        if (index < value.length) {
          maskedValue += value.charAt(index++);
        } else {
          maskedValue += '_';
        }
      } else {
        maskedValue += mask.charAt(i);
      }
    }

    return maskedValue ?? '';
  }
}
