<div class="select-container" [style.width]="width">
  <div class="header">
    @if (label) {
      <div class="label">{{ label }}</div>
    }

    @if (link && text) {
      <div>
        <app-link [link]="link" [text]="text"></app-link>
      </div>
    }
  </div>

  @if (isDisabled) {
    <div class="dropdown">
      <button
        type="button"
        class="input-wrapper input"
        [ngClass]="{'opened': dropdownOpen}"
        [disabled]="isDisabled"
      >
        @if (value !== null && value !== undefined && value >= 0) {
          <ng-container
            *ngTemplateOutlet="shareholderCard; context: {securityRegistryRecord: securityRegistryRecords[value]}"></ng-container>
        } @else {
          <div class="select-placeholder">{{ placeholder }}</div>
        }
        <span class="icon ic-select-icon"></span>
      </button>
    </div>
  } @else {
    <div ngbDropdown class="dropdown" (openChange)="getDropdownState($event)">
      <button
        type="button"
        class="input-wrapper input"
        [ngClass]="{'opened': dropdownOpen}"
        [disabled]="isDisabled"
        ngbDropdownToggle
      >
        @if (value !== null && value !== undefined) {
          <ng-container
            *ngTemplateOutlet="shareholderCard; context: {securityRegistryRecord: securityRegistryRecords[value]}"></ng-container>
        } @else {
          <div class="select-placeholder">{{ placeholder }}</div>
        }
        <span class="icon ic-select-icon"></span>
      </button>
      <div ngbDropdownMenu class="dropdown-menu">
        @if (securityRegistryRecords.length) {
          @for (securityRegistryRecord of securityRegistryRecords; track securityRegistryRecord; let index = $index) {
            <button
              ngbDropdownItem
              type="button"
              class="dropdown-item"
              [class.selected]="value === index"
              [disabled]="isDisabled"
              (click)="selectOption(index)"
            >
              <div class="dropdown-button">
                <ng-container *ngTemplateOutlet="shareholderCard; context: { securityRegistryRecord }"></ng-container>
                <span class="icon ic-circle-check"></span>
              </div>
            </button>
          }
        } @else {
          <div class="no-options">{{ noOptionsText }}</div>
        }
      </div>
    </div>
  }

  @if (helperText) {
    <div class="helper">{{ helperText }}</div>
  }

  @if (displayValidationError) {
    <app-validation-error></app-validation-error>
  }
</div>

<ng-template #shareholderCard let-securityRegistryRecord="securityRegistryRecord">
  <div class="shareholder-card">
    @for (holder of securityRegistryRecord?.holders; track holder; let last = $last) {
      <app-security-holder-icon-label [holder]="holder"></app-security-holder-icon-label>
    }
  </div>
</ng-template>
