import { FilterOption } from "../../models/gridFilter";
import { CompanyStatus } from "../../models/enums/companyEnums";

export function statusFilterOptions(activeOptions?: string[]): FilterOption[] {
  return Object.values(CompanyStatus)
    .filter((status) => typeof status === 'string')
    .map((statusName) => ({
      label: (statusName as string).charAt(0).toUpperCase() + (statusName as string).slice(1).replace(/-/g, ' '),
      value: (CompanyStatus[statusName] as number).toString(),
      active: activeOptions?.includes(statusName as string) ?? false
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
}
