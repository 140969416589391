import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { SelectComponent } from "../../components/common/select/select.component";
import { AnnualStatement } from "../../../models/annualStatement";
import { Company } from "../../../models/company";
import { SelectOption } from "../../../models/selectOptions";
import {catchError, filter, forkJoin, Observable, of, Subject, switchMap, tap} from "rxjs";
import { DocumentSelectionComponent } from "../../components/document-selection/document-selection.component";
import { DocumentSelection } from "../../../models/document-selection";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { DocumentsService } from "../../../services/documents.service";
import { DocumentationType } from "../../../models/enums/documentConfirmEnums";
import { CoverLettersService } from "../../../services/cover-letters.service";
import { CoverLetter } from "../../../models/cover-letter";
import { AnnualStatementsService } from "../../../services/annual-statements.service";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { ToastrService } from "ngx-toastr";
import { formatDate } from "@angular/common";

@Component({
  selector: 'app-annual-generate-documents',
  standalone: true,
  templateUrl: './annual-generate-documents.component.html',
  imports: [
    SelectComponent,
    FormsModule,
    DocumentSelectionComponent,
    NgxSkeletonLoaderModule
  ],
  providers: [CoverLettersService],
  styleUrl: './annual-generate-documents.component.scss'
})
export class AnnualGenerateDocumentsComponent implements OnInit {
  @Input() annual: AnnualStatement | undefined;
  @Input() company!: Company;
  @Input() confirmAction$: Subject<boolean> | undefined;
  @Output() nextStep = new EventEmitter<boolean>();
  @Output() updateAnnualDocuments= new EventEmitter<boolean>();

  private annualStatementsService = inject(AnnualStatementsService);
  private documentsService = inject(DocumentsService);
  private coverLetterService = inject(CoverLettersService);
  private toastr = inject(ToastrService);
  private destroyRef= inject(DestroyRef);

  DocumentationType = DocumentationType;

  coverLetterTemplateOptions: SelectOption[] = [];

  solvencyTemplateOptions: SelectOption[] = [
    { label: 'Solvency Resolution', value: '1' },
    { label: 'Solvency Minutes', value: '2' }
  ];

  selectedCoverLetterId = '';
  solvencyTemplate = '';
  documentSelection: DocumentSelection[] | undefined;
  coverLetters: CoverLetter[] = [];
  documentationType: DocumentationType | undefined;
  loading = false;

  ngOnInit(): void {
    this.annualStatementsService.disabledHeaderBtn.set(true);
    this.loadData();
    this.listenHeaderButton();
    this.documentationType = this.annual?.changeAuthorisation?.documentationType;
  }

  onCoverLetterSelect(coverLetter: string): void {
    this.selectedCoverLetterId = coverLetter;
    this.annualStatementsService.disabledHeaderBtn.set(!this.isValidForm());
  }

  onSolvencySelect(solvency: string): void {
  }

  private loadData(): void {
    this.loading = true;

    const documentsSelection$: Observable<DocumentSelection[]> = this.documentsService
      .getDocumentsSelection(this.annual?.documentId ?? '').pipe(
        catchError((err) => {
          console.error(err);
          return of([]);
        }
      )
    );

    const coverLetters$: Observable<CoverLetter[]> = this.coverLetterService.loadCoverLetters().pipe(
      catchError((err) => {
        console.error(err);
        return of([]);
      })
    );

    forkJoin([documentsSelection$, coverLetters$]).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(([documentsSelection, coverLetters]) => {
      this.documentSelection = [...documentsSelection];
      this.coverLetters = [...coverLetters];
      this.coverLetterTemplateOptions = coverLetters?.map(coverLetter => ({
        label: coverLetter.title,
        value: coverLetter.id
      }));
      this.selectedCoverLetterId = this.annual?.changes[0]?.coverLetterId ?? '';
      this.annualStatementsService.disabledHeaderBtn.set(!this.isValidForm());
      this.loading = false;
    });
  }

  public onDocumentSelectionChange(documentSelections: DocumentSelection[]): void {
    this.documentSelection = documentSelections;
    this.annualStatementsService.disabledHeaderBtn.set(!this.isValidForm());
  }

  private isValidForm(): boolean {
    return !!(this.selectedCoverLetterId && this.documentSelection?.some(d => d.included));
  }

  private listenHeaderButton(): void {
    this.confirmAction$?.pipe(
      filter(confirm => confirm),
      switchMap(() => this.generateAnnualStatement()),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((res) => {
      if (res !== 'ERR') {
        this.updateAnnualDocuments.emit(true);
        this.nextStep.emit(true);
        this.confirmAction$?.next(false);
      }
    });
  }

  private generateAnnualStatement(): Observable<string> {
    const annual = this.annual;
    const coverLetter = this.coverLetters.find(letter => letter.id === this.selectedCoverLetterId);
    if (annual && coverLetter) {
      annual.changes[0].coverLetterId = coverLetter.id;
      annual.changes[0].coverLetterName = coverLetter.title;
      annual.changes[0].changeDate = formatDate(new Date(), 'YYYY-MM-dd', 'en-US') as unknown as Date;
      annual.changes[0].reviewDate = annual.reviewDate ?? annual.changes[0].reviewDate;

      if (annual.changeAuthorisation && this.documentSelection?.length) {
        annual.changeAuthorisation.documentSelection = { selection: this.documentSelection };
      }

      if(annual.changeAuthorisation?.meetingDate) {
        annual.changeAuthorisation.meetingDate = formatDate(annual.changeAuthorisation?.meetingDate ?? '', 'YYYY-MM-dd', 'en-US') as unknown as Date;
      }

      annual.documentSigning = null;
    }

    return this.annualStatementsService.annualGenerate(annual).pipe(
      tap(() => {
        this.toastr.success("Annual statement document is generated", "Success");
      }),
      catchError((err) => {
        this.toastr.error("Annual statement document generated error", "Error");
        console.error(err);
        return of('ERR');
      }),
    );
  }
}
