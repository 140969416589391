import { EntityType } from '../../../../../models/enums/entityType';
import { TagActionEnum } from './manage-tags-modal.constant';

export class ManageTagRange {
  tagsId: string[];
  entitiesOrIndividualsId: string[];
  tagAction: TagActionEnum;
  type: EntityType;

  constructor(data: Partial<ManageTagRange> = {}) {
    this.tagsId = data.tagsId ?? [];
    this.entitiesOrIndividualsId = data.entitiesOrIndividualsId ?? [];
    this.tagAction = data.tagAction ?? TagActionEnum.Assign;
    this.type = data.type ?? EntityType.Company;
  }
}
