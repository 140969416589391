import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DatepickerHelper } from '../../../../../custom-form-validators/date-picker-form-validators';
import { Company } from '../../../../../models/company';
import { Document } from '../../../../../models/document';
import { EntityChangeData } from '../../../../../models/entityChangeData';
import { CompanyChangeData } from '../../../../../models/enums/companyChangeData';
import { SelectOption } from '../../../../../models/selectOptions';
import { ChangeDictionaryHelper } from '../../../../../models/shared/change-dictionary-helper.model';
import { IPDFSection } from '../../../../../models/shared/pdf-section.interface';
import { Form106WithdrawLodgedDocumentComponent } from './form106-withdraw-lodged-document.component';

export class CompanyWithdrawLodgedDocumentChange extends EntityChangeData {
  static override readonly $type = 'CompanyWithdrawLodgedDocumentChange';
  documentNumber: string;
  documentLodgementDate: Date;
  reason: DocumentWithdrawReason;
  duplicateDocumentNumber: string;
  withdrawalReasonDetails: string;

  changeName = 'Lodged Document Withdrawal';

  constructor(data: Partial<CompanyWithdrawLodgedDocumentChange> = {}) {
    super(data);

    this.documentNumber = data.documentNumber ?? '';
    this.documentLodgementDate = DatepickerHelper.toDate(data.documentLodgementDate);
    this.duplicateDocumentNumber = data.duplicateDocumentNumber ?? '';
    this.withdrawalReasonDetails = data.withdrawalReasonDetails ?? '';

    if (typeof data.reason === 'string') {
      this.reason = DocumentWithdrawReasonOptions.find(option => option.value == data.reason)?.value as DocumentWithdrawReason ?? DocumentWithdrawReason.Duplicated;
    } else {
      this.reason = data.reason ?? DocumentWithdrawReason.Duplicated;
    }
  }

  override toDictionary(): { key: string; value: string; }[] {
    const dict = new ChangeDictionaryHelper();

    dict
      .add('Document Number', this.documentNumber)
      .addDate('Date of Lodgement', this.documentLodgementDate)

    if (typeof +this.reason === 'number') {
      dict.add('Reason', DocumentWithdrawReasonsDictionary[this.reason]);
    } else {
      dict.add('Reason', this.reason);
    }

    dict
      .addIfValueIsNotEmpty('Withdrawal Reason Details', this.withdrawalReasonDetails)
      .addIfValueIsNotEmpty('Document number of previously lodged document', this.duplicateDocumentNumber);

    return dict.dictionary;
  }

  override fillModal(modalRef: NgbModalRef, document: Document, actualCompany: Company): NgbModalRef {
    modalRef = this.fillModalWithCompany(modalRef, actualCompany, true);
    (modalRef.componentInstance as Form106WithdrawLodgedDocumentComponent).companyChangeData.documentId = document.documentId;
    return modalRef;
  }

  fillModalWithCompany(modalRef: NgbModalRef, company: Company, isEdit = false): NgbModalRef {
    const instance = (modalRef.componentInstance as Form106WithdrawLodgedDocumentComponent);

    instance.isEdit = isEdit;
    instance.companyChangeData = new CompanyChangeData(company);
    instance.formModel = structuredClone(this);

    return modalRef;
  }

  override prepareToRequest(): CompanyWithdrawLodgedDocumentChange {
    return {
      ...this,
      ...super.prepareToRequest(),
      documentLodgementDate: DatepickerHelper.buildDateString(this.documentLodgementDate),
    };
  }

  override prepareToPdf(): IPDFSection[] {
    const sectionDict = new ChangeDictionaryHelper();

    sectionDict
      .addSection('Document Details')
      .add('Document Number', this.documentNumber)
      .addDate('Date of lodgement of document being amended', this.documentNumber)

    sectionDict
      .addSection('Amendment Details')
      .add('Reason why this document should be withdrawn', DocumentWithdrawReasonsDictionary[this.reason])
      .add('Document number of previously lodged documents', this.duplicateDocumentNumber)
      .add('Specific Details', this.withdrawalReasonDetails)

    return sectionDict.sections;
  }
}

export enum DocumentWithdrawReason {
  Duplicated = 0,
  Incorrect = 1
}

export const DocumentWithdrawReasonsDictionary: Record<DocumentWithdrawReason, string> = {
  [DocumentWithdrawReason.Duplicated]: 'Duplicate - an identical document has been lodged already',
  [DocumentWithdrawReason.Incorrect]: 'Information on previously lodged document was subsequently found incorrect'
};

export const DocumentWithdrawReasonOptions: SelectOption[] = Object.entries(DocumentWithdrawReasonsDictionary).map(([value, label]) => ({
  label,
  value
}));
