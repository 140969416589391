<div class="select-container">
  <div class="header">
    <div class="label">{{ label }}</div>
  </div>

  <div
    ngbDropdown
    class="dropdown"
    [open]="dropdownOpen"
    (openChange)="onDropdownChange($event)"
  >
    <div
      class="input-wrapper"
      [ngClass]="{'opened': dropdownOpen}"
    >
      <input
        ngbDropdownToggle
        tabindex="0"
        class="input"
        type="text"
        [formControl]="searchControl"
        [placeholder]="placeholder"
        (keydown)="dropdownOpen = true"
        (keydown.escape)="dropdownOpen = false"
      >
      <span ngbDropdownToggle class="icon ic-select-icon input-icon"></span>
    </div>
    <div ngbDropdownMenu class="dropdown-menu">
      @for (option of filteredOptions; track option; let index = $index) {
        <button
          ngbDropdownItem
          type="button"
          class="dropdown-button"
          [class.selected]="option.value === value?.value"
          (click)="selectOption(option, index)"
        >
          <span class="label">{{ option.label }}</span>

          <span class="icon ic-circle-check"></span>
        </button>
      }

      @if (searchControl.value?.length && !filteredOptions.length) {
        <div class="dropdown-button">No options found for "{{ searchControl.value }}".</div>
      }

      @if (!options.length) {
        <div class="dropdown-button">No options.</div>
      }
    </div>
  </div>

  @if (displayValidationError) {
    <app-validation-error></app-validation-error>
  }
</div>
