import { SelectOptionGroup } from "../../../components/common/select-groups/select-groups.component";
import { TrustSubType, TrustSubtypesLabelsDictionary, TrustType } from "../../../../models/enums/trustEnum";

export enum AddTrustSteps {
  TrustInformation = 0
}

export const trustTypesOptionGroups: SelectOptionGroup[] = [
  // - Discretionary Trust
  //    - Discretionary Trust
  //    - Discretionary Trust (Capital Reserved Lineal Descendant)
  //    - Discretionary Trust (Fully Reserved Lineal Descendant)
  // - Unit Trust
  //    - Fixed Unit Trust
  //    - NSW Land Tax Unit Trust
  //    - Unit Trust
  {
    name: 'Discretionary Trust',
    hideIcon: false,
    hideName: false,
    options: [
      {
        label: TrustSubtypesLabelsDictionary[TrustSubType.Discretionary],
        value: TrustSubType.Discretionary,
      },
      {
        label: TrustSubtypesLabelsDictionary[TrustSubType.DiscretionaryCapitalReserved],
        value: TrustSubType.DiscretionaryCapitalReserved,
      },
      {
        label: TrustSubtypesLabelsDictionary[TrustSubType.DiscretionaryFullyReserved],
        value: TrustSubType.DiscretionaryFullyReserved,
      },
    ],
  },
  {
    name: 'Unit Trust',
    hideIcon: false,
    hideName: false,
    options: [
      {
        label: TrustSubtypesLabelsDictionary[TrustSubType.Fixed],
        value: TrustSubType.Fixed,
      },
      {
        label: TrustSubtypesLabelsDictionary[TrustSubType.NSWLandTax],
        value: TrustSubType.NSWLandTax,
      },
      {
        label: TrustSubtypesLabelsDictionary[TrustSubType.Unit],
        value: TrustSubType.Unit,
      },
    ],
  },
];

export function getTrustType(type: TrustSubType): TrustType {
  const isUnit = type === TrustSubType.Fixed
    || type === TrustSubType.NSWLandTax
    || type === TrustSubType.Unit;

  return isUnit ? TrustType.Unit : TrustType.Discretionary;
}
