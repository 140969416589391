import { Component } from '@angular/core';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DocumentRecord } from '../../../../../../models/documentRecord';
import { AgActionIconButtonComponent } from '../ag-action-icon-button/ag-action-icon-button.component';
import { AsicStatus } from "../../../../../../models/enums/asicStatus";
import { DocumentStatusEnum } from "../../../../../../models/enums/documentStatusEnum";

type ActionEvent = (data: any | undefined) => void;

type CellRendererParams = ICellRendererParams<DocumentRecord> & {
  openDocument: ActionEvent,
  openNotes: ActionEvent,
  deleteDocument: ActionEvent,
  openForm492: ActionEvent,
  openForm902: ActionEvent,
  downloadDocument: ActionEvent
};

@Component({
  selector: 'app-ag-document-actions',
  standalone: true,
  imports: [
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
    AgActionIconButtonComponent
  ],
  templateUrl: './ag-document-actions.component.html',
  styleUrls: [
    '../ag-company-actions/ag-company-actions.component.scss',
    'ag-document-actions.component.scss'
  ]
})
export class AgDocumentActionsComponent implements ICellRendererAngularComp {
  protected params!: CellRendererParams;
  readonly AsicStatus = AsicStatus;
  readonly deletableDocumentStatuses: DocumentStatusEnum[] = [DocumentStatusEnum.Draft, DocumentStatusEnum.AuthorisationPending, DocumentStatusEnum.SignaturePending];

  agInit(params: CellRendererParams): void {
    this.params = params;
  }

  refresh(params: CellRendererParams): boolean {
    this.params = params;
    return true;
  }

  openDocument(): void {
    this.params.openDocument(this.params.data);
  }

  openNotes(): void {
    this.params.openNotes(this.params.data);
  }

  deleteDocument(): void {
    this.params.deleteDocument(this.params.data);
  }

  openForm492(): void {
    this.params.openForm492(this.params.data);
  }

  openForm902(): void {
    this.params.openForm902(this.params.data);
  }

  lodge(): void {
  }

  markAsSigned(): void {
  }

  markAsLodged(): void {
  }

  downloadDocument(): void {
    this.params.downloadDocument(this.params.data);
  }

  get documentDeleteAvailable(): boolean {
    return this.deletableDocumentStatuses.includes(this.params.data?.status!);
  }
}
