import { Component, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { distinctUntilChanged, startWith } from 'rxjs';
import { DatepickerHelper } from '../../../custom-form-validators/date-picker-form-validators';
import { Document } from '../../../models/document';
import { DatePickerComponent } from '../../components/common/date-picker/date-picker.component';
import { InputNumberComponent } from '../../components/common/input-number/input-number.component';
import { SelectComponent } from '../../components/common/select/select.component';
import { YesNoControlComponent } from '../../components/common/yes-no-control-component/yes-no-control.component';
import { BaseStepperFormComponent } from '../stepper-form/base-stepper-component/base-stepper-form.component';
import {
  StepperFormDescriptionComponent
} from '../stepper-form/stepper-form-description/stepper-form-description.component';
import { StepperFormComponent } from '../stepper-form/stepper-form.component';
import {
  CopyFinancialStatementsAndReports,
  CopyFinancialStatementsStepsEnum,
  FinancialStatement,
  financialStatementOptions,
  LodgementReasons,
  lodgementReasonsOptions
} from './form388.model';

// Copy of financial statements and reports
@Component({
  selector: 'app-form388',
  standalone: true,
  imports: [
    StepperFormComponent,
    StepperFormDescriptionComponent,
    ReactiveFormsModule,
    SelectComponent,
    DatePickerComponent,
    YesNoControlComponent,
    InputNumberComponent
  ],
  templateUrl: './form388.component.html',
  styleUrl: '../stepper-form/base-stepper-component/base-stepper-form.component.scss'
})
export class Form388Component extends BaseStepperFormComponent<CopyFinancialStatementsStepsEnum, CopyFinancialStatementsAndReports> implements OnInit {
  readonly DatepickerHelper = DatepickerHelper;
  readonly FinancialStatement = FinancialStatement;
  readonly lodgementReasons = lodgementReasonsOptions;
  readonly financialStatementOptions = financialStatementOptions;
  override readonly StepsEnum = CopyFinancialStatementsStepsEnum;

  minDate!: NgbDateStruct;
  maxDate!: NgbDateStruct;

  override stepperForm = new FormGroup({
    [CopyFinancialStatementsStepsEnum.FormDescription]: new FormGroup({}),
    [CopyFinancialStatementsStepsEnum.ReasonsOfLodgement]: new FormGroup({
      lodgementReason: new FormControl<LodgementReasons | null>(null, [Validators.required]),
      financialYearBegins: new FormControl<Date | null>(null, [Validators.required]),
      financialYearEnds: new FormControl<Date | null>(null, [Validators.required]),
    }),
    [CopyFinancialStatementsStepsEnum.DetailsOfLargeProprietaryCompany]: new FormGroup({
      isCompanyLarge: new FormControl<boolean | null>(null, [Validators.required]),

      // if large
      consolidatedRevenueIfLarge: new FormControl<number | null>(null, [Validators.required]),
      consolidatedGrossAssetsValueIfLarge: new FormControl<number | null>(null, [Validators.required]),
      employeesQuantityIfLarge: new FormControl<number | null>(null, [Validators.required]),
      membersQuantityIfLarge: new FormControl<number | null>(null, [Validators.required]),
    }),
    [CopyFinancialStatementsStepsEnum.ReportOfAuditorOrReviewer]: new FormGroup({
      financialStatementsAuditedOrReviewed: new FormControl<FinancialStatement | null>(FinancialStatement.NO, [Validators.required]),

      // financialStatementsAuditedOrReviewed === 'NO'
      isThereExemptionCurrent: new FormControl<boolean | null>(null, [Validators.required]),

      // financialStatementsAuditedOrReviewed === 'REV'
      isReviewerRegistered: new FormControl<boolean | null>(null, [Validators.required]),

      // financialStatementsAuditedOrReviewed !== 'NO'
      isOpinionConclusionReportModified: new FormControl<boolean | null>(null, [Validators.required]),
      reportContainEmphasis: new FormControl<boolean | null>(null, [Validators.required]),
    }),
  });

  constructor() {
    super();

    this.setupSteps(CopyFinancialStatementsStepsEnum);
    this.redirectAfterSubmit = true;
  }

  ngOnInit(): void {
    this.minDate = DatepickerHelper.getDateOfEstablishmentMinDate(this.companyChangeData.dateOfEstablishment);
    this.maxDate = DatepickerHelper.getNextNYearsStruct(10);
    this.listenIsLargeChange();
    this.listenFinancialStatementsAuditedOrReviewedChange();
    this.setupChange();
  }

  override setupChange(): void {
    if (!this.isEdit) {
      return;
    }

    this.reasonsOfLodgementForm.patchValue(this.formModel);
    this.largeProprietaryCompanyForm.patchValue(this.formModel);
    this.reportOfAuditorOrReviewerForm.patchValue(this.formModel);
  }

  override buildDocument(): Document | null {
    const change = new CopyFinancialStatementsAndReports({
      ...this.reasonsOfLodgementForm.value as Partial<CopyFinancialStatementsAndReports>,
      ...this.largeProprietaryCompanyForm.value as Partial<CopyFinancialStatementsAndReports>,
      ...this.reportOfAuditorOrReviewerForm.value as Partial<CopyFinancialStatementsAndReports>,
      changeDate: DatepickerHelper.getToday()
    });

    try {
      return new Document({
        changes: [change],
        entityId: this.companyChangeData?.entityId,
        type: 'c:388',
        documentId: this.companyChangeData?.documentId,
      });
    } catch (error) {
      this.toastr.error('Failed to create Document.', 'Error');
      return null;
    }
  }

  private listenIsLargeChange(): void {
    this.largeProprietaryCompanyForm.controls.isCompanyLarge.valueChanges
      .pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
      .subscribe((isLarge) => {
        const action = isLarge ? 'enable' : 'disable';

        this.largeProprietaryCompanyForm.controls.consolidatedRevenueIfLarge[action]();
        this.largeProprietaryCompanyForm.controls.consolidatedGrossAssetsValueIfLarge[action]();
        this.largeProprietaryCompanyForm.controls.employeesQuantityIfLarge[action]();
        this.largeProprietaryCompanyForm.controls.membersQuantityIfLarge[action]();
      });
  }

  private listenFinancialStatementsAuditedOrReviewedChange(): void {
    this.reportOfAuditorOrReviewerForm.controls.financialStatementsAuditedOrReviewed.valueChanges
      .pipe(
        startWith(FinancialStatement.NO),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((state) => {
        if (state === FinancialStatement.NO) {
          this.reportOfAuditorOrReviewerForm.controls.isReviewerRegistered.disable();
          this.reportOfAuditorOrReviewerForm.controls.isOpinionConclusionReportModified.disable();
          this.reportOfAuditorOrReviewerForm.controls.reportContainEmphasis.disable();

          this.reportOfAuditorOrReviewerForm.controls.isThereExemptionCurrent.enable();
        } else if (state === FinancialStatement.REV) {
          this.reportOfAuditorOrReviewerForm.controls.isThereExemptionCurrent.disable();

          this.reportOfAuditorOrReviewerForm.controls.isReviewerRegistered.enable();
          this.reportOfAuditorOrReviewerForm.controls.isOpinionConclusionReportModified.enable();
          this.reportOfAuditorOrReviewerForm.controls.reportContainEmphasis.enable();
        } else if (state === FinancialStatement.AUD) {
          this.reportOfAuditorOrReviewerForm.controls.isThereExemptionCurrent.disable();
          this.reportOfAuditorOrReviewerForm.controls.isReviewerRegistered.disable();

          this.reportOfAuditorOrReviewerForm.controls.isOpinionConclusionReportModified.enable();
          this.reportOfAuditorOrReviewerForm.controls.reportContainEmphasis.enable();
        }
      });
  }

  get reasonsOfLodgementForm() {
    return this.stepperForm.controls[CopyFinancialStatementsStepsEnum.ReasonsOfLodgement];
  }

  get largeProprietaryCompanyForm() {
    return this.stepperForm.controls[CopyFinancialStatementsStepsEnum.DetailsOfLargeProprietaryCompany];
  }

  get reportOfAuditorOrReviewerForm() {
    return this.stepperForm.controls[CopyFinancialStatementsStepsEnum.ReportOfAuditorOrReviewer];
  }
}
