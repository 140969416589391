import { Component, Input } from '@angular/core';
import { InputComponent } from "../input/input.component";
import { FormSwitchButtonComponent } from "../form-switch-button/form-switch-button.component";
import { FormsModule } from "@angular/forms";
import { LinkComponent } from "../link/link.component";
import { ValidationErrorComponent } from "../validation-error/validation-error.component";
import { NgClass } from "@angular/common";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-input-with-suggestions',
  standalone: true,
  imports: [
    FormSwitchButtonComponent,
    FormsModule,
    LinkComponent,
    ValidationErrorComponent,
    NgClass,
    NgbTooltip
  ],
  templateUrl: './input-with-suggestions.component.html',
  styleUrl: '../input/input.component.scss'
})
export class InputWithSuggestionsComponent extends InputComponent {
  @Input() suggestions: string[] = [];

  datalistId = Math.random().toString(36).substring(7);
}
