import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appCopy]',
  standalone: true
})
export class AppCopyDirective {

  private element: HTMLElement;
  private originalHtml?: string;
  private copyMarker!: HTMLElement;

  constructor(private el: ElementRef) {
    this.element = (el.nativeElement as HTMLElement);
  }

  ngOnInit() {
    if (!this.originalHtml) {
      this.originalHtml = this.element.innerHTML;
    }
    const icon = document.createElement('i');
    icon.className = 'fad fa-copy';
    this.element.appendChild(icon);

    this.copyMarker = document.createElement('span');
    this.copyMarker.className = 'fa fa-check success-marker copy-marker';
    this.copyMarker.style.visibility = 'hidden';
    this.element.appendChild(this.copyMarker);
  }

  @HostListener('click', ['$event']) async onClick($event) {
    const target = $event.target as HTMLElement;
    if (target && target.classList.contains('fa-copy'))
      await this.copyToClipboard();
  }

  async copyToClipboard() {
    const copy = this.element.textContent?.trim();
    if (copy && copy.length != 0 && copy != '-') {
      await navigator.clipboard.writeText(copy);
      this.showCopyMarker();
    }
  }

  showCopyMarker() {
    this.copyMarker.style.visibility = 'visible';
    setTimeout(() => {
      this.copyMarker.style.visibility = 'hidden';
    }, 500);
  }
}
