<app-common-modal-wrapper
  closeBtnText="Cancel"
  saveBtnText="Save"
  header="Edit Details"
  icon="ic-edit"
  [formGroup]="form"
  [isLoading]="isLoading"
  (submitModal)="save()"
  (close)="cancel()"
>
  <div class="two-piece-input-wrapper">
    <app-input
      label="Trust Name"
      formControlName="name"
    ></app-input>

    <app-abn-masked-input formControlName="entityNumber"></app-abn-masked-input>
  </div>

  <div class="two-piece-input-wrapper">
    <app-date-picker
      label="Commencement Date"
      formControlName="dateOfEstablishment"
      [isRequired]="false"
      [minDate]="minCommencementDate"
      [maxDate]="maxDate"
    ></app-date-picker>

    <app-date-picker
      label="Vesting Date"
      formControlName="vestingDate"
      [isRequired]="false"
      [minDate]="defaultMinDateOrDayAfterCommencementDate"
      [maxDate]="maxDate"
      [customErrors]="vestingDateErrors"
    ></app-date-picker>
  </div>

  <div class="form-group">
    <app-select
      label="Jurisdiction"
      formControlName="jurisdiction"
      [options]="JurisdictionTypeOptions"
      [valuePrimitive]="true"
    ></app-select>
  </div>

  <div class="form-group">
    <app-address-form-group
      label="Postal Address"
      [formGroup]="form.controls.registeredAddress"
      [noValidators]="true"
    ></app-address-form-group>
  </div>

  <app-multiple-input-datepicker
    label="Dates of Variation"
    formControlName="variationDates"
    [minDateStruct]="defaultMinDateOrCommencementDate"
    [maxDateStruct]="defaultMaxDateOrVestingDate"
  ></app-multiple-input-datepicker>

  <app-divider [margin]="'28px 0px 20px'"></app-divider>

  <div class="two-piece-input-wrapper">
    <app-select
      label="Partner"
      formControlName="partnerManagerId"
      [options]="usersOption"
      [valuePrimitive]="true"
    ></app-select>

    <app-select
      label="Account Manager"
      formControlName="accountManagerId"
      [options]="usersOption"
      [valuePrimitive]="true"
    ></app-select>
  </div>

  <div class="two-piece-input-wrapper">
    <app-input label="Reference Number" formControlName="referenceNumber"></app-input>
    <app-input label="Family Group" formControlName="familyGroup"></app-input>
  </div>
</app-common-modal-wrapper>
