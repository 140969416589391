import { RelationshipType } from "../../models/enums/relationshipTypeEnum";
import { Relationship } from "../../models/relationship";

export function getOfficerByRoles(relationship: Relationship[], roles: RelationshipType[]): Relationship[]{
    const list = relationship
      .filter(r => roles.includes(r.type))
      .map((relationship) =>
        relationship
      );

    return list;

}