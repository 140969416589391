import { Component, Input } from '@angular/core';
import { AppCopyDirective } from '../../../directives/copy.directive';
import { DateFormatPipe } from '../../../pipes/dateFormatPipe';
import { DefaulValuePipe } from '../../../pipes/enumsPipes/defaultValuePipe';
import { PhonePipe } from '../../../pipes/phonePipe';
import { Trust } from '../../../models/trust';
import { TrustStatusPipe } from "../../../pipes/enumsPipes/trustStatusPipe";
import { ABNPipe } from '../../../pipes/abnPipe';
import { TrustSubTypePipe } from "../../../pipes/enumsPipes/trustSubTypePipe";
import { stateLabelsDictionary } from "../../constants/au-states.constant";
import { JurisdictionType } from "../../../models/enums/trustEnum";
import { DatepickerHelper } from "../../../custom-form-validators/date-picker-form-validators";

@Component({
    selector: 'app-trust-details',
    standalone: true,
    templateUrl: './trust-details.component.html',
    styleUrl: './trust-details.component.scss',
  imports: [
    ABNPipe,
    DateFormatPipe,
    DefaulValuePipe,
    PhonePipe,
    AppCopyDirective,
    TrustStatusPipe,
    TrustSubTypePipe,
  ]
})
export class TrustDetailsComponent {
  @Input() trustProfile!: Trust

  get jurisdiction(): string {
    return stateLabelsDictionary[JurisdictionType[this.trustProfile.jurisdiction!]] ?? '-';
  }

  get variationDates(): string {
    if (this.trustProfile.variationDates.length)
      return this.trustProfile.variationDates
        .map((date) => DatepickerHelper.toString(date))
        .join(', ');
    return '-';
  }
}
