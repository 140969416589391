<div class="form-container">
  <div class="header-container">
    <div class="name-container">
      @if (showChevron) {
        <div (click)="onToggleDocumentChangeState()">
          <i
            class="icon ic-tiny-lg ic-chevron-down"
            [ngClass]="{ 'chevron-up': !change.expanded }"
          ></i>
        </div>
      }
      <div class="name">
        <span class="font-weight-normal">{{ change.type | documentType | uppercase }}:</span>
        {{ change.description }}
      </div>
    </div>

    <div class="d-flex gap-12">
      <div (click)="onDownloadPreview()">
        <i class="icon ic-xs ic-download-file"></i>
      </div>

      <div (click)="onEditDocumentChange()">
        <i class="icon ic-form-edit"></i>
      </div>
    </div>

  </div>
  @if (change.expanded) {
    <div class="company-info-container">
      @for (dic of change.preview; track dic) {
        @if (dic.key === 'divider') {
          <app-divider></app-divider>
        } @else if (dic.key === 'empty line') {
          <div class="empty-line"></div>
        } @else {
          <div class="info-row">
            <div class="title">{{ dic.key }}</div>
            <div class="value" [innerHTML]="dic.value"></div>
          </div>
        }
      }
    </div>
  }
</div>
