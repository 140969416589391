import {
  CompanyChangePartyName
} from '../app/modals/documents/asic-forms/484-forms/a2-company-member-name-change/CompanyChangePartyName';
import { CompanyChangeName } from '../app/modals/company-name-change/CompanyChangeName';
import {
  CompanyChangeHoldingCompany
} from '../app/modals/documents/asic-forms/484-forms/a3-holding-company-change/company-change-holding-company.model';
import {
  CompanyChangeSpecialPurpose
} from '../app/modals/documents/asic-forms/484-forms/b3-company-status-change/company-status-change-form.model';
import {
  CompanyWithdrawLodgedDocumentChange
} from '../app/modals/documents/asic-forms/form106-withdraw-lodged-document/withdraw-lodged-document-form.model';
import {
  CompanyDividendStatement
} from '../app/modals/documents/asic-forms/form-divident-statement/CompanyDividendStatement';
import { CompanyForm361 } from '../app/modals/documents/asic-forms/form361-registered-agent-ceasing/CompanyForm361';
import {
  CompanyChangeOfficerResignation
} from '../app/modals/documents/asic-forms/form370-notification-by-officeholder-of-resignation-or-retirement/CompanyChangeOfficerResignation.model';
import { Form410BModel } from '../app/modals/documents/asic-forms/form410b/form410B.model';
import { Form410FModel } from '../app/modals/documents/asic-forms/form410f/form410F.model';
import {
  CompanyMistakeCorrection
} from '../app/modals/documents/asic-forms/form492-request-correction/request-correction-change.model';
import {
  CompanyChangeDeregister
} from '../app/modals/documents/asic-forms/form6010-voluntary-deregistration/form6010.model';
import {
  RegisterCeaseChangeAgentForm
} from '../app/modals/documents/asic-forms/RA-forms/ra01-register-cease-change-agent/register-cease-change-agent-form.model';
import {
  AsicFormRA04
} from '../app/modals/documents/asic-forms/RA-forms/ra04-direct-debit-request/ra04-direct-debit-request.model';
import { CompanyForm280 } from '../app/modals/form280/form280.model';
import { CopyFinancialStatementsAndReports } from '../app/modals/form388/form388.model';
import { CompanyForm281 } from '../app/modals/form281/form281.model';
import {
  CompanySecurityCancellation
} from '../app/modals/share/share-cancellation/company-security-cancellation.model';
import { CompanySecurityIssue } from '../app/modals/share/share-issue/share-issue.model';
import {
  CompanySecurityConsolidationSubdivision
} from '../app/modals/share/share-subdivision-conversion/share-subdivision-conversion.model';
import { CompanySecurityTransfer } from '../app/modals/share/share-transfer/share-transfer.model';
import { CompanyAppointChange } from '../models/companyAppointChange';
import { Guid } from "../app/helpers/guid.helper";
import { CompanyChangeAddress } from "../app/modals/documents/asic-forms/484-forms/a1-company-address-change/CompanyChangeAddress.model";
import { CompanyChangeOfficer } from "./сompanyChangeOfficer";
import { EntityChangeData } from "./entityChangeData";
import { AsicStatus } from "./enums/asicStatus";
import { DocumentStatusEnum } from "./enums/documentStatusEnum";
import {
  BulkCompanyChangeAddress
} from "../app/modals/bulk-changes/bulk-change-address-form/BulkCompanyChangeAddress.model";
import { DocumentationType } from "./enums/documentConfirmEnums";
import { CompanyRegistration } from "./company-registration";
import { TrustChangeRelationship } from "./trust-change-relationship";
import {
  TrustDistribution
} from "../app/trusts/components/form-distribution-resolution-or-minutes/form-distribution-resolution-or-minutes.model";
import { DocumentBase } from './documentEnteties/document-base';
import { ChangeCompanyType } from "../app/modals/form206/form206.model";
import { EdgeValidationResult } from "./edgeValidationResult";

export class Document extends DocumentBase {
  documentStatus?: DocumentStatusEnum;
  documentationType?: DocumentationType;
  asicStatus: AsicStatus;
  asicDocumentNumber?: string | null;
  asicTraceNumber?: string;
  edgeValidationResult?: EdgeValidationResult | null;
  lodgementDate: Date | null;
  lodgementDeadline: Date | null;
  lodgementEarliestDate: Date | null;

  constructor(data: Partial<Document> = {}) {
      super(data);
      this.changes = data.changes?.map(x => this.createChangeDataInstance(x)) ?? [];
      this.documentStatus = data.documentStatus;
      this.documentationType = data.documentationType ?? DocumentationType.Default;
      this.asicStatus = data.asicStatus ?? AsicStatus.NotLodged;
      this.asicDocumentNumber = data.asicDocumentNumber ?? null;
      this.asicTraceNumber = data.asicTraceNumber ?? '';
      this.edgeValidationResult = data.edgeValidationResult ?? null;
      this.lodgementDate = data.lodgementDate ?? null;
      this.lodgementDeadline = data.lodgementDeadline ?? null;
      this.lodgementEarliestDate = data.lodgementEarliestDate ?? null;
  }

    createChangeDataInstance(data): EntityChangeData {
        //workaround for type casting
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        switch (data.$type) {
            case CompanyChangeAddress.$type:
                return new CompanyChangeAddress(data as Partial<CompanyChangeAddress>);
            case BulkCompanyChangeAddress.$type:
                return new BulkCompanyChangeAddress(data as Partial<BulkCompanyChangeAddress>);
            case CompanyChangeOfficer.$type:
                return new CompanyChangeOfficer(data as Partial<CompanyChangeOfficer>);
            case CompanyAppointChange.$type:
                return new CompanyAppointChange(data as Partial<CompanyAppointChange>);
             case CompanyChangeHoldingCompany.$type:
                return new CompanyChangeHoldingCompany(data as Partial<CompanyChangeHoldingCompany>);
            case CompanyChangeSpecialPurpose.$type:
                return new CompanyChangeSpecialPurpose(data as Partial<CompanyChangeSpecialPurpose>);
            case CompanyMistakeCorrection.$type:
                return new CompanyMistakeCorrection(data as Partial<CompanyMistakeCorrection>);
            case CompanyChangeOfficerResignation.$type:
                return new CompanyChangeOfficerResignation(data as Partial<CompanyChangeOfficerResignation>);
            case CompanyChangePartyName.$type:
                return new CompanyChangePartyName(data as Partial<CompanyChangePartyName>);
            case Form410BModel.$type:
                return new Form410BModel(data as Partial<Form410BModel>);
            case Form410FModel.$type:
                return new Form410FModel(data as Partial<Form410FModel>);
            case CompanyForm361.$type:
                return new CompanyForm361(data as Partial<CompanyForm361>);
            case CompanyWithdrawLodgedDocumentChange.$type:
                return new CompanyWithdrawLodgedDocumentChange(data as Partial<CompanyWithdrawLodgedDocumentChange>);
            case CompanyChangeDeregister.$type:
                return new CompanyChangeDeregister(data as Partial<CompanyChangeDeregister>);
            case CompanyDividendStatement.$type:
                return new CompanyDividendStatement(data as Partial<CompanyDividendStatement>);
            case CompanyChangeName.$type:
                return new CompanyChangeName(data as Partial<CompanyChangeName>);
            case ChangeCompanyType.$type:
                return new ChangeCompanyType(data as Partial<ChangeCompanyType>);
            case CompanyForm280.$type:
                return new CompanyForm280(data as Partial<CompanyForm280>);
            case CopyFinancialStatementsAndReports.$type:
                return new CopyFinancialStatementsAndReports(data as Partial<CopyFinancialStatementsAndReports>);
            case CompanyForm281.$type:
                return new CompanyForm281(data as Partial<CompanyForm281>);
            case RegisterCeaseChangeAgentForm.$type:
                return new RegisterCeaseChangeAgentForm(data as Partial<RegisterCeaseChangeAgentForm>);
             case AsicFormRA04.$type:
                return new AsicFormRA04(data as Partial<AsicFormRA04>);
             case CompanySecurityCancellation.$type:
                return new CompanySecurityCancellation(data as Partial<CompanySecurityCancellation>);
             case CompanySecurityIssue.$type:
                return new CompanySecurityIssue(data as Partial<CompanySecurityIssue>);
             case CompanySecurityTransfer.$type:
                return new CompanySecurityTransfer(data as Partial<CompanySecurityTransfer>);
             case CompanySecurityConsolidationSubdivision.$type:
                return new CompanySecurityConsolidationSubdivision(data as Partial<CompanySecurityConsolidationSubdivision>);
             case CompanyRegistration.$type:
                return new CompanyRegistration(data as Partial<CompanyRegistration>);
             case TrustChangeRelationship.$type:
                return new TrustChangeRelationship(data as Partial<TrustChangeRelationship>);
             case TrustDistribution.$type:
                return new TrustDistribution(data as Partial<TrustDistribution>);
            //// Add cases for other types as needed
            default:
                throw new Error(`Unknown change data type: ${data.$type}`);
        }
    }

  static prepareToRequest(document: Document): Document {
    const preparedDocument = {
      ...document,
      changes: document.changes.map((change) => change.prepareToRequest()),
      entityId: document.entityId === Guid.EmptyGuid ? null : document.entityId
    } as any as Document;

    delete preparedDocument.createdOn;
    delete preparedDocument.modifiedOn;

    return preparedDocument;
  }
}
