import { Observable } from "rxjs";

export function blobToBase64(blob: Blob): Observable<string> {
  return new Observable((observer) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      if (reader.result) {
        const base64String = (reader.result as string).split(',')[1];
        observer.next(base64String);
        observer.complete();
      } else {
        observer.error(new Error('Failed to read the Blob data.'));
      }
    };

    reader.onerror = (error) => {
      observer.error(error);
    };

    reader.readAsDataURL(blob);
  });
}
