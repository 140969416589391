import { enumDictionaryToSelectOptions } from '../../../../../functions/enums-to-list-formatter';
import { SelectOption } from '../../../../../models/selectOptions';

export enum TagActionEnum {
  Assign,
  Override,
  Unassign
}

export const TagActionDictionary = {
  [TagActionEnum.Assign]: 'Add',
  [TagActionEnum.Unassign]: 'Remove',
  [TagActionEnum.Override]: 'Override',
};

export const ManageTagsModalActionsOptions: SelectOption[] = enumDictionaryToSelectOptions(TagActionDictionary);
