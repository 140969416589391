import { Component } from '@angular/core';
import { ICellRendererParams } from "ag-grid-community";
import { BulkSignRow, DocumentForSign } from "../../../../../../../models/signDocuments";

type CellRendererParams = ICellRendererParams<BulkSignRow>

@Component({
  selector: 'app-ag-annual-documents-for-sign',
  standalone: true,
  imports: [],
  templateUrl: './ag-annual-documents-for-sign.component.html',
  styleUrl: './ag-annual-documents-for-sign.component.scss'
})
export class AgAnnualDocumentsForSignComponent {
  protected params!: CellRendererParams;
  protected documentsForSign: DocumentForSign[] = [];

  agInit(params: CellRendererParams): void {
    this.params = params;
    this.documentsForSign = params.data?.documentsForSign ?? [];
    this.setRowHeight();
  }

  refresh(params: CellRendererParams): boolean {
    this.params = params;
    return true;
  }

  setRowHeight(): void {
    const rowHeight = 28;

    if (this.documentsForSign.length > 1) {
      const documentsForSignRowHeight = this.documentsForSign.length * rowHeight;
      this.params.node.setRowHeight(documentsForSignRowHeight);
      this.params.api.onRowHeightChanged();
    }
  }

  downloadFile(name: string): void {
  }
}
