import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Company } from '../../../../../models/company';
import { Document } from '../../../../../models/document';
import { EntityChangeData } from '../../../../../models/entityChangeData';
import { CompanyChangeData } from '../../../../../models/enums/companyChangeData';
import { ChangeDictionaryHelper } from '../../../../../models/shared/change-dictionary-helper.model';
import { Form361RegisteredAgentCeasingComponent } from './form361-registered-agent-ceasing.component';
import { formatDate } from "@angular/common";

export class CompanyForm361 extends EntityChangeData {
  static override readonly $type = 'CompanyForm361';

  changeName = 'Cease as a Registered Agent';

  constructor(data: Partial<CompanyForm361> = {}) {
    super(data);

    this.description = 'Agent cessation';
  }

  override toDictionary(): { key: string; value: string; }[] {
    const dict = new ChangeDictionaryHelper();

    dict
      .add('Type of Change', this.changeName)
      .add('Date of Change', formatDate(this.changeDate, 'dd/MM/yyyy', 'en-US'));

    return dict.dictionary;
  }

  override fillModal(modalRef: NgbModalRef, document: Document, actualCompany: Company): NgbModalRef {
    modalRef = this.fillModalWithCompany(modalRef, actualCompany, true);
    (modalRef.componentInstance as Form361RegisteredAgentCeasingComponent).companyChangeData.documentId = document.documentId;
    return modalRef;
  }

  override fillModalWithCompany(modalRef: NgbModalRef, company: Company, isEdit: boolean): NgbModalRef {
    const instance = modalRef.componentInstance as Form361RegisteredAgentCeasingComponent;

    instance.isEdit = isEdit;
    instance.companyChangeData = new CompanyChangeData(company);
    instance.formModel = structuredClone(this);

    return modalRef;
  }
}
