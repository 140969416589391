import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CommonModalWrapperComponent
} from '../../../../components/common/common-modal-wrapper/common-modal-wrapper.component';
import { InputPasswordComponent } from '../../../../components/common/input-password/input-password.component';

@Component({
  selector: 'app-edit-agent-reset-edge-password-modal',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModalWrapperComponent,
    InputPasswordComponent
  ],
  templateUrl: './edit-agent-reset-edge-password-modal.component.html',
  styleUrl: './edit-agent-reset-edge-password-modal.component.scss'
})
export class EditAgentResetEdgePasswordModalComponent {
  activeModal = inject(NgbActiveModal);

  form = new FormGroup({
    edgeFuturePassword: new FormControl<string | null>(null, [Validators.required])
  });

  save(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.activeModal.close(this.form.value.edgeFuturePassword);
  }

  cancel(): void {
    this.activeModal.dismiss();
  }
}
