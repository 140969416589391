import { JsonPipe } from '@angular/common';
import { ChangeDetectorRef, Component, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, startWith } from 'rxjs';
import {
  DatepickerHelper
} from '../../../../../../custom-form-validators/date-picker-form-validators';
import { clearFormArray } from '../../../../../../functions/clear-form-array';
import { setControlDisabled } from '../../../../../../functions/set-control-disabled';
import { Document } from '../../../../../../models/document';
import { EntityChangeData } from '../../../../../../models/entityChangeData';
import { CompanyChangeOfficerType } from '../../../../../../models/enums/companyChangeOfficerType';
import { RelationshipType, RelationshipTypeLabels } from '../../../../../../models/enums/relationshipTypeEnum';
import { OfficerAppointmentType, OfficerAppointmentTypeLabels } from '../../../../../../models/officerAppointmentForm';
import { OfficerRelationshipDetails, Relationship } from '../../../../../../models/relationship';
import { SelectOption } from '../../../../../../models/selectOptions';
import {
  CompanyChangeOfficer,
  ReasonOfCessation,
  reasonOfCessationOptions
} from '../../../../../../models/сompanyChangeOfficer';
import { ACNPipe } from '../../../../../../pipes/acnPipe';
import { CheckboxComponent } from '../../../../../components/common/checkbox/checkbox.component';
import { DatePickerComponent } from '../../../../../components/common/date-picker/date-picker.component';
import { SelectComponent } from '../../../../../components/common/select/select.component';
import { TextareaComponent } from '../../../../../components/common/textarea/textarea.component';
import { ValidationErrorComponent } from '../../../../../components/common/validation-error/validation-error.component';
import { BaseStepperFormComponent } from '../../../../stepper-form/base-stepper-component/base-stepper-form.component';
import {
  StepperFormDescriptionComponent
} from '../../../../stepper-form/stepper-form-description/stepper-form-description.component';
import { StepperFormComponent } from '../../../../stepper-form/stepper-form.component';
import { NotificationComponent } from "../../../../../components/common/notification/notification.component";

export enum CeaseCompanyOfficeholderStepsEnum {
  FormDescription = 0,
  CeaseCompanyOfficeholder = 1
}

@Component({
  selector: 'app-b1-cease-company-officeholder',
  standalone: true,
  imports: [
    StepperFormComponent,
    StepperFormDescriptionComponent,
    ReactiveFormsModule,
    CheckboxComponent,
    JsonPipe,
    DatePickerComponent,
    ValidationErrorComponent,
    SelectComponent,
    TextareaComponent,
    NotificationComponent
  ],
  templateUrl: './b1-cease-company-officeholder.component.html',
  styleUrls: [
    './b1-cease-company-officeholder.component.scss',
    '../../../../stepper-form/base-stepper-component/base-stepper-form.component.scss'
  ]
})
export class B1CeaseCompanyOfficeholderComponent extends BaseStepperFormComponent<CeaseCompanyOfficeholderStepsEnum, CompanyChangeOfficer> implements OnInit {
  cdr = inject(ChangeDetectorRef);

  @Input() officers: Record<string, Relationship[]> = {};
  @Input() officersSelectOptions: SelectOption[] = [];
  @Input() moreThenOneDirectorExist: boolean | undefined;

  readonly reasonOfCessationOptions = reasonOfCessationOptions;
  override readonly StepsEnum = CeaseCompanyOfficeholderStepsEnum;
  officerPositionTypesOptions: SelectOption[] = [];
  readonly ceaseWarningMsg = 'You cannot cease the sole director of the company.' +
    ' Please appoint a new director before proceeding with this cessation.'

  minDate!: NgbDateStruct;
  maxDate!: NgbDateStruct;

  form = new FormGroup({
    changeDate: new FormControl<Date | null>(null),
    appointedOfficerId: new FormControl<string | null>(null, [Validators.required]),
    types: new FormArray<FormControl<boolean>>([]),
    cessationReason: new FormControl<ReasonOfCessation | null>(null, [Validators.required]),
    noticeOfResignation: new FormControl<string | null>(null, [Validators.required]),
    moreThanOneDirector: new FormControl<boolean | null>(null, [Validators.requiredTrue]),
  });

  override stepperForm = new FormGroup({
    [CeaseCompanyOfficeholderStepsEnum.FormDescription]: new FormGroup({}),
    [CeaseCompanyOfficeholderStepsEnum.CeaseCompanyOfficeholder]: this.form
  });

  constructor() {
    super();
    this.setupSteps(CeaseCompanyOfficeholderStepsEnum);
  }

  ngOnInit(): void {
    this.minDate = DatepickerHelper.getDateOfEstablishmentMinDate(this.companyChangeData.dateOfEstablishment);
    this.maxDate = DatepickerHelper.getNextNYearsStruct(10);
    this.form.controls.changeDate.setValue(DatepickerHelper.getToday());
    this.form.controls.moreThanOneDirector.setValue(true);

    this.setTypesValidators();

    this.listenReasonOfCessationChange();
    this.listenOfficerTypesChange();
    this.listenAppointedOfficerIdChanges();

    this.setupChange();
  }

  override afterSubmit(changes: EntityChangeData[]) {
    this.setupChange(changes[0] as CompanyChangeOfficer);
  }

  override setupChange(change: CompanyChangeOfficer = this.formModel) {
    if (change.offices.length && change.offices[0].relationshipId) {
      const selectedRelationshipIds = change.offices[0]?.relationshipId;
      const selectedOfficerId = Object.entries(this.officers)
        .find(([key, relashionships]) => relashionships
          .some(relashionship => relashionship.relationshipId === selectedRelationshipIds)
        )?.[0] ?? null; // it's a key
      this.form.controls.appointedOfficerId.patchValue(selectedOfficerId ?? null);
      this.cdr.detectChanges();
      this.fillTypesFormArray(selectedOfficerId, change.offices);
    }

    if (!this.isEdit && change === this.formModel)
      return;

    const selectedChangeOfficer = change.offices[0];
    this.form.controls.types.reset();
    clearFormArray(this.form.controls.types);
    this.form.patchValue({
      ...change,
      changeDate: change.changeDate,
      appointedOfficerId: selectedChangeOfficer.key,
    });
    this.cdr.detectChanges();
    this.form.controls.cessationReason.setValue(change.cessationReason);
    this.fillTypesFormArray(selectedChangeOfficer.key, change.offices);
    this.form.controls.noticeOfResignation.setValue(change.noticeOfResignation);
  }

  override buildDocument(): Document | null {
    const selectedOfficerRelationships = this.officers[this.form.value.appointedOfficerId as string];

    const changes = new CompanyChangeOfficer({
      ...this.form.value,
      actionType: CompanyChangeOfficerType.Removal,
      changeDate: this.form.controls.changeDate.value!,
      offices: this.form.controls.types.getRawValue()?.reduce((offices, isTypeMatched, index) => {

        if (isTypeMatched) {
          offices.push(selectedOfficerRelationships[index]);
        }

        return offices;
      }, [] as Relationship[])

    });
    try {
      return new Document({
        changes: [changes],
        entityId: this.companyChangeData.entityId,
        type: 'c:484',
        documentId: this.companyChangeData?.documentId,
      });
    } catch (error) {
      console.warn(error);
      return null;
    }
  }

  private listenAppointedOfficerIdChanges(): void {
    this.form.controls.appointedOfficerId.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((appointedOfficerId) => {
        if (appointedOfficerId) {
          clearFormArray(this.form.controls.types);

          this.officerPositionTypesOptions = this.officers[appointedOfficerId].map((officer, relationshipIndex) => {
            this.form.controls.types.push(new FormControl<boolean>(false, { nonNullable: true }));

            if (officer.type === RelationshipType.AlternativeDirector && officer.details instanceof OfficerRelationshipDetails && officer.details.alternativeDirectorFor) {
              return {
                label: `${ RelationshipTypeLabels[officer.type][officer.type] } (for ${ this.officers[officer.details.alternativeDirectorFor][0].individualDataOverride!.fullName })`,
                value: relationshipIndex
              };
            }

            return {
              label: RelationshipTypeLabels[officer.type],
              value: relationshipIndex
            };
          });
        } else {
          clearFormArray(this.form.controls.types);
          this.officerPositionTypesOptions = [];
          this.form.controls.cessationReason.reset();
        }
      });
  }

  private listenOfficerTypesChange(): void {
    this.form.controls.types.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(type => {
      const isDirectorSelected = this.selectedOfficerPositions.includes(OfficerAppointmentType.Director);
      const isNonDirectorSelected = this.selectedOfficerPositions.some(position => position !== OfficerAppointmentType.Director);

      if (isNonDirectorSelected) {
        this.form.controls.moreThanOneDirector.setValue(true);
      } else if (isDirectorSelected) {
        this.form.controls.moreThanOneDirector.setValue(!!this.moreThenOneDirectorExist);
      }

      this.officerPositionTypesOptions.forEach((option, index) => {
        if (option.label === 'Director') {
          const isSelected = type[index];
          this.form.controls.moreThanOneDirector.setValue(isSelected ? !!this.moreThenOneDirectorExist : true);
        }
      });
    });

    combineLatest([
      this.form.controls.types.valueChanges,
      this.form.controls.changeDate.valueChanges.pipe(startWith(this.form.controls.changeDate.value)),
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.fillNoticeOfResignation());
  }

  private listenReasonOfCessationChange(): void {
    this.form.controls.cessationReason.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((reasonOfCessation) => {
        setControlDisabled(this.form.controls.noticeOfResignation, reasonOfCessation !== ReasonOfCessation.Resignation);

        this.form.controls.types.controls.forEach(control => {
          setControlDisabled(control, reasonOfCessation === ReasonOfCessation.Death);

          if (reasonOfCessation === ReasonOfCessation.Death) {
            control.setValue(true);
          }
        });

        if (reasonOfCessation === ReasonOfCessation.Resignation) {
          this.fillNoticeOfResignation();
        }
      });
  }

  private setTypesValidators(): void {
    this.form.controls.types.addValidators((control) => {
      const controlsValue = (control.value ?? []) as boolean[];

      return controlsValue.some((value) => value) ? null : { requiredAtLeastOne: true };
    });
  }

  private fillTypesFormArray(officerKey: string | undefined | null, relationships: Relationship[] = []): void {
    if (!officerKey) {
      return;
    }

    const officerRelationship = this.officers[officerKey] ?? [];

    if (officerRelationship.length && relationships.length) {
      this.officers[officerKey].forEach((relationship, index) => {
        this.form.controls.types.at(index).setValue(relationships.some((r) => {
          return r.relationshipId === relationship.relationshipId;
        }));
      });
    }
  }

  private fillNoticeOfResignation(): void {
    const allowedToFillNoticeOfResignation =
      this.form.controls.cessationReason.value === ReasonOfCessation.Resignation
      && this.form.controls.appointedOfficerId.value !== null
      && this.form.controls.cessationReason.value !== null
      && this.form.controls.changeDate.value !== null;

    if (!allowedToFillNoticeOfResignation)
      return;

    const officer = this.officers[this.form.controls.appointedOfficerId.value as string];
    const officerName = officer?.[0]?.individualDataOverride?.fullName ?? '';
    const officerAddress = officer?.[0]?.individualDataOverride?.address.normalizedFullAddress ?? '';
    const positions = this.selectedOfficerPositions.length ? ' as ' + this.selectedOfficerPositions.map(type => OfficerAppointmentTypeLabels[type]).join(', ') : '';
    const nameOfCompanyAndACN = this.companyChangeData.companyName + (this.companyChangeData.companyAcn ? ` - ${ new ACNPipe().transform(this.companyChangeData.companyAcn) }` : '');
    const resignationDate = DatepickerHelper.toString(this.form.controls.changeDate.value as Date);

    // 'Notice of Resignation' text field should appear with pre-filled text:
    // 'I, [Officer name] of [Officer Address] hereby resign as [role (Director; Secretary; Director and Secretary)]
    // of [Company name - ACN] with effect from [Resignation Date]'
    const noticeOfResignation = `I, ${ officerName } of ${ officerAddress.trim() } hereby resign${ positions } of ${ nameOfCompanyAndACN } with effect from ${ resignationDate }`;
    this.form.controls.noticeOfResignation.setValue(noticeOfResignation);
  }

  get selectedOfficerPositions(): OfficerAppointmentType[] {
    return this.officers[this.form.controls.appointedOfficerId.value as string]
      .filter((_, index) => this.form.controls.types.value[index])
      ?.map(officer => officer.type) as any as OfficerAppointmentType[] ?? [];
  }

  get noticeOfResignationHidden(): boolean {
    return this.form.controls.cessationReason.getRawValue() !== ReasonOfCessation.Resignation;
  }
}
