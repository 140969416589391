<a
  target="_blank"
  class="entity-title"
  [class.is-link]="isLink"
  [class.f-width]="fullWidth"
  [class.d-flex]="entityType === EntityType.Individual"
  [href]="link"
>
  <div class="left">
    @if (isLink) {
      @if (entityType === EntityType.Company) {
        <i class="icon ic-md ic-companies"></i>
      }
      @else if (entityType === EntityType.Trust) {
        <i class="icon ic-md ic-trusts"></i>
      }
      @else if (entityType === EntityType.Individual) {
        <i class="icon ic-md ic-individuals"></i>
      }
    }

    @switch (entityType) {
      @case (EntityType.Company) {
        <app-entity-name-number-record
          prefix="ACN: "
          [name]="entity?.['name']"
          [numberFormatted]="entity?.['entityNumber'] | acn"
        ></app-entity-name-number-record>
      }
      @case (EntityType.Trust) {
        <app-entity-name-number-record
          prefix="ABN: "
          [name]="entity?.['name']"
          [numberFormatted]="entity?.['entityNumber'] | abn"
        ></app-entity-name-number-record>
      }
      @case (EntityType.Individual) {
        <a>{{ entity?.['fullName'] }}</a>
      }
    }

    @if (isLink) {
      <i class="icon ic-md ic-external-link"></i>
    }
  </div>
</a>
