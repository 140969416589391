import { Component, inject, Input, OnInit, signal } from '@angular/core';
import {
  AppointmentType,
  FormEditTrusteeSteps,
  IndividualCorporateRelationship,
  individualCorporateTrusteeOptions,
  TrustChangeRelationship
} from "../../../../models/trust-change-relationship";
import { Relationship } from "../../../../models/relationship";
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Trust } from "../../../../models/trust";
import { IStep } from "../../../../models/step";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectComponent } from "../../../components/common/select/select.component";
import { SelectOption } from "../../../../models/selectOptions";
import { clearFormArray } from "../../../../functions/clear-form-array";
import { DividerComponent } from "../../../components/common/divider/divider.component";
import { setControlDisabled } from "../../../../functions/set-control-disabled";
import {
  RelationshipFormGroup,
  RelationshipFormGroupComponent
} from "../../../components/reusable-form-groups/relationship-form-group/relationship-form-group.component";
import { Address } from "../../../../models/address";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ButtonComponent } from "../../../components/common/button/button.component";
import {
  FormIndividualRelationshipModalComponent
} from "../form-individual-realtionship-modal/form-individual-relationship-modal.component";
import {
  CorporateRelationshipFormGroup,
  CorporateRelationshipFormGroupComponent
} from "../../../components/reusable-form-groups/corporate-relationship-form-group/corporate-relationship-form-group.component";
import { DisclaimerComponent } from "../../../components/common/disclaimer/disclaimer.component";
import { Company } from "../../../../models/company";
import { RelationshipType } from "../../../../models/enums/relationshipTypeEnum";
import { CompanyProfileService } from "../../../company-profile/company-profile.service";
import { finalize } from "rxjs";
import { Guid } from "../../../helpers/guid.helper";
import { EntityData } from "../../../../models/entityData";
import {
  StepperFormAppliableComponent
} from "../../../modals/stepper-form/stepper-form-appliable/stepper-form-appliable.component";
import {
  BaseStepperAppliableComponent
} from "../../../modals/stepper-form/base-stepper-appliable/base-stepper-appliable.component";
import { Document } from "../../../../models/document";
import { EntityChangeData } from "../../../../models/entityChangeData";
import { ValidationErrorComponent } from "../../../components/common/validation-error/validation-error.component";
import { directorFullNameValidatorMsg } from '../../../../validators/validatorMessages/custom-form-validators-messages';

@Component({
  selector: 'app-form-change-of-trustee',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    ButtonComponent,
    DividerComponent,
    DisclaimerComponent,
    SelectComponent,
    RelationshipFormGroupComponent,
    CorporateRelationshipFormGroupComponent,
    ValidationErrorComponent,
    StepperFormAppliableComponent,
  ],
  templateUrl: './form-change-of-trustee.component.html',
  styleUrls: ['./../../../modals/stepper-form/base-stepper-component/base-stepper-form.component.scss', './form-change-of-trustee.component.scss']
})
export class FormChangeOfTrusteeComponent extends BaseStepperAppliableComponent<FormEditTrusteeSteps, TrustChangeRelationship> implements OnInit {
  private modalService = inject(NgbModal);
  private companyProfileService = inject(CompanyProfileService);

  @Input() trustProfile!: Trust;

  readonly allowedMinimumOfCorporateTrusteeDirector = 1;
  readonly allowedMaximumOfCorporateTrusteeDirector = 15;
  readonly allowedMaximumOfIndividualTrustees = 6;
  readonly allowedMinimumOfIndividualTrustees = 2;
  readonly individualCorporateTrusteeOptions: SelectOption[] = individualCorporateTrusteeOptions;
  readonly IndividualCorporateTrustee = IndividualCorporateRelationship;
  readonly customErrors = {
    notEnoughIndividualTrustees: 'At least [] individual trustees should be added.',
    tooManyIndividualTrustees: 'Maximum number of individual trustees is [].',
    notEnoughCorporateTrusteeDirectors: 'At least [] director should be present.',
    tooManyCorporateTrusteeDirectors: 'Maximum number of directors to add is []',
  };
  protected readonly directorFullNameValidatorMsg = directorFullNameValidatorMsg;
  override readonly StepsEnum = FormEditTrusteeSteps;

  stepsSignal = signal<IStep<FormEditTrusteeSteps>[]>([]);
  linkedCompanyProfile: Company | null = null;

  newIndividualTrustees: Relationship[] = [];
  corporateTrusteeDirectors: Relationship[] = [];

  override stepperForm = new FormGroup({
    [FormEditTrusteeSteps.ExistingTrusteeDetails]: new FormGroup({
      trusteeType: new FormControl<IndividualCorporateRelationship | null>(IndividualCorporateRelationship.Individual),
      individualTrustee: new FormArray<RelationshipFormGroup>([]),
      corporateTrustee: this.createCorporateTrusteeFormGroup(),
    }),
    [FormEditTrusteeSteps.NewTrusteeDetails]: new FormGroup({
      trusteeType: new FormControl<IndividualCorporateRelationship | null>(null, [Validators.required]),
      individualTrustee: new FormArray<RelationshipFormGroup>([]),
      corporateTrustee: this.createCorporateTrusteeFormGroup(),
    }),
  });

  constructor() {
    super();
    this.setupSteps(FormEditTrusteeSteps);
  }

  ngOnInit(): void {
    this.updateSteps();
    this.setupValidators();
    this.setupExistingTrusteePreview();
    this.listenTrusteeTypeChange();
    this.setupChange();
  }

  override afterSubmit(changes: EntityChangeData[]) {
    setControlDisabled(this.existingTrusteesForm);
    this.setupChange(changes[0] as TrustChangeRelationship);
  }

  override setupChange(change: TrustChangeRelationship = this.formModel) {
    if (!this.isEdit && change === this.formModel) return;
    const trusteeType = change.relationshipsToAdd[0]?.entityDataOverride
      ? IndividualCorporateRelationship.Corporate
      : IndividualCorporateRelationship.Individual;

    this.newTrusteeDetailsForm.controls.trusteeType.patchValue(trusteeType);
    if (trusteeType === IndividualCorporateRelationship.Corporate) {
      const corporateTrustee = change.relationshipsToAdd[0];
      this.newTrusteeDetailsForm.controls.corporateTrustee.patchValue({
        name: corporateTrustee.entityDataOverride?.name,
        acn: corporateTrustee.entityDataOverride?.entityNumber,
        address: corporateTrustee.entityDataOverride?.registeredAddress
      });
    } else {
      this.newIndividualTrustees = change.relationshipsToAdd;
    }
  }

  override setCurrentStep(newStepIndex: number): void {
    this.currentStep = this.stepsSignal()[newStepIndex].step;
    this.currentStepIndex = newStepIndex;
  }

  openCreateEditIndividualTrusteeModal(index = -1): void {
    const modalRef = this.modalService.open(FormIndividualRelationshipModalComponent, { size: 'sm' });
    const instance = modalRef.componentInstance as FormIndividualRelationshipModalComponent;

    instance.individualRelationship = this.newIndividualTrustees[index] ?? null;
    instance.header = this.newIndividualTrustees[index] ? 'Edit Trustee' : 'New Trustee';
    instance.nameControlLabel = 'Trustee Name';

    modalRef.result.then(
      (individualTrustee: Relationship) => {
        individualTrustee.type = RelationshipType.Trustee;

        if (this.newIndividualTrustees[index]) {
          this.newIndividualTrustees[index] = individualTrustee;
        } else {
          this.newIndividualTrustees.push(new Relationship({
            ...individualTrustee,
            entityId: this.trustProfile.entityId,
            individualId: null,
            relationshipId: Guid.generate(),
            type: RelationshipType.Trustee,
          }));
        }
        this.newTrusteeDetailsForm.controls.individualTrustee.updateValueAndValidity();
      },
      () => {
      });
  }

  openCreateEditCorporateTrusteeDirectorModal(index = -1): void {
    const modalRef = this.modalService.open(FormIndividualRelationshipModalComponent, { size: 'sm' });
    const instance = modalRef.componentInstance as FormIndividualRelationshipModalComponent;

    instance.hideAddress = true;
    instance.header = this.corporateTrusteeDirectors[index] ? 'Edit Corporate Trustee Director' : 'New Corporate Trustee Director';
    instance.individualRelationship = this.corporateTrusteeDirectors[index] ?? null;
    instance.nameControlLabel = 'Director Name';

    modalRef.result.then(
      (corporateTrusteeDirector: Relationship) => {
        corporateTrusteeDirector.type = RelationshipType.Director;

        if (this.corporateTrusteeDirectors[index]) {
          this.corporateTrusteeDirectors[index] = corporateTrusteeDirector;
        } else {
          this.corporateTrusteeDirectors.push(new Relationship({
            ...corporateTrusteeDirector,
            relatedEntityId: this.linkedCompanyProfile?.entityId,
            individualId: null,
            relationshipId: Guid.generate(),
            type: RelationshipType.Trustee,
          }));
        }
        this.newTrusteeDetailsForm.controls.corporateTrustee.updateValueAndValidity();
      },
      () => {
      });
  }

  removeCorporateTrusteeDirector(index: number): void {
    this.corporateTrusteeDirectors.splice(index, 1);
    this.newTrusteeDetailsForm.controls.corporateTrustee.updateValueAndValidity();
    this.newTrusteeDetailsForm.updateValueAndValidity();
  }

  removeIndividualTrustee(index: number): void {
    this.newIndividualTrustees.splice(index, 1);
    this.newTrusteeDetailsForm.controls.individualTrustee.updateValueAndValidity();
  }

  getCompanyProfile(entityId: string | null): void {
    this.linkedCompanyProfile = null;
    if (!entityId)
      return;

    this.isLoading = true;
    this.companyProfileService.getCompanyProfile(entityId)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: (companyProfile) => {
          this.linkedCompanyProfile = companyProfile;
          this.newTrusteeDetailsForm.controls.corporateTrustee.controls.address.patchValue({
            ...companyProfile.registeredAddress,
            country: 'AU'
          });
          this.corporateTrusteeDirectors = companyProfile.officers.filter((officer) => officer.type === RelationshipType.Director);
          this.newTrusteeDetailsForm.controls.corporateTrustee.updateValueAndValidity();
        },
        error: (error) => {
          console.warn('Company is not found', error);
        }
      });
  }

  override buildDocument(): Document | null {
    const selectedTrusteeTypeIsIndividual = this.newTrusteeDetailsForm.controls.trusteeType.value === IndividualCorporateRelationship.Individual;

    const relationshipsToAdd = selectedTrusteeTypeIsIndividual
      ? this.newIndividualTrustees
      : [new Relationship({
        relationshipId: Guid.generate(),
        entityId: this.trustProfile.entityId,
        type: RelationshipType.Trustee,
        relatedEntityId: this.linkedCompanyProfile?.entityId,
        entityDataOverride: new EntityData({
          name: this.newTrusteeDetailsForm.controls.corporateTrustee.controls.name.value ?? '',
          entityNumber: this.newTrusteeDetailsForm.controls.corporateTrustee.controls.acn.value!,
          registeredAddress: new Address(this.newTrusteeDetailsForm.controls.corporateTrustee.controls.address.value as Partial<Address>),
        })
      })];

    const change = new TrustChangeRelationship({
      appointmentType: AppointmentType.Trustees,
      relationshipsIdToRemove: this.trustProfile.trustees.map((r) => r.relationshipId),
      relationshipsToAdd
    });

    try {
      return new Document({
        changes: [change],
        type: 't:change',
        entityId: this.trustProfile.entityId,
        documentId: this.companyChangeData?.documentId ?? Guid.EmptyGuid,
      });
    } catch (error) {
      this.toastr.error('Failed to create Document.', 'Error');
      return null;
    }
  }

  private setupValidators(): void {
    const individualTrusteeMaxQuantityValidators = () => {
      return this.newIndividualTrustees.length > this.allowedMaximumOfIndividualTrustees
        ? { tooManyIndividualTrustees: this.allowedMaximumOfIndividualTrustees }
        : null;
    };

    const individualTrusteeMinQuantityValidators = () => {
      return this.newIndividualTrustees.length < this.allowedMinimumOfIndividualTrustees
        ? { notEnoughIndividualTrustees: this.allowedMinimumOfIndividualTrustees }
        : null;
    };

    const corporateTrusteeDirectorsMaxQuantityValidators = () => {
      return this.corporateTrusteeDirectors.length > this.allowedMaximumOfCorporateTrusteeDirector
        ? { tooManyCorporateTrusteeDirectors: this.allowedMaximumOfCorporateTrusteeDirector }
        : null;
    };

    const corporateTrusteeDirectorsMinQuantityValidators = () => {
      return this.corporateTrusteeDirectors.length < this.allowedMinimumOfCorporateTrusteeDirector
        ? { notEnoughCorporateTrusteeDirectors: this.allowedMinimumOfCorporateTrusteeDirector }
        : null;
    };

    this.newTrusteeDetailsForm.controls.individualTrustee.addValidators([
      individualTrusteeMaxQuantityValidators,
      individualTrusteeMinQuantityValidators,
    ]);

    this.newTrusteeDetailsForm.controls.corporateTrustee.addValidators([
      corporateTrusteeDirectorsMaxQuantityValidators,
      corporateTrusteeDirectorsMinQuantityValidators,
    ]);
  }

  private setupExistingTrusteePreview(): void {
    const trusteesToEdit = this.trustProfile.trustees;
    const corporateTrustee = trusteesToEdit.find((trustee) => trustee.entityDataOverride) ?? null;

    if (corporateTrustee) {
      this.existingTrusteesForm.patchValue({
        trusteeType: IndividualCorporateRelationship.Corporate,
        corporateTrustee: {
          name: corporateTrustee.entityDataOverride?.name,
          acn: corporateTrustee.entityDataOverride?.entityNumber,
          address: corporateTrustee.entityDataOverride?.registeredAddress,
          isOverseasCompany: corporateTrustee.entityDataOverride?.registeredAddress.country !== 'AU'
        }
      });
    } else {
      this.existingTrusteesForm.controls.trusteeType.setValue(IndividualCorporateRelationship.Individual);
      const individualTrustee = trusteesToEdit.filter((trustee) => trustee.individualDataOverride);
      clearFormArray(this.existingTrusteesForm.controls.individualTrustee);

      individualTrustee.forEach((t) => {
        if (t.individualDataOverride) {
          this.existingTrusteesForm.controls.individualTrustee.push(this.createIndividualTrusteeFormGroup(t));
        }
      });
    }

    setControlDisabled(this.existingTrusteesForm.controls.individualTrustee);
    setControlDisabled(this.existingTrusteesForm.controls.corporateTrustee);
    setControlDisabled(this.existingTrusteesForm.controls.trusteeType);
  }

  private updateSteps(): void {
    const steps = !this.trustProfile.trustees.length
      ? this.steps.filter((step) => step.step === FormEditTrusteeSteps.NewTrusteeDetails)
      : this.steps;

    this.stepsSignal.set(steps);
    this.setCurrentStep(0);
  }

  private createIndividualTrusteeFormGroup(individualTrustee: Partial<Relationship> = {}): RelationshipFormGroup {
    const form = RelationshipFormGroupComponent.defineForm();
    form.patchValue({
      name: individualTrustee?.individualDataOverride?.fullName ?? '',
      address: individualTrustee?.individualDataOverride?.address ?? new Address({ country: 'AU' })
    });
    return form;
  }

  private createCorporateTrusteeFormGroup(corporateTrustee: Partial<Relationship> = {}): CorporateRelationshipFormGroup {
    const form = CorporateRelationshipFormGroupComponent.defineForm();
    form.patchValue({
      name: corporateTrustee.entityDataOverride?.name ?? '',
      acn: corporateTrustee.entityDataOverride?.entityNumber ?? corporateTrustee.entityDataOverride?.entityNumber ?? '',
      address: corporateTrustee.entityDataOverride?.registeredAddress ?? new Address({ country: 'AU' }),
      isOverseasCompany: false
    });
    return form;
  }

  private listenTrusteeTypeChange(): void {
    this.newTrusteeDetailsForm.controls.trusteeType.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((trusteeType) => {
        setControlDisabled(this.newTrusteeDetailsForm.controls.individualTrustee, trusteeType === IndividualCorporateRelationship.Corporate);
        setControlDisabled(this.newTrusteeDetailsForm.controls.corporateTrustee, trusteeType === IndividualCorporateRelationship.Individual);

        this.newTrusteeDetailsForm.updateValueAndValidity();
      });
  }

  get existingTrusteesForm() {
    return this.stepperForm.controls[FormEditTrusteeSteps.ExistingTrusteeDetails];
  }

  get newTrusteeDetailsForm() {
    return this.stepperForm.controls[FormEditTrusteeSteps.NewTrusteeDetails];
  }

  get allowAddCorporateTrusteeDirectors(): boolean {
    return this.corporateTrusteeDirectors.length < this.allowedMaximumOfCorporateTrusteeDirector || this.isLoading;
  }

  get allowAddIndividualTrustees(): boolean {
    return this.newIndividualTrustees.length < this.allowedMaximumOfIndividualTrustees || this.isLoading;
  }
}

