import { AbstractControl, ValidationErrors } from '@angular/forms';

export const bsbPattern =  /^\d{6}$/;

export function bsbValidator(control: AbstractControl): ValidationErrors | null {
  if (!control.value || typeof control.value !== 'string') {
    return { bsbInvalid: true };
  }

  if (!bsbPattern.test(control.value)) {
    return { bsbInvalidFormat: true };
  }

  return null;
}
