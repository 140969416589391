import { NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { TrustStatus } from '../../../../../../models/enums/trustEnum';
import { TrustStatusPipe } from '../../../../../../pipes/enumsPipes/trustStatusPipe';

@Component({
  selector: 'app-ag-trust-status',
  standalone: true,
  imports: [
    NgClass,
    TrustStatusPipe
  ],
  templateUrl: './ag-trust-status.component.html',
  styleUrl: './ag-trust-status.component.scss'
})
export class AgTrustStatusComponent implements ICellRendererAngularComp {
  status = 0;
  TrustStatus = TrustStatus;

  agInit(params: ICellRendererParams): void {
    this.status = params.value as number;
  }

  refresh(params: ICellRendererParams): boolean {
    this.status = params.value as number;
    return true;
  }
}

