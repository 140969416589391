@if (openedSecurityholder) {
  <app-securityholder-profile
    [securityholder]="openedSecurityholder"
    (profileViewChange)="profileViewChange.emit($event)"
    (onDataSaved)="loadSecurities()"
  ></app-securityholder-profile>
} @else {
  <div class="card-body">
    <app-list-grid
      [rowData]="securityRegistry"
      [colDefs]="colDefs"
      [rowSelection]="rowSelection"
      [suppressRowClickSelection]="true"
      [suppressCellFocus]="true"
      [pagination]="true"
      [paginationPageSize]="defaultPageSize"
      [paginationPageSizeSelector]="true"
      (select)="onSelectShareholders($event)"
    ></app-list-grid>
  </div>
}
