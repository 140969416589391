<div class="company-edit">
  <div class="modal-header">
    <div class="header-container">
      <h2 class="title mb-0">Edit Details</h2>
      <div><i class="icon ic-xs ic-close" (click)="close()"></i></div>
    </div>
  </div>

  <div class="modal-body" [formGroup]="form">
    <div class="input-row">
      <app-input
        class="form-field"
        [label]="'Corporate Key'"
        [customErrors]="corporateKeyMsg"
        formControlName="corporateKey"
      ></app-input>

      <app-date-picker
        class="form-field"
        [label]="'Next Annual Review'"
        formControlName="nextAnnualReviewDate"
      ></app-date-picker>
    </div>

    <app-divider [margin]="'24px 0'"></app-divider>

    <div class="input-row">
      <app-select
        class="form-field"
        [label]="'Partner'"
        [options]="usersOption"
        [valuePrimitive]="true"
        formControlName="partnerManagerId"
      ></app-select>

      <app-select
        class="form-field"
        [label]="'Account Manager'"
        [options]="usersOption"
        [valuePrimitive]="true"
        formControlName="accountManagerId"
      ></app-select>
    </div>

    <app-divider [margin]="'24px 0'"></app-divider>

    <div class="input-row">
      <app-input class="form-field" [label]="'Reference Number'" formControlName="referenceNumber"></app-input>
      <app-input class="form-field" [label]="'Family Group'" formControlName="familyGroup"></app-input>
    </div>
  </div>

  <div class="modal-footer">
    <app-button
      [text]="'Save'"
      [isDisabled]="form.invalid"
      (clickBtn)="$submit()"
    ></app-button>
    <app-button [btnColor]="'white'" [text]="'Cancel'" (clickBtn)="close()"></app-button>
  </div>
</div>
