export class CompanyStatementRequestForm {
    acn: string;
    copmanyName: string;
    requestedInformation: string;
    
    constructor(data: Partial<CompanyStatementRequestForm> = {}) {
        this.acn = data.acn ?? '';
        this.copmanyName = data.copmanyName ?? '';
        this.requestedInformation = data.requestedInformation ?? '';
    }
}
