<div class="h-100 d-flex align-items-center">
  <app-ag-action-icon-button
    iconClass="ic-edit"
    tooltipText="Edit"
    (action)="onEdit()"
  />

  <app-ag-action-icon-button
    iconClass="ic-key"
    tooltipText="Reset MFA"
    (action)="onResetMFA()"
  />

  <app-ag-action-icon-button
    iconClass="ic-lock"
    tooltipText="Reset Password"
    (action)="onResetPassword()"
  />

  <app-ag-action-icon-button
    iconClass="ic-trash"
    tooltipText="Delete"
    (action)="onDelete()"
  />
</div>
