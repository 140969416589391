export class User {
    userId: string;
    externalId: string;
    firstName: string;
    middleName: string | undefined;
    lastName: string;
    email: string;

    get fullName() {
        return [this.firstName, this.middleName, this.lastName].filter(v => v).join(' ');
    }

    constructor(data: Partial<User> = {}) {
        this.userId = data.userId!;
        this.externalId = data.externalId!;
        this.firstName = data.firstName!;
        this.middleName = data.middleName;
        this.lastName = data.lastName!;
        this.email = data.email!;
    }
}