import { Component } from '@angular/core';
import { ICellRendererParams } from "ag-grid-community";
import { OfficerStatusPipe } from "../../../../../../pipes/officerStatusPipe";
import { NgClass } from "@angular/common";

@Component({
  selector: 'app-ag-officeholders-status',
  standalone: true,
  imports: [
    OfficerStatusPipe,
    NgClass
  ],
  templateUrl: './ag-officeholders-status.component.html',
  styleUrl: './ag-officeholders-status.component.scss'
})
export class AgOfficeholdersStatusComponent {
  date!: Date;

  agInit(params: ICellRendererParams): void {
    this.date = params.value;
  }

  refresh(params: ICellRendererParams): boolean {
    this.date = params.value;
    return true;
  }
}
