import { Component } from '@angular/core';
import { ICellRendererParams } from "ag-grid-community";
import { DatePipe } from "@angular/common";

@Component({
  selector: 'app-ag-company-registration-date',
  standalone: true,
  imports: [
    DatePipe,
  ],
  templateUrl: './ag-company-registration-date.component.html',
  styleUrl: './ag-company-registration-date.component.scss'
})
export class AgCompanyRegistrationDateComponent {
  date: string = '';

  agInit(params: ICellRendererParams): void {
    this.date = params.value;
  }

  refresh(params: ICellRendererParams): boolean {
    this.date = params.value;
    return true;
  }
}
