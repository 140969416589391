import {
  booleanAttribute,
  Component, DestroyRef,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  signal
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormsModule, NgControl, ReactiveFormsModule } from "@angular/forms";
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from "@ng-bootstrap/ng-bootstrap";
import { ValidationErrorComponent } from "../../../common/validation-error/validation-error.component";
import { SelectOption, SelectOptionPrimitive } from "../../../../../models/selectOptions";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { NgClass } from "@angular/common";
import { ManualAddressService } from "../../../../../services/manual-address.service";

@Component({
  selector: 'app-suburbs-autocomplete',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    NgClass,
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
    ValidationErrorComponent,
  ],
  templateUrl: './suburbs-autocomplete.component.html',
  styleUrl: '../../../common/autocomplete/autocomplete.component.scss'
})
export class SuburbsAutocompleteComponent implements OnInit, ControlValueAccessor {
  @Input() label = 'Suburb';
  @Input() placeholder = 'Type Suburb';
  @Input() options: SelectOption[] = [];
  @Input({ transform: booleanAttribute }) disabled = false;

  @Output() selectSuburb = new EventEmitter<{ value: SelectOption, index: number }>();

  searchControl = new FormControl<string>('');
  #value: SelectOption | null = null;
  dropdownOpen = false;

  searchControlValueSignal = signal('');
  filteredOptions: SelectOption[] = [];

  constructor(
    @Optional() protected ngControl: NgControl,
    private manualAddressService: ManualAddressService,
    private destroyRef: DestroyRef
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.searchControl.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((searchTerm) => this.manualAddressService.searchSuburbs(searchTerm)),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((filteredOptions: SelectOption[]) => {
      this.filteredOptions = filteredOptions;

      if(!this.value?.value) {
        if(filteredOptions.length) {
          this.#value = filteredOptions[0];
          this.onChange(this.value?.label);
        }
        this.dropdownOpen = false;
      } else {
        this.dropdownOpen = !this.value?.value || filteredOptions.length !== 1;
      }
    });
  }

  get value(): SelectOption | null {
    return this.#value;
  }

  set value(value: SelectOption | null) {
    this.#value = value;
    this.onChange(this.value?.label);
  }

  get displayValidationError(): boolean {
    return !!(this.ngControl && this.ngControl.touched && this.ngControl.errors && this.searchControl.touched);
  }

  selectOption(selectedOption: SelectOption, index: number): void {
    this.dropdownOpen = false;
    if (this.searchControl.value !== selectedOption.value) {
      this.searchControl.patchValue(selectedOption.label);
    }
    this.value = selectedOption;
    this.selectSuburb.emit({ value: selectedOption, index });
  }

  onChange: any = (): void => {
  };
  onTouched: any = (): void => {
  };

  writeValue(value: string): void {
    this.value = this.options.find((option) => option.label === value as unknown as SelectOptionPrimitive)!;
    this.searchControl.patchValue(this.value?.label ?? value ?? null);
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
    this.searchControl.registerOnChange(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    isDisabled ? this.searchControl.disable() : this.searchControl.enable();
  }

  onDropdownChange(opened: boolean): void {
    if (opened && !this.searchControl.value) {
      this.filteredOptions = this.options.slice(0, 10);
    }

    this.searchControlValueSignal.set('');
  }
}
