import { Component, Input, OnInit } from '@angular/core';
import { NgApexchartsModule } from "ng-apexcharts";

@Component({
  selector: 'app-donut-chart',
  standalone: true,
  imports: [
    NgApexchartsModule
  ],
  templateUrl: './donut-chart.component.html',
  styleUrl: './donut-chart.component.scss'
})
export class DonutChartComponent implements OnInit {
  @Input() series: number[] = [];
  @Input() labels: string[] = [];
  @Input() name = '';

  chartOptions: any;

  ngOnInit(): void {
    this.setChartOption();
  }

  setChartOption(): void {
    this.chartOptions = {
      series: this.series,
      labels: this.labels,
      colors: [
        '#33AE61',
        '#3072F2',
        '#6230F2',
        '#F230EA',
        '#CE5252',
        '#FCC60B',
      ],
      chart: {
        height: 300,
        width: 580,
        type: 'donut',
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: false
              },
              total: {
                show: true,
                showAlways: false,
                formatter: (w) => this.name
              }
            }
          }
        }
      },
      legend: {
        show: true,
        height: 240,
        width: 210,
        itemMargin: {
          horizontal: 0,
          vertical: 7
        },
        formatter: (w, opts) => w + ' (' + opts.w.config.series[opts.seriesIndex] + '%)'
      },
    };
  }
}
