import { Component, DestroyRef, Input, Optional } from '@angular/core';
import { FormsModule, NgControl } from "@angular/forms";
import { ValidationErrorComponent } from "../validation-error/validation-error.component";
import { InputDirectorNameDirective } from "../../../../directives/input-director-name.directive";
import { AuxiliaryService } from "../../../../services/auxiliary.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { finalize } from "rxjs";

@Component({
  selector: 'app-multiple-input',
  standalone: true,
  imports: [
    FormsModule,
    ValidationErrorComponent,
    InputDirectorNameDirective
  ],
  templateUrl: './multiple-input.component.html',
  styleUrl: './multiple-input.component.scss'
})
export class MultipleInputComponent {
  @Input() label = '';
  @Input() tip = '';
  @Input() placeholder = '';
  @Input() isDisabled = false;
  @Input() shouldShowErrors = true;
  @Input() customErrors: Record<string, string> = {};
  @Input() maxInputTags = 10;

  inputValue = '';
  tags: string[] = [];
  focused = false;
  #value: string[] = [];
  loading = false;

  constructor(
    @Optional() protected ngControl: NgControl,
    private auxiliaryService: AuxiliaryService,
    private destroyRef: DestroyRef
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  get value(): string[] {
    return this.#value;
  }

  set value(value: string[]) {
    this.#value = value ?? [];
    this.onChange(this.#value);
  }

  get displayValidationError(): boolean {
    return !!(this.ngControl && this.ngControl.touched && this.ngControl.errors);
  }

  onChange: any = (): void => {};
  onTouched: any = (): void => {};

  writeValue(value: string[]): void {
    this.value = value;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  onBlur(): void {
    if(this.inputValue) {
      this.addTag();
    }

    this.focused = false;
    this.onTouched();
  }

  onSubmit(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    if (this.inputValue) {
      this.addTag();
    }
  }

  onFocus(): void {
    this.focused = true;
  }

  onKeyDown(event: KeyboardEvent): void {
    const key = event.key;

    if (key === ';') {
      event.preventDefault();
      this.addTag();
    }
  }

  addTag(): void {
    if(this.value.length > this.maxInputTags) {
      return;
    }

    const fullName = this.inputValue.trim();

    const fullNamePartsViaComma = fullName.split(',').map(n=> n.trim());
    const fullNamePartsViaSpace = fullName.split(' ').map(n=> n.trim());

    if(fullNamePartsViaComma.length > 1) {
      const name = fullNamePartsViaComma.length > 4 ? fullNamePartsViaComma.slice(0, 4).join(' ') : fullNamePartsViaComma.join(' ');
      this.#value.push(name);
      this.resetInputValue();
    } else if (fullNamePartsViaSpace.length > 1) {
      if (fullNamePartsViaSpace.length > 2) {
        const name = fullNamePartsViaSpace.length > 4 ? fullNamePartsViaSpace.slice(0, 4).join(' ') : fullName;
        this.parseFullName(name);
      } else if (fullNamePartsViaSpace.length === 2) {
        this.#value.push(fullName);
        this.resetInputValue();
      }
    }
  }

  private parseFullName(name: string): void {
    this.loading = true;
    this.auxiliaryService.getParsedName(name)
      .pipe(
        finalize(() => this.loading = false),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe({
        next: (res) => {
          const orderedName = `${ res.firstName ?? '' } ${ res.middleName1 ?? '' } ${ res.middleName2 ?? '' } ${ res.lastName ?? '' }`;
          this.#value.push(orderedName);
          this.resetInputValue();
          this.loading = false;
        },
      });
  }

  private resetInputValue(): void {
    this.inputValue = '';
    this.onChange(this.#value);
  }

  removeTag(index: number): void {
    this.#value.splice(index, 1);
    this.onChange(this.#value);
  }
}
