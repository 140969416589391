import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export function companyNameValidator(): ValidatorFn {
  return (formControl: AbstractControl): ValidationErrors | null => {
    const restrictedKeywordsPattern = /(trustee|bank|trust)\s/i;
    const proprietaryCompanyPattern = /(proprietary|pty|pty.)\s(limited|ltd|ltd.)/i;
    const publicCompanyPattern = /LTD(\.){0,1}|LIMITED/i;
    const noLiabilityCompanyPattern = /N(\.){0,1}L(\.){0,1}|NO LIABILITY/i;

    const controlValueStr = String(formControl.value);
    const isValidFormat = proprietaryCompanyPattern.test(controlValueStr) || publicCompanyPattern.test(controlValueStr) || noLiabilityCompanyPattern.test(controlValueStr);
    const isWithoutRestrictedKeywords = !restrictedKeywordsPattern.test(controlValueStr);

    const isCompanyNameValid = isValidFormat && isWithoutRestrictedKeywords;

    return isCompanyNameValid ? null : { companyName: true };
  };
}

export function getCompanyNameValidators() {
  return [Validators.required, Validators.minLength(7), companyNameValidator()];
}
