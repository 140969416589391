@if (options.length) {
  <div class="select-container">
    <div ngbDropdown class="dropdown" container="body" (openChange)="getDropdownState($event)">
      <button
        type="button"
        class="input-wrapper input"
        [ngClass]="{'opened': dropdownOpen}"
        ngbDropdownToggle
      >
        @if(selectedValue) {
          <div class="text" [title]="selectedName">{{selectedName}}</div>
        }
        <span class="icon ic-select-icon"></span>
      </button>
      <div ngbDropdownMenu class="dropdown-menu">
        @if (options.length) {
          @for(option of sortedOptions; track $index) {
            <button
              ngbDropdownItem
              type="button"
              class="dropdown-item"
              (click)="selectOption(option)"
            >
              <div class="dropdown-button">
                <span [ngClass]="{'bold-label': option.label === selectedOption?.label && option.value === selectedValue }">
                  {{ option.label }}
                </span>
                <span
                  class="icon"
                  [ngClass]="{'ic-circle-check': option.label === selectedOption?.label && option.value === selectedValue }"
                ></span>
              </div>
            </button>
          }
        }
      </div>
    </div>
  </div>
}
