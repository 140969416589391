import { Injectable, inject } from '@angular/core';
import { Observable } from "rxjs";
import { CoverLetter, CoverLetterCreateModel } from "../models/cover-letter";
import { HttpClient } from "@angular/common/http";
import { environment } from "../environments/environment";

@Injectable()
export class CoverLettersService {
  private api = inject(HttpClient);

  loadCoverLetters(): Observable<CoverLetter[]> {
    return this.api.get<CoverLetter[]>(`${ environment.api_url }/cover-letters/list`);
  }

  loadDefaultCoverLetter(): Observable<string> {
    return this.api.get<string>(`${ environment.api_url }/cover-letters/default`);
  }

  downloadCoverLetterTemplate(coverLetterId: string, filename: string): Observable<string> {
    return this.api.get<string>(`${ environment.api_url }/cover-letters/${ coverLetterId }/download`, { params: { filename } });
  }

  testCoverLetter(coverLetterId: string, filename: string): Observable<string> {
    return this.api.get<string>(`${ environment.api_url }/cover-letters/${ coverLetterId }/cover-letter-test`, { params: { filename } });
  }

  patchCoverLetter(coverLetter: CoverLetter) {
    return this.api.patch<CoverLetter[]>(`${ environment.api_url }/cover-letters`, coverLetter);
  }

  deleteCoverLetterTemplate(coverLetterId: string): Observable<void> {
    return this.api.delete<void>(`${ environment.api_url }/cover-letters/${ coverLetterId }`);
  }

  createCoverLetterTemplate(coverLetter: CoverLetterCreateModel): Observable<void> {
    return this.api.post<void>(`${ environment.api_url }/cover-letters`, coverLetter);
  }

  downloadDefaultTemplate(): Observable<string> {
    return this.api.get<string>(`${ environment.api_url }/cover-letters/default`);
  }
}
