import { Component, DestroyRef, ElementRef, inject, Input, Signal, ViewChild } from '@angular/core';
import { CommonModalFormComponent } from "../common-modal-form/common-modal-form.component";
import { InputComponent } from "../../components/common/input/input.component";
import { InputPhoneNumberComponent } from "../../components/common/input-phone-number/input-phone-number.component";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { SelectComponent } from "../../components/common/select/select.component";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SelectOption } from "../../../models/selectOptions";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import { catchError, map, of, switchMap, tap } from "rxjs";
import { DollarDirective } from "../../../directives/dollar.directive";
import jsPDF from "jspdf";
import autoTable, { Styles } from "jspdf-autotable";
import { formatDate } from "@angular/common";
import { Company } from "../../../models/company";
import { OrganisationService } from "../../../services/organisation.service";
import { ACNPipe } from "../../../pipes/acnPipe";
import { blobToBase64 } from "../../helpers/blob-to-base64";
import { AutocompleteComponent } from "../../components/common/autocomplete/autocomplete.component";

@Component({
  selector: 'app-generate-payslip',
  standalone: true,
  imports: [
    CommonModalFormComponent,
    InputComponent,
    InputPhoneNumberComponent,
    ReactiveFormsModule,
    SelectComponent,
    FormsModule,
    DollarDirective,
    AutocompleteComponent
  ],
  templateUrl: './generate-payslip.component.html',
  styleUrl: './generate-payslip.component.scss'
})
export class GeneratePayslipComponent {
  @Input() companyProfile: Company | undefined;

  @ViewChild('modalElement', { static: true }) modalElement: ElementRef | undefined;

  private organisationService = inject(OrganisationService);
  private acnPipe = inject(ACNPipe);
  private fb: FormBuilder = inject(FormBuilder);
  private activeModal = inject(NgbActiveModal);
  private destroyRef = inject(DestroyRef);

  form!: FormGroup

  logo: Signal<string | undefined> = toSignal(
    this.organisationService.getSmallLogo().pipe(
      switchMap(logo => blobToBase64(logo)),
      catchError(() => of(''))
    )
  );

  phoneNumber: Signal<string | undefined> = toSignal(
    this.organisationService.getProfile().pipe(
      map(profile => profile.phoneNumber ?? '')
    )
  );

  public reasonOptions: SelectOption[] = [
    { label: 'Change of Name confirmed', value: '491' },
    { label: 'Name Reservation confirmed', value: '61' },
    { label: 'Late fee (under 28 days)', value: '96' },
    { label: 'Late fee (over 28 days)', value: '401' },
    { label: 'Company Deregistration', value: '49' },
    { label: 'Annual Fee for Special Purpose Company', value: '65' },
    { label: 'Annual Fee for Standard Company', value: '321' },
    { label: 'Annual Fee for Proprietary Non-Profit Company', value: '61' },
    { label: 'Annual Fee for Listed Public Company', value: '1492' },
    { label: 'Annual Fee for Unlisted Public Company', value: '61' },
    { label: 'Annual Fee for Unlisted Public Non-Profit Company', value: '1492' },
    { label: 'Other', value: '' },
  ];

  private boldColumnStyles: Record<string, Partial<Styles>> = {
    key: {
      fontStyle: 'bold',
      cellWidth: 110,
      fillColor: 'white'
    },
    value: {
      fontStyle: 'bold',
      cellWidth: 90,
      fillColor: 'white'
    }
  }

  private normalColumnStyles: Record<string, Partial<Styles>> = {
    key: {
      fontStyle: 'normal',
      cellWidth: 110,
      fillColor: 'white'
    },
    value: {
      fontStyle: 'normal',
      cellWidth: 90,
      fillColor: 'white'
    }
  }

  readonly titleDelimiter = '=======================================================================';

  ngOnInit(): void {
    this.initForm();
    this.listenReason();
    this.listenPaidAmount();
  }

  private initForm(): void {
    this.form = this.fb.group({
      reason: ['', Validators.required],
      paidAmount: ['', Validators.required],
    });
  }

  private listenReason(): void {
    this.form.get('reason')?.valueChanges.pipe(
      tap((reason: SelectOption) => {
        if(!isNaN(parseFloat(reason.value as string))) {
          this.form.get('paidAmount')?.patchValue(reason.value);
        } else {
          this.form.get('paidAmount')?.patchValue('');
        }
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  private listenPaidAmount(): void {
    this.form.get('paidAmount')?.valueChanges.pipe(
      tap((paidAmount: string) => {
        if(!paidAmount.includes('$')) {
          const changedPaidAmount = '$' + paidAmount;
          this.form.get('paidAmount')?.patchValue(changedPaidAmount,{ emitEvent: false });
        }

        const invalidPattern = /^\$\D.*$/;
        if (invalidPattern.test(paidAmount)) {
          this.form.get('paidAmount')?.patchValue('$',{ emitEvent: false });
        }
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  public downloadPayslip(): void {
    this.createPDF();
    this.activeModal.close();
  }

  public close(): void {
    this.activeModal.close();
  }

  private createPDF(): void {
    const pdf = new jsPDF();

    this.createHeader(pdf);
    this.createBody(pdf);

    pdf.save('Payslip ' + formatDate(new Date(), 'dd-MM-yyyy', 'en-US') + '.pdf');
  }

  private createHeader(pdf: jsPDF): void {
    const currentProfileUser = this.organisationService.getCurrentUser();
    const headerText = 'Inquiries';
    const headerEmail = currentProfileUser?.email;
    const headerPhone = this.phoneNumber();
    const headerContent = `${headerText}\n\n${headerEmail}\n\n${headerPhone}`

    const companyName = this.companyProfile?.name ?? '';
    const companyAcn = this.acnPipe.transform(this.companyProfile?.acn ?? '');
    const companyAddress = this.companyProfile?.registeredAddress.normalizedFullAddress ?? '';
    const issueDate = 'Issue Date: ' + formatDate(new Date(), 'd MMMM yyyy', 'en-US');

    const img = new Image();
    img.src = this.logo() ?? '';

    autoTable(pdf, {
      columnStyles: this.boldColumnStyles,
      didDrawCell: (data) => {
        if (data.column.index === 0 && data.row.index === 0) {
          pdf.addImage(this.logo() ?? '', 'PNG', data.cell.x, data.cell.y, img.width, 15);
        }
      },
      body: [
        [
          { content: '', styles: { minCellHeight: 15, cellWidth: 110, fillColor: "white" } },
          {
            content: headerContent,
            styles: { cellWidth: 90, fillColor: "white", fontStyle: 'bold', overflow: 'visible' }
          },
        ],
      ],
    });

    autoTable(pdf, {
      startY: (pdf as unknown as { lastAutoTable: { finalY: number }}).lastAutoTable.finalY - 1,
      columnStyles: this.boldColumnStyles,
      body: [{ key: companyName, value: '' }],
    });

    autoTable(pdf, {
      startY: (pdf as unknown as { lastAutoTable: { finalY: number }}).lastAutoTable.finalY - 1,
      columnStyles: this.normalColumnStyles,
      body: [
        { key: companyAcn, value: '' },
        { key: companyAddress, value: '' },
      ],
    });

    autoTable(pdf, {
      columnStyles: this.boldColumnStyles,
      body: [{ key: 'Invoice Statement', value: '' }],
    });

    autoTable(pdf, {
      startY: (pdf as unknown as { lastAutoTable: { finalY: number }}).lastAutoTable.finalY - 1,
      columnStyles: this.normalColumnStyles,
      body: [{ key: issueDate, value: '' }],
    });

    autoTable(pdf, {
      startY: (pdf as unknown as { lastAutoTable: { finalY: number }}).lastAutoTable.finalY + 1,
      columnStyles: {
        key: {
          fontSize: 12,
          fontStyle: 'bold',
          fillColor: 'white'
        }
      },
      body: [{ key: this.titleDelimiter }],
    });
  }

  private createBody(pdf: jsPDF): void {
    const disclaimer = 'The fee specified in this invoice is based on the recent debt report' +
      ' and is subject to change based on ASIC.';
    const reason = (this.form.get('reason')?.value as SelectOption).label;
    const paidAmountStr = ((this.form.get('paidAmount')?.value ?? '') as string).replace('$', '');
    const paidAmount = '$' + parseFloat(paidAmountStr.replace(',', '.')).toFixed(2);

    autoTable(pdf, {
      startY: (pdf as unknown as { lastAutoTable: { finalY: number }}).lastAutoTable.finalY + 1,
      columnStyles: this.boldColumnStyles,
      body: [{ key: 'Description', value: 'Price' }],
    });

    autoTable(pdf, {
      startY: (pdf as unknown as { lastAutoTable: { finalY: number }}).lastAutoTable.finalY + 1,
      columnStyles: this.normalColumnStyles,
      body: [{ key: reason, value: paidAmount }],
    });

    autoTable(pdf, {
      startY: (pdf as unknown as { lastAutoTable: { finalY: number }}).lastAutoTable.finalY + 1,
      columnStyles: this.boldColumnStyles,
      body: [{ key: 'Total', value: paidAmount }],
    });

    autoTable(pdf, {
      startY: (pdf as unknown as { lastAutoTable: { finalY: number }}).lastAutoTable.finalY + 1,
      columnStyles: {
        key: {
          fillColor: 'white'
        },
      },
      body: [{ key: '' }, { key: '' }, { key: '' }, { key: disclaimer }],
    });
  }
}
