<div class="inline-edit-input" [class.large]="isLarge" [class.control-like]="controlLike">
  @if (label) {
    <div class="header">
      <div class="label">{{ label }}</div>
    </div>
  }
  @if (!editMode) {
    <div class="content">
      <ng-content></ng-content>
    </div>
    <app-button
      class="edit-btn"
      btnColor="white"
      [iconClass]="(controlLike ? ' ic-md' : '') + ' ic-edit icon'"
      [isDisabled]="loading"
      (clickBtn)="edit()"
    ></app-button>
  } @else {
    <input
      type="text"
      class="input"
      [class.loading]="loading"
      [disabled]="loading"
      (keyup.enter)="save()"
      [(ngModel)]="inputValue"
    >
    @if (!loading) {
      <app-button
        class="save-btn"
        [iconClass]="(controlLike ? 'ic-circle-check-20 ic-md' : 'ic-checkmark') +' icon'"
        [btnColor]="controlLike ? 'white' : 'orange'"
        [isDisabled]="loading"
        (clickBtn)="save()"
      ></app-button>

      <app-button
        class="close-btn"
        [iconClass]="(controlLike ? 'ic-circle-cancel-20 ic-md' : 'ic-close-small') +' icon'"
        btnColor="white"
        [isDisabled]="loading"
        (clickBtn)="cancel()"
      ></app-button>
    } @else {
      <i class="fad fa-spinner-third fa-spin ml-n3"></i>
    }
  }
</div>
