import { inject, Pipe, PipeTransform } from '@angular/core';
import { CountriesService } from "../services/countries.service";

@Pipe({
  name: 'country',
  standalone: true
})
export class CountryPipe implements PipeTransform {
  countriesService = inject(CountriesService);

  transform(countryCode: string | undefined | null): string {
    if (!countryCode)
      return '-';

    if (CountriesService.isAustralianState(countryCode)) {
      const countryArr = countryCode.split('-');
      return CountriesService.getCountryName(countryArr[1] ?? countryArr[0]) ?? '-';
    }

    return CountriesService.getCountryName(countryCode) ?? '-';
  }
}
