import { Component, inject } from '@angular/core';
import { ReactiveFormsModule, FormGroup, FormControl, Validators, FormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { IntegrationsService } from '../../../../../../services/integrations.service';
import { CommonModalFormComponent } from '../../../../../modals/common-modal-form/common-modal-form.component';
import { ConnectionStatusEnum } from '../../../../../../models/enums/connectionStatusEnum';
import { AnnatureAccountModel } from '../../../../../../models/annatureAccountModel';
import { SelectComponent } from '../../../../../components/common/select/select.component';
import { SelectOption } from '../../../../../../models/selectOptions';
import { IntegrationConnectionsEnum } from '../../../../../../models/enums/IntegrationConnectionsEnum';

@Component({
  selector: 'app-annature-pick-account-modal',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    SelectComponent,
    CommonModalFormComponent,
    FormsModule,
  ],
  templateUrl: './annature-pick-account-modal.component.html',
  styleUrl: './annature-pick-account-modal.component.scss'
})
export class AnnaturePickAccountModalComponent {
  private activeModal = inject(NgbActiveModal);
  private integrationsSerivice = inject(IntegrationsService);
  private toastr = inject(ToastrService);
  isLoading: boolean = false;
  isDisabled: boolean = false;

  accountOptions: SelectOption[] = [];
  accountList: AnnatureAccountModel[] = [];
  pickedAccountId = '';

  onAccountSelect(accountId: string): void {
    this.pickedAccountId = accountId;
  }

  
  ngOnInit(): void {
    this.integrationsSerivice.getAnnatureAccountList()
      .subscribe((accounts) => {
        this.accountOptions = accounts.map(account => ({
          label: `${account.name} (${account.email})`,
          value: account.id,
        }));

        this.accountList = accounts;
      });
  }

  confirm(): void {
    if (!this.pickedAccountId) {
      this.toastr.warning('Please select an account');
      return;
    }

    this.isLoading = true;
    this.isDisabled = true;
    const account = this.accountList.find(acc => acc.id === this.pickedAccountId);
    
    if (!account) {
      this.toastr.error('Account not found');
      return;
    }

    this.integrationsSerivice.setAnnatureAccount(account)
      .subscribe({
        next: (status) => {
          this.toastr.success('Connected!', 'Success');
          this.activeModal.close(status)
        },
        error: () => {
          this.isLoading = false;
          this.isDisabled = false;
          this.toastr.error('Connection Failed!', 'Error');
        }
      });
  }

  close(): void {
    this.activeModal.close(ConnectionStatusEnum.NotConnected);
  }
}
