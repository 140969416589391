import { NgModule } from "@angular/core";
import { ACNPipe } from "../pipes/acnPipe";
import { CurrencyPipe, DatePipe } from "@angular/common";
import { CountryPipe } from "../pipes/countryPipe";
import { ShareTypePipe } from "../pipes/share-type.pipe";

@NgModule({
  providers: [
    DatePipe,
    CurrencyPipe,
    ShareTypePipe,
    CountryPipe,
    ACNPipe
  ],
})
export class AppPipesModule {
}
