import { Component, Input, ViewChild } from '@angular/core';
import { BaseStepperFormComponent } from "../base-stepper-component/base-stepper-form.component";
import { EntityChangeData } from "../../../../models/entityChangeData";
import { StepperFormAppliableComponent } from "../stepper-form-appliable/stepper-form-appliable.component";
import { Document } from "../../../../models/document";
import { finalize, Observable } from "rxjs";
import { Entity } from "../../../../models/entity";

export type DocumentApplier = (document: Document) => Observable<Entity>;

@Component({
  selector: 'app-base-stepper-appliable',
  standalone: true,
  imports: [],
  template: '',
})
export class BaseStepperAppliableComponent<T, Change extends EntityChangeData> extends BaseStepperFormComponent<T, Change> {
  @ViewChild(StepperFormAppliableComponent) override stepperFormComponent!: StepperFormAppliableComponent;

  @Input() hideApplyChangesButton = false;
  @Input() documentApplier!: DocumentApplier;

  constructor() {
    super();
    this.redirectAfterSubmit = true;
  }

  override setupStepperComponentMethods(): void {
    super.setupStepperComponentMethods();
    this.takeUntilDestroyed(this.stepperFormComponent.applyChanges, () => this.applyChanges());

    if (this.stepperFormComponent && 'hideApplyChangesButton' in this.stepperFormComponent) {
      this.stepperFormComponent.hideApplyChangesButton = this.hideApplyChangesButton;
    }
  }

  applyChanges(): void {
    if (this.stepperForm.invalid) {
      this.toastr.error('Please fill required fields', 'Error');
      this.stepperForm.controls[this.currentStep as string]?.markAllAsTouched();

      return;
    }

    const document = this.buildDocument();
    if (!document)
      return;

    if (!this.documentApplier)
      throw new Error('[BaseStepperAppliableComponent] documentApplier is not implemented');

    this.isLoading = true;
    this.stepperForm.disable();

    this.documentApplier(document)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.stepperForm.enable();
          this.afterSubmit(document.changes);
        })
      )
      .subscribe({
        next: (response) => {
          this.toastr.success('Document applied', 'Success');
          this.activeModal.close(new Document(document).changes);
        },
        error: (error) => {
          console.warn(error);
          this.toastr.error('Failed to apply Document.', 'Error');
        }
      });
  }
}
