import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { PageSizeService } from "../../../components/common/grid/services/page-size.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LoaderComponent } from "../../../components/common/loader/loader.component";
import { PageTitleComponent } from "../../../components/common/page-title/page-title.component";
import { EmailTemplate } from "../../../../models/email-templates/emailTemplate";
import { EmailTemplatesService } from "../../../../services/email-templates.service";
import { finalize, Observable, tap } from 'rxjs';
import { CellClickedEvent, ColDef } from "ag-grid-community";
import {
  AgCoverLetterFileComponent
} from "../document-templates-page/components/ag-cover-letter-file/ag-cover-letter-file.component";
import {
  AgEmailTemplateActionsComponent
} from "./components/ag-email-template-actions/ag-email-template-actions.component";
import {
  EditEmailTemplateModalComponent
} from "./components/edit-email-template-modal/edit-email-template-modal.component";
import { TemplateSettingsBase } from "../../../../models/email-templates/templateSettingsBase";
import {
  EmailTemplateCodesToTypes,
  EmailTemplatesHelper
} from "../../../../models/email-templates/emailTemplatesHelper";
import { ListGridComponent } from "../../../components/common/grid/components/list-grid/list-grid.component";
import { SearchInputComponent } from "../../../components/common/search-input/search-input.component";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { toSignal } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-email-templates-page',
  standalone: true,
  providers: [EmailTemplatesService, PageSizeService],
  imports: [
    ReactiveFormsModule,
    LoaderComponent,
    PageTitleComponent,
    ListGridComponent,
    SearchInputComponent,
  ],
  templateUrl: './email-templates-page.component.html',
  styleUrls: ['./email-templates-page.component.scss', '../../settings-styles.scss'],
})
export class EmailTemplatesPageComponent implements OnInit {
  private emailTemplatesService = inject(EmailTemplatesService);
  private modalService = inject(NgbModal);

  searchControl = new FormControl<string | null>(null);
  isLoading = false;
  editEmailTemplateModalOpened = false;
  colDefs: ColDef[] = [];
  defaultTemplates: Record<string, TemplateSettingsBase> = {};
  templates = signal<EmailTemplate[]>([]);
  searchControlSignal = toSignal(this.searchControl.valueChanges);
  filteredTemplatesList = computed(() => {
    const searchValue = this.searchControlSignal()?.toLowerCase().trim();

    if (!searchValue) {
      return this.templates();
    }

    return this.templates().filter((template) => this.getTemplateHeader(template.code)?.toLowerCase().includes(searchValue));
  });

  ngOnInit(): void {
    this.setupColDefs();
    this.loadDefaultTemplates().subscribe();
    this.loadEmailTemplates().subscribe();
  }

  editDocumentTemplate(template: EmailTemplate): void {
    if (this.editEmailTemplateModalOpened) return;
    this.editEmailTemplateModalOpened = true;
    let checkIfFormWasEdited = () => false;

    const modalRef = this.modalService.open(EditEmailTemplateModalComponent, {
      size: 'xl',
      windowClass: 'edit-email-template-modal',
      beforeDismiss: () => checkIfFormWasEdited?.() ? confirm('Before closing the pop-up, please note that any unsaved changes will be lost. Do you want to proceed?') : true
    });
    const instance = modalRef.componentInstance as EditEmailTemplateModalComponent;

    checkIfFormWasEdited = () => instance.formWasEdited;
    instance.template = template;
    instance.defaultTemplate = this.defaultTemplates[EmailTemplateCodesToTypes[template.code]];

    modalRef.result.then(
      () => this.loadEmailTemplates().subscribe(() => this.editEmailTemplateModalOpened = false),
      () => this.editEmailTemplateModalOpened = false
    );
  }

  private loadEmailTemplates(): Observable<EmailTemplate[]> {
    this.isLoading = true;

    return this.emailTemplatesService.loadEmailTemplates()
      .pipe(
        tap((templates) => this.templates.set(templates)),
        finalize(() => this.isLoading = false)
      );
  }

  private loadDefaultTemplates(): Observable<Record<string, TemplateSettingsBase>> {
    return this.emailTemplatesService.loadEmailDefaultTemplates()
      .pipe(tap((defaultTemplates) => this.defaultTemplates = defaultTemplates));
  }

  private getTemplateHeader(code: string): string {
    return EmailTemplatesHelper.TemplateHeadersRecord[code] ?? code;
  }

  private setupColDefs(): void {
    this.colDefs = [
      {
        headerName: 'Template Name',
        flex: 2,
        field: 'code',
        sort: 'asc',
        cellRenderer: AgCoverLetterFileComponent,
        valueGetter: (params) => this.getTemplateHeader(params.data.code),
      },
      {
        sortable: false,
        width: 60,
        minWidth: 60,
        field: '',
        headerName: '',
        cellRenderer: AgEmailTemplateActionsComponent,
        cellRendererParams: {
          edit: this.editDocumentTemplate.bind(this),
        },
        suppressHeaderMenuButton: true,
      },
    ];
  }
}
