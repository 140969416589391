import { Injectable, Signal, signal } from '@angular/core';
import { INavMenuItem } from '../app/nav-menu/nav-menu-item.interface';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  readonly #collapsedMenu = signal(false);
  readonly #menuItems = signal<INavMenuItem[]>([]);

  setMenuState(menuState: boolean): void {
    this.#collapsedMenu.set(menuState);
  }

  setMenuItems(menuItems: INavMenuItem[]): void {
    this.#menuItems.set(menuItems);
  }

  updateMenuItem(title: string, menuItem: Partial<INavMenuItem>): INavMenuItem | null {
    const menuItemToUpdate = this.findMenuItem(this.menuItems(), title);

    if (menuItemToUpdate) {
      Object.assign(menuItemToUpdate, menuItem);

      return menuItemToUpdate;
    }

    return null;
  }

  findMenuItem(items: INavMenuItem[], titleToFind: string): INavMenuItem | undefined {
    for (const item of items) {
      if (item.title === titleToFind) {
        return item;
      }

      if (item.nestedItems) {
        const nestedItem = this.findMenuItem(item.nestedItems, titleToFind);

        if (nestedItem) {
          return nestedItem;
        }
      }
    }

    return undefined;
  }

  get collapsedMenu(): Signal<boolean> {
    return this.#collapsedMenu;
  }

  get menuItems(): Signal<INavMenuItem[]> {
    return this.#menuItems;
  }
}
