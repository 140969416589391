import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const edgeLoginNumberErrorMessage = 'The Edge login number must begin with the letter A, followed by five digits.';

export function edgeLoginNumberValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const edgeLoginNumberPattern = /^[AS]\d{5}$/;

    if (!value) {
      return null;
    }

    const isValid = edgeLoginNumberPattern.test(value);
    return isValid ? null : { edgeLoginNumber: true };
  };
}
