<app-radio
  [label]="label"
  [link]="link"
  [text]="text"
  [helperText]="helperText"
  [horizontalAlign]="horizontalAlign"
  [customErrors]="customErrors"
  [formControl]="control"
  [options]="yesNoSelectOptions"
></app-radio>
