import { Component, inject, Input } from '@angular/core';
import { StepperFormDescriptionHelperService } from '../stepper-form-description-helper.service';

@Component({
  selector: 'app-stepper-form-description',
  standalone: true,
  templateUrl: './stepper-form-description.component.html',
  styleUrls: ['./stepper-form-description.component.scss', '../base-stepper-component/base-stepper-form.component.scss'],
  providers: [StepperFormDescriptionHelperService]
})
export class StepperFormDescriptionComponent {
  descriptionHelper = inject(StepperFormDescriptionHelperService);

  @Input() formKey: string | null = null;
  @Input() hidden = false;

  @Input('formKey') set formKeySetter(value: string) {
    this.formKey = value;
    this.description = this.descriptionHelper.getDescription(value);
  }

  description: { key: string; value: string; }[] = [];
}
