<div class="radios-container">
  @if (label) {
    <div class="header">
      <div class="label">{{ label }}</div>

      @if (link && text) {
        <div>
          <app-link [link]="link" [text]="text"></app-link>
        </div>
      }
    </div>
  }

  <ul class="radios-list" [class.vertical]="horizontalAlign" [class.no-label]="!label">
    @for (option of options; track option.value; let index = $index) {
      <li>
        <label class="radio-label" [class.disabled]="option.disabled">
          <input
            type="radio"
            [tabIndex]="index"
            [name]="controlsName"
            [value]="option.value"
            [checked]="value === option.value"
            [disabled]="disabled || option.disabled"
            (change)="selectRadio(option, index)">
          <span class="out-circle"></span>
          <span class="inner-circle"></span>
          <span class="radio-text-label">{{ option.label }}</span>
        </label>
      </li>
    }
  </ul>

  @if (helperText) {
    <div class="helper">{{ helperText }}</div>
  }

  @if (displayValidationError) {
    <app-validation-error [customErrors]="customErrors"></app-validation-error>
  }
</div>
