import { enumToSelectOptions } from "../functions/enums-to-list-formatter";
import { Relationship } from "./relationship";
import { EntityChangeData } from "./entityChangeData";
import { IPDFSection } from "./shared/pdf-section.interface";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Company } from "./company";
import { Document } from "./document";
import {
  FormChangeOfTrusteeComponent
} from "../app/trusts/components/form-edit-trustee/form-change-of-trustee.component";
import { ChangeDictionaryHelper } from "./shared/change-dictionary-helper.model";
import { Entity } from "./entity";

export class TrustChangeRelationship extends EntityChangeData {
  static override $type = 'TrustChangeRelationship';

  appointmentType: AppointmentType;
  relationshipsToAdd: Relationship[];
  relationshipsIdToRemove: string[];

  constructor(data: Partial<TrustChangeRelationship> = {}) {
    super(data);

    this.appointmentType = data.appointmentType!;
    this.relationshipsToAdd = data.relationshipsToAdd?.map((r) => new Relationship(r)) ?? [];
    this.relationshipsIdToRemove = data.relationshipsIdToRemove ?? [];
  }

  override fillModal(modalRef: NgbModalRef, document: Document, actualCompany: Company): NgbModalRef {
    modalRef = this.fillModalWithCompany(modalRef, actualCompany, true);
    (modalRef.componentInstance as FormChangeOfTrusteeComponent).companyChangeData.documentId = document.documentId;
    return modalRef;
  }

  override fillModalWithCompany(modalRef: NgbModalRef, company: Company, isEdit: boolean): NgbModalRef {
    const instance = modalRef.componentInstance as FormChangeOfTrusteeComponent;
    instance.isEdit = isEdit;
    instance.formModel = structuredClone(this);
    return modalRef;
  }

  override toDictionary(): { key: string; value: string }[] {
    const dict = new ChangeDictionaryHelper();
    const relationshipNames = AppointmentType[this.appointmentType];

    dict
      .addIds(`Previous ${ relationshipNames }`, this.relationshipsIdToRemove)
      .addIds(`Current ${ relationshipNames }`, this.relationshipsToAdd.map((r) => r.relationshipId));

    return dict.dictionary;
  }

  override prepareToRequest(): EntityChangeData {
    return super.prepareToRequest();
  }
}

export enum AppointmentType {
  Trustees = 0,
  Appointor = 1,
  Settlor = 2,
}

export enum FormEditTrusteeSteps {
  ExistingTrusteeDetails = 0,
  NewTrusteeDetails = 1
}

export enum IndividualCorporateRelationship {
  Individual,
  Corporate
}

export const individualCorporateTrusteeOptions = enumToSelectOptions(IndividualCorporateRelationship);
