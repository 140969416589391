<app-common-modal-form
  icon="icon ic-edit"
  [subheader]="subheader"
  [header]="titleFormControl.value ?? ''"
  [isLoading]="isLoading"
  (confirm)="confirm()"
  (close)="close()"
>
  <div class="confirm-button">
    <button
      type="button"
      class="btn delete-button"
      [hidden]="!editedCoverLetter"
      [disabled]="isLoading"
      (click)="deleteTemplate()"
    >
      Delete
    </button>
  </div>

  <div class="cover-letter-modal">
    <div class="form-group">
      <app-input
        label="Title"
        [formControl]="titleFormControl"
        [customErrors]="titleControlCustomErrors"
      />
    </div>

    <div class="tag-control form-group">
      <div
        #selectTagsDropdown="ngbDropdown"
        ngbDropdown
        class="tags-input-wrapper"
      >
        <div class="tags-input-label">Tags</div>
        <div ngbDropdownAnchor class="tags-input">
          <ul class="selected-tags-list" (click)="tagsSearchInput.focus()">
            @for (tag of selectedTags; track tag) {
              <li class="selected-tag">
                <app-tag [tag]="tag"/>
              </li>
            }

            <input
              #tagsSearchInput
              ngbDropdownToggle
              class="tags-search-input"
              type="text"
              [placeholder]="selectedTags.length ? '' : selectTagsInputPlaceholder"
              [formControl]="search"
              (input)="onSearchInputChange($event); selectTagsDropdown?.open()"
              (keydown)="removeLastTagElement($event)"
            >
          </ul>

          <div ngbDropdownMenu class="p-0">
            <app-select-tags-dropdown
              notFoundSuggestedMessage=""
              [allowAddNewTag]="false"
              [formControl]="search"
              [showOnlyAllTags]="true"
              [searchVisible]="false"
              [tags]="selectedTags"
              [notFoundMessage]="notFoundMessage"
              (assignTag)="assignTag($event)"
            />
          </div>
        </div>
      </div>
    </div>

    @if (fileToUpload) {
      <div class="attached-file-label" (click)="$event.stopPropagation(); $event.preventDefault()">
        <app-attached-file-label
          [readonly]="false"
          [filename]="fileToUpload.fileName"
          [hideDeleteBtn]="!!editedCoverLetter"
          (downloadFile)="downloadFile()"
          (deleteFile)="fileToUpload = null"
        />
      </div>
    } @else {
      <div class="form-group">
        <app-file-upload
          label="Template"
          [isLoading]="isLoading"
          [multiple]="false"
          [acceptedFormatsList]="acceptedFormatsList"
          (change)="onFilesLoaded($event)"
        />
      </div>
    }

    @if (errorMessage) {
      <app-disclaimer color="red">{{ errorMessage }}</app-disclaimer>
    }
  </div>
</app-common-modal-form>
