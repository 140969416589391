export const emailValidatorMsg = { invalidEmail: 'Enter valid email' };

export const phoneValidatorMsg = {
  invalidMobileNumber: 'Enter please valid mobile number, e.g.: 0400 000 000',
  invalidLandlineNumber: 'Enter please valid landline phone number, it must be start with \'02\', \'03\', \'07\', \'08\' (e.g.: 0700 000 000)',
  invalid1300Number: 'Enter please valid 1300 phone number, e.g.: 1300 000 000',
  invalid13Number: 'Enter please valid 13 phone number, e.g.: 13 10 00',
  minLength: 'Minimum length is 6 numbers'
};

export const directorFullNameValidatorMsg = {
  startsWithDigit: 'A first name or last name must not start with a digit',
  nameWithDigit: 'Digits are not allowed for name',
  doesNotHaveLastName: 'Last name is required',
  oneLetterPartName: 'Initials are not allowed'
}

export const directorFirstNameValidatorMsg = {
  nameWithDigit: 'Digits are not allowed for name',
  doesNotStartWithUppercase: 'Name has to start with a capital letter and contain at least two characters.',
  oneWordName: 'Only one word is allowed for the name.'
}

export const corporateKeyMsg = {
  invalidCorporateKey: 'Enter please valid corporate key, it should be exactly 8 digits long'
}
