import { Component, computed, inject, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { ColDef } from 'ag-grid-community';
import { ToastrService } from 'ngx-toastr';
import { catchError, finalize, Observable, of, switchMap } from 'rxjs';
import { Tag } from '../../../../models/tag';
import { TagsService } from '../../../../services/tags.service';
import { ButtonComponent } from '../../../components/common/button/button.component';
import { DividerComponent } from '../../../components/common/divider/divider.component';
import { GridComponent } from '../../../components/common/grid/components/grid/grid.component';
import { ListGridComponent } from '../../../components/common/grid/components/list-grid/list-grid.component';
import {
  AgManageTagsActionsComponent
} from '../../../components/common/grid/components/manage-tags/manage-tags-actions/ag-manage-tags-actions.component';
import { PageSizeService } from '../../../components/common/grid/services/page-size.service';
import { LoaderComponent } from '../../../components/common/loader/loader.component';
import { PageTitleComponent } from '../../../components/common/page-title/page-title.component';
import { SearchInputComponent } from '../../../components/common/search-input/search-input.component';
import { TagComponent } from '../../../components/common/tag/tag.component';

export const deleteTagMessage = 'Deleting the tag will permanently remove it from the available list and all associated companies.';
export const editTagMessage = 'The new tag name will be applied to all related entities.';

@Component({
  selector: 'app-manage-tags',
  standalone: true,
  imports: [
    ButtonComponent,
    DividerComponent,
    NgbDropdown,
    NgbDropdownMenu,
    NgbDropdownToggle,
    PageTitleComponent,
    SearchInputComponent,
    ReactiveFormsModule,
    ListGridComponent,
    LoaderComponent,
    GridComponent
  ],
  templateUrl: './manage-tags.component.html',
  styleUrls: ['./manage-tags.component.scss', '../../settings-styles.scss'],
})
export class ManageTagsComponent implements OnInit {
  private tagsService = inject(TagsService);
  private toastr = inject(ToastrService);
  public pageSizeService = inject(PageSizeService);

  searchControl = new FormControl<string | null>(null);
  isLoading = false;
  allTags = this.tagsService.allTags;
  searchControlSignal = toSignal(this.searchControl.valueChanges);
  filteredTagList = computed(() => {
    const searchValue = this.searchControlSignal()?.toLowerCase().trim();

    if (!searchValue) {
      return this.allTags();
    }

    return this.allTags().filter(tag => tag.name?.toLowerCase().includes(searchValue));
  });

  ngOnInit(): void {
    this.loadTags().subscribe();
  }

  addTag(): void {
    const { modalRef, instance } = this.tagsService.openCreateEditTagModal();
    instance.isEdit = false;

    modalRef.result.then((newTag: Tag) => {
        this.createOrEditTag(newTag);
      },
      () => {
      });
  }

  editTag(tag: Tag): void {
    const { modalRef, instance } = this.tagsService.openCreateEditTagModal();
    instance.isEdit = true;
    instance.tag = tag;

    modalRef.result.then((editedTag: Tag) => {
        this.createOrEditTag(editedTag);
        this.toastr.info(editTagMessage);
      },
      () => {
      });
  }

  deleteTag(tagToDelete: Tag): void {
    if (!confirm(deleteTagMessage))
      return;
    this.isLoading = true;
    this.tagsService.deleteTag(tagToDelete.tagId)
      .pipe(
        catchError((error) => {
          console.warn('[ManageTagsComponent] getTags: ', error);
          this.toastr.error(`Failed to delete "${ tagToDelete.name }" tag.`);
          return of([]);
        }),
        switchMap(() => this.loadTags())
      )
      .subscribe();
  }

  private loadTags(): Observable<Tag[]> {
    this.isLoading = true;
    return this.tagsService.getTags().pipe(finalize(() => this.isLoading = false));
  }

  private createOrEditTag(tag: Tag): void {
    this.isLoading = true;
    this.tagsService.createOrUpdateTag(tag, '', false)
      .pipe(
        catchError((error) => {
          console.warn('[ManageTagsComponent] getTags: ', error);
          this.toastr.error('Failed to load tags.');
          return of([]);
        }),
        switchMap(() => this.loadTags())
      )
      .subscribe(() => this.toastr.success('Data saved successfully', 'Success'));
  }

  readonly colDefs: ColDef[] = [
    {
      headerName: 'Tag Name',
      cellClass: 'align-items-center d-flex',
      field: 'name',
      sort: 'asc',
      cellRenderer: TagComponent,
      flex: 1
    },
    {
      headerName: 'Description',
      field: 'description',
      flex: 2
    },
    {
      headerName: '',
      cellRendererParams: {
        edit: this.editTag.bind(this),
        delete: this.deleteTag.bind(this),
      },
      cellRenderer: AgManageTagsActionsComponent,
      width: 90,
      suppressHeaderMenuButton: true
    },
  ];
}
