import { Component } from '@angular/core';
import { AlertsStatusPipe } from "../../../../../../../pipes/enumsPipes/alertsStatusPipe";
import { SplitByCapitalLetterPipePipe } from "../../../../../../../pipes/split-by-capital-letter-pipe.pipe";
import { ICellRendererParams } from "ag-grid-community";
import { ResolvedStatus } from "../../../../../../../models/enums/alertEnums";
import { NgClass } from "@angular/common";

@Component({
  selector: 'app-ag-alerts-resolved-status',
  standalone: true,
  imports: [
    AlertsStatusPipe,
    SplitByCapitalLetterPipePipe,
    NgClass
  ],
  templateUrl: './ag-alerts-resolved-status.component.html',
  styleUrl: './ag-alerts-resolved-status.component.scss'
})
export class AgAlertsResolvedStatusComponent {
  params!: ICellRendererParams;
  resolvedStatus: ResolvedStatus | undefined | null;
  protected readonly ResolvedStatus = ResolvedStatus;

  agInit(params: ICellRendererParams<ResolvedStatus, ResolvedStatus>): void {
    this.params = params;
    this.resolvedStatus = params.value;
  }

  refresh(params: ICellRendererParams<ResolvedStatus, ResolvedStatus>): boolean {
    this.params = params;
    return true;
  }
}
