import {FilterOption} from "../../models/gridFilter";

export function createFilterOptions<T>(values: T[], label: keyof T, value: keyof T): FilterOption[] {
  return (values.map((v) => ({
    label: v[label],
    value: v[value],
    active: false
  })) as FilterOption[])
    .sort((a, b) => a.label.localeCompare(b.label));
}
