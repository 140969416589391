<div>
  <div class="form-group" [class.hidden]="hideAcn">
    <app-masked-input
      placeholder="___ ___ ___"
      [formControl]="companyAcn"
      [label]="acnTitle"
      [customErrors]="{ required: 'ACN is required.' }"
      [mask]="'___ ___ ___'"
      (focusout)="onAcnInputBlur()"
    ></app-masked-input>
  </div>

  <div class="hide-empty">
    <ng-content></ng-content>
  </div>

  <div class="form-group">
    <app-input
      placeholder=""
      [formControl]="companyNameControl"
      [label]="nameTitle"
      [customErrors]="{ required: 'Name is required.', minLength: 'Company name does not seem to be valid.' }"
    ></app-input>

    @if (shouldLookForCompanyName && cantFindCompanyName && companyNameControl.invalid) {
      <div class="helper error">Can't find company name by ACN. Please enter it manually.</div>
    }
  </div>
</div>
