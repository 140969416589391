import { Component, inject, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom, Observable } from 'rxjs';
import { CommonModalFormComponent } from "../common-modal-form/common-modal-form.component";

@Component({
  selector: 'app-confirm',
  standalone: true,
  templateUrl: './confirm.component.html',
  styleUrl: './confirm.component.scss',
  imports: [CommonModalFormComponent]
})
export class ConfirmComponent implements OnInit {
  private activeModal = inject(NgbActiveModal);

  title = '';
  message = '';
  icon = '';
  closeText = "Cancel";
  confirmText = "Save";
  loading = false;
  confirm: (() => Observable<unknown>) | null = null;

  ngOnInit(): void {
    this.activeModal.update({ size: 'sm', modalDialogClass: 'common-modal-form-dialog overflow-y-auto' });
  }

  close(): void {
    this.activeModal.close(false);
  }

  async onConfirm(): Promise<void> {
    if (!this.confirm) {
      this.activeModal.close(false);
      return;
    }

    this.loading = true;

    try {
      await firstValueFrom(this.confirm());
      this.activeModal.close(true);
    } finally {
      this.loading = false;
    }
  }
}
