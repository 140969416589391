import { CurrencyPipe } from '@angular/common';

export class SecurityType {
  securityTypeId: string;
  identifier: string;
  class: string;
  nominalPrice: number;
  paidAmount: number;
  unpaidAmount: number;
  name: string;
  entityId: string;
  isPosted: boolean;

  constructor(data: Partial<SecurityType> = {}) {
    this.securityTypeId = data.securityTypeId ?? '';
    this.identifier = data.identifier ?? '';
    this.class = data.class ?? 'ORD';
    this.nominalPrice = data.nominalPrice ?? 1;
    this.paidAmount = data.paidAmount ?? 1;
    this.unpaidAmount = data.unpaidAmount ?? 0;
    this.name = data.name ?? 'Ordinary';
    this.entityId = data.entityId ?? '';
    this.isPosted = data.isPosted ?? false;
  }

  isEqual(securityType: SecurityType): boolean {
    if (this.securityTypeId && this.securityTypeId === securityType.securityTypeId) {
      return true;
    }

    return (
      this.class === securityType.class
      && this.nominalPrice === securityType.nominalPrice
      && this.paidAmount === securityType.paidAmount
      && this.unpaidAmount === securityType.unpaidAmount
    );
  }

  static buildIdentifier(securityType: SecurityType): string {
    const currencyPipe = new CurrencyPipe('en-AU');
    return `${securityType.class}@${currencyPipe.transform(securityType.nominalPrice, '$', 'code', '1.2-2')}/${currencyPipe.transform(securityType.unpaidAmount, '$', 'code', '1.2-2')}`;
  }
}
