import { CurrencyPipe } from '@angular/common';
import { booleanAttribute, Component, Input } from '@angular/core';
import { SecurityType } from '../../../../../../../models/securityType';

@Component({
  selector: 'app-share-class-type-label',
  standalone: true,
  imports: [
    CurrencyPipe
  ],
  templateUrl: './share-class-type-label.component.html',
  styleUrl: './share-class-type-label.component.scss'
})
export class ShareClassTypeLabelComponent {
  @Input() securityType!: SecurityType;
  @Input({ transform: booleanAttribute }) hidePrice = false;
  readonly currency = 'AUD';
}
