import { Component, inject } from '@angular/core';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ICellRendererParams } from "ag-grid-community";
import { AnnualRecord } from "../../../../../../../models/annualRecord";
import { AnnualStatementPageFilter, AnnualStatementStatus } from "../../../../../../../models/enums/annualStatementEnums";
import { TooltipComponent } from "../../../../tooltip/tooltip.component";
import { AnnualStatementsService } from "../../../../../../../services/annual-statements.service";
import { ConfirmComponent } from "../../../../../../modals/confirm/confirm.component";
import { of } from "rxjs";

type ActionEvent = (data: unknown) => void;

type CellRendererParams = ICellRendererParams<AnnualRecord> & {
  view: ActionEvent,
  send: ActionEvent,
  edit: ActionEvent,
  downloadDocuments: ActionEvent,
  archive: ActionEvent,
  sendReminder: ActionEvent,
  sendPaymentReminder: ActionEvent,
  regenerate: ActionEvent,
  markSent: ActionEvent,
  markSigned: ActionEvent
};

@Component({
  selector: 'app-ag-annual-actions',
  standalone: true,
  imports: [
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
    TooltipComponent
  ],
  templateUrl: './ag-annual-actions.component.html',
  styleUrl: './ag-annual-actions.component.scss'
})
export class AgAnnualActionsComponent {
  protected modalService = inject(NgbModal);
  protected annualStatementsService = inject(AnnualStatementsService);

  protected params!: CellRendererParams;
  protected status: AnnualStatementStatus | null | undefined;
  protected isPaid: boolean | undefined;
  protected documentId: string | null | undefined;
  protected debt: number | undefined;
  protected AnnualStatementStatus = AnnualStatementStatus;
  protected AnnualStatementPageFilter = AnnualStatementPageFilter;
  protected readonly tooltipMessage = 'Customise and Send ';
  protected menu: { name: string, iconClass: string, action: () => void }[] = [];
  protected readonly menuActions = [
    { name: 'Download Documents', iconClass: 'icon ic-download-file mr-1', action: () => this.downloadDocuments() },
    { name: 'Archive', iconClass: 'icon ic-archive mr-1', action: () => this.archive() },
    { name: 'Send ad-hoc Reminder', iconClass: 'icon ic-bell mr-1', action: () => this.sendReminder() },
    { name: 'Re-generate', iconClass: 'icon ic-refresh mr-1', action: () => this.regenerate() },
    { name: 'Manually Mark as Sent', iconClass: 'icon ic-circle-check mr-1', action: () => this.markSent() },
    { name: 'Manually Mark as Signed', iconClass: 'icon ic-circle-check mr-1', action: () => this.markSigned() },
  ];

  protected readonly notReceivedMenuActions = [
    { name: 'Ignore', iconClass: 'icon ic-download-file mr-1', action: () => this.ignore() },
    { name: 'Reprint Annual Statement (RA71)', iconClass: 'icon ic-archive mr-1', action: () => this.reprint() },
    { name: 'Re-generate', iconClass: 'icon ic-refresh mr-1', action: () => this.regenerate() },
  ];

  protected readonly menuSentActions = [
    { name: 'Download Documents', iconClass: 'icon ic-download-file mr-1', action: () => this.downloadDocuments() },
    { name: 'Archive', iconClass: 'icon ic-archive mr-1', action: () => this.archive() },
    { name: 'Send ad-hoc Signing Reminder', iconClass: 'icon ic-bell mr-1', action: () => this.sendReminder() },
    { name: 'Send ad-hoc Payment Reminder', iconClass: 'icon ic-bell mr-1', action: () => this.sendPaymentReminder() },
    { name: 'Refresh Debt via RA63', iconClass: 'icon ic-refresh mr-1', action: () => this.regenerate() },
    { name: 'Mark as Signed', iconClass: 'icon ic-circle-check mr-1', action: () => this.markSigned() },
  ];

  protected readonly menuSignedActions = [
    { name: 'Download Documents', iconClass: 'icon ic-download-file mr-1', action: () => this.downloadDocuments() },
    { name: 'Archive', iconClass: 'icon ic-archive mr-1', action: () => this.archive() },
    { name: 'Send ad-hoc Payment Reminder', iconClass: 'icon ic-bell mr-1', action: () => this.sendPaymentReminder() },
    { name: 'Refresh Debt via RA63', iconClass: 'icon ic-refresh mr-1', action: () => this.regenerate() },
  ];

  protected readonly menuPaidActions = [
    { name: 'Download Documents', iconClass: 'icon ic-download-file mr-1', action: () => this.downloadDocuments() },
    { name: 'Archive', iconClass: 'icon ic-archive mr-1', action: () => this.archive() },
    { name: 'Send ad-hoc Signing Reminder', iconClass: 'icon ic-bell mr-1', action: () => this.sendReminder() },
    { name: 'Refresh Debt via RA63', iconClass: 'icon ic-refresh mr-1', action: () => this.regenerate() },
    { name: 'Mark as Signed', iconClass: 'icon ic-circle-check mr-1', action: () => this.markSigned() },
  ];

  protected readonly menuFinalizedActions = [
    { name: 'Download Documents', iconClass: 'icon ic-download-file mr-1', action: () => this.downloadDocuments() },
    { name: 'Archive', iconClass: 'icon ic-archive mr-1', action: () => this.archive() },
  ];

  protected readonly menuArchivedActions = [
    { name: 'Download Documents', iconClass: 'icon ic-download-file mr-1', action: () => this.downloadDocuments() },
  ];

  agInit(params: CellRendererParams): void {
    this.params = params;
    this.status = params.data?.status;
    this.isPaid = params.data?.isPaid;
    this.documentId = params.data?.documentId;
    this.debt = params.data?.debt ?? 0;

    this.setMenuOption();
  }

  refresh(params: CellRendererParams): boolean {
    this.params = params;
    this.status = params.data?.status;
    this.isPaid = params.data?.isPaid;
    this.documentId = params.data?.documentId;
    this.debt = params.data?.debt ?? 0;
    return true;
  }

  setMenuOption(): void {
    const page = this.annualStatementsService.pageFilter();
    const paymentReminderName = 'Send ad-hoc Payment Reminder';

    if(page === AnnualStatementPageFilter.NotReceived) {
      this.menu = this.notReceivedMenuActions;
    } else if(this.isPaid) {
      this.menu = this.menuPaidActions;
    } else if(this.status === AnnualStatementStatus.Sent) {
      if(page === AnnualStatementPageFilter.Overdue || page === AnnualStatementPageFilter.PaymentDue) {
        this.menu = this.menuSentActions;
      } else {
        this.menu = this.menuSentActions.filter(item => item.name !== paymentReminderName);
      }
    } else if(this.status === AnnualStatementStatus.Signed) {
      if(page === AnnualStatementPageFilter.Overdue || page === AnnualStatementPageFilter.PaymentDue) {
        this.menu = this.menuSignedActions;
      } else {
        this.menu = this.menuSignedActions.filter(item => item.name !== paymentReminderName);
      }
    } else if(this.status === AnnualStatementStatus.Finalized) {
      this.menu = this.menuFinalizedActions;
    } else if(this.status === AnnualStatementStatus.Archived) {
      this.menu = this.menuArchivedActions;
    } else {
      this.menu = this.menuActions;
    }
  }

  view(): void {
    this.params.view(this.params.data?.companyId);
  }

  send(): void {
    this.params.send(this.params.data?.documentId);
  }

  edit(): void {
    this.params.edit(this.params.data?.documentId);
  }

  downloadDocuments(): void {
  }

  archive(): void {
    if(this.status !== AnnualStatementStatus.Finalized) {
      const modalRef = this.modalService.open(ConfirmComponent);
      (modalRef.componentInstance as ConfirmComponent).title = 'Archive warning';
      (modalRef.componentInstance as ConfirmComponent).message = 'Once you change the status to "Archive",' +
        ' you will have limited options for managing the annual statement. This means you will not be able to refresh' +
        ' the debt or send the annual statement. If annual statements have already been sent for e-signing' +
        ' and are still in progress, the e-signing process will be cancelled. Payment reminders will not be sent either.\n' +
        'Would you like to continue?';
      (modalRef.componentInstance as ConfirmComponent).confirmText = 'Yes';
      (modalRef.componentInstance as ConfirmComponent).closeText = 'No';
      (modalRef.componentInstance as ConfirmComponent).confirm = () => {
        this.params.archive(this.params.data?.companyId);
        return of(true);
      };
    } else {
      this.params.archive(this.params.data?.companyId);
    }
  }

  sendReminder(): void {
    this.params.sendReminder(this.params.data?.companyId);
  }

  sendPaymentReminder(): void {
    this.params.sendPaymentReminder(this.params.data?.companyId);
  }

  regenerate(): void {
    if(!this.debt) {
      const modalRef = this.modalService.open(ConfirmComponent);
      (modalRef.componentInstance as ConfirmComponent).title = 'No debt warning';
      (modalRef.componentInstance as ConfirmComponent).message = 'The company currently has no debt.' +
        ' Would you like to regenerate the annual statement?';
      (modalRef.componentInstance as ConfirmComponent).confirmText = 'Yes';
      (modalRef.componentInstance as ConfirmComponent).closeText = 'No';
      (modalRef.componentInstance as ConfirmComponent).confirm = () => {
        this.params.regenerate(this.params.data?.companyId);
        return of(true);
      };
    }
  }

  markSent(): void {
  }

  markSigned(): void {
    const modalRef = this.modalService.open(ConfirmComponent);
    (modalRef.componentInstance as ConfirmComponent).title = 'Sign warning';
    (modalRef.componentInstance as ConfirmComponent).message = 'E-signing is currently in progress.' +
      ' If you mark the annual statement as "Signed," it will prevent signatories from signing and the envelope' +
      ' from being voided. Would you like to proceed?';
    (modalRef.componentInstance as ConfirmComponent).confirmText = 'Yes';
    (modalRef.componentInstance as ConfirmComponent).closeText = 'No';
    (modalRef.componentInstance as ConfirmComponent).confirm = () => {
      this.params.markSigned(this.params.data?.companyId);
      return of(true);
    };
  }

  ignore(): void {
  }

  reprint(): void {
  }
}
