<div style="display: flex">
  <div class="date-range-picker-container" [style.width]="width">
    <div class="header">
      @if(label) {
        <div class="label">{{label}}</div>
      }
    </div>

    <div class="dp-hidden position-absolute">
      <div class="input-group">
        <input
          name="datepicker"
          class="form-control"
          ngbDatepicker
          #d="ngbDatepicker"
          [autoClose]="'outside'"
          (dateSelect)="onDateSelection($event)"
          [displayMonths]="2"
          [dayTemplate]="t"
          [weekdays]="3"
          [minDate]="minDate"
          [maxDate]="maxDate"
          outsideDays="hidden"
          [startDate]="fromDate!"
          (blur)="d.close()"
          tabindex="-1"
        />
        <ng-template #t let-date let-focused="focused">
        <span
          class="custom-day"
          [class.focused]="focused"
          [class.range]="isRange(date)"
          [class.faded]="isHovered(date) || isInside(date)"
          (mouseenter)="hoveredDate = date"
          (mouseleave)="hoveredDate = null"
        >
          {{ date.day }}
        </span>
        </ng-template>
      </div>
    </div>

    <div class="input-wrapper">
      <input
        #dpFromDate
        class="input"
        name="dpFromDate"
        autofocus
        [mask]="mask"
        [showMaskTyped]="true"
        [leadZeroDateTime]="false"
        [dropSpecialCharacters]="false"
        [tabindex]="0"
        [placeholder]="placeholder"
        [(ngModel)]="value"
        [readonly]="false"
        (keydown.enter)="onEnterPress()"
      />

      <div class="input-icon" [tabindex]="0" (click)="d.toggle()" (keydown)="d.toggle()">
        <div class="icon ic-md ic-calendar"></div>
      </div>

    </div>

    @if(helperText) {
      <div class="helper">{{helperText}}</div>
    }
  </div>
  @if (showActions) {
    <div class="actions-container d-flex justify-content-center align-items-center">
      <button class="done-btn ml-4" (click)="onActionButtonClick()">
        <span class="icon ic ic-md ic-done-circle"></span>
      </button>
      <button class="ml-2 cancel-btn" (click)="onActionButtonClick(false)">
        <span class="icon ic ic-md ic-cancel-circle"></span>
      </button>
    </div>
  }
</div>
