import { Component, inject, OnInit, viewChildren } from '@angular/core';
import { PageTitleComponent } from "../../../components/common/page-title/page-title.component";
import {
  EditTextMessageTemplateComponent
} from "./components/edit-text-message-template/edit-text-message-template.component";
import { combineLatest, finalize, Observable, tap } from "rxjs";
import { LoaderComponent } from "../../../components/common/loader/loader.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { TextMessageTemplatesService } from "../../../../services/sms-templates.service";
import { TextMessageTemplate } from "../../../../models/text-messages/textMessageTemplate";
import { TextMessagesHelper } from "../../../../models/text-messages/textMessagesHelper";
import { TextMessagesTemplateSettingsBase } from "../../../../models/text-messages/textMessagesTemplateSettingsBase";
import {
  SendTextMessageTemplateModalComponent
} from "./components/send-text-messages-template-modal/send-text-message-template-modal.component";
import { ILoadable, toLoadable } from "../../../../models/ILoadable";
import { AuthService } from "../../../../services/auth.service";

@Component({
  selector: 'app-text-messages-templates-page',
  standalone: true,
  providers: [TextMessageTemplatesService],
  imports: [
    LoaderComponent,
    PageTitleComponent,
    EditTextMessageTemplateComponent,
  ],
  templateUrl: './text-messages-templates-page.component.html',
  styleUrl: './text-messages-templates-page.component.scss'
})
export class TextMessagesTemplatesPageComponent implements OnInit {
  private textMessagesTemplateService = inject(TextMessageTemplatesService);
  private modalService = inject(NgbModal);
  private authService = inject(AuthService);
  private toastr = inject(ToastrService);

  editTextMessageComponents = viewChildren(EditTextMessageTemplateComponent, { read: EditTextMessageTemplateComponent });

  textMessageTemplates: ILoadable<TextMessageTemplate>[] = [];
  defaultTemplatesRecord: Record<string, TextMessagesTemplateSettingsBase> = {};
  isLoading = false;
  testTextMessageTemplateModalOpened = false;

  ngOnInit(): void {
    this.isLoading = true;
    combineLatest([this.loadTextMessagesTemplates(), this.loadTextMessagesDefaultTemplates()]).subscribe();
  }

  onStartSmsEditing(componentIndex: number): void {
    this.editTextMessageComponents()
      .forEach((editTemplateComponent, index) => {
        if (index !== componentIndex)
          editTemplateComponent.cancelEditing();
      });
  }

  testTextMessageTemplate(smsTemplate: TextMessageTemplate): void {
    if (this.testTextMessageTemplateModalOpened) return;
    this.testTextMessageTemplateModalOpened = false;

    const modalRef = this.modalService.open(SendTextMessageTemplateModalComponent, { size: 'sm', centered: true });
    const instance = modalRef.componentInstance as SendTextMessageTemplateModalComponent;

    instance.phoneByDefault = this.authService.currentUserProfile()?.phoneNumber ?? '';
    instance.textMessageSendRequest = TextMessagesHelper.buildTextMessageSendRequest(smsTemplate, '');

    modalRef.result.then(
      (phone: string) => {
        this.testTextMessageTemplateModalOpened = false;
        this.toastr.success(`Test SMS will be sent to: ${ phone }`);
      },
      () => this.testTextMessageTemplateModalOpened = false
    );
  }

  saveTextMessageTemplate(smsTemplate: TextMessageTemplate, index: number): void {
    this.textMessageTemplates[index].isLoading = true;
    this.textMessagesTemplateService.saveTextMessageTemplate(smsTemplate)
      .pipe(finalize(() => this.textMessageTemplates[index].isLoading = false))
      .subscribe({
        next: (updatedTemplate) => this.textMessageTemplates[index].value = updatedTemplate,
        error: () => this.toastr.error('Something wrong while saving the template', 'Template saving failed')
      });
  }

  showErrorMessage(messageElements: string[]): void {
    this.toastr.error(...messageElements);
  }

  private loadTextMessagesTemplates(): Observable<TextMessageTemplate[]> {
    return this.textMessagesTemplateService.loadTextMessageTemplates()
      .pipe(
        finalize(() => this.isLoading = false),
        tap({
          next: (textMessageTemplates) => this.textMessageTemplates = toLoadable(textMessageTemplates),
          error: () => this.toastr.error('Failed loading of SMS templates', 'Error')
        })
      );
  }

  private loadTextMessagesDefaultTemplates(): Observable<Record<string, TextMessagesTemplateSettingsBase>> {
    return this.textMessagesTemplateService.loadSmsDefaultTemplates()
      .pipe(tap({
        next: (defaultTemplatesRecord) => this.defaultTemplatesRecord = defaultTemplatesRecord,
        error: () => this.toastr.error('Failed loading of default SMS templates', 'Error')
      }));
  }
}
