import { NgClass } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NumbersValidators } from '../../../../validators/numbers.validators';
import { FormSwitchButtonComponent } from '../form-switch-button/form-switch-button.component';
import { InputComponent } from '../input/input.component';
import { LinkComponent } from '../link/link.component';
import { ValidationErrorComponent } from '../validation-error/validation-error.component';

@Component({
  selector: 'app-input-number',
  standalone: true,
  imports: [
    FormsModule,
    NgClass,
    FormSwitchButtonComponent,
    LinkComponent,
    ValidationErrorComponent
  ],
  templateUrl: './input-number.component.html',
  styleUrl: `../input/input.component.scss`,
  styles: `
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
  `
})
export class InputNumberComponent extends InputComponent implements OnInit {
  @Input() override customErrors: Record<string, string> = NumbersValidators.numbersValidatorErrors;
  @Input() min: number | null = 0;
  @Input() max: number | null = null;

  ngOnInit() {
    if (this.ngControl.control) {
      this.ngControl.control.addValidators(NumbersValidators.isNumber);
    }
  }
}
