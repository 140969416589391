import { Component, DestroyRef, inject, Input, OnInit, Optional } from '@angular/core';
import { ControlValueAccessor, FormControl, FormsModule, NgControl, ReactiveFormsModule } from "@angular/forms";
import { AgActionIconButtonComponent } from "../grid/components/ag-action-icon-button/ag-action-icon-button.component";
import { ValidationErrorComponent } from "../validation-error/validation-error.component";
import { debounceTime, Subject } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-multiple-input-numbers',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    ValidationErrorComponent,
    AgActionIconButtonComponent,
  ],
  templateUrl: './multiple-input-numbers.component.html',
  styleUrl: './multiple-input-numbers.component.scss'
})
export class MultipleInputNumbersComponent implements ControlValueAccessor, OnInit {
  @Optional() private ngControl = inject(NgControl);
  private destroyRef = inject(DestroyRef);

  @Input() label = '';
  @Input() placeholder = '';
  @Input() tip = '';
  @Input() isDisabled = false;
  @Input() readonly = false;
  @Input() shouldShowErrors = true;
  @Input() customErrors = {};
  @Input() restrictValuesDuplicates = true;

  @Input('minNumber') set minNumberSetter(value: number) {
    this.updateLimit('minNumber', value);
  }

  @Input('maxNumber') set maxNumberSetter(value: number) {
    this.updateLimit('maxNumber', value);
  }

  @Input('minQuantity') set minQuantitySetter(value: number) {
    this.updateLimit('minQuantity', value);
  }

  @Input('maxQuantity') set maxQuantitySetter(value: number) {
    this.updateLimit('maxQuantity', value);
  }

  // limits
  minNumber = 0;
  maxNumber = 100;
  minQuantity = 0;
  maxQuantity = 10;

  _value: number[] | null = [];
  numbersValidity: boolean[] = [];
  numberControl = new FormControl<number | null>(null);
  numbersInputHandler = new Subject<string | null>();

  constructor() {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.listenNumbersControlChange();
    this.initValidators();
  }

  submit(submitEvent: SubmitEvent): void {
    submitEvent.preventDefault();
    submitEvent.stopPropagation();
    this.addItem(this.numberControl.value!);
  }

  addItem(newNum: number): void {
    const num = Number(newNum);
    if (newNum === null || Number.isNaN(num))
      return;

    const isDateAdded = this.value.some((n) => n === num);
    if (isDateAdded && this.restrictValuesDuplicates)
      return;

    this.value.push(num);
    this.value = this.value.sort();
    this.numberControl.reset();
    this.onChange(this.value);
    this.control.updateValueAndValidity();
    this.checkSelectedNumbersValidation();
  }

  removeItem(index: number): void {
    this.value.splice(index, 1);
    this.checkSelectedNumbersValidation();
  }

  set value(value: number[] | null) {
    this._value = value;
    this.onChange(value);
  }

  get value(): number[] {
    return this._value ?? [];
  }

  writeValue(numbers: number[]): void {
    this.value = numbers;
    this.checkSelectedNumbersValidation();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onChange: any = (): void => {
  };
  onTouched: any = (): void => {
  };

  handleNumbersInput(inputCharacter: string | null): void {
    this.numbersInputHandler.next(inputCharacter);
  }

  private initValidators(): void {
    const minNumbersQuantity = () => {
      return this.value.length < this.minQuantity
        ? { noEnoughItems: this.minQuantity }
        : null;
    };

    const maxNumbersQuantity = () => {
      return this.value.length > this.maxQuantity
        ? { tooManyItems: this.maxQuantity }
        : null;
    };

    const someNumbersAreSmaller = () => {
      return this.value.some((num) => +num < this.minNumber)
        ? { someNumbersAreSmaller: this.minNumber }
        : null;
    };

    const someNumbersAreBigger = () => {
      return this.value.some((num) => +num > this.maxNumber)
        ? { someNumbersAreBigger: this.maxNumber }
        : null;
    };

    this.control.setValidators([
      minNumbersQuantity,
      maxNumbersQuantity,
      someNumbersAreBigger,
      someNumbersAreSmaller
    ]);
  }

  private checkSelectedNumbersValidation(): void {
    this.numbersValidity = this.value.map((num) => num < this.maxNumber && num >= this.minNumber);
    this.control?.updateValueAndValidity();
    this.numberControl.updateValueAndValidity();
  }

  private updateLimit(key: 'minNumber' | 'maxNumber' | 'maxQuantity' | 'minQuantity', value: number): void {
    this[key] = value;
    this.checkSelectedNumbersValidation();
  }

  private listenNumbersControlChange(): void {
    const allowedSeparators = [',', ';'];
    this.numbersInputHandler
      .pipe(
        debounceTime(10),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((symbol) => {
        if (symbol && allowedSeparators.includes(symbol)) {
          this.addItem(this.numberControl.value!);
        }
      });
  }

  get control(): FormControl<number[]> {
    return this.ngControl.control as FormControl<number[]>;
  }
}
