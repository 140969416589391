import {
  AfterContentInit,
  AfterViewInit,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  ViewChild
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { finalize, Observable } from 'rxjs';
import { enumToSteps } from '../../../../functions/enums-to-list-formatter';
import { Document } from '../../../../models/document';
import { EntityChangeData } from '../../../../models/entityChangeData';
import { CompanyChangeData } from '../../../../models/enums/companyChangeData';
import { IStep } from '../../../../models/step';
import { DocumentsService } from '../../../../services/documents.service';
import { DocumentStatusEnum } from '../../../../models/enums/documentStatusEnum';
import { StepperFormComponent } from '../stepper-form.component';
import { DatePickerFormValidators } from "../../../../custom-form-validators/date-picker-form-validators";

export abstract class BaseStepperForm<T extends unknown> {
  isEdit = false;
  isLoading = false;
  redirectAfterSubmit = false;
  steps!: IStep<T>[];
  currentStepIndex!: number;
  currentStep!: T;
  abstract stepperForm: FormGroup;

  abstract setCurrentStep(newStepIndex: number): void;

  abstract buildDocument(): Document | null;

  abstract confirm(): void;

  abstract close(isEdit: boolean): void;
  abstract setupChange(): void;
}

export type DocumentSubmitter = (document: Document, saveAndCompleteLater: boolean) => Observable<{ id: string }>;

@Component({
  selector: 'app-base-stepper-component',
  standalone: true,
  imports: [],
  template: '',
  styleUrl: './base-stepper-form.component.scss'
})
export class BaseStepperFormComponent<T, Change extends EntityChangeData> implements BaseStepperForm<T>, AfterContentInit, AfterViewInit {
  @ViewChild(StepperFormComponent) stepperFormComponent!: StepperFormComponent;

  destroyRef = inject(DestroyRef);
  activeModal = inject(NgbActiveModal);
  toastr = inject(ToastrService);
  documentsService = inject(DocumentsService);
  router = inject(Router);

  @Input() companyChangeData = new CompanyChangeData();
  @Input() formModel!: Change;
  @Input() documentSubmitter: DocumentSubmitter = this.documentsService.createOrUpdateDocument.bind(this.documentsService);

  readonly customDatepickerErrors = DatePickerFormValidators.errorMessagesWithDateOfEstablishmentErrorMessages;

  isEdit = false;
  isLoading = false;
  redirectAfterSubmit = false;
  forceClose = false;

  StepsEnum!: any;
  steps!: IStep<T>[];
  currentStepIndex = 0;
  currentStep!: T;

  stepperForm: FormGroup = new FormGroup({});

  ngAfterContentInit() {
    this.activeModal.update({ size: 'xl' });
  }

  ngAfterViewInit(): void {
    this.setupStepperComponentMethods();
  }

  setCurrentStep(newStepIndex: number): void {
    this.currentStep = this.steps[newStepIndex].step;
    this.currentStepIndex = newStepIndex;
  }

  setupChange(): void {
    if (!this.isEdit) {
      return;
    }

    console.warn('Method not implemented. Override "setupChange" method.');
    console.warn(this);
  }

  saveAndCompleteLater(): void {
    this.submit(true);
  }

  confirm(): void {
    this.submit();
  }

  submit(draft = false): void {
    if (this.stepperForm.invalid && !draft) {
      this.toastr.error('Please fill required fields', 'Error');
      this.stepperForm.controls[this.currentStep as string]?.markAllAsTouched();

      return;
    }

    const document = this.buildDocument();

    if (!document)
      return;

    document.documentStatus = draft ? DocumentStatusEnum.Draft : DocumentStatusEnum.AuthorisationPending;

    this.isLoading = true;
    this.stepperForm.disable();

    this.documentSubmitter(document, draft)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.stepperForm.enable();
          this.afterSubmit(document.changes);
        })
      )
      .subscribe({
        next: (response) => {
          this.toastr.success('Document Saved', 'Success');
          this.activeModal.close(new Document(document).changes);

          if (response.id && this.redirectAfterSubmit && !this.isEdit && !draft) {
            this.router.navigate([`/document/${ response.id }`]);
          }
        },
        error: (error) => {
          console.warn(error);
          this.toastr.error('Failed to save Document.', 'Error');
        }
      });
  }

  buildDocument(): Document | null {
    throw new Error('Method not implemented. Override "buildDocument" method.');
  }

  close(forceClose = true): void {
    this.forceClose = forceClose;

    if (this.isLoading && !forceClose) {
      alert('Please wait for the operation to complete before closing the modal.');
      return;
    }

    this.activeModal.dismiss(forceClose);
  }

  setupSteps(stepEnum: any): void {
    this.StepsEnum = stepEnum as unknown as Record<string, number>;
    this.steps = enumToSteps(stepEnum);
    this.currentStepIndex = 0;
    this.currentStep = this.steps[this.currentStepIndex].step;
  }

  afterSubmit(changes: EntityChangeData[]): void {
  }

  setupStepperComponentMethods(): void {
    this.takeUntilDestroyed(this.stepperFormComponent.stepIndexChange, this.setCurrentStep.bind(this));
    this.takeUntilDestroyed(this.stepperFormComponent.close, () => this.close(false));
    this.takeUntilDestroyed(this.stepperFormComponent.saveAndCompleteLater, () => this.saveAndCompleteLater());
    this.takeUntilDestroyed(this.stepperFormComponent.confirm, () => this.submit());
  }

  takeUntilDestroyed(eventEmitter: EventEmitter<any>, callback: (p: any) => void): void {
    eventEmitter
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(callback);
  }
}
