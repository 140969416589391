<app-stepper-form
  header="Appoint Company"
  subheader="Form 362"
  [showSteps]="false"
  [steps]="stepsSignal()"
  [currentStep]="currentStep"
  [currentStepIndex]="currentStepIndex"
  [form]="stepperForm"
  [isLoading]="isLoading"
>
  <div class="step-content" [formGroup]="stepperForm">
    <app-stepper-form-description
      formKey="362"
      [hidden]="currentStep !== StepsEnum.FormDescription"
    ></app-stepper-form-description>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.CompanyInformation"
      [hidden]="currentStep !== StepsEnum.CompanyInformation"
    >
      <h1 class="h1-heading">Company Information</h1>
      <div class="form-group">
        <app-company-name-acn-form-group [formGroup]="companyInformationForm"/>
      </div>

      <div class="form-group" [hidden]="isEdit">
        <app-yes-no-control
          label="Do you need to change the registered address?"
          formControlName="needToChangeRegisteredAddress"
        ></app-yes-no-control>
      </div>
    </section>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.NewAddressInformation"
      [hidden]="currentStep !== StepsEnum.NewAddressInformation"
    >
      <h1 class="h1-heading">New Address Information</h1>

      <div>
        <app-address-and-occupier-form-group [formGroup]="addressForm"/>
      </div>
    </section>
  </div>
</app-stepper-form>
