import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DatepickerHelper } from '../../../../../../custom-form-validators/date-picker-form-validators';
import { AsicBankData } from '../../../../../../models/asicBankData';
import { Document } from '../../../../../../models/document';
import { IndividualData } from '../../../../../../models/individualData';
import { InputNumberComponent } from '../../../../../components/common/input-number/input-number.component';
import { InputComponent } from '../../../../../components/common/input/input.component';
import {
  BsbMaskedInputComponent
} from '../../../../../components/common/masked-input/specific-masked-inputs/bsb-masked-input/bsb-masked-input.component';
import { BaseStepperFormComponent } from '../../../../stepper-form/base-stepper-component/base-stepper-form.component';
import {
  StepperFormDescriptionComponent
} from '../../../../stepper-form/stepper-form-description/stepper-form-description.component';
import { StepperFormComponent } from '../../../../stepper-form/stepper-form.component';
import {
  AppIndividualOrCompanyComponent
} from '../components/app-individual-or-company/app-individual-or-company.component';
import { AsicFormRA04, Ra04DirectDebitRequestStepsEnum } from './ra04-direct-debit-request.model';
import {
  IndividualDataFormGroupControls
} from "../../../../../components/reusable-form-groups/individual-data-form-group/individual-data-form-group.component";
import { parseFullName } from "../../../../../../functions/parse-fullname";
import { EntityChangeData } from "../../../../../../models/entityChangeData";

// Direct debit request
@Component({
  selector: 'app-ra04-direct-debit-request',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputComponent,
    InputNumberComponent,
    BsbMaskedInputComponent,
    StepperFormComponent,
    StepperFormDescriptionComponent,
    AppIndividualOrCompanyComponent,
  ],
  templateUrl: './ra04-direct-debit-request.component.html',
  styleUrl: '../../../../stepper-form/base-stepper-component/base-stepper-form.component.scss'
})
export class Ra04DirectDebitRequestComponent extends BaseStepperFormComponent<Ra04DirectDebitRequestStepsEnum, AsicFormRA04> implements OnInit {
  override readonly StepsEnum = Ra04DirectDebitRequestStepsEnum;
  readonly hiddenIndividualControls: (keyof IndividualDataFormGroupControls)[] = ['address', 'dob', 'birthCity', 'birthCountry'];

  override stepperForm = new FormGroup({
    [Ra04DirectDebitRequestStepsEnum.FormDescription]: new FormGroup({}),
    [Ra04DirectDebitRequestStepsEnum.Customer]: new FormGroup({
      individualOrCompany: AppIndividualOrCompanyComponent.defineForm()
    }),
    [Ra04DirectDebitRequestStepsEnum.DetailsOfTheAccountToBeDebited]: new FormGroup({
      bankName: new FormControl<string | null>(null, [Validators.required]),
      bankBSB: new FormControl<string | null>(null, [Validators.required]),
      bankAccountName: new FormControl<string | null>(null, [Validators.required]),
      bankAccountNumber: new FormControl<string | null>(null, [Validators.required, Validators.minLength(6), Validators.maxLength(9)]),
      dailyCreditLimit: new FormControl<number | null>(null, [Validators.required, Validators.min(0), Validators.max(100000)]),
    }),
  });

  constructor() {
    super();
    this.setupSteps(Ra04DirectDebitRequestStepsEnum);
    this.redirectAfterSubmit = true;
  }

  ngOnInit(): void {
    this.setupChange();
  }

  override afterSubmit(changes: EntityChangeData[]) {
    this.setupChange(changes.find((change) => change.$type === AsicFormRA04.$type) as AsicFormRA04);
  }

  override setupChange(change: AsicFormRA04 = this.formModel) {
    if (!this.isEdit && change === this.formModel)
      return;

    this.detailsForm.patchValue(change.bankData);
  }

  override buildDocument(): Document | null {
    const change = new AsicFormRA04({
      date: DatepickerHelper.buildTodayDateString(),
      companyName: this.customerForm.value.individualOrCompany?.company?.name ?? '',
      companyAcn: this.customerForm.value.individualOrCompany?.company?.acn ?? '',
      individual: this.customerForm.value.individualOrCompany?.isIndividual
        ? new IndividualData({ ...parseFullName(this.customerForm.controls.individualOrCompany.controls.individual.controls.fullName.value ?? '') })
        : null,
      bankData: new AsicBankData(this.detailsForm.value as Partial<AsicBankData>),
      otherForms: true,
      ecrForm: false,
      changeDate: DatepickerHelper.buildTodayDateString(),
    });

    try {
      return new Document({
        changes: [change],
        entityId: this.companyChangeData?.entityId,
        documentId: this.companyChangeData?.documentId,
        type: 'c:ra04',
      });
    } catch (error) {
      this.toastr.error('Failed to create Document.', 'Error');
      return null;
    }
  }

  get customerForm() {
    return this.stepperForm.controls[Ra04DirectDebitRequestStepsEnum.Customer];
  }

  get detailsForm() {
    return this.stepperForm.controls[Ra04DirectDebitRequestStepsEnum.DetailsOfTheAccountToBeDebited];
  }
}
