import { CurrencyPipe } from '@angular/common';
import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, startWith } from 'rxjs';
import { setControlDisabled } from '../../../../../functions/set-control-disabled';
import { ShareTypeEnum, shareTypeOptions } from '../../../../../models/enums/shareTypeEnum';
import { SecurityType } from '../../../../../models/securityType';
import { Guid } from '../../../../helpers/guid.helper';
import { CommonModalWrapperComponent } from '../../../common/common-modal-wrapper/common-modal-wrapper.component';
import { InputNumberComponent } from '../../../common/input-number/input-number.component';
import { InputComponent } from '../../../common/input/input.component';
import { ValidationErrorComponent } from '../../../common/validation-error/validation-error.component';
import { AutocompleteComponent } from "../../../common/autocomplete/autocomplete.component";

@Component({
  selector: 'app-add-share-type-modal',
  standalone: true,
  imports: [
    CommonModalWrapperComponent,
    InputComponent,
    ReactiveFormsModule,
    InputNumberComponent,
    ValidationErrorComponent,
    AutocompleteComponent
  ],
  templateUrl: './add-share-type-modal.component.html',
  styleUrl: './add-share-type-modal.component.scss'
})
export class AddShareTypeModalComponent implements OnInit {
  activeModal = inject(NgbActiveModal);
  destroyRef = inject(DestroyRef);

  shareTypeOptions = shareTypeOptions;
  shareClassCustomErrors = { invalidFormat: 'Only letters and numbers allowed.', maxlength: 'Max length of Share Class is 5.' };

  @Input() securityTypes: SecurityType[] = [];

  form = new FormGroup({
    class: new FormControl<ShareTypeEnum | string | null>(ShareTypeEnum.ORD, [Validators.required, Validators.maxLength(5)]),
    paidAmount: new FormControl<number | null>(0, [Validators.required]),
    unpaidAmount: new FormControl<number | null>(0, [Validators.required]),
    name: new FormControl<string | null>('', [Validators.required]),
    identifier: new FormControl<string | null>(ShareTypeEnum.ORD, [Validators.required]),
  });

  ngOnInit() {
    setControlDisabled(this.form.controls.identifier);
    this.listenFormChanges();
    this.setExistingSecurityTypeValidator();
    this.addShareClassFormatValidator();
  }

  close(): void {
    this.activeModal.dismiss();
  }

  submit(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const securityType = this.buildSecurityType();
    this.activeModal.close(securityType);
  }

  listenFormChanges(): void {
    combineLatest(
      this.form.controls.class.valueChanges.pipe(startWith(this.form.value.class)),
      this.form.controls.paidAmount.valueChanges,
      this.form.controls.unpaidAmount.valueChanges.pipe(startWith(this.form.value.unpaidAmount))
    )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([Class, paidAmount, unpaidAmount]) => {
        if (Class === null || paidAmount === null || unpaidAmount === null) {
          return;
        }

        const currencyPipe = new CurrencyPipe('en-AU');
        const nominalPrice = paidAmount! + unpaidAmount!;
        const identifier = `${ Class }@${ currencyPipe.transform(nominalPrice, '$', 'code', '1.2-2') }/${ currencyPipe.transform(unpaidAmount, '$', 'code', '1.2-2') }`;

        this.form.controls.identifier.patchValue(identifier, { emitViewToModelChange: true });
      });
  }

  setExistingSecurityTypeValidator(): void {
    this.form.setValidators([
      Validators.required,
      () => {
        const securityType = this.securityTypes
          .find((securityType) => securityType.isEqual(this.buildSecurityType()));
        return securityType ? { existingSecurityType: true } : null;
      }
    ]);
  }

  private buildSecurityType(): SecurityType {
    return new SecurityType({
      ...this.form.value as Partial<SecurityType>,
      nominalPrice: this.form.value.unpaidAmount! + this.form.value.paidAmount!,
      securityTypeId: Guid.generate(),
    });
  }

  private addShareClassFormatValidator(): void {
    const shareClassFormatValidator = (control: AbstractControl): ValidationErrors | null => {
      const value = (control.value as string | null | undefined) ?? '';
      const regex = /^[a-zA-Z0-9]+$/;

      return regex.test(value)
        ? null
        : { invalidFormat: true };
    };
    this.form.controls.class.addValidators([shareClassFormatValidator]);
  }

  get showValidationError(): boolean {
    return this.form.invalid
      && this.form.touched
      && this.form.controls.paidAmount.touched
      && this.form.controls.unpaidAmount.touched;
  }
}
