import { Address } from "./address";
import { JurisdictionType } from "./enums/trustEnum";
import { DatepickerHelper } from "../custom-form-validators/date-picker-form-validators";

export class EntityData {
  name: string;
  entityNumber?: string;
  jurisdiction: JurisdictionType | null;
  registeredAddress: Address;
  dateOfEstablishment?: Date;

  constructor(data: Partial<EntityData> = {}) {
    this.name = data.name ?? '';
    this.registeredAddress = data.registeredAddress ? new Address(data.registeredAddress) : new Address();
    this.jurisdiction = data.jurisdiction ?? null;
    this.dateOfEstablishment = data.dateOfEstablishment ? new Date(data.dateOfEstablishment) : data.dateOfEstablishment;
    this.entityNumber = data.entityNumber;
  }

  static prepareToRequest(entityData: EntityData): EntityData {
    return {
      ...entityData,
      dateOfEstablishment: entityData.dateOfEstablishment ? DatepickerHelper.buildDateString(entityData.dateOfEstablishment) : entityData.dateOfEstablishment,
    }
  }
}
