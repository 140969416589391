import { Component, EventEmitter, inject, Output } from '@angular/core';
import { CommonModalFormComponent } from "../../common-modal-form/common-modal-form.component";
import { FormGroup } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FileUploadComponent } from "../../../components/common/file-upload/file-upload.component";
import { bytesToMegabytes } from "../../../../functions/bytes-to-megabytes";
import {
  AttachedFileLabelComponent
} from "../../../components/notes/components/attached-file-label/attached-file-label.component";

@Component({
  selector: 'app-annual-upload-files',
  standalone: true,
  imports: [
    CommonModalFormComponent,
    FileUploadComponent,
    AttachedFileLabelComponent
  ],
  templateUrl: './annual-upload-files.component.html',
  styleUrl: './annual-upload-files.component.scss'
})
export class AnnualUploadFilesComponent {
  @Output() save = new EventEmitter<File[]>();

  private activeModal = inject(NgbActiveModal);

  form!: FormGroup;

  readonly allowedMaxFileSizeMB = 2;
  readonly maxFilesNumber = 10;
  readonly acceptedFormatsList = ['.pdf'];
  readonly acceptedFormats = this.acceptedFormatsList.join(', ');
  readonly uploadNotification = 'NOTE: The maximum number of files to upload is 10 and should not exceed 2 MB.';
  errorMessage = '';
  uploadedFiles: File[] = [];

  onFilesLoaded(files: File[]): void {
    if (this.uploadedFiles.length + files.length > this.maxFilesNumber) {
      this.setErrorMessage('The maximum number of files to upload is 10. Please use the allowed number of files.');
      return;
    }

    const isAllowedFileExtension = files.some(file => {
      return this.acceptedFormatsList.some((fileExtension) => file.name.endsWith(fileExtension))
    });
    if (!isAllowedFileExtension) {
      this.setErrorMessage(`Uploaded file has not allowed file extension. Please use one of allowed file extensions: ${ this.acceptedFormats }`);
      return;
    }

    const isNotAllowedFileSize = files.some(file => bytesToMegabytes(file.size) > this.allowedMaxFileSizeMB);
    if (isNotAllowedFileSize) {
      this.setErrorMessage(`Uploaded file has not allowed file is too big. Max file size is ${ this.allowedMaxFileSizeMB } MB`);
      return;
    }

    this.uploadedFiles = [...this.uploadedFiles, ...files];

    this.setErrorMessage();
  }

  setErrorMessage(errorMessage = ''): void {
    this.errorMessage = errorMessage;
  }

  deleteFile(index: number): void {
    this.uploadedFiles.splice(index, 1);
  }

  confirm(): void {
    this.save.emit(this.uploadedFiles);
    this.activeModal.close();
  }

  close(): void {
    this.activeModal.close();
  }
}
