<div class="address-and-occupier">
  <app-address-control
    [label]="'New Address'"
    [useInternationalAddresses]="useInternationalAddresses"
    [address]="address"
    [expandedAddressForm]="expandedAddressForm"
  ></app-address-control>

  <div class="occupier-name" [formGroup]="form">
    <app-date-picker
      label="Date of Change"
      formControlName="changeDate"
      [minDate]="minDateOfAddressChange"
      [maxDate]="maxDateOfAddressChange"
    ></app-date-picker>

    <div [hidden]="occupierPartHidden || occupierNameHidden">
      <app-yes-no-control
        formControlName="occupiesTheAddress"
        label="Does the company occupy the premises at the new registered office?"
      ></app-yes-no-control>
    </div>

    <div [hidden]="occupierPartHidden || occupierNameHidden" class="occupier-name">
      <app-input
        label="Name of Occupier"
        formControlName="occupierNameIfDoesntOccupy"
      ></app-input>

      <app-yes-no-control
        formControlName="companyHaveOccupiersConsent"
        label="Does the company have occupier's consent?"
        [customErrors]="{ valueMismatch: 'Consent from the occupier is necessary to proceed.' }"
      ></app-yes-no-control>
    </div>
  </div>
</div>
