<div class="h-100 d-flex align-items-center">
  <app-ag-action-icon-button
    iconClass="ic-edit"
    tooltipText="Edit"
    (action)="editTag()"
  />

  <app-ag-action-icon-button
    iconClass="ic-close"
    tooltipText="Delete"
    (action)="deleteTag()"
  />
</div>
