export enum RelationshipType
{
    Agent,
    Director,
    Secretary,
    AlternativeDirector,
    /// <summary>
    /// Shareholder or unitholder
    /// </summary>
    Securityholder,
    /// <summary>
    /// Reserved for discretionary trusts only
    /// </summary>
    Beneficiary,
    Trustee,
    Appointor,
    Settlor,
    Successor,
    /// For company registration documentation
    PublicOfficer
}

export const RelationshipTypeLabels: Record<RelationshipType, string> = {
  ...RelationshipType as Partial<Record<RelationshipType, string>>,
  [RelationshipType.Securityholder]: 'Shareholder',
  [RelationshipType.AlternativeDirector]: 'Alternative Director',
} as Record<RelationshipType, string>;

export const RelationshipTypeLabelsPlural: Record<RelationshipType, string> = {
  [RelationshipType.Agent]: "Agents",
  [RelationshipType.Director]: "Directors",
  [RelationshipType.Secretary]: "Secretaries",
  [RelationshipType.AlternativeDirector]: "Alternative Directors",
  [RelationshipType.Securityholder]: "Shareholders",
  [RelationshipType.Beneficiary]: "Beneficiaries",
  [RelationshipType.Trustee]: "Trustees",
  [RelationshipType.Appointor]: "Appointors",
  [RelationshipType.Settlor]: "Settlors",
  [RelationshipType.Successor]: "Successors"
} as Record<RelationshipType, string>;
