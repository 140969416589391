<div class="individual-relationship-modal">
  <app-common-modal-form
    closeText="Cancel"
    confirmText="Save"
    [header]="header"
    [formGroup]="form"
    (confirm)="save()"
    (close)="cancel()"
  >
    <app-relationship-form-group
      [formGroup]="form"
      [hideAddress]="hideAddress"
      [nameControlLabel]="nameControlLabel"
    ></app-relationship-form-group>
  </app-common-modal-form>
</div>
