<div class="tags" [class.wrappable]="wrappable">
  @if (allowEdit) {
    <div
      #selectTagsDropdown="ngbDropdown"
      ngbDropdown
      class="dropdown-wrapper"
      placement="bottom-right"
      container="body"
    >
      <button
        #selectTagsDropdownToggle
        ngbDropdownToggle
        type="button"
        title="Customize"
        class="edit-tags-button"
      >
        <i class="icon ic-sm ic-tag"></i>
      </button>
      <div
        ngbDropdownMenu
        class="p-0"
      >
        @defer (on interaction(selectTagsDropdownToggle)) {
          <app-select-tags-dropdown
            [entityProfile]="entity"
            [tags]="tags"
            (assignTag)="assignTag($event)"
            (addNewTag)="addNewTag()"
          ></app-select-tags-dropdown>
        }
      </div>
    </div>
  }

  <div class="entity-tags-list">
    @for (tag of systemTags; track tag.tagId) {
      <app-tag
        [tag]="tag"
        (clickTag)="selectTag($event.name, tag.isSystemTag)"
      ></app-tag>
    }

    @for (tag of tags; track tag.tagId) {
      <app-tag
        [tag]="tag"
        (clickTag)="selectTag($event.name, tag.isSystemTag)"
      ></app-tag>
    }
  </div>
</div>
