import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { NumberRange } from "../../../../models/gridFilter";

@Component({
  selector: 'app-number-range-input',
  standalone: true,
  imports: [
    FormsModule
  ],
  templateUrl: './number-range-input.component.html',
  styleUrl: './number-range-input.component.scss'
})
export class NumberRangeInputComponent {
  @Input() label = 'Select number range';
  @Input() placeholderMin = '';
  @Input() placeholderMax = '';
  @Input() min: number | undefined;
  @Input() max: number | undefined;
  @Output() numberRangeSelected = new EventEmitter<NumberRange>()

  onChangeMin(min: number): void {
    if(min !== null && min !== undefined && this.max !== null && this.max !== undefined && min <= this.max) {
      this.numberRangeSelected.emit({ min, max: this.max });
    }
  }

  onChangeMax(max: number): void {
    if(this.min !== null && this.min !==undefined && max !== null && max !==undefined && this.min <= max) {
      this.numberRangeSelected.emit({ min: this.min , max });
    }
  }
}
