import { Component } from '@angular/core';
import { ICellRendererParams } from "ag-grid-community";
import { KeyValuePipe, NgClass } from "@angular/common";
import { CorporateHolderModel, HolderModelBase, IndividualHolderModel, SecurityRegistryRecord } from '../../../../../../models/securityRegistryRecord';

@Component({
  selector: 'app-ag-holder-beneficial-owner-status',
  standalone: true,
  imports: [
    NgClass,
    KeyValuePipe
  ],
  templateUrl: './ag-holder-beneficial-owner-status.component.html',
  styleUrl: './ag-holder-beneficial-owner-status.component.scss'
})
export class AgHolderBeneficialOwnerStatusComponent {
  holderGroups: Record<string, HolderModelBase[]> = {};

  CorporateHolderModel = CorporateHolderModel;
  IndividualHolderModel = IndividualHolderModel;

  agInit(params: ICellRendererParams): void {
    this.holderGroups = (params.data as SecurityRegistryRecord).beneficialOwnerGroups;
  }

  refresh(params: ICellRendererParams): boolean {
    this.holderGroups = (params.data as SecurityRegistryRecord).beneficialOwnerGroups;
    return true;
  }

}
