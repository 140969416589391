import { TextMessagePayloadBase } from "./payload/TextMessagePayloadBase";
import { TextMessageTemplate } from "./textMessageTemplate";

export class TextMessageSendRequest {
  to: string;
  payloadModel: TextMessagePayloadBase;
  template: TextMessageTemplate | null;

  constructor(data: Partial<TextMessageSendRequest> = {}) {
    this.to = data.to ?? '';
    this.payloadModel = data.payloadModel ?? new TextMessagePayloadBase();
    this.template = data.template ?? null;
  }
}
