import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { DatepickerHelper } from '../custom-form-validators/date-picker-form-validators';
import { Company } from './company';
import { Document } from "./document";
import { IPDFSection } from './shared/pdf-section.interface';
import { ChangeDictionaryHelper } from "./shared/change-dictionary-helper.model";
import { Entity } from "./entity";

export abstract class EntityChangeData{
    $type: string;
    changeDate: Date;
    changeType: string;
    description: string;

    static readonly $type: string;

    constructor(data: Partial<EntityChangeData> = {}) {
        this.$type = (this.constructor as typeof EntityChangeData).$type;
        this.changeDate = DatepickerHelper.toDate(data.changeDate);
        this.changeType = data.changeType ?? '';
        this.description = data.description ?? '';
    }

    abstract toDictionary(entity: Entity): { key: string, value: string }[];

    abstract fillModal(modalRef: NgbModalRef, document: Document, actualCompany: Company): NgbModalRef;

    abstract fillModalWithCompany(modalRef: NgbModalRef, company: Company, isEdit: boolean): NgbModalRef;

    buildPreviewType(): string { return this.changeType; }

    prepareToRequest(): EntityChangeData {
        return { ...this, changeDate: DatepickerHelper.buildDateString(this.changeDate) };
    }

    prepareToPdf(entity: Entity): IPDFSection[] {
      return new ChangeDictionaryHelper().addSection(this.description, this.toDictionary(entity)).sections;
    }
}
