<div class="holder">
  @for (groupEntry of holderGroups | keyvalue; track groupEntry) {
  <div [style]="'flex:' + groupEntry.value.length" class="owner-name border-bottom-dashed prop">
    <div class="cell-padding" [ngClass]="{'text-danger' : !groupEntry.value[0].details.beneficialOwner && !groupEntry.value[0].details.isBeneficialOwner}">
      @if (groupEntry.key.length)
      {
        <!-- todo: replace route with trust profile when ready -->
        <app-span-with-open-icon 
        [route]="groupEntry.value[0].details.beneficialOwnerEntityId ? ['/company-profile', groupEntry.value[0].details.beneficialOwnerEntityId!] : []">
          {{groupEntry.value[0].details.beneficialOwner | defaultValue : 'Not Supplied'}}
        </app-span-with-open-icon>
      }
      @else
      {
        -
      }
    </div>
  </div>
  }
</div>