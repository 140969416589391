<div class="alert-status-wrapper">
  @if(isRead && (status === AlertStatus.New || status === null || status === undefined)) {
    <div class="status read">
      {{ 'Read' }}
    </div>
  } @else {
    <div class="status" [ngClass]="AlertStatus[status!]">
      {{ status | alertsStatusPipe | splitByCapitalLetterPipe }}
    </div>
  }
</div>
