<app-common-modal-form
  icon="ic-edit"
  confirmText="Save"
  subheader="Edit Reminder"
  [header]="reminder.name"
  [formGroup]="form"
  [isLoading]="isLoading"
  (close)="close()"
  (confirm)="save()"
>
  <div class="edit-reminder-modal-content">
    <app-checkbox
      label="Send Reminders automatically"
      formControlName="autoSending"
    />

    @if (form.controls.autoSending.value) {
      <app-radio
        label="Method of Sending"
        formControlName="sendingMethod"
        [options]="sendingMethodOptions"
      />

      <app-multiple-input-numbers
        label="Dates"
        formControlName="dates"
        [customErrors]="datesCustomErrors"
        [minQuantity]="datesLimits.minQuantity"
        [minNumber]="datesLimits.minNumber"
        [maxNumber]="datesLimits.maxNumber"
      />

      <div class="deadlines-message mb-2" [innerHtml]="deadlineMessage"></div>

      <app-select
        label="Time to send reminder"
        formControlName="timeOfSending"
        [requireSorting]="false"
        [valuePrimitive]="true"
        [options]="timeOfSendingOptions"
      />
    }
  </div>
</app-common-modal-form>
