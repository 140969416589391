import { Component } from '@angular/core';
import { AlertType } from "../../../../../../../models/enums/alertEnums";
import { ICellRendererParams } from "ag-grid-community";
import { AlertsTypePipe } from "../../../../../../../pipes/enumsPipes/alertsTypePipe";
import { NgClass } from "@angular/common";
import { SplitByCapitalLetterPipePipe } from "../../../../../../../pipes/split-by-capital-letter-pipe.pipe";

@Component({
  selector: 'app-ag-alerts-category',
  standalone: true,
  templateUrl: './ag-alerts-category.component.html',
  imports: [
    AlertsTypePipe,
    NgClass,
    SplitByCapitalLetterPipePipe
  ],
  styleUrl: './ag-alerts-category.component.scss'
})
export class AgAlertsCategoryComponent {
  type: AlertType | undefined | null;
  protected readonly AlertType = AlertType;

  agInit(params: ICellRendererParams<AlertType, AlertType>): void {
    this.type = params.value;
  }

  refresh(params: ICellRendererParams<AlertType, AlertType>): boolean {
    this.type = params.value;
    return true;
  }
}
