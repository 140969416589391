<app-stepper-form
  header="Change of Ultimate Holding Company"
  subheader="Form 484A3"
  [showSteps]="false"
  [steps]="steps"
  [currentStep]="currentStep"
  [currentStepIndex]="currentStepIndex"
  [isLoading]="isLoading"
  [form]="stepperForm"
>
  <div class="step-content" [formGroup]="stepperForm">
    <app-stepper-form-description
      formKey="484 A3"
      [hidden]="currentStep !== StepsEnum.FormDescription"
    ></app-stepper-form-description>
    <section
      class="step-section"
      [formGroupName]="StepsEnum.ChangeHoldingCompany"
      [hidden]="currentStep !== StepsEnum.ChangeHoldingCompany"
    >
      <h1 class="h1-heading">Change of Ultimate Holding Company</h1>

      <div class="form-group">
        <app-select
          label="The Change is"
          formControlName="action"
          [options]="changeDescriptionOptions"
          [valuePrimitive]="true"
        ></app-select>
      </div>

      <div class="form-group" [hidden]="hideDataPart">
        <app-autocomplete
          label="Place of incorporation of Ultimate Holding Company"
          formControlName="holdingCompanyPlaceOfIncorporation"
          [options]="countries"
          [valuePrimitive]="true"
        ></app-autocomplete>
      </div>

      <div class="form-group">
        <app-date-picker
          label="Date of Change"
          formControlName="changeDate"
          [minDate]="minDate"
          [maxDate]="maxDate"
          [customErrors]="customDatepickerErrors"
        ></app-date-picker>
      </div>

      <div
        class="form-group"
        [hidden]="hideAustralianCompanyNameAcn"
        [formGroup]="form"
      >
        <app-company-name-acn-form-group
          nameTitle="Ultimate Holding Company Name"
          acnTitle="Ultimate Holding Company ACN"
          [formGroup]="form"
          [shouldLookForCompanyName]="isOverseasCompany"
        ></app-company-name-acn-form-group>
      </div>

      <div class="form-group" [hidden]="hideAboardCompanyName">
        <app-input
          label="Ultimate Holding Company Name"
          formControlName="aboardCompanyName"
        ></app-input>
      </div>

      @if (errorMessage) {
        <div class="alert alert-danger" role="alert">{{ errorMessage }}</div>
      }
    </section>
  </div>
</app-stepper-form>
