import { Component, Input } from '@angular/core';
import { ICellRendererParams } from "ag-grid-community";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { NgClass } from "@angular/common";

@Component({
  selector: 'app-ag-multiple-rows',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './ag-multiple-rows.component.html',
  styleUrl: './ag-multiple-rows.component.scss'
})
export class AgMultipleRowsComponent implements ICellRendererAngularComp {
  @Input() strings: string[] = [];
  @Input() rowClass = '';

  agInit(params: ICellRendererParams): void {
    this.init(params);
  }

  refresh(params: ICellRendererParams): boolean {
    return this.init(params);
  }

  private init(params: ICellRendererParams): boolean {
    this.strings = params.value ?? [];
    this.rowClass = params?.['rowClass'] ?? '';
    return true;
  }
}
