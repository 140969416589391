import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererParams } from "ag-grid-community";
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from "@ng-bootstrap/ng-bootstrap";
import { SecurityTransaction } from '../../../../../../../models/securityTransaction';

type ActionEvent = (data: unknown) => void;

type CellRendererParams = ICellRendererParams<unknown> & {
  annotation: ActionEvent,
  downloadCertificate: ActionEvent,
  edit: ActionEvent,
  delete: ActionEvent
};

@Component({
  selector: 'app-ag-share-actions',
  standalone: true,
  imports: [
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle
  ],
  templateUrl: './ag-share-actions.component.html',
  styleUrl: './ag-share-actions.component.scss'
})
export class AgShareActionsComponent {
  router = inject(Router);

  protected params!: CellRendererParams;

  agInit(params: CellRendererParams): void {
    this.params = params;
  }

  refresh(params: CellRendererParams): boolean {
    this.params = params;
    return true;
  }

  annotation(): void {
  }

  downloadCertificate(): void {
  }

  onEdit(): void {
    if((this.params.data as SecurityTransaction)?.documentId) {
      void this.router.navigate(['document', (this.params.data as SecurityTransaction).documentId]).then(r => r);
    }
  }

  onDelete(): void {
  }
}
