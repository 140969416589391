export class TextMessagePayloadBase {
  static $type = 'TextMessagePayloadBase';

  $type: string;
  organisationName: string;
  organisationEmail: string;
  organisationPhone: string;

  constructor(data: Partial<TextMessagePayloadBase> = {}) {
    this.$type = (this.constructor as typeof TextMessagePayloadBase).$type;
    this.organisationName = data.organisationName ?? '';
    this.organisationEmail = data.organisationEmail ?? '';
    this.organisationPhone = data.organisationPhone ?? '';
  }
}
