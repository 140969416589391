<app-common-modal-form
  header="Annature Registration"
  confirmText="Register"
  closeText="Cancel"
  icon="ic-mail"
  [formGroup]="form"
  (close)="close()"
  (confirm)="confirm()"
>
  <div style="padding-bottom: 15px">
    <app-input label="Name"  formControlName="name"/>
    <app-input label="Email"  formControlName="email"/>
    <app-input label="Business"  formControlName="business"/>
    <app-input label="Phone number (optional)"  formControlName="number"/>
  </div>
</app-common-modal-form>