<div class="input-container" [class.disabled]="disabled">
  <div class="header">
    <div class="label">{{ label }}</div>
  </div>

  <div class="input-wrapper">
    <ul class="selected-dates">
      @for (date of dates; track date; let index = $index) {
        <li
          class="selected-date"
          [class.invalid]="!datesValidity[index]"
        >
          <span>{{ date | dateFormat }}</span>

          @if (!readonly || !disabled) {
            <app-ag-action-icon-button
              iconClass="ic-sm ic-close"
              [disabled]="disabled"
              (action)="removeDate(index)"
            ></app-ag-action-icon-button>
          }
        </li>
      }

      @if (!readonly || !disabled) {
        <li class="datepicker-control-wrapper">
          <form (submit)="submit($event)">
            <app-date-picker
              [placeholder]="dates.length ? '' : 'Select Date'"
              [formControl]="editedDateControl"
              [minDate]="minDateStruct"
              [maxDate]="maxDateStruct"
              [shouldShowErrors]="false"
              [useNullValueIfInvalid]="true"
              [smallWidth]="false"
              (selectDate)="addDate($event)"
              (input)="handleDatepickerInput($event)"
              (blur)="handleDatepickerInput(',')"
            ></app-date-picker>

            <button type="submit" hidden [disabled]="disabled"></button>
          </form>
        </li>
      }
    </ul>
  </div>

  <div class="tip">{{ tip }}</div>

  @if (shouldShowErrors && !disabled) {
    <div class="errors-wrapper">
      <app-validation-error
        [control]="control"
        [customErrors]="customErrors"
      ></app-validation-error>
    </div>
  }
</div>

