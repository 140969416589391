import { Component, inject, Input, OnInit } from '@angular/core';
import { ReactiveFormsModule } from "@angular/forms";
import {
  RelationshipFormGroupComponent
} from "../../../components/reusable-form-groups/relationship-form-group/relationship-form-group.component";
import { CommonModalFormComponent } from "../../../modals/common-modal-form/common-modal-form.component";
import { Guid } from "../../../helpers/guid.helper";
import { IndividualData } from "../../../../models/individualData";
import { Address } from "../../../../models/address";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Relationship } from "../../../../models/relationship";
import { setControlDisabled } from "../../../../functions/set-control-disabled";
import { parseFullName } from '../../../../functions/parse-fullname';

@Component({
  selector: 'app-form-individual-relationship-modal',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    RelationshipFormGroupComponent,
    CommonModalFormComponent
  ],
  templateUrl: './form-individual-relationship-modal.component.html',
})
export class FormIndividualRelationshipModalComponent implements OnInit {
  private activeModal = inject(NgbActiveModal);

  @Input() hideAddress = false;
  @Input() nameControlLabel = '';
  @Input() individualRelationship: Relationship | null = null;

  header = '';
  form = RelationshipFormGroupComponent.defineForm();

  ngOnInit(): void {
    this.activeModal.update({ modalDialogClass: 'common-modal-form-dialog overflow-y-auto' });
    if (!this.individualRelationship) {
      this.individualRelationship = new Relationship({
        individualId: Guid.generate(),
        relationshipId: Guid.generate(),
        individualDataOverride: new IndividualData({
          address: new Address(this.form.controls.address as Partial<Address>)
        })
      });
    } else {
      this.form.patchValue({
        name: this.individualRelationship.individualDataOverride?.fullName,
        address: this.individualRelationship.individualDataOverride?.address
      });
    }

    setControlDisabled(this.form.controls.address, this.hideAddress);
  }

  save(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const individualRelationship = new Relationship({
      ...this.individualRelationship,
      individualDataOverride: new IndividualData({
        ...parseFullName(this.form.controls.name.value ?? ''),
        address: new Address(this.form.controls.address.value as Partial<Address>)
      })
    });

    this.activeModal.close(individualRelationship);
  }

  cancel(): void {
    setControlDisabled(this.form);
    this.activeModal.dismiss();
  }
}
