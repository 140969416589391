<div class="select-container" [style.width]="width">
  <div class="header">
    @if (label) {
      <div class="label">{{ label }}</div>
    }

    @if (link && text) {
      <div>
        <app-link [link]="link" [text]="text"></app-link>
      </div>
    }
  </div>

  @if (disabled) {
    <div class="dropdown">
      <button
        type="button"
        class="input-wrapper input"
        [ngClass]="{'opened': dropdownOpen}"
        [disabled]="disabled"
      >
        @if (value) {
          <ng-container *ngTemplateOutlet="shareTemplate; context: { share: value }"></ng-container>
        } @else {
          <div class="select-placeholder">{{ placeholder }}</div>
        }
        <span class="icon ic-select-icon"></span>
      </button>
    </div>
  } @else {
    <div ngbDropdown class="dropdown" (openChange)="getDropdownState($event)">
      <button
        type="button"
        class="input-wrapper input"
        [ngClass]="{'opened': dropdownOpen}"
        [disabled]="disabled"
        ngbDropdownToggle
      >
        @if (value) {
          <ng-container *ngTemplateOutlet="shareTemplate; context: { share: value }"></ng-container>
        } @else {
          <div class="select-placeholder">{{ placeholder }}</div>
        }
        <span class="icon ic-select-icon"></span>
      </button>
      <div ngbDropdownMenu class="dropdown-menu">
        @if (options.length) {
          @for (share of options; track share) {
            <button
              ngbDropdownItem
              type="button"
              class="dropdown-item"
              [class.selected]="share === value"
              [disabled]="disabled"
              (click)="selectOption(share)"
            >
              <div class="dropdown-button">
                <ng-container *ngTemplateOutlet="shareTemplate; context: { share }"></ng-container>
                <span class="icon ic-circle-check"></span>
              </div>
            </button>
          }
        } @else {
          <div class="no-options">{{ noOptionsText }}</div>
        }
      </div>
    </div>
  }

  @if (helperText) {
    <div class="helper">{{ helperText }}</div>
  }

  @if (displayValidationError) {
    <app-validation-error></app-validation-error>
  }
</div>

<ng-template #shareTemplate let-share="share">
  <app-share-class-type-label
    [securityType]="share.securityType"
    [hidePrice]="hidePrice"
  >
    <span> - </span>
    <span class="quantity">[{{ share?.number }} shares]</span>
  </app-share-class-type-label>
</ng-template>
