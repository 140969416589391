import { ChangeDetectorRef, Component, inject, Input, OnInit } from '@angular/core';
import { OfficerAppointmentForm } from "../../../../models/officerAppointmentForm";
import { Relationship } from "../../../../models/relationship";
import { SelectOption } from "../../../../models/selectOptions";
import {
  customForm484A1Errors
} from "../../documents/asic-forms/484-forms/a1-company-address-change/CompanyChangeAddress.model";
import {
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormGroup,
  ValidatorFn, Validators
} from "@angular/forms";
import { Document } from "../../../../models/document";
import { DatepickerHelper } from "../../../../custom-form-validators/date-picker-form-validators";
import { EntityChangeData } from "../../../../models/entityChangeData";
import { autocompleteServiceToken } from "../../../../services/autocomplete.service";
import { AuxiliaryService } from "../../../../services/auxiliary.service";
import {
  AddressAndOccupierComponent,
  AddressAndOccupierFormGroupControls
} from "../../../components/common/address-and-occupier/address-and-occupier.component";
import { CheckboxComponent } from "../../../components/common/checkbox/checkbox.component";
import { StepperFormComponent } from "../../stepper-form/stepper-form.component";
import {
  StepperFormDescriptionComponent
} from "../../stepper-form/stepper-form-description/stepper-form-description.component";
import { ValidationErrorComponent } from "../../../components/common/validation-error/validation-error.component";
import { BulkCompanyChangeAddress } from "./BulkCompanyChangeAddress.model";
import {
  DocumentationType,
  MeetingLocationType,
  MinutesMeetingPresence
} from "../../../../models/enums/documentConfirmEnums";
import { RadioComponent } from "../../../components/common/radio/radio.component";
import { BulkAddressChangeStepsEnum } from "../../../../models/enums/bulkAddressChangeStepsEnum";
import { ListGridComponent } from "../../../components/common/grid/components/list-grid/list-grid.component";
import { NgClass } from "@angular/common";
import { BaseBulkStepperFormComponent } from "../../stepper-form/base-bulk-stepper-form/base-bulk-stepper-form.component";
import { MatchesSelected, OfficeHoldersByCompany } from "../../../../models/bulkChangeData";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { ChangeAuthorisation, MinutesMeetingAttendee } from "../../../../models/changeAuthorisation";
import { catchError, forkJoin, of } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { CompaniesService } from "../../../../services/companies.service";
import { RelationshipType } from "../../../../models/enums/relationshipTypeEnum";
import { ColDef, RowClassRules } from "ag-grid-community";
import {
  AgEntityNameAcnAbnComponent
} from "../../../components/common/grid/components/ag-entity-name-acn-abn/ag-entity-name-acn-abn.component";
import { RouterLink } from "@angular/router";
import {
  AgSelectChangeNameComponent
} from "../../../components/common/grid/components/ag-select-change-name/ag-select-change-name.component";

export interface BulkChangeAddressFormGroupControls extends AddressAndOccupierFormGroupControls {
  relationshipIds: FormArray<FormControl<string>>;
  changeDate: FormControl<Date>;
  changeDateError: FormControl<boolean | null>;
}

export type BulkChangeAddressFormGroup = FormGroup<BulkChangeAddressFormGroupControls>;

interface AppliedChanges {
  name: string,
  acn?: string,
  abn?: string,
  entityId: string,
  fullName: string,
  relationshipId: string,
  selectedOption: SelectOption;
  options: SelectOption[];
  error: string;
}

@Component({
  selector: 'app-bulk-address-change',
  standalone: true,
  imports: [
    AddressAndOccupierComponent,
    CheckboxComponent,
    ReactiveFormsModule,
    StepperFormComponent,
    StepperFormDescriptionComponent,
    ValidationErrorComponent,
    RadioComponent,
    FormsModule,
    ListGridComponent,
    NgClass,
    RouterLink
  ],
  templateUrl: './bulk-change-address-form.component.html',
  styleUrls: [
    '../../documents/asic-forms/484-forms/a1-company-address-change/a1-company-address-change.component.scss',
    '../../stepper-form/base-stepper-component/base-stepper-form.component.scss',
    './bulk-change-address-form.component.scss'
  ],
  providers: [{ provide: autocompleteServiceToken, useClass: AuxiliaryService }]
})
export class BulkChangeAddressFormComponent extends BaseBulkStepperFormComponent<BulkAddressChangeStepsEnum, BulkCompanyChangeAddress> implements OnInit {
  cdr = inject(ChangeDetectorRef);
  companiesService = inject(CompaniesService);

  @Input() changeOfficerModel: OfficerAppointmentForm = new OfficerAppointmentForm();
  @Input() officers: Record<string, Relationship[]> = {};
  @Input() officersSelectOptions: SelectOption[] = [];

  readonly customErrors = customForm484A1Errors;
  override readonly StepsEnum = BulkAddressChangeStepsEnum;

  public rowClassRules: RowClassRules = {
    'error-row-bg': (params: { data: { error: string }}) => !!params.data.error,
  };

  documentTypeOptions: SelectOption[] = [
    { label: 'Minutes', value: DocumentationType.Minutes },
    { label: 'Resolution', value: DocumentationType.Resolution },
    { label: 'ASIC Form only', value: DocumentationType.FormOnly },
  ];

  colDefs: ColDef[] = [
    {
      headerName: 'Entity Name',
      field: 'name',
      sort: 'asc',
      flex: 1,
      cellRenderer: AgEntityNameAcnAbnComponent,
      menuTabs: []
    },
    {
      headerName: 'Authorising Officeholder',
      field: 'fullName',
      flex: 1.2,
      cellRenderer: AgSelectChangeNameComponent,
      cellRendererParams: {
        selectName: this.selectName.bind(this),
      },
      menuTabs: []
    },
  ];
  private readonly colDefsWithErrorLength = 3;

  rows: AppliedChanges[] = [];

  addressInfo = 'A non-Australian address cannot be utilised as a registered address,' +
    ' principal place of business, or a director address for a sole director company.';

  minDate!: NgbDateStruct;
  maxDate!: NgbDateStruct;
  expandedAddressForm = false;
  bulkMatchesSelected: MatchesSelected[] = [];
  officeHoldersByCompany: OfficeHoldersByCompany[] = [];
  changeAuthorisationByCompany: { companyId: string, changeAuthorisation: ChangeAuthorisation }[] = [];
  isIndividual = false;
  trustOnly = false;
  isAustraliaSelected = false;
  documentationType: DocumentationType = DocumentationType.Minutes;
  rowHasError = false;

  form: BulkChangeAddressFormGroup = new UntypedFormGroup({
    applyToRegistered: new FormControl<boolean | null>(null),
    applyToPrincipal: new FormControl<boolean | null>(null),
    relationshipIds: new FormArray<FormControl<string>>([]),
    changeDate: new FormControl<Date | null>(null),
    changeDateError: new FormControl<boolean | null>(false, [Validators.required])
  }) as BulkChangeAddressFormGroup;

  override stepperForm = new FormGroup({
    [BulkAddressChangeStepsEnum.FormDescription]: new FormGroup({}),
    [BulkAddressChangeStepsEnum.NewAddress]: this.form,
    [BulkAddressChangeStepsEnum.Summary]: this.form,
  });

  constructor() {
    super();
    this.setupSteps(BulkAddressChangeStepsEnum);
  }

  ngOnInit(): void {
    this.minDate = DatepickerHelper.getDefaultMinDateStruct();
    this.maxDate = DatepickerHelper.getTodayStruct();
    this.setupDefaultState();
    this.setupChange();
    this.listenChangeDate();

    this.cdr.detectChanges();
  }

  override buildDocument(): Document[] | null {
    const formDate = new Date(this.form.value.changeDate as Date | string);
    const changeDate = DatepickerHelper.buildDateString(formDate);
    const bulkCompanyChangeAddress = this.bulkMatchesSelected.map(item => {
      return new BulkCompanyChangeAddress({
        ...this.form.value as Partial<BulkCompanyChangeAddress>,
        changeDate,
        applyToRegistered: item?.matches?.some(match => match.label === 'Registered Address'),
        applyToPrincipal: item?.matches?.some(match => match.label === 'Principal Place of Business'),
        isIndividual: this.isIndividual,
        relationshipIds: item.relationshipIds,
        relationships: item.relationships
      });
    });

    this.documentationType = this.formModel.documentationType;

    try {
      return this.bulkMatchesSelected.map(((item, index) => {
        if(item.acn) {
          const changeAuthorisation = this.changeAuthorisationByCompany[index].changeAuthorisation;
          changeAuthorisation.documentationType = this.documentationType;

          if (this.documentationType === DocumentationType.Minutes) {
            if(changeAuthorisation.authorisingParty) {
              const meetingDate = this.form.value.changeDate?.toISOString().split('T')[0];
              changeAuthorisation.meetingDate = meetingDate ? meetingDate as unknown as Date : undefined;
              changeAuthorisation.meetingAddressType = MeetingLocationType.Registered;
              changeAuthorisation.meetingAddress = changeAuthorisation.authorisingParty?.entity?.registeredAddress;
              changeAuthorisation.chairperson = changeAuthorisation.authorisingParty;
            }
          }

          if(changeAuthorisation.authorisingParty) {
            changeAuthorisation.signingManager = changeAuthorisation.authorisingParty.relationshipId;
            changeAuthorisation.authorisingParty.entityId = item.entityId;
          }

          delete changeAuthorisation.authorisingParty.entity;
          delete changeAuthorisation.chairperson?.entity;

          return new Document({
            changes: [bulkCompanyChangeAddress[index] as EntityChangeData],
            changeAuthorisation,
            entityId: item.entityId,
            type: 'c:484',
            documentId: this.companyChangeData?.documentId,
            documentationType: this.documentationType
          });
        } else {
          return new Document({
            changes: [bulkCompanyChangeAddress[index] as EntityChangeData],
            entityId: item.entityId,
            type: 't:484',
            documentId: this.companyChangeData?.documentId,
            documentationType: this.documentationType
          });
        }
      }));
    } catch (error) {
      console.warn(error);
      this.toastr.error('Failed to create Document.', 'Error');
      return null;
    }
  }

  override afterSubmit(changes: EntityChangeData[]) {
    this.setupChange(changes[0] as BulkCompanyChangeAddress);
  }

  override setupChange(change: BulkCompanyChangeAddress = this.formModel): void {
    this.bulkMatchesSelected = change.bulkMatchesSelected;

    this.getOfficeHoldersByCompany();
    this.getOfficeHoldersByTrust();

    this.isIndividual = !!change.isIndividual;
    this.trustOnly = change.trustOnly;
    this.documentationType = this.trustOnly ? DocumentationType.Default : DocumentationType.Minutes;
    this.formModel.documentationType = this.documentationType;

    if(this.trustOnly) {
      this.currentStep = this.StepsEnum.NewAddress;
      this.currentStepIndex = 1;
    }

    if (!this.isEdit && change === this.formModel)
      return;

    this.form.patchValue({
      ...change,
      companyHaveOccupiersConsent: !!this.formModel.occupierNameIfDoesntOccupy.trim(),
    });
    this.cdr.detectChanges();
  }

  private setupDefaultState(): void {
    this.addDoNotAllowForeignAddressAsCompanyAddressesValidator();
  }

  //  do not allow:
  //  - using a foreign address as a registered address
  //  - using a foreign address as a principal address
  private addDoNotAllowForeignAddressAsCompanyAddressesValidator(): void {
    this.form.addValidators(((form: BulkChangeAddressFormGroup) => {
      if (form.value.address?.country) {
        const isAustraliaSelected = form.value.address?.country === 'AU' || form.value.address?.country === 'Australia';
        this.isAustraliaSelected = isAustraliaSelected;

        if (!isAustraliaSelected) {
          return { foreignAddressForCompany: 'true' };
        }
      }

      return null;
    }) as ValidatorFn);
  }

  private getOfficeHoldersByCompany(): void {
    const companyIds: string[] = this.bulkMatchesSelected
      .filter(item => item.acn)
      .map(item => item.entityId);
    const getOfficeHolders = companyIds.map(companyId => this.companiesService.officeHolders(companyId));

    forkJoin(getOfficeHolders)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        catchError(error => {
          console.error('Error getting office holders:', error);
          return of([] as { isSigningContact: boolean, relationship: Relationship }[][]);
        })
      )
      .subscribe(officeHolders => {
        if(officeHolders.length) {
          this.officeHoldersByCompany = officeHolders.map((item, index) => {
            const relationship = item[0].relationship;
            this.rows.push({
              name: relationship.entity?.name ?? '',
              fullName: '',
              acn: relationship.entity?.entityNumber ?? '',
              entityId: relationship.entityId,
              relationshipId: '',
              selectedOption: { label: '', value: '' },
              options: [],
              error: '',
            });

            return ({companyId: companyIds[index], officeHolders: item});
          });

          this.changeAuthorisationByCompany = this.officeHoldersByCompany.map((officeHolder, index) => {
            const changeAuthorisation = new ChangeAuthorisation();

            const relationshipOfficeHolders = officeHolder.officeHolders.map(item => item.relationship);
            const signingContact = officeHolder.officeHolders.find(item => item.isSigningContact)?.relationship;
            const directors = relationshipOfficeHolders.filter(o => o.type === RelationshipType.Director);
            const authOfficeHolder = directors[0];
            changeAuthorisation.attendees = directors?.map(director => {
              return {
                individual: director.individualDataOverride,
                presence: MinutesMeetingPresence.PresentPhysically
              }
            }) as MinutesMeetingAttendee[];

            changeAuthorisation.authorisingParty = signingContact ? signingContact : authOfficeHolder;
            changeAuthorisation.signingManager = changeAuthorisation.authorisingParty.relationshipId;
            changeAuthorisation.authorisingParty.entityId = relationshipOfficeHolders[0].entityId;
            changeAuthorisation.documentationType = this.form.get('documentationType')?.value as unknown as DocumentationType;

            if(directors.length === 1) {
              changeAuthorisation.documentationType = DocumentationType.Resolution;
            }

            const changeDate = this.form.get('changeDate')?.value as unknown as Date;

            if(relationshipOfficeHolders[0].entity?.dateOfEstablishment) {
              this.rows[index].error = changeDate <= new Date(relationshipOfficeHolders[0].entity?.dateOfEstablishment)
                ? 'Date of change cannot be earlier or same as the company registration date'
                : '';
            }

            if(this.rows[index].acn) {
              this.rows[index].options = directors.map(director => ({ label: director.individualDataOverride?.fullName ?? '', value: director.relationshipId }) );
              this.rows[index].selectedOption = { label: this.rows[index].options[0].label, value: this.rows[index].options[0].value };
            }

            return {companyId: officeHolder.companyId, changeAuthorisation};
          });

          this.rows = [...this.rows];
          this.addErrorToRows();
        }
      });
  }

  selectName(data: {  entityId: string, selectedId: string }): void {
    let selectedOfficeholder: Relationship | undefined;
    this.officeHoldersByCompany.forEach(item => {
      if(item.companyId === data.entityId) {
        selectedOfficeholder = item.officeHolders.find(officeholder => {
          return officeholder.relationship.relationshipId === data.selectedId
        })?.relationship;
      }
    });

    this.changeAuthorisationByCompany.forEach(c => {
      if(c.companyId === data.entityId) {
        if(selectedOfficeholder) {
          c.changeAuthorisation.authorisingParty = selectedOfficeholder;
        }

        c.changeAuthorisation.signingManager = data.selectedId;
      }
    });
  }

  private getOfficeHoldersByTrust(): void {
    const trusts = this.bulkMatchesSelected.filter(item => item.abn);
    trusts.forEach(trust => {
      this.rows.push({
        name: trust.name ?? '',
        fullName: '',
        abn: trust?.abn ?? '',
        entityId: trust.entityId,
        relationshipId: '',
        selectedOption: { label: '', value: '' },
        options: [],
        error: '',
      });
    })
  }

  onStepChange(stepIndex: BulkAddressChangeStepsEnum): void {
    if(stepIndex === BulkAddressChangeStepsEnum.Summary) {
      if(this.form.get('changeDateError')?.value) {
        this.form.get('changeDateError')?.patchValue(null);
      }
    }
  }

  private getOfficers(includeALt = true, icludeSec = true, officeHolders: Relationship[]) {
    let officersSelected: Relationship[] = [];
    const includedTypes: RelationshipType[] = [RelationshipType.Director];

    if (includeALt) {
      includedTypes.push(RelationshipType.AlternativeDirector);
    }
    if (icludeSec) {
      includedTypes.push(RelationshipType.Secretary);
    }

    officersSelected = officeHolders.filter(
      x => x.type != undefined && includedTypes.includes(x.type)
    );

    return officersSelected;
  }

  private addErrorToRows(): void {
    this.rowHasError = this.rows.some(row => row.error);

    if (this.rowHasError && !this.colDefs.some(c => c.field === 'error')) {
      this.colDefs.push({
        headerName: 'Error',
        field: 'error',
        flex: 1.5,
        cellRenderer: (params: { value: string }) => {
          const error = params.value;
          if (error) {
            return `<div style="color: #CE5252; line-height: 2px; padding-top: 8px;">${error.slice(0, Math.floor(error.length / 2))}</div>
                    <div style="color: #CE5252;">${error.slice(Math.floor(error.length / 2), error.length)}</div>`;
          } else {
            return `<div>${error}</div>`;
          }
        },
        menuTabs: []
      });
    } else if(!this.rowHasError && this.colDefs.length === this.colDefsWithErrorLength) {
      this.colDefs.pop();
    }

    this.colDefs = [...this.colDefs];

    if(this.rows.some(row => row.error)) {
      this.form.get('changeDateError')?.setValue(true);
    } else {
      this.form.get('changeDateError')?.setValue(false);
    }
  }

  private listenChangeDate(): void {
    this.form.controls.changeDate.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(changeDate => {
        if(changeDate) {
          this.officeHoldersByCompany.map((officeHolder, index) => {
            const relationshipOfficeHolders = officeHolder.officeHolders.map(item => item.relationship);

            if(relationshipOfficeHolders[0].entity?.dateOfEstablishment) {
              this.rows[index].error = changeDate <= new Date(relationshipOfficeHolders[0].entity?.dateOfEstablishment)
                ? 'Date of change cannot be earlier or same as the company registration date'
                : '';
            }
          });

          this.rows = [...this.rows];
          this.addErrorToRows();
        }
      });
  }
}
