<app-stepper-form
  header="Cessation of Officeholder"
  [showSteps]="false"
  [steps]="steps"
  [currentStep]="currentStep"
  [currentStepIndex]="currentStepIndex"
  [isLoading]="isLoading"
  [form]="stepperForm"
  (stepIndexChange)="onStepChange($event)"
>
  <div
    class="step-content"
    [ngClass]="{'step-content-error': (currentStep === StepsEnum.Summary && rowHasError)}"
    [formGroup]="stepperForm"
  >
    <app-stepper-form-description
      formKey="484 B1"
      [hidden]="currentStep !== StepsEnum.FormDescription"
    ></app-stepper-form-description>

    <section
      class="step-section"
      [hidden]="currentStep !== StepsEnum.CeaseCompanyOfficeholder"
      [formGroupName]="StepsEnum.CeaseCompanyOfficeholder"
    >
      <h2 class="title">{{ steps[currentStepIndex].label }}</h2>

      <div class="form-group">
        <app-select
          label="Cease Officer"
          formControlName="appointedOfficerId"
          [options]="officersSelectOptions"
          [valuePrimitive]="true"
          (select)="form.controls.cessationReason.reset()"
        ></app-select>
      </div>

      <div class="form-group d-flex gap-32">
        <app-date-picker
          [ngClass]="{'width50': this.form.get('position')?.value !== null, 'width100': this.form.get('position')?.value === null }"
          label="Date of Cessation"
          formControlName="changeDate"
          [minDate]="minDate"
          [maxDate]="maxDate"
          [customErrors]="customDatepickerErrors"
        ></app-date-picker>

        @if(this.form.get('position')?.value !== null) {
          <app-select
            class="width50"
            label="Positions"
            formControlName="position"
            [options]="officerPositionTypesOptions"
            [valuePrimitive]="true"
          ></app-select>
        }
      </div>

      <div class="form-group">
        <app-select
          label="Reason"
          formControlName="cessationReason"
          [options]="reasonOfCessationOptions"
          [valuePrimitive]="true"
        ></app-select>
      </div>

      <div class="form-group">
        <app-radio
          formControlName="documentationType"
          [label]="'Choose the type of document to generate'"
          [options]="documentTypeOptions"
        ></app-radio>
      </div>

      <div class="form-group" [hidden]="noticeOfResignationHidden">
        <app-textarea
          label="Notice of Resignation"
          formControlName="noticeOfResignation"
          [rows]="3"
        ></app-textarea>
      </div>
    </section>

    <section
      class="step-section gap-32"
      [formGroup]="form"
      [hidden]="currentStep !== StepsEnum.Summary"
    >
      <div class="change">
        <h2 class="title">Change is applied for</h2>

        <app-list-grid
          class="change-grid"
          [rowData]="rows"
          [colDefs]="colDefs"
          [rowClassRules]="rowClassRules"
          [suppressRowClickSelection]="true"
          [pagination]="true"
          [paginationPageSizeSelector]="false"
          [suppressCellFocus]="true"
        ></app-list-grid>
      </div>
    </section>
  </div>
</app-stepper-form>


