<app-stepper-form
  header="Appointment of Officeholder"
  [showSteps]="false"
  [currentStepIndex]="currentStepIndex"
  [currentStep]="currentStep"
  [steps]="steps"
  [form]="stepperForm"
  [isLoading]="isLoading"
  (stepIndexChange)="onStepChange($event)"
>
  <div
    class="step-content"
    [formGroup]="stepperForm"
    [ngClass]="{'step-content-error': (currentStep === StepsEnum.Summary && rowHasError)}"
  >
    <app-stepper-form-description
      formKey="484 B2"
      [hidden]="currentStep !== StepsEnum.FormDescription"
    ></app-stepper-form-description>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.AppointOfficer"
      [hidden]="currentStep !== StepsEnum.AppointOfficer"
    >
      <h1 class="h1-heading">Appoint Officer</h1>

      <div class="form-group">
        <app-input
          formControlName="fullName"
          [label]="individualDataFormLabels.fullName"
          [customErrors]="directorFullNameValidatorMsg"
        ></app-input>
      </div>

      <div class="form-group">
        <app-input
          formControlName="formerFullName"
          [label]="individualDataFormLabels.formerName"
          [customErrors]="directorFullNameValidatorMsg"
        ></app-input>
      </div>

      <app-divider [margin]="'0 0 16px 0'"></app-divider>

      <div class="form-group">
        <app-date-picker
          label="Date of Appointment"
          formControlName="changeDate"
          [minDate]="minDate"
          [maxDate]="maxDate"
        ></app-date-picker>
      </div>

      <div class="form-group" [formGroup]="form">
        <app-address-control
          [useInternationalAddresses]="useInternationalAddresses"
          [address]="individualData?.address"
          [expandedAddressForm]="expandedAddressForm"
        ></app-address-control>
      </div>

      <div class="form-group d-flex gap-12">
        <app-date-picker
          formControlName="dob"
          [label]="individualDataFormLabels.dob"
          [minDate]="minDobDate"
          [maxDate]="maxDobDate"
        ></app-date-picker>

        <app-autocomplete
          formControlName="birthCountry"
          [label]="individualDataFormLabels.birthCountry"
          [options]="countryOptions"
          [valuePrimitive]="true"
        ></app-autocomplete>

        <app-input
          formControlName="birthCity"
          [label]="individualDataFormLabels.birthCity"
        ></app-input>
      </div>

      <div class="form-group d-flex gap-12">
        <app-select
          style="width: 100%"
          label="Position"
          formControlName="type"
          [options]="positionTypesOptions"
          [valuePrimitive]="true"
        ></app-select>

        @if (selectedPositionSignal() && selectedPositionSignal() !== OfficerAppointmentType.Secretary) {
          <app-din-masked-input
            style="width: 48%"
            formControlName="din"
          ></app-din-masked-input>
        }
      </div>

      <div class="document-type mb-3">
        <app-radio
          formControlName="documentationType"
          [label]="'Choose the type of document to generate'"
          [options]="documentTypeOptions"
        ></app-radio>
      </div>

      <div class="info">
        <div><i class="icon ic-circle-info"></i></div>
        <div class="text">{{generateDocumentsInfo}}</div>
      </div>
    </section>

    <section
      class="step-section gap-32"
      [formGroup]="form"
      [hidden]="currentStep !== StepsEnum.Summary"
    >
      <div class="change">
        <h2 class="title">Change is applied for</h2>

        <app-list-grid
          class="change-grid"
          [rowData]="rows"
          [colDefs]="colDefs"
          [rowClassRules]="rowClassRules"
          [suppressRowClickSelection]="true"
          [pagination]="true"
          [paginationPageSizeSelector]="false"
          [suppressCellFocus]="true"
        ></app-list-grid>
      </div>
    </section>
  </div>
</app-stepper-form>
