<div class="corporate-relationship-form-group" [formGroup]="form">
  <div class="form-group">
    <app-company-name-acn-form-group
      [formGroup]="form"
      [shouldLookForCompanyName]="!form.controls.isOverseasCompany.value"
      [hideAcn]="!!form.controls.isOverseasCompany.value"
      (foundEntityId)="foundEntityId.emit($event)"
    >
      <div class="form-group" [hidden]="hideIsOverseasCompany">
        <app-checkbox
          label="This is an overseas company or the company doesn't have an ACN"
          formControlName="isOverseasCompany"
        ></app-checkbox>
      </div>
    </app-company-name-acn-form-group>
  </div>

  <div class="form-group">
    <app-address-form-group [formGroup]="form.controls.address"></app-address-form-group>
  </div>
</div>
