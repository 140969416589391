import { SelectOption } from "../../models/selectOptions";

export const stateLabelsDictionary: Record<string, string> = {
  NSW: "New South Wales",
  QLD: "Queensland",
  SA: "South Australia",
  TAS: "Tasmania",
  VIC: "Victoria",
  WA: "Western Australia",
  ACT: "Australian Capital Territory",
  NT: "Northern Territory"
};

export const stateOptions: SelectOption[] = [
  { value: 'NSW', label: stateLabelsDictionary['NSW'] },
  { value: 'QLD', label: stateLabelsDictionary['QLD'] },
  { value: 'SA', label: stateLabelsDictionary['SA'] },
  { value: 'TAS', label: stateLabelsDictionary['TAS'] },
  { value: 'VIC', label: stateLabelsDictionary['VIC'] },
  { value: 'WA', label: stateLabelsDictionary['WA'] },
  { value: 'ACT', label: stateLabelsDictionary['ACT'] },
  { value: 'NT', label: stateLabelsDictionary['NT'] }
];
