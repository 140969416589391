@for(item of dataMatches; track $index) {
  <div class="d-flex gap-24">
    <div class="checkbox-container">
      <app-checkbox
        [label]="item.label"
        [boldLabel]="true"
        [helperText]="item?.type || ''"
        [value]="item.value"
        (change)="selectItem($event, $index)"
      ></app-checkbox>
    </div>
    <div class="address">{{item.address}}</div>
  </div>
}
