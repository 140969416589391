import { EmailPayloadBase } from "../emailPayloadBase";

export class AsicDocumentsEmailPayload extends EmailPayloadBase {
  static override $type = 'AsicDocumentsEmailPayload';

  companyName: string;
  companyACN: string;
  directorFirstName: string;
  directorFullName: string;
  documentName: string;
  lodgementDeadline: string;
  lodgementDeadlineShortFormat: string;
  tenDaysBeforeLodgementDeadline: string;
  tenDaysBeforeLodgementDeadlineShortFormat: string;
  username: string;
  partner: string;
  asicContact: string;
  accountManager: string;
  billingContactFirstName: string;
  billingContactFullName: string;
  documentType: string;

  constructor(data: Partial<AsicDocumentsEmailPayload> = {}) {
    super(data);
    this.companyName = data.companyName ?? '';
    this.companyACN = data.companyACN ?? '';
    this.directorFirstName = data.directorFirstName ?? '';
    this.directorFullName = data.directorFullName ?? '';
    this.documentName = data.documentName ?? '';
    this.lodgementDeadline = data.lodgementDeadline ?? '';
    this.lodgementDeadlineShortFormat = data.lodgementDeadlineShortFormat ?? '';
    this.tenDaysBeforeLodgementDeadline = data.tenDaysBeforeLodgementDeadline ?? '';
    this.tenDaysBeforeLodgementDeadlineShortFormat = data.tenDaysBeforeLodgementDeadlineShortFormat ?? '';
    this.username = data.username ?? '';
    this.partner = data.partner ?? '';
    this.asicContact = data.asicContact ?? '';
    this.accountManager = data.accountManager ?? '';
    this.billingContactFirstName = data.billingContactFirstName ?? '';
    this.billingContactFullName = data.billingContactFullName ?? '';
    this.documentType = data.documentType ?? '';
  }
}
