export enum ShareTypeEnum {
  ORD = 'ORD',
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  L = 'L',
  M = 'M',
  N = 'N',
  O = 'O',
  EMP = 'EMP',
  FOU = 'FOU',
  LG = 'LG',
  CPS = 'GPS',
  MAN = 'MAN',
  RED = 'RED',
  SPE = 'SPE',
  PRF = 'PRF',
  CUMP = 'CUMP',
  NCP = 'NCP',
  REDP = 'REDP',
  NRP = 'NRP',
  CRP = 'CRP',
  NCRP = 'NCRP',
  PARP = 'PARP',
  DIVR = 'DIVR',
  ORDA = 'ORDA',
  ORDB = 'ORDB',
  ORD1 = 'ORD1',
  APRF = 'APRF'
}

export const shareTypeOptions = Object.values(ShareTypeEnum).map((type) => ({ value: type, label: type }));
