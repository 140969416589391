import { Component, DestroyRef, EventEmitter, inject, Input, Output } from '@angular/core';
import {
    DinMaskedInputComponent
} from "../../components/common/masked-input/specific-masked-inputs/din-masked-input/din-masked-input.component";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModalFormComponent } from "../common-modal-form/common-modal-form.component";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { InputComponent } from "../../components/common/input/input.component";
import { InputPhoneNumberComponent } from "../../components/common/input-phone-number/input-phone-number.component";
import { CustomFormValidators } from "../../../custom-form-validators/custom-form-validators";
import { emailValidatorMsg } from "../../../validators/validatorMessages/custom-form-validators-messages";
import { ContactsService } from "../../../services/contacts.service";
import { Contact } from "../../../models/contact";
import { Relationship } from "../../../models/relationship";
import { CompaniesService } from "../../../services/companies.service";
import { Company } from "../../../models/company";
import { observe, Observer } from "fast-json-patch";
import { IndividualData } from "../../../models/individualData";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-officer-contact-edit',
  standalone: true,
  imports: [
    DinMaskedInputComponent,
    FormsModule,
    ReactiveFormsModule,
    CommonModalFormComponent,
    InputComponent,
    InputPhoneNumberComponent
  ],
  templateUrl: './officer-contact-edit.component.html',
  styleUrl: './officer-contact-edit.component.scss'
})
export class OfficerContactEditComponent {
  @Input() company: Company | undefined;
  @Input() officer: Relationship | undefined;
  @Input() din = '';
  @Input() email = '';
  @Input() phone = '';
  @Output() confirm = new EventEmitter<boolean>();

  private contactsService = inject(ContactsService);
  private companiesService = inject(CompaniesService);
  private activeModal = inject(NgbActiveModal);
  private fb: FormBuilder = inject(FormBuilder);
  private destroyRef = inject(DestroyRef);

  protected readonly CustomFormValidators = CustomFormValidators;
  protected readonly emailValidatorMsg = emailValidatorMsg;

  form!: FormGroup
  observer!: Observer<Company>;

  ngOnInit(): void {
    this.initForm();

    if(this.company) {
      this.observer = observe(this.company);
    }
  }

  private initForm(): void {
    this.form = this.fb.group({
      din: [this.din],
      email: [this.email, [CustomFormValidators.emailValidator]],
      phone: [this.phone, [CustomFormValidators.phoneNumberValidator]],
    });
  }

  public save(): void {
    const { din, email, phone } = this.form.getRawValue();
    const companyId = this.company?.entityId ?? '';
    this.company?.officers.forEach(officer => {
      if (officer.relationshipId === this.officer?.relationshipId) {
        officer.individualDataOverride = officer.individualDataOverride ?? new IndividualData();
        officer.individualDataOverride.din = din ?? '';

        officer.contact = officer.contact ?? new Contact();
        officer.contact.email = email;
        officer.contact.phone = phone;
      }
    });

    //TODO: need to check backend
    this.companiesService.patchCompany(companyId, this.observer).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();

    this.activeModal.close();
  }

  public close(): void {
    this.activeModal.close();
  }

  get checkAtLeastOneFieldFilled(): boolean {
    const { din, email, phone } = this.form.value;
    return !!din?.trim() || !!email?.trim() || !!phone?.trim();
  }
}
