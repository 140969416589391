import { Component } from '@angular/core';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { IndividualData } from '../../../../../../models/individualData';
import { AgActionIconButtonComponent } from '../ag-action-icon-button/ag-action-icon-button.component';

type ActionEvent = (data: IndividualData | undefined) => void;

type CellRendererParams = ICellRendererParams<IndividualData> & {
  open: ActionEvent,
  openAlerts: ActionEvent,
  openNotes: ActionEvent,
};

@Component({
  selector: 'app-ag-individual-actions',
  standalone: true,
  imports: [
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
    AgActionIconButtonComponent
  ],
  templateUrl: './ag-individual-actions.component.html',
  styleUrl: './ag-individual-actions.component.scss'
})
export class AgIndividualActionsComponent implements ICellRendererAngularComp {
  protected params!: CellRendererParams;
  protected numberOfAlerts = 0;

  agInit(params: CellRendererParams): void {
    this.params = params;
    this.numberOfAlerts = this.params.data?.alerts?.length ?? 0;
  }

  refresh(params: CellRendererParams): boolean {
    this.params = params;
    return true;
  }

  onOpenProfile(): void {
    this.params.open(this.params.data);
  }

  onOpenAlerts(): void {
    this.params.openAlerts(this.params.data);
  }

  onOpenNotes(): void {
    this.params.openNotes(this.params.data);
  }
}
