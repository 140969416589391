import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DatepickerHelper } from '../../../../../custom-form-validators/date-picker-form-validators';
import { Company } from '../../../../../models/company';
import { Document } from '../../../../../models/document';
import { EntityChangeData } from '../../../../../models/entityChangeData';
import { CompanyChangeData } from '../../../../../models/enums/companyChangeData';
import { ChangeDictionaryHelper } from '../../../../../models/shared/change-dictionary-helper.model';
import { Form492RequestCorrectionComponent } from './form492-request-correction.component';

export class CompanyMistakeCorrection extends EntityChangeData {
  static override readonly $type = 'CompanyMistakeCorrection';

  // formProcessed = true;
  correctionText: string;
  correctionType: CompanyMistakeCorrectionType;
  formProcessed = true;
  paperLodgement = false;
  documentNumber: string;
  formCode: string;
  lodgementDate: Date;
  documentTitle: string;
  correctionDetails: string;

  constructor(data: Partial<CompanyMistakeCorrection> = {}) {
    super(data);

    this.correctionText = data.correctionText ?? '';
    this.correctionType = data.correctionType ?? CompanyMistakeCorrectionType.F492;
    this.documentNumber = data.documentNumber ?? '';
    this.formCode = data.formCode ?? '';
    this.lodgementDate = DatepickerHelper.toDate(data.lodgementDate);
    this.documentTitle = data.documentTitle ?? '';
    this.correctionDetails = data.correctionDetails ?? '';
  }

  override toDictionary(): { key: string; value: string; }[] {
    const dict = new ChangeDictionaryHelper();

    dict
      .add('Document title of the document being amended', this.documentTitle)
      .add('Document number of document being amended', this.documentNumber)
      .add('Form code of document being amended', this.formCode)
      .addDate('Date of lodgment of document being amended', this.lodgementDate)
      .add('Details of correction', this.correctionDetails);

    return dict.dictionary;
  }

  override fillModal(modalRef: NgbModalRef, document: Document, actualCompany: Company): NgbModalRef {
    modalRef = this.fillModalWithCompany(modalRef, actualCompany, true);
    (modalRef.componentInstance as Form492RequestCorrectionComponent).companyChangeData.documentId = document.documentId;
    return modalRef;
  }

  fillModalWithCompany(modalRef: NgbModalRef, company: Company, isEdit = false): NgbModalRef {
    const instance = modalRef.componentInstance as Form492RequestCorrectionComponent;

    instance.isEdit = isEdit;
    instance.companyChangeData = new CompanyChangeData(company);
    instance.formModel = structuredClone(this);

    return modalRef;
  }

  override prepareToRequest(): CompanyMistakeCorrection {
    return {
      ...this,
      ...super.prepareToRequest(),
      lodgementDate: DatepickerHelper.buildDateString(this.lodgementDate),
    };
  }
}

export enum CompanyMistakeCorrectionType {
  F492,
  F902
}
