import { Component, inject, Input, OnInit } from '@angular/core';
import { CommonModalFormComponent } from "../../../../../modals/common-modal-form/common-modal-form.component";
import { InputComponent } from "../../../../../components/common/input/input.component";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { TextMessageTemplatesService } from "../../../../../../services/sms-templates.service";
import {
  InputPhoneNumberComponent
} from "../../../../../components/common/input-phone-number/input-phone-number.component";
import { TextMessageSendRequest } from "../../../../../../models/text-messages/TextMessageSendRequest";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-send-sms-template-modal',
  standalone: true,
  providers: [TextMessageTemplatesService],
  imports: [
    CommonModalFormComponent,
    InputComponent,
    ReactiveFormsModule,
    InputPhoneNumberComponent
  ],
  templateUrl: './send-text-message-template-modal.component.html',
  styleUrl: './send-text-message-template-modal.component.scss'
})
export class SendTextMessageTemplateModalComponent implements OnInit {
  private textMessageTemplatesService = inject(TextMessageTemplatesService);
  private activeModal = inject(NgbActiveModal);
  private toastr = inject(ToastrService);

  @Input() phoneByDefault = '';
  @Input() textMessageSendRequest!: TextMessageSendRequest;

  isLoading = false;
  phoneControl = new FormControl<string | null>(null, [Validators.required]);

  ngOnInit(): void {
    this.phoneControl.setValue(this.phoneByDefault);
  }

  confirm(): void {
    if (this.phoneControl.invalid) {
      this.phoneControl.markAllAsTouched();
      return;
    }

    this.textMessageSendRequest.to = this.phoneControl.value!;

    this.isLoading = true;
    this.phoneControl.disable();

    this.textMessageTemplatesService.sendTestTextMessageTemplate(this.textMessageSendRequest)
      .subscribe({
        next: () => this.activeModal.close(this.textMessageSendRequest.to),
        error: () => {
          this.toastr.error('Something wrong while sending', 'SMS sending failed');
          this.phoneControl.enable();
          this.isLoading = false;
        }
      });
  }

  close(): void {
    this.activeModal.dismiss();
  }
}
