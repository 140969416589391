import { ChangeDetectionStrategy, Component, EventEmitter, input, Output } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-tab-button',
  standalone: true,
  imports: [NgClass],
  templateUrl: './tab-button.component.html',
  styleUrl: './tab-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabButtonComponent {
  isActive = input<boolean>(false);
  isDisabled = input<boolean>(false);
  iconClass = input<string | null>(null);
  @Output() tabClicked = new EventEmitter<MouseEvent>();
}
