import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { SecurityRegistryRecord } from '../../../../../../models/securityRegistryRecord';

@Component({
  selector: 'app-ag-share-beneficially-holder',
  standalone: true,
  imports: [],
  templateUrl: './ag-share-beneficially-holder.component.html',
  styleUrl: './ag-share-beneficially-holder.component.scss'
})
export class AgShareBeneficiallyHolderComponent implements ICellRendererAngularComp {
  shares!: SecurityRegistryRecord;

  agInit(params: ICellRendererParams): void {
    this.shares = params.data as SecurityRegistryRecord;
  }

  refresh(params: ICellRendererParams): boolean {
    this.shares = params.data as SecurityRegistryRecord;
    return true;
  }

  get isBeneficialOwner(): boolean {
    return this.shares.isBeneficialOwner;
  }

  get beneficialOwner(): string {
    return this.removeDuplicates(this.shares.holders.map(holder => holder.details.beneficialOwner)).join(', ') ?? '';
  }

  private removeDuplicates(beneficialOwners: string[]): string[] {
    return beneficialOwners.filter((beneficialOwner, index) => beneficialOwners.indexOf(beneficialOwner) === index);
  }
}
