import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  signal,
  WritableSignal
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  NgbActiveModal,
  NgbDropdown,
  NgbDropdownAnchor,
  NgbDropdownMenu,
  NgbDropdownToggle
} from '@ng-bootstrap/ng-bootstrap';
import { ValueGetterParams } from 'ag-grid-community';
import { ColDef } from 'ag-grid-enterprise';
import { ToastrService } from 'ngx-toastr';
import { catchError, finalize, of, startWith, tap } from 'rxjs';
import { excludeArray } from '../../../../../functions/exclude-array';
import { filterUniqueElements } from '../../../../../functions/filter-unique-elements';
import { setControlDisabled } from '../../../../../functions/set-control-disabled';
import { Entity } from '../../../../../models/entity';
import { EntityType, getEntityType } from '../../../../../models/enums/entityType';
import { Individual } from '../../../../../models/individual';
import { IndividualRecord } from '../../../../../models/IndividualRecord';
import { Tag } from '../../../../../models/tag';
import { TagsService } from '../../../../../services/tags.service';
import {
  CommonModalWrapperComponent
} from '../../../../components/common/common-modal-wrapper/common-modal-wrapper.component';
import { AgEntityTagsComponent } from '../../../../components/common/grid/components/ag-company-tag/ag-entity-tags.component';
import {
  AgTagEntityTitleComponent
} from '../../../../components/common/grid/components/ag-tag-entity-title/ag-tag-entity-title.component';
import { ListGridComponent } from '../../../../components/common/grid/components/list-grid/list-grid.component';
import { SelectComponent } from '../../../../components/common/select/select.component';
import { TagComponent } from '../../../../components/common/tag/tag.component';
import {
  SelectTagsDropdownComponent
} from '../../../../components/company-profile-tags/select-tags-dropdown/select-tags-dropdown.component';
import { ManageTagRange } from './manage-tag-range.class';
import { ManageTagsModalActionsOptions, TagActionEnum } from './manage-tags-modal.constant';
import { Company } from "../../../../../models/company";

export type ITagsEntity = Entity | Company | Individual | IndividualRecord;

@Component({
  selector: 'app-manage-tags-modal',
  standalone: true,
  imports: [
    CommonModalWrapperComponent,
    ReactiveFormsModule,
    SelectComponent,
    NgbDropdown,
    NgbDropdownAnchor,
    TagComponent,
    NgbDropdownToggle,
    SelectTagsDropdownComponent,
    NgbDropdownMenu,
    ListGridComponent
  ],
  templateUrl: './manage-tags-modal.component.html',
  styleUrl: './manage-tags-modal.component.scss'
})
export class ManageTagsModalComponent implements OnInit {
  tagsService = inject(TagsService);
  activeModal = inject(NgbActiveModal);
  destroyRef: DestroyRef = inject(DestroyRef);
  toastr = inject(ToastrService);

  @Input() entities: ITagsEntity[] = [];

  @Output() confirm: EventEmitter<ManageTagRange> = new EventEmitter<ManageTagRange>();

  readonly TagActionEnum = TagActionEnum;
  readonly ManageTagsModalActionsOptions = ManageTagsModalActionsOptions;
  readonly notFoundMessage = 'No tags have been selected.';

  isLoading = false;
  actionTags: Tag[] = [];
  selectedEntitiesTags: Tag[] = [];
  colDefConfigSignal: WritableSignal<ColDef[]> = signal([]);
  form = new FormGroup({
    action: new FormControl<TagActionEnum>(TagActionEnum.Assign, { nonNullable: true }),
    search: new FormControl<string | null>(''),
  });

  ngOnInit() {
    this.selectedEntitiesTags = this.entities.flatMap(entity => entity.tags);
    this.actionTags = [];
    this.listenActionChange();
  }

  onSearchInputChange(e: Event): void {
    const event = e as KeyboardEvent;
    const text = event.target?.['value'] as string;
    this.form.controls.search.setValue(text ?? '');
  }

  removeLastElement(event: KeyboardEvent): void {
    const text = (event.target?.['value'] || '') as string;

    if (
      !text.trim().length
      && this.actionTags.length
      && (event.key === 'Backspace' || event.key === 'Delete')
    ) {
      this.assignTag({ tag: this.actionTags[this.actionTags.length - 1], assign: false });
    }
  }

  assignTag({ tag, assign }: { tag: Tag, assign: boolean }): void {
    if (assign && !this.actionTags.find(t => t.tagId === tag.tagId)) {
      this.actionTags.push(tag);
    } else {
      this.actionTags = this.actionTags.filter(t => t.tagId !== tag.tagId);
    }

    this.updateColDefs(this.form.controls.action.value);
    this.form.controls.search.setValue('');
  }

  onModalSubmit(): void {
    if (!this.actionTags.length) {
      this.activeModal.dismiss();
      return;
    }

    this.setDisabledState(true);
    const manageTagRange = new ManageTagRange({
      entitiesOrIndividualsId: this.entities.map(entity => entity['entityId'] || entity['individualId']),
      tagsId: this.actionTags.map(tag => tag.tagId),
      tagAction: this.form.controls.action.value ?? TagActionEnum.Assign,
      type: this.entityType
    });

    this.tagsService.assignTagsRange(manageTagRange)
      .pipe(finalize(() => this.setDisabledState(false)))
      .subscribe({
        next: () => {
          this.activeModal.close();
          this.confirm.emit(manageTagRange);
        },
        error: (err) => {
          console.warn('[ManageTagsModalComponent] Error while assigning tags in Range: ', err, EntityType[this.entityType], this.actionTags, this.entities);
          this.toastr.error('Error while assigning tags in Manage Tags');
        }
      });
  }

  onModalClose(): void {
    this.activeModal.dismiss();
  }

  listenActionChange(): void {
    this.form.controls.action.valueChanges
      .pipe(
        startWith(this.form.controls.action.value),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((action: TagActionEnum) => this.updateColDefs(action));
  }

  addNewTag(): void {
    const { modalRef, instance } = this.tagsService.openCreateEditTagModal();
    instance.isEdit = false;

    modalRef.result.then((tag: Tag) => {
        this.tagsService.createOrUpdateTag(tag, '', false)
          .pipe(
            tap((tagId) => {
              this.assignTag({ tag: new Tag({ ...tag, tagId }), assign: true });
            }),
            catchError((error) => {
              console.warn('[ManageTagsModalComponent] createOrUpdateTag: ', error);
              return of([]);
            }),
          )
          .subscribe();
      },
      () => {
      });
  }

  private updateColDefs(action: TagActionEnum): void {
    this.colDefConfigSignal.set([
      this.entityNameColDef,
      ...this.colDefsDictionary[action],
    ]);
  }

  private setDisabledState(disabled: boolean) {
    setControlDisabled(this.form, disabled);
    this.isLoading = disabled;
  }

  removeColDefs: ColDef[] = [
    {
      headerName: 'Current',
      field: 'tags',
      flex: 2,
      filter: 'agSetColumnFilter',
      autoHeight: true,
      cellRendererParams: { wrappable: true, useValueForTags: true },
      cellRenderer: AgEntityTagsComponent,
    },
    {
      headerName: 'Change To',
      field: 'tags',
      flex: 2,
      filter: 'agSetColumnFilter',
      autoHeight: true,
      cellRendererParams: { wrappable: true, useValueForTags: true },
      cellRenderer: AgEntityTagsComponent,
      valueGetter: (params: ValueGetterParams<ITagsEntity>) => excludeArray('tagId', (params.data?.tags || []), this.actionTags),
    },
  ];

  overrideColDefs: ColDef[] = [
    {
      headerName: 'Current',
      field: 'tags',
      flex: 2,
      filter: 'agSetColumnFilter',
      autoHeight: true,
      cellRendererParams: { wrappable: true, useValueForTags: true },
      cellRenderer: AgEntityTagsComponent,
    },
    {
      headerName: 'Change To',
      field: 'tags',
      flex: 2,
      filter: 'agSetColumnFilter',
      autoHeight: true,
      cellRendererParams: { wrappable: true, useValueForTags: true },
      cellRenderer: AgEntityTagsComponent,
      valueGetter: () => this.actionTags
    },
  ];

  addColDefs: ColDef[] = [
    {
      headerName: 'Current',
      field: 'tags',
      flex: 2,
      filter: 'agSetColumnFilter',
      autoHeight: true,
      cellRendererParams: { wrappable: true, useValueForTags: true },
      cellRenderer: AgEntityTagsComponent,
    },
    {
      headerName: 'Change To',
      field: 'tags',
      flex: 2,
      filter: 'agSetColumnFilter',
      autoHeight: true,
      cellRendererParams: { wrappable: true, useValueForTags: true },
      cellRenderer: AgEntityTagsComponent,
      valueGetter: (params: ValueGetterParams<ITagsEntity>) => filterUniqueElements<Tag>('tagId', [...(params.data?.tags || []), ...this.actionTags]),
    },
  ];

  readonly colDefsDictionary: Record<TagActionEnum, ColDef[]> = {
    [TagActionEnum.Unassign]: this.removeColDefs,
    [TagActionEnum.Override]: this.overrideColDefs,
    [TagActionEnum.Assign]: this.addColDefs,
  };

  get entityNameColDef(): ColDef {
    return {
      headerName: this.entityName,
      editable: false,
      flex: 1,
      minWidth: 240,
      sort: 'asc',
      cellRenderer: AgTagEntityTitleComponent,
      cellRendererParams: {
        openCompanyProfile: () => {
        },
        entityType: this.entityType
      },
    };
  }

  get entityType(): EntityType {
    return getEntityType(this.entities[0]) || EntityType.Company;
  }

  get entityName(): string {
    switch (this.entityType) {
      case EntityType.Company:
        return 'Company Name & ACN';
      case EntityType.Trust:
        return 'Trust Name & ABN';
      default:
        return 'Name';
    }
  }
}
