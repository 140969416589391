import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DatepickerHelper } from '../../../custom-form-validators/date-picker-form-validators';
import { Company } from '../../../models/company';
import { Document } from '../../../models/document';
import { EntityChangeData } from '../../../models/entityChangeData';
import { CompanyChangeData } from '../../../models/enums/companyChangeData';
import { SelectOption } from '../../../models/selectOptions';
import { ChangeDictionaryHelper } from '../../../models/shared/change-dictionary-helper.model';
import { Form388Component } from './form388.component';

export enum CopyFinancialStatementsStepsEnum {
  FormDescription = 0,
  ReasonsOfLodgement = 1,
  DetailsOfLargeProprietaryCompany = 2,
  ReportOfAuditorOrReviewer = 3,
}

export class CopyFinancialStatementsAndReports extends EntityChangeData {
  static override readonly $type = 'CopyFinancialStatementsAndReports';
  lodgementReason: LodgementReasons;
  financialYearBegins: Date;
  financialYearEnds: Date;
  isCompanyLarge: boolean;
  consolidatedRevenueIfLarge:number;
  consolidatedGrossAssetsValueIfLarge:number;
  employeesQuantityIfLarge:number;
  membersQuantityIfLarge:number;
  financialStatementsAuditedOrReviewed: FinancialStatement;
  isThereExemptionCurrent: boolean;
  isReviewerRegistered: boolean;
  isOpinionConclusionReportModified: boolean;
  reportContainEmphasis: boolean;

  constructor(data: Partial<CopyFinancialStatementsAndReports> = {}) {
    super(data);

    // TODO: Add ChangeType
    this.lodgementReason = data.lodgementReason ?? LodgementReasons.PC;
    this.financialYearBegins = data.financialYearBegins ?? new Date();
    this.financialYearEnds = data.financialYearEnds ?? new Date();
    this.isCompanyLarge = data.isCompanyLarge ?? false;
    this.consolidatedRevenueIfLarge = data.consolidatedRevenueIfLarge ?? 0;
    this.consolidatedGrossAssetsValueIfLarge = data.consolidatedGrossAssetsValueIfLarge ?? 0;
    this.employeesQuantityIfLarge = data.employeesQuantityIfLarge ?? 0;
    this.membersQuantityIfLarge = data.membersQuantityIfLarge ?? 0;
    this.financialStatementsAuditedOrReviewed = data.financialStatementsAuditedOrReviewed ?? FinancialStatement.NO;
    this.isThereExemptionCurrent = data.isThereExemptionCurrent ?? false;
    this.isReviewerRegistered = data.isReviewerRegistered ?? false;
    this.isOpinionConclusionReportModified = data.isOpinionConclusionReportModified ?? false;
    this.reportContainEmphasis = data.reportContainEmphasis ?? false;
  }

  toDictionary(): { key: string; value: string; }[] {
    const dict = new ChangeDictionaryHelper();

    dict
      .add('Reason for lodgement of statement and reports', lodgementReasonsDictionary[this.lodgementReason] ?? '')
      .addDate('Financial year begins', this.financialYearBegins)
      .addDate('Financial year ends', this.financialYearEnds)
      .addYesNo('Is this a large proprietary company?', this.isCompanyLarge);

    if (this.isCompanyLarge) {
      dict
        .add('What is the consolidated revenue of the large proprietary company and entities that it controls?', this.consolidatedRevenueIfLarge)
        .add('What is the value of the consolidated gross assets of the large proprietary company and the entities that it controls?', this.consolidatedGrossAssetsValueIfLarge)
        .add('How many employees are employed by the large proprietary company and the entities that it controls?', this.employeesQuantityIfLarge)
        .add('How many members does the large proprietary company have?', this.membersQuantityIfLarge)
        .add('Were the financial statements audited or reviewed?', FinancialStatement[this.financialStatementsAuditedOrReviewed]);

      if (this.financialStatementsAuditedOrReviewed === FinancialStatement.REV) {
        dict
          .addYesNo('Is the reviewer a registered company auditor, or member of The Institute of Chartered Accountants in Australia, CPA Australia Ltd, or Institute of Public Accountants and holds a practising certificate issued by one of those bodies?', this.isReviewerRegistered)
          .addYesNo('Is the opinion/conclusion in the report modified?', this.isOpinionConclusionReportModified)
          .addYesNo('Does the report contain an Emphasis of Matter and/or Other Matter paragraph?', this.reportContainEmphasis);
      } else if (this.financialStatementsAuditedOrReviewed === FinancialStatement.AUD) {
        dict
          .addYesNo('Is the opinion/conclusion in the report modified?', this.isOpinionConclusionReportModified)
          .addYesNo('Does the report contain an Emphasis of Matter and/or Other Matter paragraph?', this.reportContainEmphasis)
          .addYesNo('Is there a class or other order exemption current?', this.isThereExemptionCurrent);
      }
    }

    return dict.dictionary;
  }

  fillModal(modalRef: NgbModalRef, document: Document, actualCompany: Company): NgbModalRef {
    modalRef = this.fillModalWithCompany(modalRef, actualCompany, true);
    (modalRef.componentInstance as Form388Component).companyChangeData.documentId = document.documentId;
    return modalRef;
  }

  override fillModalWithCompany(modalRef: NgbModalRef, company: Company, isEdit: boolean): NgbModalRef {
    const instance = modalRef.componentInstance as Form388Component;

    instance.isEdit = isEdit;
    instance.formModel = structuredClone(this);
    instance.companyChangeData = new CompanyChangeData(company);

    return modalRef;
  }

  override prepareToRequest(): CopyFinancialStatementsAndReports {
    return {
      ...this,
      ...super.prepareToRequest(),
      financialYearBegins: DatepickerHelper.buildDateString(this.financialYearBegins),
      financialYearEnds: DatepickerHelper.buildDateString(this.financialYearEnds),
    };
  }
}

export enum LodgementReasons {
  PC,             // 'A public company or a disclosing entity which is not a registered scheme or prescribed interest undertaking'
  TI,             // 'A Tier 2 public company limited by guarantee'
  REG_SCM,        // 'A registered scheme'
  AMND_COMP,      // 'Amendment of financial statements or director\'s report (company)'
  AMND_SCM,       // 'Amendment of financial statements or director\'s report (registered scheme)'
  LRG_COMP,       // 'A large proprietary company that is not a disclosing entity'
  SML_COMP,       // 'A small proprietary company that is controlled by a foreign company'
  SML_COMP_CNRL,  // 'A small proprietary company, or a small company limited by guarantee that is requested by ASIC to prepare and lodge statements and reports'
  PRD_INT,        // 'A prescribed interest undertaking that is a disclosing entity'
}

export const lodgementReasonsDictionary: Record<LodgementReasons, string> = {
  [LodgementReasons.PC]: 'A public company or a disclosing entity which is not a registered scheme or prescribed interest undertaking',
  [LodgementReasons.TI]: 'A Tier 2 public company limited by guarantee',
  [LodgementReasons.REG_SCM]: 'A registered scheme',
  [LodgementReasons.AMND_COMP]: 'Amendment of financial statements or director\'s report (company)',
  [LodgementReasons.AMND_SCM]: 'Amendment of financial statements or director\'s report (registered scheme)',
  [LodgementReasons.LRG_COMP]: 'A large proprietary company that is not a disclosing entity',
  [LodgementReasons.SML_COMP]: 'A small proprietary company that is controlled by a foreign company',
  [LodgementReasons.SML_COMP_CNRL]: 'A small proprietary company, or a small company limited by guarantee that is requested by ASIC to prepare and lodge statements and reports',
  [LodgementReasons.PRD_INT]: 'A prescribed interest undertaking that is a disclosing entity'
};
export const lodgementReasonsOptions: SelectOption[] = Object.entries(lodgementReasonsDictionary)
  .map(([value, label]) => ({ label, value: +value }));

export enum FinancialStatement {
  NO = 0,
  REV = 1,
  AUD = 2
}

export const financialStatementOptions: SelectOption[] = [
  {
    value: FinancialStatement.NO,
    label: 'No'
  },
  {
    value: FinancialStatement.REV,
    label: 'Reviewed'
  },
  {
    value: FinancialStatement.AUD,
    label: 'Audited'
  }
];
