import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DatepickerHelper } from '../../../custom-form-validators/date-picker-form-validators';
import { Document } from '../../../models/document';
import { CompanySubtype, CompanyType } from '../../../models/enums/companyEnums';
import { SelectOption } from '../../../models/selectOptions';
import { DatePickerComponent } from '../../components/common/date-picker/date-picker.component';
import { SelectComponent } from '../../components/common/select/select.component';
import { BaseStepperFormComponent } from '../stepper-form/base-stepper-component/base-stepper-form.component';
import {
  StepperFormDescriptionComponent
} from '../stepper-form/stepper-form-description/stepper-form-description.component';
import { StepperFormComponent } from '../stepper-form/stepper-form.component';
import {
  ChangeCompanyType,
  ChangeCompanyTypeStepsEnum,
  companySubtypeDictionaryOptions,
  companyTypeOptions
} from './form206.model';

// Application for change of company type
@Component({
  selector: 'app-form206',
  standalone: true,
  imports: [
    StepperFormComponent,
    StepperFormDescriptionComponent,
    ReactiveFormsModule,
    SelectComponent,
    DatePickerComponent
  ],
  templateUrl: './form206.component.html',
  styleUrl: `../stepper-form/base-stepper-component/base-stepper-form.component.scss`
})
export class Form206Component extends BaseStepperFormComponent<ChangeCompanyTypeStepsEnum, ChangeCompanyType> implements OnInit {
  readonly companyTypeOptions = companyTypeOptions;
  override readonly StepsEnum = ChangeCompanyTypeStepsEnum;

  minDate!: NgbDateStruct;
  maxDate!: NgbDateStruct;

  form = new FormGroup({
    changeDate: new FormControl<Date | null>(null),
    companyType: new FormControl<CompanyType | null>(null, Validators.required),
    companySubtype: new FormControl<CompanySubtype | null>(null, Validators.required),
  });

  override stepperForm = new FormGroup({
    [ChangeCompanyTypeStepsEnum.FormDescription]: new FormGroup({}),
    [ChangeCompanyTypeStepsEnum.ChangeOfType]: this.form,
  });

  constructor() {
    super();
    this.setupSteps(ChangeCompanyTypeStepsEnum);
    this.redirectAfterSubmit = true;
  }

  ngOnInit(): void {
    this.minDate = DatepickerHelper.getDateOfEstablishmentMinDate(this.companyChangeData.dateOfEstablishment);
    this.maxDate = DatepickerHelper.getNextNYearsStruct(10);

    this.form.controls.changeDate.setValue(DatepickerHelper.getToday());
    this.setupChange();
  }

  override setupChange(): void {
    if (!this.isEdit) {
      return;
    }

    this.form.patchValue(this.formModel);
  }

  override buildDocument(): Document | null {
    const change = new ChangeCompanyType({
      ...this.form.value as Partial<ChangeCompanyType>,
    });

    try {
      return new Document({
        changes: [change],
        entityId: this.companyChangeData?.entityId,
        type: 'c:206',
        documentId: this.companyChangeData?.documentId,
      });
    } catch (error) {
      console.warn(error);
      this.toastr.error('Failed to create Document.', 'Error');
      return null;
    }
  }

  get companySubtypeOptions(): SelectOption[] {
    return this.form.value.companyType ? companySubtypeDictionaryOptions[this.form.value.companyType] : [];
  }
}
