import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceStr',
  standalone: true
})
export class ReplaceStrPipe implements PipeTransform {
  transform(value: string | null, search: string | null, replacement: string): string {
    if (!value || !search || !replacement) {
      return value ?? '';
    }

    return value.split(search).join(replacement);
  }
}
