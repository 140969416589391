import { Injectable, signal } from "@angular/core";

@Injectable({providedIn: 'root'})
export class PageSizeService {
  #pageSize = signal<number>(20);

  addPageSize(value: number): void {
    this.#pageSize.set(value);
  }

  get getPageSize(): number {
    return this.#pageSize();
  }
}
