import { Component } from '@angular/core';
import { ICellRendererParams } from "ag-grid-community";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { Company } from "../../../../../../models/company";
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from "@ng-bootstrap/ng-bootstrap";
import { AgActionIconButtonComponent } from '../ag-action-icon-button/ag-action-icon-button.component';

type ActionEvent = (data: unknown) => void;

type CellRendererParams = ICellRendererParams<Company> & {
  openCompanyProfile: ActionEvent,
  appointAccountManager: ActionEvent,
  exportCompanyProfile: ActionEvent,
  managePins: ActionEvent,
  openAlerts: ActionEvent,
  onOpenNotes: ActionEvent,
};

@Component({
  selector: 'app-ag-company-actions',
  standalone: true,
  imports: [
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
    AgActionIconButtonComponent
  ],
  templateUrl: './ag-company-actions.component.html',
  styleUrl: './ag-company-actions.component.scss'
})
export class AgCompanyActionsComponent implements ICellRendererAngularComp {
  protected params!: CellRendererParams;
  protected numberOfAlerts = 0;

  agInit(params: CellRendererParams): void {
    this.params = params;
    this.numberOfAlerts = this.params.data?.alerts?.length ?? 0;
  }

  refresh(params: CellRendererParams): boolean {
    this.params = params;
    return true;
  }

  onOpenCompanyProfile(): void {
    this.params.openCompanyProfile(this.params.data);
  }

  onAppointAccountManager(): void {
    this.params.appointAccountManager(this.params.data);
  }

  onExportCompanyProfile(): void {
    this.params.node.setSelected(true);
    this.params.exportCompanyProfile(this.params.data);
  }

  onManagePins(): void {
    // this.params.managePins(this.params.data);
  }

  onOpenAlerts(): void {
    this.params.openAlerts(this.params.data);
  }

  onOpenNotes(): void {
    this.params.onOpenNotes(this.params.data);
  }
}
