<div class="relationship-form-group" [formGroup]="form">
  <div class="form-group">
    <app-input
      formControlName="name"
      [label]="nameControlLabel"
      [customErrors]="directorFullNameValidatorMsg"
    ></app-input>
  </div>

  <div class="form-group">
    <app-address-form-group
      [label]="addressControlLabel"
      [formGroup]="form.controls.address"
      [hidden]="hideAddress"
    ></app-address-form-group>
  </div>
</div>
