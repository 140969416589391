@if(!loading) {
  @if (isSomeFilterTag) {
    <div class="grid-search-filter-container">
      @for (column of colDefs; track column.headerName; let columnIndex = $index) {
        @if (column.filterTagShowed) {
          <div class="grid-search-filter" ngbDropdown #dropdown="ngbDropdown" (click)="column.filterTagSelected = !!(dropdown.openChange)">
            <div
              class="filter-tag"
              ngbDropdownToggle
              placement="bottom"
              [ngbTooltip]="dropdown.isOpen() ? '' : isActiveOption(column.filterParams.filterOptions) ? tooltipContent : ''"
            >
              <ng-template #tooltipContent>
                @for (option of column.filterParams.filterOptions; track option.label) {
                  @if (option.active) {
                    <div class="popup-item">{{option.label}}</div>
                  }
                }
              </ng-template>

              <div class="column-name">{{ column?.headerName }}</div>
              <i class="fa fa-sm fa-chevron-right"></i>
              <div class="filter-name">
                @if(column?.cellDataType === CellDataType.DATE) {
                  {{ (column.filterParams.dateRangeSelected?.from | date) + ' to ' + (column.filterParams.dateRangeSelected?.to | date) }}
                } @else {
                  {{ showTagInfo(column.filterParams.filterOptions) }}
                }
              </div>
              <i class="icon ic-tiny ic-close" (click)="closeFilterTag(columnIndex)"></i>
            </div>

            <div
              ngbDropdownMenu
              aria-labelledby="dropdownBasic1"
              [ngClass]="{'date-container': (column?.cellDataType === CellDataType.DATE)}"
            >
              <app-search-filter-list
                [column]="column"
                (dateRangeSelected)="onServerDateRangeSelected(columnIndex, $event)"
                (filterOptionChange)="onServerFilterOptionChange(columnIndex, $event)"
                (searchByOption)="onSearchByOption(columnIndex, $event)"
              ></app-search-filter-list>
            </div>
          </div>
        }
      }

      @if(numberOfNotShowedTags) {
        <div class="grid-search-filter" ngbDropdown #dropdown="ngbDropdown">
          <app-button
            ngbDropdownToggle
            [btnColor]="(dropdown.openChange | async) ? 'gray' : 'white'"
            [iconClass]="'icon ic-plus'"
            (clickBtn)="resetSelectedColumn()"
          ></app-button>

          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" [ngClass]="{'date-container': isDateColumn}">
            @if(selectedColIndex === -1) {
              @for (column of colDefs; track column.field) {
                @if (!column.filterTagShowed && column?.headerName && (column?.filter === 'agSetColumnFilter' || column?.filter === 'agDateColumnFilter')) {
                  <div class="column-option d-flex" (click)="onSelectColumn($index)">
                    <div class="filter-option-name">{{ column.headerName }}</div>
                  </div>
                }
              }
            } @else {
              <app-search-filter-list
                [column]="colDefs[selectedColIndex]"
                (dateRangeSelected)="onServerDateRangeSelected(selectedColIndex, $event)"
                (filterOptionChange)="onServerFilterOptionChange(selectedColIndex, $event)"
                (searchByOption)="onSearchByOption(selectedColIndex, $event)"
              ></app-search-filter-list>
            }
          </div>
        </div>
      }
    </div>
  } @else {
    <div class="grid-search-filter-container">
      <div class="grid-search-filter dropdown-selector" ngbDropdown #startBtnDropdown="ngbDropdown">
        <app-button
          ngbDropdownToggle
          [btnColor]="(startBtnDropdown.openChange | async) ? 'gray' : 'white'"
          [text]="'Filter'"
          [iconClass]="'icon ic-filter'"
          (clickBtn)="onFilterClick()"
        ></app-button>

        <div id="start-button" ngbDropdownMenu aria-labelledby="dropdownBasic1" [ngClass]="{'date-container': isDateColumn}">
          @if(selectedColIndex === -1) {
            @for (column of colDefs; track column.field) {
              @if (column?.headerName && column?.headerName && (column?.filter === 'agSetColumnFilter' || column?.filter === 'agDateColumnFilter' || column?.filter === 'agTextColumnFilter')) {
                <div class="column-option d-flex" (click)="onSelectColumn($index)">
                  <div class="filter-option-name">{{ column.headerName }}</div>
                </div>
              }
            }
          } @else {
            <app-search-filter-list
              [column]="colDefs[selectedColIndex]"
              (dateRangeSelected)="onServerDateRangeSelected(selectedColIndex, $event)"
              (filterOptionChange)="onServerFilterOptionChange(selectedColIndex, $event)"
              (filterSearch)="onServerFilterSearch(selectedColIndex, $event)"
              (searchByOption)="onSearchByOption(selectedColIndex, $event)"
            ></app-search-filter-list>
          }
        </div>
      </div>

      @if (selectedColIndex > -1 && isSomeActiveFilterOptions(selectedColIndex)) {
        <div class="grid-search-filter">
          <app-button
            [btnColor]="'white'"
            [iconClass]="'icon ic-plus'"
            (clickBtn)="addFilterToTag(selectedColIndex)"
          ></app-button>
        </div>
      }
    </div>
  }
} @else {
  <div class="grid-search-filter">
    <app-button
      [btnColor]="'white'"
      [text]="'Filter'"
      [iconClass]="'icon ic-filter'"
      [isDisabled]="loading"
    ></app-button>
  </div>
}
