@if (isAuthenticated) {
  @if(isGenerateDocumentsRoute$ | async) {
    <app-nav-menu></app-nav-menu>
    <div class="content-container document-page" [ngClass]="collapsedMenu() ? 'collapsed' : 'expanded'">
      <div class="content-document-wrapper">
        <router-outlet></router-outlet>
      </div>
    </div>
  } @else {
    <app-nav-menu></app-nav-menu>
    <app-header></app-header>
    <div class="content-container main-page" [ngClass]="collapsedMenu() ? 'collapsed' : 'expanded'">
      <div class="content-wrapper">
        <router-outlet></router-outlet>
      </div>
    </div>
  }
}

<div *ngIf="!isAuthenticated" class="d-flex justify-content-center align-items-center mh-400">
  <i class="fad fa-spinner-third fa-spin"></i>
</div>
