import { Component } from '@angular/core';
import { ICellRendererParams } from "ag-grid-community";
import { BulkChangeData, BulkMatches } from "../../../../../../models/bulkChangeData";
import { CheckboxComponent } from "../../../checkbox/checkbox.component";

type ActionEvent = (data: unknown) => void;

type CellRendererParams = ICellRendererParams<BulkChangeData>& {
  matchesSelected: ActionEvent,
}

@Component({
  selector: 'app-ag-bulk-matches',
  standalone: true,
  imports: [
    CheckboxComponent
  ],
  templateUrl: './ag-bulk-matches.component.html',
  styleUrl: './ag-bulk-matches.component.scss'
})
export class AgBulkMatchesComponent {
  protected params!: CellRendererParams;
  protected dataMatches: BulkMatches[] = [];

  agInit(params: CellRendererParams): void {
    this.params = params;
    this.dataMatches = this.params.data?.matches ?? [];
    this.setRowHeight();
  }

  refresh(params: CellRendererParams): boolean {
    this.params = params;
    return true;
  }

  setRowHeight(): void {
    const rowWithoutTypeHeight = 42;
    const rowWithTypeHeight = 52;
    const rowWithType = this.dataMatches.filter(item => item?.type)?.length;
    const rowWithoutType = this.dataMatches.length - rowWithType;
    const rowHeight = (rowWithoutType * rowWithoutTypeHeight) + (rowWithType * rowWithTypeHeight);
    this.params.node.setRowHeight(rowHeight);
    this.params.api.onRowHeightChanged();
  }

  selectItem(value: boolean, index: number): void {
    this.dataMatches[index].value = value;
    this.params.node.setSelected(this.dataMatches.some(item => item.value));
    this.params.matchesSelected(this.params.data?.matches);
  }
}
