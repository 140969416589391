<div class="input-container">
  <div class="header">
    @if (label) {
      <div class="label">{{ label }}</div>
    }

    @if (link && text) {
      <div>
        <app-link [link]="link" [text]="text"/>
      </div>
    } @else {
      <app-form-switch-button
        [text]="switchBtnText"
        (switchForm)="onClickBtn()"
      />
    }
  </div>

  <div
    class="input-wrapper"
    [ngClass]="{'error': validationError || displayValidationError, 'check': checkedState, 'warn': warningState}"
  >
    <input
      type="text"
      class="input"
      [placeholder]="placeholder"
      [disabled]="disabled"
      [(ngModel)]="value"
      (input)="onInput($event)"
      (keydown)="onKeyPressed($event)"
      (focusin)="focusin.emit($event)"
      (blur)="onTouched()"
    >

    <app-tooltip [customContent]="true">
      <i
        container="body"
        class="input-icon icon"
        placement="top"
        [ngbTooltip]="this.getValidationErrorMsg()"
        [tooltipClass]="'tooltip-info'"
        [ngClass]="{'ic-circle-info': validationError || warningState || displayValidationError, 'ic-circle-check': checkedState}">
      </i>
    </app-tooltip>
  </div>

  @if (helperText) {
    <div class="helper" [ngClass]="{'error': validationError}">{{ helperText }}</div>
  }

  @if (shouldShowErrors && displayValidationError) {
    <app-validation-error [customErrors]="customErrors"></app-validation-error>
  }
</div>
