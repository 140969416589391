import { ChangeDetectorRef, Component, inject, Input, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Address } from '../../../../../models/address';
import { IndividualData } from '../../../../../models/individualData';
import { IndividualHolderModel } from '../../../../../models/securityRegistryRecord';
import { Guid } from '../../../../helpers/guid.helper';
import { CommonModalFormComponent } from '../../../common-modal-form/common-modal-form.component';
import {
  IndividualDataFormGroupComponent, IndividualDataFormGroupControls,
} from "../../../../components/reusable-form-groups/individual-data-form-group/individual-data-form-group.component";
import { setControlDisabled } from "../../../../../functions/set-control-disabled";
import { parseFullName } from "../../../../../functions/parse-fullname";

@Component({
  selector: 'app-add-individual-security-holder-modal',
  standalone: true,
  imports: [
    CommonModalFormComponent,
    ReactiveFormsModule,
    IndividualDataFormGroupComponent
  ],
  templateUrl: './add-individual-security-holder-modal.component.html',
  styleUrl: './add-individual-security-holder-modal.component.scss'
})
export class AddIndividualSecurityHolderModalComponent implements OnInit {
  activeModal = inject(NgbActiveModal);
  cdr = inject(ChangeDetectorRef);

  @Input() individualHolderModel: IndividualHolderModel | null = null;
  @Input() suggestedAddresses: Address[] = [];
  @Input() fullNameSuggestions: string[] = [];

  readonly individualDataFormLabels: Record<keyof Partial<IndividualDataFormGroupControls>, string> = { fullName: 'Individual Name' } as Record<keyof Partial<IndividualDataFormGroupControls>, string>;
  readonly hiddenIndividualControls: (keyof IndividualDataFormGroupControls)[] = ['formerName', 'dob', 'birthCity', 'birthCountry'];

  header = '';

  form = new FormGroup({
    newIndividualShareholder: IndividualDataFormGroupComponent.defineForm()
  });

  ngOnInit(): void {
    this.activeModal.update({ modalDialogClass: 'common-modal-form-dialog overflow-y-auto' });
    this.cdr.detectChanges();

    this.setHeader();
    if (this.individualHolderModel) {
      this.form.controls.newIndividualShareholder.patchValue({
        fullName: this.individualHolderModel.individualData.fullName,
        address: this.individualHolderModel.individualData.address
      });
    } else {
      this.individualHolderModel = new IndividualHolderModel({
        individualId: Guid.generate(),
        relationshipId: Guid.generate(),
        individualData: new IndividualData({
          address: new Address({ country: 'AU' })
        })
      });
    }

    this.hiddenIndividualControls.forEach((key) => {
      setControlDisabled(this.form.controls.newIndividualShareholder.controls[key]);
    });
  }

  save(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const individualData = IndividualDataFormGroupComponent.toIndividualData(
      this.form.controls.newIndividualShareholder as FormGroup<Partial<IndividualDataFormGroupControls>>,
      this.individualHolderModel?.individualData ?? {}
    );
    const individualHolderModel = new IndividualHolderModel({
      individualData,
      ...parseFullName(individualData.fullName ?? ''),
      relationshipId: this.individualHolderModel?.relationshipId || Guid.generate(),
    });

    this.activeModal.close(individualHolderModel);
  }

  cancel(): void {
    this.activeModal.dismiss();
  }

  get newIndividualShareholder() {
    return this.form.controls.newIndividualShareholder;
  }

  private setHeader(): void {
    this.individualHolderModel ? this.header = 'Edit Individual' : this.header = 'New Individual';
  }
}
