import { Component, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { bsbValidator } from '../../../../../../validators/bsb.validators';
import { MaskedInputComponent } from '../../masked-input.component';

@Component({
  selector: 'app-bsb-masked-input',
  standalone: true,
  imports: [
    MaskedInputComponent
  ],
  templateUrl: './bsb-masked-input.component.html',
})
export class BsbMaskedInputComponent extends MaskedInputComponent implements OnInit {
  @Input() override label = 'BSB number';
  @Input() override placeholder = '___-___';
  @Input() override customErrors = {
    required: 'BSB is required.',
    bsbInvalidFormat: 'Invalid BSB.',
  };

  ngOnInit(): void {
    this.ngControl.control?.setValidators([
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(6),
      bsbValidator
    ]);
  }
}
