import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { GoogleMapsService } from '../services/google-maps.service';
import { MenuService } from "../services/menu.service";
import { ModalFormsService } from "../services/modal-forms.service";
import { SignalRService } from '../services/signalr.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [GoogleMapsService]
})
export class AppComponent {
  title = 'bolt';
  collapsedMenu = this.menuService.collapsedMenu;
  isGenerateDocumentsRoute$: Observable<boolean> = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    map(() => this.router.url.includes('/document/'))
  );

  constructor(
    private authService: AuthService,
    private menuService: MenuService,
    private router: Router,
    private modalFormsService: ModalFormsService,
    private googleMapsService: GoogleMapsService,
    private signalRService: SignalRService
  ) {
    void this.googleMapsService.loadApi();
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => this.modalFormsService.closeAllFormsModals());

    // if (this.authService.isAuthenticated) {
    //   this.signalRService.startConnection().subscribe(() => {
    //     this.signalRService.receive().subscribe((message) => {
    //       console.log(message);
    //     });
    //   });
    // }
  }

  public get isAuthenticated() {
    return this.authService.isAuthenticated;
  }

  public get isOrgSetupRequired() {
    return this.authService.profile.organisationSetupRequired;
  }
}
