import { Component, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { ModalFormsService } from "../../../services/modal-forms.service";
import { ToastrService } from "ngx-toastr";
import { convertEnumToFilterOption } from "../../helpers/enum-to-filter-option.helper";
import {
  CellClickedEvent,
  ColDef,
  CsvExportParams,
  ExcelExportParams,
  ExcelStyle,
  GetRowIdFunc,
  GetRowIdParams,
  GridApi,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  ProcessCellForExportParams,
  ProcessHeaderForExportParams,
  ValueFormatterParams
} from 'ag-grid-community';
import { ExportTypeEnum } from '../../../models/enums/exportTypeEnum';
import { GridOptions, IServerSideGetRowsRequest } from "ag-grid-enterprise";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ExportCompanyListComponent } from "../../modals/export-company-list/export-company-list.component";
import { dateTimeComparator } from "../../components/common/grid/functions/date-time-comparator";
import { CellDataType } from "../../../models/enums/agGridEnums";
import { formatDate } from "@angular/common";
import { FilterOption } from "../../../models/gridFilter";
import { ColumnWithExportName } from "../../../models/columnWithExportName";
import { ButtonComponent } from "../../components/common/button/button.component";
import { GridComponent } from "../../components/common/grid/components/grid/grid.component";
import { PageTitleComponent } from "../../components/common/page-title/page-title.component";
import { TextMessageTemplatesService } from "../../../services/sms-templates.service";
import { TextMessageRecord } from "../../../models/textMessageRecord";
import { TextMessageStatus } from "../../../models/enums/textMessageStatus";
import {
  AgOutgoingTextMessageStatusComponent
} from "../components/ag-outgoing-text-message-status/ag-outgoing-text-message-status.component";
import {
  OutgoingTextMessageDetailsComponent
} from "../components/outgoing-text-message-details/outgoing-text-message-details.component";
import {
  AgOutgoingMessagesActionsComponent
} from "../components/ag-ougoing-emails-actions/ag-outgoing-messages-actions.component";
import { finalize } from "rxjs";

@Component({
  selector: 'app-outgoing-sms-messages',
  standalone: true,
  imports: [
    ButtonComponent,
    GridComponent,
    PageTitleComponent,
    OutgoingTextMessageDetailsComponent
  ],
  templateUrl: './outgoing-sms-messages.component.html',
  providers: [TextMessageTemplatesService],
  styleUrl: '../outgoing-email-messages/outgoing-email-messages.component.scss'
})
export class OutgoingSmsMessagesComponent implements OnInit {
  private modalFormsService = inject(ModalFormsService);
  private textMessagesTemplatesService = inject(TextMessageTemplatesService);
  private toastr = inject(ToastrService);
  private destroyRef = inject(DestroyRef);

  readonly defaultPageSize = 20;
  readonly cacheBlockSize = 20;
  readonly excelExportParams = this.exportParams() as ExcelExportParams;
  readonly excelStyles = this.getExcelStyles();
  readonly serverRowModelType: 'serverSide' | 'infinite' | 'clientSide' = 'serverSide';
  readonly textMessagesStatusFilterOptions = convertEnumToFilterOption(TextMessageStatus);
  readonly ExportTypeEnum = ExportTypeEnum;

  gridApi!: GridApi;
  colDefs: ColDef[] = [];
  totalRows = 0;
  currentTextMessageRecordIndex = -1;
  currentTextMessageItemNumber = 0;
  gridOptions: GridOptions = {
    overlayNoRowsTemplate: '<span class="no-rows-overlay">No data available</span>',
    rowModelType: 'serverSide',
    pagination: true,
    paginationPageSize: 20,
    cacheBlockSize: 20,
    maxBlocksInCache: 1,
  };
  checkedTextMessageRecords: TextMessageRecord[] = [];
  dataSource: IServerSideDatasource = this.getDataSource('');
  textMessagesRows: TextMessageRecord[] = [];
  lastParamsRequest: IServerSideGetRowsRequest | null = null;
  isDetailsLoading = false;
  $selectedTextMessageRecord = signal<TextMessageRecord | null>(null);

  ngOnInit(): void {
    this.setGridConfig();
  }

  onGridReady(gridApi: GridApi): void {
    this.gridApi = gridApi;
  }

  selectTextMessageRecord(textMessageRecords: TextMessageRecord[]): void {
    this.checkedTextMessageRecords = textMessageRecords;
  }

  onSearch(searchText = ''): void {
    this.dataSource = this.getDataSource(searchText);
  }

  getDataSource(searchText = ''): IServerSideDatasource {
    return {
      getRows: (params: IServerSideGetRowsParams) => {
        const request = params.request;
        this.lastParamsRequest = params.request;
        this.textMessagesTemplatesService.getOutgoingTextMessagesList(request, searchText)
          .pipe(finalize(() => this.isDetailsLoading = false))
          .subscribe({
            next: result => {
              this.textMessagesRows = result.records;
              this.totalRows = result.total;
              params.success({
                rowData: result.records,
                rowCount: result.total,
              });

              if (this.currentTextMessageRecordIndex >= 0) {
                this.updateSelectedTextMessage();
              }
            },
            error: (err) => {
              this.toastr.error('Something happened while loading outgoing text messages', 'Error');
              console.error(err);
              params.fail();
            }
          });
      }
    };
  }

  getRowId: GetRowIdFunc = (params: GetRowIdParams<TextMessageRecord>) => params.data.textMessageId;

  openTextMessageRecordPreview(data: { record: TextMessageRecord, index: number }): void {
    this.currentTextMessageRecordIndex = data.index;
    this.updateSelectedTextMessage();
  }

  previousTextMessage(): void {
    if (this.currentTextMessageRecordIndex > 0) {
      const pageSize = this.gridApi.paginationGetPageSize();
      const currentPage = this.gridApi.paginationGetCurrentPage();
      const lastElementPrevPageIndex = pageSize * currentPage - 1;

      this.currentTextMessageRecordIndex--;
      if (this.currentTextMessageRecordIndex === lastElementPrevPageIndex) {
        this.gridApi.paginationGoToPreviousPage();
        this.isDetailsLoading = true;
      }
      this.updateSelectedTextMessage();
    }
  }

  nextTextMessage(): void {
    if (this.currentTextMessageRecordIndex < this.totalRows) {
      const pageSize = this.gridApi.paginationGetPageSize();
      const currentPage = this.gridApi.paginationGetCurrentPage();
      const firstElementNextPageIndex = (pageSize * currentPage + pageSize);

      this.currentTextMessageRecordIndex++;
      if (this.currentTextMessageRecordIndex === firstElementNextPageIndex) {
        this.gridApi.paginationGoToNextPage();
        this.isDetailsLoading = true;
      }
      this.updateSelectedTextMessage();
    }
  }

  reloadGrid(): void {
    this.gridApi.refreshServerSide();
  }

  exportSelectedTextMessagesToXls(): void {
    this.exportTextMessagesList(true, ExportTypeEnum.EXCEL);
  }

  exportTextMessagesList(isBulkExport: boolean, exportType: ExportTypeEnum): void {
    if (this.modalOpened()) return;
    const componentInstance = this.modalFormsService.openModal(ExportCompanyListComponent, { modalDialogClass: 'export-company-list' }).componentInstance as ExportCompanyListComponent;

    componentInstance.title = 'Export Outgoing Text Messages';
    componentInstance.subTitle = 'text messages selected';
    componentInstance.colDefs = this.colDefs;
    componentInstance.numberOfCompanies = isBulkExport ? this.checkedTextMessageRecords.length : this.totalRows;
    componentInstance.exportType = exportType;
    componentInstance.confirm.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((columnForExport: string[]) => {
      if (exportType === ExportTypeEnum.EXCEL) {
        const params: ExcelExportParams = isBulkExport ? {
          columnKeys: columnForExport,
          onlySelected: true
        } : { columnKeys: columnForExport };
        this.gridApi.exportDataAsExcel(params);
      } else if (exportType === ExportTypeEnum.CSV) {
        const params: CsvExportParams = isBulkExport ? {
          columnKeys: columnForExport,
          onlySelected: true
        } : { columnKeys: columnForExport };
        this.gridApi.exportDataAsCsv(params);
      }
    });
  }

  onCloseDetails(): void {
    this.currentTextMessageRecordIndex = -1;
    this.$selectedTextMessageRecord.set(null);
    this.reloadGrid();
  }

  private updateSelectedTextMessage(): void {
    const pageSize = this.gridApi.paginationGetPageSize();
    const currentPage = this.gridApi.paginationGetCurrentPage();

    this.currentTextMessageItemNumber = this.currentTextMessageRecordIndex + 1;

    let currentIndex = 0;
    if (this.currentTextMessageRecordIndex < pageSize) {
      currentIndex = this.currentTextMessageRecordIndex;
    } else {
      const countRows = (pageSize * currentPage);
      if (countRows) {
        currentIndex = this.currentTextMessageRecordIndex % (pageSize * currentPage);
      } else {
        currentIndex = 0;
      }
    }

    if (!this.isDetailsLoading)
      this.$selectedTextMessageRecord.set(this.textMessagesRows[currentIndex]);
  }

  private setGridConfig(): void {
    this.colDefs = [
      {
        headerName: 'Date',
        field: 'createdOn',
        sort: 'desc',
        width: 200,
        minWidth: 200,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateTimeComparator,
        },
        cellDataType: CellDataType.DATE,
        valueFormatter: (params: ValueFormatterParams<TextMessageRecord>) => {
          return formatDate(params.data?.createdOn ?? '', 'dd MMM yyyy HH:mm', 'en-US');
        },
        checkboxSelection: true,
        headerCheckboxSelection: true,
        menuTabs: ['generalMenuTab'],
        onCellClicked: this.cellClickHandler.bind(this),
      },
      {
        headerName: 'Header',
        field: 'code',
        valueFormatter: (params: ValueFormatterParams<TextMessageRecord>) => params.data?.header ?? '',
        onCellClicked: this.cellClickHandler.bind(this),
        cellClass: 'hover-underline'
      },
      {
        headerName: 'Recipients',
        field: 'to',
        flex: 2,
        menuTabs: ['generalMenuTab'],
        onCellClicked: this.cellClickHandler.bind(this),
        cellClass: 'hover-underline'
      },
      {
        headerName: 'Status',
        field: 'status',
        cellRenderer: AgOutgoingTextMessageStatusComponent,
        width: 105,
        minWidth: 105,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.textMessagesStatusFilterOptions,
          values: Object.values(TextMessageStatus).filter((v) => !isNaN(Number(v))),
        },
        menuTabs: ['generalMenuTab']
      },
      {
        headerName: 'Sender',
        field: 'from',
        flex: 1,
        hide: true,
        cellClass: 'hover-underline',
        onCellClicked: this.cellClickHandler.bind(this),
      },
      {
        width: 60,
        minWidth: 60,
        cellRenderer: AgOutgoingMessagesActionsComponent,
        cellRendererParams: {
          resend: this.resendTextMessage.bind(this),
          delete: this.deleteTextMessage.bind(this),
          checkShowResend: (data: TextMessageRecord | undefined) => data?.status === TextMessageStatus.Failed,
          checkShowDelete: (data: TextMessageRecord | undefined) => data?.status !== TextMessageStatus.Created,
          checkDisabled: (data: TextMessageRecord | undefined) => data?.status === TextMessageStatus.Created,
        }
      }
    ];
  }

  private cellClickHandler(params: CellClickedEvent<TextMessageRecord>) {
    this.openTextMessageRecordPreview({
      record: params.data as TextMessageRecord,
      index: params.rowIndex as number
    });
  }

  private resendTextMessage(textMessageRecord: TextMessageRecord): void {
    this.textMessagesTemplatesService.resendOutgoingTextMessage(textMessageRecord.textMessageId)
      .subscribe({
        next: () => this.reloadGrid(),
        error: () => this.toastr.error('Something happened while sending text', 'Error')
      });
  }

  private deleteTextMessage(textMessageRecord: TextMessageRecord): void {
    if (confirm('Deleting the text message will not cancel pending e-signing envelopes or recall sent text messages. To cancel an e-signing request, you must void the envelope. Do you want to continue?')) {
      this.textMessagesTemplatesService.deleteOutgoingTextMessage(textMessageRecord.textMessageId)
        .subscribe({
          next: () => this.reloadGrid(),
          error: () => this.toastr.error('Something went wrong with deleting!', 'Error'),
        });
    }
  }

  private exportParams() {
    return {
      processCellCallback(params: ProcessCellForExportParams): string {
        const value: unknown = params.value;
        const textMessageMessageData = params.node?.data as TextMessageRecord;

        if (params.column.getColId() === 'createdOn') {
          return formatDate(textMessageMessageData.createdOn ?? '', 'dd MMM yyyy HH:mm', 'en-US');
        } else if (params.column.getColId() === 'type' || params.column.getColId() === 'status' || params.column.getColId() === 'resolvedStatus') {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
          const options: FilterOption[] = params.column.getColDef()?.filterParams?.filterOptions ?? [];
          return options.find(o => o.value == value)?.label ?? '';
        }

        return value as string;
      },
      processHeaderCallback(params: ProcessHeaderForExportParams) {
        const colDef = params.column.getColDef() as ColumnWithExportName;
        return colDef?.exportColumnName ?? colDef.headerName;
      },
      sheetName: 'Outgoing Text Messages',
      fileName: 'Outgoing Text Messages ' + formatDate(new Date(), 'dd-MM-yyyy', 'en-US')
    };
  }

  private getExcelStyles(): ExcelStyle[] {
    return [
      {
        id: 'titleStyle',
        font: {
          size: 18,
          color: '#FFC82C',
          bold: true,
        },
        alignment: {
          horizontal: 'Center',
        },
      },
      {
        id: 'dateStyle',
        font: {
          size: 14,
          color: '#FFC82C',
        },
        alignment: {
          horizontal: 'Center',
        },
      },
      {
        id: "header",
        interior: {
          color: "#aaaaaa",
          pattern: "Solid",
        },
        font: {
          size: 11,
          bold: true,
        },
      },
    ];
  }

  get modalOpened() {
    return this.modalFormsService.modalOpened;
  }
}

