<div class="card card-profile ">
  <div class="profile">
    <div class="header">
      <i class="ic-trusts ic-md icon"></i>
      <h3>{{ trustProfile.name }}</h3>
    </div>

    <app-divider></app-divider>

    <div class="info">
      <div class="info-row">
        <div class="info-item">
          <div class="icon-container">
            <span class="figure">15</span>
            <i class="icon ic-md ic-form-pending"></i>
          </div>
          <div class="text">Pending Forms</div>
        </div>
        <div class="info-item">
          <div class="icon-container">
            <span class="figure">100%</span>
            <i class="icon ic-md ic-notes"></i>
          </div>
          <div class="text">Readines</div>
        </div>
      </div>
    </div>

    <app-divider></app-divider>

    <div class="company-action-list">
      <div class="company-row">
        <div class="left">
          <img src="assets/images/xpm-logo.svg" alt="img" class="img-max36">
          <div class="text-container">
            <div class="text">XPM</div>
            <div class="sub-text">Synced 15 minutes ago</div>
          </div>
        </div>

        <div class="right">
          <app-button [btnColor]="'white'" [iconClass]="'icon ic-md ic-refresh'" [isDisabled]="disableControls"></app-button>
        </div>
      </div>
    </div>

  </div>
</div>
