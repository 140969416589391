<div class="annual-container">
  <div class="annual-header">
    <app-clickable-stepper
      [shouldControlStepsUsingInput]="true"
      [currentStepIndex]="currentStepIndex"
      [steps]="steps"
      [disableSteps]="modalOpened()"
      (stepIndexChange)="setCurrentStep($event)"
    ></app-clickable-stepper>

    <div class="actions-container">
      <div class="btn-container">
        <app-button [text]="'Close'" [btnColor]="'white'" (clickBtn)="close()"></app-button>
        <app-divider [vertical]="true"></app-divider>
        @if(currentStepIndex === AnnualStep.SignDocuments && !showVoidEnvelope()) {
          <app-button
            [text]="'Send'"
            [iconClassLast]="'icon ic-arrow-right'"
            [loading]="loading || patchLoading() || sendSignLoading()"
            [isDisabled]="loading || patchLoading() || disabledHeaderBtn() || sendSignLoading()"
            (clickBtn)="confirmSentESignAction$.next(true)"
          ></app-button>
        } @else if(currentStepIndex !== AnnualStep.SignDocuments) {
          <app-button
            [text]="'Next Step'"
            [iconClassLast]="'icon ic-arrow-right'"
            [loading]="loading || patchLoading() || sendSignLoading()"
            [isDisabled]="loading || patchLoading() || disabledHeaderBtn() || sendSignLoading()"
            (clickBtn)="confirmChanges()"
          ></app-button>
        }
      </div>
    </div>
  </div>

  <div class="annual-content">
    <app-annual-statement-sidebar
      [loadCompany]="loading"
      [currentStepIndex]="currentStepIndex"
      [annualStatement]="annual"
      [entity]="actualCompany || actualTrustProfile"
      [entityType]="entityType"
    ></app-annual-statement-sidebar>

    <div class="step-content">
      @switch (currentStepIndex) {
        @case(AnnualStep.Authorization) {
          @if(!loading) {
            <app-annual-statement-authorization
              [annual]="annual"
              [company]="actualCompany"
              [partnerManagerOptions]="partnerManagerOptions"
              [confirmAction$]="confirmAction$"
              (nextStep)="setNextStep()"
            ></app-annual-statement-authorization>
          } @else {
            <div *ngTemplateOutlet="skeletonTemplate"></div>
          }
        } @case(AnnualStep.GenerateDocuments) {
          @if(!loading) {
            <app-annual-generate-documents
              [annual]="annual"
              [company]="actualCompany"
              [confirmAction$]="confirmAction$"
              (nextStep)="setNextStep()"
              (updateAnnualDocuments)="updateAnnual()"
            ></app-annual-generate-documents>
          } @else {
            <div *ngTemplateOutlet="skeletonTemplate"></div>
          }
        } @case(AnnualStep.SignDocuments) {
          @if(!loading) {
            <app-annual-sign-documents
              [documentId]="annual.documentId"
              [documentSigning]="annual.documentSigning"
              [changeAuthorisation]="annual.changeAuthorisation"
              [paymentDeadline]="annual.paymentDeadline"
              [reviewDate]="annual.reviewDate"
              [company]="annual.company"
              [confirmAction$]="confirmAction$"
              [confirmSentESignAction$]="confirmSentESignAction$"
              (nextStep)="setNextStep()"
            ></app-annual-sign-documents>
          } @else {
            <div *ngTemplateOutlet="skeletonTemplate"></div>
          }
        }
        @default {
          <p>Unexpected step index: {{ currentStepIndex }}</p>
        }
      }
    </div>
  </div>
</div>

<ng-template #skeletonTemplate>
  <div class="skeleton-container">
    <div class="mb-4">
      <ngx-skeleton-loader count="1" appearance="line" />
    </div>
    <ngx-skeleton-loader count="1" appearance="line" />
    <ngx-skeleton-loader count="2" appearance="line" />
    <div class="d-flex gap-24">
      <ngx-skeleton-loader style="width: 50%" count="1" appearance="line" />
      <ngx-skeleton-loader style="width: 50%" count="1" appearance="line" />
    </div>
    <ngx-skeleton-loader count="2" appearance="line" />
    <div class="d-flex gap-24">
      <ngx-skeleton-loader style="width: 50%" count="1" appearance="line" />
      <ngx-skeleton-loader style="width: 50%" count="1" appearance="line" />
    </div>
    <ngx-skeleton-loader count="6" appearance="line" />
  </div>
</ng-template>
