import { Component } from '@angular/core';
import { ICellRendererParams } from "ag-grid-community";
import { KeyValuePipe, NgClass } from "@angular/common";
import { CorporateHolderModel, HolderModelBase, IndividualHolderModel, SecurityRegistryRecord } from '../../../../../../models/securityRegistryRecord';
import { AsPipe } from '../../../../../../pipes/asPipe';
import { EntityNameNumberRecordComponent } from '../../../entity-name-number-record/entity-name-number-record.component';
import { ACNPipe } from '../../../../../../pipes/acnPipe';
import { SpanWithOpenIconComponent } from '../../../span-with-open-icon/span-with-open-icon.component';
import { DefaulValuePipe } from "../../../../../../pipes/enumsPipes/defaultValuePipe";
import { IndividualData } from "../../../../../../models/individualData";
import { ICellRendererAngularComp } from "ag-grid-angular";

export interface IContainsHolderData {
  pendingButtonState?: boolean;
  individualData?: IndividualData;
  previousIndividualData?: IndividualData;
}

@Component({
  selector: 'app-ag-holder',
  standalone: true,
  imports: [
    NgClass,
    KeyValuePipe,
    AsPipe,
    ACNPipe,
    EntityNameNumberRecordComponent,
    SpanWithOpenIconComponent,
    DefaulValuePipe
  ],
  templateUrl: './ag-holder.component.html',
  styleUrl: './ag-holder.component.scss'
})
export class AgHolderComponent implements ICellRendererAngularComp {
  holderGroups: Record<string, HolderModelBase[]> = {};
  pendingButtonState = false;
  officeholderName: string | null = '';
  previousOfficeholderName = '';

  CorporateHolderModel = CorporateHolderModel;
  IndividualHolderModel = IndividualHolderModel;

  agInit(params: ICellRendererParams<SecurityRegistryRecord>): void {
    this.updateCell(params);
  }

  refresh(params: ICellRendererParams<SecurityRegistryRecord>): boolean {
    return this.updateCell(params);
  }

  updateCell(params: ICellRendererParams<SecurityRegistryRecord>): boolean {
    this.holderGroups = params.data!.beneficialOwnerGroups;
    const holder = this.holderGroups['']?.[0] as IContainsHolderData | null;
    this.pendingButtonState = holder?.pendingButtonState ?? false;
    this.officeholderName = holder?.individualData?.fullName ?? '';
    this.previousOfficeholderName = holder?.previousIndividualData?.fullName ?? '';
    return true;
  }

  get keys(): string[] {
    return Object.keys(this.holderGroups);
  }

  get isJoint(): boolean {
    return this.keys.length > 1 || this.holderGroups[this.keys[0]].length > 1;
  }
}
