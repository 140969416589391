import { Injectable } from '@angular/core';
import suburbs from './../assets/suburbs/suburbs.json';
import { SelectOption } from "../models/selectOptions";
import { map, Observable, of } from "rxjs";
import { HttpClient } from "@angular/common/http";

export interface IAddressPart {
  suburb: string,
  postcode: string,
  state: string,
}

@Injectable()
export class ManualAddressService {
  static suburbs = suburbs as unknown as IAddressPart[];

  constructor(private http: HttpClient) {}

  getSuburbs(): Observable<SelectOption[]> {
    return this.http.get<IAddressPart[]>('./../assets/suburbs/suburbs.json').pipe(
      map((suburbs) =>
        suburbs.map((suburb, index) => ({
          label: suburb.suburb,
          value: index,
        }))
      )
    );
  }

  searchSuburbs(query: string | null): Observable<SelectOption[]> {
    if(!query) return of([]);

    return this.getSuburbs().pipe(
      map((options) =>
        options.filter((option) =>
          option.label.toLowerCase().includes(query.toLowerCase())
        )
      )
    );
  }

  // getSuburbsSelectOptions(): SelectOption[] {
  //   return ManualAddressService.suburbs
  //     .map((suburb, index) => ({
  //       label: suburb.suburb,
  //       value: index,
  //     }));
  // }

  getAddressPartByIndex(index: number): IAddressPart {
    return ManualAddressService.suburbs[index];
  }

  static findFirstNAddressParts(substring: string, key: keyof IAddressPart, limit = 20): IAddressPart[] {
    const suburbsQuantity = ManualAddressService.suburbs.length;
    const addressParts: IAddressPart[] = [];
    substring = substring.trim().toLowerCase();

    for (let i = 0; i < suburbsQuantity && addressParts.length < limit; i++) {
      const includes = ManualAddressService.suburbs[i].suburb.toString().toLowerCase().includes(substring);

      if (includes) {
        addressParts.push(ManualAddressService.suburbs[i]);
      }
    }

    return addressParts;
  }
}
