<app-stepper-form-appliable
  header="Change of Appointor"
  [showSteps]="false"
  [showSaveAndCompleteLaterBtn]="false"
  [steps]="stepsSignal()"
  [currentStep]="currentStep"
  [currentStepIndex]="currentStepIndex"
  [form]="stepperForm"
  [isLoading]="isLoading"
>
  <div class="step-content" [formGroup]="stepperForm">
    <section
      class="step-section"
      [formGroupName]="StepsEnum.ExistingTrusteeDetails"
      [hidden]="currentStep !== StepsEnum.ExistingTrusteeDetails"
    >
      <h1 class="h1-heading">{{ stepsSignal()[currentStepIndex].label }}</h1>

      <ul class="list-unstyled">
        @if (existingTrusteesForm.controls.trusteeType.value === IndividualCorporateRelationship.Individual) {
          @for (appointorFormGroup of existingTrusteesForm.controls.individualTrustee.controls; track appointorFormGroup; let index = $index; let last = $last) {
            <li>
              <app-relationship-form-group
                nameControlLabel="Name of Appointor"
                [formGroup]="appointorFormGroup"
              />
            </li>

            @if (!last) {
              <li>
                <app-divider margin="24px 0"/>
              </li>
            }
          }
        } @else if (existingTrusteesForm.controls.trusteeType.value === IndividualCorporateRelationship.Corporate) {
          <app-corporate-relationship-form-group
            [hideIsOverseasCompany]="true"
            [formGroup]="existingTrusteesForm.controls.corporateTrustee"
         />
        }
      </ul>
    </section>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.ExistingAppointorDetails"
      [hidden]="currentStep !== StepsEnum.ExistingAppointorDetails"
    >
      <h1 class="h1-heading">{{ stepsSignal()[currentStepIndex].label }}</h1>

      <ul class="list-unstyled">
        @if (existingAppointorForm.controls.appointorType.value === IndividualCorporateRelationship.Individual) {
          @for (appointorFormGroup of existingAppointorForm.controls.individualAppointors.controls; track appointorFormGroup; let index = $index; let last = $last) {
            <li>
              <app-relationship-form-group
                nameControlLabel="Name of Appointor"
                [formGroup]="appointorFormGroup"
             />
            </li>

            @if (!last) {
              <li>
                <app-divider margin="24px 0"/>
              </li>
            }
          }
        } @else if (existingAppointorForm.controls.appointorType.value === IndividualCorporateRelationship.Corporate) {
          <app-corporate-relationship-form-group
            [hideIsOverseasCompany]="true"
            [formGroup]="existingAppointorForm.controls.corporateAppointor"
          />
        }
      </ul>
    </section>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.NewAppointorDetails"
      [hidden]="currentStep !== StepsEnum.NewAppointorDetails"
    >
      <h1 class="h1-heading">{{ stepsSignal()[currentStepIndex].label }}</h1>

      <div class="form-group">
        <app-select
          label="Appointor Type"
          formControlName="appointorType"
          [options]="individualCorporateAppointorOptions"
          [valuePrimitive]="true"
        />
      </div>

      @if (newAppointorDetailsForm.controls.appointorType.value == IndividualCorporateRelationship.Individual) {
        <div>Appointor(s)</div>

        <ul class="corporate-appointors-directors-list" (click)="openCreateEditIndividualAppointorModal()">
          @for (individualAppointor of newIndividualAppointors; track individualAppointor; let index = $index) {
            <li class="corporate-appointor-director" (click)="$event.stopPropagation()">
              <i class="icon ic-my-account"></i>
              <span>{{ individualAppointor.individualDataOverride?.fullName }}</span>

              <button
                class="edit-icon"
                type="button"
                [disabled]="isLoading"
                (click)="openCreateEditIndividualAppointorModal(index)"
              >
                <i class="ic-edit icon ic-sm"></i>
              </button>

              <button
                class="delete-icon"
                type="button"
                [disabled]="isLoading"
                (click)="$event.stopPropagation(); removeIndividualAppointor(index)"
              >
                <i class="ic-close icon ic-sm"></i>
              </button>
            </li>
          }
        </ul>

        @if (newAppointorDetailsForm.controls.individualAppointors.touched && newAppointorDetailsForm.controls.individualAppointors.errors) {
          <div class="form-group">
            <app-validation-error
              [control]="newAppointorDetailsForm.controls.individualAppointors"
              [customErrors]="customErrors"
            />
          </div>
        }

        <app-button
          iconClass="ic-plus ic-xs icon"
          text="Add Appointor"
          [isDisabled]="!allowAddIndividualAppointors"
          (clickBtn)="openCreateEditIndividualAppointorModal()"
       />
      } @else if (newAppointorDetailsForm.controls.appointorType.value == IndividualCorporateRelationship.Corporate) {
        <app-corporate-relationship-form-group
          [hideIsOverseasCompany]="true"
          [formGroup]="newAppointorDetailsForm.controls.corporateAppointor"
          (foundEntityId)="getCompanyProfile($event)"
        />

        <div>
          <div>Director(s)</div>

          <ul class="individual-appointors-list" (click)="openCreateEditCorporateAppointorDirectorModal()">
            @for (director of corporateAppointorDirectors; track director; let index = $index) {
              <li class="corporate-appointor" (click)="$event.stopPropagation()">
                <i class="icon ic-my-account"></i>
                <span>{{ director.individualDataOverride?.fullName }}</span>

                <button
                  class="edit-icon"
                  type="button"
                  [disabled]="isLoading"
                  (click)="openCreateEditCorporateAppointorDirectorModal(index)"
                >
                  <i class="ic-edit icon ic-sm"></i>
                </button>

                <button
                  class="delete-icon"
                  type="button"
                  [disabled]="isLoading"
                  (click)="$event.stopPropagation(); removeCorporateAppointorDirector(index)"
                >
                  <i class="ic-close icon ic-sm"></i>
                </button>
              </li>
            }
          </ul>

          @if (newAppointorDetailsForm.controls.corporateAppointor.touched && newAppointorDetailsForm.controls.corporateAppointor.errors) {
            <div class="form-group">
              <app-validation-error
                [control]="newAppointorDetailsForm.controls.corporateAppointor"
                [customErrors]="customErrors"
              />
            </div>
          }

          <app-button
            iconClass="ic-plus ic-xs icon"
            text="Add Director"
            [isDisabled]="!allowAddCorporateAppointorDirectors"
            (click)="openCreateEditCorporateAppointorDirectorModal()"
          />
        </div>
      }
    </section>
  </div>
</app-stepper-form-appliable>
