import { Component, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs';
import { DatepickerHelper } from '../../../custom-form-validators/date-picker-form-validators';
import { Document } from '../../../models/document';
import { DatePickerComponent } from '../../components/common/date-picker/date-picker.component';
import { SelectComponent } from '../../components/common/select/select.component';
import { BaseStepperFormComponent } from '../stepper-form/base-stepper-component/base-stepper-form.component';
import {
  StepperFormDescriptionComponent
} from '../stepper-form/stepper-form-description/stepper-form-description.component';
import { StepperFormComponent } from '../stepper-form/stepper-form.component';
import { CompanyForm281, ShareBuyBackStepsEnum, ShareBuyBackType, shareBuyBackTypeOptions } from './form281.model';

// Notice of intention to carry out a share buy-back
@Component({
  selector: 'app-form281',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    StepperFormDescriptionComponent,
    StepperFormComponent,
    SelectComponent,
    DatePickerComponent
  ],
  templateUrl: './form281.component.html',
  styleUrl: '../stepper-form/base-stepper-component/base-stepper-form.component.scss'
})
export class Form281Component extends BaseStepperFormComponent<ShareBuyBackStepsEnum, CompanyForm281> implements OnInit {
  readonly shareBuyBackTypeOptions = shareBuyBackTypeOptions;
  readonly dateControlsTypes: Record<string, ShareBuyBackType[]> = {
    proposedDateBuyBackAgreementEnter: [
      ShareBuyBackType.EmployeeWithin,
      ShareBuyBackType.EmployeeOver,
      ShareBuyBackType.EqualWithin,
      ShareBuyBackType.EqualOver,
      ShareBuyBackType.Selective
    ],
    proposedDatePassingResolutionBuyBackApprove: [
      ShareBuyBackType.EmployeeOver,
      ShareBuyBackType.MarketOver,
      ShareBuyBackType.EqualOver,
      ShareBuyBackType.Selective
    ],
    marketBuyBackFrom: [ShareBuyBackType.MarketWithin, ShareBuyBackType.MarketOver],
    marketBuyBackTo: [ShareBuyBackType.MarketWithin, ShareBuyBackType.MarketOver]
  };
  override readonly StepsEnum = ShareBuyBackStepsEnum;

  minDate = DatepickerHelper.getTodayStruct();
  maxDate = DatepickerHelper.getNextYearStruct();

  override stepperForm = new FormGroup({
    [ShareBuyBackStepsEnum.FormDescription]: new FormGroup({}),
    [ShareBuyBackStepsEnum.TheChange]: new FormGroup({
      shareBuyBackType: new FormControl<ShareBuyBackType | null>(null, [Validators.required]),
      proposedDateBuyBackAgreementEnter: new FormControl<Date | null>(null, [Validators.required]),
      proposedDatePassingResolutionBuyBackApprove: new FormControl<Date | null>(null, [Validators.required]),
      marketBuyBackFrom: new FormControl<Date | null>(null, [Validators.required]),
      marketBuyBackTo: new FormControl<Date | null>(null, [Validators.required]),
    }),
  });

  constructor() {
    super();
    this.setupSteps(ShareBuyBackStepsEnum);
    this.redirectAfterSubmit = true;
  }

  ngOnInit(): void {
    this.listenShareBuyBackTypeChange();
    this.setupChange();
  }

  override setupChange() {
    if (!this.isEdit) {
      return;
    }

    this.theChangeForm.patchValue(this.formModel);
  }

  override buildDocument(): Document | null {
    const dates: Partial<CompanyForm281> = Object.keys(this.dateControlsTypes)
      .reduce((acc, key) => {
        acc[key] = this.theChangeForm.value[key] ? DatepickerHelper.buildDateString(this.theChangeForm.value[key]) : null;

        return acc;
      }, {} as Partial<CompanyForm281>);

    const change = new CompanyForm281({
      ...this.theChangeForm.value as Partial<CompanyForm281>,
      ...dates,
      changeDate: DatepickerHelper.buildTodayDateString(),
    });

    try {
      return new Document({
        changes: [change],
        entityId: this.companyChangeData?.entityId,
        type: 'c:281',
        documentId: this.companyChangeData?.documentId,
      });
    } catch (error) {
      this.toastr.error('Failed to create Document.', 'Error');
      return null;
    }
  }

  private listenShareBuyBackTypeChange(): void {
    this.stepperForm.controls[ShareBuyBackStepsEnum.TheChange].controls.shareBuyBackType.valueChanges
      .pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
      .subscribe((shareByBackType) => {
        if (shareByBackType) {
          Object.entries(this.dateControlsTypes).forEach(([key, allowedTypes]) => {
            (this.theChangeForm.controls[key] as FormControl)?.[allowedTypes.includes(+shareByBackType) ? 'enable' : 'disable']();
          });
        }
      });
  }

  get theChangeForm() {
    return this.stepperForm.controls[ShareBuyBackStepsEnum.TheChange];
  }
}
