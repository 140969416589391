import { FileCreateDto } from './files';
import { UIProfile } from './uiProfile';
import { User } from './user';

export class Note {
  id: string;
  organisationId: string | null;
  text: string;
  attachemntsName: string[];
  modifiedOn: string;
  entityId: string | null;
  individualId: string | null;
  userId: string | null;
  user: User | null;

  constructor(data: Partial<Note> = {}) {
    this.id = data.id ?? '';
    this.organisationId = data.organisationId ?? null;
    this.text = data.text ?? '';
    this.attachemntsName = data.attachemntsName ?? [];
    this.modifiedOn = data.modifiedOn ?? '';
    this.entityId = data.entityId ?? null;
    this.individualId = data.individualId ?? null;
    this.userId = data.userId ?? null;
    this.user = data.user ? new User(data.user) : null;
  }
}

export class NoteRecord {
  id: string;
  text: string;
  entityId: string | null;
  individualId: string | null;
  attachmentsToUpload: FileCreateDto[];
  attachmentsName: string[];
  userId: string | null;
  user: UIProfile | null;
  modifiedOn: Date;

  constructor(data: Partial<NoteRecord> = {}) {
    this.id = data.id ?? '';
    this.text = data.text ?? '';
    this.entityId = data.entityId ?? null;
    this.individualId = data.individualId ?? null;
    this.attachmentsToUpload = data.attachmentsToUpload ?? [];
    this.attachmentsName = data.attachmentsName ?? [];
    this.userId = data.userId ?? null;
    this.user = data.user ?? null;
    this.modifiedOn = data.modifiedOn ? new Date(data.modifiedOn) : new Date();
  }

  toJSON() {
    const { user, ...rest } = this;
    return { ...rest };
  }
}
