import { DocumentStatusEnum } from "./enums/documentStatusEnum";
import { AsicStatus } from "./enums/asicStatus";

export class DocumentRecord {
    documentName: string;
    documentId: string;
    entityId: string;
    entityName: string;
    entityNumber: string;
    description: string;
    asicStatus: AsicStatus;
    status?: DocumentStatusEnum;
    createdOn: Date;
    type: string;
    checked: boolean;

    constructor(documentRecord: Partial<DocumentRecord> = {}) {
        this.documentName = documentRecord.documentName ?? '';
        this.documentId = documentRecord.documentId ?? '';
        this.entityId = documentRecord.entityId ?? '';
        this.entityName = documentRecord.entityName ?? '';
        this.entityNumber = documentRecord.entityNumber ?? '';
        this.description = documentRecord.description ?? '';
        this.asicStatus = documentRecord.asicStatus ?? AsicStatus.NotLodged;
        this.status = documentRecord.status;
        this.createdOn = documentRecord.createdOn ? new Date(documentRecord.createdOn) : new Date();
        this.type = documentRecord.type ?? '';
        this.checked = documentRecord.checked ?? false;
    }
}
