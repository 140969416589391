import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { IStep } from '../../../models/step';
import { ButtonComponent } from '../../components/common/button/button.component';
import { ClickableStepperComponent } from '../../components/common/clickable-stepper/clickable-stepper.component';
import { AppSpinnerDirective } from '../../../directives/spinner.directive';
import { LoaderStandaloneComponent } from "../../components/common/loader-standalone/loader-standalone.component";

@Component({
  selector: 'app-stepper-form',
  standalone: true,
  imports: [
    ClickableStepperComponent,
    AppSpinnerDirective,
    ButtonComponent,
    LoaderStandaloneComponent,
  ],
  templateUrl: './stepper-form.component.html',
  styleUrl: './stepper-form.component.scss'
})
export class StepperFormComponent {
  @ViewChild('nextStepButton', { read: ElementRef }) nextStepButton?: ElementRef;
  @ViewChild('submitButton', { read: ElementRef }) submitButton?: ElementRef;

  @Input({ required: true }) steps: IStep[] = [];
  @Input({ required: true }) currentStep!: unknown | string;
  @Input({ required: true }) currentStepIndex = 0;
  @Input({ required: true }) form!: FormGroup;
  @Input() isSaveAndCompleteLaterLoading = false;
  @Input() showSaveAndCompleteLaterBtn = true;
  @Input() isInvalid = false;
  @Input() header = '';
  @Input() subheader = '';
  @Input() submitButtonText = 'Generate Documents';
  @Input() showSteps = true;
  @Input('isLoading') set isLoadingSetter(value: boolean) {
    this.isLoading = value;
    if (!value) {
      this.isSaveAndCompleteLaterLoading = false;
    }
  }

  @Output() stepIndexChange = new EventEmitter<number>();
  @Output() close = new EventEmitter<void>();
  @Output() saveAndCompleteLater = new EventEmitter<void>();
  @Output() confirm = new EventEmitter<void>();

  isLoading = false;

  setCurrentStep(newStepIndex: number) {
    const firstInvalidFormStep = this.steps
      .slice(0, newStepIndex)
      .find(step => this.form.controls[step.step as string].invalid);

    if (newStepIndex < this.currentStepIndex || !firstInvalidFormStep) {
      this.currentStep = this.steps[newStepIndex].step as string;
      this.currentStepIndex = newStepIndex;
      this.stepIndexChange.emit(newStepIndex);

      this.setFocusOnFirstInput();

      return;
    }

    const invalidStepIndex = this.steps.findIndex(step => step.step === firstInvalidFormStep.step);
    this.setCurrentStep(invalidStepIndex);
    this.markEnabledControlsAsTouched(this.form.controls[firstInvalidFormStep.step as string]);
    this.form.controls[firstInvalidFormStep.step as string].updateValueAndValidity();
  }

  setNextStep() {
    if (this.currentStepIndex < (this.steps.length - 1)) {
      this.setCurrentStep(this.currentStepIndex + 1);
    }
  }

  setPreviousStep() {
    if (this.currentStepIndex > 0) {
      this.setCurrentStep(this.currentStepIndex - 1);
    }
  }

  returnFocus() {
    if (this.nextStepButton?.nativeElement && !this.nextStepButton?.nativeElement?.disabled) {
      this.setNextStep();
    } else if (this.submitButton?.nativeElement) {
      this.submitButton.nativeElement?.focus();
    }
  }

  onSubmit(): void {
    this.setNextStep();

    if (this.currentStepIndex === this.steps.length - 1) {
      this.confirm.emit();
      this.markEnabledControlsAsTouched(this.form.controls[this.currentStep as string]);
    }
  }

  onSaveAndCompleteLater(): void {
    this.isSaveAndCompleteLaterLoading = true;
    this.saveAndCompleteLater.emit();
  }

  closeModal(): void {
    this.close.emit();
  }

  get isCurrentStepInvalid(): boolean {
    return this.form.controls[this.currentStep as string]?.invalid || this.isLoading;
  }

  get isFormInvalid(): boolean {
    return this.isInvalid || this.form.invalid || this.isLoading;
  }

  private markEnabledControlsAsTouched(control: AbstractControl) {
    try {
      if (control.enabled) {
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });

        } else if (control instanceof FormGroup || control instanceof FormArray) {
          Object.values(control?.controls ?? []).forEach((c) => this.markEnabledControlsAsTouched(c));
        }
      }
    } catch (error) {
      console.error('[markEnabledControlsAsTouched] Error marking controls as touched: ', error);
      console.error('Control with error: ', control);
    }
  }

  private setFocusOnFirstInput(): void {
    setTimeout(() => {
      const element = document.getElementById('stepper-body')
        ?.querySelector('.step-section:not([hidden])')
        ?.querySelector('input, radio, checkbox');

      if (element instanceof HTMLElement) {
        element.focus();
      }
    });
  }
}
