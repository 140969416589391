import { Component, Input } from '@angular/core';
import {
  AgActionIconButtonComponent
} from "../../../components/common/grid/components/ag-action-icon-button/ag-action-icon-button.component";
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle } from "@ng-bootstrap/ng-bootstrap";
import { EmailRecipientsComponent } from "../ag-email-recipients/email-recipients.component";
import { ICellRendererParams } from "ag-grid-community";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { EmailRecord } from "../../../../models/email-templates/emailRecord";

@Component({
  selector: 'app-ag-email-recipients-dropdown',
  standalone: true,
  imports: [
    AgActionIconButtonComponent,
    NgbDropdown,
    NgbDropdownMenu,
    NgbDropdownToggle,
    EmailRecipientsComponent
  ],
  templateUrl: './ag-email-recipients-dropdown.component.html',
  styles: '.dropdown:hover .email-recipient { text-decoration: underline; cursor: pointer; }'
})
export class AgEmailRecipientsDropdownComponent implements ICellRendererAngularComp {
  @Input() emailRecord!: EmailRecord;

  clickedOnDropdown = false;

  agInit(params: ICellRendererParams<EmailRecord>): void {
    this.emailRecord = params.data ?? new EmailRecord();
  }

  refresh(params: ICellRendererParams<EmailRecord>): boolean {
    this.emailRecord = params.data ?? new EmailRecord();
    return true;
  }

  onOpenDropdown(event: Event, clickedOnDropdown = false): void {
    if (!this.showRecipientsDropdown && this.clickedOnDropdown) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.clickedOnDropdown = clickedOnDropdown;
  }

  get showRecipientsDropdown(): boolean {
    return Boolean(this.emailRecord.cc);
  }
}
