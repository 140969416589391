import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common'

@Pipe({
  standalone: true,
  name: 'dateFormat'
})

export class DateFormatPipe implements PipeTransform {

  constructor(private datePipe: DatePipe) { }

  transform(value: Date | string | undefined | null): string {
    if (value === undefined || value === null)
      return '-';

    const datestr = this.datePipe.transform(value, "dd/MM/yyyy");
    if (datestr)
      return datestr;

    return '-';
  }
}
