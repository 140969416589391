import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { NgClass, NgForOf } from "@angular/common";
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from "@ng-bootstrap/ng-bootstrap";
import { PageSizeService } from "../../services/page-size.service";
import { FormsModule } from "@angular/forms";
import { SelectComponent } from "../../../select/select.component";

@Component({
  selector: 'app-ag-custom-pagination',
  standalone: true,
  imports: [
    NgForOf,
    NgClass,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownItem,
    NgbDropdownMenu,
    FormsModule,
    SelectComponent
  ],
  templateUrl: './ag-custom-pagination.component.html',
  styleUrl: './ag-custom-pagination.component.scss',
})
export class AgCustomPaginationComponent {
  @Input() currentPage = 0;
  @Input() totalPages = 0;
  @Input() pageNumbers: (number | string)[] = [];
  @Input() paginationPageSize = 0;
  @Input() paginationPageSizeSelector: number[] | undefined;
  @Input() showPageSizeSelector: number[] | boolean = true;
  @Output() previousPage = new EventEmitter<boolean>();
  @Output() nextPage = new EventEmitter<boolean>();
  @Output() selectPage = new EventEmitter<number>();

  pageSizeService = inject(PageSizeService);

  dropdownOpen = false;

  goToPage(page: number): void {
    this.selectPage.emit(page)
  }

  goToPreviousPage(): void {
    this.previousPage.emit(true);
  }

  goToNextPage(): void {
    this.nextPage.emit(true);
  }

  onSelectPageSize(size: number): void {
    this.pageSizeService.addPageSize(size);
    this.goToPage(0);
  }

  getDropdownState(dropdownState: boolean): void {
    this.dropdownOpen = dropdownState;
  }
}
