import { Component } from '@angular/core';
import { IHeaderParams } from "ag-grid-community";
import { NgClass } from "@angular/common";
import { ButtonComponent } from "../../../../button/button.component";

@Component({
  selector: 'app-ag-share-group-header',
  standalone: true,
  imports: [
    NgClass,
    ButtonComponent
  ],
  templateUrl: './ag-share-group-header.component.html',
  styleUrl: './ag-share-group-header.component.scss'
})
export class AgShareGroupHeaderComponent {
  public params!: IHeaderParams;
  public expandedGroups = false;

  agInit(params: IHeaderParams): void {
    this.params = params;
  }

  refresh(params: IHeaderParams) {
    this.params = params;
    return false;
  }

  toggleAllGroups():void {
    this.expandedGroups = !this.expandedGroups;

    for(let i = 0; i < this.params.api.getDisplayedRowCount(); i++) {
      this.params.api.getDisplayedRowAtIndex(i)!.setExpanded(this.expandedGroups);
    }
  }
}
