import { Pipe, PipeTransform } from '@angular/core';
import { JurisdictionType } from "../../models/enums/trustEnum";
import { AustralianState } from "../../models/enums/countryEnums";

@Pipe({
  name: 'jurisdiction',
  standalone: true
})
export class JurisdictionPipe implements PipeTransform {
  transform(value: JurisdictionType | null): string {
    if(value !== null)
      return AustralianState[JurisdictionType[value]] as string;

    return '-';
  }
}
