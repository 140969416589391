import { CurrencyPipe } from '@angular/common';
import { booleanAttribute, Component, inject, Input, OnInit } from '@angular/core';
import { ColDef, ValueGetterParams } from 'ag-grid-enterprise';
import { SecurityRegistryRecord } from '../../../../../models/securityRegistryRecord';
import { AgHolderComponent } from '../../../../components/common/grid/components/ag-holder/ag-holder.component';
import {
  AgShareBeneficiallyHolderComponent
} from '../../../../components/common/grid/components/ag-share-beneficially-holder/ag-share-beneficially-holder.component';
import {
  AgShareClassTypeComponent
} from '../../../../components/common/grid/components/ag-share-class-type/ag-share-class-type.component';
import { ListGridComponent } from '../../../../components/common/grid/components/list-grid/list-grid.component';
import { LoaderComponent } from '../../../../components/common/loader/loader.component';
import {
  AgMultipleRowsComponent
} from "../../../../components/common/grid/components/ag-multiple-rows/ag-multiple-rows.component";

@Component({
  selector: 'app-share-estimate-table',
  standalone: true,
  imports: [
    ListGridComponent,
    LoaderComponent
  ],
  templateUrl: './share-estimate-table.component.html',
  styleUrl: './share-estimate-table.component.scss'
})
export class ShareEstimateTableComponent implements OnInit {
  currencyPipe = inject(CurrencyPipe);

  @Input() isEstimateLoading = false;
  @Input() exceptColumns: string[] = [];
  @Input() estimatedSecurityRegistryRecords: SecurityRegistryRecord[] = [];
  @Input({ transform: booleanAttribute }) hideSecurityTypeQuantity = false;
  @Input({ transform: booleanAttribute }) hideSecurityTypePrice = false;

  shareCancellationEstimatesColDefs: ColDef[] = [
    {
      headerName: 'Name',
      flex: 2,
      cellRenderer: AgHolderComponent,
      autoHeight: true,
      cellClass: 'overflow-hidden pr-0',
      sort: 'asc',
      suppressHeaderMenuButton: true,
      minWidth: 200
    },
    {
      headerName: 'Class Type',
      width: 200,
      autoHeight: true,
      flex: 2,
      cellRendererParams: { hideQuantity: false, hidePrice: false },
      cellRenderer: AgShareClassTypeComponent
    },
    {
      headerName: 'Number',
      width: 120,
      flex: 1,
      cellClass: 'overflow-hidden text-end',
      cellRenderer: AgMultipleRowsComponent,
      cellRendererParams: { rowClass: 'text-end' },
      valueGetter: (params: ValueGetterParams) => {
        return params.data?.holdings.map((holding) => holding.number) ?? [];
      },
    },
    {
      headerName: 'Total Paid',
      width: 120,
      flex: 1,
      cellClass: 'overflow-hidden text-end',
      cellRenderer: AgMultipleRowsComponent,
      cellRendererParams: { rowClass: 'text-end' },
      valueGetter: (params: ValueGetterParams) => {
        return params.data?.holdings.map((holding) => this.toCurrency(holding.paid)) ?? [];
      },
    },
    {
      headerName: 'Total Unpaid',
      width: 120,
      flex: 1,
      cellClass: 'overflow-hidden text-end',
      cellRenderer: AgMultipleRowsComponent,
      cellRendererParams: { rowClass: 'text-end' },
      valueGetter: (params: ValueGetterParams) => {
        return params.data?.holdings.map((holding) => this.toCurrency(holding.unpaid)) ?? [];
      },
    },
    {
      headerName: 'Beneficially Hold',
      width: 80,
      flex: 1,
      cellRenderer: AgShareBeneficiallyHolderComponent
    },
  ];

  ngOnInit() {
    this.shareCancellationEstimatesColDefs = this.shareCancellationEstimatesColDefs
      .filter(colDef => colDef.headerName && !this.exceptColumns.includes(colDef.headerName))
      .map((colDef) => {

        if (colDef.headerName === 'Class Type') {
          colDef.cellRendererParams = {
            hideQuantity: this.hideSecurityTypeQuantity,
            hidePrice: this.hideSecurityTypePrice
          };
        }

        return colDef;
      });
  }

  private toCurrency(value: number) {
    return this.currencyPipe.transform(value ?? '', '$', 'code', '1.2-2')!;
  }
}
