<div class="add-corporate-security-holder-modal">
  <app-common-modal-form
    closeText="Cancel"
    confirmText="Save"
    [header]="header"
    [formGroup]="form"
    (confirm)="save()"
    (close)="cancel()"
  >
    <div class="form-group">
      <app-company-name-acn-form-group
        [shouldLookForCompanyName]="!form.value.isOverseasCompany"
        [hideAcn]="hideAcn"
      >
        <div class="form-group">
          <app-checkbox
            label="This is an overseas company or the company doesn't have an ACN"
            formControlName="isOverseasCompany"
          ></app-checkbox>
        </div>
      </app-company-name-acn-form-group>
    </div>

    <div class="form-group">
      <app-address-form-group
        [formGroup]="form.controls.registeredAddress"
        [useInternationalAddresses]="form.value.isOverseasCompany ?? false"
        [addressFormLabels]="addressFormLabels"
        [suggestedAddresses]="suggestedAddresses"
      ></app-address-form-group>
    </div>
  </app-common-modal-form>
</div>
