import { NgIf } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { ButtonComponent } from '../button/button.component';

@Component({
  standalone: true,
  selector: 'app-inline-edit-input',
  templateUrl: './inline-edit-input.component.html',
  styleUrl: './inline-edit-input.component.scss',
  imports: [RouterModule, NgIf, FormsModule, ButtonComponent]
})
export class InlineEditInputComponent implements OnInit {
  @Input() label!: string;
  @Input() value!: string;
  @Input() onSave!: (value: string) => Observable<unknown>;
  @Input() isLarge = false;
  @Input() controlLike = false;

  editMode = false;
  loading = false;
  inputValue = '';

  ngOnInit() {
    this.inputValue = this.value;
  }

  edit() {
    this.editMode = true;
  }

  save() {
    this.loading = true;
    this.onSave(this.inputValue).subscribe({
      next: () => {
        this.value = this.inputValue;
        this.editMode = false;
        this.loading = false;
      }
    });
  }

  cancel() {
    this.inputValue = this.value;
    this.editMode = false;
  }
}
