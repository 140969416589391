<div class="card settings-page">
  <app-page-title
    iconClass="ic-organisation"
    header="Organisation Details"
    [backButtonPath]="organisationSetupRequired ? null : 'settings'"
  >
    <app-button
      text="Edit Organisation"
      iconClass="icon ic-xs ic-edit"
      [isDisabled]="editOrganisationModalOpened || isLoading"
      (clickBtn)="openEditOrganisationModal()"
    />
  </app-page-title>

  <div>
    @if (!isLoading) {
      <div class="organisation-details-part">
        <div class="organisation-details-item">
          <div class="form-group">
            <label class="title mb-1">Account Name</label>
            <span class="property" appCopy>{{ organisation.name | defaultValue }}</span>
          </div>

          <div class="form-group">
            <label class="title mb-1">Email Address</label>
            <span class="property" appCopy>{{ organisation.email | defaultValue }}</span>
          </div>

          <div class="form-group">
            <label class="title mb-1">Phone Number</label>
            <span class="property" appCopy>{{ (organisation.phone ?? '') | phone | defaultValue }}</span>
          </div>
        </div>

        <div class="organisation-details-item">
          <div class="form-group">
            <label class="title mb-1">Address</label>
            <span class="property"
                  appCopy>{{ organisation.address?.normalizedFullAddress | defaultValue }}</span>
          </div>

          <div class="form-group">
            <label class="title mb-1">Website</label>
            @if (organisation.website) {
              <a class="property website-url" target="_blank"
                 [href]="organisation.website">{{ organisation.website }}</a>
            } @else {
              <span class="property" appCopy>{{ organisation.website | defaultValue }}</span>
            }
          </div>
        </div>

        <div class="organisation-details-item">
          <div class="form-group">
            <label class="title mb-1">Primary Contact</label>
            <span class="property" appCopy>{{ primaryContactLabel | defaultValue }}</span>
          </div>

          <div class="form-group">
            <label class="title mb-1">Billing Contact</label>
            <span class="property" appCopy>{{ billingContactLabel | defaultValue }}</span>
          </div>
        </div>
      </div>

      <div class="subscription-part">
        <div class="part-title">Current Subscription</div>

        <div class="subscription-part-grid">
          <div class="form-group">
            <label class="title mb-1">Current Subscription</label>
            <span class="property" appCopy>{{ '' | defaultValue }}</span>
          </div>
        </div>
      </div>
    }

    <app-loader-standalone [loading]="isLoading"/>
  </div>
</div>
