import { DocumentStatusEnum } from "../../models/enums/documentStatusEnum";
import { DocumentStepEnum } from "../../models/enums/documentStepEnum";

export function documentStatusToStep(documentStatus: DocumentStatusEnum | null | undefined): number {
  const documentStatusToStepMap = {
    [DocumentStatusEnum.Draft]: DocumentStepEnum.InitiateChange,
    [DocumentStatusEnum.AuthorisationPending]: DocumentStepEnum.InitiateChange,
    [DocumentStatusEnum.SignaturePending]: DocumentStepEnum.SignDocuments,
    [DocumentStatusEnum.LodgementPending]: DocumentStepEnum.SignDocuments,
    [DocumentStatusEnum.Completed]: DocumentStepEnum.AsicResponse,
  };

  if(documentStatus !== null && documentStatus !== undefined) {
    return documentStatusToStepMap[documentStatus] as number;
  }

  return 0;
}
