@if(previewMode) {
  <div class="preview-wrapper">
    <div class="preview-label">Email Preview</div>

    <div class="template-wrapper">
      @if(!loading) {
        <app-shadow-dom-viewer [customStyle]="customStyle" [htmlContent]="htmlContent()"/>
      } @else {
        <div class="d-flex flex-column gap-24">
          <ngx-skeleton-loader count="3" appearance="line" />
          <div class="d-flex gap-24">
            <ngx-skeleton-loader style="width: 50%" count="10" appearance="line" />
            <ngx-skeleton-loader style="width: 50%" count="10" appearance="line" />
          </div>
        </div>
      }
    </div>
  </div>
} @else {
  <div class="email-container" [formGroup]="form">
    <div class="controls-wrapper">
      <!--    <div class="form-group">-->
      <!--      <app-select-->
      <!--        [label]="'Email Template'"-->
      <!--        [placeholder]="'Select Email Template'"-->
      <!--        [valuePrimitive]="true"-->
      <!--        [options]="emailTemplateOptions"-->
      <!--        [(ngModel)]="emailTemplate"-->
      <!--        [ngModelOptions]="{standalone: true}"-->
      <!--        (ngModelChange)="onEmailSelect($event)"-->
      <!--      ></app-select>-->
      <!--    </div>-->

      <div class="form-group">
        <app-input label="Subject" formControlName="subjectTemplate"/>
      </div>

      <div class="form-group mh-500">
        <app-textarea label="Body" formControlName="bodyTemplate" [rows]="maxBodyRows"/>
      </div>

      <div class="form-group width-200">
        <app-button [text]="'Click here to change footer'" [btnColor]="'white'" (clickBtn)="toggleFooter()"></app-button>
      </div>

      @if(showFooter) {
        <div class="form-group text-area">
          <app-textarea label="Footer" formControlName="footerTemplate" [rows]="maxFooterRows"/>
        </div>
      }
    </div>

    <div class="preview-wrapper">
      <div class="preview-label">Email Preview</div>

      <div class="template-wrapper">
        @if(!loading) {
          <app-shadow-dom-viewer [customStyle]="customStyle" [htmlContent]="htmlContent()"/>
        } @else {
          <div class="d-flex flex-column gap-24">
            <ngx-skeleton-loader count="3" appearance="line" />
            <div class="d-flex gap-24">
              <ngx-skeleton-loader style="width: 50%" count="10" appearance="line" />
              <ngx-skeleton-loader style="width: 50%" count="10" appearance="line" />
            </div>
          </div>
        }
      </div>
    </div>
  </div>
}

