import { NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { AsicStatus } from '../../../../../../models/enums/asicStatus';
import { AsicStatusPipe } from '../../../../../../pipes/enumsPipes/asicStatusPipe';
import { CompanyStatusPipe } from '../../../../../../pipes/enumsPipes/companyStatusPipe';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-ag-asic-document-status',
  standalone: true,
  imports: [
    CompanyStatusPipe,
    AsicStatusPipe,
    NgClass
  ],
  templateUrl: './ag-asic-document-status.component.html',
  styleUrl: './ag-asic-document-status.component.scss'
})
export class AgAsicDocumentStatusComponent implements ICellRendererAngularComp {
  readonly AsicStatus = AsicStatus;

  status = AsicStatus.NotLodged;

  agInit(params: ICellRendererParams): void {
    this.status = params.value as number;
  }

  refresh(params: ICellRendererParams): boolean {
    this.status = params.value as number;
    return true;
  }
}
