import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DatepickerHelper } from '../../../../../../custom-form-validators/date-picker-form-validators';
import { Address } from '../../../../../../models/address';
import { Company } from '../../../../../../models/company';
import { Contact } from '../../../../../../models/contact';
import { Document } from '../../../../../../models/document';
import { EntityChangeData } from '../../../../../../models/entityChangeData';
import { CompanyChangeData } from '../../../../../../models/enums/companyChangeData';
import { IndividualData } from '../../../../../../models/individualData';
import { SelectOption } from '../../../../../../models/selectOptions';
import { ChangeDictionaryHelper } from '../../../../../../models/shared/change-dictionary-helper.model';
import { Ra01RegisterCeaseChangeAgentComponent } from './ra01-register-cease-change-agent.component';
import { EntityData } from "../../../../../../models/entityData";

export enum RegisterCeaseChangeAgentStepsEnum {
  FormDescription = 0,
  NotificationType = 1,
  RegisterAnAgent = 2,
  AddressDetails = 3,
  ResponsiblePersonAndContactPerson = 4,
  ChangeOfDetails = 5,
  CeaseAnAgent = 6,
}

export class RegisterCeaseChangeAgentForm extends EntityChangeData {
  static override readonly $type = 'RegisterCeaseChangeAgentForm';
  date: Date;
  actionType: RA01ActionType;

  // Section registrant
  registrantIfIndividual: IndividualData | null;
  registrantIfCompany: EntityData | null;
  registrantTaxPractitionerNumber: string | null;
  registrantPostalAddress: Address | null;
  registrantStreetAddressSameAsPostal: boolean;
  registrantStreetAddress: Address | null;
  responsiblePerson: Contact | null;
  contactPersonIsResponsiblePerson: boolean;
  contactPerson: Contact | null;

  // Change of Details
  isAddressChange: boolean;
  newPostalAddress: Address | null;
  newStreetAddressSameAsPostal: boolean;
  newStreetAddress: Address | null;
  isResponsiblePersonChange: boolean;
  newResponsiblePerson: Contact | null;
  isContactPersonChange: boolean;
  isNewContactPersonResponsiblePerson: boolean;
  newContactPerson: Contact | null;

  // Cease
  cessationResponsiblePerson: Contact | null;

  constructor(data: Partial<RegisterCeaseChangeAgentForm> = {}) {
    super(data);

    // TODO: Add ChangeType
    this.date = data.date ?? new Date();
    this.actionType = data.actionType!;

    // Section registrant
    this.registrantIfIndividual = data.registrantIfIndividual ?? null;
    this.registrantIfCompany = data.registrantIfCompany ?? null;
    this.registrantTaxPractitionerNumber = data.registrantTaxPractitionerNumber ?? null;
    this.registrantPostalAddress = data.registrantPostalAddress ?? null;
    this.registrantStreetAddressSameAsPostal = data.registrantStreetAddressSameAsPostal ?? true;
    this.registrantStreetAddress = data.registrantStreetAddress ?? null;
    this.responsiblePerson = data.responsiblePerson ?? null;
    this.contactPersonIsResponsiblePerson = data.contactPersonIsResponsiblePerson ?? false;
    this.contactPerson = data.contactPerson ?? null;

    // Change of Details
    this.isAddressChange = data.isAddressChange ?? false;
    this.newPostalAddress = data.newPostalAddress ?? null;
    this.newStreetAddressSameAsPostal = data.newStreetAddressSameAsPostal ?? true;
    this.newStreetAddress = data.newStreetAddress ?? null;
    this.isResponsiblePersonChange = data.isResponsiblePersonChange ?? false;
    this.newResponsiblePerson = data.newResponsiblePerson ?? null;
    this.isContactPersonChange = data.isContactPersonChange ?? false;
    this.isNewContactPersonResponsiblePerson = data.isNewContactPersonResponsiblePerson ?? false;
    this.newContactPerson = data.newContactPerson ?? null;

    // Cease
    this.cessationResponsiblePerson = data.cessationResponsiblePerson ?? null;
  }

  override toDictionary(): { key: string; value: string; }[] {
    const dict = new ChangeDictionaryHelper();

    const addAddressPart = (postalAddress: Address, isSameAddress: boolean, streetAddress: Address) => {
      dict
        .add('Postal Address', postalAddress?.normalizedFullAddress)
        .addYesNo('Is street address the same as the postal address?', isSameAddress)
        .add('Street Address', streetAddress?.normalizedFullAddress);
    };

    const addContactPart = (contact: Contact, key: string) => {
      const fullname = [contact.firstName, contact.lastName].join(' ');
      const responsiblePersonData = [
        fullname,
        contact.email,
        contact.phone
      ].filter(Boolean).join(', ');

      dict.add(key, responsiblePersonData);
    };

    dict.add('Type of Notification', ra01ActionTypeDictionary[this.actionType]);

    if (this.actionType === RA01ActionType.Registration) {
      dict.add('Agent Type', this.registrantIfIndividual ? 'Individual' : 'Company');

      if (this.registrantIfIndividual) {
        dict
          .add('Name', this.registrantIfIndividual.fullName)
          .addDate('Date of Birth', this.registrantIfIndividual.dob)
          .addCountry('Country', this.registrantIfIndividual.birthCountry)
          .add('City', this.registrantIfIndividual.birthCity);
      } else if (this.registrantIfCompany) {
        dict
          .add('Company Name', this.registrantIfCompany.name)
          .addAcn('ACN', this.registrantIfCompany.entityNumber);
      }

      dict
        .addYesNo('Applicant is registered with the Tax Practitioners Board', !!this.registrantTaxPractitionerNumber)
        .addIfValueIsNotEmpty('Registration Number', this.registrantTaxPractitionerNumber);
      addAddressPart(this.registrantPostalAddress!, this.registrantStreetAddressSameAsPostal, this.registrantStreetAddress!);

      if (this.responsiblePerson) {
        addContactPart(this.responsiblePerson, 'Responsible Person');
      }

      if (this.contactPerson) {
        addContactPart(this.contactPerson, 'Responsible Person');
      }
    } else if (this.actionType === RA01ActionType.ChangeOfDetails) {
      if (this.isAddressChange) {
        addAddressPart(this.registrantPostalAddress!, this.registrantStreetAddressSameAsPostal, this.registrantStreetAddress!);
      }
    } else if (this.actionType === RA01ActionType.Cessation && this.cessationResponsiblePerson) {
      dict
        .add('Name', [this.cessationResponsiblePerson.firstName, this.cessationResponsiblePerson.lastName].join(' '))
        .add('Email Address', this.cessationResponsiblePerson.email)
        .add('Phone Number', this.cessationResponsiblePerson.phone);
    }

    return dict.dictionary;
  }

  override fillModal(modalRef: NgbModalRef, document: Document, actualCompany: Company): NgbModalRef {
    modalRef = this.fillModalWithCompany(modalRef, actualCompany, true);
    (modalRef.componentInstance as Ra01RegisterCeaseChangeAgentComponent).companyChangeData.documentId = document.documentId;
    return modalRef;
  }

  override fillModalWithCompany(modalRef: NgbModalRef, company: Company, isEdit: boolean): NgbModalRef {
    const instance = modalRef.componentInstance as Ra01RegisterCeaseChangeAgentComponent;

    instance.isEdit = isEdit;
    instance.formModel = structuredClone(this);
    instance.companyChangeData = new CompanyChangeData(company);

    instance.billingContact = company.billingContact!;
    instance.signingContact = company.signingContact!;

    return modalRef;
  }

  override prepareToRequest(): RegisterCeaseChangeAgentForm {
    return {
      ...this,
      ...super.prepareToRequest(),
      date: DatepickerHelper.buildDateString(this.date),
    };
  }
}

export enum RA01ActionType // Type of notification
{
  Registration = 0,      // Register as an agent
  ChangeOfDetails = 1,   // Change details of an agent
  Cessation = 2          // Cease as an agent
}

export const ra01ActionTypeDictionary: Record<RA01ActionType, string> = {
  [RA01ActionType.Registration]: 'Register as an agent',
  [RA01ActionType.ChangeOfDetails]: 'Change details of an agent',
  [RA01ActionType.Cessation]: 'Cease as an agent',
};

export const ra01ActionTypeOptions: SelectOption[] = Object.entries(ra01ActionTypeDictionary)
  .map(([value, label]) => ({ label, value: +value }));

export const RA01TypesStepsDictionary: Record<string, RegisterCeaseChangeAgentStepsEnum[]> = {
  ['null']: [
    RegisterCeaseChangeAgentStepsEnum.FormDescription,
    RegisterCeaseChangeAgentStepsEnum.NotificationType,
  ],
  [RA01ActionType.Registration]: [
    RegisterCeaseChangeAgentStepsEnum.FormDescription,
    RegisterCeaseChangeAgentStepsEnum.NotificationType,
    RegisterCeaseChangeAgentStepsEnum.RegisterAnAgent,
    RegisterCeaseChangeAgentStepsEnum.AddressDetails,
    RegisterCeaseChangeAgentStepsEnum.ResponsiblePersonAndContactPerson
  ],
  [RA01ActionType.ChangeOfDetails]: [
    RegisterCeaseChangeAgentStepsEnum.FormDescription,
    RegisterCeaseChangeAgentStepsEnum.NotificationType,
    RegisterCeaseChangeAgentStepsEnum.ChangeOfDetails,
  ],
  [RA01ActionType.Cessation]: [
    RegisterCeaseChangeAgentStepsEnum.FormDescription,
    RegisterCeaseChangeAgentStepsEnum.NotificationType,
    RegisterCeaseChangeAgentStepsEnum.CeaseAnAgent,
  ],
};

export const isAgentIndividualOptions: SelectOption[] = [
  { label: 'Individual', value: true },
  { label: 'Company', value: false }
];

export enum RA01ChangeType {
  isAddressChange = 'isAddressChange',
  isResponsiblePersonChange = 'isResponsiblePersonChange',
  isContactPersonChange = 'isContactPersonChange'
}

export const RA01ChangeTypeDictionary: Record<RA01ChangeType, string> = {
  [RA01ChangeType.isAddressChange]: 'Address change',
  [RA01ChangeType.isResponsiblePersonChange]: 'Responsible person change',
  [RA01ChangeType.isContactPersonChange]: 'Contact person change'
};
export const RA01ChangeTypeOptions: SelectOption[] = Object.entries(RA01ChangeTypeDictionary)
  .map(([value, label]) => ({ label, value }));
