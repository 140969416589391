<div
  ngbDropdown
  container="body"
  class="dropdown d-flex gap-4 align-items-center"
  [placement]="['bottom-end', 'bottom-start', 'top-start', 'top-end']"
  (click)="onOpenDropdown($event)"
>
  <span class="email-recipient">{{ emailRecord.to }}</span>

  @if (showRecipientsDropdown) {
    <app-ag-action-icon-button
      iconClass="ic-three-dots"
      ngbDropdownToggle
      (action)="onOpenDropdown($event, true)"
    />

    <div ngbDropdownMenu class="py-3">
      <app-email-recipients [emailRecord]="emailRecord"/>
    </div>
  }
</div>
