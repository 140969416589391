<div class="wrapper">
  @if (autoSendingEnable) {
    <div class="sending-method-label auto-sending">Auto Sending</div>
  }

  @if (sendingMethod !== null) {
    <div class="sending-method-label"
         [class.sms]="sendingMethod === ReminderSendingMethods.sms"
         [class.sms-and-email]="sendingMethod === ReminderSendingMethods.smsAndEmail"
         [class.email]="sendingMethod === ReminderSendingMethods.email"
    >
      {{ ReminderSendingMethodsLabelsRecord[sendingMethod] }}
    </div>
  }
</div>
