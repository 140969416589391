<div class="text-message-template-card">
  <div class="title">{{ headers[textMessageTemplate.code] }}</div>
  @if (isLoading) {
    <div class="message d-flex">
      <app-loader-standalone class="m-auto" customClass="h-100" [loading]="true"/>
    </div>
  } @else {
    <div class="message-edit">
      <app-textarea
        [rows]="currentState() === TextMessagesTemplateComponentState.Edit ? 8 : 13"
        [formControl]="templateControl"
        [customErrors]="customErrors"
        (focus)="onStartEditing()"
      />

      @if (currentState() === TextMessagesTemplateComponentState.Edit) {
        <div class="sms-template-show-keys-button pl-2">
          <div
            #dropdown="ngbDropdown"
            ngbDropdown
            container="body"
            [placement]="['start-top', 'start-bottom']"
          >
            <app-ag-action-icon-button
              ngbDropdownToggle
              iconClass="ic-help-circle"
              (mouseenter)="dropdown.open()"
            />

            <div ngbDropdownMenu class="codes-preview" (mouseleave)="dropdown.close()">
              <app-templates-legend-table [preview]="legendPreview"/>
            </div>
          </div>
        </div>
      }
    </div>

    @if (currentState() === TextMessagesTemplateComponentState.Edit) {
      <div style="padding: 0 8px">
        <app-divider margin="8px 0"/>
      </div>

      @if (keysMatchErrorMessage) {
        <div class="keys-not-match-error-message" [innerHtml]="keysMatchErrorMessage"></div>
      }

      <div class="edit-controls justify-content-between">
        <div class="edit-controls">
          <app-button text="Save" (clickBtn)="onSave()"/>
          <app-button text="Cancel" btnColor="white" (clickBtn)="cancelEditing()"/>
        </div>

        <div class="edit-controls">
          <app-button text="Test" btnColor="white" (clickBtn)="onTestTextMessageTemplate()"/>
          <app-button text="Reset to Default" btnColor="white" (clickBtn)="resetTemplateToDefault()"/>
        </div>
      </div>
    }
  }
</div>
