import { Component } from '@angular/core';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from "@ng-bootstrap/ng-bootstrap";
import { ICellRendererParams } from "ag-grid-community";
import { SignRow } from "../../../../../../../models/signDocuments";
import { AnnualSignGroup, SigningStatus } from "../../../../../../../models/enums/annualStatementEnums";
import { ApproverStatus } from "../../../../../../../models/enums/approverStatus";
import { SignatureStatus } from "../../../../../../../models/enums/signatureStatus";

type ActionEvent = (data: unknown) => void;

type CellRendererParams = ICellRendererParams<SignRow> & {
  edit: ActionEvent,
  delete: ActionEvent,
  resend: ActionEvent,
};

@Component({
  selector: 'app-ag-annual-sign-actions',
  standalone: true,
  imports: [
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle
  ],
  templateUrl: './ag-annual-sign-actions.component.html',
  styleUrl: './ag-annual-sign-actions.component.scss'
})
export class AgAnnualSignActionsComponent {
  protected params!: CellRendererParams;
  protected groupName = '';
  protected rowIndex: number | undefined;
  protected type: AnnualSignGroup | undefined;
  protected disabled = false;
  protected status: ApproverStatus | SigningStatus | SignatureStatus | undefined;
  protected AnnualSignGroup = AnnualSignGroup;
  protected menuActions = [
    { name: 'Delete', iconClass: 'icon ic-trash mr-1', action: () => this.delete() },
    { name: 'Re-send', iconClass: 'icon ic-send mr-1', action: () => this.resend() },
  ];

  agInit(params: CellRendererParams): void {
    this.params = params;
    this.type = params.data?.type;
    this.status = params.data?.status;
    this.disabled = params.data?.disabled ?? false;
    if(this.params.node.rowIndex !== null && this.params.node.rowIndex !== undefined) {
      this.rowIndex = this.params.node.rowIndex;
    }

    if(this.type === AnnualSignGroup.Approver) {
      if(this.status !== ApproverStatus.WaitingForApproval) {
        this.removeMenuActions('Re-send');
      }
    }

    if(this.type === AnnualSignGroup.Signatories) {
      if(this.status !== SigningStatus.Sent) {
        this.removeMenuActions('Re-send');
      }

      this.removeMenuActions('Delete');
    }

    if(this.type === AnnualSignGroup.CarbonCopy) {
      this.removeMenuActions('Re-send');
    }
  }

  refresh(params: CellRendererParams): boolean {
    this.params = params;
    return true;
  }

  removeMenuActions = (name: string) => {
    this.menuActions = this.menuActions.filter(menu => menu.name !== name);
  };

  delete(): void {
    this.params.delete(this.rowIndex);
  }

  resend(): void {
    this.params.resend(this.rowIndex);
  }
}
