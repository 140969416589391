export const formTypesList = [
  'c:106',
  'c:205a',
  'c:361',
  'c:362',
  'c:370',
  'c:410',
  'c:484',
  'c:6010',
  'c:71',
  'c:63',
  'c:492',
  'c:902'
];

// export const formTypesList = [
//   '106',
//   '205A',
//   '361',
//   '362',
//   '370',
//   '410B',
//   '410F',
//   '484',
//   '484 A1',
//   '484 A2',
//   '484 A3',
//   '484 B1',
//   '484 B2',
//   '484 B3',
//   '6010',
//   'RA 71',
//   'RA 63',
//   '492',
//   '902'
// ];
