import { Component, DestroyRef, inject } from '@angular/core';
import { CurrencyPipe, NgClass, SlicePipe } from "@angular/common";
import { ICellRendererParams } from "ag-grid-community";
import { Alert } from "../../../../../../../models/alerts/alert";
import { AlertsService } from "../../../../../../../services/alert.service";
import { observe, Observer } from "fast-json-patch";

type ActionEvent = (data: unknown) => void;

type CellRendererParams = ICellRendererParams<Alert> & {
  openAlert: ActionEvent,
}

@Component({
  selector: 'app-ag-alerts-title',
  standalone: true,
  imports: [
    CurrencyPipe,
    NgClass,
    SlicePipe
  ],
  templateUrl: './ag-alerts-title.component.html',
  styleUrl: './ag-alerts-title.component.scss'
})
export class AgAlertsTitleComponent {
  protected alertService = inject(AlertsService);
  protected destroyRef = inject(DestroyRef);

  observer!: Observer<Alert[]>;

  protected params!: CellRendererParams;
  title = '';
  message = '';
  alerts!: Alert;

  agInit(params: CellRendererParams): void {
    this.params = params;
    if(params.data !== undefined) {
      this.alerts = params.data;
      this.observer = observe(this.alerts);
    }

    this.title = (this.params.value as string) ?? '';
    this.message = params.data?.model?.message ?? '';
  }

  refresh(params: CellRendererParams): boolean {
    this.params = params;
    return true;
  }

  openAlert(): void {
    const data = { alert: this.params.data, index: this.params.node.rowIndex };
    this.params.openAlert(data);
  }

  private updateSingleRow(data: Alert) {
    const rowNodeId = this.params.node.id;
    if (rowNodeId) {
      this.params.node.setData(data);
      this.params.api.refreshServerSide({ route: [rowNodeId] });
    }
  }
}
