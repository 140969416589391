<app-stepper-form
  header="Cease as a Registered Agent"
  [showSteps]="false"
  [steps]="steps"
  [currentStep]="currentStep"
  [currentStepIndex]="currentStepIndex"
  [isLoading]="isLoading"
  [form]="stepperForm"
  (stepIndexChange)="onStepChange($event)"
>
  <div
    class="step-content"
    [formGroup]="stepperForm"
    [ngClass]="{'step-content-error': (currentStep === StepsEnum.Summary && rowHasError)}"
  >
    <app-stepper-form-description
      formKey="361"
      [hidden]="currentStep !== StepsEnum.FormDescription"
    ></app-stepper-form-description>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.RegisteredAgentCeasing"
      [hidden]="currentStep !== StepsEnum.RegisteredAgentCeasing"
    >
      <h1 class="h1-heading">{{ steps[currentStepIndex].label }}</h1>

      <div class="form-group">
        <app-date-picker
          label="Date of Cessation"
          formControlName="changeDate"
          [minDate]="minDate"
          [maxDate]="maxDate"
        ></app-date-picker>
      </div>

      <div class="form-group">
        <app-radio
          formControlName="documentationType"
          [label]="'Choose the type of document to generate'"
          [options]="documentTypeOptions"
        ></app-radio>
      </div>
    </section>

    <section
      class="step-section"
      [formGroup]="form"
      [hidden]="currentStep !== StepsEnum.Summary"
    >
      <div class="change">
        <h2 class="title">Cease as a Registered Agent</h2>

        <app-list-grid
          class="change-grid"
          [rowData]="rows"
          [colDefs]="colDefs"
          [rowClassRules]="rowClassRules"
          [suppressRowClickSelection]="true"
          [pagination]="true"
          [paginationPageSizeSelector]="false"
          [suppressCellFocus]="true"
        ></app-list-grid>
      </div>
    </section>
  </div>
</app-stepper-form>
