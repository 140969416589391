<div class="notification-container" [ngClass]="color">
  <div><i class="{{iconClass}}" [ngClass]="color"></i></div>

  <div class="text-container">
    @if(title) {
      <div class="title mb-2" [ngClass]="color">{{title}}</div>
    }
    <div class="text">
      {{text}}
      <ng-content></ng-content>
    </div>
    @if(amount) {
      @if(isMoneyFormat) {
        <app-divider class="mb-2 mt-2"></app-divider>
        <div class="amount">{{amount | currency : "$" : 'code' : '1.2-2'}}</div>
      } @else {
        <div class="amount">{{amount}}</div>
      }
    }
  </div>
</div>
