import { Injectable } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { defer, from, Observable, retry } from 'rxjs';
import { environment } from '../environments/environment';
import { Address } from '../models/address';

@Injectable()
export class GoogleMapsService {
  static geocoder: google.maps.Geocoder | null = null;

  async loadApi() {
    const loader = new Loader({
      apiKey: environment.google_maps_api_key,
      libraries: ['places'],
    });

    await defer(() => from(loader.load()))
      .pipe(retry(3))
      .toPromise()
      .then(
        () => {
          GoogleMapsService.geocoder = new google.maps.Geocoder();
        },
        (error) => {
          console.warn('[GoogleMapsService] Failed to load Google Maps API: ', error);
        }
      );
  }

  searchAddress(address: string, country: string): Observable<Address[]> {
    const componentRestrictions = { country };

    return from(
      GoogleMapsService.geocoder!.geocode({
        address,
        language: 'en-Au',
        componentRestrictions: country ? componentRestrictions : undefined

      }).then(
        ({ results }) => results.map(address => this.parseAddress(address, country)),
        (err) => {
          console.warn('oops: ', err);
          return [];
        }
      )
    );
  }

  parseAddress(geocoderResult: google.maps.GeocoderResult, country: string): Address {
    const address = new Address();
    address.normalizedFullAddress = geocoderResult.formatted_address;
    address.country = country;
    const route = geocoderResult.address_components.find(component => component.types.includes('route'))?.long_name ?? '';
    const street = geocoderResult.address_components.find(component => component.types.includes('street_number'))?.long_name ?? '';

    if (geocoderResult.address_components) {
      geocoderResult.address_components.forEach(component => {
        if (component.types.includes('locality')) {
          address.suburb = component.long_name;
        } else if (component.types.includes('administrative_area_level_1')) {
          address.state = component.short_name;
        } else if (component.types.includes('postal_code')) {
          address.postcode = component.long_name;
        }
      });
    }

    address.street = `${ route } ${ street }`.trim();

    return address;
  }
}
