import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Company } from '../../../models/company';
import { Document } from '../../../models/document';
import { EntityChangeData } from '../../../models/entityChangeData';
import { CompanyChangeData } from '../../../models/enums/companyChangeData';
import { SelectOption } from '../../../models/selectOptions';
import { ChangeDictionaryHelper } from '../../../models/shared/change-dictionary-helper.model';
import { Form280Component } from './form280.component';

export enum ShareBuyBackStepsEnumSteps {
  FormDescription = 0,
  TheChange = 1,
}

export class CompanyForm280 extends EntityChangeData {
  static override readonly $type = 'CompanyForm280';
  buyBackType: BuyBackType;
  attachedDocumentType: BuyBackAttachedDocumentType;

  constructor(data: Partial<CompanyForm280> = {}) {
    super(data);

    // TODO: Add ChangeType
    this.buyBackType = data.buyBackType ?? BuyBackType.employee;
    this.attachedDocumentType = data.attachedDocumentType ?? BuyBackAttachedDocumentType.approval;
  }

  override toDictionary(): { key: string; value: string; }[] {
    const dict = new ChangeDictionaryHelper();

    dict
      .addDate('Date of Change', this.changeDate)
      .add('Type of share buy-back', buyBackTypeDictionary[this.buyBackType] ?? '')
      .add('Documents attached to this form', buyBackDocumentTypeDictionary[this.attachedDocumentType] ?? '');

    return dict.dictionary;
  }

  override fillModal(modalRef: NgbModalRef, document: Document, actualCompany: Company): NgbModalRef {
    modalRef = this.fillModalWithCompany(modalRef, actualCompany, true);
    (modalRef.componentInstance as Form280Component).companyChangeData.documentId = document.documentId;
    return modalRef;
  }

  override fillModalWithCompany(modalRef: NgbModalRef, company: Company, isEdit: boolean): NgbModalRef {
    const instance = modalRef.componentInstance as Form280Component;

    instance.isEdit = isEdit;
    instance.formModel = structuredClone(this);
    instance.companyChangeData = new CompanyChangeData(company);

    return modalRef;
  }
}

export enum BuyBackType {
  employee = 0,
  market = 1,
  equal_within = 2,
  equal_over = 3,
  selective = 4
}

export const buyBackTypeDictionary: Record<BuyBackType, string> = {
  [BuyBackType.employee]: 'Employee share scheme buy-back (over 10/12 limit)',
  [BuyBackType.market]: 'On-market buy-back(over 10/12 limit)',
  [BuyBackType.equal_within]: 'Equal access scheme buy-back (within 10/12 limit)',
  [BuyBackType.equal_over]: 'Equal access scheme buy-back (over 10/12 limit)',
  [BuyBackType.selective]: 'Selective buy-back'
};

export const buyBackTypeOptions: SelectOption[] = Object.entries(buyBackTypeDictionary)
  .map(([value, label]) => ({ label, value: +value }));

export enum BuyBackAttachedDocumentType {
  approval = 0,
  selective = 1,
  equal = 2
}

export const buyBackDocumentTypeDictionary: Record<BuyBackAttachedDocumentType, string> = {
  [BuyBackAttachedDocumentType.approval]: 'Shareholder approval if the 10/12 limit exceeded (s257C(3))',
  [BuyBackAttachedDocumentType.selective]: 'Selective buy-back (s257D(3))',
  [BuyBackAttachedDocumentType.equal]: 'Equal access scheme buy-back and selective buy-back (s257E)'
};

export const buyBackDocumentTypeOptions: SelectOption[] = Object.entries(buyBackDocumentTypeDictionary)
  .map(([value, label]) => ({ label, value: +value }));
