<div class="modal-header">
  <div class="title-container">
    <div class="icon-container">
      <i class="icon ic-download-file"></i>
    </div>
    <div class="title-wrapper">
      <div class="title">{{title}}</div>
      <div class="sub-title">{{numberOfCompanies}} {{subTitle}}</div>
    </div>
  </div>
  <div class="close-btn" (click)="onClose()">
    <i class="icon ic-xs ic-close"></i>
  </div>
</div>

<div class="modal-body">
  <div class="text">Select the columns you wish to export. The data will be exported in {{exportType}} format.</div>
  <div class="column-all">
    <app-checkbox
      [label]="'Select All Columns'"
      [value]="selectAll"
      (change)="onSelectAllChange($event)"
    ></app-checkbox>
  </div>

  <app-divider [margin]="'16px 0'"></app-divider>

  <div class="column-container">
    @for (column of colDefs; track $index) {
      @if (column.headerName && column?.field) {
        <div class="column-name d-flex align-items-start">
          <app-checkbox
            [label]="column.headerName"
            [value]="!column.hide"
            [disabled]="disableSelection() && !column.hide"
            (change)="onCheckboxChange($index)"
          ></app-checkbox>
        </div>
      } @else if(column.headerName && column?.children?.length) {
        <div>
          <div class="column-name d-flex align-items-center">
            <app-checkbox
              [label]="column.headerName"
              [value]="!column.hide"
              [disabled]="disableSelection() && !column.hide"
              (change)="onGroupCheckboxChange(column)"
            ></app-checkbox>
          </div>

          @for(child of column?.children; track childIndex; let childIndex = $index) {
            <div class="column-name d-flex align-items-center">
              <i class="icon ic-minus mr-1"></i>
              <app-checkbox
                [label]="child?.headerName || ''"
                [value]="!$any(child)?.hide"
                [disabled]="disableSelection() && !$any(child)?.hide"
                (change)="onGroupChildCheckboxChange(column, childIndex)"
              ></app-checkbox>
            </div>
          }
        </div>
      }
    }
  </div>
</div>

<div class="modal-footer">
  <app-button [text]="'Export'" (click)="onExport()" [isDisabled]="!numberSelectedColumns"></app-button>
  <app-button [text]="'Cancel'" [btnColor]="'white'" (click)="onClose()"></app-button>
</div>
