import { inject, Injectable, signal } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../environments/environment";
import { IServerSideGetRowsRequest } from 'ag-grid-enterprise';
import { PageResult } from "../models/pageResult";
import { AnnualRecord } from "../models/annualRecord";
import { map, Observable } from "rxjs";
import { reorderGridFilterModel } from "../app/helpers/reorder-grid-filter";
import { AnnualStatement } from "../models/annualStatement";
import { AnnualStatementPageFilter, SigningStatus } from "../models/enums/annualStatementEnums";
import {
  ExcelRow,
  ExcelStyle,
  IServerSideDatasource,
  ProcessCellForExportParams,
  ProcessHeaderForExportParams
} from "ag-grid-community";
import { CurrencyPipe, formatDate } from "@angular/common";
import { Tag } from "../models/tag";
import { ColumnWithExportName } from "../models/columnWithExportName";
import { ACNPipe } from "../pipes/acnPipe";
import { generate, Observer, Operation } from "fast-json-patch";
import { filterPatchOperations } from "../functions/filter-patch-operations";
import { DocumentToAttach } from "../models/files";

@Injectable({
  providedIn: 'root'
})
export class AnnualStatementsService {
  private acnPipe = inject(ACNPipe);
  private currencyPipe = inject(CurrencyPipe);

  patchLoading = signal(false);
  sendSignLoading= signal(false);
  disabledHeaderBtn = signal(false);
  showVoidEnvelope = signal(false);
  showSendEmailBtn= signal(false);
  showSendPaperBtn = signal(false);
  documentsToAttach = signal<DocumentToAttach[]>([]);
  pageFilter = signal<AnnualStatementPageFilter>(0);
  numberColumnForExport = signal(0);

  constructor(private api: HttpClient) { }

  public getAnnualList(request: IServerSideGetRowsRequest, pageFilter: AnnualStatementPageFilter, search?: string, startRow?:number, endRow?: number): Observable<PageResult<AnnualRecord>> {
    request = reorderGridFilterModel(request);

    if(startRow !== null && startRow !== undefined) {
      request.startRow = startRow;
    }

    if(endRow) {
      request.endRow = endRow;
    }

    let params = new HttpParams();
    if (search) {
      params = params.append('search', search);
    }

    return this.api.post<PageResult<AnnualRecord>>(
      `${environment.api_url}/annual-statements/grid`,
      {...request, pageFilter },
      { params: params });
  }

  public getAnnualById(id: string): Observable<AnnualStatement> {
    return this.api.get<AnnualStatement>(`${environment.api_url}/annual-statements/${id}`);
  }

  public patchAnnual(id: string, observer: Observer<AnnualStatement>) {
    const filteredPatchOperations: Operation[] = observer ? filterPatchOperations(generate(observer)) : [];

    return this.api.patch<AnnualStatement>(`${environment.api_url}/annual-statements/${id}`, filteredPatchOperations, { headers: { 'Content-Type': 'application/json-patch+json' } })
      .pipe(map((data) => new AnnualStatement(data)));
  }

  public patchAnnualOperation(id: string, operation: Operation[]) {
    const filteredPatchOperations: Operation[] = operation?.length ? filterPatchOperations(operation) : [];

    return this.api.patch<AnnualStatement>(`${environment.api_url}/annual-statements/${id}`, filteredPatchOperations, { headers: { 'Content-Type': 'application/json-patch+json' } })
      .pipe(map((data) => new AnnualStatement(data)));
  }

  public annualGenerate(body: unknown): Observable<string> {
    return this.api.post<string>(`${environment.api_url}/annual-statements/generate`, body);
  }

  public annualRegenerate(companyId: string): Observable<string> {
    return this.api.post<string>(`${environment.api_url}/annual-statements/${companyId}/regenerate`, { id: companyId });
  }

  public annualBulk(ids: string[]): Observable<string> {
    return this.api.post<string>(`${environment.api_url}/annual-statements/bulk`, { ids });
  }

  public exportParams() {
    const acnPipe = this.acnPipe;
    const currencyPipe = this.currencyPipe;

    return {
      processCellCallback(params: ProcessCellForExportParams): string {
        const value: unknown = params.value;
        const annualData = params.node?.data as AnnualRecord;

        if (params.column.getColId() === 'companyName') {
          return annualData.companyName;
        } if (params.column.getColId() === 'acn') {
          return acnPipe.transform(annualData.acn);
        } if (params.column.getColId() === 'status') {
          return AnnualStatementPageFilter[annualData.status];
        } if (params.column.getColId() === 'reviewDate') {
          return formatDate(annualData.reviewDate ?? '', 'dd MMM yyyy', 'en-US');
        } if (params.column.getColId() === 'paymentDeadline') {
          return formatDate(annualData.paymentDeadline ?? '', 'dd MMM yyyy', 'en-US');
        } if (params.column.getColId() === 'debt') {
          return currencyPipe.transform(annualData.debt,'$','code','1.2-2') ?? '';
        } else if (params.column.getColId() === 'tags') {
          return (value as Tag[])?.map((tag: Tag) => tag.name)?.join(', ');
        } else if (params.column.getColId() === 'signingStatus') {
          return SigningStatus[annualData.signingStatus];
        }

        return value as string;
      },
      processHeaderCallback(params: ProcessHeaderForExportParams) {
        const colDef = params.column.getColDef() as ColumnWithExportName;
        return colDef?.exportColumnName ?? colDef.headerName;
      },
      sheetName: 'Annual statements',
    }
  }

  exportParamsXls() {
    return {
      ...this.exportParams(),
      prependContent: this.getExcelTitleRows(this.numberColumnForExport())
    };
  }

  public getExcelTitleRows: (numberColumnForExport: number) => ExcelRow[] = (numberColumnForExport) => [
    {
      cells: [
        {
          data: {
            value: 'Annual Statements Report',
            type: "String",
          },
          mergeAcross: numberColumnForExport,
          styleId: 'titleStyle',
        },
      ],
    },
    {
      cells: [
        {
          data: {
            value: formatDate(new Date, 'dd/MM/yyyy', 'en-US'),
            type: "String",
          },
          mergeAcross: numberColumnForExport,
          styleId: 'dateStyle',
        },
      ],
    },
  ];

  public readonly excelStyles: ExcelStyle[] = [
    {
      id: 'titleStyle',
      font: {
        size: 18,
        color: '#FFC82C',
        bold: true,
      },
      alignment: {
        horizontal: 'Center',
      },
    },
    {
      id: 'dateStyle',
      font: {
        size: 14,
        color: '#FFC82C',
      },
      alignment: {
        horizontal: 'Center',
      },
    },
    {
      id: "header",
      interior: {
        color: "#aaaaaa",
        pattern: "Solid",
      },
      font: {
        size: 11,
        bold: true,
      },
    },
  ];
}
