import { Component, inject, Input, OnInit } from '@angular/core';
import { CommonModalFormComponent } from "../../../../../modals/common-modal-form/common-modal-form.component";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { EmailTemplatesService } from "../../../../../../services/email-templates.service";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { InputComponent } from "../../../../../components/common/input/input.component";
import { EmailSendRequest } from "../../../../../../models/email-templates/emailSendRequest";

@Component({
  selector: 'app-send-email-modal',
  standalone: true,
  providers: [EmailTemplatesService],
  imports: [
    ReactiveFormsModule,
    InputComponent,
    CommonModalFormComponent,
  ],
  templateUrl: './send-email-modal.component.html',
})
export class SendEmailModalComponent implements OnInit {
  private emailTemplateService = inject(EmailTemplatesService);
  private activeModal = inject(NgbActiveModal);

  @Input() emailByDefault = '';
  @Input() emailSendRequest!: EmailSendRequest;

  isLoading = false;
  emailControl = new FormControl<string | null>(null, [Validators.email]);

  ngOnInit(): void {
    this.activeModal.update({ modalDialogClass: 'common-modal-form-dialog' });
    this.emailControl.setValue(this.emailByDefault);
  }

  confirm(): void {
    if (this.emailControl.invalid) {
      this.emailControl.markAllAsTouched();
      return;
    }

    this.emailSendRequest.to = this.emailControl.value ?? '';

    this.isLoading = true;
    this.emailControl.disable();

    this.emailTemplateService.sendEmailTemplate(this.emailSendRequest)
      .subscribe({
        next: () => this.activeModal.close(),
        error: () => {
          this.emailControl.enable();
          this.isLoading = false;
        }
      });
  }

  close(): void {
    this.activeModal.dismiss();
  }
}
