<div class="document-changes-preview-container">
  <div class="title-container">
    <h3>Changes Preview</h3>
    <div class="text">Your document is now ready for submission. Before proceeding, kindly preview it to ensure all details are accurate.</div>
  </div>

  <div class="changes-warning-container">
    <div><i class="icon ic-md ic-alert-triangle"></i></div>
    <div class="text-container">
      <div class="title">Changes</div>
      <div class="text">Please note that any user-provided changes will require regenerating the entire document to ensure accuracy, consistency, and compliance with the latest information.</div>
    </div>
  </div>

  @if (loading) {
    <div class="item skeleton-container">
      <ngx-skeleton-loader count="1" appearance="line" />
      <div class="d-flex gap-24">
        <ngx-skeleton-loader style="width: 50%" count="3" appearance="line" />
        <ngx-skeleton-loader style="width: 50%" count="3" appearance="line" />
      </div>
      <ngx-skeleton-loader count="1" appearance="line" />
      <div class="d-flex gap-24">
        <ngx-skeleton-loader style="width: 50%" count="3" appearance="line" />
        <ngx-skeleton-loader style="width: 50%" count="3" appearance="line" />
      </div>
    </div>
  }

  @if (!loading && document) {
    @for (change of documentChangesPreviews; let index = $index; track change) {
      <app-document-changes-preview-details
        [change]="change"
        [showChevron]="documentChangesPreviews.length > 1"
        (toggleDocumentChangeState)="toggleDocumentChangeState(index)"
        (downloadPreview)="downloadPreview(index)"
        (editDocumentChange)="onEditDocumentChange(index)"
      ></app-document-changes-preview-details>
    }
  }
</div>
