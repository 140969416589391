import { booleanAttribute, Component, inject, Input, OnInit } from '@angular/core';
import { Relationship } from "../../../../models/relationship";
import { SelectOption } from "../../../../models/selectOptions";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import {
  DatePickerFormValidators,
  DatepickerHelper
} from "../../../../custom-form-validators/date-picker-form-validators";
import { Document } from "../../../../models/document";
import {
  CompanyChangePartyNameStepsWithSummaryEnum
} from "../../documents/asic-forms/484-forms/a2-company-member-name-change/a2-company-change-party-name.component";
import { RelationshipType } from '../../../../models/enums/relationshipTypeEnum';
import { ChangesAppliedComponent } from "../../stepper-form/pages/changes-applied/changes-applied.component";
import { DatePickerComponent } from "../../../components/common/date-picker/date-picker.component";
import { InputComponent } from "../../../components/common/input/input.component";
import { SelectComponent } from "../../../components/common/select/select.component";
import { StepperFormComponent } from "../../stepper-form/stepper-form.component";
import {
  StepperFormDescriptionComponent
} from "../../stepper-form/stepper-form-description/stepper-form-description.component";
import { MultipleInputComponent } from "../../../components/common/multiple-input/multiple-input.component";
import { RadioComponent } from "../../../components/common/radio/radio.component";
import {
  DocumentationType,
  MeetingLocationType,
  MinutesMeetingPresence
} from "../../../../models/enums/documentConfirmEnums";
import { ColDef, RowClassRules } from "ag-grid-community";
import {
  AgEntityNameAcnAbnComponent
} from "../../../components/common/grid/components/ag-entity-name-acn-abn/ag-entity-name-acn-abn.component";
import { ListGridComponent } from "../../../components/common/grid/components/list-grid/list-grid.component";
import { NgClass } from "@angular/common";
import { BaseBulkStepperFormComponent } from "../../stepper-form/base-bulk-stepper-form/base-bulk-stepper-form.component";
import { BulkCompanyChangeName } from "./BulkCompanyChangeName.model";
import { EntityChangeData } from "../../../../models/entityChangeData";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ChangeAuthorisation, MinutesMeetingAttendee } from "../../../../models/changeAuthorisation";
import { CompaniesService } from "../../../../services/companies.service";
import { catchError, debounceTime, forkJoin, of } from "rxjs";
import { OfficeHoldersByCompany } from "../../../../models/bulkChangeData";
import { BulkAddressChangeStepsEnum } from "../../../../models/enums/bulkAddressChangeStepsEnum";
import {
  AgSelectChangeNameComponent
} from "../../../components/common/grid/components/ag-select-change-name/ag-select-change-name.component";
import { IndividualData } from "../../../../models/individualData";
import { parseFullName } from "../../../../functions/parse-fullname";
import { CustomFormValidators } from "../../../../custom-form-validators/custom-form-validators";
import { directorFullNameValidatorMsg } from "../../../../validators/validatorMessages/custom-form-validators-messages";

interface AppliedChanges {
  name: string;
  acn?: string;
  abn?: string;
  fullName: string;
  newFullName: string;
  selectedOption: SelectOption;
  options: SelectOption[];
  error: string;
}

@Component({
  selector: 'app-bulk-change-name-form',
  standalone: true,
  imports: [
    ChangesAppliedComponent,
    DatePickerComponent,
    InputComponent,
    ReactiveFormsModule,
    SelectComponent,
    StepperFormComponent,
    StepperFormDescriptionComponent,
    MultipleInputComponent,
    RadioComponent,
    FormsModule,
    ListGridComponent,
    NgClass
  ],
  templateUrl: './bulk-change-name-form.component.html',
  styleUrls: ['./bulk-change-name-form.component.scss', '../../stepper-form/base-bulk-stepper-form/base-bulk-stepper-form.component.scss']
})
export class BulkChangeNameFormComponent extends BaseBulkStepperFormComponent<CompanyChangePartyNameStepsWithSummaryEnum, BulkCompanyChangeName> implements OnInit {
  companiesService = inject(CompaniesService);

  @Input() officers: Record<string, Relationship[]> = {};
  @Input() officersChangeNameOptions: SelectOption[] = [];
  @Input({ transform: booleanAttribute }) set hasSummary(value: boolean) {
    if (value) {
      this.setupSteps(CompanyChangePartyNameStepsWithSummaryEnum);
    }
  }

  override readonly StepsEnum = CompanyChangePartyNameStepsWithSummaryEnum;
  readonly RelationshipType = RelationshipType;
  protected readonly directorFullNameValidatorMsg = directorFullNameValidatorMsg;
  relationships: Relationship[] = [];
  relationshipIds: string[] = [];
  officeHoldersByCompany: OfficeHoldersByCompany[] = [];
  changeAuthorisationByCompany: { companyId: string, changeAuthorisation: ChangeAuthorisation }[] = [];
  trustOnly = false;
  readonly abnLength = 11;
  readonly acnLength = 9;

  minDate!: NgbDateStruct;
  maxDate!: NgbDateStruct;

  public rowClassRules: RowClassRules = {
    'error-row-bg': (params: { data: { error: string }}) => !!params.data.error,
  };

  documentTypeOptions: SelectOption[] = [
    { label: 'Minutes', value: DocumentationType.Minutes },
    { label: 'Resolution', value: DocumentationType.Resolution },
    { label: 'ASIC Form only', value: DocumentationType.FormOnly },
  ];

  colDefs: ColDef[] = [
    {
      headerName: 'Entity Name',
      field: 'name',
      sort: 'asc',
      flex: 1,
      cellRenderer: AgEntityNameAcnAbnComponent,
      menuTabs: []
    },
    {
      headerName: 'Authorising Officeholder',
      field: 'fullName',
      flex: 1.2,
      cellRenderer: AgSelectChangeNameComponent,
      cellRendererParams: {
        selectName: this.selectName.bind(this),
      },
      menuTabs: []
    },
  ];
  private readonly colDefsWithErrorLength = 3;

  rowHasError = false;
  rows: AppliedChanges[] = [];
  selectedOfficeholder = { entityId: '', selectedId: '' };

  form = new FormGroup({
    changeDate: new FormControl<Date | null>(DatepickerHelper.getToday(), [Validators.required, DatePickerFormValidators.dateFormatValidator]),
    name: new FormControl<string | null>(null,[Validators.required]),
    newName: new FormControl<string | null>(null, [Validators.required, CustomFormValidators.directorFullNameValidator]),
    documentationType: new FormControl<DocumentationType>(DocumentationType.Minutes, [Validators.required]),
    changeDateError: new FormControl<boolean | null>(false, [Validators.required])
  });

  override stepperForm = new FormGroup({
    [CompanyChangePartyNameStepsWithSummaryEnum.FormDescription]: new FormGroup({}),
    [CompanyChangePartyNameStepsWithSummaryEnum.CompanyMemberName]: this.form,
    [CompanyChangePartyNameStepsWithSummaryEnum.Summary]: this.form
  });

  constructor() {
    super();
    this.setupSteps(CompanyChangePartyNameStepsWithSummaryEnum);
  }

  ngOnInit(): void {
    this.minDate = DatepickerHelper.getDefaultMinDateStruct();
    this.maxDate = DatepickerHelper.getTodayStruct();

    this.listenChangeDate();
    this.listenChangeNewName();
    this.setupChange();
  }

  override afterSubmit(changes: EntityChangeData[]): void {
    this.setupChange(changes[0] as BulkCompanyChangeName);
  }

  override setupChange(change: BulkCompanyChangeName = this.formModel): void {
    this.relationships = change.relationships;
    this.relationshipIds = change.relationshipIds;
    this.form.updateValueAndValidity();
    this.form.controls.name.setValue(this.relationships[0].individualDataOverride?.fullName ?? '');
    this.trustOnly = this.relationships.every(relationship => relationship.entity?.entityNumber?.length === this.abnLength);
    if(this.trustOnly) {
      this.form.get('documentationType')?.patchValue(DocumentationType.Default);
    }
    this.getOfficeHoldersByCompany();
  }

  override buildDocument(): Document[] | null {
    const newName = this.form.get('newName')?.value ?? '';
    const namesList = parseFullName(newName);

    const bulkCompanyChangeNames = this.officeHoldersByCompany.map(item => {
      return new BulkCompanyChangeName({
        ...namesList,
        description: `Name changed to ${item.officeHolders[0].relationship.entity?.name ?? ''}`,
        changeDate: this.form.value.changeDate!,
        relationshipIds: this.relationships.filter(r => r.entityId === item.companyId).map(r => r.relationshipId)
      });
    });

    try {
      return this.officeHoldersByCompany.map(((item, index) => {
        let documentationType = this.form.get('documentationType')?.value ?? DocumentationType.Default;
        const changeAuthorisation = new ChangeAuthorisation();
        const relationshipOfficeHolders = item.officeHolders.map(item => {
          if(item.relationship.individualDataOverride && this.relationshipIds.includes(item.relationship.relationshipId)) {
            item.relationship.individualDataOverride = new IndividualData({ ...item.relationship.individualDataOverride, ...namesList });
          }

          return item.relationship;
        });
        const signingContact = item.officeHolders.find(item => item.isSigningContact)?.relationship;
        const directors = relationshipOfficeHolders.filter(o => o.type === RelationshipType.Director);
        const authOfficeHolder = directors[0];
        changeAuthorisation.authorisingParty = signingContact ? signingContact : authOfficeHolder;
        changeAuthorisation.signingManager = changeAuthorisation.authorisingParty.relationshipId;

        changeAuthorisation.documentationType = documentationType ?? DocumentationType.Default;
        if(directors.length === 1) {
          documentationType = DocumentationType.Resolution;
          changeAuthorisation.documentationType = documentationType;
        }

        if(documentationType === DocumentationType.Resolution || documentationType === DocumentationType.FormOnly) {
          changeAuthorisation.authorisingParty = signingContact ? signingContact : authOfficeHolder;
          changeAuthorisation.signingManager = changeAuthorisation.authorisingParty.relationshipId;
          changeAuthorisation.authorisingParty.entityId = item.companyId;
        } else if (documentationType === DocumentationType.Minutes) {
          changeAuthorisation.authorisingParty = signingContact ? signingContact : authOfficeHolder;
          changeAuthorisation.signingManager = changeAuthorisation.authorisingParty.relationshipId;
          changeAuthorisation.chairperson = signingContact ? signingContact : authOfficeHolder;
          changeAuthorisation.authorisingParty.entityId = item.companyId;
          changeAuthorisation.attendees = directors.map(director => {
            return {
              individual: director.individualDataOverride,
              presence: MinutesMeetingPresence.PresentPhysically
            }
          }) as MinutesMeetingAttendee[];

          const meetingDate = this.form.value.changeDate?.toISOString().split('T')[0];
          changeAuthorisation.meetingDate = meetingDate ? meetingDate as unknown as Date : undefined;
          changeAuthorisation.meetingAddressType = MeetingLocationType.Registered;
          changeAuthorisation.meetingAddress = authOfficeHolder.entity?.registeredAddress;
        }

        if(this.selectedOfficeholder.selectedId && changeAuthorisation.authorisingParty.entityId === this.selectedOfficeholder.entityId) {
          changeAuthorisation.signingManager = this.selectedOfficeholder.selectedId;
        }

        delete changeAuthorisation.authorisingParty.entity;
        delete changeAuthorisation.chairperson?.entity;

        return new Document({
          changes: [bulkCompanyChangeNames[index] as EntityChangeData],
          changeAuthorisation,
          entityId: item.companyId,
          type: 'c:484',
          documentId: this.companyChangeData?.documentId,
          documentationType
        });
      }));
    } catch (error) {
      console.warn(error);
      this.toastr.error('Failed to create Document.', 'Error');
      return null;
    }
  }

  private getOfficeHoldersByCompany(): void {
    const companyIds: string[] = Array.from(new Set(this.relationships
      .map(item => item.entity?.entityId ?? '')
      .filter(id => id)));
    const getOfficeHolders = companyIds.map(companyId => this.companiesService.officeHolders(companyId));

    forkJoin(getOfficeHolders)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        catchError(error => {
          console.error('Error getting office holders:', error);
          return of([] as { isSigningContact: boolean, relationship: Relationship }[][]);
        })
      )
      .subscribe(officeHolders => {
        if(officeHolders.length) {
          this.officeHoldersByCompany = officeHolders.map((item, index) => {
            return ({ companyId: companyIds[index], officeHolders: item });
          });

          this.officeHoldersByCompany.forEach((officeHolder, index) => {
            const changeAuthorisation = new ChangeAuthorisation();
            const relationshipOfficeHolders = officeHolder.officeHolders.map(item => item.relationship);
            const signingContact = officeHolder.officeHolders.find(item => item.isSigningContact)?.relationship;
            const directors = relationshipOfficeHolders.filter(o => o.type === RelationshipType.Director);
            const authOfficeHolder = directors[0];
            changeAuthorisation.authorisingParty = signingContact ? signingContact : authOfficeHolder;
            changeAuthorisation.signingManager = changeAuthorisation.authorisingParty.relationshipId;

            const foundOfficeHolder = this.relationships.find(relationship => relationship.entityId === officeHolder.companyId);
            this.rows.push({
              name: foundOfficeHolder?.entity?.name ?? '',
              fullName: foundOfficeHolder?.individualDataOverride?.fullName ?? '',
              acn: foundOfficeHolder?.entity?.entityNumber,
              newFullName: '',
              selectedOption: { label: changeAuthorisation.authorisingParty.individualDataOverride?.fullName ?? '', value: changeAuthorisation.authorisingParty.relationshipId },
              options: directors.map(director => ({ label: director.individualDataOverride?.fullName ?? '', value: director.relationshipId })),
              error: ''
            });

            const changeDate = this.form.get('changeDate')?.value as unknown as Date;
            if(relationshipOfficeHolders[0].entity?.dateOfEstablishment) {
              this.rows[index].error = changeDate <= new Date(relationshipOfficeHolders[0].entity?.dateOfEstablishment)
                ? 'Date of change cannot be earlier or same as the company registration date'
                : '';
            }
          });

          this.rows = [...this.rows];
        }
      });
  }

  selectName(data: {  entityId: string, selectedId: string }): void {
    this.selectedOfficeholder = data;
  }

  onStepChange(stepIndex: BulkAddressChangeStepsEnum): void {
    if(stepIndex === BulkAddressChangeStepsEnum.Summary) {
      if(this.form.get('changeDateError')?.value) {
        this.form.get('changeDateError')?.patchValue(null);
      }
    }
  }

  private addErrorToRows(): void {
    this.rowHasError = this.rows.some(row => row.error);

    if (this.rowHasError && !this.colDefs.some(c => c.field === 'error')) {
      this.colDefs.push({
        headerName: 'Error',
        field: 'error',
        flex: 1.2,
        cellRenderer: (params: { value: string }) => {
          const error = params.value;
          if (error) {
            return `<div style="color: #CE5252; line-height: 2px; padding-top: 8px;">${error.slice(0, Math.floor(error.length / 2))}</div>
                    <div style="color: #CE5252;">${error.slice(Math.floor(error.length / 2), error.length)}</div>`;
          } else {
            return `<div>${error}</div>`;
          }
        },
        menuTabs: []
      });
    } else if(!this.rowHasError && this.colDefs.length === this.colDefsWithErrorLength) {
      this.colDefs.pop();
    }

    this.colDefs = [...this.colDefs];

    if(this.rows.some(row => row.error)) {
      this.form.get('changeDateError')?.setValue(true);
    } else {
      this.form.get('changeDateError')?.setValue(false);
    }
  }

  private listenChangeDate(): void {
    this.form.controls.changeDate.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(changeDate => {
        if(changeDate) {
          this.officeHoldersByCompany.map((officeHolder, index) => {
            const relationshipOfficeHolders = officeHolder.officeHolders.map(item => item.relationship);

            if(relationshipOfficeHolders[0].entity?.dateOfEstablishment) {
              this.rows[index].error = changeDate <= new Date(relationshipOfficeHolders[0].entity?.dateOfEstablishment)
                ? 'Date of change cannot be earlier or same as the company registration date'
                : '';
            }
          });

          this.rows = [...this.rows];
          this.addErrorToRows();
        }
      });
  }

  private listenChangeNewName(): void {
    this.form.get('newName')?.valueChanges.pipe(
      debounceTime(300),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(newName => {
      if(newName) {
        this.rows.forEach(row => {
          row.newFullName = newName;
          row.options.forEach((o: SelectOption) => {
            if(this.relationshipIds.includes(o.value as string)) {
              o.label = newName;
              row.selectedOption = o;
            }
          });
        });

        this.rows = [...this.rows];
      }
    });
  }
}
