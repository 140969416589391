import { Component, ElementRef, inject, Input, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { SafeHtml } from "@angular/platform-browser";

@Component({
  selector: 'app-shadow-dom-viewer',
  standalone: true,
  template: `<div #htmlContentWrapper [innerHtml]="htmlContent"></div>`,
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class ShadowDomViewerComponent {
  private renderer = inject(Renderer2);

  @ViewChild('htmlContentWrapper') htmlContentWrapper!: ElementRef;

  @Input() customStyle = '';
  @Input('htmlContent') set htmlContentSetter(value: SafeHtml | string | null) {
    this.htmlContent = value;
    this.addStyles();
  }

  htmlContent: SafeHtml | string | null = null;

  addStyles(): void {
    setTimeout(() => {
      const styleTag = this.renderer.createElement('style') as HTMLStyleElement;
      styleTag.innerHTML = this.customStyle;
      this.renderer.appendChild(this.htmlContentWrapper?.nativeElement, styleTag);
    });
  }
}
