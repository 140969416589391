<app-common-modal-form
  header="Send Test Email"
  confirmText="Send"
  closeText="Cancel"
  icon="ic-mail"
  [isLoading]="isLoading"
  (close)="close()"
  (confirm)="confirm()"
>
  <div style="padding-bottom: 15px">
    <app-input label="Test email will be sent to:" [formControl]="emailControl"/>
  </div>
</app-common-modal-form>
