import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  standalone: true,
  name: 'abn'
})
export class ABNPipe implements PipeTransform {
  transform(value?: string): string {
    if(value == null)
      return '';

    let str = value.replaceAll(' ', '').replaceAll('\xa0', '');

    if (/^[0-9]+$/.test(str) && str.length <= 11) {
        return `${str.substring(0, 2)}\xa0${str.substring(2, 5)}\xa0${str.substring(5, 8)}\xa0${str.substring(8, 11)}`;
    }

    return '-';
  }
}
