<app-common-modal-form
  header="Send Test SMS"
  confirmText="Send"
  closeText="Cancel"
  icon="ic-message"
  [isLoading]="isLoading"
  (close)="close()"
  (confirm)="confirm()"
>
  <div style="padding-bottom: 15px">
    <app-input-phone-number
      label="Test SMS will be sent to:"
      [formControl]="phoneControl"
    />
  </div>
</app-common-modal-form>
