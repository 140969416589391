<app-common-modal-wrapper
  closeBtnText="Cancel"
  saveBtnText="Save"
  header="Edit Agent Profile"
  icon="ic-edit"
  [formGroup]="form"
  [isLoading]="isLoading"
  (submitModal)="save()"
  (close)="cancel()"
>
  <div class="two-piece-input-wrapper">
    <app-input
      label="Agent Name"
      formControlName="firmName"
      [customErrors]="{ maxLength: 'You\'ve exceeded the 50 character limit.' }"
    ></app-input>

    <app-input
      label="Agent Number"
      formControlName="agentNumber"
      [customErrors]="agentNumberCustomErrors"
    ></app-input>
  </div>

  <div class="form-group">
    <app-select
      label="ASIC Contact"
      formControlName="contactPerson"
      [options]="asicContacts"
      [valuePrimitive]="true"
    ></app-select>
  </div>

  <div class="form-group">
    <app-address-form-group [formGroup]="form.controls.address"/>
  </div>

  <div class="two-piece-input-wrapper">
    <app-input-phone-number formControlName="phone"></app-input-phone-number>

    <app-input
      label="Email"
      formControlName="email"
    ></app-input>
  </div>

  <app-divider margin="24px 0"></app-divider>

  <div class="two-piece-input-wrapper">
    <app-input
      label="EDGE Login"
      formControlName="edgeLogin"
      [warningTooltipMessage]="edgeLoginTip"
      [customErrors]="edgeLoginCustomErrors"
    ></app-input>

    <app-input-password
      label="EDGE Password"
      formControlName="edgePassword"
    ></app-input-password>
  </div>

  <app-divider margin="24px 0"></app-divider>

  <app-radio
    label="ASIC Mailbox"
    formControlName="mailbox"
    [options]="asicMailboxOptions"
  ></app-radio>
</app-common-modal-wrapper>
