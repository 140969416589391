import { Component } from '@angular/core';
import { ICellRendererParams } from "ag-grid-community";
import { Trust } from "../../../../../../models/trust";
import { Company } from "../../../../../../models/company";
import { ABNPipe } from "../../../../../../pipes/abnPipe";
import { EntityNameNumberRecordComponent } from "../../../entity-name-number-record/entity-name-number-record.component";
import { ACNPipe } from "../../../../../../pipes/acnPipe";

type CellRendererParams = ICellRendererParams<Trust & Company>

@Component({
  selector: 'app-ag-entity-name-acn-abn',
  standalone: true,
  imports: [
    ABNPipe,
    EntityNameNumberRecordComponent,
    ACNPipe
  ],
  templateUrl: './ag-entity-name-acn-abn.component.html',
  styleUrl: './ag-entity-name-acn-abn.component.scss'
})
export class AgEntityNameAcnAbnComponent {
  protected params!: CellRendererParams;
  protected name = '';
  protected acn = '';
  protected abn = '';

  agInit(params: CellRendererParams): void {
    this.params = params;
    this.name = this.params.data?.['name'] ?? '';
    this.acn = this.params.data?.['acn'] ?? '';
    this.abn = this.params.data?.['abn'] ?? '';
  }

  refresh(params: CellRendererParams): boolean {
    this.params = params;
    return true;
  }
}
