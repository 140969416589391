import { Component } from '@angular/core';
import { AlertsStatusPipe } from "../../../../../../../pipes/enumsPipes/alertsStatusPipe";
import { SplitByCapitalLetterPipePipe } from "../../../../../../../pipes/split-by-capital-letter-pipe.pipe";
import { ICellRendererParams } from "ag-grid-community";
import { AnnualStatementStatus } from "../../../../../../../models/enums/annualStatementEnums";
import { NgClass } from "@angular/common";
import { AnnualRecord } from "../../../../../../../models/annualRecord";

@Component({
  selector: 'app-ag-annual-status',
  standalone: true,
  imports: [
    AlertsStatusPipe,
    SplitByCapitalLetterPipePipe,
    NgClass
  ],
  templateUrl: './ag-annual-status.component.html',
  styleUrl: './ag-annual-status.component.scss'
})
export class AgAnnualStatusComponent {
  protected isPaid = false;
  protected status: AnnualStatementStatus | null | undefined;
  protected readonly AnnualStatementStatus = AnnualStatementStatus;

  agInit(params: ICellRendererParams<AnnualStatementStatus, AnnualStatementStatus>): void {
    this.status = params.value;
    this.isPaid = (params.data as unknown as AnnualRecord).isPaid;
  }

  refresh(params: ICellRendererParams<AnnualStatementStatus, AnnualStatementStatus>): boolean {
    this.status = params.value;
    return true;
  }
}
