@if (!loading) {
  @if (companyProfile) {
    <div class="company-profile-container">
      <div class="company-profile-wrapper d-flex gap-24">
        <div class="left d-flex flex-column gap-24">
          <app-company-profile-card
            [disableControls]="modalOpened()"
            [companyProfile]="companyProfile"
            [countPendingForm]="countPendingForm"
            (openCompanyChangeNameForm)="openCompanyChangeNameForm()"
            (onCompanyRefreshed)="onCompanyRefreshed()"
            (onSelectTab)="selectTab($event)"
          ></app-company-profile-card>
          <app-entity-profile-tags
            [disableControls]="modalOpened()"
            [entityProfile]="companyProfile"
          ></app-entity-profile-tags>
          @if (pendingDocuments.length) {
            <app-company-pending-changes
              [disableControls]="modalOpened()"
              [pendingButtonState]="pendingButtonState"
              [pendingDocuments]="pendingDocuments"
              [highlightCurrentChanges]="highlightCurrentChanges"
              (switchPendingState)="switchPendingState($event)"
              (updatePendingDocuments)="updateCompanyData()"
              (deletePendingDocuments)="deleteDocumentChange($event)"
              (openEditForm)="openDraftForm($event)"
            ></app-company-pending-changes>
          }
        </div>

        <div class="right d-flex flex-column flex-grow-1">
          <div id="details" class="card mb-4">

            @if (!hideTabs) {
              <div class="card-header">
                <div class="d-flex align-items-center justify-content-between gap-24">
                  <div class="left">
                    <div class="tab-container">
                      @for (tab of tabs; track $index) {
                        <app-tab-button
                          [isActive]="activeTab === tab.title"
                          (tabClicked)="setActiveTab(tab)"
                          [isDisabled]="modalOpened()"
                        >{{ tab.title }}
                        </app-tab-button>
                      }
                    </div>
                    <div class="vertical-divider"></div>
                    <div class="notes-alerts-buttons">
                      <app-tab-button
                        iconClass="ic-alert-triangle"
                        [isActive]="alertsVisible"
                        [isDisabled]="!alerts.length || modalOpened()"
                        (tabClicked)="alerts.length ? alertsVisible = true : alertsVisible = false"
                      >{{ alertsLabel }}
                      </app-tab-button>

                      <app-tab-button
                        iconClass="ic-notes"
                        [isActive]="notesVisible"
                        [isDisabled]="modalOpened()"
                        (tabClicked)="notesVisible = true"
                      >{{ notesLabel }}
                      </app-tab-button>
                    </div>
                  </div>

                  @if (activeTab === 'Details') {
                    <div ngbDropdown [placement]="['bottom-end', 'bottom-start', 'top-start', 'top-end']" class="dropdown">
                      <button
                        ngbDropdownToggle
                        id="company-actions-dd"
                        class="btn btn-primary d-flex align-items-center gap-2"
                        [disabled]="modalOpened()"
                      >
                        <span>Actions</span>
                        <i class="icon ic-xs ic-select-icon"></i>
                      </button>

                      <div ngbDropdownMenu aria-labelledby="company-actions-dd" class="dropdown-menu dropdown-menu-right">
                        @for(section of menuSections; track $index) {
                          <div ngbDropdownItem class="dropdown-item">
                            <div class="section-title">{{ section.title }}</div>
                            <div class="sub-menu">
                              @for(item of section.subMenuItems; track $index) {
                                @if(item.title !== 'Change of Address' || companyProfile.isPublic) {
                                  <div ngbDropdownItem class="dropdown-item">
                                    <a class="dropdown-button" (click)="item.action()">
                                      <i class="ic-form ic-md icon"></i>
                                      <div class="document">
                                        <div class="title">{{ item.title }}</div>
                                        <div class="asic-form">{{ item.formName }}</div>
                                      </div>
                                    </a>
                                  </div>
                                }
                              }
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  } @else if (activeTab === 'Officeholders') {
                    <div class="d-flex gap-2">
                      <app-button
                        id="officeholders-actions-dd"
                        [text]="'Appoint Officer'"
                        [iconClass]="'icon ic-xs ic-plus'"
                        [isDisabled]="modalOpened()"
                        (clickBtn)="appointOfficeholder(selectedOfficeholders)"
                      ></app-button>

                      <div ngbDropdown class="dropdown">
                        <button
                          ngbDropdownToggle
                          id="company-actions-dd-2"
                          class="btn btn-primary d-flex align-items-center gap-2"
                          [disabled]="modalOpened()"
                        >
                          <span>Actions</span>
                          <i class="icon ic-xs ic-select-icon"></i>
                        </button>

                        <div
                          ngbDropdownMenu
                          aria-labelledby="company-actions-dd-2"
                          class="dropdown-menu dropdown-menu-right"
                        >
                          @for(item of officeholdersMenu; track $index) {
                            <div ngbDropdownItem class="dropdown-item">
                              <a class="dropdown-button" (click)="item.action(selectedOfficeholders)">
                                <i class="ic-form ic-md icon"></i>
                                <div class="document">
                                  <div class="title">{{ item.title }}</div>
                                  <div class="asic-form">{{ item.formName }}</div>
                                </div>
                              </a>
                            </div>
                          }
                        </div>
                      </div>
                    </div>

                  } @else if (activeTab === 'Shareholders') {
                    <div class="d-flex gap-2">
                      <app-button
                        [text]="'Share Register'"
                        (clickBtn)="openShares()"
                        [isDisabled]="modalOpened()"
                      ></app-button>

                      <div ngbDropdown class="dropdown">
                        <button
                          ngbDropdownToggle
                          id="company-actions-dd-4"
                          class="btn btn-primary d-flex align-items-center gap-2"
                          [disabled]="modalOpened()"
                        >
                          <span>Actions</span>
                          <i class="icon ic-xs ic-select-icon"></i>
                        </button>

                        <div
                          ngbDropdownMenu
                          aria-labelledby="company-actions-dd-4"
                          class="dropdown-menu dropdown-menu-right"
                        >
                          @for(item of shareholdersMenu; track $index) {
                            <div ngbDropdownItem class="dropdown-item">
                              <a class="dropdown-button" (click)="item.action(selectedShareholders)">
                                <i class="ic-form ic-md icon"></i>
                                <div class="document">
                                  <div class="title">{{ item.title }}</div>
                                  <div class="asic-form">{{ item.formName }}</div>
                                </div>
                              </a>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  } @else if (activeTab === 'Documents') {
                    @if(selectedDocuments.length) {
                      <app-button
                        [text]="'Delete documents'"
                        [iconClass]="'icon ic-xs ic-trash'"
                        (clickBtn)="deleteDocuments()"
                        [isDisabled]="modalOpened()"
                      ></app-button>
                    } @else {
                      <div ngbDropdown [placement]="['bottom-end', 'bottom-start', 'top-start', 'top-end']" class="dropdown">
                        <button
                          ngbDropdownToggle
                          id="company-actions-dd-3"
                          class="btn btn-primary d-flex align-items-center gap-2"
                          [disabled]="modalOpened()"
                        >
                          <span>Actions</span>
                          <i class="icon ic-xs ic-select-icon"></i>
                        </button>

                        <div ngbDropdownMenu aria-labelledby="company-actions-dd-3" class="dropdown-menu dropdown-menu-right">
                          @for(item of documentsMenu; track $index) {
                            <div ngbDropdownItem class="dropdown-item">
                              <a class="dropdown-button" (click)="item.action()">
                                <i class="ic-form ic-md icon"></i>
                                <div class="document">
                                  <div class="title">{{ item.title }}</div>
                                  <div class="asic-form">{{ item.formName }}</div>
                                </div>
                              </a>
                            </div>
                          }
                        </div>
                      </div>
                    }
                  }
                </div>
              </div>
            }

            <div>
              @if (activeTab === 'Details') {
                <app-details
                  [pendingButtonState]="pendingButtonState"
                  [companyProfile]="companyProfile"
                  [companyProfilePrevious]="companyProfilePrevious"
                  [isDifferenceBetweenCompany]="isDifferenceBetweenCompany"
                  [companyProfileDifference]="companyProfileDifference"
                ></app-details>
              } @else if (activeTab === 'Officeholders') {
                <app-officeholders
                  [disableControls]="modalOpened()"
                  [pendingButtonState]="pendingButtonState"
                  [previousOfficeholders]="companyProfilePrevious.officers"
                  [officeholders]="companyProfile.officers"
                  [officeholderRows]="officeholderRows"
                  [entityId]="companyProfile.entityId"
                  (selectOfficeholders)="onSelectOfficeholdersChange($event)"
                  (profileViewChange)="profileViewChange($event)"
                  (ceaseRegisteredAgent)="ceaseCompanyOfficeholder($event)"
                  (companyChangePartyName)="companyChangePartyName($event)"
                  (changeOfficerAddress)="address($event)"
                  (editOfficer)="editOfficer($event)"
                ></app-officeholders>
              } @else if (activeTab === 'Shareholders') {
                <app-securityholders
                  [entityId]="companyProfile.entityId"
                  [pendingButtonState]="pendingButtonState"
                  [previousOfficeholders]="companyProfilePrevious.officers"
                  (profileViewChange)="profileViewChange($event)"
                  (selectShareholders)="onSelectShareholdersChange($event)"
                ></app-securityholders>
              } @else if (activeTab === 'Documents') {
                <app-company-profile-documents
                  [pendingDocuments]="pendingDocuments"
                  (openForm492)="requestCorrestionForm492($event)"
                  (openForm902)="notificationOfAdditionalInformationForm902($event)"
                  (deleteDocument)="deleteDocumentChange($event)"
                  (onSelectDocuments)="selectDocuments($event)"
                ></app-company-profile-documents>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

<app-notes
  [entityId]="companyProfile.entityId"
  [opened]="notesVisible"
  (close)="notesVisible = false"
  (notesTotalChanged)="notesTotal = notesTotal + $event"
></app-notes>

<app-alerts-list
  [alerts]="companyProfile.alerts"
  [entityType]="EntityType.Company"
  [entity]="companyProfile"
  [opened]="alertsVisible"
  (close)="alertsVisible = false"
></app-alerts-list>

<app-loader-standalone [loading]="loading"></app-loader-standalone>

<!--Temporary don't delete-->
<!--<div ngbDropdownItem class="dropdown-item">-->
<!--  <a class="dropdown-button" (click)="generateAnnualStatement()">-->
<!--    <i class="ic-form ic-md icon"></i>-->

<!--    <div class="document">-->
<!--      <div class="title">Generate Annual Statement</div>-->
<!--      <div class="asic-form">Form 480f</div>-->
<!--    </div>-->
<!--  </a>-->
<!--</div>-->

<!--&lt;!&ndash;                    <div ngbDropdownItem class="dropdown-item">-->
<!--                      <a class="dropdown-button" (click)="statementRequestRa71()">-->
<!--                        <i class="ic-form ic-md icon"></i>-->

<!--                        <div class="document">-->
<!--                          <div class="title">Company Statement Request</div>-->
<!--                          <div class="asic-form">RA 71</div>-->
<!--                        </div>-->
<!--                      </a>-->
<!--                    </div>-->
<!--                    <div ngbDropdownItem class="dropdown-item">-->
<!--                      <a class="dropdown-button" (click)="companyDebtReportRa63()">-->
<!--                        <i class="ic-form ic-md icon"></i>-->

<!--                        <div class="document">-->
<!--                          <div class="title">Company Debt Repot Request</div>-->
<!--                          <div class="asic-form">RA 63</div>-->
<!--                        </div>-->
<!--                      </a>-->
<!--                    </div>&ndash;&gt;-->

<!--<div ngbDropdownItem class="dropdown-item">-->
<!--  <a class="dropdown-button" (click)="resignOrRetireOfficeholder()">-->
<!--    <i class="ic-form ic-md icon"></i>-->

<!--    <div class="document">-->
<!--      <div class="title">Notification by Officeholder of Resignation or Retirement</div>-->
<!--      <div class="asic-form">Form 370</div>-->
<!--    </div>-->
<!--  </a>-->
<!--</div>-->
