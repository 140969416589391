import { FilterOption } from "./gridFilter";
import { SelectOption } from "./selectOptions";

export class UserRecord {
  externalId: string;
  firstName: string;
  middleName: string | undefined;
  lastName: string;
  email: string;
  phoneNumber: string | undefined;
  roles: IUserRole[];

  get fullName() {
    return [this.firstName, this.middleName, this.lastName].filter(v => v).join(' ');
  }

  constructor(data: Partial<UserRecord> = {}) {
    this.externalId = data.externalId!;
    this.firstName = data.firstName!;
    this.middleName = data.middleName;
    this.lastName = data.lastName!;
    this.email = data.email!;
    this.phoneNumber = data.phoneNumber;
    this.roles = data.roles ?? [];
  }
}

export type IUserRole = 'Admin' | 'Manager' | 'Preparer' | 'User';

export const UserPermissions: IUserRole[] = [
  'Admin',
  'Manager',
  'Preparer',
  'User'
];

export const UserPermissionsLabelsRecord: Record<IUserRole, string> = {
  Admin: 'Master (Admin)',
  Manager: 'Manager',
  Preparer: 'Preparer',
  User: 'User'
};

export const UserPermissionsFilterOptions: FilterOption[] = UserPermissions
  .map((value) => ({ label: UserPermissionsLabelsRecord[value], value, active: false }));

export const UserPermissionsSelectOptions: SelectOption[] = UserPermissions
  .map((value) => ({ label: UserPermissionsLabelsRecord[value], value }));

export enum UserStatus {
  Active = 0,
  Inactive = 1,
  Archived = 2
}
