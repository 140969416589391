import { TextMessageStatus } from "./enums/textMessageStatus";
import { TextMessagesHelper } from "./text-messages/textMessagesHelper";

export class TextMessageRecord {
  textMessageId: string;
  createdOn: string;
  modifiedOn: string;
  to: string;
  status: TextMessageStatus;
  errorMessage?: string;
  code?: string;
  header: string;

  constructor(data: Partial<TextMessageRecord> = {}) {
    this.textMessageId = data.textMessageId ?? '';
    this.createdOn = data.createdOn ?? '';
    this.modifiedOn = data.modifiedOn ?? '';
    this.to = data.to ?? '';
    this.status = data.status ?? TextMessageStatus.Created;
    this.errorMessage = data.errorMessage;
    this.code = data.code;

    // only FE part
    this.header = data.code && data.code in TextMessagesHelper.TemplateHeadersRecord ? TextMessagesHelper.TemplateHeadersRecord[data.code] : '';
  }
}
