@if (trustProfile) {
  <div class="card-body">
    <div class="row-container">
      <div class="left">
        <div class="form-group">
          <label class="title mb-1">Name</label>
          <span class="property" appCopy>{{ trustProfile.name }}</span>
        </div>

        <div class="row-container">
          <div class="left">
            <div class="form-group mb-0">
              <label class="title mb-1">ABN</label>
              <span class="property" appCopy>{{ trustProfile.abn | abn }}</span>
            </div>
          </div>

          <div class="right">
            <div class="form-group">
              <label class="title mb-1">Status</label>
              <span class="property" appCopy>{{ trustProfile.status | trustStatus }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="right">
        <div class="row-container">
          <div class="left">
            <div class="form-group">
              <label class="title mb-1">Commencement date</label>
              <span class="property" appCopy> {{ trustProfile.dateOfEstablishment | dateFormat }}</span>
            </div>
          </div>
          <div class="right">
            <div class="form-group">
              <label class="title mb-1">Jurisdiction</label>
              <span class="property" appCopy> {{ jurisdiction }}</span>
            </div>
          </div>
        </div>
        <div class="row-container">
          <div class="right">
            <div class="form-group">
              <label class="title mb-1 d-block">Trust Type</label>
              <span class="property d-inline" appCopy>{{ trustProfile.trustSubType | trustSubType }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="horizontal-divider"></div>

    <div class="row-container">
      <div class="left">
        <div class="form-group">
          <label class="title mb-1">Address</label>
          <span class="property"
                appCopy>{{ trustProfile.registeredAddress.normalizedFullAddress | defaultValue }}</span>
        </div>
      </div>
    </div>

    <div class="horizontal-divider"></div>

    @for (trustee of trustProfile.trustees; track trustee.relationshipId) {
      <div class="row-container trusty-element">
        <div class="left">
          <div class="form-group">
            <label class="title mb-1">Trustee Name</label>
            <span class="property" appCopy>{{ trustee.individualDataOverride?.fullName ?? trustee.entityDataOverride?.name ?? '-' }}</span>
          </div>
        </div>

        <div class="right">
          <div class="form-group">
            <label class="title mb-1">Address</label>
            <span class="property" appCopy>{{ trustee.individualDataOverride?.address?.normalizedFullAddress ?? trustee.entityDataOverride?.registeredAddress?.normalizedFullAddress | defaultValue }}</span>
          </div>
        </div>
      </div>
    }

    @if (trustProfile.trustees.length) {
      <div class="horizontal-divider"></div>
    }

    <div class="row-container">
      <div class="left">
        <div class="form-group">
          <label class="title mb-1">Dates of variation</label>
          <span class="property" appCopy>{{ variationDates }}</span>
        </div>
      </div>

      <div class="right">
        <div class="form-group">
          <label class="title mb-1">Vesting Date</label>
          <span class="property" appCopy>{{ trustProfile.vestingDate | dateFormat }}</span>
        </div>
      </div>
    </div>

    <div class="horizontal-divider"></div>

    <div class="row-container">
      <div class="left">
        <div class="form-group">
          <label class="title mb-1">Partner</label>
          <span class="property" appCopy>{{ trustProfile.partnerManager?.fullName | defaultValue }}</span>
        </div>
      </div>

      <div class="right">
        <div class="form-group">
          <label class="title mb-1">Account Manager</label>
          <span class="property" appCopy>{{ trustProfile.accountManager?.fullName | defaultValue }}</span>
        </div>
      </div>
    </div>

    <div class="horizontal-divider"></div>

    <div class="row-container">
      <div class="left">
        <div class="form-group">
          <label class="title mb-1">Reference Number</label>
          <span class="property" appCopy>{{ trustProfile.referenceNumber | defaultValue }}</span>
        </div>
      </div>

      <div class="right">
        <div class="form-group">
          <label class="title mb-1">Family group</label>
          <span class="property" appCopy>{{ trustProfile.familyGroup | defaultValue }}</span>
        </div>
      </div>
    </div>

    <div class="horizontal-divider"></div>
    <div class="row-container">
      <div class="left">
        <div class="form-group">
          <label class="title mb-1">Signing Contact</label>
          <span
            class="property mb-1">{{ trustProfile.signingContact?.firstName }} {{ trustProfile.signingContact?.lastName }}</span>
          <div class="contact form-group mb-1">
            <span class="contact-icon mr-1">&#64;</span>
            <span class="property" appCopy>{{ trustProfile.signingContact?.email }}</span>
          </div>
          <div class="contact form-group align-items-center">
            <i class="icon ic-xs ic-phone mr-1"></i>
            <span class="property" appCopy>{{ trustProfile.signingContact?.phone }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
}
