import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { map, Observable } from "rxjs";
import { environment } from "../environments/environment";
import { TextMessageTemplate } from "../models/text-messages/textMessageTemplate";
import { TextMessageSendRequest } from "../models/text-messages/TextMessageSendRequest";
import { TextMessagesTemplateSettingsBase } from "../models/text-messages/textMessagesTemplateSettingsBase";
import { IServerSideGetRowsRequest } from "ag-grid-enterprise";
import { PageResult } from "../models/pageResult";
import { TextMessageRecord } from "../models/textMessageRecord";
import { reorderGridFilterModel } from "../app/helpers/reorder-grid-filter";
import { TextMessageModel } from "../models/textMessageModel";

@Injectable()
export class TextMessageTemplatesService {
  private api = inject(HttpClient);

  public loadTextMessageTemplates(): Observable<TextMessageTemplate[]> {
    return this.api.get<TextMessageTemplate[]>(`${ environment.api_url }/textmessages/templates`);
  }

  public loadSmsDefaultTemplates(): Observable<Record<string, TextMessagesTemplateSettingsBase>> {
    return this.api.get<Record<string, TextMessagesTemplateSettingsBase>>(`${ environment.api_url }/textmessages/templates/default`);
  }

  public saveTextMessageTemplate(textMessageTemplate: TextMessageTemplate): Observable<TextMessageTemplate> {
    return this.api.post<TextMessageTemplate>(`${ environment.api_url }/textmessages/templates`, textMessageTemplate);
  }

  public sendTestTextMessageTemplate(sendRequest: TextMessageSendRequest): Observable<void> {
    return this.api.post<void>(`${ environment.api_url }/textmessages/send`, sendRequest);
  }

  public getOutgoingTextMessagesList(request: IServerSideGetRowsRequest, search?: string): Observable<PageResult<TextMessageRecord>> {
    request = reorderGridFilterModel(request);

    let params = new HttpParams();
    if (search) {
      params = params.append('search', search);
    }

    return this.api.post<PageResult<TextMessageRecord>>(`${ environment.api_url }/textmessages/grid`, request, { params })
      .pipe(map((response => {
        response.records = response.records.map((textMessageRecord) => new TextMessageRecord(textMessageRecord));
        return response;
      })));
  }

  public getOutgoingTextMessageDetails(textMessageId: string): Observable<TextMessageModel> {
    return this.api.get<TextMessageModel>(`${ environment.api_url }/textmessages/${ textMessageId }/details`)
      .pipe(map((textMessageModel) => new TextMessageModel(textMessageModel)));
  }

  public resendOutgoingTextMessage(textMessageId: string): Observable<string> {
    return this.api.get<string>(`${ environment.api_url }/textmessages/${ textMessageId }/resend`);
  }

  public deleteOutgoingTextMessage(textMessageId: string): Observable<void> {
    return this.api.delete<void>(`${ environment.api_url }/textmessages/${ textMessageId }`);
  }
}

