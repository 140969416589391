import { Component, inject, Input, Optional } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormsModule, NgControl } from "@angular/forms";
import { ValidationErrorComponent } from "../validation-error/validation-error.component";
import { EmailTemplatesService } from "../../../../services/email-templates.service";
import { catchError, debounceTime, distinctUntilChanged, map, Observable, of, switchMap } from "rxjs";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { DotsLoaderComponent } from "../dots-loader/dots-loader.component";
import { EmailMultipleAddress } from "../../../../models/email-templates/emailMultipleAddress";
import { CustomFormValidators } from "../../../../custom-form-validators/custom-form-validators";

@Component({
  selector: 'app-multiple-input-email',
  standalone: true,
  imports: [
    FormsModule,
    ValidationErrorComponent,
    NgbTypeahead,
    DotsLoaderComponent,
  ],
  providers: [EmailTemplatesService],
  templateUrl: './multiple-input-email.component.html',
  styleUrl: './multiple-input-email.component.scss'
})
export class MultipleInputEmailComponent implements ControlValueAccessor {
  private emailTemplatesService = inject(EmailTemplatesService);

  @Input() label = '';
  @Input() tip = '';
  @Input() placeholder = '';
  @Input() isDisabled = false;
  @Input() shouldShowErrors = true;
  @Input() customErrors: Record<string, string> = {};
  @Input() maxInputTags = 15;

  inputValue = '';
  typeaheadValue = '';
  tags: EmailMultipleAddress[] = [];
  #value: EmailMultipleAddress[] = [];
  focused = false;
  loading = false;

  constructor(@Optional() protected ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  get value(): EmailMultipleAddress[] {
    return this.#value;
  }

  set value(value: EmailMultipleAddress[]) {
    this.#value = value ? value?.filter(email => email) : [];
    this.onChange(this.#value);
  }

  get displayValidationError(): boolean {
    return !!(this.ngControl && this.ngControl.touched && this.ngControl.errors);
  }

  onChange: any = (): void => {};
  onTouched: any = (): void => {};

  writeValue(value: EmailMultipleAddress[]): void {
    this.value = value;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  onBlur(): void {
    if(this.inputValue) {
      this.addTag();
    }

    this.focused = false;
    this.onTouched();
  }

  onSubmit(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    if(this.inputValue) {
      this.addTag();
    }
  }

  onFocus(): void {
    this.focused = true;
  }

  onInput(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;
    if (this.tags.length <= this.maxInputTags) {
      this.inputValue = inputValue;
      event.preventDefault();
    }
  }

  onKeyDown(event: KeyboardEvent): void {
    const key = event.key;

    if (key === 'Enter' || key === 'Tab') {
      event.preventDefault();
      this.addTag();
    } else if (key === 'Backspace' && !this.inputValue) {
      this.removeTag(this.#value.length - 1);
    }
  }

  addTag(): void {
    if(!this.value || this.value.length > this.maxInputTags) {
      return;
    }

    if(typeof this.inputValue === 'object') {
      this.inputValue = '';
      return;
    }

    const email = this.inputValue.trim();
    if(!email.includes('@')) {
      return;
    }

    if (this.isValidEmail(email)) {
      this.value.push({ email, fullName: '', isFound: false });
      this.resetInputValue();
    }
  }

  addFoundTag(selected: {item: EmailMultipleAddress}): void {
    if(this.value.length > this.maxInputTags) {
      return;
    }

    const email = selected.item.email;
    const fullName = selected.item.fullName ?? '';
    this.value.push({ email, fullName, isFound: true });
    this.resetInputValue();
  }

  isValidEmail(email: string): boolean {
    const control: AbstractControl = { value: email } as AbstractControl;
    const validationResult = CustomFormValidators.emailValidator(control);
    return validationResult === null;
  }

  inputFormatEmail = () => '';
  formatEmail = (result: EmailMultipleAddress) => result.fullName ? `${result.fullName} (${result.email})` : result.email;

  private resetInputValue(): void {
    this.inputValue = '';
    this.typeaheadValue = '';
    this.onChange(this.#value);
  }

  removeTag(index: number): void {
    this.#value.splice(index, 1);
    this.onChange(this.#value);
  }

  searchEmails = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(searchTerm => {
        if(searchTerm.length < 2) {
          return [];
        } else {
          this.loading = true;

          return this.emailTemplatesService.getEmailList().pipe(
            map(emails => {
              this.loading = false;
              const foundEmails = emails.filter(item => item.email.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1);
              const selectedEmailsList = this.value.filter(item => item?.isFound).map(item => item.email);
              const filteredEmailList = foundEmails.filter(item => !selectedEmailsList.includes(item.email));
              return selectedEmailsList.length ? filteredEmailList : foundEmails;
            })
          )
        }
      }),
      catchError(() => {
        this.loading = false;
        return of([]);
      })
    );
  }
}
