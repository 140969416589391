import { Component } from '@angular/core';
import { settingsLinksBlocks } from "./settings.constant";
import { PageTitleComponent } from "../components/common/page-title/page-title.component";
import { RouterLink } from "@angular/router";

@Component({
  selector: 'app-settings',
  standalone: true,
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss', './settings-styles.scss'],
  imports: [
    PageTitleComponent,
    RouterLink
  ]
})
export class SettingsComponent {
  readonly linkBlocks = settingsLinksBlocks;
}
