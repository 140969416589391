import { FilterModel, IServerSideGetRowsRequest } from 'ag-grid-enterprise';

export function reorderGridFilterModel(request: IServerSideGetRowsRequest): IServerSideGetRowsRequest {
  // This is a fix for serialization issue in dot net - discriminator should always come first in the json
  // Create a new filter model with filterType always first
  const filterModel = request.filterModel as FilterModel;

  if (filterModel && Object.keys(filterModel).length > 0) {
    request.filterModel = Object.keys(filterModel).reduce((acc, key) => {
      const filter = filterModel[key];
      if (filter) {
        acc[key] = {filterType: null, ...filter};
      }
      return acc;
    }, {} as FilterModel);
  }

  return request;
}
