import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { UserRecord } from '../models/userRecord';
import { map, switchMap } from 'rxjs';
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(
    private api: HttpClient,
    private authService: AuthService
  ) { }

  public getUsers() {
    return this.api.get<UserRecord[]>(`${environment.api_url}/users`)
      .pipe(map(u => u.map(u => new UserRecord(u))));
  }

  saveUser(user: UserRecord) {
    return this.api.post(`${environment.api_url}/users`, user)
      .pipe(switchMap((response) => this.authService.updateUserProfile().pipe(map(() => response))));
  }

  deleteUser(externalId: string) {
    return this.api.delete(`${environment.api_url}/users/${externalId}`)
      .pipe(switchMap((response) => this.authService.updateUserProfile().pipe(map(() => response))));
  }

  triggerPasswordReset(externalId: string) {
    return this.api.post(`${environment.api_url}/users/${externalId}/reset-password`, null);
  }
}
