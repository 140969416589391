import { Pipe, PipeTransform } from '@angular/core';
import { MinutesMeetingPresence } from "../../models/enums/documentConfirmEnums";
@Pipe({
  standalone: true,
  name: 'minutesMeetingPresenceStatus'
})
export class MinutesMeetingPresencePipe implements PipeTransform {
  transform(value: MinutesMeetingPresence | undefined): string {
    if(value != undefined) {
      if(value === MinutesMeetingPresence.PresentPhysically) {
        return 'Present'
      } else if(value === MinutesMeetingPresence.PresentVirtually) {
        return 'Online'
      } else if(value === MinutesMeetingPresence.PresentOverPhone) {
        return 'Phone'
      } else if(value === MinutesMeetingPresence.Apology) {
        return 'Apology'
      }
    }

    return '';
  }
}
