<app-common-modal-form
  header="Annature Login"
  subheader="Please select the account you want to use"
  closeText="Disconnect"
  confirmText="Save"
  icon="ic-mail"
  (close)="close()"
  (confirm)="confirm()"
>
  <div style="padding-bottom: 15px">
    <app-select
        [label]="'Annature Accounts'"
        [placeholder]="'Select Annature Account'"
        [valuePrimitive]="true"
        [disabled]="isDisabled"
        [options]="accountOptions"
        [(ngModel)]="pickedAccountId"
        [ngModelOptions]="{standalone: true}"
        (ngModelChange)="onAccountSelect($event)"
      ></app-select>
  </div>
</app-common-modal-form>
