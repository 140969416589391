export function mergeUniqueValues<T>(arrays: T[], keyName: string ): T {
  const uniqueValues = {};

  // @ts-expect-error todo check
  arrays?.forEach((array: T[]) => {
    // Loop through each tag in the array
    array?.forEach((value: T) => {
      // Add the tag to the uniqueTags object if its tagId doesn't exist already
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (!uniqueValues[value[keyName]]) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
        uniqueValues[value[keyName]] = value;
      }
    });
  });

  // Convert the uniqueTags object back to an array
  return Object.values(uniqueValues) as T;
}
