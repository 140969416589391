import { Component } from '@angular/core';
import { SigningStatus } from "../../../../../../../models/enums/annualStatementEnums";
import { SplitByCapitalLetterPipePipe } from "../../../../../../../pipes/split-by-capital-letter-pipe.pipe";
import { ICellRendererParams } from "ag-grid-community";
import { NgClass } from "@angular/common";

@Component({
  selector: 'app-ag-annual-signing-status',
  standalone: true,
  imports: [
    SplitByCapitalLetterPipePipe,
    NgClass
  ],
  templateUrl: './ag-annual-signing-status.component.html',
  styleUrl: './ag-annual-signing-status.component.scss'
})
export class AgAnnualSigningStatusComponent {
  protected status: SigningStatus | null | undefined;
  protected readonly SigningStatus = SigningStatus;

  agInit(params: ICellRendererParams<SigningStatus, SigningStatus>): void {
    this.status = params.value;
  }

  refresh(params: ICellRendererParams<SigningStatus, SigningStatus>): boolean {
    this.status = params.value;
    return true;
  }
}
