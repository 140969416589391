import { SelectOption } from '../models/selectOptions';
import { IStep } from '../models/step';

export function PascalCaseToText(camelCaseText: string): string {
  const key = camelCaseText.replace(/([A-Z])/g, ' $1');
  return (key.charAt(0).toUpperCase() + key.slice(1)).trim();
}

export function enumToSelectOptions(enumValue: any, usePascalCase = true): SelectOption[] {
  return Object.entries(enumValue as Record<string, number>)
    .reduce((steps: SelectOption[], [stepKey, stepValue]) => {
      if (!isNaN(Number(stepValue))) {
        steps.push({
          label: usePascalCase ? PascalCaseToText(stepKey) : stepKey,
          value: stepValue
        } as SelectOption);
      }

      return steps;
    }, []);
}

export function enumToSteps<T>(enumValue: any): IStep<T>[] {
  return Object.entries(enumValue as Record<string, number>)
    .reduce((steps: IStep<T>[], [stepKey, stepValue]) => {
      if (!isNaN(Number(stepValue))) {
        steps.push({
          label: PascalCaseToText(stepKey),
          step: stepValue
        } as IStep<T>);
      }

      return steps;
    }, []);
}

export function enumDictionaryToSelectOptions(dictionary: Record<string, string>): SelectOption[] {
  return Object.entries(dictionary)
    .reduce((steps: SelectOption[], [key, label]) => {
      steps.push({
        label: PascalCaseToText(label),
        value: +key
      } as SelectOption);

      return steps;
    }, []);
}
