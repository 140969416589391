import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { Company } from '../../../../models/company';
import { RadioComponent } from "../../../components/common/radio/radio.component";
import { SelectOption } from "../../../../models/selectOptions";
import { InputComponent } from "../../../components/common/input/input.component";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { AuthService } from "../../../../services/auth.service";
import { DocumentsService } from "../../../../services/documents.service";
import { Document } from "../../../../models/document";
import { DatePickerComponent } from "../../../components/common/date-picker/date-picker.component";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import {
  DatePickerFormValidators,
  DatepickerHelper
} from "../../../../custom-form-validators/date-picker-form-validators";
import { CheckboxComponent } from "../../../components/common/checkbox/checkbox.component";
import { DividerComponent } from "../../../components/common/divider/divider.component";
import {
  DocumentationType,
  DocumentSelectionType,
  MeetingLocationType,
  MeetingOnlineType,
  MinutesMeetingPresence
} from "../../../../models/enums/documentConfirmEnums";
import { RelationshipType } from "../../../../models/enums/relationshipTypeEnum";
import { OfficerRelationshipDetails, Relationship } from "../../../../models/relationship";
import { ChangeAuthorisation, MinutesMeetingAttendee } from "../../../../models/changeAuthorisation";
import { ToastrService } from "ngx-toastr";
import { Address } from "../../../../models/address";
import { SelectComponent } from "../../../components/common/select/select.component";
import { AsyncPipe, formatDate, NgForOf, NgIf } from "@angular/common";
import { catchError, filter, firstValueFrom, of, Subject } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { AddressControlComponent } from "../../../components/common/address-control/address-control.component";
import { autocompleteServiceToken } from "../../../../services/autocomplete.service";
import { AuxiliaryService } from "../../../../services/auxiliary.service";
import { DocumentStatusEnum } from "../../../../models/enums/documentStatusEnum";
import { observe, Observer } from "fast-json-patch";
import { MultipleInputComponent } from "../../../components/common/multiple-input/multiple-input.component";
import { CustomFormValidators } from "../../../../custom-form-validators/custom-form-validators";
import { ColDef, GridApi } from "ag-grid-community";
import { GridComponent } from "../../../components/common/grid/components/grid/grid.component";
import { ListGridComponent } from "../../../components/common/grid/components/list-grid/list-grid.component";
import { NgArrayPipesModule } from "ngx-pipes";
import {
  AgDirectorMeetingStatusComponent
} from "../../../components/common/grid/components/ag-director-meeting-status/ag-director-meeting-status.component";
import { Attendee } from "../../../../models/generateDocuments";
import { Contact } from "../../../../models/contact";
import { DocumentSelectionComponent } from "../../../components/document-selection/document-selection.component";
import { DocumentSelection } from "../../../../models/document-selection";
import { ButtonComponent } from "../../../components/common/button/button.component";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { AddressStatus } from "../../../../models/enums/addressEnums";
import { isDocumentVisible } from "../../../components/document-selection/utils/is-document-visible";
import { OrganisationService } from "../../../../services/organisation.service";
import { IndividualData } from "../../../../models/individualData";
import { deepClone } from "fast-json-patch/commonjs/core";
import { parseFullName } from "../../../../functions/parse-fullname";
import { InputPhoneNumberComponent } from "../../../components/common/input-phone-number/input-phone-number.component";
import { Router } from "@angular/router";
import { ReasonOfCessation } from "../../../../models/сompanyChangeOfficer";
import {
  directorFullNameValidatorMsg,
  emailValidatorMsg,
} from "../../../../validators/validatorMessages/custom-form-validators-messages";
import { ParsedName } from "../../../../models/parsedName";

@Component({
  selector: 'app-generate-documents-form',
  standalone: true,
  imports: [
    RadioComponent,
    InputComponent,
    ReactiveFormsModule,
    DatePickerComponent,
    CheckboxComponent,
    DividerComponent,
    FormsModule,
    SelectComponent,
    NgForOf,
    NgIf,
    AddressControlComponent,
    MultipleInputComponent,
    GridComponent,
    ListGridComponent,
    NgArrayPipesModule,
    DocumentSelectionComponent,
    ButtonComponent,
    AsyncPipe,
    NgxSkeletonLoaderModule,
    InputPhoneNumberComponent,
  ],
  providers: [{provide: autocompleteServiceToken, useClass: AuxiliaryService}],
  templateUrl: './generate-documents-form.component.html',
  styleUrl: './generate-documents-form.component.scss'
})
export class GenerateDocumentsFormComponent implements OnInit {
  @Input() document!: Document;
  @Input() company!: Company;
  @Input() saveAction$: Subject<boolean> | undefined;
  @Input() confirmAction$: Subject<boolean> | undefined;
  @Input() set declarationState(value: boolean) {
    this.declaration = value;
  }
  @Output() nextStep = new EventEmitter<boolean>();
  @Output() updateDocuments = new EventEmitter<boolean>();
  @Output() declarationStateChange = new EventEmitter<boolean>();

  private authService = inject(AuthService);
  public documentsService = inject(DocumentsService);
  private organisationService = inject(OrganisationService);
  private toastr = inject(ToastrService);
  private router = inject(Router);
  private fb: FormBuilder = inject(FormBuilder);
  #destroyRef: DestroyRef = inject(DestroyRef);

  form!: FormGroup;
  gridApi!: GridApi;
  colDefs: ColDef[] = [];

  formModel!: ChangeAuthorisation;
  formModelCopy!: ChangeAuthorisation;
  expandedAddressForm = false;

  documentTypeOptions: SelectOption[] = [
    { label: 'Minutes', value: DocumentationType.Minutes },
    { label: 'Resolution', value: DocumentationType.Resolution },
    { label: 'ASIC Form only', value: DocumentationType.FormOnly },
  ];

  meetingTypeOptions: SelectOption[] = [
    { label: 'Registered Address', value: MeetingLocationType.Registered },
    { label: 'Principal Address', value: MeetingLocationType.Principal },
    { label: 'Other address', value: MeetingLocationType.Other },
    { label: 'Phone', value: MeetingLocationType.Phone },
    { label: 'Online', value: MeetingLocationType.Online },
  ];

  meetingOnlineTypeOptions: SelectOption[] = [
    { label: 'Microsoft Teams', value: MeetingOnlineType.MicrosoftTeams },
    { label: 'Zoom', value: MeetingOnlineType.Zoom },
    { label: 'Google Meet', value: MeetingOnlineType.GoogleMeet }
  ];

  contactNameOptions: SelectOption[] = [];
  officerOptions: SelectOption[] = [];
  directorOptions: SelectOption[] = [];
  chairOptions: SelectOption[] = [];

  minDateOfMeeting: NgbDateStruct = DatepickerHelper.getTodayStruct();
  maxDateOfMeeting: NgbDateStruct = DatepickerHelper.getNextNMonthStruct(2);

  declaration = false;
  authAgentDeclaration = false;

  DocumentationTypeEnum = DocumentationType;
  MeetingLocationTypeEnum = MeetingLocationType;
  protected readonly emailValidatorMsg = emailValidatorMsg;
  protected readonly directorFullNameValidatorMsg = directorFullNameValidatorMsg;

  individualsList: Relationship[] = [];
  attendeesList: Attendee[] = [];
  addressStatus: AddressStatus | undefined;
  isFormFilled = false;
  isRaForm = false;
  loading = false;
  documentSelection: DocumentSelection[] | undefined;
  initialChangeAuthExists = false;
  customDatepickerErrors = DatePickerFormValidators.errorMessages;
  readonly raFormList = ['01', '04', '61', '63', '67', '71'];

  observer!: Observer<Document>;

  constructor() {
    this.toastr.toastrConfig.positionClass = 'toast-top-right';
  }

  ngOnInit(): void {
    this.getSelection();
    this.initForm();
    this.listenHeaderButton();
    this.observer = observe(this.document);
    this.formModelCopy = structuredClone(this.formModel);
  }

  private getSelection(): void {
    this.loading = true;
    this.documentsService.getDocumentsSelection(this.document.documentId)
      .pipe(
        catchError((err) => {
          console.error(err);
          return of([]);
        }),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe((documentsSelection) => {
        this.setDocumentTypeOptions(documentsSelection);
        this.setDocumentsSelection(documentsSelection);
        this.loading = false;
      });
  }

  setDocumentTypeOptions(documentSelection: DocumentSelection[]): void {
    if(!documentSelection?.length) {
      return;
    }

    const minutesNotExist = documentSelection.findIndex(document => document.documentType === DocumentSelectionType.Minutes) === -1;
    const resolutionsNotExist = documentSelection.findIndex(document => document.documentType === DocumentSelectionType.Resolution) === -1;

    if(minutesNotExist) {
      this.documentTypeOptions = this.documentTypeOptions.filter(o => o.value !== DocumentationType.Minutes);
      this.formModel.documentationType = DocumentationType.Resolution;
    }

    if(resolutionsNotExist) {
      this.documentTypeOptions = this.documentTypeOptions.filter(o => o.value !== DocumentationType.Resolution);
    }

    if(minutesNotExist && resolutionsNotExist) {
      this.documentTypeOptions = [];
      this.formModel.documentationType = DocumentationType.FormOnly;
    }
  }

  setDocumentsSelection(documentsSelection: DocumentSelection[] | undefined): void {
    if(!documentsSelection?.length) {
      return;
    }

    if(this.formModel.documentationType === DocumentationType.Minutes) {
      documentsSelection.forEach(document => {
        if(document.documentType === DocumentSelectionType.Minutes) {
          document.included = true;
        }
      });
    } else if (this.formModel.documentationType === DocumentationType.Resolution) {
      documentsSelection.forEach(document => {
        if(document.documentType === DocumentSelectionType.Resolution) {
          document.included = true;
        }
      });
    }

    this.documentSelection = this.initDocumentSelection(documentsSelection, this.document.changeAuthorisation);

    if(this.document?.changes?.length && this.document.changes[0].$type === 'CompanyChangeOfficer') {
      if((this.document.changes[0] as { cessationReason?: ReasonOfCessation })?.cessationReason !== ReasonOfCessation.Resignation) {
        this.documentSelection = this.documentSelection.filter(item => item.fileName !== 'Resignation Letter');
      }
    }
  }

  private initDocumentSelection(documentsSelection: DocumentSelection[], changeAuthorisation?: ChangeAuthorisation): DocumentSelection[] {
    return documentsSelection.map(documentSelection => ({
      ...documentSelection,
      included: changeAuthorisation?.documentSelection?.selection?.find(d => d.fileName === documentSelection.fileName)?.included ?? documentSelection.included,
    }))
  }

  initForm(): void {
    this.initialChangeAuthExists = !!this.document?.changeAuthorisation;
    this.formModel = this.document?.changeAuthorisation ?? new ChangeAuthorisation();
    if(!this.initialChangeAuthExists) {
      this.formModel.documentationType = DocumentationType.Minutes;
      this.formModel.meetingAddressType = MeetingLocationType.Registered;
    }

    this.isRaForm = this.raFormList.some(raType => this.document?.type === raType);
    if(this.documentTypeIs361 || this.documentTypeIs370 || this.isRaForm || this.documentTypeIs410b || this.documentTypeIs410f) {
      this.formModel.documentationType = DocumentationType.FormOnly;
      this.documentTypeOptions = this.documentTypeOptions.filter(o => o.value === DocumentationType.FormOnly);
    }

    if(this.documentTypeIs362) {
      this.meetingTypeOptions = this.meetingTypeOptions.filter(o => o.value !== MeetingLocationType.Registered && o.value !== MeetingLocationType.Principal);
    }

    this.individualsList = this.company?.officers ?? [];
    this.attendeesList = this.getAttendees();
    this.prefillAtendees();

    const contact = this.document.changeAuthorisation?.authorisingParty?.contact;
    const directorName = (contact?.firstName ?? '') + ' ' + (contact?.lastName ?? '');
    this.form = this.fb.group({
      address: [this.formModel.meetingAddress],
      directorNameList: [[], [
        Validators.required,
        CustomFormValidators.directorNameValidator,
        CustomFormValidators.directorOneLetterNameValidator
      ]],
      directorName: [directorName.trim(), [Validators.required, CustomFormValidators.directorFullNameValidator]],
      phone: [contact?.phone, [CustomFormValidators.phoneNumberValidator]],
      email: [contact?.email, [CustomFormValidators.emailValidator]]
    });

    this.form.get('address')?.valueChanges.pipe(
      takeUntilDestroyed(this.#destroyRef)
    ).subscribe((address: { normalizedFullAddress?: string }) => {
      if (this.document.changeAuthorisation) {
        this.document.changeAuthorisation.meetingAddress = this.form.get('address')?.getRawValue() as Address;
      }

      if (!address?.normalizedFullAddress) {
        this.documentsService.disabledHeaderBtn.set(true);
      } else {
        this.onDisableHeaderBtn();
      }
    });
    this.form.get('directorNameList')?.valueChanges.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(() => this.onDisableHeaderBtn());
    this.form.get('directorName')?.valueChanges.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(() => this.onDisableHeaderBtn());
    this.form.get('phone')?.valueChanges.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(() => this.onDisableHeaderBtn());
    this.form.get('email')?.valueChanges.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(() => this.onDisableHeaderBtn());

    this.setAllowedDateRange();
    this.setFormSelectOptions();
    this.setGridConfig();

    const isNotAuthorisingId = !this.formModel.authorisingParty.relationshipId || this.formModel.authorisingParty.relationshipId === '00000000-0000-0000-0000-000000000000';
    if(isNotAuthorisingId && this.company?.signingContactId) {
      if(this.officerOptions.some(o => (o.value as string) === this.company.signingContactId)) {
        this.formModel.authorisingParty.relationshipId = this.company.signingContactId;
      } else {
        this.formModel.authorisingParty.relationshipId = this.officerOptions[0].value as string;
      }
    } else if(isNotAuthorisingId && this.officerOptions?.length) {
      this.formModel.authorisingParty.relationshipId = this.officerOptions[0].value as string;
    } else {
      if(this.officerOptions.length && this.officerOptions?.every(o => (o.value as string) !== this.formModel.authorisingParty.relationshipId)) {
        this.formModel.authorisingParty.relationshipId = this.officerOptions[0].value as string;
      }
    }
    delete this.formModel.authorisingParty.details;
  }

  listenHeaderButton(): void {
    this.saveAction$?.pipe(
      filter(save => save),
      takeUntilDestroyed(this.#destroyRef)
    ).subscribe(() => {
      void this.save();
      this.saveAction$?.next(false);
    });

    this.confirmAction$?.pipe(
      filter(confirm => confirm),
      takeUntilDestroyed(this.#destroyRef)
    ).subscribe(() => {
      void this.confirm();
      this.confirmAction$?.next(false);
    });
  }

  setFormSelectOptions(): void {
    if(!this.initialChangeAuthExists || !this.formModel.signingManager) {
      this.formModel.signingManager = this.organisationService.getCurrentUser()?.id ?? '';
    }
    this.contactNameOptions = this.authService.currentUserProfile()?.users?.map(user => ({ label: user.fullName, value: user.id })) ?? [];

    this.officerOptions = this.getOfficers()?.map(officer => {
      const type = officer.type;
      const label = officer.individualDataOverride?.fullName + ` (${RelationshipType[type]})`;
      return ({ label, value: officer.relationshipId });
    });

    this.directorOptions = this.getOfficers(false, false)?.map(director => {
      return ({ label: director.individualDataOverride!.fullName, value: director.relationshipId });
    });

    this.chairOptions = this.getOfficers(false, false)?.map(chair => {
      const type = chair.type;
      const label = chair.individualDataOverride?.fullName + ` (${RelationshipType[type]})`;
      return ({ label, value: chair.relationshipId });
    });

    if(this.chairOptions.length) {
      if(!this.formModel?.chairperson) {
        this.formModel.chairperson = new Relationship();
        this.formModel.chairperson.relationshipId = this.chairOptions[0].value as string;
      }
    }

    if(this.directorOptions.length === 1) {
      this.documentTypeOptions = this.documentTypeOptions.filter(o => o.value !== this.DocumentationTypeEnum.Minutes);
      this.formModel.documentationType = this.documentTypeOptions[0].value as DocumentationType;
    }

    if(this.formModel.meetingAddressType !== undefined) {
      this.onMeetingAddressTypeSelect(this.formModel.meetingAddressType);
    }
  }

  setAllowedDateRange(): void {
    if(!this.formModel.meetingDate) {
      this.formModel.meetingDate = this.document?.changes[0]?.changeDate ?? new Date();
    }

    const dateOfEstablishment = this.document?.company?.dateOfEstablishment as string | undefined;
    if(dateOfEstablishment) {
      this.minDateOfMeeting = DatepickerHelper.getStructFromString(dateOfEstablishment);
      this.customDatepickerErrors = DatePickerFormValidators.errorMessagesWithDateOfEstablishmentErrorMessages;
    }
  }

  setGridConfig(): void {
    this.colDefs = [
      {
        headerName: 'Directors who attended the meeting',
        field: 'officer.individualDataOverride.fullName',
        width: 296,
        flex: 2,
        editable: false,
        sort: 'asc',
        menuTabs: ['generalMenuTab'],
      },
      {
        headerName: 'Status',
        field: 'presence',
        width: 80,
        flex: 0.7,
        sortable: false,
        cellRenderer: AgDirectorMeetingStatusComponent,
        cellRendererParams: {
          onSelectDirectorMeetingStatus: this.onSelectDirectorMeetingStatus.bind(this),
        },
        cellClass: 'actions-button-cell',
        suppressHeaderMenuButton: true,
      },
    ];
  }

  onSelectDirectorMeetingStatus(attendee: Attendee): void {
    if(attendee.presence === MinutesMeetingPresence.Apology) {
      this.officerOptions = this.officerOptions.filter(o => o.value !== attendee.officer.relationshipId);
      this.formModel.authorisingParty.relationshipId = '';
    } else {
      this.officerOptions = this.getOfficers()?.map(officer => {
        const type = officer.type;
        const label = officer.individualDataOverride?.fullName + ` (${RelationshipType[type]})`;
        return ({ label, value: officer.relationshipId });
      });
    }
  }

  //tofo refactor, need to use individualId
  prefillAtendees() {
    const attendees = this.document.changeAuthorisation?.attendees ?? [];
    if(!attendees.length) {
      return;
    }

    this.attendeesList = this.attendeesList.map(x => {
      const attendee = attendees.find(y => y.individual.fullName.trim().toLowerCase() === x.officer.individualDataOverride?.fullName?.trim().toLowerCase());
      return (attendee === undefined || attendee.presence === MinutesMeetingPresence.Apology) ? x : { ...x, checked: true, presence: attendee.presence };
    });
  }

  onDocumentationTypeChange(): void {
    this.setDocumentsSelection(this.documentSelection);

    this.authAgentDeclaration = false;
    this.onDisableHeaderBtn();
  }

  onMeetingAddressTypeSelect(type: MeetingLocationType): void {
    if(type === MeetingLocationType.Other) {
      this.form.get('address')?.patchValue(new Address());
      this.documentsService.disabledHeaderBtn.set(true);
      return;
    }

    if(type === MeetingLocationType.Registered) {
      this.form.get('address')?.patchValue(this.company?.registeredAddress);
    } else if(type === MeetingLocationType.Principal) {
      this.form.get('address')?.patchValue(this.company?.principalAddress);
    } else {
      this.form.get('address')?.patchValue(new Address());
    }

    this.addressStatus = undefined;

    this.onDisableHeaderBtn();
  }

  onDataStatusChange(addressStatus: AddressStatus | undefined): void {
    this.addressStatus = addressStatus;
    if(addressStatus === AddressStatus.WARNING) {
      this.documentsService.disabledHeaderBtn.set(true);
    }
  }

  onChairPersonSelect(chairPersonId: string): void {
    if (!this.formModel.authorisingParty.relationshipId) {
      this.formModel.authorisingParty.relationshipId = chairPersonId;
    }

    this.onDisableHeaderBtn();
  }

  onDisableHeaderBtn(): void {
    this.documentsService.disabledHeaderBtn.set(!this.validateForm());
  }

  //TODO should be to group the officers by type when ind will be available
  getOfficers(includeALt = true, icludeSec = true) {
    let officersSelected: Relationship[] = [];
    const includedTypes: RelationshipType[] = [RelationshipType.Director];

    if (includeALt) {
      includedTypes.push(RelationshipType.AlternativeDirector);
    }
    if (icludeSec) {
      includedTypes.push(RelationshipType.Secretary);
    }

    officersSelected = this.individualsList.filter(
      x => x.type != undefined && includedTypes.includes(x.type)
    );

    return officersSelected;
  }

  getAttendees() {
    return this.getOfficers(false, false).map(x => ({
      officer: x,
      checked: false,
      presence: MinutesMeetingPresence.PresentPhysically
    }));
  }

  getMinutesMeetingAttendeeFor362(directorNameList: string[]): MinutesMeetingAttendee[] {
    if (directorNameList?.length) {
      const attendeesArray: MinutesMeetingAttendee[] = [];
      const attendees: MinutesMeetingAttendee = {
        individual: new IndividualData({
          firstName: '',
          middleName1: '',
          middleName2: '',
          lastName: '',
          fullName: '',
          fullNameWithMiddle: '',
          formerName: new ParsedName(),
          din: '',
          address: new Address(),
          birthCity: '',
          birthCountry: '',
        })
      }

      directorNameList.forEach(name=> {
        const parsedName = parseFullName(name);
        attendees.individual.firstName = parsedName.firstName;
        attendees.individual.middleName1 = parsedName.middleName1;
        attendees.individual.middleName2 = parsedName.middleName2;
        attendees.individual.lastName = parsedName.lastName;

        attendeesArray.push(attendees);
      });

      return attendeesArray
    }

    return [];
  }

  private changeAuthorisationUpdate(): void {
    this.document.changeAuthorisation = this.formModel;

    if(
      this.formModel.meetingAddressType === MeetingLocationType.Registered
      || this.formModel.meetingAddressType === MeetingLocationType.Principal
      || this.formModel.meetingAddressType === MeetingLocationType.Other
    ) {
      this.document.changeAuthorisation.meetingAddress = this.form.get('address')?.getRawValue() as Address;
    }

    if (!this.company?.officers?.length && this.documentTypeIs362) {
      const directorName = this.form.get('directorName')?.value as string;
      const phone = this.form.get('phone')?.value as string;
      const email = this.form.get('email')?.value as string;

      if(!this.document.changeAuthorisation.authorisingParty) {
        this.document.changeAuthorisation.authorisingParty = new Relationship();
      }

      if (this.document.changeAuthorisation.authorisingParty.type === undefined) {
        this.document.changeAuthorisation.authorisingParty.type = RelationshipType.Director;
      }

      if (!this.document.changeAuthorisation.authorisingParty.individualDataOverride?.fullName?.trim()) {
        this.document.changeAuthorisation.authorisingParty.individualDataOverride = new IndividualData();
        this.document.changeAuthorisation.authorisingParty.details = new OfficerRelationshipDetails();
        const individualDataOverride = this.document.changeAuthorisation.authorisingParty.individualDataOverride!;
        const parsedName = parseFullName(directorName);

        individualDataOverride.firstName = parsedName.firstName;
        individualDataOverride.lastName = parsedName.lastName;
        individualDataOverride.middleName1 = parsedName.middleName1;
        individualDataOverride.middleName2 = parsedName.middleName2;
        this.document.changeAuthorisation.authorisingParty.contact = new Contact({
          firstName: parsedName.firstName, lastName: parsedName.lastName, phone, email
        });
      }
    }

    if(this.formModel.documentationType == DocumentationType.Minutes) {
      if (this.documentTypeIs362 || (this.document.entityId === null && this.documentTypeIs484)) {
        const directorNameList = this.form.get('directorNameList')?.value as string[];
        this.formModel.attendees = this.getMinutesMeetingAttendeeFor362(directorNameList);
      } else {
        if(this.attendeesList.length) {
          this.formModel.attendees = this.attendeesList.map(x => new MinutesMeetingAttendee({
            individual: x.officer.individualDataOverride!,
            presence: x.presence
          }));
        }
      }

      this.document.changeAuthorisation.attendees = this.formModel.attendees;
    }

    if (this.formModel.meetingDate) {
      const meetingDate = this.formModel.meetingDate as Date | NgbDateStruct | string;
      const date = meetingDate instanceof Date ? meetingDate : typeof meetingDate === 'string' ? new Date(meetingDate) : DatepickerHelper.getDateFromStruct(meetingDate);
      const serverDateFormat = formatDate(date, 'YYYY-MM-dd', 'en-US');
      this.document.changeAuthorisation.meetingDate = serverDateFormat as unknown as Date;
    }

    if(this.formModel.authorisingParty.relationshipId) {
      const relationshipId = this.formModel.authorisingParty.relationshipId;
      const authorisingParty = this.getOfficers().find(officer => officer.relationshipId === relationshipId);

      if (authorisingParty && !this.documentTypeIs362) {
        this.document.changeAuthorisation.authorisingParty = deepClone(authorisingParty);
      }
    }

    if(this.formModel.chairperson?.relationshipId) {
      const relationshipId = this.formModel.chairperson.relationshipId;
      const chairperson = this.getOfficers(false, false).find(chair => chair.relationshipId === relationshipId);

      if (chairperson) {
        this.document.changeAuthorisation.chairperson = deepClone(chairperson);
      }
    }

    if(this.formModel.directorReplacedByAlternative?.relationshipId) {
      const relationshipId = this.formModel.directorReplacedByAlternative.relationshipId;
      const director = this.getOfficers(false, false).find(director => director.relationshipId === relationshipId);

      if (director) {
        this.document.changeAuthorisation.directorReplacedByAlternative = director;
      }
    }
  }

  checkFillForm(): void {
    const isAuthorisingRelationshipId = this.document.changeAuthorisation?.authorisingParty?.relationshipId
      && this.document.changeAuthorisation?.authorisingParty?.relationshipId !== '00000000-0000-0000-0000-000000000000';

    if(this.document.changeAuthorisation?.documentationType === DocumentationType.Resolution || this.document.changeAuthorisation?.documentationType === DocumentationType.FormOnly) {
      if(this.document.changeAuthorisation?.signingManager && isAuthorisingRelationshipId) {
        this.isFormFilled = true;
      } else if (!this.company?.officers?.length && this.documentTypeIs362) {
        const contact = this.document.changeAuthorisation?.authorisingParty?.contact;
        const validContact = this.form.get('directorName')?.valid && this.form.get('phone')?.valid && this.form.get('email')?.valid;
        this.isFormFilled = !!(validContact && contact?.firstName && contact?.lastName && this.document.changeAuthorisation?.signingManager);
      } else if((this.documentTypeIs361 || this.documentTypeIs370 || this.isRaForm) && this.document.changeAuthorisation?.signingManager) {
        this.isFormFilled = true;
      }
    } else if (this.document.changeAuthorisation?.documentationType === DocumentationType.Minutes) {
      if (!this.company?.officers?.length && this.documentTypeIs362) {
        const contact = this.document.changeAuthorisation?.authorisingParty?.contact;
        const isFormFilled = this.document.changeAuthorisation?.attendees?.length
          && this.document.changeAuthorisation?.meetingDate
          && this.document.changeAuthorisation?.signingManager
          && this.form.get('directorName')?.valid
          && this.form.get('phone')?.valid
          && this.form.get('email')?.valid;

        if(this.formModel.meetingAddressType === MeetingLocationType.Other) {
          if (this.document.changeAuthorisation.meetingAddress?.normalizedFullAddress && isFormFilled) {
            this.isFormFilled = true;
          }
        } else if (this.formModel.meetingAddressType === MeetingLocationType.Online && isFormFilled) {
          if (this.document.changeAuthorisation.meetingOnlineAddress) {
            this.isFormFilled = true;
          }
        } else {
          this.isFormFilled = !!isFormFilled;
        }
      }

      if (this.document.changeAuthorisation.meetingDate && this.document.changeAuthorisation?.signingManager && isAuthorisingRelationshipId) {
        if (this.documentTypeIs362 || (this.document.entityId === null && this.documentTypeIs484)) {
          if(this.document.changeAuthorisation?.attendees?.length) {
            this.isFormFilled = true;
          }
        }

        if(this.getOfficers(false, false).length > 1) {
          if(this.formModel.chairperson?.relationshipId && this.document.changeAuthorisation.chairperson) {
            this.isFormFilled = true;
          }
        }

        if(this.formModel.meetingAddressType === MeetingLocationType.Other) {
          if (this.document.changeAuthorisation.meetingAddress?.normalizedFullAddress) {
            this.isFormFilled = true;
          }
        } else if (this.formModel.meetingAddressType === MeetingLocationType.Online) {
          if (this.document.changeAuthorisation.meetingOnlineAddress) {
            this.isFormFilled = true;
          }
        } else {
          this.isFormFilled = true;
        }
      }
    }
  }

  private validateForm(): boolean {
    this.changeAuthorisationUpdate();
    this.checkFillForm();

    if (!this.isFormFilled) {
      return false;
    }

    if ((this.documentTypeIs361 || this.isRaForm) && !this.authAgentDeclaration) {
      return false;
    }

    if (!this.declaration) {
      return false;
    }

    return true;
  }

  async save(): Promise<void> {
    try {
      this.documentsService.saveLoading.set(true);

      this.document.documentStatus = DocumentStatusEnum.Draft;
      await firstValueFrom(this.documentsService.patchDocument(this.document.documentId, this.observer));
      this.toastr.success("Document saved", "Success");

      this.documentsService.saveLoading.set(false);

      await this.router.navigate(['/documents']);
    } catch (e) {
      this.toastr.error("Error happened", "Failed");
      console.log(e);
    }
  }

  async confirm(): Promise<void> {
    if (!this.validateForm()) {
      return;
    }

    this.document.documentStatus = DocumentStatusEnum.SignaturePending;

    try {
      this.documentsService.patchLoading.set(true);

      if(!this.initialChangeAuthExists) {
        this.addChangeAuthToObservable();
      }

      await firstValueFrom(this.documentsService.patchDocument(this.document.documentId, this.observer));
      if (this.document.changeAuthorisation) {
        const visibleDocuments = this.documentSelection?.filter(d => isDocumentVisible(d, this.formModel.documentationType)) ?? [];
        this.document.changeAuthorisation.documentSelection = this.documentSelection?.length ? { selection: this.formatDocuments(visibleDocuments) } : this.document.changeAuthorisation.documentSelection;
        await firstValueFrom(this.documentsService.authoriseDocument(this.document.documentId, this.document.changeAuthorisation));
        this.updateDocuments.emit(true);
      }
      this.toastr.success("Document confirmed", "Success");
      this.nextStep.emit(true);
      this.documentsService.patchLoading.set(false);
    } catch (e) {
      this.toastr.error("Error happened", "Failed");
      this.documentsService.patchLoading.set(false);
      console.log(e);
    }
  }

  onGridReady(gridApi: GridApi): void {
    this.gridApi = gridApi;
  }

  addChangeAuthToObservable(): void {
    const changeAuthorisation = deepClone(this.document.changeAuthorisation) as ChangeAuthorisation;
    this.document.changeAuthorisation = undefined;
    this.observer.unobserve();
    this.observer = observe(this.document);
    this.document.changeAuthorisation = changeAuthorisation;
  }

  onDocumentSelectionChange(documentSelections: DocumentSelection[]): void {
    this.documentSelection = documentSelections;
  }

  formatDocuments(documentsSelection: DocumentSelection[]): DocumentSelection[] {
    return documentsSelection.map(document => ({...document, included: document.mandatory || document.included}))
  }

  get documentTypeIs361(): boolean {
    return this.document?.type === '361' || this.document?.type === 'c:361';
  }

  get documentTypeIs362(): boolean {
    return this.document?.type === '362' || this.document?.type === 'c:362' || this.document?.type === 'c:362a';
  }

  get documentTypeIs370(): boolean {
    return this.document?.type === '370' || this.document?.type === 'c:370';
  }

  get documentTypeIs484(): boolean {
    return this.document?.type === '484' || this.document?.type === 'c:484';
  }

  get documentTypeIs410b(): boolean {
    return this.document?.type === '410b' || this.document?.type === 'c:410b';
  }

  get documentTypeIs410f(): boolean {
    return this.document?.type === '410f' || this.document?.type === 'c:410f';
  }

  declarationSelect(ev: boolean): void {
    this.declarationStateChange.emit(ev);
  }

  onDocumentTypeSelectionChange(): void {
    this.declaration = false;
  }
}
