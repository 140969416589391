import { Component, Input } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { CoverLetter } from "../../../../../../models/cover-letter";
import { ICellRendererParams } from "ag-grid-community";

type CellRendererParams = ICellRendererParams<CoverLetter> & {
  placeholder: string | null;
};

@Component({
  selector: 'app-ag-cover-letter-file',
  standalone: true,
  imports: [],
  templateUrl: './ag-cover-letter-file.component.html',
  styleUrl: './ag-cover-letter-file.component.scss'
})
export class AgCoverLetterFileComponent implements ICellRendererAngularComp {
  @Input() fileName = '';

  agInit(params: CellRendererParams): void {
    this.fileName = params.value || '';
  }

  refresh(params: CellRendererParams): boolean {
    this.fileName = params.value || '';
    return true;
  }
}
