<div class="confirm-modal">
  <app-common-modal-form
    [header]="title"
    [icon]="icon"
    [closeText]="closeText"
    [confirmText]="confirmText"
    [isLoading]="loading"
    (close)="close()"
    (confirm)="onConfirm()"
  >
    {{ message }}
  </app-common-modal-form>
</div>
