<div class="card">
  <div class="card-header">
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <i class="ic-bulk-changes ic-md icon"></i>
        <div class="d-flex flex-column ml-3">
          <div class="alerts-title">Bulk Changes</div>
        </div>
      </div>
      @if(isMatchesSelected) {
        <app-button [text]="'Continue'" [iconClassLast]="'icon ic-arrow-right'" (clickBtn)="bulkChangeAddress()"></app-button>
      }
    </div>
  </div>
  <div class="info-container">
    <i class="ic-info-circle ic-md icon"></i>
    <div class="text">{{pageInfo}}</div>
  </div>
  <div class="search-container" [formGroup]="form">
    <app-address-autocomplete
      class="d-block mt-2"
      placeholder="Type Entity Name or Address that you want to change"
      formControlName="search"
      [showBulkSearch]="true"
      [dataStatus]="addressStatus"
      [clearStatusAfter]="5000"
      [isDisabled]="isDisabled || this.modalOpened()"
      (onSelect)="parseAddress($event)"
      (onTextChange)="textChange($event)"
    ></app-address-autocomplete>
  </div>

  @if(form.get('search')?.value || bulkRowData.length) {
    <div class="card-body">
      <app-list-grid
        [rowData]="bulkRowData"
        [colDefs]="colDefs"
        [suppressRowClickSelection]="true"
        [pagination]="true"
        [paginationPageSize]="pageSizeService.getPageSize"
        [paginationPageSizeSelector]="true"
        [suppressCellFocus]="true"
      ></app-list-grid>
    </div>
  } @else {
    <div class="bulk-changes-container">
      <div class="title">Other Bulk Changes</div>
      <div class="bulk-wrapper">
        @for(bulk of bulkChanges; track $index) {
          <a class="bulk-item" [routerLink]="bulk.link">
            <div>
              <i class="ic-arrow-right icon"></i>
            </div>
            <div class="d-flex flex-column gap-12">
              <div class="title">{{bulk.title}}</div>
              <div class="description">{{bulk.description}}</div>
            </div>
          </a>
        }
      </div>
    </div>
  }
</div>
