<div class="alerts-container">
  <div class="alerts-title-container" (click)="openAlert()">
    <div class="title" [ngClass]="{'title-height': message}">
      {{ title }}
    </div>

    @if(message) {
      <div class="message">
        {{ message }}
      </div>
    }
  </div>
</div>

