import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appInputDirectorName]',
  standalone: true
})
export class InputDirectorNameDirective {
  @HostListener('input', ['$event'])
  onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    const regex = /^[a-zA-Z0-9\- ,;]*$/;
    const value = input.value;
    if (!regex.test(value)) {
      input.value = value.replace(/[^a-zA-Z0-9\- ,;]/g, '');
    }
  }

}
