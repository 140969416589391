import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CellDataType } from "../../../../models/enums/agGridEnums";
import { CheckboxComponent } from "../../common/checkbox/checkbox.component";
import { DateRangePickerComponent } from "../../common/date-range-picker/date-range-picker.component";
import { DividerComponent } from "../../common/divider/divider.component";
import { NumberRangeInputComponent } from "../../common/number-range-input/number-range-input.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SearchInputComponent } from "../../common/search-input/search-input.component";
import { ColDefCommunityExtended, DateRange, GroupByOption, NumberRange } from "../../../../models/gridFilter";

@Component({
  selector: 'app-search-filter-list',
  standalone: true,
  imports: [
    CheckboxComponent,
    DateRangePickerComponent,
    DividerComponent,
    NumberRangeInputComponent,
    ReactiveFormsModule,
    SearchInputComponent,
    FormsModule
  ],
  templateUrl: './search-filter-list.component.html',
  styleUrl: './search-filter-list.component.scss'
})
export class SearchFilterListComponent {
  @Input() column!: ColDefCommunityExtended;
  @Input() groupByOptions: GroupByOption[] = [];
  @Output() dateRangeSelected = new EventEmitter<DateRange>();
  @Output() numberRangeSelected = new EventEmitter<NumberRange>();
  @Output() filterOptionChange = new EventEmitter<{indexOption: number, active: boolean}>();
  @Output() filterSearch = new EventEmitter<string>();
  @Output() searchByOption = new EventEmitter<string>();

  searchText = '';

  onDateRangeSelected($event: DateRange): void {
    this.dateRangeSelected.emit($event);
  }

  onNumberRangeSelected($event: NumberRange): void {
    this.numberRangeSelected.emit($event)
  }

  onFilterOptionChange(indexOption: number, active: boolean): void {
    this.filterOptionChange.emit({ indexOption, active })
  }

  onSearchFilter(searchText: string): void {
    this.filterSearch.emit(searchText);
  }

  onSearch(searchText: string): void {
    this.searchByOption.emit(searchText);
  }

  protected readonly CellDataType = CellDataType;
}
