import { Component, inject } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { NgForOf } from "@angular/common";

@Component({
  selector: 'app-me',
  standalone: true,
  templateUrl: './me.component.html',
  imports: [NgForOf],
  styleUrl: './me.component.scss'
})
export class MeComponent {
  authService = inject(AuthService);
  Object = Object;
}
