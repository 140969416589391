import { EmailPayloadBase } from "../emailPayloadBase";

export class AsicDocumentsReviewEmailPayload extends EmailPayloadBase {
  static override $type = 'AsicDocumentsReviewEmailPayload';

  companyName: string;
  companyACN: string;
  reviewerName: string;
  documentName: string;
  documentType: string;
  lodgementDeadline: string;
  lodgementDeadlineShortFormat: string;
  tenDaysBeforeLodgementDeadline: string;
  tenDaysBeforeLodgementDeadlineShortFormat: string;
  partner: string;
  asicContact: string;
  accountManager: string;
  billingContactFirstName: string;
  billingContactFullName: string;
  username: string;
  reviewUrl: string;

  constructor(data: Partial<AsicDocumentsReviewEmailPayload> = {}) {
    super(data);
    this.companyName = data.companyName ?? '';
    this.companyACN = data.companyACN ?? '';
    this.reviewerName = data.reviewerName ?? '';
    this.documentName = data.documentName ?? '';
    this.documentType = data.documentType ?? '';
    this.lodgementDeadline = data.lodgementDeadline ?? '';
    this.lodgementDeadlineShortFormat = data.lodgementDeadlineShortFormat ?? '';
    this.tenDaysBeforeLodgementDeadline = data.tenDaysBeforeLodgementDeadline ?? '';
    this.tenDaysBeforeLodgementDeadlineShortFormat = data.tenDaysBeforeLodgementDeadlineShortFormat ?? '';
    this.partner = data.partner ?? '';
    this.asicContact = data.asicContact ?? '';
    this.accountManager = data.accountManager ?? '';
    this.billingContactFirstName = data.billingContactFirstName ?? '';
    this.billingContactFullName = data.billingContactFullName ?? '';
    this.username = data.username ?? '';
    this.reviewUrl = data.reviewUrl ?? '';
  }
}
