import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonComponent } from "../../../components/common/button/button.component";
import { ClickableStepperComponent } from "../../../components/common/clickable-stepper/clickable-stepper.component";
import { StepperFormComponent } from "../stepper-form.component";

@Component({
  selector: 'app-stepper-form-appliable',
  standalone: true,
  imports: [
    ButtonComponent,
    ClickableStepperComponent,
  ],
  templateUrl: './stepper-form-appliable.component.html',
  styleUrls: ['./stepper-form-appliable.component.scss', '../stepper-form.component.scss']
})
export class StepperFormAppliableComponent extends StepperFormComponent {
  @Input() hideApplyChangesButton = false;
  @Input() isApplyChangesLoading = false;
  @Input() applyChangesButtonText = 'Apply Changes';

  @Output() applyChanges = new EventEmitter<void>();
}
