import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loader-standalone',
  standalone: true,
  templateUrl: './loader-standalone.component.html',
  styleUrl: './loader-standalone.component.scss'
})
export class LoaderStandaloneComponent {
  @Input() loading= false;
  @Input() customClass = 'mh-200'
}
