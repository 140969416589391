import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { DisclaimerComponent } from "../disclaimer/disclaimer.component";
import { ButtonComponent } from "../button/button.component";
import { LoaderStandaloneComponent } from "../loader-standalone/loader-standalone.component";

@Component({
  selector: 'app-file-upload',
  standalone: true,
  imports: [
    DisclaimerComponent,
    ButtonComponent,
    LoaderStandaloneComponent
  ],
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.scss'
})
export class FileUploadComponent {
  @ViewChild('fileUploadInput') fileUploadInput!: ElementRef<HTMLInputElement>;

  @Input() label = '';
  @Input() disabled = false;
  @Input() isLoading = false;
  @Input() multiple = true;
  @Input() checkFormats = true;
  @Input() acceptedFormatsList: string[] = [];
  @Input() lodgeView = false;

  @Output() change = new EventEmitter<File[]>();

  @HostListener('dragover', ['$event']) onDragover(event: DragEvent): void {
    event.stopPropagation();
    event.preventDefault();
    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event']) onDragleave(event: DragEvent): void {
    event.stopPropagation();
    event.preventDefault();
    this.fileOver = false;
  }

  @HostListener('drop', ['$event']) onDrop(event: DragEvent): void {
    event.stopPropagation();
    event.preventDefault();
    this.fileOver = false;
    const files = Array.from((event.dataTransfer as unknown as { files: File[] }).files) ?? [];
    if (files.length)
      this.handleFileUpload(files);
  }

  errorMessage = '';
  fileOver = false;

  handleFileUpload(files: File[]) {
    if (this.disabled || this.isLoading) return;
    if (this.checkFormats && !files.some((file) => this.acceptedFormatsList.some((fileExtension) => file.name.endsWith(fileExtension)))) {
      this.setErrorMessage(`Uploaded file has not allowed file extension. Please use one of allowed file extensions: ${ this.acceptedFormats }`);
      return;
    }

    this.setErrorMessage();
    this.change.emit(files);
  }

  handleFileInputEvent(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    if (this.disabled || this.isLoading) return;
    this.handleFileUpload(Array.from((event.target as unknown as { files: File[] }).files) ?? []);
  }

  tryHandleFileUpload(event: Event, fileUploadInput: HTMLInputElement): void {
    event.stopPropagation();
    event.preventDefault();
    this.setErrorMessage();

    this.fileUploadInput.nativeElement.value = '';
    fileUploadInput.click();
  }

  setErrorMessage(errorMessage = ''): void {
    this.errorMessage = errorMessage;
  }

  get acceptedFormats(): string {
    return this.acceptedFormatsList.join(', ');
  }
}
