<app-masked-input
  mask="___-___"
  [placeholder]="placeholder"
  [label]="label"
  [link]="link"
  [text]="text"
  [switchBtnText]="switchBtnText"
  [helperText]="helperText"
  [shouldShowErrors]="shouldShowErrors"
  [readonly]="readonly"
  [validationError]="validationError"
  [checkedState]="checkedState"
  [warningState]="warningState"
  [customErrors]="customErrors"
  (switchBtnClick)="switchBtnClick.emit($event)"
></app-masked-input>
