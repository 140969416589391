<app-stepper-form
  header="Add a Trust"
  submitButtonText="Create Trust"
  [showSteps]="false"
  [showSaveAndCompleteLaterBtn]="false"
  [steps]="steps"
  [currentStep]="currentStep"
  [currentStepIndex]="currentStepIndex"
  [form]="stepperForm"
  [isLoading]="isLoading"
  (stepIndexChange)="setCurrentStep($event)"
  (confirm)="submit()"
  (close)="close()"
>
  <div class="step-content" [formGroup]="stepperForm">
    <section
      class="step-section"
      [formGroupName]="StepsEnum.TrustInformation"
      [hidden]="currentStep !== StepsEnum.TrustInformation"
    >
      <h1 class="h1-heading">{{ steps[currentStepIndex].label }}</h1>

      <div class="form-group">
        <app-input
          label="Trust Name"
          formControlName="name"
        ></app-input>
      </div>

      <div class="form-group">
        <app-select-groups
          label="Trust Type"
          formControlName="trustSubType"
          [optionGroups]="trustTypesOptionGroups"
        ></app-select-groups>
      </div>

      <div class="form-group">
        <app-abn-masked-input formControlName="entityNumber"></app-abn-masked-input>
      </div>

      <div class="form-group">
        <app-date-picker
          label="Commencement Date"
          formControlName="dateOfEstablishment"
          [minDate]="minDate"
          [maxDate]="maxDate"
        ></app-date-picker>
      </div>

      <div class="form-group">
        <app-select
          label="Jurisdiction"
          formControlName="jurisdiction"
          [options]="JurisdictionTypeOptions"
          [valuePrimitive]="true"
        ></app-select>
      </div>
    </section>
  </div>
</app-stepper-form>
