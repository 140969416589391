import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DatepickerHelper } from '../../../../../custom-form-validators/date-picker-form-validators';
import { Company } from '../../../../../models/company';
import { Document } from '../../../../../models/document';
import { EntityChangeData } from '../../../../../models/entityChangeData';
import { CompanyChangeData } from '../../../../../models/enums/companyChangeData';
import { ChangeDictionaryHelper } from '../../../../../models/shared/change-dictionary-helper.model';
import { Form410FComponent } from './form410f.component';
import { PaymentMethod, paymentMethodsNames } from '../../../company-name-change/CompanyChangeName';

export enum Form410FStepsEnum {
  FormDetails = 0,
  CompanyNameDetails = 1,
  ExtensionDetails = 2
}

export class Form410FModel extends EntityChangeData {
  static override readonly $type = 'Form410FTransaction';

  proposedCompanyName = '';
  reservationDocumentNumber = '';
  existingExpiryDate: Date;
  extensionReason = '';
  paymentMethod: PaymentMethod;

  constructor(data: Partial<Form410FModel> = {}) {
    super(data);

    this.changeType = 'c:410f'

    this.proposedCompanyName = data.proposedCompanyName ?? '';
    this.reservationDocumentNumber = data.reservationDocumentNumber ?? '';
    this.existingExpiryDate = DatepickerHelper.toDate(data.existingExpiryDate);
    this.extensionReason = data.extensionReason ?? '';
    this.paymentMethod = data.paymentMethod ?? PaymentMethod.INV;
  }

  override toDictionary(): { key: string; value: string; }[] {
    const dict = new ChangeDictionaryHelper();

    dict
      .add('Proposed new company name', this.proposedCompanyName)
      .add('Name reservation document number', this.reservationDocumentNumber)
      .add('Extension Reason', this.extensionReason)
      .add('Payment Method', paymentMethodsNames[this.paymentMethod])
      .addDate('Date of reservation expiry', this.existingExpiryDate);

    return dict.dictionary;
  }

  fillModal(modalRef: NgbModalRef, document: Document, actualCompany: Company): NgbModalRef {
    modalRef = this.fillModalWithCompany(modalRef, actualCompany, true);
    (modalRef.componentInstance as Form410FComponent).companyChangeData.documentId = document.documentId;
    return modalRef;
  }

  fillModalWithCompany(modalRef: NgbModalRef, company: Company, isEdit = false): NgbModalRef {
    const instance = (modalRef.componentInstance as Form410FComponent);

    instance.isEdit = isEdit;
    instance.companyChangeData = new CompanyChangeData(company);
    instance.formModel = structuredClone(this);

    return modalRef;
  }

  override prepareToRequest(): Form410FModel {
    return {
      ...this,
      ...super.prepareToRequest(),
      existingExpiryDate: DatepickerHelper.buildDateString(this.existingExpiryDate),
    };
  }
}
