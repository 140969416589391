<div class="action-container">
  <app-ag-action-icon-button
    iconClass="ic-notes"
    [disabled]="!params.data?.entityId"
    (action)="openNotes()"
  ></app-ag-action-icon-button>

  <app-ag-action-icon-button
    iconClass="ic-eye"
    (action)="openDocument()"
  ></app-ag-action-icon-button>

  <div ngbDropdown container="body" [placement]="['bottom-end', 'bottom-start', 'top-start', 'top-end']"
       class="dropdown d-inline-flex">
    <app-ag-action-icon-button
      ngbDropdownToggle
      iconClass="ic-three-dots"
    ></app-ag-action-icon-button>

    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
      <div ngbDropdownItem class="dropdown-item" (click)="lodge()">
        <a class="menu-action-button">
          <span>Lodge</span>
        </a>
      </div>

      <div ngbDropdownItem class="dropdown-item" (click)="markAsSigned()">
        <a class="menu-action-button">
          <span>Mark as Signed</span>
        </a>
      </div>

      <div ngbDropdownItem class="dropdown-item" (click)="markAsLodged()">
        <a class="menu-action-button">
          <span>Mark as Lodged</span>
        </a>
      </div>

      <div ngbDropdownItem class="dropdown-item" (click)="downloadDocument()">
        <a class="menu-action-button">
          <span>Download</span>
        </a>
      </div>

      @if (params.data?.asicStatus === AsicStatus.Lodged) {
        <div ngbDropdownItem class="dropdown-item" (click)="openForm492()">
          <a class="menu-action-button">
            <span>Request for Correction</span>
          </a>
        </div>

        <div ngbDropdownItem class="dropdown-item" (click)="openForm902()">
          <a class="menu-action-button">
            <span>Notification for Supplementary Information</span>
          </a>
        </div>
      }

      @if (documentDeleteAvailable) {
        <div ngbDropdownItem class="dropdown-item" (click)="deleteDocument()">
          <a class="menu-action-button">
            <span>Delete Document</span>
          </a>
        </div>
      }
    </div>
  </div>
</div>
