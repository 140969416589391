<div class="edit-email-template-modal-wrapper">
  <app-common-modal-form
    icon="ic-edit"
    confirmText="Save"
    closeText="Cancel"
    subheader="Edit Template"
    [header]="header"
    [formGroup]="form"
    [isLoading]="isLoading"
    (close)="close()"
    (confirm)="confirm()"
  >
    <div class="confirm-button">
      <app-button
        text="Test"
        btnColor="white"
        [isDisabled]="isLoading"
        (clickBtn)="testTemplate()"
      />

      <app-button
        text="Reset to Default"
        btnColor="white"
        [isDisabled]="isLoading"
        (clickBtn)="resetTemplate()"
      />
    </div>

    <div class="edit-email-template-modal-content">
      <div class="controls-wrapper">
        <div class="form-group">
          <app-input
            label="Subject"
            formControlName="subjectTemplate"
            [customErrors]="subjectTemplateCustomErrors"
          />

          @if (keysMatchErrorMessages.subjectTemplate) {
            <div class="keys-not-match-error-message" [innerHtml]="keysMatchErrorMessages.subjectTemplate"></div>
          }
        </div>

        <div class="form-group position-relative">
          <app-textarea
            label="Body"
            formControlName="bodyTemplate"
            [rows]="15"
            [customErrors]="bodyTemplateCustomErrors"
          />

          @if (keysMatchErrorMessages.bodyTemplate) {
            <div class="keys-not-match-error-message" [innerHtml]="keysMatchErrorMessages.bodyTemplate"></div>
          }

          <div class="email-template-show-keys-button pl-2">
            <div
              #dropdown="ngbDropdown"
              ngbDropdown
              container="body"
              [placement]="['start-top', 'start-bottom', 'end-top', 'end-bottom']"
            >
              <app-ag-action-icon-button
                ngbDropdownToggle
                iconClass="ic-help-circle"
                (mouseenter)="dropdown.open()"
              />

              <div ngbDropdownMenu class="codes-preview" (mouseleave)="dropdown.close()">
                <app-templates-legend-table [preview]="legendPreview"/>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <app-textarea
            label="Footer"
            formControlName="footerTemplate"
            [rows]="8"
            [customErrors]="footerTemplateCustomErrors"
          />

          @if (keysMatchErrorMessages.footerTemplate) {
            <div class="keys-not-match-error-message" [innerHtml]="keysMatchErrorMessages.footerTemplate"></div>
          }
        </div>
      </div>

      <div class="preview-wrapper">
        <div class="preview-label">Email Preview</div>

        <div class="template-wrapper">
          <app-shadow-dom-viewer [customStyle]="customStyle" [htmlContent]="htmlContent()"/>
        </div>
      </div>
    </div>
  </app-common-modal-form>
</div>
