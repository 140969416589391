import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TemplateCodePreview } from "../../../../../../models/text-messages/textMessagesHelper";
import { AppCopyDirective } from "../../../../../../directives/copy.directive";

@Component({
  selector: 'app-templates-legend-table',
  standalone: true,
  imports: [AppCopyDirective],
  template: `
    <div class="templates-legend-table">
      <table>
        <thead>
        <tr>
          <th>Key</th>
          <th>Description</th>
        </tr>
        </thead>

        <tbody>
          @for (row of preview; track row) {
            <tr>
              <td>
                <span class="key" appCopy>{{ row.key }}</span>
              </td>
              <td>
                <div class="description" [innerHtml]="row.description"></div>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  `,
  styles: `
    .templates-legend-table {
      max-width: 600px;
      max-height: 500px;
      overflow-y: auto;
      border-radius: 4px;

      thead {
        position: sticky;
        top: 0;
        z-index: 1;
      }

      .description {
        max-width: 230px;
      }

      table {
        thead {
          background-color: var(--gray200);
        }

        th, td {
          padding: 8px 12px;
        }

        th:first-child {
          border-bottom-left-radius: 4px;
          border-top-left-radius: 4px;
        }

        th:last-child {
          border-bottom-right-radius: 4px;
          border-top-right-radius: 4px;
        }

        tr:not(:last-child) td {
          border-bottom: 1px solid color-mix(in srgb, transparent, #181d1f 15%);
        }
      }
    }

    :host::ng-deep {
      .fa-copy {
        margin-left: 10px !important;
      }
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplatesLegendTableComponent {
  @Input() preview: TemplateCodePreview = [];
}
