<div class="custom-toggle">
  <div class="form-check form-switch">
    <input
      class="form-check-input"
      type="checkbox"
      role="switch"
      id="flexSwitchCheckDefault"
      [(ngModel)]="value"
      [disabled]="disabled"
      (ngModelChange)="switch($event)"
    >

    @if(label) {
      <label class="form-check-label" for="flexSwitchCheckDefault">{{ label }}</label>
    }
  </div>
</div>
