import { Component, Input, OnInit } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { abnValidator } from '../../../../../../validators/abn.validators';
import { MaskedInputComponent } from '../../masked-input.component';

@Component({
  selector: 'app-abn-masked-input',
  standalone: true,
  imports: [MaskedInputComponent, ReactiveFormsModule],
  templateUrl: './abn-masked-input.component.html',
})
export class AbnMaskedInputComponent extends MaskedInputComponent implements OnInit {
  @Input() override label = 'ABN';
  @Input() override placeholder = '__ ___ ___ ___';
  @Input() override customErrors = {
    required: 'ABN is required.',
    abn: 'Invalid ABN.',
  };

  ngOnInit(): void {
    this.ngControl.control?.setValidators([
      Validators.minLength(11),
      Validators.maxLength(11),
      abnValidator()
    ]);
  }
}
