<app-common-offcanvas
  [width]="notesContainerWidth"
  [opened]="opened()"
  (close)="closeNotes()"
>
  <div class="header">
    <div class="w-100 d-flex align-items-center justify-content-between">
      <h2 class="h2-heading m-0 p-0">Notes</h2>

      @if (entityType !== null && entity !== null && currentEntityId !== null) {
        <div>
          <app-ag-tag-entity-title
            [isLink]="true"
            [entity]="entity"
            [entityType]="entityType"
          ></app-ag-tag-entity-title>
        </div>
      }
    </div>
  </div>

  <div
    appScrollLoad
    class="notes"
    [disabled]="!allowLoadMoreNotes"
    (scrolledToBottom)="loadMoreNotes()"
  >
    <div #controlWrapper>
      <app-create-edit-note-control
        [note]="noteForEdit"
        [isLoading]="isLoading"
        (downloadFile)="downloadFile($event.filename, $event.note)"
        (submitNote)="submitNote($event)"
        (clear)="noteForEdit = null"
      ></app-create-edit-note-control>
    </div>

    <div class="notes-cards-wrapper">
      <ul class="notes-cards">
        @for (note of notes; track note.id) {
          <li class="note-card">
            <app-note-list-element
              [note]="note"
              [disabled]="isLoading || note.id === noteForEdit?.['id']"
              (deleteNote)="deleteNote(note.id)"
              (editNote)="editNote(note); controlWrapper.scrollIntoView({block: 'start', inline: 'start', behavior: 'smooth' });"
              (downloadFile)="downloadFile($event, note)"
              (deleteFile)="deleteFile($event, note)"
            ></app-note-list-element>
          </li>
        }

        @if (isLoading) {
          <app-loader-standalone [loading]="isLoading"></app-loader-standalone>
        }
      </ul>
    </div>
  </div>
</app-common-offcanvas>
