import { Pipe, PipeTransform } from '@angular/core';
import { AlertType } from "../../models/enums/alertEnums";

@Pipe({
  name: 'alertsType',
  standalone: true
})
export class AlertsTypePipe implements PipeTransform {
  transform(value: AlertType | undefined | null): string {
    if(value != undefined) {
      if (value === AlertType.DebtReports) {
        return 'Debt Reports'
      } else if (value === AlertType.ValidationReport) {
        return 'Validation Reports'
      } else if (value === AlertType.AsicInvoice) {
        return 'ASIC Invoices'
      } else if (value === AlertType.ChangeOfNameCertificate) {
        return 'Change Of Name Certificates'
      } else if (value === AlertType.CompanyStatement) {
        return 'Company Statements'
      } else if (value === AlertType.AsicDataIssue) {
        return 'ASIC Data Issues'
      } else if (value === AlertType.IdentifiedExternalChanges) {
        return 'Identified External Changes'
      } else if (value === AlertType.XpmSyncIssue) {
        return 'XPM Sync Issues'
      } else if (value === AlertType.Others) {
        return 'Other'
      }
    }

    return '-';
  }
}
