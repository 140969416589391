<div class="lodgement-documents-container">
  <div class="title-container">
    <h3>Prepare Lodgement</h3>
  </div>

  <div class="d-flex flex-column gap-24">
    <app-notification
      [iconClass]="'icon ic-md ic-timer'"
      [color]="'orange'"
      [title]="'Late Fee'"
      [text]="lateFeeText"
      [amount]="amountFee.toString()"
    ></app-notification>

    <div class="lodgement-info">{{prepareLodgementInfo}}</div>
  </div>
</div>
