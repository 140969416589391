import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from './auth.service';

export const authGuard: CanActivateFn = async (route) => {
  const authService = inject(AuthService);
  const router = inject(Router);


  if (!authService.isAuthenticated) {
    await authService.login();
    return false;
  }

  if (route.routeConfig?.path !== 'settings/my-organisation' && authService.profile.organisationSetupRequired) {
    await router.navigate(['settings/my-organisation'], { state: { accountSetup: true }});
    return false;
  }
  
  return true;
};
