import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DocumentsComponent } from './documents/documents.component';
import { IndividualComponent } from './individual/individual.component';
import { IndividualsComponent } from './individuals/individuals.component';
import { AccountsComponent } from './settings/components/accounts/accounts.component';
import { AgentSettingsComponent } from './settings/components/agent-settings/agent-settings.component';
import { OrganisationDetailsPageComponent } from './settings/components/organisation-details-page/organisation-details-page.component';
import { CompaniesComponent } from './companies/companies.component';
import { AuthService } from '../services/auth.service';
import { authGuard } from '../services/auth.guard';
import { CompanyProfileComponent } from './company-profile/company-profile.component';
import { MeComponent } from './dev/me/me.component';
import { devGuard } from '../services/dev.guard';
import { SettingsComponent } from './settings/settings.component';
import { GenerateDocumentsComponent } from './generate-documents/generate-documents.component';
import { ManageTagsComponent } from './settings/components/tags/manage-tags.component';
import { TrustsComponent } from './trusts/trusts.component';
import { TrustProfileComponent } from './trust-profile/trust-profile.component';
import { SharesComponent } from './shares/shares.component';
import { AllAlertsComponent } from "./alerts/all-alerts/all-alerts.component";
import { RequireAttentionAlertsComponent } from "./alerts/require-attention-alerts/require-attention-alerts.component";
import { PostponedAlertsComponent } from "./alerts/postponed-alerts/postponed-alerts.component";
import { InboxAlertsComponent } from "./alerts/inbox-alerts/inbox-alerts.component";
import { BulkChangesComponent } from "./bulk-changes/bulk-changes.component";
import { DocumentTemplatesPageComponent } from "./settings/components/document-templates-page/document-templates-page.component";
import { IntegrationsPageComponent } from "./settings/components/integrations-page/integrations-page.component";
import {
  DocumentSettingsPageComponent
} from "./settings/components/document-settings-page/document-settings-page.component";
import { AnnualReadyToSendComponent } from "./annual-statements/annual-ready-to-send/annual-ready-to-send.component";
import { AnnualExpectedComponent } from "./annual-statements/annual-expected/annual-expected.component";
import { AnnualNotReceivedComponent } from "./annual-statements/annual-not-received/annual-not-received.component";
import { AnnualSentComponent } from "./annual-statements/annual-sent/annual-sent.component";
import { AnnualAllComponent } from "./annual-statements/annual-all/annual-all.component";
import { AnnualArchivedComponent } from "./annual-statements/annual-archived/annual-archived.component";
import { AnnualOverdueComponent } from "./annual-statements/annual-overdue/annual-overdue.component";
import { AnnualPaymentDueComponent } from "./annual-statements/annual-payment-due/annual-payment-due.component";
import { EmailTemplatesPageComponent } from "./settings/components/email-templates-page/email-templates-page.component";
import { BrandingPageComponent } from "./settings/components/branding-page/branding-page.component";
import { AnnualStatementComponent } from "./annual-statement/annual-statement.component";
import { EmailSettingsPageComponent } from "./settings/components/email-settings-page/email-settings-page.component";
import {
  TextMessagesTemplatesPageComponent
} from "./settings/components/text-messages-templates-page/text-messages-templates-page.component";
import { RemindersPageComponent } from "./settings/components/reminders-page/reminders-page.component";
import {
  OutgoingEmailMessagesComponent
} from "./outgoing-messages/outgoing-email-messages/outgoing-email-messages.component";
import {
  OutgoingSmsMessagesComponent
} from "./outgoing-messages/outgoing-sms-messages/outgoing-sms-messages.component";
import {
  BulkSendDocumentsForSigningComponent
} from "./bulk-send-documents-for-signing/bulk-send-documents-for-signing.component";

const routes: Routes = [
  { path: '', redirectTo: '/companies', pathMatch: 'full' },
  { path: 'companies', component: CompaniesComponent, title: 'Companies', canActivate: [authGuard]},
  { path: 'company-profile/:id', component: CompanyProfileComponent, title: "Company Profile", canActivate: [authGuard]},
  { path: 'trusts', component: TrustsComponent, title: 'Trusts', canActivate: [authGuard]},
  { path: 'trust-profile/:id', component: TrustProfileComponent, title: "Trust Profile", canActivate: [authGuard]},
  {
    path: 'alerts',
    title: 'Alerts',
    canActivate: [authGuard],
    canActivateChild: [authGuard],
    children: [
      { path: '', redirectTo: 'all', pathMatch: 'full' },
      { path: 'inbox', component: InboxAlertsComponent, title: 'Inbox' },
      { path: 'all', component: AllAlertsComponent, title: 'All' },
      { path: 'required', component: RequireAttentionAlertsComponent, title: 'Required' },
      { path: 'postponed', component: PostponedAlertsComponent, title: 'Postponed' },
    ],
  },
  {
    path: 'annual',
    title: 'Annual Statements',
    canActivate: [authGuard],
    canActivateChild: [authGuard],
    children: [
      { path: '', redirectTo: 'ready-to-send', pathMatch: 'full' },
      { path: 'ready-to-send', component: AnnualReadyToSendComponent, title: 'Ready to Send' },
      { path: 'not-received', component: AnnualNotReceivedComponent, title: 'Not Received' },
      { path: 'expected', component: AnnualExpectedComponent, title: 'Expected' },
      { path: 'sent', component: AnnualSentComponent, title: 'Sent' },
      { path: 'payment-due', component: AnnualPaymentDueComponent, title: 'Payment Due' },
      { path: 'overdue', component: AnnualOverdueComponent, title: 'Overdue' },
      { path: 'archived', component: AnnualArchivedComponent, title: 'Archived' },
      { path: 'all', component: AnnualAllComponent, title: 'All' },
    ],
  },
  { path: 'annual-statement/:id', component: AnnualStatementComponent, title: 'Annual Statements', canActivate: [authGuard]},
  { path: 'individuals', component: IndividualsComponent, title: 'Individuals', canActivate: [authGuard]},
  { path: 'individual/:id', component: IndividualComponent, title: 'Individual', canActivate: [authGuard]},
  { path: 'documents', component: DocumentsComponent, title: 'Documents', canActivate: [authGuard]},
  { path: 'bulk-changes', component: BulkChangesComponent, title: 'Bulk Changes', canActivate: [authGuard]},
  { path: 'bulk-send-documents-for-signing', component: BulkSendDocumentsForSigningComponent, title: 'Bulk Send Documents For Signing', canActivate: [authGuard]},
  { path: 'shares/:id', component: SharesComponent, title: 'Shares', canActivate: [authGuard]},
  { path: 'settings', component: SettingsComponent, title: 'Settings', canActivate: [authGuard]},
  { path: 'settings/my-organisation', component: OrganisationDetailsPageComponent, title: 'My Account', canActivate: [authGuard]},
  { path: 'settings/accounts', component: AccountsComponent, title: 'Accounts', canActivate: [authGuard]},
  { path: 'settings/agent-settings', component: AgentSettingsComponent, title: 'Agent Settings', canActivate: [authGuard]},
  { path: 'settings/branding', component: BrandingPageComponent, title: 'Branding', canActivate: [authGuard]},
  { path: 'settings/tags', component: ManageTagsComponent, title: 'Tags', canActivate: [authGuard]},
  { path: 'settings/cover-letter-templates', component: DocumentTemplatesPageComponent, title: 'Cover Letter Templates', canActivate: [authGuard]},
  { path: 'settings/email-settings', component: EmailSettingsPageComponent, title: 'Email Settings', canActivate: [authGuard]},
  { path: 'settings/email-templates', component: EmailTemplatesPageComponent, title: 'Email Templates', canActivate: [authGuard]},
  { path: 'settings/integrations', component: IntegrationsPageComponent, title: 'Integrations', canActivate: [authGuard]},
  { path: 'settings/document-settings', component: DocumentSettingsPageComponent, title: 'Document Settings', canActivate: [authGuard]},
  { path: 'settings/sms-templates', component: TextMessagesTemplatesPageComponent, title: 'SMS Templates', canActivate: [authGuard]},
  { path: 'settings/reminders', component: RemindersPageComponent, title: 'Reminders', canActivate: [authGuard]},
  { path: 'document/:id', component: GenerateDocumentsComponent, title: 'Generate Documents', canActivate: [authGuard]},
  {
    path: 'outgoing-messages',
    title: 'Outgoing Messages',
    canActivate: [authGuard],
    canActivateChild: [authGuard],
    children: [
      { path: 'email', component: OutgoingEmailMessagesComponent, title: 'Outgoing Email Messages' },
      { path: 'sms', component: OutgoingSmsMessagesComponent, title: 'Outgoing SMS Messages' },
      { path: '**', redirectTo: 'email' },
    ]
  },

  //dev
  { path: 'dev/me', component: MeComponent, title: 'Me', canActivate: [authGuard, devGuard]},

  { path: '**', redirectTo: '/companies' },
];

function initializeAuthService(authService: AuthService): () => Promise<void> {
  return () => authService.init();
}

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    AuthService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAuthService,
      deps: [AuthService],
      multi: true
    }
  ],
})
export class AppRoutingModule { }
