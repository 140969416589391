import { AfterContentInit, AfterViewInit, Component, DestroyRef, inject, Input, ViewChild } from '@angular/core';
import { StepperFormComponent } from "../stepper-form.component";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { DocumentsService } from "../../../../services/documents.service";
import { Router } from "@angular/router";
import { CompanyChangeData } from "../../../../models/enums/companyChangeData";
import { IStep } from "../../../../models/step";
import { FormGroup } from "@angular/forms";
import { DocumentStatusEnum } from "../../../../models/enums/documentStatusEnum";
import { catchError, EMPTY, finalize, Observable, of, switchMap, tap } from "rxjs";
import { Document } from "../../../../models/document";
import { enumToSteps } from "../../../../functions/enums-to-list-formatter";
import { EntityChangeData } from "../../../../models/entityChangeData";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { DatepickerHelper } from "../../../../custom-form-validators/date-picker-form-validators";
import { DocumentAuthorise } from "../../../../models/documentAuthorise";
import { TrustsService } from "../../../../services/trustsService";
import { Address } from "../../../../models/address";
import { Operation } from "fast-json-patch";

export abstract class BaseBulkStepperForm<T> {
  isEdit = false;
  isLoading = false;
  redirectAfterSubmit = false;
  steps!: IStep<T>[];
  currentStepIndex!: number;
  currentStep!: T;
  abstract stepperForm: FormGroup;

  abstract setCurrentStep(newStepIndex: number): void;

  abstract buildDocument(): Document[] | null;

  abstract confirm(): void;

  abstract close(isEdit: boolean): void;
  abstract setupChange(): void;
}

export type DocumentBulkSubmitter = OmitThisParameter<(documents: Document[], saveAndCompleteLater?: boolean) => Observable<{
  id: string
}[]>>;

@Component({
  selector: 'app-base-bulk-stepper-form',
  standalone: true,
  imports: [],
  templateUrl: './base-bulk-stepper-form.component.html',
  styleUrl: './base-bulk-stepper-form.component.scss'
})
export class BaseBulkStepperFormComponent<T, Change extends EntityChangeData> implements BaseBulkStepperForm<T>, AfterContentInit, AfterViewInit {
  @ViewChild(StepperFormComponent) stepperFormComponent!: StepperFormComponent;

  destroyRef = inject(DestroyRef);
  activeModal = inject(NgbActiveModal);
  toastr = inject(ToastrService);
  trustsService = inject(TrustsService);
  documentsService = inject(DocumentsService);
  router = inject(Router);

  @Input() companyChangeData = new CompanyChangeData();
  @Input() formModel!: Change;
  @Input() documentSubmitter: DocumentBulkSubmitter = this.documentsService.createOrUpdateBulkDocuments.bind(this.documentsService);

  isEdit = false;
  isLoading = false;
  redirectAfterSubmit = false;
  forceClose = false;

  StepsEnum!: any;
  steps!: IStep<T>[];
  currentStepIndex = 0;
  currentStep!: T;

  stepperForm: FormGroup = new FormGroup({});

  ngAfterContentInit() {
    this.activeModal.update({ size: 'xl' });
  }

  ngAfterViewInit(): void {
    this.setupStepperComponentMethods();
  }

  setCurrentStep(newStepIndex: number): void {
    this.currentStep = this.steps[newStepIndex].step;
    this.currentStepIndex = newStepIndex;
  }

  setupChange(): void {
    if (!this.isEdit) {
      return;
    }

    console.warn('Method not implemented. Override "setupChange" method.');
    console.warn(this);
  }

  saveAndCompleteLater(): void {
    this.submit(true);
  }

  confirm(): void {
    this.submit(false);
  }

  submit(draft: boolean): void {
    if (this.stepperForm.invalid) {
      this.toastr.error('Please fill required fields', 'Error');
      this.stepperForm.controls[this.currentStep as string]?.markAllAsTouched();

      return;
    }

    const documents = this.buildDocument();

    if (!documents?.length)
      return;

    documents.forEach(document => {
      document.changes.forEach(change => {
        change.changeDate = DatepickerHelper.buildDateString(change.changeDate);
      });
      document.documentStatus = draft ? DocumentStatusEnum.Draft : DocumentStatusEnum.AuthorisationPending;
    });

    this.isLoading = true;
    this.stepperForm.disable();

    const isOnlyTrust = documents.every(d => !d.changeAuthorisation)
    const documentsWithoutChangeAuthorization = documents.filter(d => !d.changeAuthorisation);
    if(isOnlyTrust) {
      this.updateTrustAddress(documentsWithoutChangeAuthorization, isOnlyTrust);
      return;
    } else if(documentsWithoutChangeAuthorization.length && documents[0].changes[0].$type === 'CompanyChangeAddress') {
      this.updateTrustAddress(documentsWithoutChangeAuthorization, isOnlyTrust);
    }

    const documentsWithChangeAuthorization = documents.filter(d => d.changeAuthorisation);
    if(documentsWithChangeAuthorization.length) {
      this.documentSubmitter(documentsWithChangeAuthorization, draft)
        .pipe(
          switchMap((createdDocuments: { id: string }[]) => {
            if (draft) {
              void this.router.navigate(['/documents']);
              return EMPTY;
            } else {
              documentsWithChangeAuthorization.forEach((item, index) => item.documentId = createdDocuments[index].id);

              return this.documentsService.authoriseBulkDocuments(createdDocuments, documentsWithChangeAuthorization).pipe(
                tap((documentAuthorise: (DocumentAuthorise | null)[]) => {
                  if (documentAuthorise.length) {
                    void this.router.navigate(['/documents']);
                  } else {
                    this.toastr.error('Failed to authorise Document.', 'Error');
                  }
                }),
              );
            }
          }),
          finalize(() => {
            this.toastr.success('Document Saved', 'Success');
            this.isLoading = false;
            this.activeModal.close(new Document(documents[0]).changes);
            this.stepperForm.enable();
            this.afterSubmit(documents[0].changes);
          })
        )
        .subscribe({
          error: (error) => {
            console.warn(error);
            this.toastr.error('Failed to save Document.', 'Error');
            this.isLoading = false;
          },
        });
    }
  }

  buildDocument(): Document[] | null {
    throw new Error('Method not implemented. Override "buildDocument" method.');
  }

  close(forceClose = false): void {
    this.forceClose = forceClose;

    if (this.isLoading && !forceClose) {
      alert('Please wait for the operation to complete before closing the modal.');
      return;
    }

    this.activeModal.dismiss(forceClose);
  }

  setupSteps(stepEnum: any): void {
    this.StepsEnum = stepEnum as unknown as Record<string, number>;
    this.steps = enumToSteps(stepEnum);
    this.currentStepIndex = 0;
    this.currentStep = this.steps[this.currentStepIndex].step;
  }

  afterSubmit(changes: EntityChangeData[]): void {
  }

  setupStepperComponentMethods(): void {
    this.stepperFormComponent.stepIndexChange
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(this.setCurrentStep.bind(this));

    this.stepperFormComponent.close
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.close());

    this.stepperFormComponent.saveAndCompleteLater
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.submit(true);
      });

    this.stepperFormComponent.confirm
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.submit(false);
      });
  }

  updateTrustAddress(documents: Document[], isOnlyTrust: boolean): void {
    const selectedTrustIds = documents
      .filter(document => document.entityId)
      .map(document => ({ entityId: document.entityId })) as { entityId: string }[];

    const operation: Operation[] = [{
      op: 'replace',
      path: '/registeredAddress',
      value: (documents[0].changes[0] as unknown as { address: Address }).address
    }];

    if(selectedTrustIds.length) {
      this.trustsService.bulkPatchOperationTrust(selectedTrustIds, operation).pipe(
        catchError(error => {
          console.warn(error);
          this.toastr.error('Failed to change Trusts Address.', 'Error');
          this.isLoading = false;
          return of([]);
        })
      ).subscribe(res => {
        if (res.length && isOnlyTrust) {
          this.toastr.success(
            'The address change for the trust has been successfully updated.',
            'Success',
          );
          this.activeModal.close();
          void this.router.navigate(['/trusts']);
        }
        this.isLoading = false;
      });
    }
  }
}
