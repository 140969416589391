import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'documentType',
  standalone: true,
})
export class DocumentTypePipe implements PipeTransform {
  static prefixes = ['t:', 'i:'];

  transform(value: string | undefined | null): string{
    if (!value) {
      return '';
    }

    if (value.startsWith('c:')) {
      return (value === 'c:362:a') ? 'Form 362' : 'Form ' + value.substring(2).replace(':', ' ');
    } else if (value === '362:a') {
      return 'Form 362';
    } else {
      const prefix = DocumentTypePipe.prefixes.find(prefix => value.startsWith(prefix));

      if (prefix) {
        return prefix.substring(0, prefix.length - 1) + ' ' + value.substring(2);
      }

      return value;
    }
  }

}
