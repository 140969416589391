import { Injectable } from '@angular/core';
import { Observable, of } from "rxjs";
import { IReminderItem } from "../app/settings/components/reminders-page/reminders-page.component";
import {
  ReminderSendingMethods
} from "../app/settings/components/reminders-page/components/ag-reminder-sending-method-label/ag-reminder-sending-method-label.component";

@Injectable({
  providedIn: 'root'
})
export class RemindersService {
  public loadReminders(): Observable<IReminderItem[]> {
    return of([
      {
        name: 'Annual Statement Payment Reminder',
        id: '0',
        dates: [],
        sendingMethod: null,
        autoSending: false,
        time: null,
      },
      {
        name: 'Company Debt Reminder',
        id: '1',
        dates: [],
        sendingMethod: null,
        autoSending: false,
        time: null,
      },
      {
        name: 'Signing Reminder for Annual Statement',
        id: '2',
        dates: [],
        sendingMethod: null,
        autoSending: false,
        time: null,
      },
      {
        name: 'Signing Reminder for ASIC Forms',
        id: '3',
        dates: [2, 3, 4],
        sendingMethod: ReminderSendingMethods.smsAndEmail,
        autoSending: true,
        time: '07:00 AM',
      },
    ]);
  }

  public saveReminderChanges(reminder: IReminderItem): Observable<IReminderItem> {
    return of(reminder);
  }
}
