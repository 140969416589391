import { FilterOption } from "../../models/gridFilter";
import { SplitByCapitalLetterPipePipe } from "../../pipes/split-by-capital-letter-pipe.pipe";

export function convertEnumToFilterOption(enumObj: Record<string, string | number>, reverse = false): FilterOption[] {
  if (!reverse) {
    return Object.keys(enumObj).filter((v) => isNaN(Number(v))).map(key => ({
      label: key.charAt(0).toUpperCase() + new SplitByCapitalLetterPipePipe().transform(key).slice(1).replace(/-/g, ' '),
      value: enumObj[key].toString(),
      active: false
    })).sort((a, b) => a.label.localeCompare(b.label));
  } else {
    return Object.keys(enumObj).filter((v) => isNaN(Number(v))).map(key => ({
      label: new SplitByCapitalLetterPipePipe().transform(enumObj[key].toString()),
      value: key,
      active: false
    })).sort((a, b) => a.label.localeCompare(b.label));
  }
}
