<app-stepper-form
  header="Voluntary Deregistration"
  subheader="Form 6010"
  [showSteps]="false"
  [steps]="stepsSignal()"
  [currentStep]="currentStep"
  [currentStepIndex]="currentStepIndex"
  [isLoading]="isLoading"
  [form]="stepperForm"
>

  <div class="step-content" [formGroup]="stepperForm">
    <app-stepper-form-description
      formKey="6010"
      [hidden]="currentStep !== StepsEnum.FormDescription"
    ></app-stepper-form-description>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.ApplicantDeclaration"
      [hidden]="currentStep !== StepsEnum.ApplicantDeclaration"
    >
      <h1 class="h1-heading">{{ steps[currentStepIndex].label }}</h1>

      <div class="form-group">
        <app-checkbox
          label="I declare that the statements below are correct"
          formControlName="declarationNoAssetsLiabilities"
        ></app-checkbox>
      </div>

      <ol class="voluntary-deregistration-declarations">
        @for (declaration of voluntaryDeregistrationDeclarations; track declaration) {
          <li>{{ declaration }}</li>
        }
      </ol>
    </section>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.DetailsOfTheApplicant"
      [hidden]="currentStep !== StepsEnum.DetailsOfTheApplicant"
    >
      <h1 class="h1-heading">{{ steps[currentStepIndex].label }}</h1>

      <div class="form-group">
        <app-radio
          label="Is the Applicant"
          formControlName="isApplicantDirector"
          [options]="isApplicantDirectorOptions"
        ></app-radio>
      </div>

      <div [hidden]="detailsOfTheApplicantForm.controls.isApplicantDirector.value !== true">
        <!-- Director Selected -->

        <div class="form-group">
          <app-select
            label="Director"
            formControlName="applicantIfDirector"
            [options]="directorsOptions"
            [valuePrimitive]="true"
          ></app-select>
        </div>
      </div>

      <div
        [formGroup]="detailsOfTheApplicantForm"
        [hidden]="detailsOfTheApplicantForm.controls.isApplicantDirector.value !== false"
      >
        <h3 class="h3-heading">Nominee Details</h3>

        <div class="form-group">
          <app-individual-data-form-group
            [formGroup]="detailsOfTheApplicantForm.controls.applicantIfNominee"
            [hiddenControls]="disabledIndividualControlKeys"
          />
        </div>
      </div>

      <div class="form-group">
        <app-radio
          label="Payment Method"
          formControlName="paymentMethod"
          [options]="paymentMethodOptions"
        ></app-radio>
      </div>
    </section>

    <app-authorised-signatories-step
      [hidden]="currentStep !== StepsEnum.AuthorisedSignatories"
      [title]="steps[currentStepIndex].label"
      [formGroup]="authorisedSignatoriesForm"
      [securityRegistryRecords]="securityRegistryRecords"
    />

    <section
      class="step-section"
      [hidden]="currentStep !== StepsEnum.BeneficialOwnersDetails"
    >
      <h1 class="h1-heading">{{ steps[currentStepIndex].label }}</h1>

      @if (holders && holders.length) {
        <nbo-beneficial-owners-group
          [holders]="holders"
          [formGroup]="stepperForm.controls[StepsEnum.BeneficialOwnersDetails]"
          (dataSaved)="updateSignValue($event.index, $event.newBOName)"
        ></nbo-beneficial-owners-group>
      }
    </section>
  </div>
</app-stepper-form>
