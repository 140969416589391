<app-common-modal-form
  [header]="'Upload Files'"
  [icon]="'icon ic-upload-file'"
  [confirmText]="'Save'"
  [closeText]="'Cancel'"
  [footerClass]="'d-flex justify-content-end flex-row-reverse'"
  [isInvalid]="!uploadedFiles.length"
  (confirm)="confirm()"
  (close)="close()"
>
  <div class="body-container">
    @if(errorMessage) {
      <div class="error-msg">
        <div class="icon-container">
          <i class="icon ic-cancel-circle"></i>
        </div>
        <div>{{errorMessage}}</div>
      </div>
    }

    @if(uploadedFiles.length) {
      <div class="files-container">
        @for(file of uploadedFiles; track $index) {
          <app-attached-file-label
            [readonly]="false"
            [filename]="file.name"
            [hideDownloadBtn]="true"
            (deleteFile)="deleteFile($index)"
          ></app-attached-file-label>
        }
      </div>
    } @else {
      <app-file-upload
        [multiple]="true"
        [acceptedFormatsList]="acceptedFormatsList"
        (change)="onFilesLoaded($event)"
      ></app-file-upload>
    }

    <div class="note">{{uploadNotification}}</div>
  </div>
</app-common-modal-form>
