<div class="full-width-panel" [ngClass]="{'warning': hasIssue, 'no-warning': !hasIssue}">
  <app-list-grid
    [defaultExcelExportParams]="excelExportParams"
    [defaultCsvExportParams]="csvExportParams"
    [colDefs]="$any(columnDefs)"
    [rowData]="rowData"
    [statusBar]="statusBar"
    [paginationPageSize]="paginationPageSize"
    [pagination]="true"
    (gridReady)="onGridReady($event)"
  ></app-list-grid>
</div>
