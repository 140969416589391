import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shareTypePipe',
  standalone: true
})
export class ShareTypePipe implements PipeTransform {
  transform(value: string | null | undefined, htmlView = true): string {
    if (!value) return '';

    const parts = value.split('@');
    if (parts.length !== 2) return value;
    const [type, amount] = parts;

    const formattedAmount = amount.replace(/\$/g, '').split('/')
      .map(part => {
        const number = parseFloat(part);
        if (number) {
          return `$${number.toFixed(2)}`;
        }

        return;
      }).filter(part => part).join('/');

    return htmlView ? `<b>${type}</b>@${formattedAmount}` : `${type}@${formattedAmount}`;
  }
}
