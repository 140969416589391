<div
  ngbAccordion
  class="menu-item-wrapper"
  [class.active]="isActive"
  [class.top-level]="isTopLevelElement"
>
  <div ngbAccordionItem>
    <a
      ngbAccordionHeader
      class="menu-item"
      container="body"
      routerLinkActive="active"
      [routerLink]="navMenuitemData.routerLink"
      [class.collapse-menu]="collapsedMenu"
      [class.is-accordion]="navMenuitemData.nestedItems?.length"
      [ngbTooltip]="collapsedMenu ? navMenuitemData.title : ''"
      (isActiveChange)="isActive = $event"
    >
      <div class="left-side">
        <div class="menu-icon" [hidden]="!navMenuitemData.icon">
          <i class="fad icon ic-md {{ navMenuitemData.icon }}"></i>
        </div>

        <div class="title text-truncate" [title]="navMenuitemData.title">{{ navMenuitemData.title }}</div>
      </div>

      <div class="right-side">
        <div
          class="count text-truncate"
          [title]="navMenuitemData.count"
          [hidden]="!navMenuitemData.count"
        >
          <span>{{ navMenuitemData.count }}</span>
        </div>

        @if (!collapsedMenu && navMenuitemData.nestedItems?.length) {
          <button
            class="toggle-button btn"
            ngbAccordionToggle
            type="button"
            (click)="$event.preventDefault(); $event.stopPropagation(); false;"
          >
            <i class="icon ic-xs ic-chevron-down"></i>
          </button>
        }
      </div>
    </a>

    <div [class.pl-4]="!collapsedMenu" [hidden]="collapsedMenu" ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          @for(child of navMenuitemData.nestedItems; track child.title) {
            <app-nav-menu-link
              [navMenuitemData]="child"
              [collapsedMenu]="collapsedMenu"
            ></app-nav-menu-link>
          }
        </ng-template>
      </div>

    </div>
  </div>
</div>
