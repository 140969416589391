import { Pipe, PipeTransform } from '@angular/core';
import { TrustStatus } from '../../models/enums/trustEnum';
@Pipe({
  standalone: true,
  name: 'trustStatus'
})
export class TrustStatusPipe implements PipeTransform {
  transform(value: TrustStatus | null): string {
    if(value !== null)
      return TrustStatus[value];

    return '-';
  }
}
