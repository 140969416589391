<app-stepper-form
  header="New Name"
  subheader="Form 484A2"
  [showSteps]="false"
  [steps]="steps"
  [currentStep]="currentStep"
  [currentStepIndex]="currentStepIndex"
  [isLoading]="isLoading"
  [form]="stepperForm"
>
  <div class="step-content" [formGroup]="stepperForm">
    <app-stepper-form-description
      formKey="484 A2"
      [hidden]="currentStep !== StepsEnum.FormDescription"
    ></app-stepper-form-description>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.CompanyMemberName"
      [hidden]="currentStep !== StepsEnum.CompanyMemberName"
    >
      <h1 class="h1-heading">{{ steps[currentStep].label }}</h1>

      <div class="form-group">
        <app-date-picker
          label="Date of Change"
          formControlName="changeDate"
          [minDate]="minDate"
          [maxDate]="maxDate"
          [customErrors]="customDatepickerErrors"
        ></app-date-picker>
      </div>

      <div class="form-group">
        <app-select
          label="Which Officeholder/Shareholder is the name change applicable to?"
          formControlName="selectedOfficerId"
          [options]="officersChangeNameOptions"
          [valuePrimitive]="true"
        ></app-select>
      </div>

      <div
        formGroupName="userData"
        [hidden]="!selectedOfficerTypeSignal() || isCorporateSecurityHolderSignal()"
      >
        <h3 class="h3-heading">New Name</h3>

        <div class="form-group">
          <app-input
            label="First Name"
            formControlName="firstName"
            [customErrors]="directorFirstNameValidatorMsg"
          ></app-input>
        </div>

        <div class="form-group">
          <app-input
            label="First Middle Name"
            formControlName="middleName1"
            [customErrors]="directorFirstNameValidatorMsg"
          ></app-input>
        </div>

        <div class="form-group">
          <app-input
            label="Second Middle Name"
            formControlName="middleName2"
            [customErrors]="directorFirstNameValidatorMsg"
          ></app-input>
        </div>

        <div class="form-group">
          <app-input
            label="Last Name"
            formControlName="lastName"
            [customErrors]="directorFirstNameValidatorMsg"
          ></app-input>
        </div>
      </div>

      <div [hidden]="!isCorporateSecurityHolderSignal()">
        <div class="form-group">
          <app-input
            label="New Company Name"
            formControlName="companyName"
          ></app-input>
        </div>
      </div>
    </section>

    <section [hidden]="$any(currentStep) !== StepsWithSummaryEnum.Summary">
      <app-changes-applied></app-changes-applied>
    </section>
  </div>
</app-stepper-form>
