<div class="card">
  <div class="card-header">
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <i class="ic-companies ic-md icon"></i>
        <h2 class="h2-heading mb-0 ml-3">{{pageTitle}}</h2>
      </div>

      <div class="button-container">
        <app-button
          [text]="'Register Company'"
          [iconClass]="'icon ic-xs ic-plus'"
          [isDisabled]="modalOpened()"
          (clickBtn)="registerCompany()"
        ></app-button>

        <app-button
          style="width: 138px"
          [text]="'Add Company'"
          [iconClass]="'icon ic-xs ic-plus'"
          [isDisabled]="modalOpened()"
          (clickBtn)="addCompany()"
        ></app-button>

        @if (checkedCompanies.length) {
          <div ngbDropdown [placement]="['bottom-end', 'bottom-start', 'top-start', 'top-end']" class="dropdown">
            <app-button
              ngbDropdownToggle
              class="hide-button-text-thin-page"
              id="bulk-actions-dd"
              [text]="'Actions'"
              [iconLast]="true"
              [iconClass]="'icon ic-xs ic-select-icon'"
              [isDisabled]="modalOpened()"
            ></app-button>
            <div ngbDropdownMenu aria-labelledby="bulk-actions-dd" class="dropdown-menu dropdown-menu-right">
              <div class="dropdown-item" (click)="changeOfAddress()">
                <a class="dropdown-button">
                  <span>Change of Address</span>
                </a>
              </div>
              <div class="dropdown-item" (click)="appointOfficeholder()">
                <a class="dropdown-button">
                  <span>Appoint an Officeholder</span>
                </a>
              </div>
              <div class="dropdown-item" (click)="ceaseOfficeholder()">
                <a class="dropdown-button">
                  <span>Cease an Officeholder</span>
                </a>
              </div>
              <div class="dropdown-item" (click)="ceaseRegisteredAgent()">
                <a class="dropdown-button">
                  <span>Cease as a Registered Agent</span>
                </a>
              </div>
              <div class="dropdown-item" (click)="exportSelectedCompaniesToXls()">
                <a class="dropdown-button">
                  <span>Export to xlsx</span>
                </a>
              </div>
              <div class="dropdown-item" (click)="exportSelectedCompaniesToCsv()">
                <a class="dropdown-button">
                  <span>Export to csv</span>
                </a>
              </div>
              <div class="dropdown-item" (click)="manageTags()">
                <a class="dropdown-button">
                  <span>Manage Tags</span>
                </a>
              </div>
            </div>
          </div>
        } @else {
          <app-button
            id="export-company-list-dd"
            class="hide-button-text-thin-page"
            [text]="'Export Company List'"
            [iconClass]="'icon ic-xs ic-download-file'"
            [isDisabled]="!companiesList.length || modalOpened()"
            (clickBtn)="exportCompanyList(false, ExportTypeEnum.EXCEL)"
          ></app-button>
        }
      </div>
    </div>
  </div>

  <app-grid
    [defaultExcelExportParams]="excelExportParams"
    [defaultCsvExportParams]="csvExportParams"
    [excelStyles]="excelStyles"
    [rowData]="companiesList"
    [colDefs]="$any(colDefs())"
    [pageTitle]="pageTitle"
    [pageSizeList]="pageSizeList"
    [getContextMenuItems]="$any(getContextMenuItems)"
    [loading]="loading"
    (itemSelect)="selectCompanies($event)"
    (gridReady)="onGridReady($event)"
  ></app-grid>
</div>

<app-notes
  [entityType]="EntityType.Company"
  [entity]="entityForNotes"
  [opened]="notesVisible"
  (close)="notesVisible = false"
></app-notes>

<app-alerts-list
  [alerts]="alerts"
  [entityType]="EntityType.Company"
  [entity]="entityForAlerts"
  [opened]="alertsVisible"
  (close)="alertsVisible = false"
></app-alerts-list>
