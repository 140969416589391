<div
  class="bolt-offcanvas-overlay"
  (click)="closeOffcanvas()"
  [@fadeAnimation]="fadeAnimation()"
  [hidden]="!opened()"
></div>

<div class="bolt-offcanvas" [style.--offcanvas-width]="width" [@expandOffcanvasAnimation]="expandOffcanvasAnimation()">
  <div class="header-wrapper">
    <button class="close-bolt-offcanvas-button" (click)="closeOffcanvas()">
      <span class="icon ic ic-close cursor-pointer"></span>
    </button>

    <div class="header">
      <ng-content select=".header"></ng-content>
    </div>
  </div>

  <div class="d-flex">
    <div class="spacer" (click)="closeOffcanvas()"></div>
    <div class="content flex-grow-1" [@expandOffcanvasAnimation]="expandOffcanvasAnimation()">
      <ng-content></ng-content>
    </div>
  </div>
</div>
