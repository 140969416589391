import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  signal,
  SimpleChanges
} from '@angular/core';
import { InputComponent } from "../common/input/input.component";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { SelectComponent } from "../common/select/select.component";
import { TextareaComponent } from "../common/textarea/textarea.component";
import { catchError, debounceTime, map, Observable, of, switchMap, tap } from "rxjs";
import { IEmailPreviewResponse } from "../../../models/email-templates/IEmailPreviewResponse";
import { EmailTemplatesHelper, IEmailTemplateMockedData } from "../../../models/email-templates/emailTemplatesHelper";
import { EmailTemplate } from "../../../models/email-templates/emailTemplate";
import { SelectOption } from "../../../models/selectOptions";
import { CustomFormValidators } from "../../../custom-form-validators/custom-form-validators";
import { OrganisationService } from "../../../services/organisation.service";
import { EmailTemplatesService } from "../../../services/email-templates.service";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import { SendOption } from "../../../models/enums/annualStatementEnums";
import { ESignBody } from "../../../models/signDocuments";
import { ShadowDomViewerComponent } from "../common/shadow-dom-viewer/shadow-dom-viewer.component";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { Company } from "../../../models/company";
import { ChangeAuthorisation } from "../../../models/changeAuthorisation";
import { LodgementDeadline } from "../../../models/documents";
import { formatDate } from "@angular/common";
import { UIProfile } from "../../../models/uiProfile";
import { ButtonComponent } from "../common/button/button.component";

@Component({
  selector: 'app-email-preview',
  standalone: true,
  imports: [
    InputComponent,
    ReactiveFormsModule,
    SelectComponent,
    TextareaComponent,
    FormsModule,
    ShadowDomViewerComponent,
    NgxSkeletonLoaderModule,
    ButtonComponent
  ],
  templateUrl: './email-preview.component.html',
  styleUrl: './email-preview.component.scss'
})
export class EmailPreviewComponent implements OnInit, OnChanges {
  @Input() sendBy: SendOption | undefined;
  @Input() changeAuthorisation: ChangeAuthorisation | null | undefined;
  @Input() lodgement: LodgementDeadline | undefined;
  @Input() paymentDeadline: string | null | undefined;
  @Input() reviewDate: string | null | undefined;
  @Input() company: Company | undefined;
  @Input() isDocument = false;
  @Input() previewMode = false;
  @Output() changeEmail = new EventEmitter<Partial<ESignBody>>();

  private organisationService = inject(OrganisationService);
  private emailTemplatesService = inject(EmailTemplatesService);
  private sanitizer = inject(DomSanitizer);
  private toastr = inject(ToastrService);
  private destroyRef = inject(DestroyRef);

  template!: EmailTemplate;
  emailTemplateOptions: SelectOption[] = [];
  emailTemplate = '';
  emailTemplates: EmailTemplate[] = [];
  organisationName = '';
  profile: UIProfile | undefined = this.organisationService.getCachedProfile();
  payloadModel: Partial<IEmailTemplateMockedData> | undefined;
  SendOption = SendOption;

  loading = false;
  showFooter = false;
  maxBodyRows = 8;
  maxFooterRows = 4;
  readonly PREVIEW_LOADING_DEBOUNCE_TIME = 1000;
  readonly customStyle = `.container {margin: 0 !important}`;

  form = new FormGroup({
    emailTemplateId: new FormControl<string | null>(null),
    organisationId: new FormControl<string | null>(null),
    code: new FormControl<string | null>(null),
    subjectTemplate: new FormControl<string | null>(null, [Validators.required, CustomFormValidators.minLength(1), CustomFormValidators.maxLength(200)]),
    bodyTemplate: new FormControl<string | null>(null, [Validators.required, CustomFormValidators.minLength(1), CustomFormValidators.maxLength(1000)]),
    footerTemplate: new FormControl<string | null>(null, [CustomFormValidators.maxLength(1000)]),
  });

  htmlContent = signal<SafeHtml | null>(null);
  logo = toSignal(this.organisationService.getSmallLogo()
    .pipe(map(b => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(b))))
    .pipe(catchError(() => of('assets/svg/bolt-logo-icon.svg'))));

  ngOnInit(): void {
    this.loading = true;

    this.emailTemplatesService.loadEmailTemplates().pipe(
      tap((templates) => {
        this.emailTemplateOptions = templates.map(template => ({
          label: template.subjectTemplate,
          value: template.emailTemplateId
        }));

        this.emailTemplates = templates;
        this.setEmailPreview();
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();

    this.form.valueChanges.pipe(
      debounceTime(this.PREVIEW_LOADING_DEBOUNCE_TIME),
      tap(value => {
        this.loading = true;
        if(value) {
          value.subjectTemplate = this.replaceNewLine(value.subjectTemplate ?? '');
          value.bodyTemplate = this.replaceNewLine(value.bodyTemplate ?? '');
          value.footerTemplate = this.replaceNewLine(value.footerTemplate ?? '');

          const emailData: Partial<ESignBody> = {
            payloadModel: this.payloadModel,
            emailTemplate: value as EmailTemplate
          }

          this.changeEmail.emit(emailData);
        }
      }),
      switchMap(() => this.loadPreview()),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['sendBy']?.currentValue && this.emailTemplates.length) {
      this.setEmailPreview();
    }
  }

  setEmailPreview(): void {
    if(this.isDocument && this.sendBy === SendOption.Email) {
      this.selectEmailTemplate('asic-documents');
    } else if(this.isDocument && this.sendBy === SendOption.Sign) {
      this.selectEmailTemplate( 'asic-documents-esign');
    } else if(!this.isDocument && this.sendBy === SendOption.Email) {
      this.selectEmailTemplate( 'as-email');
    } else if(!this.isDocument && this.sendBy === SendOption.Sign) {
      this.selectEmailTemplate( 'as-esign');
    }
  }

  selectEmailTemplate(templateCode: string): void {
    const emailTemplate = this.emailTemplates.filter(template => template.code === templateCode);
    if(emailTemplate.length) {
      this.template = emailTemplate[0];

      this.form.patchValue({
        emailTemplateId: this.template.emailTemplateId,
        organisationId: this.template.organisationId,
        code: this.template.code,
        subjectTemplate: this.fillSubjectTemplate(this.template.subjectTemplate),
        bodyTemplate: this.prepareTemplateString(this.fillBodyTemplate(this.template.bodyTemplate)),
        footerTemplate: this.prepareTemplateString(this.fillFooterTemplate(this.template.footerTemplate)),
      });

      const payloadModel: Partial<IEmailTemplateMockedData> = EmailTemplatesHelper.buildEmailPreviewRequest(this.buildTemplate()).payloadModel;
      payloadModel.organisationName = this.profile?.organisationName ?? '';
      payloadModel.organisationEmail = this.profile?.email ?? ''
      payloadModel.organisationPhone = this.profile?.phoneNumber ?? '';
      payloadModel.documentName = EmailTemplatesHelper.TemplateHeadersRecord[this.template.code];
      payloadModel.companyName = this.company?.name ?? '';
      payloadModel.directorFirstName = this.changeAuthorisation?.authorisingParty?.individualDataOverride?.firstName ?? this.changeAuthorisation?.chairperson?.individualDataOverride?.firstName ?? '';
      payloadModel.lodgementDeadline = this.lodgement?.lodgementDeadline ? formatDate(this.lodgement?.lodgementDeadline ?? '', 'dd MMM yyyy', 'en-US') : 'N/A';
      payloadModel.annualReviewFeeDeadline = this.paymentDeadline ? formatDate(new Date(this.paymentDeadline) ?? '', 'dd MMM yyyy', 'en-US') : '';
      payloadModel.year = this.reviewDate ? formatDate(new Date(this.reviewDate) ?? '', 'yyyy', 'en-US') : '';

      this.payloadModel = payloadModel;
    }
  }

  fillSubjectTemplate(subjectTemplate: string): string {
    let subject = '';
    if(this.company) {
      subject = subjectTemplate
        .replace('{{DocumentName}}', EmailTemplatesHelper.TemplateHeadersRecord[this.template.code])
        .replace('{{CompanyName}}', this.company.name);
    }

    return subject ? subject : subjectTemplate;
  }

  fillBodyTemplate(bodyTemplate: string): string {
    let body = '';
    if(this.company) {
      body = bodyTemplate
        .replaceAll('{{DirectorFirstName}}', this.changeAuthorisation?.authorisingParty?.individualDataOverride?.firstName ?? this.changeAuthorisation?.chairperson?.individualDataOverride?.firstName ?? '{{DirectorFirstName}}')
        .replaceAll('{{DocumentName}}', EmailTemplatesHelper.TemplateHeadersRecord[this.template.code])
        .replaceAll('{{CompanyName}}', this.company.name)
        .replaceAll('{{LodgementDeadline}}', this.lodgement?.lodgementDeadline ? formatDate(this.lodgement?.lodgementDeadline ?? '', 'dd MMM yyyy', 'en-US') : 'N/A')
        .replaceAll('{{OrganisationPhone}}', this.profile?.phoneNumber ?? '')
        .replaceAll('{{OrganisationEmail}}', this.profile?.email ?? '')
        .replaceAll('{{AnnualReviewFeeDeadline}}', this.paymentDeadline ? formatDate(new Date(this.paymentDeadline) ?? '', 'dd MMM yyyy', 'en-US') : '')
        .replaceAll('{{Year}}', this.reviewDate ? formatDate(new Date(this.reviewDate) ?? '', 'yyyy', 'en-US') : '');
    }

    return body ? body : bodyTemplate;
  }

  fillFooterTemplate(footerTemplate: string): string {
    let footer = '';
    if(this.company) {
      footer = footerTemplate
        .replace('{{Username}}', this.organisationService.getCurrentUser()?.fullName ?? '')
        .replace('{{OrganisationName}}', this.profile?.organisationName ?? '');
    }

    return footer ? footer : footerTemplate;
  }

  loadPreview(): Observable<IEmailPreviewResponse> {
    const errorFormResponse = of({ subject: '', htmlContent: '' });

    return this.emailTemplatesService.loadPreview(EmailTemplatesHelper.buildEmailPreviewRequest(this.buildTemplate()))
      .pipe(
        tap({
          next: (res) => {
            this.htmlContent.set(this.sanitizer.bypassSecurityTrustHtml(res.htmlContent) ?? '');
            this.loading = false;
          },
          error: (error) => {
            console.warn('[loadPreview] error: ', error);
            this.toastr.error("Something happened while loading preview", "Error");
            this.loading = false;
          }
        }),
        catchError(() => errorFormResponse),
      );
  }

  private buildTemplate(): EmailTemplate {
    return new EmailTemplate({
      ...this.template,
      subjectTemplate: this.template?.subjectTemplate,
      bodyTemplate: this.bodyTemplate,
      footerTemplate: this.footerTemplate,
    });
  }

  private replaceNewLine(text: string | null): string {
    return text?.replaceAll('\n', '</br>') ?? '';
  }

  private prepareTemplateString(text: string | null): string {
    return (text ?? '')
      .replaceAll('\n', '')
      .replaceAll('<br />', '\n')
      .replaceAll('</br>', '\n');
  }

  public toggleFooter(): void {
    this.showFooter = !this.showFooter;
  }

  get bodyTemplate(): string {
    return this.replaceNewLine(this.form.controls.bodyTemplate.value);
  }

  get footerTemplate(): string {
    return this.replaceNewLine(this.form.controls.footerTemplate.value);
  }
}
