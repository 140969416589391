import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { AuxiliaryService } from "../../../../services/auxiliary.service";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { firstValueFrom } from "rxjs";
import { MaskedInputComponent } from "../../common/masked-input/masked-input.component";
import { InputComponent } from "../../common/input/input.component";
import { ReusableFormGroupComponent } from "../reusable-form-group/reusable-form-group.component";
import { acnValidator } from "../../../../validators/acn.validator";
import { companyNameValidator } from "../../../../validators/compny-name.validator";

export interface CompanyNameAcnFormGroupControls {
  name: FormControl<string | null>;
  acn: FormControl<string | null>;
}

export type CompanyNameAcnFormGroup = FormGroup<CompanyNameAcnFormGroupControls>

@Component({
  selector: 'app-company-name-acn-form-group',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputComponent,
    MaskedInputComponent,
  ],
  templateUrl: './company-name-acn-form-group.component.html',
  styleUrl: './company-name-acn-form-group.component.scss'
})
export class CompanyNameAcnFormGroupComponent extends ReusableFormGroupComponent<CompanyNameAcnFormGroup> {
  private abnLookupService = inject(AuxiliaryService);

  @Input() acnTitle = 'ACN';
  @Input() nameTitle = 'Company Name';
  @Input() hideAcn = false;
  @Input() shouldLookForCompanyName = true;

  @Output() formSubmit = new EventEmitter<{ name: string, acn: string }>();
  @Output() foundEntityId = new EventEmitter<string>();

  cantFindCompanyName = false;

  async onAcnInputBlur() {
    if (this.shouldLookForCompanyName && this.form.controls.acn && this.form.controls.acn.valid) {
      try {
        const result = await firstValueFrom(this.abnLookupService.getCompanyNameByACn(String(this.form.controls.acn?.value)));

        if (!this.shouldLookForCompanyName)
          return;

        this.form.controls.name?.setValue(result.entityName);
        this.cantFindCompanyName = false;
        this.foundEntityId.emit(result.entityId);
      } catch (error) {
        this.cantFindCompanyName = true;
      }
    } else if (this.shouldLookForCompanyName)
      this.form.controls.name?.setValue('');
  }

  static override defineForm(): CompanyNameAcnFormGroup {
    return new FormGroup<CompanyNameAcnFormGroupControls>(CompanyNameAcnFormGroupComponent.defineFormControls());
  }

  static override defineFormControls(): CompanyNameAcnFormGroupControls {
    return {
      acn: new FormControl<string | null>(null, {
        updateOn: 'blur',
        validators: [Validators.required, acnValidator()]
      }),
      name: new FormControl<string | null>(null, [Validators.required, Validators.minLength(7), companyNameValidator()]),
    };
  }
}
