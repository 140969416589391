import { IndividualData } from "./individualData";

export class OfficerAppointmentForm {
    individualData: IndividualData;
    position?: OfficerAppointmentType;
    appointmentDate: Date;
    alternativeDirectorForId?: string;
    appointNewOfficer?: boolean;
    appointedOfficerId?: string;
    termsOfAppointment?: string;

    constructor(data: Partial<OfficerAppointmentForm> = {}) {
        this.individualData = data.individualData ?? new IndividualData();
        this.position = data.position;
        this.appointmentDate = data.appointmentDate ?? new Date();
        this.alternativeDirectorForId = data.alternativeDirectorForId ?? undefined; // ??
        this.appointNewOfficer = data.appointNewOfficer;
        this.appointedOfficerId = data.appointedOfficerId;
        this.termsOfAppointment = data.termsOfAppointment ?? undefined;
    }
}

export enum OfficerAppointmentType {
  Director = 1,
  Secretary = 2,
  AlternativeDirector = 3,
  PublicOfficer = 10,
}

export const OfficerAppointmentTypeLabels: Record<OfficerAppointmentType, string> = {
  [OfficerAppointmentType.PublicOfficer]: 'Public Officer',
  [OfficerAppointmentType.Director]: 'Director',
  [OfficerAppointmentType.Secretary]: 'Secretary',
  [OfficerAppointmentType.AlternativeDirector]: 'Alternate Director',
};
