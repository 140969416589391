<app-common-modal-form
  closeText="Cancel"
  confirmText="Save"
  [header]="header"
  [formGroup]="form"
  (confirm)="save()"
  (close)="cancel()"
>
  <app-individual-data-form-group
    [formGroup]="form.controls.newIndividualShareholder"
    [useInternationalAddresses]="true"
    [individualDataFormLabels]="individualDataFormLabels"
    [hiddenControls]="hiddenIndividualControls"
    [suggestedAddresses]="suggestedAddresses"
    [fullNameSuggestions]="fullNameSuggestions"
  ></app-individual-data-form-group>
</app-common-modal-form>
