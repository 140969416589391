import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[appScrollLoad]',
  standalone: true
})
export class ScrollLoadDirective {
  @Input() disabled = true;

  @Output() scrolledToBottom = new EventEmitter<void>();

  constructor(private element: ElementRef<HTMLElement>) {
  }

  @HostListener('scroll')
  onScroll(): void {
    if (this.disabled)
      return;

    if (this.isThereScroll) {
      if (this.isScrolledToBottom) {
        this.scrolledToBottom.emit();
      }
    }
  }

  get offsetHeight(): number {
    return this.element.nativeElement.offsetHeight || 0;
  }

  get scrollHeight(): number {
    return this.element.nativeElement.scrollHeight || 0;
  }

  get isThereScroll(): boolean {
    return this.scrollHeight > this.offsetHeight;
  }

  get scrollBottom(): number {
    return this.element.nativeElement.scrollTop + this.offsetHeight;
  }

  get isScrolledToBottom(): boolean {
    return this.scrollHeight <= this.scrollBottom;
  }
}
