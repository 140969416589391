import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitByCapitalLetterPipe',
  standalone: true
})
export class SplitByCapitalLetterPipePipe implements PipeTransform {
  transform(value: string): string {
    if(!value) return '';

    return value.replace(/([A-Z]+)([A-Z][a-z])/g, '$1 $2')
      .replace(/([a-z])([A-Z])/g, '$1 $2');
  }
}
