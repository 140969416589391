<ul class="recipients-list list-unstyled mb-0">
  @if (showSender && emailRecord.from) {
    <li class="recipients-group">
      <div class="recipients-type">From:</div>
      <div class="recipients-addresses">{{ emailRecord.from }}</div>
    </li>
  }

  @if (emailRecord.to) {
    <li class="recipients-group">
      <div class="recipients-type">To:</div>
      <div class="recipients-addresses">{{ emailRecord.to }}</div>
    </li>
  }

  @if (emailRecord.cc) {
    <li class="recipients-group">
      <div class="recipients-type">CC:</div>
      <div class="recipients-addresses">{{ emailRecord.cc }}</div>
    </li>
  }
</ul>
