import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { DividerComponent } from '../common/divider/divider.component';
import { Relationship } from '../../../models/relationship';
import { OfficerStatusPipe } from '../../../pipes/officerStatusPipe';
import { RelationshipTypePipe } from '../../../pipes/enumsPipes/relationshipTypePipe';
import { CommonModule, DatePipe, TitleCasePipe } from '@angular/common';
import { DateFormatPipe } from '../../../pipes/dateFormatPipe';
import { DefaulValuePipe } from '../../../pipes/enumsPipes/defaultValuePipe';
import { ButtonComponent } from '../common/button/button.component';
import { PhonePipe } from "../../../pipes/phonePipe";
import { RelationshipType } from '../../../models/enums/relationshipTypeEnum';
import { CountryPipe } from "../../../pipes/countryPipe";
import { AppCopyDirective } from "../../../directives/copy.directive";

@Component({
  selector: 'app-officeholder-profile',
  standalone: true,
  imports: [
    DividerComponent,
    OfficerStatusPipe,
    RelationshipTypePipe,
    DatePipe,
    DateFormatPipe,
    TitleCasePipe,
    DefaulValuePipe,
    ButtonComponent,
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
    CommonModule,
    PhonePipe,
    CountryPipe,
    AppCopyDirective
  ],
  templateUrl: './officeholder-profile.component.html',
  styleUrl: './officeholder-profile.component.scss'
})
export class OfficeholderProfileComponent {
  @Input() officeholder!: Relationship;
  @Input() pendingButtonState = true;
  @Input() openedPreviousOfficeholder: Relationship | undefined;
  @Input() disableControls = false;

  @Output() profileViewChange = new EventEmitter<boolean>();
  @Output() ceaseRegisteredAgent = new EventEmitter<Relationship[]>();
  @Output() companyChangePartyName = new EventEmitter<Relationship[]>();
  @Output() changeOfficerAddress = new EventEmitter<Relationship[]>();
  @Output() editOfficer= new EventEmitter<Relationship>();

  RelationshipType = RelationshipType;

  get appointedOfficeholder(): boolean {
    return this.openedPreviousOfficeholder?.individualDataOverride?.fullName !== this.officeholder.individualDataOverride?.fullName
      && this.officeholder.start !== this.openedPreviousOfficeholder?.start
      && !this.officeholder.end;
  }

  get changedOfficeholderName(): boolean {
    return !!this.openedPreviousOfficeholder?.individualDataOverride?.fullName
    && this.openedPreviousOfficeholder?.individualDataOverride?.fullName !== this.officeholder.individualDataOverride?.fullName;
  }

  get ceasedOfficeholder(): boolean {
    return this.officeholder.end !== this.openedPreviousOfficeholder?.end
      && this.openedPreviousOfficeholder?.individualDataOverride?.fullName === this.officeholder.individualDataOverride?.fullName;
  }



  onCeaseRegisteredAgent(): void {
    this.ceaseRegisteredAgent.emit([this.officeholder]);
  }

  onCompanyChangePartyName(): void {
    this.companyChangePartyName.emit([this.officeholder]);
  }

  onChangeOfficerAddress(): void {
    this.changeOfficerAddress.emit([this.officeholder]);
  }

  onEditOfficer(): void {
    this.editOfficer.emit(this.officeholder);
  }
}
