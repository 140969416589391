import { Component, Input, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { timer } from 'rxjs';
import { CompanyStatementRequestForm } from './company-statement-request-form.model';
import CompanyNameAcnComponent from "../../../../../components/common/company-name-acn/company-name-acn.component";
import { FormsModule } from "@angular/forms";

@Component({
  selector: 'app-ra71-company-statement-request',
  standalone: true,
  imports: [
    FormsModule,
    CompanyNameAcnComponent,
  ],
  templateUrl: './ra71-company-statement-request.component.html',
  styleUrl: './ra71-company-statement-request.component.scss'
})
export class RA71CompanyStatementRequestComponent {
  activeModal = inject(NgbActiveModal);
  @Input() formModel: CompanyStatementRequestForm = new CompanyStatementRequestForm();

  ngOnInit(): void {
    this.activeModal.update({ size: 'sm' });
    if(this.formModel) {
      this.formModel.acn = "";
    }
  }

  confirm() {
      timer(1000).subscribe(() => {
        this.activeModal.close(this.formModel);
      })
  }
}
