import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { InputComponent } from "../../../../input/input.component";
import { AbstractControl, FormsModule } from "@angular/forms";
import { ICellRendererParams } from "ag-grid-community";
import { SignRow } from "../../../../../../../models/signDocuments";
import { AnnualSignGroup } from "../../../../../../../models/enums/annualStatementEnums";
import { CustomFormValidators } from "../../../../../../../custom-form-validators/custom-form-validators";
import { emailValidatorMsg } from "../../../../../../../validators/validatorMessages/custom-form-validators-messages";

type ActionEvent = (data: unknown) => void;

type CellRendererParams = ICellRendererParams<SignRow> & {
  edit: ActionEvent,
};

@Component({
  selector: 'app-ag-annual-sign-edit-email',
  standalone: true,
  imports: [
    InputComponent,
    FormsModule
  ],
  templateUrl: './ag-annual-sign-edit-email.component.html',
  styleUrl: './ag-annual-sign-edit-email.component.scss'
})
export class AgAnnualSignEditEmailComponent {
  protected params!: CellRendererParams;
  public value: string | undefined;
  public disabled = false;
  public validationError = false;
  protected type: AnnualSignGroup | undefined;
  protected readonly emailValidatorMsg = emailValidatorMsg;

  @ViewChild('input', { read: ViewContainerRef }) public input!: ViewContainerRef;

  agInit(params: CellRendererParams): void {
    this.params = params;
    this.value = params.data?.email.trim() ?? '';
    this.disabled = params.data?.disabled ?? false;
    this.type = params.data?.type;
  }

  refresh(params: CellRendererParams): boolean {
    this.params = params;
    return true;
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      if(this.input) {
        (this.input.element.nativeElement as Element).querySelector('input')?.focus();
      }
    });
  }

  onChangeValue(value: string): void {
    if(this.isEmailValid(value)) {
      this.validationError = false;
      this.params.edit({ value: value, index: this.params.node.rowIndex, column: 'email' });
    } else {
      this.validationError = true;
      this.params.edit({ value: '', index: this.params.node.rowIndex, column: 'email' });
    }

    if(this.params.data) {
      this.params.data.email = value;
    }
  }

  private isEmailValid(email: string): boolean {
    if(!email) return false;

    const control = { value: email } as AbstractControl;
    const errors = CustomFormValidators.emailValidator(control);

    return errors === null;
  }
}
