import { TextMessagePayloadBase } from "./TextMessagePayloadBase";

export class AnnualStatementFeeReminderTextMessagePayload extends TextMessagePayloadBase {
  static override $type = 'AnnualStatementFeeReminderTextMessagePayload';

  companyName: string;
  companyACN: string;
  partner: string;
  asicContact: string;
  accountManager: string;
  billingContactFirstName: string;
  billingContactFullName: string;
  annualReviewFee: string;
  annualReviewFeeDeadline: string;
  annualReviewFeeDeadlineShortFormat: string;
  payUrl: string;

  constructor(data: Partial<AnnualStatementFeeReminderTextMessagePayload> = {}) {
    super(data);
    this.companyName = data.companyName ?? '';
    this.companyACN = data.companyACN ?? '';
    this.partner = data.partner ?? '';
    this.asicContact = data.asicContact ?? '';
    this.accountManager = data.accountManager ?? '';
    this.billingContactFirstName = data.billingContactFirstName ?? '';
    this.billingContactFullName = data.billingContactFullName ?? '';
    this.annualReviewFee = data.annualReviewFee ?? '';
    this.annualReviewFeeDeadline = data.annualReviewFeeDeadline ?? '';
    this.annualReviewFeeDeadlineShortFormat = data.annualReviewFeeDeadlineShortFormat ?? '';
    this.payUrl = data.payUrl ?? '';
  }
}
