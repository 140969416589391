import { CurrencyPipe } from '@angular/common';
import { DatepickerHelper } from '../../custom-form-validators/date-picker-form-validators';
import { ABNPipe } from '../../pipes/abnPipe';
import { ACNPipe } from '../../pipes/acnPipe';
import { DinPipe } from '../../pipes/din.pipe';
import { CountriesService } from '../../services/countries.service';
import { IPDFSection } from "./pdf-section.interface";
import { JurisdictionType } from "../enums/trustEnum";
import { stateLabelsDictionary } from "../../app/constants/au-states.constant";
import { Relationship } from "../relationship";

export interface IDictionaryItem {
  key: string,
  value: string
}

export class ChangeDictionaryHelper {
  // special keys and prefixes
  static readonly COMPANY_NAME = 'companyName';
  static readonly DIVIDER = 'divider';
  static readonly EMPTY_LINE = 'empty line';
  static readonly BENEFICIAL_OWNER = 'beneficialOwner';
  static readonly PREFIXES = [ChangeDictionaryHelper.BENEFICIAL_OWNER];
  static readonly SPECIAL_KEYS = [ChangeDictionaryHelper.DIVIDER, ChangeDictionaryHelper.EMPTY_LINE];

  dictionary: IDictionaryItem[] = [];
  sections: IPDFSection[] = [];

  constructor(dictionary: IDictionaryItem[] = []) {
    this.dictionary = dictionary;
  }

  addSection(header: string, rows: { key: string, value: string }[] = []): ChangeDictionaryHelper {
    this.sections.push({header, rows});
    return this;
  }

  add(key: string, value: any): ChangeDictionaryHelper {
    const dictionaryItem = { key, value: value?.toString()?.trim() as string || '' };

    if (this.sections.length) {
      this.sections[this.sections.length - 1].rows.push(dictionaryItem);
    } else {
      this.dictionary.push(dictionaryItem);
    }

    return this;
  }

  addIfValueIsNotEmpty(key: string, value: any): ChangeDictionaryHelper {
    if (value?.toString()?.trim())
      this.add(key, value?.toString()?.trim());
    return this;
  }

  addRelationshipsIds(key: string, relationships: Relationship[]): ChangeDictionaryHelper {
    const ids = relationships.map((r) => r.individualId ?? r.relationshipId);
    return this.addIds(key, [...new Set(ids)]);
  }

  addIds(key: string, ids: string[]): ChangeDictionaryHelper {
    return this.add(key, ids.join('<br/>'));
  }

  addDate(key: string, date: Date | string | null | undefined): ChangeDictionaryHelper {
    return this.add(key, DatepickerHelper.toString(date));
  }

  addYesNo(key: string, value: boolean): ChangeDictionaryHelper {
    return this.add(key, value ? 'Yes' : 'No');
  }

  addNoYes(key: string, value: boolean): ChangeDictionaryHelper {
    return this.add(key, value ? 'No' : 'Yes');
  }

  addAcn(key: string, value: string | undefined | null): ChangeDictionaryHelper {
    if (!value)
      return this.add(key, '');

    const acn = new ACNPipe().transform(value);
    return this.add(key, acn);
  }

  addAbn(key: string, value: string | undefined | null): ChangeDictionaryHelper {
    if (!value)
      return this.add(key, '');

    const abn = new ABNPipe().transform(value);
    return this.add(key, abn);
  }

  addDin(key: string, value: string | undefined | null): ChangeDictionaryHelper {
    if (!value)
      return this.add(key, '');

    const din = new DinPipe().transform(value);
    return this.add(key, din);
  }

  addMoney(key: string, value: number | undefined | null): ChangeDictionaryHelper {
    const money = new CurrencyPipe('en-AU').transform(value, '$', 'code', '1.2-2');
    return this.add(key, money);
  }

  addCountry(key: string, value: string): ChangeDictionaryHelper {
    return CountriesService.getCountryName(value)
      ? this.add(key, CountriesService.getCountryName(value))
      : this.add(key, value);
  }

  addJurisdiction(key: string, value: JurisdictionType): ChangeDictionaryHelper {
    return this.add(key, stateLabelsDictionary[value]);
  }

  addDivider(): ChangeDictionaryHelper {
    return this.add(ChangeDictionaryHelper.DIVIDER, '');
  }

  addEmptyLine(): ChangeDictionaryHelper {
    return this.add(ChangeDictionaryHelper.EMPTY_LINE, '');
  }

  addWithBeneficialOwnerPrefix(key: string, value: string): ChangeDictionaryHelper {
    return this.add(key, ChangeDictionaryHelper.BENEFICIAL_OWNER + value);
  }

  static handlePrefix(prefix: string, dictionaryItem: IDictionaryItem, hasValueInRelationshipHashset: boolean): IDictionaryItem[] {
    switch (prefix) {
      case ChangeDictionaryHelper.BENEFICIAL_OWNER: {
        const items: IDictionaryItem[] = [];
        items.push({
          key: 'Are the shares owned on behalf of another individual, company, trust or fund?',
          value: hasValueInRelationshipHashset ? 'Yes' : 'No'
        });

        if (hasValueInRelationshipHashset)
          items.push(dictionaryItem)
        return items;
      }

      default:
        throw new Error('Unknown prefix ' + prefix);
    }
  }

  static replaceNewLineWithComma(value: string): string {
    return value.replace(/<br\/>/g, ', ');
  }
}

