import { Component, Input } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { EmailRecord } from "../../../../models/email-templates/emailRecord";

@Component({
  selector: 'app-ag-email-subject',
  standalone: true,
  imports: [],
  templateUrl: './ag-email-subject.component.html',
  styleUrl: './ag-email-subject.component.scss'
})
export class AgEmailSubjectComponent implements ICellRendererAngularComp {
  @Input() subject = ''
  @Input() hasAttachments = false;

  agInit(params: ICellRendererParams<EmailRecord>): void {
    this.subject = params.data?.subject ?? '';
    this.hasAttachments = params.data?.hasAttachments ?? false;
  }

  refresh(params: ICellRendererParams<EmailRecord>): boolean {
    this.subject = params.data?.subject ?? '';
    this.hasAttachments = params.data?.hasAttachments ?? false;
    return true;
  }
}
