import { NgClass } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { FormSwitchButtonComponent } from '../form-switch-button/form-switch-button.component';
import { InputComponent } from '../input/input.component';
import { LinkComponent } from '../link/link.component';
import { ValidationErrorComponent } from '../validation-error/validation-error.component';
import { CustomFormValidators } from "../../../../custom-form-validators/custom-form-validators";

@Component({
  selector: 'app-input-password',
  standalone: true,
  imports: [
    FormSwitchButtonComponent,
    LinkComponent,
    ValidationErrorComponent,
    NgClass,
    FormsModule,
    NgbTooltip
  ],
  templateUrl: './input-password.component.html',
  styleUrls: ['./input-password.component.scss', '../input/input.component.scss']
})
export class InputPasswordComponent extends InputComponent implements OnInit {
  @Input() passwordVisible = false;
  @Input() override customErrors: Record<string, string> = { onlyLettersAndNumbersAllowed: 'Only letters and numbers are allowed.' };

  ngOnInit(): void {
    if (this.ngControl.control) {
      this.ngControl.control.addValidators([CustomFormValidators.onlyLettersAndNumbersAllowedValidator]);
    }
  }

  get type(): string {
    return this.passwordVisible ? 'text' : 'password';
  }

  get showPasswordIconClass(): string {
    return this.passwordVisible ? 'ic-eye-off' : 'ic-eye';
  }

  get hidePasswordIcon(): boolean {
    return !this.ngControl?.control?.value;
  }
}
