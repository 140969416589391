export interface IParsedFullName {
  firstName: string,
  lastName: string,
  middleName1: string,
  middleName2: string,
}

export function parseFullName(fullName: string): IParsedFullName {
  // const nameSplittingRegExp = /(?:(?:[A-ZÁ-ÚÑÜ][a-zá-úñü]+){1,2})|(?:(?:[aeodlsz]{1,3}[ ]){0,2}[A-ZÁ-ÚÑÜ][a-zá-úñü]+)/gms;
  // INDIA, GOLF HOTEL - for this case
  const nameSplittingRegExp = /(?:(?:[A-ZÁ-ÚÑÜ][A-Za-zá-úñü]+){1,2})|(?:(?:[aeodlsz]{1,3}[ ]){0,2}[A-ZÁ-ÚÑÜ][A-Za-zá-úñü]+)/gms;
  const result: Partial<IParsedFullName> = {
    firstName: '',
    lastName: '',
    middleName1: '',
    middleName2: '',
  };

  const includesComma = fullName.includes(',');

  if (includesComma) {
    let rest = '';
    [result.lastName, rest] = fullName.split(',');

    const nameTokens = rest.match(nameSplittingRegExp) || [];

    if (nameTokens.length == 1) {
      [result.firstName] = nameTokens;
    } else if (nameTokens.length == 2) {
      [result.firstName, result.middleName1] = nameTokens;
    } else if (nameTokens.length > 2) {
      const middleName2Tokens = nameTokens.slice(2);
      result.middleName2 = middleName2Tokens.join(' ');
      nameTokens.splice(2, middleName2Tokens.length);
      [result.firstName, result.middleName1] = nameTokens;
    }
  } else {
    if (fullName.length > 0) {
      const nameTokens = fullName.match(nameSplittingRegExp) || [];
      if (nameTokens.length == 1) {
        [result.firstName] = nameTokens;
      } else if (nameTokens.length == 2) {
        [result.firstName, result.lastName] = nameTokens;
      } else if (nameTokens.length == 3) {
        [result.firstName, result.middleName1, result.lastName] = nameTokens;
      } else if (nameTokens.length > 3) {
        const middleName2Tokens = nameTokens.slice(2, -1);
        result.middleName2 = middleName2Tokens.join(' ');
        nameTokens.splice(2, middleName2Tokens.length);
        [result.firstName, result.middleName1, result.lastName] = nameTokens;
      }
    }
  }

  Object.keys(result).forEach((key) => result[key] = result[key].trim());
  return result as IParsedFullName;
}
