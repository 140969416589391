<div class="holder">
  @for (groupEntry of holderGroups | keyvalue; track groupEntry) {
  <div [style]="'flex:' + groupEntry.value.length" class="owner-name border-bottom-dashed">
    <div class="cell-padding">
      <div class="tag" [ngClass]="{
          'green': groupEntry.value[0].details.isBeneficialOwner,
          'purple': !groupEntry.value[0].details.isBeneficialOwner,
        }">
        <span>{{ groupEntry.value[0].details.isBeneficialOwner ? "Yes" : "No" }}</span>
      </div>
    </div>
  </div>
  }
</div>