<app-common-offcanvas
  [width]="alertContainerWidth"
  [opened]="opened()"
  (close)="closeAlerts()"
>
  <div class="header">
    <div class="w-100 d-flex align-items-center justify-content-between">
      <h2 class="h2-heading m-0 p-0">Alerts</h2>
    </div>
  </div>

  @if(!$selectedAlert()) {
    <div class="alert-details-container">
      <div class="alert-details-body">
        <app-list-grid
          class="alert-details-grid"
          [rowData]="alertsList"
          [colDefs]="colDefs"
          [suppressRowClickSelection]="true"
          [pagination]="true"
          [paginationPageSize]="pageSizeService.getPageSize"
          [paginationPageSizeSelector]="false"
          [suppressCellFocus]="true"
          (gridReady)="onGridReady($event)"
        ></app-list-grid>
      </div>
    </div>
  } @else {
    <div class="info-container">
      <div class="d-flex gap-12 align-items-center">
        <app-button [btnColor]="'white'" [iconClass]="'icon ic-arrow-left'" (clickBtn)="backToAlerts()"></app-button>
        <div class="header-title">{{$selectedAlert()?.model?.title}}</div>
      </div>

      <div class="alert-pagination-container">
        <div class="pages">{{currentAlertIndex + 1}} of {{totalRows}}</div>
        <div class="arrow-btn-container">
          <i
            class="icon ic-arrow-left"
            [ngClass]="{'disabled': !currentAlertIndex}"
            (click)="previousAlert()"
          ></i>
          <i
            class="icon ic-arrow-right"
            [ngClass]="{'disabled': currentAlertIndex + 1 === totalRows}"
            (click)="nextAlert()"
          ></i>
        </div>
      </div>
    </div>
    <app-alert-details
      [alert]="$selectedAlert()"
      [headerHeight]="'57px'"
      (updateAlert)="updateAlert($event)"
    ></app-alert-details>
  }
</app-common-offcanvas>
