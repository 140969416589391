import { Component } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { IReminderItem } from "../../reminders-page.component";

type ActionEvent = (data: IReminderItem | undefined) => void;
type CellRendererParams = ICellRendererParams<IReminderItem> & {
  edit: ActionEvent
};

export enum ReminderSendingMethods {
  sms = 0,
  email = 1,
  smsAndEmail = 2
}

export const ReminderSendingMethodsLabelsRecord: Record<ReminderSendingMethods, string> = {
  [ReminderSendingMethods.email]: "Email",
  [ReminderSendingMethods.sms]: "SMS",
  [ReminderSendingMethods.smsAndEmail]: "SMS and Email",
};

@Component({
  selector: 'app-ag-reminder-sending-method-label',
  standalone: true,
  templateUrl: './ag-reminder-sending-method-label.component.html',
  styleUrl: './ag-reminder-sending-method-label.component.scss'
})
export class AgReminderSendingMethodLabelComponent implements ICellRendererAngularComp {
  readonly ReminderSendingMethods = ReminderSendingMethods;
  readonly ReminderSendingMethodsLabelsRecord = ReminderSendingMethodsLabelsRecord;

  params!: CellRendererParams;
  sendingMethod: ReminderSendingMethods | null = null;
  autoSendingEnable = false;

  agInit(params: CellRendererParams): void {
    this.setupParams(params);
  }

  refresh(params: CellRendererParams): boolean {
    return this.setupParams(params);
  }

  private setupParams(params: CellRendererParams): boolean {
    this.params = params;
    this.sendingMethod = params.data?.sendingMethod ?? null;
    this.autoSendingEnable = params.data?.autoSending ?? false;
    return true;
  }
}
