@if(showPageSizeSelector || totalPages > 1) {
  <div class="pagination">
    @if (totalPages > 1) {
      <div class="previous-container">
        <div
          class="previous-btn"
          [ngClass]="{ 'disabled': currentPage === 0 }"
          (click)="(currentPage) && goToPreviousPage()"
        >
          <i class="icon ic-arrow-left"></i>
          <span>Previous</span>
        </div>
      </div>
    }

    <div class="d-flex">
      @if (showPageSizeSelector) {
        <div class="dropdown-container">
          <div class="d-flex align-items-center">
            <p class="label">Page size:</p>
            <div ngbDropdown class="d-inline-block" (openChange)="getDropdownState($event)">
              <div ngbDropdownToggle [ngClass]="{'opened': dropdownOpen}" class="btn dropdown-custom-item btn-secondary">{{ pageSizeService.getPageSize }}</div>
              <div ngbDropdownMenu>
                @for (size of paginationPageSizeSelector; track size) {
                  <button (click)="onSelectPageSize(size)" ngbDropdownItem type="button">{{ size }}</button>
                }
              </div>
            </div>
          </div>
        </div>
      }

      @if (pageNumbers.length > 1) {
        <div class="page-container">
          <ng-container *ngFor="let page of pageNumbers">
            <div
              class="custom-pagination-btn"
              [ngClass]="{ 'active': +page === currentPage + 1 }"
              (click)="goToPage(+page - 1)"
            >
              {{ page }}
            </div>
          </ng-container>
        </div>
      }
    </div>

    @if (totalPages > 1) {
      <div class="next-container">
        <div
          class="next-btn"
          [ngClass]="{ 'disabled': currentPage === totalPages - 1 }"
          (click)="(currentPage !== totalPages - 1) && goToNextPage()"
        >
          <span>Next</span>
          <i class="icon ic-arrow-right"></i>
        </div>
      </div>
    }
  </div>
}


