import { Component } from '@angular/core';
import { NgClass } from "@angular/common";

@Component({
  selector: 'app-changes-applied',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './changes-applied.component.html',
  styleUrl: './changes-applied.component.scss'
})
export class ChangesAppliedComponent {
  CHANGE_IS_APPLIED_FOR = [
    {
      firstColumnValue: 'First column value 1',
      secondColumnValue: 'Second column value 1',
    },
    {
      firstColumnValue: 'First column value 2',
      secondColumnValue: 'second column value 2',
    }
  ];

  DOCUMENTS_TO_GENERATE = [
    'Document1.dpf',
    'Document2.dpf',
    'Document3.dpf',
    'Document4.dpf',
  ];
}
