import { TextMessagePayloadBase } from "./TextMessagePayloadBase";

export class AnnualStatementSigningReminderTextMessagePayload extends TextMessagePayloadBase {
  static override $type = 'AnnualStatementSigningReminderTextMessagePayload';

  companyName: string;
  companyACN: string;
  partner: string;
  asicContact: string;
  accountManager: string;
  billingContactFirstName: string;
  billingContactFullName: string;
  annualReviewFeeDeadline: string;
  annualReviewFeeDeadlineShortFormat: string;
  directorFirstName: string;
  directorFullName: string;

  constructor(data: Partial<AnnualStatementSigningReminderTextMessagePayload> = {}) {
    super(data);
    this.companyName = data.companyName ?? '';
    this.companyACN = data.companyACN ?? '';
    this.partner = data.partner ?? '';
    this.asicContact = data.asicContact ?? '';
    this.accountManager = data.accountManager ?? '';
    this.billingContactFirstName = data.billingContactFirstName ?? '';
    this.billingContactFullName = data.billingContactFullName ?? '';
    this.annualReviewFeeDeadline = data.annualReviewFeeDeadline ?? '';
    this.annualReviewFeeDeadlineShortFormat = data.annualReviewFeeDeadlineShortFormat ?? '';
    this.directorFirstName = data.directorFirstName ?? '';
    this.directorFullName = data.directorFullName ?? '';
  }
}
