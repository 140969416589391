<div class="card">
  <app-page-title
    header="Outgoing Email Messages"
    iconClass="ic-outgoing-emails"
  >
    @if (checkedEmailRecords.length) {
      <app-button
        [text]="'Export selected messages'"
        [iconClass]="'icon ic-xs ic-download-file'"
        [isDisabled]="!totalRows || modalOpened()"
        (clickBtn)="exportEmailMessagesList(false, ExportTypeEnum.EXCEL)"
      ></app-button>
    } @else {
      <app-button
        style="width: 100px"
        [text]="'Export'"
        [iconClass]="'icon ic-xs ic-download-file'"
        [isDisabled]="!totalRows || modalOpened()"
        (clickBtn)="exportSelectedEmailMessagesToXls()"
      ></app-button>
    }
  </app-page-title>

  <app-grid
    pageTitle="Outgoing Email Messages"
    [maxBlocksInCache]="1"
    [excelStyles]="excelStyles"
    [defaultExcelExportParams]="excelExportParams"
    [colDefs]="$any(colDefs)"
    [rowData]="[]"
    [totalRows]="totalRows"
    [gridOptions]="gridOptions"
    [suppressRowClickSelection]="true"
    [rowModelType]="serverRowModelType"
    [serverSideDatasource]="dataSource"
    [getRowId]="getRowId"
    [pagination]="true"
    [paginationPageSize]="defaultPageSize"
    [cacheBlockSize]="cacheBlockSize"
    (gridReady)="onGridReady($event)"
    (itemSelect)="selectEmailRecord($event)"
    (search)="onSearch($event)"
  ></app-grid>
</div>

<app-email-details
  [isOuterLoading]="isDetailsLoading"
  [opened]="!!$selectedEmailRecord()"
  [emailRecord]="$selectedEmailRecord()"
  [currentEmailMessageIndex]="currentEmailRecordIndex"
  [totalRows]="totalRows"
  [currentEmailMessageItemNumber]="currentEmailMessageItemNumber"
  (close)="onCloseDetails()"
  (getNextEmailMessage)="nextEmailMessage()"
  (getPreviousEmailMessage)="previousEmailMessage()"
  (refreshData)="reloadGrid()"
/>
