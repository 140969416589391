import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DatepickerHelper } from '../../../../../custom-form-validators/date-picker-form-validators';
import { Document } from '../../../../../models/document';
import { EntityChangeData } from '../../../../../models/entityChangeData';
import { SelectOption } from '../../../../../models/selectOptions';
import { companyNameValidator } from '../../../../../validators/compny-name.validator';
import { PaymentMethod, paymentMethodOptions } from '../../../company-name-change/CompanyChangeName';
import { BaseStepperFormComponent } from '../../../stepper-form/base-stepper-component/base-stepper-form.component';
import { Form410FModel, Form410FStepsEnum } from './form410F.model';
import {
  reservationNumberCustomErrors,
  reservationNumberValidator
} from "../../../../../validators/reservation-number.validator";
import { StepperFormComponent } from "../../../stepper-form/stepper-form.component";
import {
  StepperFormDescriptionComponent
} from "../../../stepper-form/stepper-form-description/stepper-form-description.component";
import { InputComponent } from "../../../../components/common/input/input.component";
import { DatePickerComponent } from "../../../../components/common/date-picker/date-picker.component";
import { RadioComponent } from "../../../../components/common/radio/radio.component";
import { TextareaComponent } from "../../../../components/common/textarea/textarea.component";

@Component({
  selector: 'app-form410f',
  standalone: true,
  templateUrl: './form410f.component.html',
  imports: [
    ReactiveFormsModule,
    InputComponent,
    RadioComponent,
    DatePickerComponent,
    TextareaComponent,
    StepperFormComponent,
    StepperFormDescriptionComponent,
  ],
  styleUrl: '../../../stepper-form/base-stepper-component/base-stepper-form.component.scss'
})
export class Form410FComponent extends BaseStepperFormComponent<Form410FStepsEnum, Form410FModel> implements OnInit {
  override readonly StepsEnum = Form410FStepsEnum;
  readonly paymentMethodOptions: SelectOption[] = paymentMethodOptions;
  readonly reservationNumberCustomErrors = reservationNumberCustomErrors;
  minDate!: NgbDateStruct;
  maxDate!: NgbDateStruct;

  override stepperForm = new FormGroup({
    [Form410FStepsEnum.FormDetails]: new FormGroup({}),
    [Form410FStepsEnum.CompanyNameDetails]: new FormGroup({
      proposedCompanyName: new FormControl<string>('', [Validators.required, Validators.minLength(7), companyNameValidator()]),
      reservationDocumentNumber: new FormControl<string | null>(null, [Validators.required, reservationNumberValidator]),
      existingExpiryDate: new FormControl<Date | null>(null, [Validators.required]),
      paymentMethod: new FormControl<PaymentMethod>(PaymentMethod.INV, [Validators.required]),
    }),
    [Form410FStepsEnum.ExtensionDetails]: new FormGroup({
      extensionReason: new FormControl<string>('', [Validators.required]),
    }),
  });

  constructor() {
    super();
    this.setupSteps(Form410FStepsEnum);
    this.redirectAfterSubmit = true;
  }

  ngOnInit(): void {
    this.minDate = DatepickerHelper.getDateOfEstablishmentMinDate(this.companyChangeData.dateOfEstablishment);
    this.maxDate = DatepickerHelper.getNextNYearsStruct(10);
    this.stepperForm.controls[Form410FStepsEnum.CompanyNameDetails].controls.reservationDocumentNumber.setValue(this.companyChangeData.documentId ?? null);
    this.setupChange();
  }

  override afterSubmit(changes: EntityChangeData[]) {
    this.setupChange(changes[0] as Form410FModel);
  }

  override setupChange(change: Form410FModel = this.formModel) {
    if (!this.isEdit && change === this.formModel) {
      return;
    }

    this.stepperForm.patchValue({
      [Form410FStepsEnum.CompanyNameDetails]: { ...change },
      [Form410FStepsEnum.ExtensionDetails]: { ...change }
    });
  }

  override buildDocument(): Document | null {
    const companyNameDetailsFormValue = this.stepperForm.controls[Form410FStepsEnum.CompanyNameDetails].value;
    const extensionDetailsFormValue = this.stepperForm.controls[Form410FStepsEnum.ExtensionDetails].value;
    const changes = new Form410FModel({
      ...companyNameDetailsFormValue as Partial<Form410FModel>,
      ...extensionDetailsFormValue as Partial<Form410FModel>,
      changeDate: DatepickerHelper.getToday(),
      existingExpiryDate: companyNameDetailsFormValue.existingExpiryDate!
    });

    try {
      return new Document({
        changes: [changes],
        entityId: this.companyChangeData.entityId,
        type: 'c:410f',
        documentId: this.companyChangeData?.documentId,
      });
    } catch (error) {
      console.warn(error);
      this.toastr.error('Failed to create Document.', 'Error');
      return null;
    }
  }
}

