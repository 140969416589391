<div class="annual-status-wrapper">
  @if(isPaid) {
    <div class="status paid">
      {{ 'Paid' }}
    </div>
  } @else {
    <div class="status" [ngClass]="AnnualStatementStatus[status!]">
      {{ AnnualStatementStatus[status!] | splitByCapitalLetterPipe }}
    </div>
  }
</div>
