<app-stepper-form
  header="Company Dividend Statement"
  subheader=""
  [steps]="stepsSignal()"
  [currentStep]="currentStep"
  [currentStepIndex]="currentStepIndex"
  [isLoading]="isLoading"
  [form]="stepperForm"
>
  <div class="step-content" [formGroup]="stepperForm">
    <app-stepper-form-description
      formKey="DIVIDEND_STATEMENT"
      [hidden]="currentStep !== StepsEnum.FormDescription"
    ></app-stepper-form-description>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.DistributionDetails"
      [hidden]="currentStep !== StepsEnum.DistributionDetails"
    >
      <h1 class="h1-heading">{{ steps[currentStepIndex].label }}</h1>

      <div class="form-group">
        <app-date-picker
          label="Date of Declaration"
          formControlName="declarationDate"
          [minDate]="minDate"
          [maxDate]="maxDate"
          [customErrors]="customDatepickerErrors"
        ></app-date-picker>
      </div>

      <div class="form-group">
        <app-date-picker
          label="Proposed Payment Date"
          formControlName="paymentDate"
          [minDate]="minDate"
          [maxDate]="maxDate"
        ></app-date-picker>
      </div>

      <div class="form-group">
        <app-radio
          label="Payment Type"
          formControlName="paymentType"
          [options]="paymentTypeOptions"
        ></app-radio>
      </div>

      <div class="form-group">
        <app-select
          label="Dividend Type"
          formControlName="dividendType"
          [options]="dividendTypeOptions"
          [valuePrimitive]="true"
        ></app-select>
      </div>
    </section>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.DistributionAmount"
      [hidden]="currentStep !== StepsEnum.DistributionAmount"
    >
      <h1 class="h1-heading">{{ steps[currentStepIndex].label }}</h1>

      <div class="form-group">
        <app-yes-no-control
          label="Are dividends split equally between all share classes?"
          formControlName="equalDividendsForAllShareClasses"
        ></app-yes-no-control>
      </div>

      <div [hidden]="distributionAmountForm.value.equalDividendsForAllShareClasses !== true">
        <h2 class="h2-heading">Details for all classes of shares</h2>

        <div class="form-group">
          <app-input-number
            label="Amount of Distribution"
            formControlName="amountOfDistribution"
          ></app-input-number>
        </div>

        <div class="form-group">
          <app-input-number
            label="What percentage of the distribution is franked?"
            formControlName="percentageOfDividendsFranked"
            [max]="100"
          ></app-input-number>
        </div>
      </div>

      <div [hidden]="distributionAmountForm.value.equalDividendsForAllShareClasses !== false">
        <ul class="share-class-list" formArrayName="dividendsPerShareClassIfNotEqual">
          @for (share of shareTypes; track share; let index = $index) {
            <li class="share-class-item" [formGroupName]="index">
              <h2 class="h2-heading">Details for class {{ share.class }}</h2>

              <div class="form-group">
                <app-input-number
                  label="Amount of Distribution"
                  formControlName="amountOfDistribution"
                ></app-input-number>
              </div>

              <div class="form-group">
                <app-input-number
                  label="What Percentage of the Distribution is Franked?"
                  formControlName="percentageOfDistributionFranked"
                  [max]="100"
                ></app-input-number>
              </div>
            </li>

            <app-divider margin="24px 0"></app-divider>
          }
        </ul>
      </div>

      <div class="form-group">
        <app-select
          label="What company tax rate to be applied for franking purposes?"
          formControlName="companyTaxApplied"
          [options]="taxRateForFrankingPurposesOptions"
          [valuePrimitive]="true"
        ></app-select>
      </div>
    </section>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.BeneficialOwnersDetails"
      [hidden]="currentStep !== StepsEnum.BeneficialOwnersDetails"
    >
      <h1 class="h1-heading">{{ steps[currentStepIndex].label }}</h1>

      @if (holders && holders.length) {
        <nbo-beneficial-owners-group
          [holders]="holders"
          [formGroup]="stepperForm.controls[StepsEnum.BeneficialOwnersDetails]"
          (dataSaved)="updateSignValue($event.index, $event.newBOName)"
        ></nbo-beneficial-owners-group>
      }
    </section>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.Estimate"
      [hidden]="currentStep !== StepsEnum.Estimate"
    >
      <h1 class="h1-heading">{{ steps[currentStepIndex].label }}</h1>

    </section>
  </div>
</app-stepper-form>
