import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { AddressFormGroup, AddressFormGroupComponent } from "../address-form-group/address-form-group.component";
import { CompanyNameAcnFormGroupComponent } from "../company-name-acn-form-group/company-name-acn-form-group.component";
import { CheckboxComponent } from "../../common/checkbox/checkbox.component";
import CompanyNameAcnComponent from "../../common/company-name-acn/company-name-acn.component";
import { ReusableFormGroupComponent } from "../reusable-form-group/reusable-form-group.component";
import { Relationship } from "../../../../models/relationship";
import { Address } from "../../../../models/address";
import { Company } from "../../../../models/company";
import { CorporateHolderModel } from "../../../../models/securityRegistryRecord";
import { EntityData } from "../../../../models/entityData";
import { acnValidator } from "../../../../validators/acn.validator";
import { companyNameValidator } from "../../../../validators/compny-name.validator";

export interface CorporateRelationshipFormGroupControls {
  acn: FormControl<string | null>,
  name: FormControl<string | null>,
  isOverseasCompany: FormControl<boolean | null>,
  address: AddressFormGroup
}

export type CorporateRelationshipFormGroup = FormGroup<CorporateRelationshipFormGroupControls>;

@Component({
  selector: 'app-corporate-relationship-form-group',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CompanyNameAcnFormGroupComponent,
    CheckboxComponent,
    AddressFormGroupComponent
  ],
  templateUrl: './corporate-relationship-form-group.component.html',
})
export class CorporateRelationshipFormGroupComponent extends ReusableFormGroupComponent<CorporateRelationshipFormGroup> {
  @Input() hideIsOverseasCompany = false;

  @Output() foundEntityId = new EventEmitter<string | null>();

  static override defineForm(): CorporateRelationshipFormGroup {
    return new FormGroup({
      acn: new FormControl<string | null>(null, { updateOn: 'blur', validators: [Validators.required, acnValidator()] }),
      name: new FormControl<string | null>(null, [Validators.required, Validators.minLength(7), companyNameValidator()]),
      isOverseasCompany: new FormControl<boolean>(false),
      address: AddressFormGroupComponent.defineForm()
    });
  }

  static buildCorporateRelationship(form: CorporateRelationshipFormGroup, relationship = new Relationship()): Relationship {
    return new Relationship({
      ...relationship,
      entityDataOverride: new Company({
        name: form.controls.name.value!,
        entityNumber: form.controls.acn.value!,
        registeredAddress: new Address(form.controls.address.value as Partial<Address>)
      })
    });
  }

  static buildCorporateSecurityHolder(form: CorporateRelationshipFormGroup): CorporateHolderModel {
    return new CorporateHolderModel({
      name: form.controls.name.value!,
      entityData: new EntityData({
        registeredAddress: new Address(form.controls.address.value as Partial<Address>),
        name: form.controls.name.value!,
        entityNumber: form.controls.acn.value!,
      }),
    });
  }
}
