import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DatepickerHelper } from '../../../custom-form-validators/date-picker-form-validators';
import { Document } from '../../../models/document';
import { DatePickerComponent } from '../../components/common/date-picker/date-picker.component';
import { SelectComponent } from '../../components/common/select/select.component';
import { BaseStepperFormComponent } from '../stepper-form/base-stepper-component/base-stepper-form.component';
import {
  StepperFormDescriptionComponent
} from '../stepper-form/stepper-form-description/stepper-form-description.component';
import { StepperFormComponent } from '../stepper-form/stepper-form.component';
import {
  BuyBackAttachedDocumentType,
  buyBackDocumentTypeOptions,
  BuyBackType,
  buyBackTypeOptions,
  CompanyForm280,
  ShareBuyBackStepsEnumSteps
} from './form280.model';

// Notification of share buy-back details
@Component({
  selector: 'app-form280',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    StepperFormDescriptionComponent,
    StepperFormComponent,
    SelectComponent,
    DatePickerComponent
  ],
  templateUrl: './form280.component.html',
  styleUrl: '../stepper-form/base-stepper-component/base-stepper-form.component.scss'
})
export class Form280Component extends BaseStepperFormComponent<ShareBuyBackStepsEnumSteps, CompanyForm280> implements OnInit {

  readonly buyBackTypeOptions = buyBackTypeOptions;
  readonly buyBackDocumentTypeOptions = buyBackDocumentTypeOptions;
  override readonly StepsEnum = ShareBuyBackStepsEnumSteps;

  minDate!: NgbDateStruct;
  maxDate!: NgbDateStruct;

  override stepperForm = new FormGroup({
    [ShareBuyBackStepsEnumSteps.FormDescription]: new FormGroup({}),
    [ShareBuyBackStepsEnumSteps.TheChange]: new FormGroup({
      changeDate: new FormControl<Date | null>(DatepickerHelper.getToday(), [Validators.required]),
      buyBackType: new FormControl<BuyBackType | null>(null, [Validators.required]),
      attachedDocumentType: new FormControl<BuyBackAttachedDocumentType | null>(null, [Validators.required]),
    }),
  });

  constructor() {
    super();
    this.setupSteps(ShareBuyBackStepsEnumSteps);
    this.redirectAfterSubmit = true;
  }

  ngOnInit(): void {
    this.minDate = DatepickerHelper.getDateOfEstablishmentMinDate(this.companyChangeData.dateOfEstablishment);
    this.maxDate = DatepickerHelper.getNextNYearsStruct(10);
    this.setupChange();
  }

  override setupChange() {
    if (!this.isEdit) {
      return;
    }

    this.theChangeForm.patchValue(this.formModel);
  }

  override buildDocument(): Document | null {
    const change = new CompanyForm280({
      ...this.theChangeForm.value as Partial<CompanyForm280>
    });

    try {
      return new Document({
        changes: [change],
        entityId: this.companyChangeData?.entityId,
        type: 'c:280',
        documentId: this.companyChangeData?.documentId,
      });
    } catch (error) {
      this.toastr.error('Failed to create Document.', 'Error');
      return null;
    }
  }

  get theChangeForm() {
    return this.stepperForm.controls[ShareBuyBackStepsEnumSteps.TheChange];
  }
}
