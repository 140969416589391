<div class="select-container" [style.width]="width">
  <div class="header">
    @if (label) {
      <div class="label">{{ label }}</div>
    }

    @if (link && text) {
      <div>
        <app-link [link]="link" [text]="text"></app-link>
      </div>
    }
  </div>

  @if (isDisabled) {
    <div class="dropdown">
      <button
        type="button"
        class="input-wrapper input"
        [ngClass]="{'opened': dropdownOpen}"
        [disabled]="isDisabled"
      >
        @if (value) {
          <ng-container *ngTemplateOutlet="selectedOption"></ng-container>
        } @else {
          <div class="select-placeholder">{{ placeholder }}</div>
        }
        <span class="icon ic-select-icon"></span>
      </button>
    </div>
  } @else {
    <div ngbDropdown class="dropdown" (openChange)="getDropdownState($event)">
      <button
        type="button"
        class="input-wrapper input"
        [ngClass]="{'opened': dropdownOpen}"
        [disabled]="isDisabled"
        ngbDropdownToggle
      >
        @if (value && selectedGroupIndex !== null) {
          <ng-container *ngTemplateOutlet="selectedOption"></ng-container>
        } @else {
          <div class="select-placeholder">{{ placeholder }}</div>
        }
        <span class="icon ic-select-icon"></span>
      </button>
      <div ngbDropdownMenu class="dropdown-menu">
        @if (optionGroups.length) {
          @for (optionGroup of optionGroups; track optionGroup?.name; let groupIndex = $index) {
            <div class="option-group">
              <h4 class="h4-header title" [hidden]="optionGroup.hideName">{{ optionGroup?.name }}</h4>
              @for (option of optionGroup.options; track option.value) {
                <button
                  ngbDropdownItem
                  type="button"
                  class="dropdown-item option"
                  [class.selected]="option === value"
                  [disabled]="isDisabled"
                  (click)="selectOption(option, groupIndex)"
                >
                  <i class="icon ic-arrow-right ic-sm" [hidden]="optionGroup.hideIcon"></i>
                  <div class="dropdown-button">
                    <span>{{ option.label }}</span>
                    <span class="icon ic-circle-check ic-sm"></span>
                  </div>
                </button>
              }
            </div>
          }

        } @else {
          <div class="no-options">{{ noOptionsText }}</div>
        }
      </div>
    </div>
  }

  @if (helperText) {
    <div class="helper">{{ helperText }}</div>
  }

  @if (displayValidationError) {
    <app-validation-error></app-validation-error>
  }
</div>

<ng-template #selectedOption>
  <div class="selected-option">
    @if(selectedGroupIndex !== null && optionGroups[selectedGroupIndex].name) {
      <span>{{ optionGroups[selectedGroupIndex].name }}</span>
      <i class="icon ic-arrow-right ic-sm"></i>
    }
    <span>{{ value?.label }}</span>
  </div>
</ng-template>
