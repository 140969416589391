import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Operation } from 'fast-json-patch';
import { map, Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { filterPatchOperations } from '../functions/filter-patch-operations';
import { AsicAgentData } from '../models/AsicAgentData.model';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private api: HttpClient) {
  }

  getAgentSettings(): Observable<AsicAgentData> {
    return this.api.get<AsicAgentData>(`${ environment.api_url }/settings/asic`)
      .pipe(map(agent => new AsicAgentData(agent ?? {})));
  }

  patchAgentChanges(operations: Operation[]): Observable<AsicAgentData> {
    return this.api.patch<AsicAgentData>(`${ environment.api_url }/settings/asic`, filterPatchOperations(operations), { headers: { 'Content-Type': 'application/json-patch+json' } });
  }
}
