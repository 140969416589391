import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, from, lastValueFrom } from 'rxjs';
import * as Sentry from "@sentry/angular-ivy";

@Injectable()
export class SentryInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return from(this.handle(request, next));
  }

  async handle(request: HttpRequest<unknown>, next: HttpHandler) {
    return await Sentry.startSpan({ op: 'http.client', name: `${request.method} ${request.url}` },
      async () => {
        return await lastValueFrom(next.handle(request));
      }
    );
  }
}
