import { Component, inject } from '@angular/core';
import { Router } from "@angular/router";
import { ICellRendererParams } from "ag-grid-community";
import { DocumentRecord } from "../../../../../../models/documentRecord";
import { documentStatusToStep } from "../../../../../helpers/document-status-to-step";

type CellRendererParams = ICellRendererParams<DocumentRecord>;

@Component({
  selector: 'app-ag-document-name',
  standalone: true,
  imports: [],
  templateUrl: './ag-document-name.component.html',
  styleUrl: './ag-document-name.component.scss'
})
export class AgDocumentNameComponent {
  private router = inject(Router);

  id = '';
  name = '';
  protected params!: CellRendererParams;

  agInit(params: CellRendererParams): void {
    this.params = params;
    this.name = this.params.data?.documentName ?? '';
    this.id = this.params.data?.documentId ?? '';
  }

  refresh(params: CellRendererParams): boolean {
    this.params = params;
    return true;
  }

  onOpenDocument(e: MouseEvent): void {
    e.preventDefault();
    e.stopPropagation();

    const step = documentStatusToStep(this.params.data?.status);

    if (e.ctrlKey) {
      window.open(`/document/${this.id}?step=${step}`, '_blank');
    } else {
      void this.router.navigate(['/document', this.id], { queryParams: { step } });
    }
  }
}
