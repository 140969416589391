<app-stepper-form
  header="Change of Company Status"
  subheader="Form 484B3"
  [showSteps]="false"
  [steps]="steps"
  [currentStep]="currentStep"
  [currentStepIndex]="currentStepIndex"
  [isLoading]="isLoading"
  [form]="stepperForm"
>
  <div class="step-content" [formGroup]="stepperForm">
    <app-stepper-form-description
      formKey="484 B3"
      [hidden]="currentStep !== StepsEnum.FormDescription"
    ></app-stepper-form-description>
    <section
      class="step-section"
      [hidden]="currentStep !== StepsEnum.CompanyStatusChange"
      [formGroupName]="StepsEnum.CompanyStatusChange"
    >
      <h1 class="h1-heading">{{ steps[currentStepIndex].label }}</h1>
      <div class="form-group">
        <app-date-picker
          label="Date of Change"
          formControlName="changeDate"
          [minDate]="minDate"
          [maxDate]="maxDate"
          [customErrors]="customDatepickerErrors"
        ></app-date-picker>
      </div>
      <div class="form-group">
        <app-select
          label="The Change is"
          formControlName="newCompanySubtype"
          [options]="availableChangeTypesOptions"
          [valuePrimitive]="true"
        ></app-select>
      </div>

      <div class="form-group" [hidden]="isChangeTypeCeaseOrNull()">
        <app-select
          label="What type of special purpose company?"
          formControlName="specialPurposeType"
          [options]="statusOptions"
          [valuePrimitive]="true"
        ></app-select>
      </div>
    </section>
  </div>
</app-stepper-form>
