<app-common-modal-form
  [header]="'Add carbon copy'"
  [icon]="'icon ic-note'"
  [confirmText]="'Save'"
  [closeText]="'Cancel'"
  [isInvalid]="form.invalid"
  (close)="close()"
  (confirm)="add()"
>
  <div class="body-container d-flex flex-column gap-24" [formGroup]="form">
    <app-input
      [label]="'Email *'"
      [placeholder]="'Enter email'"
      [customErrors]="emailValidatorMsg"
      formControlName="email"
    ></app-input>
  </div>
</app-common-modal-form>
