import { Component, Input, OnInit, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { setControlDisabled } from '../../../../../functions/set-control-disabled';
import { Address } from '../../../../../models/address';
import { CompanyAppointChange } from '../../../../../models/companyAppointChange';
import { Document } from '../../../../../models/document';
import { EntityChangeData } from '../../../../../models/entityChangeData';
import { AppointCompanyStepsEnum } from '../../../../../models/enums/AppointCompanyStepsEnum';
import { IStep } from '../../../../../models/step';
import { autocompleteServiceToken } from '../../../../../services/autocomplete.service';
import { AuxiliaryService } from '../../../../../services/auxiliary.service';
import { CompanyChangeAddress } from '../484-forms/a1-company-address-change/CompanyChangeAddress.model';
import { BaseStepperFormComponent } from '../../../stepper-form/base-stepper-component/base-stepper-form.component';
import {
  AddressAndOccupierFormGroupComponent
} from "../../../../components/reusable-form-groups/address-and-occupier-form-group/address-and-occupier-form-group.component";
import { StepperFormComponent } from "../../../stepper-form/stepper-form.component";
import {
  StepperFormDescriptionComponent
} from "../../../stepper-form/stepper-form-description/stepper-form-description.component";
import {
  CompanyNameAcnFormGroupComponent
} from "../../../../components/reusable-form-groups/company-name-acn-form-group/company-name-acn-form-group.component";
import { YesNoControlComponent } from "../../../../components/common/yes-no-control-component/yes-no-control.component";

@Component({
  selector: 'app-form362-appoint-cease-agent',
  standalone: true,
  templateUrl: './form362-appoint-cease-agent.component.html',
  styleUrls: [
    './form362-appoint-cease-agent.component.scss',
    '../../../stepper-form/base-stepper-component/base-stepper-form.component.scss'
  ],
  imports: [
    ReactiveFormsModule,
    StepperFormComponent,
    StepperFormDescriptionComponent,
    YesNoControlComponent,
    CompanyNameAcnFormGroupComponent,
    AddressAndOccupierFormGroupComponent
  ],
  providers: [{ provide: autocompleteServiceToken, useClass: AuxiliaryService }],
})
export class Form362AppointCeaseAgentComponent extends BaseStepperFormComponent<AppointCompanyStepsEnum, CompanyAppointChange> implements OnInit {
  @Input() address?: Address; // pass as input prop to address component for changes

  override readonly StepsEnum = AppointCompanyStepsEnum;

  expandedAddressForm = false;

  override stepperForm = new FormGroup({
    [AppointCompanyStepsEnum.FormDescription]: new FormGroup({}),
    [AppointCompanyStepsEnum.CompanyInformation]: new FormGroup({
      ...CompanyNameAcnFormGroupComponent.defineFormControls(),
      needToChangeRegisteredAddress: new FormControl<boolean | null>(null, [Validators.required]),
    }),
    [AppointCompanyStepsEnum.NewAddressInformation]: AddressAndOccupierFormGroupComponent.defineForm(),
  });

  stepsSignal: WritableSignal<IStep<AppointCompanyStepsEnum>[]> = signal([]);

  constructor() {
    super();
    this.setupSteps(AppointCompanyStepsEnum);
    this.redirectAfterSubmit = true;
  }

  ngOnInit(): void {
    this.updateFormSteps();
    this.listenNeedToChangeRegisteredAddress();
    this.setupChange();
  }

  override setupChange(change: CompanyAppointChange = this.formModel): void {
    this.addressForm.controls.address.patchValue({ country: 'AU' });
    if (!this.isEdit && change === this.formModel)
      return;

    this.companyInformationForm.patchValue({
      name: change.companyName,
      acn: change.companyACN,
      needToChangeRegisteredAddress: false
    });
  }

  override setCurrentStep(newStepIndex: number) {
    const firstInvalidFormStep = this.stepsSignal()
      .slice(0, newStepIndex)
      .find(step => this.stepperForm.controls[step.step].invalid);

    this.currentStep = this.stepsSignal()[newStepIndex].step;
    this.currentStepIndex = newStepIndex;

    if (firstInvalidFormStep?.step === AppointCompanyStepsEnum.NewAddressInformation) {
      this.expandedAddressForm = true;
    }
  }

  override buildDocument(): Document | null {
    const changes: EntityChangeData[] = [];
    const companyAppointChange = new CompanyAppointChange({
      companyName: this.companyInformationForm.controls.name.value!,
      companyACN: this.companyInformationForm.controls.acn.value!,
    });
    changes.push(companyAppointChange);

    if (this.companyInformationForm.controls.needToChangeRegisteredAddress.value) {
      const companyChangeAddress = new CompanyChangeAddress({
        ...this.addressForm.value as Partial<CompanyChangeAddress>,
        applyToRegistered: true,
        applyToPrincipal: false,
      });
      changes.push(companyChangeAddress);
    }

    try {
      return new Document({
        changes,
        entityId: this.companyChangeData?.entityId ?? null,
        type: 'c:362a',
        documentId: this.companyChangeData?.documentId,
      });
    } catch (error) {
      console.warn(error);
      this.toastr.error('Failed to create Document.', 'Error');
      return null;
    }
  }

  private listenNeedToChangeRegisteredAddress(): void {
    this.companyInformationForm.controls.needToChangeRegisteredAddress.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((needToChangeRegisteredAddress) => this.updateFormSteps(needToChangeRegisteredAddress));
  }

  private updateFormSteps(needToChangeRegisteredAddress: boolean | null = false) {
    setControlDisabled(this.addressForm, !needToChangeRegisteredAddress);
    this.addressForm.controls.occupiesTheAddress?.patchValue(this.stepperForm.value[AppointCompanyStepsEnum.NewAddressInformation]?.occupiesTheAddress ?? true);

    this.stepsSignal.set(this.steps.filter((step) => {
      if (step.step === AppointCompanyStepsEnum.NewAddressInformation) {
        return needToChangeRegisteredAddress;
      }

      return true;
    }));
  }

  get companyInformationForm() {
    return this.stepperForm.controls[AppointCompanyStepsEnum.CompanyInformation];
  }

  get addressForm() {
    return this.stepperForm.controls[AppointCompanyStepsEnum.NewAddressInformation];
  }
}
