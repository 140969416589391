<input
  #fileUploadInput
  style="display: none"
  type="file"
  [multiple]="multiple"
  [accept]="acceptedFormats"
  [disabled]="isLoading || disabled"
  (change)="handleFileInputEvent($event)"
>

<div
  class="file-upload-component"
  [class.disabled]="disabled"
  [class.loading]="isLoading"
>
  @if (label) {
    <div class="header">
      <div class="label">{{ label }}</div>
    </div>
  }

  @if(!lodgeView) {
    <div
      class="dropzone"
      [class.no-label]="!label"
      [class.file-over]="fileOver"
      (click)="tryHandleFileUpload($event, fileUploadInput)"
    >
      @if (isLoading) {
        <app-loader-standalone customClass="mh-100" [loading]="isLoading"/>
      } @else {
        <div class="dropzone-control">
          <app-button text="Upload a File"/>
          <div>...or drag and drop a file.</div>
        </div>
      }
    </div>
  } @else {
    <div
      class="lodge-dropzone"
      [class.no-label]="!label"
      [class.file-over]="fileOver"
      (click)="tryHandleFileUpload($event, fileUploadInput)"
    >
      @if (isLoading) {
        <app-loader-standalone customClass="mh-100" [loading]="isLoading"/>
      } @else {
        <div class="dropzone-control">
          <div><i class="icon ic-xlg ic-upload-file"></i></div>
          <div>Drag & drop your files here or</div>
          <app-button text="Choose Files" [btnColor]="'white'"/>
        </div>
      }
    </div>
  }

  <div class="hide-empty message">
    <ng-content select=".note"></ng-content>
  </div>

  @if (errorMessage) {
    <div>
      <app-disclaimer color="red">{{ errorMessage }}</app-disclaimer>
    </div>
  }
</div>
