<div class="action-container my-styles">
  <div
    ngbDropdown
    class="dropdown d-inline-flex"
    container="body"
    [placement]="['bottom-end', 'bottom-start', 'top-start', 'top-end']"
  >
    <app-ag-action-icon-button
      ngbDropdownToggle
      iconClass="ic-three-dots"
      [disabled]="disabled"
    />

    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
      @if (showDelete) {
        @if (showResend) {
          <div ngbDropdownItem class="dropdown-item" (click)="resend()">
            <a class="menu-action-button">
              <span>Resend</span>
            </a>
          </div>
        }

        <div ngbDropdownItem class="dropdown-item" (click)="delete()">
          <a class="menu-action-button">
            <span>Delete</span>
          </a>
        </div>
      } @else {
        <div class="no-action">No Action</div>
      }
    </div>
  </div>
</div>
