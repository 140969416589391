<div [formGroup]="form">
  <div class="form-group">
    <app-select
      label="Select Agent Type"
      formControlName="isIndividual"
      [options]="isAgentIndividualOptions"
      [valuePrimitive]="true"
    ></app-select>
  </div>

  <!-- If Individual Selected-->
  <div class="form-group" [hidden]="form.controls.isIndividual.value !== true">
    <app-individual-data-form-group
      individualsName="Agents"
      [formGroup]="form.controls.individual"
      [hiddenControls]="hiddenIndividualControls"
    />
  </div>

  <!-- If Company Selected-->
  <div class="form-group" [hidden]="form.controls.isIndividual.value !== false">
    <app-company-name-acn-form-group [formGroup]="form.controls.company"/>
  </div>
</div>
