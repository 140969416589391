import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Company } from '../../../../../models/company';
import { Document } from '../../../../../models/document';
import { EntityChangeData } from '../../../../../models/entityChangeData';
import { CompanyChangeData } from '../../../../../models/enums/companyChangeData';
import { RelationshipType } from '../../../../../models/enums/relationshipTypeEnum';
import { IndividualData } from '../../../../../models/individualData';
import { Relationship } from '../../../../../models/relationship';
import { SelectOption } from '../../../../../models/selectOptions';
import { ChangeDictionaryHelper } from '../../../../../models/shared/change-dictionary-helper.model';
import { PaymentMethod, paymentMethodsNames } from '../../../company-name-change/CompanyChangeName';
import { Form6010VoluntaryDeregistrationComponent } from './form6010-voluntary-deregistration.component';
import { IPDFSection } from "../../../../../models/shared/pdf-section.interface";

export enum VoluntaryDeregistrationStepsEnum {
  FormDescription = 0,
  ApplicantDeclaration = 1,
  DetailsOfTheApplicant = 2,
  AuthorisedSignatories = 3,
  BeneficialOwnersDetails = 4,
}

export enum ApplicantType {
  Company = 0,
  Director = 1
}

export class CompanyChangeDeregister extends EntityChangeData {
  static override readonly $type = 'CompanyChangeDeregister';
  isApplicantDirector: boolean;
  applicantIfDirector?: Relationship;
  applicantIfNominee?: IndividualData;
  declarationNoAssetsLiabilities: boolean;
  paymentMethod: PaymentMethod;

  constructor(data: Partial<CompanyChangeDeregister> = {}) {
    super(data);

    this.isApplicantDirector = data.isApplicantDirector ?? false;
    this.applicantIfDirector = data.applicantIfDirector ? new Relationship(data.applicantIfDirector) : data.applicantIfDirector;
    this.applicantIfNominee = data.applicantIfNominee ? new IndividualData(data.applicantIfNominee) : data.applicantIfNominee;
    this.declarationNoAssetsLiabilities = data.declarationNoAssetsLiabilities ?? false;
    this.paymentMethod = data.paymentMethod ?? PaymentMethod.INV;
  }

  toDictionary(): { key: string; value: string }[] {
    const dict = new ChangeDictionaryHelper();

    voluntaryDeregistrationDeclarations.forEach((key) => dict.add(key, 'Yes'));

    dict
      .add('Applicant', this.isApplicantDirector ? 'Director' : 'Company')
      .addIfValueIsNotEmpty('Name of the Director', this.applicantIfDirector?.individualDataOverride?.fullName)
      .addIfValueIsNotEmpty('Name of the Nominee', this.applicantIfNominee?.fullName)
      .addIfValueIsNotEmpty('Address of the Nominee', this.applicantIfNominee?.address?.normalizedFullAddress)
      .add('Payment Method', paymentMethodsNames[this.paymentMethod]);

    return dict.dictionary;
  }

  fillModal(modalRef: NgbModalRef, document: Document, actualCompany: Company): NgbModalRef {
    modalRef = this.fillModalWithCompany(modalRef, actualCompany, true);
    (modalRef.componentInstance as Form6010VoluntaryDeregistrationComponent).companyChangeData.documentId = document.documentId;
    return modalRef;
  }

  fillModalWithCompany(modalRef: NgbModalRef, company: Company, isEdit: boolean): NgbModalRef {
    const instance = modalRef.componentInstance as Form6010VoluntaryDeregistrationComponent;

    instance.isEdit = isEdit;
    instance.companyChangeData = new CompanyChangeData(company);
    instance.formModel = structuredClone(this);

    // TODO: remove after individualId works fine
    const officerIndividualsIdDictionary: Record<string, string> = {};

    instance.directorsOptions = structuredClone(company.officers)
      .reduce((directorsOptions: SelectOption[], officer) => {
          // TODO: remove after individualId works fine
          if (!(officer.individualDataOverride!.fullName in officerIndividualsIdDictionary)) {
            officerIndividualsIdDictionary[officer.individualDataOverride!.fullName] = Math.random().toString(36).substring(7);
          }

          // TODO: remove after individualId works fine
          officer.key = officer.individualId ?? officerIndividualsIdDictionary[officer.individualDataOverride!.fullName];

          const officerOption: SelectOption = {
            label: officer.individualDataOverride!.fullName,
            value: officer.key,
          };

          if (!(officer.key in instance.officers)) {
            instance.officers[officer.key] = [];

            if (officer.type === RelationshipType.Director) {
              directorsOptions?.push(officerOption);
            }
          }

          instance.officers[officer.key]?.push(officer);

          return directorsOptions;
        },
        []
      )
      .sort((a, b) => a.label?.toLowerCase().localeCompare(b.label?.toLowerCase()));

    return modalRef;
  }

  override prepareToPdf(): IPDFSection[] {
    const sectionDict = new ChangeDictionaryHelper();

    sectionDict.addSection('Applicant Declaration');
    voluntaryDeregistrationDeclarations.forEach((key) => sectionDict.add(key, 'Yes'))

    sectionDict
      .addSection('Details of the Applicant')
      .add('Applicant', this.isApplicantDirector ? 'Director' : 'Company')
      .addIfValueIsNotEmpty('Name of the Director', this.applicantIfDirector?.individualDataOverride?.fullName ?? '')
      .addIfValueIsNotEmpty('Name of the Nominee', this.applicantIfNominee?.fullName ?? '')
      .addIfValueIsNotEmpty('Address of the Nominee', this.applicantIfNominee?.address?.normalizedFullAddress.trim() ?? '')
      .add('Payment Method', paymentMethodsNames[this.paymentMethod]);

    return sectionDict.sections;
  }
}

export const voluntaryDeregistrationDeclarations = [
  'All members of the company agree to the deregistration',
  'The company is not carrying on business',
  'The company\'s assets are worth less than $1000',
  'The company has paid all fees and penalties payable under this Act',
  'The company has no outstanding liabilities',
  'The company is not a party to any legal proceedings'
];
