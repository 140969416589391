import { Component, inject } from '@angular/core';
import { StepperFormComponent } from "../../../modals/stepper-form/stepper-form.component";
import { AddTrustSteps, getTrustType, trustTypesOptionGroups } from "./form-add-trust.model";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { enumToSteps } from "../../../../functions/enums-to-list-formatter";
import { CustomFormValidators } from "../../../../custom-form-validators/custom-form-validators";
import { NgbActiveModal, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { DatepickerHelper } from "../../../../custom-form-validators/date-picker-form-validators";
import { InputComponent } from "../../../components/common/input/input.component";
import { DatePickerComponent } from "../../../components/common/date-picker/date-picker.component";
import {
  SelectGroupsComponent,
  SelectOptionGroup
} from "../../../components/common/select-groups/select-groups.component";
import {
  AbnMaskedInputComponent
} from "../../../components/common/masked-input/specific-masked-inputs/abn-masked-input/abn-masked-input.component";
import { SelectComponent } from "../../../components/common/select/select.component";
import { TrustsService } from "../../../../services/trustsService";
import { Trust } from "../../../../models/trust";
import { JurisdictionType, JurisdictionTypeOptions, TrustSubType } from "../../../../models/enums/trustEnum";
import { finalize } from "rxjs";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-form-add-trust',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    StepperFormComponent,
    InputComponent,
    SelectComponent,
    DatePickerComponent,
    SelectGroupsComponent,
    AbnMaskedInputComponent,
  ],
  templateUrl: './form-add-trust.component.html',
  styleUrl: './../../../modals/stepper-form/base-stepper-component/base-stepper-form.component.scss',
})
export class FormAddTrustComponent {
  private activeModal = inject(NgbActiveModal);
  private trustsService: TrustsService = inject(TrustsService);
  private toastr = inject(ToastrService);

  readonly StepsEnum = AddTrustSteps;
  readonly steps = enumToSteps<AddTrustSteps>(AddTrustSteps);
  readonly trustTypesOptionGroups: SelectOptionGroup[] = trustTypesOptionGroups;
  readonly JurisdictionTypeOptions = JurisdictionTypeOptions;

  minDate: NgbDateStruct = DatepickerHelper.getStructFromDate(new Date(1, 0, 1920)); // Extend date range from 1920 January
  maxDate: NgbDateStruct = DatepickerHelper.getNextNYearsStruct(100);

  currentStep = AddTrustSteps.TrustInformation;
  currentStepIndex = 0;
  isLoading = false;

  stepperForm = new FormGroup({
    [AddTrustSteps.TrustInformation]: new FormGroup({
      name: new FormControl<string | null>(null, [Validators.required, CustomFormValidators.maxLength(50)]),
      trustSubType: new FormControl<TrustSubType | null>(null, [Validators.required]),
      entityNumber: new FormControl<string | null>(null),
      dateOfEstablishment: new FormControl<Date | null>(null),
      jurisdiction: new FormControl<JurisdictionType | null>(null, [Validators.required]),
    }),
  });

  submit(): void {
    if (this.stepperForm.invalid) {
      this.stepperForm.markAllAsTouched();
      return;
    }

    const trust = new Trust({
      ...this.trustInformationForm.value as Partial<Trust>,
      trustType: getTrustType(this.trustInformationForm.controls.trustSubType.value!),
    });

    this.isLoading = true;
    this.stepperForm.disable();

    this.trustsService.addTrust(trust)
      .pipe(finalize(() => {
        this.isLoading = false;
        this.stepperForm.enable();
      }))
      .subscribe({
        next: (res) => this.activeModal.close(res),
        error: (error) => {
          console.warn('[FormAddTrustComponent.submit] Add Trust Error: ', error);
          this.toastr.error('Failed to create Trust');
        }
      });
  }

  close(): void {
    this.activeModal.dismiss();
  }

  setCurrentStep(newStepIndex: number): void {
    this.currentStep = this.steps[newStepIndex].step;
    this.currentStepIndex = newStepIndex;
  }

  get trustInformationForm() {
    return this.stepperForm.controls[AddTrustSteps.TrustInformation];
  }
}
