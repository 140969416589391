<div class="generate-document-container">
  <div class="generate-documents-header">
    <app-page-title
      header="Document Lodgment Tracker"
      iconClass="ic-documents"
    />

    <div class="actions-container">
      <app-generate-documents-header
        [loading]="loading"
        [currentStepIndex]="currentStepIndex"
        [document]="document"
        [notBeLodgedFormTypeList]="notBeLodgedFormTypeList"
        (saveAndCompleteLater)="saveAndCompleteLater()"
        (confirmChanges)="confirmChanges()"
        (signDocuments)="signDocuments()"
        (paperSignDocuments)="paperSignDocuments()"
        (sendEmail)="sendEmail()"
        (lodgeDocumentNow)="lodgeDocumentNow()"
        (confirmNotLodgedForm)="confirmNotLodged()"
      ></app-generate-documents-header>
    </div>
  </div>

  <div class="generate-documents-content" [class.hide]="collapsedStateBtn">
    <app-generate-documents-sidebar
      [class.hide]="collapsedStateBtn"
      [loadCompany]="loading"
      [currentStepIndex]="currentStepIndex"
      [document]="document"
      [lodgement]="lodgement"
      [entity]="actualCompany || actualTrustProfile"
      [entityType]="entityType"
      [confirmLodgeDocumentNowAction$]="confirmLodgeDocumentNowAction$"
      (setLastStep)="setLastStep()"
    ></app-generate-documents-sidebar>

    <div class="step-content">
      <app-button
        class="sidebar-switch-btn"
        [btnColor]="'white'"
        [iconClass]="collapsedStateBtn ? 'icon ic-arrow-right' : 'icon ic-arrow-left'"
        (clickBtn)="collapsedStateBtn = !collapsedStateBtn"
      ></app-button>

      <div class="d-flex flex-column w-100">
        <app-clickable-stepper
          class="stepper-container"
          [shouldControlStepsUsingInput]="true"
          [currentStepIndex]="currentStepIndex"
          [steps]="steps"
          [disableSteps]="modalOpened()"
          (stepIndexChange)="setCurrentStep($event)"
        ></app-clickable-stepper>

        <div class="content-container" [class.sign-container]="currentStepIndex === DocumentStepEnum.SignDocuments">
          @switch (currentStepIndex) {
            @case(DocumentStepEnum.InitiateChange) {
              @if(!loading && document && actualCompany) {
                <app-documents-changes-preview
                  [loading]="loading"
                  [document]="document"
                  [entity]="actualCompany"
                  [relationshipHashset]="relationshipHashset"
                  [confirmAction$]="confirmAction$"
                  [updatePreview]="updatePreviewTrigger"
                  (editDocumentChange)="editDocumentChange($event)"
                  (nextStep)="setNextStep()"
                ></app-documents-changes-preview>
              } @else {
                @if(loading) {
                  <div *ngTemplateOutlet="skeletonTemplate"></div>
                }
                @if(!document || !actualCompany) {
                  <div></div>
                }
              }
            }
            @case(DocumentStepEnum.GenerateDocuments) {
              @if(!loading && document && actualCompany) {
                <app-generate-documents-form
                  [document]="document"
                  [company]="actualCompany"
                  [saveAction$]="saveAction$"
                  [confirmAction$]="confirmAction$"
                  [declarationState]="declarationState"
                  (nextStep)="setNextStep()"
                  (updateDocuments)="updateDocuments()"
                  (declarationStateChange)="onDeclarationStateChange($event)"
                ></app-generate-documents-form>
              } @else {
                @if(loading) {
                  <div *ngTemplateOutlet="skeletonTemplate"></div>
                }
                @if(!document || !actualCompany) {
                  <div></div>
                }
              }
            }
            @case(DocumentStepEnum.SignDocuments) {
              @if(!loading && document) {
                <app-sign-documents
                  [documentId]="documentId"
                  [documentSigning]="document.documentSigning"
                  [documentStatus]="document.documentStatus"
                  [documentType]="document.type"
                  [changeAuthorisation]="document.changeAuthorisation"
                  [lodgement]="lodgement"
                  [company]="actualCompany"
                  [confirmAction$]="confirmAction$"
                  [confirmSentESignAction$]="confirmSentESignAction$"
                  [confirmPaperSignAction$]="confirmPaperSignAction$"
                  [confirmSendEmailAction$]="confirmSendEmailAction$"
                  [confirmNotLodgedFormAction$]="confirmNotLodgedFormAction$"
                  (updateDocuments)="updateDocuments()"
                  (nextStep)="setNextStep()"
                ></app-sign-documents>
              } @else {
                @if(loading) {
                  <div class="sign-container" *ngTemplateOutlet="skeletonTemplate"></div>
                }
                @if(!document) {
                  <div></div>
                }
              }
            }
            @case(DocumentStepEnum.AsicResponse) {
              @if(!loading) {
                <app-asic-response
                  [document]="document"
                  (editDocument)="editDocument()"
                ></app-asic-response>
              } @else {
                <div *ngTemplateOutlet="skeletonTemplate"></div>
              }
            }
            @default {
              <p>Unexpected step index: {{ currentStepIndex }}</p>
            }
          }
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #skeletonTemplate>
  <div class="skeleton-container">
    <div class="mb-4">
      <ngx-skeleton-loader count="1" appearance="line" />
    </div>
    <ngx-skeleton-loader count="1" appearance="line" />
    <ngx-skeleton-loader count="2" appearance="line" />
    <div class="d-flex gap-24">
      <ngx-skeleton-loader style="width: 50%" count="1" appearance="line" />
      <ngx-skeleton-loader style="width: 50%" count="1" appearance="line" />
    </div>
    <ngx-skeleton-loader count="2" appearance="line" />
    <div class="d-flex gap-24">
      <ngx-skeleton-loader style="width: 50%" count="1" appearance="line" />
      <ngx-skeleton-loader style="width: 50%" count="1" appearance="line" />
    </div>
    <ngx-skeleton-loader count="6" appearance="line" />
  </div>
</ng-template>
