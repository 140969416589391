<div [formGroup]="formGroup">
  <app-select-shareholder
    label="Current Shareholders"
    formControlName="securityRegistryRecordIndex"
    [securityRegistryRecords]="securityRegistryRecords"
  ></app-select-shareholder>

  @if (formGroup.value.securityRegistryRecordIndex || formGroup.value.securityRegistryRecordIndex === 0) {
    @for (holder of securityRegistryRecords[formGroup.value.securityRegistryRecordIndex!].holders; track holder) {
      @if (!holder.details.isBeneficialOwner) {
        <div class="form-group">
          <app-beneficial-owner
            [controlLike]="true"
            [details]="holder.details"
            [relationshipId]="holder.relationshipId"
            [showOpenIcon]="false"
            (onDataSaved)="signDataSaved()">
          </app-beneficial-owner>
        </div>
      }
    }
  }

  <div class="form-group" [hidden]="beneficialOwnersSignsErrorMessageHidden">
    <app-validation-error
      [control]="formGroup"
      [customErrors]="customSignErrors"
    ></app-validation-error>
  </div>
</div>
