import { Component, Input } from '@angular/core';
import { CurrencyPipe, NgClass } from "@angular/common";
import { DividerComponent } from "../divider/divider.component";

type NotificationColor = 'green' | 'blue' | 'orange' | 'red' | 'purple' | '';

@Component({
  selector: 'app-notification',
  standalone: true,
  imports: [
    CurrencyPipe,
    DividerComponent,
    NgClass
  ],
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.scss'
})
export class NotificationComponent {
  @Input() iconClass = '';
  @Input() color: NotificationColor = '';
  @Input() title = '';
  @Input() text = '';
  @Input() amount = ''
  @Input() isMoneyFormat = true;
}
