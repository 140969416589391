import { Component, Input } from '@angular/core';
import { NgClass } from "@angular/common";
import { ICellRendererParams } from 'ag-grid-enterprise';

@Component({
  selector: 'app-ag-yes-no-status',
  standalone: true,
  templateUrl: './ag-yes-no-status.component.html',
  imports: [
    NgClass
  ],
  styleUrl: './ag-yes-no-status.component.scss'
})
export class AgYesNoStatusComponent {
  @Input() value!: boolean;

  agInit(params: ICellRendererParams): void {
    this.value = params.value;
  }

  refresh(params: ICellRendererParams): boolean {
    this.value = params.value;
    return true;
  }
}
