import { Component } from '@angular/core';
import { TagComponent } from "../../../../../components/common/tag/tag.component";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { UserRecord } from "../../../../../../models/userRecord";
import { Tag } from "../../../../../../models/tag";

type CellRendererParams = ICellRendererParams<UserRecord> & {
  roleClick?: (role: string) => void
}

const colorRoleDictionary: Record<string, string> = {
  Admin: '#35A37F',
  Manager: '#2E8DB7',
  Preparer: '#6D6FD4',
  User: '#8E6CDA'
}

@Component({
  selector: 'app-ag-user-roles',
  standalone: true,
  imports: [
    TagComponent
  ],
  templateUrl: './ag-user-roles.component.html',
  styleUrl: './ag-user-roles.component.scss'
})
export class AgUserRolesComponent implements ICellRendererAngularComp {
  params!: CellRendererParams;
  roles: Tag[] = [];

  agInit(params: CellRendererParams): void {
    this.params = params;
    this.fillRoles();

  }

  refresh(params: CellRendererParams): boolean {
    this.params = params;
    this.fillRoles();

    return true;
  }

  handleRoleClick(role: Tag): void {
    this.params.roleClick?.(role.name);
  }

  private fillRoles(): void {
    this.roles = this.params.data?.roles?.map((role) => new Tag({
      customColor: colorRoleDictionary[role] || '',
      name: role,
      tagId: role
    })) ?? [];
  }
}
