<div class="card settings-page text-message-templates-page">
  <app-page-title
    header="SMS Templates"
    iconClass="ic-message"
    backButtonPath="settings"
  />

  @if (!isLoading) {
    <div class="grid">
      @for (textMessageTemplate of textMessageTemplates; track textMessageTemplate; let index = $index) {
        <app-edit-text-message-template
          [isLoading]="textMessageTemplate.isLoading"
          [textMessageTemplate]="textMessageTemplate.value"
          [textMessageDefaultTemplatesRecord]="defaultTemplatesRecord"
          (showErrorMessage)="showErrorMessage($event)"
          (save)="saveTextMessageTemplate($event, index)"
          (testTextMessageTemplate)="testTextMessageTemplate($event)"
          (startEditing)="onStartSmsEditing(index)"
        />
      }
    </div>
  }

  <app-loader [loading]="isLoading"/>
</div>
