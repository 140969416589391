<div class="input-container" [class.disabled]="isDisabled">
  <div class="header">
    <div class="label">{{ label }}</div>
  </div>

  <div class="input-wrapper">
    <ul class="selected-numbers list-unstyled">
      @for (num of value; track num; let index = $index) {
        <li
          class="selected-number"
          [class.invalid]="!numbersValidity[index]"
        >
          <span>{{ num }}</span>

          @if (!readonly || !isDisabled) {
            <button class="remove-button" [disabled]="isDisabled" (click)="removeItem(index)">
              <span class="icon ic-tiny-xs ic-close"></span>
            </button>
          }
        </li>
      }

      @if (!readonly || !isDisabled) {
        <li class="number-control-wrapper">
          <form class="form h-100" (submit)="submit($event)">
            <input
              type="number"
              class="input"
              [min]="minNumber"
              [max]="maxNumber"
              [placeholder]="placeholder"
              [formControl]="numberControl"
              (keydown)="handleNumbersInput($event.key)"
              (blur)="handleNumbersInput(',')"
            >

            <button type="submit" hidden [disabled]="isDisabled"></button>
          </form>
        </li>
      }
    </ul>
  </div>

  <div class="tip">{{ tip }}</div>

  @if (shouldShowErrors && !isDisabled) {
    <div class="errors-wrapper">
      <app-validation-error
        [control]="control"
        [customErrors]="customErrors"
      ></app-validation-error>
    </div>
  }
</div>

