import { Pipe, PipeTransform } from '@angular/core';
import { DocumentStatusEnum } from '../../models/enums/documentStatusEnum';
@Pipe({
  standalone: true,
  name: 'documentStatus'
})
export class DocumentStatusPipe implements PipeTransform {
  transform(value: DocumentStatusEnum | undefined | null): string {
    if(value != undefined) {
      if (value === DocumentStatusEnum.Draft) {
        return 'Draft'
      } else if (value === DocumentStatusEnum.LodgementPending) {
        return 'Lodgement Pending'
      } else if (value === DocumentStatusEnum.AuthorisationPending) {
        return 'Authorisation Pending'
      } else if (value === DocumentStatusEnum.SignaturePending) {
        return 'Signature Pending'
      } else if (value === DocumentStatusEnum.Completed) {
        return 'Completed'
      }
    }

    return '';
  }
}
