import { Component, EventEmitter, Input, Output, inject, DestroyRef } from '@angular/core';
import { CurrencyPipe, DatePipe } from "@angular/common";
import { Company } from "../../../models/company";
import { DividerComponent } from "../common/divider/divider.component";
import { ButtonComponent } from "../common/button/button.component";
import {
  NgbDropdown,
  NgbDropdownItem,
  NgbDropdownMenu,
  NgbDropdownToggle,
} from "@ng-bootstrap/ng-bootstrap";
import { ExternalSourceType } from '../../../models/enums/externalEntityEnums';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { Guid } from '../../helpers/guid.helper';
import { SubMenuItem } from "../../../models/sectionFormMenu";
import { ModalFormsService } from "../../../services/modal-forms.service";
import { GeneratePayslipComponent } from "../../modals/generate-payslip/generate-payslip.component";
import { TooltipComponent } from "../common/tooltip/tooltip.component";
import {
  AnnualPaymentRemindersComponent
} from "../../modals/annual/annual-payment-reminders/annual-payment-reminders.component";
import { EntityIdentityService } from '../../../services/entity.identity.service';

@Component({
  selector: 'app-company-profile-card',
  standalone: true,
  imports: [
    CurrencyPipe,
    DividerComponent,
    ButtonComponent,
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
    DatePipe,
    TooltipComponent
  ],
  templateUrl: './company-profile-card.component.html',
  styleUrl: './company-profile-card.component.scss'
})
export class CompanyProfileCardComponent {
  @Input() companyProfile!: Company;
  @Input() disableControls = false;
  @Input() countPendingForm = 0;

  @Output() openCompanyChangeNameForm = new EventEmitter<void>();
  @Output() onCompanyRefreshed = new EventEmitter<void>();
  @Output() onSelectTab = new EventEmitter<string>();
  private toastr = inject(ToastrService);
  private entityIdentityService = inject(EntityIdentityService);
  private modalFormsService = inject(ModalFormsService);
  private destroyRef = inject(DestroyRef);
  ExternalSourceType = ExternalSourceType;

  debtUpdatedDate = new Date();
  readonly refreshDebtMsg = 'Your request has been submitted. We are expecting ASIC\'s' +
    ' response soon. Once you\'ve received it, you\'ll be notified via the Alerts page.'
  companyDebtMenu: SubMenuItem[] = [
    {
      title: 'Refresh Company Debt',
      iconClass: 'icon ic-refresh',
      action: () => this.refreshCompanyDebt()
    },
    {
      title: 'Generate a Payslip',
      iconClass: 'icon ic-generate-documents',
      action: () => this.generatePayslip()
    },
    {
      title: 'Send Payment Reminder',
      iconClass: 'icon ic-send',
      action: () => this.sendPaymentReminder()
    },
    {
      title: 'Pay Debt',
      iconClass: 'icon ic-debt',
      action: () => this.payDebt()
    },
  ];

  xpmMenu: SubMenuItem[] = [
    {
      title: 'Go to Profile',
      iconClass: 'icon ic-external-link',
      action: () => this.redirectToExternalProfile(ExternalSourceType.Xpm)
    },
    {
      title: 'Refresh',
      iconClass: 'icon ic-refresh',
      action: () => void this.refreshProfile(ExternalSourceType.Xpm)
    },
    {
      title: 'Unlink Profile',
      iconClass: 'icon ic-unlink',
      action: () => void this.unlinkProfile(ExternalSourceType.Xpm)
    }
  ];

  onOpenCompanyChangeNameForm(): void {
    this.openCompanyChangeNameForm.emit();
  }

  isConnectedtoExtentedEntity(sourceType: ExternalSourceType) {
    const externalEntity = this.companyProfile.externalEntities.find(x => x.source == sourceType);
    return externalEntity == undefined;
  }

  redirectToExternalProfile(sourceType: ExternalSourceType) {
    const externalEntity = this.companyProfile.externalEntities.find(x => x.source == sourceType);
    if(externalEntity?.linkTo && externalEntity.linkTo != ""){
      window.open(externalEntity.linkTo, '_blank');
      return
    }

    this.toastr.error("Link was not found");
  }

  async unlinkProfile(sourceType: ExternalSourceType) {
   try{
      await firstValueFrom(this.entityIdentityService.unlink(this.companyProfile.entityId, sourceType));
      this.toastr.success("Unlink successfully");
   }catch {
    this.toastr.error("Error happen");
   }
  }

  linkProfile(sourceType: ExternalSourceType) {
    try{
       //await firstValueFrom(this.integrationsSerivice.unlink(this.companyProfile.entityId, sourceType));
       this.toastr.success("Link successfully");
    }catch {
     this.toastr.error("Error happen");
    }
   }

  async refreshProfile(sourceType: ExternalSourceType){
    try{
      const externalEntity = this.companyProfile.externalEntities.find(x => x.source == sourceType);

      if(!externalEntity || externalEntity.externalId == Guid.EmptyGuid){
        this.toastr.error("External entity was not found");
        return;
      }

       await firstValueFrom(this.entityIdentityService.refresh(this.companyProfile.entityId, externalEntity.externalId, sourceType));
       this.onCompanyRefreshed.emit();
       this.toastr.success("Refreshed");
    }catch {
     this.toastr.error("Error happen");
    }
  }

  refreshCompanyDebt(): void {
    alert(this.refreshDebtMsg);
  }

  generatePayslip(): void {
    const componentInstance = this.modalFormsService.openModal(GeneratePayslipComponent, {
      size: 'sm'
    }).componentInstance as GeneratePayslipComponent;
    componentInstance.companyProfile = this.companyProfile;

    const modalElement: HTMLElement | null = componentInstance.modalElement?.nativeElement as HTMLElement | null;
    if (modalElement) {
      modalElement.addEventListener('keydown', (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
          event.stopPropagation();
          event.preventDefault();
        }
      });
    }
  }

  sendPaymentReminder(): void {
    const componentInstance = this.modalFormsService
      .openModal(AnnualPaymentRemindersComponent).componentInstance as AnnualPaymentRemindersComponent;
    componentInstance.isAnnualReminder = false;
    componentInstance.companyProfile = this.companyProfile;

  }

  payDebt(): void {
    const payUrl = 'https://paypaperbills.postbillpay.com.au/postbillpay/pay/default';
    window.open(payUrl, '_blank');
  }

  openDocumentsTab(): void {
    this.onSelectTab.emit('Documents');
  }
}
