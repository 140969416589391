import { EmailPayloadBase } from "../emailPayloadBase";

export class AnnualStatementDebtReminderEmailPayload extends EmailPayloadBase {
  static override $type = 'AnnualStatementDebtReminderEmailPayload';

  companyName: string;
  companyACN: string;
  billingContactFirstName: string;
  billingContactFullName: string;

  directorFirstName: string;
  directorFullName: string;
  username: string;
  partner: string;
  asicContact: string;
  accountManager: string;

  //01 January 2024 format
  annualReviewFeeDeadline: string;
  //01/01/2024 format
  annualReviewFeeDeadlineShortFormat: string;
  debt: string;
  lateFeeForOneMonth: string;
  lateFeeForMoreThanOneMonth: string;
  payUrl: string;

  constructor(data: Partial<AnnualStatementDebtReminderEmailPayload> = {}) {
    super(data);

    this.companyName = data.companyName ?? '';
    this.companyACN = data.companyACN ?? '';
    this.billingContactFirstName = data.billingContactFirstName ?? '';
    this.billingContactFullName = data.billingContactFullName ?? '';
    this.directorFirstName = data.directorFirstName ?? '';
    this.directorFullName = data.directorFullName ?? '';
    this.username = data.username ?? '';
    this.partner = data.partner ?? '';
    this.asicContact = data.asicContact ?? '';
    this.accountManager = data.accountManager ?? '';
    this.annualReviewFeeDeadline = data.annualReviewFeeDeadline ?? '';
    this.annualReviewFeeDeadlineShortFormat = data.annualReviewFeeDeadlineShortFormat ?? '';
    this.debt = data.debt ?? '';
    this.lateFeeForOneMonth = data.lateFeeForOneMonth ?? '';
    this.lateFeeForMoreThanOneMonth = data.lateFeeForMoreThanOneMonth ?? '';
    this.payUrl = data.payUrl ?? '';
  }
}
