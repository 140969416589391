export enum CompanyStatus {
  'active' = 0,
  'strike-off' = 1,
  'deregistered' = 2,
  'administration' = 3,
  'non-agent' = 4,
  'archived' = 5
}

// export enum CompanyStatus {
//     Registered,
//     Deregistering,
//     Deregistered,
//     Administration,
//     External
// }

export enum CompanyType {
        LMSH, // Limited by shares
        NLIA, // No liability
        UNLM, // Unlimited
        LMGT, // Limited by guarantee
        LMSG  // Limited by shares and guarantee
}

export enum CompanySubtype {
        PNPC, // Proprietary non-profit
        PSTC, // Proprietary SMSF trustee
        HUNT, // Proprietary home unit company
        PROP, // Proprietary other company
        LISN, // Section 383 company
        LISS, // Superannuation trustee company
        LIST, // Listed public company
        STFI, // Small transferring financial institutions
        NLTD, // Not limited public company (section 150)
        ULSN, // Unlisted public non-profit company
        ULSS, // Unlisted superannuation trustee
        ULST  // Unlisted public
}
