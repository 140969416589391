import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CheckboxComponent } from "../../common/checkbox/checkbox.component";
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle } from "@ng-bootstrap/ng-bootstrap";
import { DividerComponent } from "../../common/divider/divider.component";
import { ButtonComponent } from "../../common/button/button.component";
import { GroupByOption } from "../../../../models/gridFilter";

@Component({
  selector: 'app-group-by-btn',
  standalone: true,
  imports: [
    CheckboxComponent,
    NgbDropdownMenu,
    NgbDropdownToggle,
    NgbDropdown,
    DividerComponent,
    ButtonComponent
  ],
  templateUrl: './group-by-btn.component.html',
  styleUrl: './group-by-btn.component.scss'
})
export class GroupByBtnComponent implements OnInit {
  @Input() groupByOptions: GroupByOption[] = [];
  @Input() isDisabled = false;
  @Output() clearGroupBy = new EventEmitter<boolean>();
  @Output() changeGroupByOption = new EventEmitter<number>();

  selectedGroupByIndex = -1;

  ngOnInit(): void {
    this.groupByOptions.forEach((o, index) => {
      if(o.active) {
        this.selectedGroupByIndex = index;
      }
    });
  }

  onClearGroupBy(): void {
    this.clearGroupBy.emit(true);
  }

  onChangeGroupByOption(index: number): void {
    this.selectedGroupByIndex = index;
    this.changeGroupByOption.emit(index);
  }
}
