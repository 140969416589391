export const downloadBase64File = (base64Data: string, fileName: string) => {
  // Convert base64 to binary
  const binaryData = atob(base64Data);

  // Create ArrayBuffer from binary data
  const arrayBuffer = new ArrayBuffer(binaryData.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < binaryData.length; i++) {
    uint8Array[i] = binaryData.charCodeAt(i);
  }

  // Create Blob from ArrayBuffer
  const blob = new Blob([uint8Array], { type: 'application/octet-stream' });

  // Create download link
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;

  // Trigger download
  document.body.appendChild(a);
  a.click();

  // Cleanup
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
}
