import { Component, EventEmitter, input, Output } from '@angular/core';
import { AgGridAngular } from "ag-grid-angular";
import { AgCustomPaginationComponent } from "../ag-custom-pagination/ag-custom-pagination.component";
import {
  CsvExportParams,
  ExcelExportParams,
  GridReadyEvent,
  GetContextMenuItems,
  IServerSideDatasource,
  GetRowIdFunc,
  GridColumnsChangedEvent,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy,
  SizeColumnsToContentStrategy, StatusPanelDef, ExcelStyle, RowClassRules,
} from 'ag-grid-community';
import { ColDef, GridOptions, GridApi } from 'ag-grid-enterprise';
import { RowGroupingDisplayType } from "ag-grid-community/dist/lib/entities/gridOptions";

@Component({
  selector: 'app-list-grid',
  standalone: true,
  imports: [
    AgGridAngular,
    AgCustomPaginationComponent,
  ],
  templateUrl: './list-grid.component.html',
  styleUrl: './list-grid.component.scss'
})
export class ListGridComponent {
  themeClass= input<string>('ag-theme-quartz');
  rowData= input.required<unknown[]>();
  colDefs= input.required<ColDef[]>();
  maxBlocksInCache = input<number | undefined>();
  getRowHeight = input<number | undefined | null>();
  rowClassRules = input<RowClassRules<unknown> | undefined>();
  detailRowHeight = input<number | undefined>();
  detailRowAutoHeight = input<boolean>(false);
  autoSizeStrategy= input<SizeColumnsToFitGridStrategy | SizeColumnsToFitProvidedWidthStrategy | SizeColumnsToContentStrategy>();
  getContextMenuItems = input<GetContextMenuItems<any> | undefined>();
  gridOptions = input<GridOptions>();
  groupDefaultExpanded = input<number | undefined>();
  rowSelection = input<'single' | 'multiple'>('multiple');
  quickFilterText= input<string>();
  statusBar = input< {statusPanels: StatusPanelDef[]} | undefined>();
  suppressCellFocus = input<boolean>(false);
  suppressRowClickSelection= input<boolean>(false);
  pagination= input<boolean>(false);
  paginationPageSize= input<number>(20);
  pageSizeList= input<number[]>([20, 50, 100, 250, 500]);
  paginationPageSizeSelector= input<number[] | boolean>(false);
  rowModelType= input<'serverSide' | 'infinite' | 'clientSide'>('clientSide');
  serverSideDatasource= input<IServerSideDatasource | undefined>();
  getRowId= input<GetRowIdFunc<unknown> | undefined>();
  enableAdvancedFilter = input<boolean>(false);
  cacheBlockSize = input<number>(20);
  masterDetail = input<boolean>(false);
  detailCellRenderer = input<unknown>();
  detailCellRendererParams= input<unknown>();
  groupDisplayType = input<RowGroupingDisplayType>();
  defaultExcelExportParams = input<ExcelExportParams>();
  defaultCsvExportParams = input<CsvExportParams>();
  excelStyles = input<ExcelStyle[]>([]);
  suppressPaginationPanel = input<boolean>(true);
  suppressScrollOnNewData = input<boolean>(true);

  @Output() select = new EventEmitter();
  @Output() gridReady = new EventEmitter();
  @Output() cellValueChanged = new EventEmitter();

  private gridApi!: GridApi;
  currentPage = 0;
  totalPages = 0;
  pageNumbers: (number | string)[] = [];

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api as unknown as GridApi;

    if (this.rowModelType() === 'clientSide') {
      this.totalPages =  this.gridApi.paginationGetTotalPages();
      this.pageNumbers = this.getPageNumbers();
    } else if (this.rowModelType() === 'serverSide') {
      this.gridApi?.addEventListener('modelUpdated', () => {
        this.totalPages = (this.gridApi as unknown as { paginationProxy: { totalPages: number }}).paginationProxy.totalPages;
        this.pageNumbers = this.getPageNumbers();
        this.currentPage = this.gridApi.paginationGetCurrentPage();
      });
    }

    this.gridReady.emit(params.api);
  }

  onSelectionChanged(event: { api: { getSelectedRows: () => unknown } }) {
    const selectedRows = event.api.getSelectedRows();
    this.select.emit(selectedRows);
  }

  onPaginationChanged(params: GridColumnsChangedEvent): void {
    if (this.paginationPageSize()) {
      this.totalPages = params.api.paginationGetTotalPages();
      this.pageNumbers = this.getPageNumbers();
      if(this.rowModelType() === 'serverSide') {
        this.cacheBlockSize = this.paginationPageSize;
      }
    }
  }

  onFilterChanged(event: { api: unknown }): void {
    this.totalPages = (event.api  as { paginationProxy: { totalPages: number } }).paginationProxy.totalPages;
    this.currentPage = (event.api as { paginationProxy: { currentPage: number } }).paginationProxy.currentPage;
    this.pageNumbers = this.getPageNumbers();
  }

  onCellValueChanged(event: unknown): void {
    this.cellValueChanged.emit(event);
  }

  goToPreviousPage(): void {
    this.gridApi.paginationGoToPreviousPage();
    this.currentPage = this.gridApi.paginationGetCurrentPage();
    this.updatePageNumbers(this.currentPage + 1);
  }

  goToNextPage(): void {
    this.gridApi.paginationGoToNextPage();
    this.currentPage = this.gridApi.paginationGetCurrentPage();
    this.updatePageNumbers(this.currentPage + 1);
  }

  goToPage(page: number): void {
    this.gridApi.paginationGoToPage(page);
    this.currentPage = this.gridApi.paginationGetCurrentPage();
    this.updatePageNumbers(page + 1);
  }

  getPageNumbers(): (number | string)[] {
    const pageNumbers: (number | string)[] = [];
    const maxPagesToShow = 5;

    if (this.totalPages <= maxPagesToShow) {
      for (let i = 1; i <= this.totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
      const startPage = Math.max(1, this.currentPage - halfMaxPagesToShow);
      const endPage = Math.min(this.totalPages, this.currentPage + halfMaxPagesToShow);

      if (startPage > 1) {
        pageNumbers.push(1, '...');
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      if (endPage < this.totalPages) {
        pageNumbers.push('...', this.totalPages);
      }
    }

    return pageNumbers;
  }

  updatePageNumbers(pageNumber: number): void {
    if (pageNumber >= 1 && pageNumber <= this.totalPages) {
      this.pageNumbers = this.getPageNumbers();
    }
  }
}
