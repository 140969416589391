export function dateTimeComparator(filterLocalDateAtMidnight: Date, cellValue: string) {
  // Input date format: yyyy-mm-ddTHH:MM:SS.sssZ

  if (!cellValue) return -1;
  const cellDate = new Date(cellValue);
  const cellDateOnly = new Date(cellDate.getFullYear(), cellDate.getMonth(), cellDate.getDate());

  if (cellDateOnly < filterLocalDateAtMidnight) {
    return -1;
  } else if (cellDateOnly > filterLocalDateAtMidnight) {
    return 1;
  } else {
    return 0;
  }
}
