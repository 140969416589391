import { Component, Input, OnInit, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DatepickerHelper } from '../../../custom-form-validators/date-picker-form-validators';
import { setControlDisabled } from '../../../functions/set-control-disabled';
import { Address } from '../../../models/address';
import { Document } from '../../../models/document';
import { EntityChangeData } from '../../../models/entityChangeData';
import { CompanyChangeOfficerType } from '../../../models/enums/companyChangeOfficerType';
import { RelationshipType, RelationshipTypeLabelsPlural } from '../../../models/enums/relationshipTypeEnum';
import { IndividualData } from '../../../models/individualData';
import { OfficerAppointmentType } from '../../../models/officerAppointmentForm';
import { OfficerRelationshipDetails, Relationship } from '../../../models/relationship';
import { SelectOption } from '../../../models/selectOptions';
import { CompanyChangeOfficer } from '../../../models/сompanyChangeOfficer';
import { autocompleteServiceToken } from '../../../services/autocomplete.service';
import { AuxiliaryService } from '../../../services/auxiliary.service';
import { Guid } from '../../helpers/guid.helper';
import { BaseStepperFormComponent } from '../stepper-form/base-stepper-component/base-stepper-form.component';
import {
  IndividualDataFormGroup,
  IndividualDataFormGroupComponent,
} from "../../components/reusable-form-groups/individual-data-form-group/individual-data-form-group.component";
import { StepperFormComponent } from "../stepper-form/stepper-form.component";
import {
  StepperFormDescriptionComponent
} from "../stepper-form/stepper-form-description/stepper-form-description.component";
import { DatePickerComponent } from "../../components/common/date-picker/date-picker.component";
import { YesNoControlComponent } from "../../components/common/yes-no-control-component/yes-no-control.component";
import { AutocompleteComponent } from "../../components/common/autocomplete/autocomplete.component";
import { SelectComponent } from "../../components/common/select/select.component";
import {
  DinMaskedInputComponent
} from "../../components/common/masked-input/specific-masked-inputs/din-masked-input/din-masked-input.component";
import { TextareaComponent } from "../../components/common/textarea/textarea.component";
import { parseFullName } from "../../../functions/parse-fullname";
import {
  addressIncludesPoBox,
  poBoxAddressCustomErrors
} from "../documents/asic-forms/484-forms/a1-company-address-change/CompanyChangeAddress.model";
import { CountriesService } from "../../../services/countries.service";
import { ValidationErrorComponent } from "../../components/common/validation-error/validation-error.component";
import { CheckboxComponent } from "../../components/common/checkbox/checkbox.component";
import { ParsedName } from "../../../models/parsedName";

export enum OfficerAppointmentStepsEnum {
  FormDescription = 0,
  AppointOfficer = 1,
}

export const positionTypesOptions: SelectOption[] = [
  { label: 'Public Officer', value: OfficerAppointmentType.PublicOfficer },
  { label: 'Director', value: OfficerAppointmentType.Director },
  { label: 'Secretary', value: OfficerAppointmentType.Secretary },
  { label: 'Alternate Director', value: OfficerAppointmentType.AlternativeDirector }
];

export interface OfficerAppointmentControls {
  changeDate: FormControl<Date | null>;
  isNewOfficeholder: FormControl<boolean | null>;
  appointedOfficerId: FormControl<string | null>;
  type: FormControl<RelationshipType | OfficerAppointmentType | null>;
  din: FormControl<string | null>;
  alternativeDirectorFor: FormControl<string | null>;
  expiryDate: FormControl<Date | null>;
  termsOfAppointment: FormControl<string | null>;
  newOfficeholder: IndividualDataFormGroup;
}

export type OfficerAppointmentFormGroup = FormGroup<OfficerAppointmentControls>;

@Component({
  selector: 'app-officer-appointment',
  standalone: true,
  templateUrl: './officer-appointment.component.html',
  styleUrl: '../stepper-form/base-stepper-component/base-stepper-form.component.scss',
  providers: [{ provide: autocompleteServiceToken, useClass: AuxiliaryService }],
  imports: [
    ReactiveFormsModule,
    StepperFormComponent,
    StepperFormDescriptionComponent,
    SelectComponent,
    TextareaComponent,
    AutocompleteComponent,
    DatePickerComponent,
    YesNoControlComponent,
    DinMaskedInputComponent,
    IndividualDataFormGroupComponent,
    ValidationErrorComponent,
    CheckboxComponent,
  ]
})
export class OfficerAppointmentComponent extends BaseStepperFormComponent<OfficerAppointmentStepsEnum, CompanyChangeOfficer> implements OnInit {
  @Input() officers: Record<string, Relationship[]> = {};
  @Input() officeholders: Relationship[] = [];
  @Input() officersSelectOptions: SelectOption[] = [];
  @Input() allDirectorsOptions: SelectOption[] = [];
  @Input() moreThenOneDirectorExist = false;

  override readonly StepsEnum = OfficerAppointmentStepsEnum;
  readonly OfficerAppointmentType = OfficerAppointmentType;
  readonly addressesCustomErrors = poBoxAddressCustomErrors;

  positionTypesOptions = positionTypesOptions;

  form: OfficerAppointmentFormGroup = new FormGroup<OfficerAppointmentControls>({
    changeDate: new FormControl<Date | null>(null),
    isNewOfficeholder: new FormControl<boolean | null>(null, [Validators.required]),
    appointedOfficerId: new FormControl<string | null>(null, [Validators.required]),
    type: new FormControl<RelationshipType | null>(null, [Validators.required]),
    din: new FormControl<string | null>(null),
    alternativeDirectorFor: new FormControl<string | null>(null, [Validators.required]),
    expiryDate: new FormControl<Date | null>(null),
    termsOfAppointment: new FormControl<string | null>(null, [Validators.required]),
    newOfficeholder: IndividualDataFormGroupComponent.defineForm()
  });
  override stepperForm = new FormGroup({
    [OfficerAppointmentStepsEnum.FormDescription]: new FormGroup({}),
    [OfficerAppointmentStepsEnum.AppointOfficer]: this.form,
  });

  isNewOfficeholderSignal = signal<boolean | null>(null);
  selectedPositionSignal = signal<OfficerAppointmentType | null>(null);
  allowedRelationshipTypesSignal: WritableSignal<SelectOption[]> = signal([]);
  allowedDirectorsSignal: WritableSignal<SelectOption[]> = signal([]);

  minDate!: NgbDateStruct;
  maxDate!: NgbDateStruct;

  constructor() {
    super();

    this.setupSteps(OfficerAppointmentStepsEnum);
  }

  ngOnInit(): void {
    this.minDate = DatepickerHelper.getDateOfEstablishmentMinDate(this.companyChangeData.dateOfEstablishment);
    this.maxDate = DatepickerHelper.getNextNYearsStruct(10);
    this.formModel.actionType = CompanyChangeOfficerType.Appointment;
    this.form.controls.changeDate.setValue(DatepickerHelper.getToday());

    this.listenIsNewOfficerChanges();
    this.listenPositionChanges();
    this.listenAppointedOfficerChanges();

    this.addOfficeholderAddressPoBoxValidator();

    this.setupChange();
  }

  override afterSubmit(changes: EntityChangeData[]) {
    this.setupChange(changes[0] as CompanyChangeOfficer);
  }

  override setupChange(change: CompanyChangeOfficer = this.formModel): void {
    const appointedOfficerId = this.officersSelectOptions
      .find(of => of.label === change.offices[0]?.individualDataOverride?.fullName)?.value as string ?? null;
    this.form.controls.appointedOfficerId.setValue(appointedOfficerId ?? null);
    if (appointedOfficerId) {
      this.form.controls.isNewOfficeholder.setValue(!appointedOfficerId);
    }

    if (!this.isEdit && change === this.formModel)
      return;

    const allCompanyRelationship = Object.values(this.officers).flat();
    const isNewOfficeholder = !appointedOfficerId;
    const din = change.offices
      .find(relationship =>
        (relationship.type === RelationshipType.Director
          || relationship.type === RelationshipType.AlternativeDirector)
        && relationship.individualDataOverride?.din)?.individualDataOverride?.din ?? null;
    const selectedAlternativeDirectorDetails = (change.offices.find(relationship => relationship.type === RelationshipType.AlternativeDirector)
      ?.details as OfficerRelationshipDetails | undefined) ?? null;
    const alternativeDirectorForId = (selectedAlternativeDirectorDetails)?.alternativeDirectorFor ?? null;
    const alternativeDirectorFor = allCompanyRelationship.find(relationship => relationship.relationshipId === alternativeDirectorForId) ?? null;
    const alternativeDirectorForKey = alternativeDirectorFor?.key;

    this.form.patchValue({
      ...change,
      isNewOfficeholder,
      din,
      type: change.offices[0]?.type ?? null,
      alternativeDirectorFor: alternativeDirectorForKey,
      termsOfAppointment: selectedAlternativeDirectorDetails?.termsOfAppointment ?? null,
      expiryDate: selectedAlternativeDirectorDetails?.expiryDate ?? null,
    });
  }

  override buildDocument(): Document | null {
    const formValue = this.form.value;
    const change = new CompanyChangeOfficer({ changeDate: formValue.changeDate! });

    if (formValue.isNewOfficeholder === true) {
      // fill changes for new officer
      const names = parseFullName(formValue.newOfficeholder?.fullName ?? '');
      const formerName = parseFullName(formValue.newOfficeholder?.formerFullName ?? '') as ParsedName;
      const newOfficerIndividualData = new IndividualData({
        ...names,
        formerName,
        dob: formValue.newOfficeholder!.dob!,
        address: new Address({
          ...formValue.newOfficeholder?.address,
          normalizedFullAddress: formValue.newOfficeholder?.address?.normalizedFullAddress,
        } as Address),
        birthCity: formValue.newOfficeholder?.birthCity ?? '',
        birthCountry: CountriesService.getCountryName(formValue.newOfficeholder?.birthCountry ?? ''),
      });

      const newOfficer = new Relationship({
        relationshipId: Guid.generate(),
        start: formValue.changeDate!,
        entityId: this.companyChangeData.entityId,
        organisationId: this.companyChangeData.organisationId,
        individualDataOverride: newOfficerIndividualData,
        type: formValue.type as RelationshipType,
      });

      change.offices.push(newOfficer);
    } else if (formValue.isNewOfficeholder === false) {
      // fill changes for existing officer

      const existingOfficer = new Relationship(this.officers[formValue.appointedOfficerId!]?.[0] ?? {});
      existingOfficer.relationshipId = Guid.generate();
      existingOfficer.type = formValue.type as RelationshipType;
      existingOfficer.start = formValue.changeDate!;
      existingOfficer.end = undefined;

      change.offices.push(existingOfficer);
    }

    change.offices = change.offices.map(relationship => {
      if (formValue.type && formValue.type !== OfficerAppointmentType.Secretary) {
        relationship.individualDataOverride!.din = formValue.din ?? '';
      }
      if (formValue.type === OfficerAppointmentType.AlternativeDirector && formValue.alternativeDirectorFor) {
        const alternativeDirectorFor = this.officers[formValue.alternativeDirectorFor]
          .find(director => director.type == RelationshipType.Director)?.relationshipId ?? '';
        relationship.details = new OfficerRelationshipDetails({
          isAlternativeDirector: true,
          alternativeDirectorFor: alternativeDirectorFor,
          termsOfAppointment: formValue.termsOfAppointment ?? undefined,
          expiryDate: formValue.expiryDate ?? undefined
        });
      }

      return relationship;
    });

    return new Document({
      changes: [change],
      entityId: this.companyChangeData.entityId,
      type: 'c:484',
      documentId: this.companyChangeData?.documentId,
    });
  }

  private listenIsNewOfficerChanges(): void {
    this.form.controls.isNewOfficeholder.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isNewOfficeholder) => {
        setControlDisabled(this.form.controls.appointedOfficerId, isNewOfficeholder === true);
        setControlDisabled(this.form.controls.din, isNewOfficeholder === true);
        setControlDisabled(this.form.controls.alternativeDirectorFor, isNewOfficeholder === true);
        setControlDisabled(this.form.controls.newOfficeholder, isNewOfficeholder !== true);

        if (isNewOfficeholder === true) {
          this.positionTypesOptions = positionTypesOptions;
          this.allowedDirectorsSignal.set(this.allDirectorsOptions.filter(director => director.value !== this.form.value.appointedOfficerId));
        } else if (isNewOfficeholder === false) {
          this.allowedDirectorsSignal.set([]);
        }

        this.form.controls.type.patchValue(this.form.value.type ?? null);
        if (!this.form.value.isNewOfficeholder) {
          this.form.controls.appointedOfficerId.patchValue(this.form.value.appointedOfficerId ?? null);
        }
        this.isNewOfficeholderSignal.set(isNewOfficeholder);
      });
  }

  listenPositionChanges(): void {
    this.form.controls.type.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((type) => {
        setControlDisabled(this.form.controls.alternativeDirectorFor, type !== OfficerAppointmentType.AlternativeDirector);
        setControlDisabled(this.form.controls.termsOfAppointment, type !== OfficerAppointmentType.AlternativeDirector);
        setControlDisabled(this.form.controls.din, type === null || type === OfficerAppointmentType.Secretary);
        this.selectedPositionSignal.set(type as OfficerAppointmentType ?? null);

        this.form.updateValueAndValidity();
      });
  }

  listenAppointedOfficerChanges(): void {
    this.form.controls.appointedOfficerId.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((appointedOfficerId) => {
          if (!appointedOfficerId) {
            this.selectedPositionSignal.set(null);
            this.allowedRelationshipTypesSignal.set([]);
          } else {
            const selectedOfficerRelationships = this.officers[appointedOfficerId];
            const allowedTypes = positionTypesOptions
              .filter((option) =>
                !selectedOfficerRelationships.find((officer) => officer.type === option.value)
                || (this.isEdit && this.formModel.offices.find((office) => office.type === option.value))
              );

            this.allowedRelationshipTypesSignal.set(allowedTypes);
            this.allowedDirectorsSignal.set(this.allDirectorsOptions.filter((director) => director.value !== appointedOfficerId));

            if (this.form.controls.alternativeDirectorFor.value === appointedOfficerId) {
              this.form.controls.alternativeDirectorFor.patchValue(null);
            }
          }

          this.form.controls.type.patchValue(this.form.value.type ?? null);
        }
      );
  }

  private addOfficeholderAddressPoBoxValidator(): void {
    const getOfficeholdersByType = (officeholders: Relationship[], type: RelationshipType) => officeholders.filter((office) => office.type === type);
    const officersIncludeAtLeastOneNotPoBoxAuAddress = (officeholders: Relationship[]) => officeholders
      .some((officeholder) => {
        return officeholder.individualDataOverride
          && !addressIncludesPoBox(officeholder.individualDataOverride.address.normalizedFullAddress)
          && CountriesService.isAustralianStateOrAustralia(officeholder.individualDataOverride.address.country);
      });

    const officeholderAddressPoBoxValidator = (control: AbstractControl): ValidationErrors | null => {
      const form = control as OfficerAppointmentFormGroup;
      const isNewOfficeholder = form.controls.isNewOfficeholder.value;
      const type = form.controls.type.value;

      if (!isNewOfficeholder || type === null) {
        return null;
      }

      const isPoBoxType = addressIncludesPoBox(form.controls.newOfficeholder.controls.address.controls.normalizedFullAddress.value ?? '');

      if (!isPoBoxType) {
        return null;
      }

      const officeholdersWithSameType = getOfficeholdersByType(this.officeholders, type as RelationshipType);

      return officersIncludeAtLeastOneNotPoBoxAuAddress(officeholdersWithSameType)
        ? null
        : { poBoxAddressOfficeholder: RelationshipTypeLabelsPlural[type] };
    };

    this.form.addValidators(officeholderAddressPoBoxValidator);
  }

  get minExpiryDate(): NgbDateStruct {
    return DatepickerHelper.getStructFromDateOrNull(this.form.controls.changeDate.value) ?? this.minDate;
  }
}
