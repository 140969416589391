import { AbstractControl, ValidationErrors } from '@angular/forms';

export class NumbersValidators {
  static numbersValidatorErrors = {
    isNaN: 'Value must be a number',
    isPositive: 'Value must be a positive number',
    percentRange: 'Value must be between 0 and 100',
    max: 'Value must be less than or equal to 100000',
    min: 'Value must be greater than or equal to 0',
    strictMin: 'Value must be greater than 0',
    strictMax: 'Value must be less than 0',
    isInteger: 'Please enter a valid integer value.',
  };

  static isNumber(control: AbstractControl): ValidationErrors | null {
    return isNaN(control.value) ? { isNaN: true } : null;
  }

  static isInteger(control: AbstractControl): ValidationErrors | null {
    const forbiddenСharacters = [',', '.'];
    return forbiddenСharacters.some(char => control.value?.toString()?.includes(char)) || control.value % 1 !== 0 ? { isInteger: true } : null;
  }

  static isBetweenValidator(min: number, max: number): (control: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl) => control.value < min || control.value > max ? { isBetween: true } : null;
  }

  static percentRangeValidator(control: AbstractControl): ValidationErrors | null {
    return control.value < 0 || control.value > 100 ? { percentRange: true } : null;
  }

  static min(min: number): (control: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl) => control.value < min ? { min } : null;
  }

  static strictMin(min: number): (control: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl) => control.value <= min ? { strictMin: min } : null;
  }

  static max(max: number): (control: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl) => control.value > max ? { max } : null;
  }

  static strictMax(max: number): (control: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl) => control.value >= max ? { strictMax: max } : null;
  }

  static relativeMax(maxFn: () => number): (control: AbstractControl) => ValidationErrors | null {
    const max = maxFn();
    return (control: AbstractControl) => control.value > max ? { max } : null;
  }
}
