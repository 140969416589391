import { CurrencyPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { booleanAttribute, Component, EventEmitter, Input, Optional, Output } from '@angular/core';
import { NgControl, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { SecurityStructure } from '../../../../models/enums/securityStructure';
import { SecurityHolding } from '../../../../models/securityHolding';
import { DividerComponent } from '../../common/divider/divider.component';
import {
  ShareClassTypeLabelComponent
} from '../../common/grid/components/ag-share-class-type/share-class-type-label/share-class-type-label.component';
import { LinkComponent } from '../../common/link/link.component';
import { ValidationErrorComponent } from '../../common/validation-error/validation-error.component';

@Component({
  selector: 'app-share-security-holdings-dropdown',
  standalone: true,
  imports: [
    LinkComponent,
    ReactiveFormsModule,
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
    NgClass,
    ValidationErrorComponent,
    DividerComponent,
    NgTemplateOutlet,
    CurrencyPipe,
    ShareClassTypeLabelComponent,
  ],
  templateUrl: 'share-security-holdings-dropdown.component.html',
  styleUrl: 'share-security-holdings-dropdown.component.scss'
})
export class ShareSecurityHoldingsDropdownComponent {
  @Input() width = '100%';
  @Input() placeholder = 'Select Share Type';
  @Input() label = 'Share Type';
  @Input() link = '';
  @Input() text = '';
  @Input() helperText = '';
  @Input() noOptionsText = 'There are no available shares.';
  @Input({ required: true }) options: SecurityHolding[] = [];
  @Input() shareClasses: SecurityStructure[] = [];
  @Input({ transform: booleanAttribute }) disabled = false;
  @Input({ transform: booleanAttribute }) hidePrice = false;

  @Output() select = new EventEmitter<SecurityHolding>();
  @Output() addShareType = new EventEmitter<SecurityHolding>();

  #value: SecurityHolding | null = null;
  dropdownOpen = false;

  constructor(@Optional() protected ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  get value(): SecurityHolding | null {
    return this.#value;
  }

  set value(value: SecurityHolding | null) {
    this.#value = value;
    this.onChange(this.#value?.securityHoldingId ?? null);
  }

  get displayValidationError(): boolean {
    return !!(this.ngControl && this.ngControl.touched && this.ngControl.errors);
  }

  selectOption(selectedOption: SecurityHolding): void {
    this.value = selectedOption;
    this.select.emit(selectedOption);
  }

  onChange: any = (): void => {
  };
  onTouched: any = (): void => {
  };

  writeValue(value: string): void {
    this.value = this.options.find((securityHolding) => securityHolding.securityHoldingId === value) ?? null;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  getDropdownState(dropdownState: boolean): void {
    this.dropdownOpen = dropdownState;
  }
}
