<div class="card email-settings-page settings-page">
  <app-page-title
    header="Email Settings"
    iconClass="ic-settings"
    backButtonPath="settings"
  />

  <div class="card-body">
    <div class="no-templates-message">
      Bolt default email address (<a [href]="'mailto:' + boltEmailAddress">{{ boltEmailAddress }}</a>) will be used for
      sending emails. If you would like to add a custom email, please contact support.
    </div>
  </div>
</div>
