import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  standalone: true,
  name: 'acn'
})
export class ACNPipe implements PipeTransform {
  transform(value?: string): string {
    if(value == null)
      return '';

    let str = value.replaceAll(' ', '').replaceAll('\xa0', '');

    if (/^[0-9]+$/.test(str) && str.length <= 9) {
        str = str.padStart(9, '0');
        return `${str.substring(0, 3)}\xa0${str.substring(3, 6)}\xa0${str.substring(6, 9)}`;
    }

    return '-';
  }
}
