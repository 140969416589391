<div class="address-form" [class.half-width]="halfWidth" [formGroup]="form">
  <div class="form-group" [hidden]="!useInternationalAddresses">
    <app-autocomplete
      formControlName="country"
      [label]="addressFormLabels.country"
      [options]="countryOptions"
      [valuePrimitive]="true"
    ></app-autocomplete>
  </div>

  <div [hidden]="expandedAddressForm">
    <app-address-autocomplete
      class="d-block mt-2"
      placeholder="Type Address"
      formControlName="normalizedFullAddress"
      [label]="label || addressFormLabels.normalizedFullAddress"
      [clearStatusAfter]="5000"
      [isDisabled]="isDisabled"
      [isAustraliaSelected]="isAustraliaSelected()"
      [useInternationalAddresses]="useInternationalAddresses"
      [country]="form.value.country ?? ''"
      [shouldSearchAddresses]="!expandedAddressForm"
      (onSelect)="parseAddress($event)"
      (onTextChange)="textChange($event)"
      (dataStatusChange)="dataStatusChange($event)"
      (switchBtnClick)="switchAddressForm()"
      [(dataStatus)]="addressStatus"
    ></app-address-autocomplete>
  </div>

  <div [hidden]="!expandedAddressForm">
    @if (addressStatus === AddressStatus.WARNING) {
      <div class="warning-message mt-2">
        <i class="icon ic-circle-info"></i>
        <span>Address was not validated and most likely to be rejected</span>
      </div>
    }

    <app-input
      class="d-block mt-2"
      formControlName="street"
      placeholder="Type Address"
      switchBtnText="Find Address"
      [label]="addressFormLabels.street"
      (switchBtnClick)="switchAddressForm()"
      [isDisabled]="isDisabled"
    ></app-input>

    <app-input
      class="d-block mt-2"
      formControlName="line2"
      placeholder="Type level, unit or shop number"
      [label]="addressFormLabels.line2"
      [isDisabled]="isDisabled"
    ></app-input>

    @if (expandedAddressForm && isAustraliaSelected()) {
      <app-suburbs-autocomplete
        class="d-block mt-2"
        formControlName="suburb"
        (selectSuburb)="selectSuburb($event)"
      />
    } @else {
      <app-input
        class="d-block mt-2"
        formControlName="suburb"
        placeholder="Type Suburb"
        [label]="isAustraliaSelected() ? 'Suburb' : 'City or Locality'"
        [isDisabled]="isDisabled"
      ></app-input>
    }

    <div class="mt-2" [class.state-code]="!useInternationalAddresses">
      <app-select
        formControlName="state"
        placeholder="Select State"
        [label]="addressFormLabels.state"
        [options]="stateOptions"
        [valuePrimitive]="true"
        [isDisabled]="isDisabled"
        [hidden]="useInternationalAddresses && !isAustraliaSelected()"
      ></app-select>

      <app-input
        formControlName="postcode"
        placeholder="Type Postcode"
        [label]="addressFormLabels.postcode"
        [isDisabled]="isDisabled"
      ></app-input>
    </div>
  </div>
</div>
