import { Pipe, PipeTransform } from '@angular/core';
import { AsicStatus } from '../../models/enums/asicStatus';

@Pipe({
  standalone: true,
  name: 'asicStatus'
})
export class AsicStatusPipe implements PipeTransform {
  transform(value: AsicStatus | undefined): string {
    switch (value) {
      case AsicStatus.NotLodged:
        return 'Not Lodged';
      case AsicStatus.Pending:
        return 'Pending';
      case AsicStatus.WaitingForASIC:
        return 'Waiting For ASIC';
      case AsicStatus.Validated:
        return 'Validated';
      case AsicStatus.Lodged:
        return 'Lodged';
      case AsicStatus.FutureLodgement:
        return 'Future Lodgement';
      case AsicStatus.Rejected:
        return 'Rejected';
      default:
        return '-';
    }
  }
}
