import { Component, EventEmitter, Input, Optional, Output } from '@angular/core';
import { FormsModule, NgControl } from "@angular/forms";

@Component({
  selector: 'app-toggle-button',
  standalone: true,
  imports: [
    FormsModule
  ],
  templateUrl: './toggle-button.component.html',
  styleUrl: './toggle-button.component.scss'
})
export class ToggleButtonComponent {
  @Input() label = '';
  @Input() switched = false;
  @Input() disabled = false;
  @Output() switchBtn = new EventEmitter<boolean>();

  #value = false;

  constructor(@Optional() protected ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  get value(): boolean {
    return this.#value || this.switched;
  }

  set value(value: boolean) {
    this.#value = value;
    this.onChange(this.#value);
  }

  onChange: any = (): void => {};
  onTouched: any = (): void => {};

  switch(selected: boolean): void {
    this.value = selected;
    this.switchBtn.emit(selected);
  }

  writeValue(value: boolean): void {
    this.value = value;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
