import { EmailPayloadBase } from "../emailPayloadBase";

export class DirectorIdNotificationEmailPayload extends EmailPayloadBase {
  static override $type = 'DirectorIdNotificationEmailPayload';

  username: string;
  directorFirstName: string;
  directorFullName: string;
  companyNames: string[];

  constructor(data: Partial<DirectorIdNotificationEmailPayload> = {}) {
    super(data);
    this.username = data.username ?? '';
    this.directorFirstName = data.directorFirstName ?? '';
    this.directorFullName = data.directorFullName ?? '';
    this.companyNames = data.companyNames ?? [];
  }
}
