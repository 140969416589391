<div class="sidebar-container">
  <div class="section">
    @if(!loadCompany) {
      @if (entity !== null) {
        <div>
          <app-ag-tag-entity-title
            [isLink]="true"
            [entity]="entity"
            [entityType]="entityType"
          ></app-ag-tag-entity-title>
        </div>
      }

      <div class="document-info-grid">
        <div class="info-item">
          <div class="info-item-title">Status</div>
          <div class="status-wrapper">
            <div class="status" [ngClass]="AnnualStatementStatus[annual?.annualStatementStatus ?? 0]">
              {{ AnnualStatementStatus[annual?.annualStatementStatus ?? 0] | splitByCapitalLetterPipe }}
            </div>
          </div>
        </div>

        <div class="info-item">
          <div class="info-item-title">Debt</div>
          <div class="info-item-content">
            {{annual?.paymentAmount ? (annual?.paymentAmount | currency : "$" : 'code' : '1.2-2') : 'N/A'}}
          </div>
        </div>

        <div class="info-item">
          <div class="info-item-title">Review Date</div>
          <div class="info-item-content">{{annual?.reviewDate | date: 'dd MMM yyyy'}}</div>
        </div>

        <div class="info-item">
          <div class="info-item-title">Payment Deadline</div>
          <div class="info-item-content">{{annual?.paymentDeadline ? (annual?.paymentDeadline | date : 'dd MMM yyyy') : 'N/A'}}</div>
        </div>
      </div>

      <div class="tag-container">
        <div class="info-item-title">Tags</div>
        <div class="info-item-tag">
          @for (tag of entity?.tags; track tag) {
            <app-tag [tag]="tag"></app-tag>
          } @empty {
            N/A
          }
        </div>
      </div>
    } @else {
      <div class="skeleton-container">
        <ngx-skeleton-loader count="2" appearance="line" />
        <div class="d-flex gap-12">
          <ngx-skeleton-loader style="width: 50%" count="5" appearance="line" />
          <ngx-skeleton-loader style="width: 50%" count="5" appearance="line" />
        </div>
      </div>
    }
  </div>

  <app-divider></app-divider>

  <div class="section">
    @if(!loading) {
      @if(currentStepIndex === AnnualStep.SignDocuments && (documentsList$ | async)?.final?.length) {
        <div class="documents-container">
          <div class="header">
            <div class="title-wrapper">
              <div><i class="icon ic-documents"></i></div>
              <div class="title">Documents</div>
            </div>

            <div ngbDropdown [placement]="['bottom-end', 'bottom-start', 'top-start', 'top-end']" class="dropdown">
              <app-button
                ngbDropdownToggle
                [btnColor]="'white'"
                [iconClass]="'icon ic-download-file'"
              ></app-button>
              <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
                <div class="dropdown-item" (click)="downloadCombinedPDF(DocumentSubFolderName.Final)">
                  <a class="dropdown-button">
                    <span>Download combined PDF</span>
                  </a>
                </div>
                <div class="dropdown-item" (click)="downloadZIP(DocumentSubFolderName.Final)">
                  <a class="dropdown-button">
                    <span>Download ZIP</span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="document-uploaded-container">
            <div class="uploaded-container">
              <div class="sub-title">Generated</div>
              <div class="uploaded-list">
                @for (finalDocument of (documentsList$ | async)?.final; track finalDocument.name) {
                  <div class="uploaded-row">
                    <div class="name-wrapper">
                      <i class="icon ic-xs ic-file"></i>
                      <div class="file-name" (click)="downloadFile(finalDocument.name, DocumentSubFolderName.Final)">{{finalDocument.name }}</div>
                    </div>
                    <div class="action-wrapper">
                      <i class="icon ic-xs ic-download-file" (click)="downloadFile(finalDocument.name, DocumentSubFolderName.Final)"></i>
                    </div>
                  </div>
                } @empty {
                  <div>No generated documents</div>
                }
              </div>
            </div>
          </div>

          <div class="document-uploaded-container">
            <div class="uploaded-container">
              <div class="sub-title">
                Documents to Attach
                <app-tooltip class="ml-2" [tooltipText]="documentsToAttachInfo"></app-tooltip>
              </div>
              <div class="uploaded-list">
                @for (document of documentsToAttach; track $index) {
                  <div class="document">
                    <app-checkbox
                      [label]="document.documentName"
                      [labelIconClass]="'icon ic-xs ic-file'"
                      (change)="selectAttachedDocument($event, $index)"
                    ></app-checkbox>
                  </div>
                } @empty {
                  <div>No Documents to Attach</div>
                }
              </div>
            </div>
          </div>

          <div class="uploaded-container">
            <div class="sub-title">
              Files to Attach
              <app-tooltip class="ml-2" [tooltipText]="filesToAttachInfo"></app-tooltip>
            </div>
            <div class="uploaded-list">
              @for (uploadedDocument of (documentsList$ | async)?.attached; track uploadedDocument.name) {
                <div class="uploaded-row uploaded-name">
                  <div class="name-wrapper">
                    <div><i class="icon ic-xs ic-file mt-0"></i></div>
                    <div class="file-name">{{ uploadedDocument.name }}</div>
                  </div>
                  <div class="action-wrapper">
                    <div><i class="icon ic-xs ic-download-file" (click)="downloadFile(uploadedDocument.name, DocumentSubFolderName.FileAttached)"></i></div>
                    <div><i class="icon ic-xs ic-close" (click)="deleteFile(uploadedDocument.name)"></i></div>
                  </div>
                </div>
              } @empty {
                <div>No uploaded files</div>
              }
            </div>

            <app-button
              class="upload-file mt-2"
              [text]="'Upload File(s)'"
              (clickBtn)="openUploadFileModal(DocumentSubFolderName.FileAttached)"
            ></app-button>
          </div>
        </div>
      }
    } @else {
      @if(currentStepIndex) {
        <div class="skeleton-container">
          <ngx-skeleton-loader count="6" appearance="line" />
        </div>
      }
    }
  </div>
</div>
