import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DatepickerHelper } from '../../../../../custom-form-validators/date-picker-form-validators';
import { Company } from '../../../../../models/company';
import { Document } from '../../../../../models/document';
import { EntityChangeData } from '../../../../../models/entityChangeData';
import { CompanyChangeData } from '../../../../../models/enums/companyChangeData';
import { RelationshipType } from '../../../../../models/enums/relationshipTypeEnum';
import { IndividualData } from '../../../../../models/individualData';
import { OfficerAppointmentType } from '../../../../../models/officerAppointmentForm';
import { Relationship } from '../../../../../models/relationship';
import { SelectOption } from '../../../../../models/selectOptions';
import { ChangeDictionaryHelper } from '../../../../../models/shared/change-dictionary-helper.model';
import {
  Form370OfficeholderResignationOrRetirementComponent
} from './form370-officeholder-resignation-or-retirement.component';
import { IPDFSection } from "../../../../../models/shared/pdf-section.interface";

export class CompanyChangeOfficerResignation extends EntityChangeData {
  static override readonly $type = 'CompanyChangeOfficerResignation';
  relationships: Relationship[];
  resignationLetterText: string;
  resignationLetterGiven: Date;
  isFormerOfficerResignation: boolean;
  officerDataIfFormerOfficer: IndividualData;
  relationshipTypesIfFormerOfficer: (RelationshipType | OfficerAppointmentType)[];

  constructor(data: Partial<CompanyChangeOfficerResignation> = {}) {
    super(data);

    this.relationships = data.relationships ?? [];
    this.resignationLetterText = data.resignationLetterText ?? '';
    this.resignationLetterGiven = DatepickerHelper.toDate(data.resignationLetterGiven);
    this.isFormerOfficerResignation = data.isFormerOfficerResignation ?? false;
    this.officerDataIfFormerOfficer = data.officerDataIfFormerOfficer ?? new IndividualData();
    this.relationshipTypesIfFormerOfficer = data.relationshipTypesIfFormerOfficer ?? [];
  }

  override toDictionary(): { key: string; value: string; }[] {
    const dict = new ChangeDictionaryHelper();
    const fullName = this.relationships[0]?.individualDataOverride?.fullName ?? this.officerDataIfFormerOfficer?.fullName ?? '';
    const positions = !this.isFormerOfficerResignation
      ? this.relationships.map((relationship) => OfficerAppointmentType[relationship.type]).join(', ')
      : this.relationshipTypesIfFormerOfficer.map((type) => RelationshipType[type]).join(', ');

    dict
      .addNoYes('Existing Officer', this.isFormerOfficerResignation)
      .add('Resigning Officer', fullName)
      .add('Resignation Letter Text', this.resignationLetterText)
      .addDate('Resignation Letter Given', this.resignationLetterGiven)
      // .addYesNo('Is Resigning Officer a Former Officer?', this.isFormerOfficerResignation)
      .add('Positions', positions)
      .addDate('Date of Resignation', this.changeDate)

    return dict.dictionary;
  }

  override prepareToPdf(): IPDFSection[] {
    const sectionDict = new ChangeDictionaryHelper();
    const fullName = this.relationships[0]?.individualDataOverride?.fullName ?? this.officerDataIfFormerOfficer?.fullName ?? '';
    const positions = !this.isFormerOfficerResignation
      ? this.relationships.map((relationship) => OfficerAppointmentType[relationship.type]).join(', ')
      : this.relationshipTypesIfFormerOfficer.map((type) => RelationshipType[type]).join(', ');
    const placeOfBirth = this.officerDataIfFormerOfficer?.birthCity && this.officerDataIfFormerOfficer?.birthCountry
      ? this.officerDataIfFormerOfficer?.birthCity + ', ' + this.officerDataIfFormerOfficer?.birthCountry
      : this.officerDataIfFormerOfficer?.birthCity || this.officerDataIfFormerOfficer?.birthCountry;

    sectionDict
      .addSection('Resignation Details')
      .addDate('Date of Resignation', this.changeDate)
      .addDate('Date the letter handed in', this.resignationLetterGiven);

    if(!this.isFormerOfficerResignation) {
      sectionDict
        .addSection('Resigning Officer Details')
        .addNoYes('Existing Officer', this.isFormerOfficerResignation)
        .add('Resigning Officer', fullName)
        .add('Position', positions)
        .add('Resignation letter', this.resignationLetterText);
    } else {
      sectionDict
        .addSection('Resigning Officer Details')
        .addNoYes('Existing Officer', this.isFormerOfficerResignation)
        .add('Resigning Officer', fullName)
        .add('Former Name', this.officerDataIfFormerOfficer?.formerName ?? '')
        .addDate('Date of Birth', this.officerDataIfFormerOfficer?.dob ?? '')
        .add('Place of Birth', placeOfBirth)
        .add('Address', this.officerDataIfFormerOfficer?.address?.normalizedFullAddress)
        .add('Position', positions)
        .add('Resignation letter', this.resignationLetterText);
    }

    return sectionDict.sections;
  }

  override fillModal(modalRef: NgbModalRef, document: Document, actualCompany: Company): NgbModalRef {
    modalRef = this.fillModalWithCompany(modalRef, actualCompany, true);
    const instance = (modalRef.componentInstance as Form370OfficeholderResignationOrRetirementComponent);
    instance.companyChangeData.documentId = document.documentId;

    instance.formModel.relationships = instance.formModel.relationships.map(officer => {
      officer.key = officer.individualId ?? instance.officersSelectOptions
        .find(option => option.label === officer.individualDataOverride!.fullName)?.value as string ?? '';
      return officer;
    });

    return modalRef;
  }

  fillModalWithCompany(modalRef: NgbModalRef, company: Company, isEdit: boolean): NgbModalRef {
    const instance = (modalRef.componentInstance as Form370OfficeholderResignationOrRetirementComponent);

    instance.isEdit = isEdit;
    instance.companyChangeData = new CompanyChangeData(company);
    instance.formModel = structuredClone(this);

    // TODO: remove after individualId works fine
    const officerIndividualsIdDictionary: Record<string, string> = {};

    instance.officersSelectOptions = structuredClone(company.officers)
      .reduce((officersSelectOptions: SelectOption[], officer) => {
          // TODO: remove after individualId works fine
          if (!(officer.individualDataOverride!.fullName in officerIndividualsIdDictionary)) {
            officerIndividualsIdDictionary[officer.individualDataOverride!.fullName] = Math.random().toString(36).substring(7);
          }

          // TODO: remove after individualId works fine
          officer.key = officer.individualId ?? officerIndividualsIdDictionary[officer.individualDataOverride!.fullName];

          const officerOption: SelectOption = {
            label: officer.individualDataOverride!.fullName,
            value: officer.key,
          };

          if (!(officer.key in instance.officers)) {
            instance.officers[officer.key] = [];

            officersSelectOptions.push(officerOption);
          }

          instance.officers[officer.key].push(officer);

          return officersSelectOptions;
        },
        []
      );

    instance.moreThenOneDirectorExist = company.officers.slice(0, 1)
      .reduce((sum: number, officeholder: Relationship) => {
        if (officeholder.type === RelationshipType.Director) sum++;
        return sum;
      }, 0) > 1;

    return modalRef;
  }

  override prepareToRequest(): CompanyChangeOfficerResignation {
    return {
      ...this,
      ...super.prepareToRequest(),
      resignationLetterGiven: DatepickerHelper.buildDateString(this.resignationLetterGiven),
      relationships: this.relationships.map(relationship => Relationship.prepareToRequest(relationship)),
      officerDataIfFormerOfficer: IndividualData.prepareToRequest(this.officerDataIfFormerOfficer)
    };
  }
}
