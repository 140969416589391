import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Company } from '../../../../../models/company';
import { Document } from '../../../../../models/document';
import { EntityChangeData } from '../../../../../models/entityChangeData';
import { CompanyChangeData } from '../../../../../models/enums/companyChangeData';
import { ChangeDictionaryHelper } from '../../../../../models/shared/change-dictionary-helper.model';
import { PaymentMethod, paymentMethodsNames } from '../../../company-name-change/CompanyChangeName';
import { Form410BComponent } from './form410b.component';

export enum Form410BStepsEnum {
  FormDetails = 0,
  CompanyNameDetails = 1,
  ManualProcessing = 2
}

export class Form410BModel extends EntityChangeData {

  static override readonly $type = 'Form410BTransaction';
  proposedCompanyName = '';
  isProposedNameIdenticalToRegisteredBusinessName = false;
  businessNameHolderAbn = '';
  manualProcessing = false;
  manualProcessingReason = '';
  paymentMethod: PaymentMethod;

  constructor(data: Partial<Form410BModel> = {}) {
    super(data);

    this.changeType = 'c:410b'

    this.proposedCompanyName = data.proposedCompanyName ?? '';
    this.isProposedNameIdenticalToRegisteredBusinessName = data.isProposedNameIdenticalToRegisteredBusinessName ?? false;
    this.businessNameHolderAbn = data.businessNameHolderAbn ?? '';
    this.manualProcessing = data.manualProcessing ?? false;
    this.manualProcessingReason = data.manualProcessingReason ?? '';
    this.paymentMethod = data.paymentMethod ?? PaymentMethod.INV;
  }

  override toDictionary(): { key: string; value: string; }[] {
    const dict = new ChangeDictionaryHelper();

    dict
      .add('Proposed new company name', this.proposedCompanyName)
      .addYesNo('Is proposed name identical to a registered business name?', this.isProposedNameIdenticalToRegisteredBusinessName);

    if (this.isProposedNameIdenticalToRegisteredBusinessName && this.businessNameHolderAbn)
      dict.addAbn('ABN', this.businessNameHolderAbn);

    if (this.isProposedNameIdenticalToRegisteredBusinessName && this.businessNameHolderAbn) {
      dict.addYesNo('Manual Processing', this.manualProcessing);

      if (this.manualProcessing && this.manualProcessingReason)
        dict.add('Manual Processing Reason', this.manualProcessingReason);
    }
    dict.add('Payment Method', paymentMethodsNames[this.paymentMethod]);

    return dict.dictionary;
  }

  fillModal(modalRef: NgbModalRef, document: Document, actualCompany: Company): NgbModalRef {
    modalRef = this.fillModalWithCompany(modalRef, actualCompany, true);
    (modalRef.componentInstance as Form410BComponent).companyChangeData.documentId = document.documentId;
    return modalRef;
  }

  // Assuming you have a method for applying the change to the Company entity
  fillModalWithCompany(modalRef: NgbModalRef, company: Company, isEdit = false): NgbModalRef {
    const instance = (modalRef.componentInstance as Form410BComponent);

    instance.isEdit = isEdit;
    instance.companyChangeData = new CompanyChangeData(company);
    instance.formModel = structuredClone(this);

    return modalRef;
  }
}
