import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { EntityType } from '../../../../../../models/enums/entityType';
import { ABNPipe } from '../../../../../../pipes/abnPipe';
import { ACNPipe } from '../../../../../../pipes/acnPipe';
import { ITagsEntity } from '../../../../../settings/components/tags/manage-tags-modal/manage-tags-modal.component';
import {
  EntityNameNumberRecordComponent
} from '../../../entity-name-number-record/entity-name-number-record.component';

@Component({
  selector: 'app-ag-tag-entity-title',
  standalone: true,
  imports: [
    EntityNameNumberRecordComponent,
    ACNPipe,
    ABNPipe,
  ],
  templateUrl: './ag-tag-entity-title.component.html',
  styleUrl: './ag-tag-entity-title.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgTagEntityTitleComponent implements ICellRendererAngularComp {
  @Input() entity: ITagsEntity | undefined;
  @Input() entityType: EntityType = EntityType.Company;
  @Input() isLink = false;
  @Input() fullWidth = false;

  readonly EntityType = EntityType;

  agInit(params: ICellRendererParams<any>): void {
    this.entity = params.data;
    this.entityType = params['entityType'] ?? EntityType.Company;
  }

  refresh(params: ICellRendererParams<any>): boolean {
    this.entity = params.data;
    this.entityType = params['entityType'] ?? EntityType.Company;
    return true;
  }

  get link(): string {
    if (!this.isLink) {
      return '';
    }

    switch (this.entityType) {
      case EntityType.Company:
        return `/company-profile/${ this.entity?.['entityId'] }`;
      case EntityType.Trust:
        return `/trust-profile/${ this.entity?.['entityId'] }`;
      case EntityType.Individual:
        return `/individual/${ this.entity?.['individualId'] }`;
      default:
        return '';
    }
  }
}
