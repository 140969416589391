<div class="card settings-page">
  <app-page-title
    header="Users and Permissions"
    iconClass="ic-accounts"
    backButtonPath="settings"
  >
    <app-button
      [text]="'Add User'"
      [iconClass]="'icon ic-xs ic-plus'"
      (clickBtn)="openCreateEditUserModal()"
    ></app-button>
  </app-page-title>

  <app-grid
    pageTitle="users"
    [rowData]="users"
    [colDefs]="$any(colDefs)"
    [loading]="loading"
    (itemSelect)="selectUsers($event)"
    (gridReady)="onGridReady($event)"
  ></app-grid>
</div>
