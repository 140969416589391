import { Component, inject, OnInit } from '@angular/core';
import { OrganisationService } from '../../../../services/organisation.service';
import { combineLatest, finalize } from 'rxjs';
import { PageTitleComponent } from "../../../components/common/page-title/page-title.component";
import { AppCopyDirective } from "../../../../directives/copy.directive";
import { DefaulValuePipe } from "../../../../pipes/enumsPipes/defaultValuePipe";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  EditOrganisationProfileModalComponent
} from "./components/edit-organisation-profile-modal/edit-organisation-profile-modal.component";
import { ButtonComponent } from "../../../components/common/button/button.component";
import { LoaderStandaloneComponent } from "../../../components/common/loader-standalone/loader-standalone.component";
import { OrganisationModel } from "../../../../models/organisationModel";
import { PhonePipe } from "../../../../pipes/phonePipe";
import { AuthService } from "../../../../services/auth.service";

@Component({
  selector: 'app-organisation-details-page',
  standalone: true,
  templateUrl: './organisation-details-page.component.html',
  styleUrls: ['./organisation-details-page.component.scss', '../../settings-styles.scss'],
  imports: [
    PageTitleComponent,
    AppCopyDirective,
    DefaulValuePipe,
    ButtonComponent,
    LoaderStandaloneComponent,
    PhonePipe,
  ],
})
export class OrganisationDetailsPageComponent implements OnInit {
  private organisationService = inject(OrganisationService);
  private authService = inject(AuthService);
  private modalService = inject(NgbModal);

  organisation!: OrganisationModel;
  isLoading = true;
  editOrganisationModalOpened = true;
  primaryContactLabel: string | null = null;
  billingContactLabel: string | null = null;

  openEditOrganisationModal(): void {
    if (this.editOrganisationModalOpened || this.isLoading) return;
    this.editOrganisationModalOpened = true;
    const modalRef = this.modalService.open(EditOrganisationProfileModalComponent, { size: 'lg' });
    const instance = modalRef.componentInstance as EditOrganisationProfileModalComponent;
    instance.organisation = structuredClone(this.organisation);
    this.editOrganisationModalOpened = true;

    modalRef.result.then(
      () => {
        this.authService.updateUserProfile().subscribe();
        this.loadOrganisation();
      },
      () => {}
    ).finally(() => this.editOrganisationModalOpened = false);
  }

  ngOnInit(): void {
    this.loadOrganisation();
  }

  loadOrganisation() {
    this.isLoading = true;
    combineLatest([
      this.organisationService.getOrganisationProfile(),
      this.organisationService.getOrganisation()
    ])
      .pipe(finalize(() => {
        this.isLoading = false;
        if (this.organisationSetupRequired)
          this.openEditOrganisationModal();
      }))
      .subscribe(([profile, organisation]) => {
        this.organisation = organisation;
        this.billingContactLabel = organisation.billingContactId
          ? profile.users?.find(u => u.id === organisation.billingContactId)?.fullName ?? null
          : null;
        this.primaryContactLabel = organisation.primaryContactId
          ? profile.users?.find(u => u.id === organisation.primaryContactId)?.fullName ?? null
          : null;

        this.editOrganisationModalOpened = false;
      });
  }

  get organisationSetupRequired(): boolean {
    return this.authService.currentUserProfile()?.organisationSetupRequired ?? false;
  }
}
