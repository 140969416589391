<div
  class="status-tag"
  [ngClass]="{
    'active': TrustStatus.Active === status,
    'archived': TrustStatus.Archived === status,
    'vested': TrustStatus.Vested === status,
  }"
>
  <span>{{ status | trustStatus }}</span>
</div>
