import { EmailPayloadBase } from "../emailPayloadBase";

export class AnnualStatementSignReminderEmailPayload extends EmailPayloadBase {
  static override $type = 'AnnualStatementSignReminderEmailPayload';

  companyName: string;
  companyACN: string;
  billingContactFirstName: string;
  billingContactFullName: string;
  directorFirstName: string;
  directorFullName: string;
  username: string;
  partner: string;
  asicContact: string;
  accountManager: string;
  annualReviewFeeDeadline: string;
  annualReviewFeeDeadlineShortFormat: string;
  debt: string;
  lateFeeForOneMonth: string;
  lateFeeForMoreThanOneMonth: string;

  constructor(data: Partial<AnnualStatementSignReminderEmailPayload> = {}) {
    super(data);
    this.companyName = data.companyName ?? '';
    this.companyACN = data.companyACN ?? '';
    this.billingContactFirstName = data.billingContactFirstName ?? '';
    this.billingContactFullName = data.billingContactFullName ?? '';
    this.directorFirstName = data.directorFirstName ?? '';
    this.directorFullName = data.directorFullName ?? '';
    this.username = data.username ?? '';
    this.partner = data.partner ?? '';
    this.asicContact = data.asicContact ?? '';
    this.accountManager = data.accountManager ?? '';
    this.annualReviewFeeDeadline = data.annualReviewFeeDeadline ?? '';
    this.annualReviewFeeDeadlineShortFormat = data.annualReviewFeeDeadlineShortFormat ?? '';
    this.debt = data.debt ?? '';
    this.lateFeeForOneMonth = data.lateFeeForOneMonth ?? '';
    this.lateFeeForMoreThanOneMonth = data.lateFeeForMoreThanOneMonth ?? '';
  }
}
