<app-input
  #input
  class="ag-input-field-input ag-text-field-input"
  [label]="''"
  [placeholder]="'Enter email *'"
  [isDisabled]="disabled"
  [usedInsideGrid]="true"
  [validationError]="validationError"
  [customErrors]="emailValidatorMsg"
  [warningTooltipMessage]="!value ? 'Email is required' : emailValidatorMsg.invalidEmail"
  [(ngModel)]="value"
  (ngModelChange)="onChangeValue($event)"
></app-input>
