import { Component } from '@angular/core';
import { ICellRendererParams } from "ag-grid-community";
import { Relationship } from "../../../../../../models/relationship";
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from "@ng-bootstrap/ng-bootstrap";

type ActionEvent = (data: unknown) => void;

type CellRendererParams = ICellRendererParams<Relationship> & {
  open: ActionEvent,
  hidden: (params: CellRendererParams) => boolean,
};

@Component({
  selector: 'app-ag-open-action',
  standalone: true,
  imports: [
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle
  ],
  templateUrl: './ag-open-action.component.html',
  styleUrl: './ag-open-action.component.scss'
})
export class AgOpenActionComponent {
  protected params!: CellRendererParams;
  hidden = false;

  agInit(params: CellRendererParams): void {
    this.params = params;
    this.hidden = params.hidden?.(params) ?? false;
  }

  refresh(params: CellRendererParams): boolean {
    this.params = params;
    this.hidden = params.hidden?.(params) ?? false;
    return true;
  }

  onOpen(): void {
    this.params.open(this.params.data);
  }
}
