<app-common-modal-wrapper
  header="Add Share Type"
  (close)="close()"
  (submitModal)="submit()"
  [formGroup]="form"
>
  <div class="wrapper">
    <app-autocomplete
      label="Share class"
      formControlName="class"
      [options]="shareTypeOptions"
      [valuePrimitive]="true"
      [allowTypeAnyValue]="true"
      [customErrors]="shareClassCustomErrors"
    ></app-autocomplete>

    <app-input-number
      label="Paid"
      formControlName="paidAmount"
    ></app-input-number>

    <app-input-number
      label="Unpaid"
      formControlName="unpaidAmount"
    ></app-input-number>

    <app-input
      label="Share Type Custom Name"
      formControlName="name"
    ></app-input>

    <app-input
      label="Share Type"
      formControlName="identifier"
    ></app-input>
  </div>

  @if (showValidationError) {
    <app-validation-error
      [control]="form"
      [customErrors]="{existingSecurityType: 'This security type already exists.'}"
    ></app-validation-error>
  }
</app-common-modal-wrapper>
