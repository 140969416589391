<div class="card card-profile">
  <div class="header">
    <div class="d-flex gap-12">
      <i class="icon ic-tags"></i>
      <h3>Tags</h3>
    </div>

    <div
      #selectTagsDropdown="ngbDropdown"
      ngbDropdown
      placement="bottom-right"
    >
      <button
        ngbDropdownToggle
        title="Customize"
        class="btn btn-secondary d-flex align-items-center justify-content-center"
        [disabled]="disableControls"
      >
        <i class="icon ic-md ic-settings"></i>
      </button>

      <app-select-tags-dropdown
        ngbDropdownMenu
        [entityProfile]="entityProfile"
        [tags]="entityProfile.tags"
        [disabled]="disableControls"
        (assignTag)="assignTag($event)"
        (addNewTag)="addNewTag()"
      ></app-select-tags-dropdown>
    </div>
  </div>

  <div class="customize-tags-tip">
    Click the gear icon to manage tags
  </div>

  <div class="tags-wrapper">
    @if (entityProfile.systemTags.length || actualTagsSignal().length) {
      <ul class="tags-list">
        @for (tag of entityProfile.systemTags; track tag) {
          <li class="tag-item">
            <app-tag [tag]="tag"></app-tag>
          </li>
        }

        @for (tag of actualTagsSignal(); track tag) {
          <li class="tag-item">
            <app-tag [tag]="tag"></app-tag>
          </li>
        }
      </ul>
    } @else {
      <div class="no-data">{{ noTagsAssignedMessage }}</div>
    }
  </div>
</div>
