<div class="multiple-input-email-container">
  @if(label) {
    <div class="header">
      <div class="label">{{label}}</div>
    </div>
  }

  <form class="tags-container" [class.active]="focused" (ngSubmit)="onSubmit($event)">
    @for (tag of value; track $index) {
      <div class="tag" [class.found]="tag?.isFound">
        <div
          class="tag-text"
          [title]="tag.fullName ? tag.fullName + ' (' + tag.email + ')' : tag.email"
        >
          {{ tag.fullName ? tag.fullName + ' (' + tag.email + ')' : tag.email }}
        </div>

        <button (click)="removeTag($index)">
          <span class="icon ic-tiny-xs ic-close"></span>
        </button>
      </div>
    }

    <input
      class="input"
      type="text"
      [disabled]="isDisabled"
      [placeholder]="placeholder"
      [(ngModel)]="typeaheadValue"
      [ngbTypeahead]="searchEmails"
      [inputFormatter]="inputFormatEmail"
      [resultFormatter]="formatEmail"
      (selectItem)="addFoundTag($event)"
      (blur)="!loading && onBlur()"
      (focus)="onFocus()"
      (input)="onInput($event)"
      (keydown)="!loading && onKeyDown($event)"
      tabindex="0"
      name="emails"
    >
  </form>

  @if (loading) {
    <app-dots-loader [label]="'Searching'"></app-dots-loader>
  }

  @if(tip) {
    <div class="tip">{{tip}}</div>
  }

  @if(shouldShowErrors && displayValidationError) {
    <app-validation-error [customErrors]="customErrors"></app-validation-error>
  }

  @if(value.length > maxInputTags) {
    <div class="error">You can't input more than {{maxInputTags}} emails</div>
  }
</div>

