<div class="input-container">
  <div class="header">
    @if (label) {
      <div class="label">{{ label }}</div>
    }

    @if (link && text) {
      <div>
        <app-link [link]="link" [text]="text"></app-link>
      </div>
    } @else {
      <app-form-switch-button
        [text]="switchBtnText"
        (switchForm)="onClickBtn()"
      ></app-form-switch-button>
    }
  </div>

  <div
    class="input-wrapper"
    [ngClass]="{'error': validationError || displayValidationError, 'check': checkedState, 'warn': warningState}"
  >
    <input
      type="text"
      class="input"
      [placeholder]="mask"
      [disabled]="disabled"
      [(ngModel)]="value"
      (input)="onInput($event)"
      (focusin)="focusin.emit($event)"
      (blur)="onTouched()"
    >

    <i
      class="input-icon icon"
      [ngClass]="{'ic-circle-info': validationError || warningState || displayValidationError, 'ic-circle-check': checkedState}">
    </i>
  </div>

  @if (helperText) {
    <div class="helper" [ngClass]="{'error': validationError}">{{ helperText }}</div>
  }

  @if (shouldShowErrors && displayValidationError) {
    <app-validation-error [customErrors]="customErrors"></app-validation-error>
  }
</div>
