<div [formGroup]="form">
  <div class="form-group" [hidden]="hiddenControls['fullName']">
    <app-input-with-suggestions
      formControlName="fullName"
      [label]="individualDataFormLabels.fullName"
      [suggestions]="fullNameSuggestions"
      [customErrors]="directorFullNameValidatorMsg"
    ></app-input-with-suggestions>
  </div>

  <div class="form-group" [hidden]="hiddenControls['formerFullName']">
    <app-input
      formControlName="formerFullName"
      [label]="individualDataFormLabels.formerFullName"
      [customErrors]="directorFullNameValidatorMsg"
    ></app-input>
  </div>

  <div class="form-group" [hidden]="hiddenControls['dob']">
    <app-date-picker
      formControlName="dob"
      [label]="individualDataFormLabels.dob"
      [minDate]="minDate"
      [maxDate]="maxDate"
      [customErrors]="dateOfBirthCustomErrors"
    ></app-date-picker>
  </div>

  <div class="form-group" [hidden]="hiddenControls['birthCountry']">
    <app-autocomplete
      formControlName="birthCountry"
      [label]="individualDataFormLabels.birthCountry"
      [options]="countryOptions"
      [valuePrimitive]="true"
    ></app-autocomplete>
  </div>

  <div class="form-group" [hidden]="hiddenControls['birthCity']">
    <app-input
      formControlName="birthCity"
      [label]="individualDataFormLabels.birthCity"
    ></app-input>
  </div>

  <div class="form-group" [hidden]="hiddenControls['address']">
    <app-address-form-group
      [formGroup]="form.controls.address"
      [useInternationalAddresses]="useInternationalAddresses"
      [expandedAddressForm]="expandedAddressForm"
      [suggestedAddresses]="suggestedAddresses"
    ></app-address-form-group>
  </div>
</div>
