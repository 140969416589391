<div
  class="header"
  [ngClass]="collapsedMenu() ? 'collapsed' : 'expanded'">
  <div class="left"></div>
  <div class="right">
    @if (logo()) {
      <a class="logo d-inline-block" routerLink="/settings">
        <img alt="logo" [attr.src]="logo()">
      </a>
    }

    @if (availableOrganisations()?.length) {
      <div ngbDropdown class="dropdown organisations-dropdown">
        <div class="organisation-select" id="organisation-switch" ngbDropdownToggle>
          <div class="text">{{ organisationName() }}</div>
          <span class="icon ic-select-icon"></span>
        </div>
        <div ngbDropdownMenu aria-labelledby="organisation-switch" class="dropdown-menu">
          @for (org of availableOrganisations(); track org.organisationExternalId) {
            @if (organisationName() !== org.organisationName) {
              <div ngbDropdownItem class="dropdown-item">
                <a (click)="changeOrganization(org.organisationExternalId)" class="dropdown-button">
                  {{ org.organisationName }}
                </a>
              </div>
            }
          }
        </div>
      </div>
    }

    <div class="divider"></div>

    <div class="action-container">
      @if ((alerts$ | async)?.length) {
        <div ngbDropdown #dropdown="ngbDropdown" class="dropdown">
          <div ngbDropdownToggle class="action-btn" data-toggle="dropdown">
            <i class="icon ic-md ic-bell"></i>
            <div class="bell indicator" [ngClass]="{red: !(greenAlert$ | async), green: (greenAlert$ | async)}"></div>
          </div>
          <div ngbDropdownMenu class="dropdown-menu bell-menu dropdown-menu-right">
            @for (alert of (alerts$ | async); track $index) {
              <div
                class="info-item-container"
                [ngClass]="{'unread': !alert.isReaded}"
                (click)="displayAlert(alert.id); dropdown.close()"
              >
                <div class="info-item-wrapper">
                  <div class="info-item">{{ alert.modelDetailTitle }}</div>
                  <div class="info-item date">{{ alert.dateCreated | date: 'dd MMM yyyy HH:mm' }}</div>
                </div>
                <div
                  class="indicator"
                  [ngClass]="{
                     red: alert.indicator === 'red',
                     green: alert.indicator === 'green'
                  }"
                ></div>
              </div>
              <app-divider/>
            }

            <div class="display-all">
              <app-button
                btnColor="white"
                text="See all alerts"
                (click)="displayInboxAlerts(); dropdown.close()"
              />
            </div>
          </div>
        </div>
      }

      <div ngbDropdown class="dropdown">
        <div ngbDropdownToggle class="action-btn" data-toggle="dropdown">
          <i class="icon ic-md ic-my-account"></i>
        </div>
        <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
          <div class="dropdown-item"><a class="dropdown-button">My account</a></div>
          <div class="dropdown-item"><a class="dropdown-button">Business settings</a></div>
          <!-- <div class="dropdown-item"><a class="dropdown-button">Change password</a></div> -->
          <div class="dropdown-item"><a class="dropdown-button" (click)="logOut()">Log out</a>
          </div>
        </div>
      </div>

      <div class="action-btn" (click)="help()">
        <i class="icon ic-md ic-help-circle"></i>
      </div>
      <div class="action-btn" (click)="logOut()">
        <i class="icon ic-md ic-log-out"></i>
      </div>
    </div>
  </div>
</div>
