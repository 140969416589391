import { Injectable } from '@angular/core';
import { map, Observable } from "rxjs";
import { environment } from "../environments/environment";
import { HttpClient } from "@angular/common/http";
import { BulkChangeData, BulkEntity, BulkMatches, BulkSearchResult } from "../models/bulkChangeData";

@Injectable({
  providedIn: 'root'
})
export class BulkService {

  constructor(private api: HttpClient) { }

  public searchByName(name: string): Observable<BulkChangeData[]> {
    const url = name
      ? `${environment.api_url}/bulk/search-by-name?name=${name}`
      : `${environment.api_url}/bulk/search-by-name?name=null`
    return this.api.get<BulkSearchResult>(url).pipe(
      map(res => this.transformBulkSearchData(res))
    );
  }

  public bulkSearchByAddress(address: string): Observable<BulkSearchResult> {
    const url = address
      ? `${environment.api_url}/bulk/search-by-address?address=${address}`
      : `${environment.api_url}/bulk/search-by-address?address=null`

    return this.api.get<BulkSearchResult>(url);
  }

  public searchByAddress(address: string): Observable<BulkChangeData[]> {
    return this.bulkSearchByAddress(address).pipe(
      map(res => this.transformBulkSearchData(res))
    );
  }

  private transformBulkSearchData(res: BulkSearchResult): BulkChangeData[] {
    const transformEntity = (entity: BulkEntity, identifier: string) => {
      return {
        name: entity.name,
        [identifier]: entity.entityNumber,
        dateOfEstablishment: entity.dateOfEstablishment ?? '',
        entityId: entity.entityId,
        matches: transformRelationships(entity)
      };
    };

    const transformRelationships = (entity: BulkEntity) => {
      const transformRegisteredAddress: BulkMatches[] = [];
      if(entity.registeredAddress) {
        transformRegisteredAddress.push({
          label: 'Registered Address',
          value: false,
          address: entity.registeredAddress.normalizedFullAddress,
          relationshipId: ''
        });
      }

      const transformPrincipalAddress: BulkMatches[] = [];
      if(entity.principalAddress) {
        transformPrincipalAddress.push({
          label: 'Principal Place of Business',
          value: false,
          address: entity.principalAddress.normalizedFullAddress,
          relationshipId: ''
        })
      }

      const relationShips = entity.relationsdhips?.map((r) => ({
        label: r.individualDataOverride?.fullName ?? '',
        value: false,
        type: r.details?.$type?.replace('RelationshipDetails', ''),
        address: r.individualDataOverride?.address?.normalizedFullAddress ?? '',
        relationshipId: r.relationshipId,
        relationship: r
      })).sort((a, b) => a.label.localeCompare(b.label));

      return [
        ...transformRegisteredAddress,
        ...transformPrincipalAddress,
        ...relationShips
      ];
    };

    const transformData: BulkChangeData[] = [
      ...res.companies.map(c => transformEntity(c, 'acn')),
      ...res.trusts.map(c => transformEntity(c, 'abn'))
    ];

    return transformData;
  }
}
