@if(column) {
  @if (column.cellDataType === CellDataType.DATE_STRING || column.cellDataType === CellDataType.DATE) {
    <app-date-range-picker
      [startDate]="column.filterParams.dateRangeSelected?.from"
      [endDate]="column.filterParams.dateRangeSelected?.to"
      (dateRangeSelected)="onDateRangeSelected($event)"
    ></app-date-range-picker>
  } @else if (column.cellDataType === CellDataType.NUMBER) {
    <app-number-range-input
      [min]="column.filterParams.numberRangeSelected?.min"
      [max]="column.filterParams.numberRangeSelected?.max"
      (numberRangeSelected)="onNumberRangeSelected($event)"
    ></app-number-range-input>
  } @else if (column.cellDataType === CellDataType.TEXT) {
    <app-search-input
      [placeholder]="column.headerName"
      [borderNone]="true"
      [autoFocused]="true"
      [(ngModel)]="searchText"
      (ngModelChange)="onSearchFilter($event)"
    ></app-search-input>
  } @else {
    <app-search-input
      [placeholder]="column.headerName"
      [borderNone]="true"
      [(ngModel)]="searchText"
      (ngModelChange)="onSearch($event)"
    ></app-search-input>

    <app-divider></app-divider>

    <div class="filter-option-container">
      @for (option of column.filterParams.filterOptions; track option.active; let optionIndex = $index) {
        <div class="filter-option d-flex">
          <app-checkbox
            [label]="option.label"
            [value]="option.active"
            (change)="onFilterOptionChange(optionIndex, $event)"
          ></app-checkbox>
        </div>
      }
    </div>
  }
}
