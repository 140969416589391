<app-stepper-form
  header="Cancellation"
  subheader="Form 484C1"
  [steps]="stepsSignal()"
  [currentStep]="currentStep"
  [currentStepIndex]="currentStepIndex"
  [isLoading]="isLoading"
  [form]="stepperForm"
>
  <div class="step-content full-width" [formGroup]="stepperForm">
    <div class="step-content">
      <app-stepper-form-description
        formKey="484C1"
        [hidden]="currentStep !== StepsEnum.FormDescription"
      ></app-stepper-form-description>
    </div>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.ShareholderAndCancellationDetails"
      [hidden]="currentStep !== StepsEnum.ShareholderAndCancellationDetails"
    >
      <h1 class="h1-heading">Shareholder Details</h1>

      <div class="form-group">
        <app-select-shareholder
          label="Current Shareholders"
          formControlName="securityRegistryRecordIndex"
          [securityRegistryRecords]="securityRegistryRecords"
        ></app-select-shareholder>
      </div>

      @if (boSignsVisible) {
        @for (holder of selectedSecurityRegistryRecordsHolders; track holder) {
          @if (!holder.details.isBeneficialOwner) {
            <div class="form-group">
              <app-beneficial-owner
                [controlLike]="true"
                [details]="holder.details"
                [relationshipId]="holder.relationshipId"
                [showOpenIcon]="false"
                (onDataSaved)="signDataSaved()">
              </app-beneficial-owner>
            </div>
          }
        }

        <div class="form-group" [hidden]="beneficialOwnersSignsErrorMessageHidden">
          <app-validation-error
            [control]="stepperForm"
            [customErrors]="customSignErrors"
          ></app-validation-error>
        </div>
      }

      <app-divider margin="24px 0"></app-divider>

      <div class="form-group share-types">
        <app-share-security-holdings-dropdown
          formControlName="securityHoldingId"
          [options]="securityRegistryRecordsHoldings"
        ></app-share-security-holdings-dropdown>
      </div>

      <div class="share-numbers-grid">
        <div class="form-group number-of-shares-cancelled">
          <app-input-number
            label="Number of Shares Cancelled"
            formControlName="numberOfSharesCancelled"
            [customErrors]="totalNumberOfSharesCustomErrors"
            [min]="0"
            [max]="this.selectedShareholderShareType?.number ?? 0"
          ></app-input-number>
        </div>

        <div class="form-group consideration-paid">
          <app-input-number
            label="Consideration Paid"
            formControlName="considerationPaid"
            [customErrors]="totalNumberOfSharesCustomErrors"
            [min]="0"
            [max]="this.selectedShareholderShareType?.number ?? 0"
          ></app-input-number>
        </div>

        <div class="form-group total-paid">
          <app-input-number
            label="Total Amount Paid"
            formControlName="totalPaid"
            [customErrors]="totalNumberOfSharesCustomErrors"
            [min]="0"
            [max]="this.selectedShareholderShareType?.paid ?? 0"
          ></app-input-number>
        </div>

        <div class="form-group total-unpaid">
          <app-input-number
            label="Total Amount Unpaid"
            formControlName="totalUnpaid"
            [customErrors]="totalNumberOfSharesCustomErrors"
            [min]="0"
            [max]="this.selectedShareholderShareType?.unpaid ?? 0"
          ></app-input-number>
        </div>
      </div>

      <div class="form-group" [hidden]="formShareNumberErrorMessageHidden">
        <app-validation-error
          [control]="shareholderDetailsForm"
          [customErrors]="customErrorsOfShareCancellationNumbers"
        ></app-validation-error>
      </div>

      <div class="form-group certificate-group">
        <app-divider margin="24px 0 32px"></app-divider>

        <div class="form-group">
          <app-radio
            label="Consideration Type"
            formControlName="issueForCashConsidarationOnly"
            [options]="considerationTypesOptions"
          ></app-radio>
        </div>

        <div class="form-group" [hidden]="shareholderDetailsForm.controls.issueForCashConsidarationOnly.value !== false">
          <app-textarea
            label="Non Cash Consideration Details"
            formControlName="nonCashConsiderationDetails"
          ></app-textarea>
        </div>

        <div
          [hidden]="certificatePartHidden"
        >
          <div class="form-group">
            <app-select
              label="Certificate Consolidation"
              formControlName="consolidateCertificate"
              [options]="certificateConsolidationOptions"
              [valuePrimitive]="true"
            ></app-select>
          </div>

          <div class="form-group">
            <app-input-number
              label="Certificate Number"
              formControlName="shareCertificateNumber"
            ></app-input-number>
          </div>
        </div>
      </div>

      <app-divider margin="24px 0 32px"></app-divider>

      <h1 class="h1-heading">Cancellation Details</h1>

      <div class="form-group">
        <app-date-picker
          label="Date of Cancellation"
          formControlName="transactionDate"
          [minDate]="minDate"
          [maxDate]="maxDate"
          [smallWidth]="true"
          [customErrors]="customDatepickerErrors"
        ></app-date-picker>
      </div>

      <div class="form-group">
        <app-select-groups
          label="Reason for Cancellation"
          formControlName="reason"
          [optionGroups]="reasonForCancellationOptionGroups"
        ></app-select-groups>
      </div>

      <app-disclaimer [hidden]="shareholderDetailsForm.controls.reason.value !== CancellationReason.CapitalReductionMultipleShareholder">
        A Form 2560 must be lodged before a capital reduction takes place.
      </app-disclaimer>

      <app-disclaimer [hidden]="shareholderDetailsForm.controls.reason.value !== CancellationReason.BuyBackOther">
        A Form 280 or 281 must be lodged at least 14 days. and no more than 1 year before the share buy-back can take place.
      </app-disclaimer>

      <div [hidden]="shareholderDetailsForm.controls.reason.value !== CancellationReason.Other">
        <div class="form-group">
          <app-textarea label="Description" formControlName="reasonDescription"></app-textarea>
        </div>

        <div class="form-group">
          <app-input label="Section Reference" formControlName="section"></app-input>
        </div>
      </div>
    </section>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.Form280"
      [hidden]="currentStep !== StepsEnum.Form280"
    >
      <h1 class="h1-heading">{{ stepsSignal()[currentStepIndex].label }} (Form 280)</h1>

      <div class="form-group">
        <app-date-picker
          label="Relevant Date"
          formControlName="changeDate"
          [minDate]="minDate"
        ></app-date-picker>
      </div>

      <div class="form-group">
        <app-select
          label="Type of share buy-back"
          formControlName="buyBackType"
          [options]="buyBackTypeOptions"
          [valuePrimitive]="true"
        ></app-select>
      </div>

      <div class="form-group">
        <app-select
          label="Documents attached to this form"
          formControlName="attachedDocumentType"
          [options]="buyBackDocumentTypeOptions"
          [valuePrimitive]="true"
        ></app-select>
      </div>
    </section>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.Form2560"
      [hidden]="currentStep !== StepsEnum.Form2560"
    >
      <h1 class="h1-heading">{{ stepsSignal()[currentStepIndex].label }} (Form 2560)</h1>

      <div class="form-group">
        <app-radio
          label="Documents lodged"
          formControlName="section"
          [options]="form2560DocumentsOptions"
          [horizontalAlign]="false"
        ></app-radio>
      </div>

      <div class="form-group">
        <app-date-picker
          label="Date of Proposed Meeting"
          formControlName="dateOfProposedMeeting"
          [minDate]="minDate"
        ></app-date-picker>
      </div>

      <div class="form-group">
        <app-checkbox
          label="Tick box if ss.249H(2) applies"
          formControlName="section249hAppliesForUnlistedCompany"
        ></app-checkbox>
      </div>

      <div class="form-group">
        <app-checkbox
          label="Is Listed Company"
          formControlName="isListedCompany"
        ></app-checkbox>
      </div>
    </section>

    <section
      class="step-section w-100"
      [formGroupName]="StepsEnum.Estimate"
      [hidden]="currentStep !== StepsEnum.Estimate"
    >
      <h1 class="h1-heading">{{ stepsSignal()[currentStepIndex].label }}</h1>

      <app-share-estimate-table
        [isEstimateLoading]="isEstimateLoading"
        [estimatedSecurityRegistryRecords]="estimatedSecurityRegistryRecords"
      ></app-share-estimate-table>
    </section>
  </div>
</app-stepper-form>
