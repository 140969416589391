@if (readonly && value) {
  <div class="input-container" [style.width]="width">
    <div class="header">
      <div class="label-readonly">{{ label }}</div>
    </div>

    <input
      type="password"
      class="input-readonly"
      [disabled]="true"
      [(ngModel)]="value"
      [placeholder]="placeholder"
    >
  </div>
} @else {
  <div class="input-container" [style.width]="width">
    <div class="header">
      <div class="label">{{ label }}</div>
    </div>

    <div
      class="input-wrapper"
      [ngClass]="{ 'error': validationError || displayValidationError, 'check': checkedState, 'warn': warningState }"
    >
      <input
        class="input"
        [type]="type"
        [disabled]="isDisabled || disabled"
        [(ngModel)]="value"
        (blur)="onTouched()"
      >

      <i
        class="input-icon icon"
        placement="bottom"
        [ngbTooltip]="warningTooltipMessage"
        [ngClass]="{
          'ic-circle-info': validationError || warningState || displayValidationError,
          'ic-circle-check': checkedState,
          'icon-close-to-side': hidePasswordIcon
        }">
      </i>

      <i
        [class]="'show-password-icon icon ' + showPasswordIconClass"
        [hidden]="hidePasswordIcon"
        (click)="passwordVisible = !passwordVisible"
      ></i>
    </div>

    @if (helperText) {
      <div class="helper" [ngClass]="{ 'error': validationError }">{{ helperText }}</div>
    }

    @if (shouldShowErrors && displayValidationError) {
      <app-validation-error [customErrors]="customErrors"></app-validation-error>
    }
  </div>
}

