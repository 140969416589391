<app-common-modal-wrapper
  closeBtnText="Cancel"
  saveBtnText="Save"
  header="Edit Billing & Signing contact details"
  icon=""
  [formGroup]="form"
  [isLoading]="isLoading"
  [isDisabled]="form.invalid"
  (submitModal)="submit()"
  (close)="close()"
>
  <div class="billing-signing-contact-details">
    <div class="d-flex justify-content-end">
      <app-checkbox
        label="Billing is the same"
        formControlName="billingIsTheSame"
      ></app-checkbox>
    </div>

    <div>
      <div class="singing-contact">
        <div class="form-group">
          <app-select
            formControlName="signingContactId"
            [label]="signingContactLabel"
            [options]="contactsOptions"
            [valuePrimitive]="true"
            [requireSorting]="false"
          />
        </div>

        @if (form.controls.signingContactId.value) {
          <div class="contact-details" formGroupName="signingContact">
            <div class="input-row">
              <div class="form-group">
                <app-input
                  label="Email Address"
                  formControlName="email"
                  [customErrors]="emailValidatorMsg"
                />
              </div>

              <div class="form-group">
                <app-input-phone-number
                  label="Mobile Number"
                  formControlName="phone"
                />
              </div>
            </div>
          </div>
        }
      </div>
      @if (!form.controls.billingIsTheSame.value) {
        <app-divider [margin]="'24px 0'"></app-divider>

        <div class="billing-contact">
          <div class="form-group">
            <app-select
              formControlName="billingContactId"
              label="Billing Contact"
              [options]="billingContactsOptions"
              [valuePrimitive]="true"
              [requireSorting]="false"
            />
          </div>

          @if (form.controls.billingContactId.value) {
            <div class="contact-details" formGroupName="billingContact">
              @if (form.controls.billingContactId.value === Guid.EmptyGuid) {
                <div class="input-row">
                  <div class="form-group">
                    <app-input
                      label="First Name"
                      formControlName="firstName"
                    />
                  </div>

                  <div class="form-group">
                    <app-input
                      label="Last Name"
                      formControlName="lastName"
                    />
                  </div>
                </div>
              }

              <div class="input-row">
                <div class="form-group">
                  <app-input
                    label="Email Address"
                    formControlName="email"
                  />
                </div>

                <div class="form-group">
                  <app-input-phone-number
                    label="Mobile Number"
                    formControlName="phone"
                  />
                </div>
              </div>
            </div>
          }
        </div>
      }
    </div>
  </div>
</app-common-modal-wrapper>
