import { EntityData } from "./entityData";
import { IndividualData } from "./individualData";
import { ShareholderRelationshipDetails } from "./relationship";
import { SecurityHolding } from "./securityHolding";

export class SecurityRegistryRecord {
    holders: (IndividualHolderModel | CorporateHolderModel)[];
    holdings: SecurityHolding[];

    constructor(record?: Partial<SecurityRegistryRecord>) {
        this.holders = record?.holders?.map(holder => {
            switch (holder.$type) {
                case 'IndividualHolderModel':
                    return new IndividualHolderModel(holder);
                case 'CorporateHolderModel':
                    return new CorporateHolderModel(holder);
                default:
                    throw new Error(`Unknown holder type: ${holder.$type}`);
            }
        }) ?? [];
        this.holdings = record?.holdings ? record.holdings.map(holding => new SecurityHolding(holding)) : [];
    }

    get beneficialOwnerGroups(): Record<string, (IndividualHolderModel | CorporateHolderModel)[]> {
        const result = this.holders.reduce((result, item) => {
            const key = item.details.beneficialOwnerLabel;
            result[key] = [...result[key] || [], item];
            return result;
        }, {} as Record<string, (IndividualHolderModel | CorporateHolderModel)[]>);

        //sort result by type and name
        Object.keys(result).forEach(key => {
            result[key].sort((a, b) => {
                if (a instanceof IndividualHolderModel && b instanceof CorporateHolderModel) {
                    return 1;
                }
                if (a instanceof CorporateHolderModel && b instanceof IndividualHolderModel) {
                    return -1;
                }
                return a.name.localeCompare(b.name);
            });
        });

        return result;
    }

    get fullName(): string {
        const groups = Object.entries(this.beneficialOwnerGroups).sort(([a], [b]) => a.localeCompare(b));

        return groups.map(([key, value]) => {
            const names = value.map(item => item instanceof IndividualHolderModel ? item.individualData.fullName : `${item.entityData.name}`);

            const nboPrefix = (key || key === 'NBO') ? ' NBO' : ` as trustee for ${key}`;

            return `${names.join(', ')}${nboPrefix}`;
        }).join('; ');
    }

    get beneficialOwner(): string {
        return Object.keys(this.beneficialOwnerGroups).sort((a, b) => a.localeCompare(b))[0] ?? '';
    }

    get isBeneficialOwner(): boolean {
        return this.holders.every(h => h.details.isBeneficialOwner);
    }

    get number(): number {
      return this.holdings.reduce((acc, holding) => acc + holding.number, 0);
    }

    get name(): string {
      return this.holders.map((holder) => holder.name).join(', ') + ` (${this.number} ${this.holdings[0].securityType.class})`;
    }
}


export abstract class HolderModelBase {
    static readonly $type: string;
    $type!: string;

    abstract get name(): string;

    relationshipId: string
    details: ShareholderRelationshipDetails;

    constructor(holder?: Partial<HolderModelBase>) {
        this.details = holder?.details ? new ShareholderRelationshipDetails(holder.details) : new ShareholderRelationshipDetails();
        this.$type = (this.constructor as typeof HolderModelBase).$type;
        this.relationshipId = holder?.relationshipId ?? '';
    }
}

export class CorporateHolderModel extends HolderModelBase {
    static override readonly $type = 'CorporateHolderModel';

    entityData: EntityData;
    entityId?: string;

    override get name(): string {
        return `${this.entityData.name}`;
    }

    constructor(holder?: Partial<CorporateHolderModel>) {
        super(holder);
        this.entityData = holder?.entityData ? new EntityData(holder.entityData) : new EntityData();
        this.entityId = holder?.entityId;
    }
}

export class IndividualHolderModel extends HolderModelBase {
    static override readonly $type = 'IndividualHolderModel';

    individualData: IndividualData;
    previousIndividualData?: IndividualData | null | undefined;
    pendingButtonState?: boolean | undefined
    individualId?: string;

    override get name(): string {
        return this.individualData.fullName;
    }

    constructor(holder?: Partial<IndividualHolderModel>) {
        super(holder);
        this.individualData = holder?.individualData ? new IndividualData(holder.individualData) : new IndividualData();
        this.previousIndividualData = holder?.previousIndividualData ? new IndividualData(holder.previousIndividualData) : new IndividualData();
        this.pendingButtonState = holder?.pendingButtonState ?? false;
        this.individualId = holder?.individualId;
    }
}
