import { Component, Input, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { timer } from 'rxjs';
import { AddressForm } from '../../../models/addressForm';
import { NgIf } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { AppSpinnerDirective } from "../../../directives/spinner.directive";

@Component({
  selector: 'app-individual-address',
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    AppSpinnerDirective
  ],
  templateUrl: './individual-address.component.html',
  styleUrl: './individual-address.component.scss'
})
export class IndividualAddressComponent {
  activeModal = inject(NgbActiveModal);
  continuing = false;
  @Input() addressForm!: AddressForm;

  ngOnInit(): void {
    this.activeModal.update({ size: 'sm' });
  }

  continue() {
    this.continuing = true;

    timer(2000).subscribe(() => {
      this.continuing = false;
      this.addressForm.preview = true;
    });
  }

  passBack() {
    this.continuing = true;

    timer(2000).subscribe(() => {
      this.continuing = false;
      this.activeModal.close(this.addressForm);
    });
  }
}
