<app-stepper-form
  header="Trust Distribution Resolution or Minutes"
  [showSteps]="false"
  [showSaveAndCompleteLaterBtn]="false"
  [steps]="stepsSignal()"
  [currentStep]="currentStep"
  [currentStepIndex]="currentStepIndex"
  [form]="stepperForm"
  [isLoading]="isLoading"
>
  <div class="step-content" [formGroup]="stepperForm">
    <section
      class="step-section"
      [formGroupName]="StepsEnum.TrustDetails"
      [hidden]="currentStep !== StepsEnum.TrustDetails"
    >
      <h1 class="h1-heading">{{ stepsSignal()[currentStepIndex].label }}</h1>

      <div class="form-group">
        <app-input
          label="Name of Trust"
          formControlName="name"
        />
      </div>

      <div class="form-group">
        <app-textarea
          label="Determination of income under the Trust Deed"
          formControlName="trustDeedIncomeDetermination"
          [rows]="3"
        />
      </div>

      <div class="form-group">
        <app-textarea
          label="Power to distribute income and classes of income"
          formControlName="powerToDistributeIncome"
          [rows]="3"
        />
      </div>
    </section>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.TrusteeDetails"
      [hidden]="currentStep !== StepsEnum.TrusteeDetails"
    >
      <h1 class="h1-heading">{{ stepsSignal()[currentStepIndex].label }}</h1>

      <ul class="list-unstyled">
        @if (trusteeDetailsForm.controls.trusteeType.value === IndividualCorporateTrustee.Individual) {
          @for (trusteeFormGroup of trusteeDetailsForm.controls.individualTrustee.controls; track trusteeFormGroup; let index = $index; let last = $last) {
            <li>
              <app-relationship-form-group
                nameControlLabel="Name of Trustee"
                [formGroup]="trusteeFormGroup"
              />
            </li>

            @if (!last) {
              <li>
                <app-divider margin="24px 0"/>
              </li>
            }
          }
        } @else if (trusteeDetailsForm.controls.trusteeType.value === IndividualCorporateTrustee.Corporate) {
          <app-corporate-relationship-form-group
            [hideIsOverseasCompany]="true"
            [formGroup]="trusteeDetailsForm.controls.corporateTrustee"
          />
        }
      </ul>
    </section>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.BeneficiaryDetails"
      [hidden]="currentStep !== StepsEnum.BeneficiaryDetails"
    >
      <h1 class="h1-heading">{{ stepsSignal()[currentStepIndex].label }}</h1>

      <ul class="list-unstyled">
        @if (beneficiaryDetailsForm.controls.beneficiaryType.value === IndividualCorporateTrustee.Individual) {
          @for (beneficiaryFormGroup of beneficiaryDetailsForm.controls.individualBeneficiaries.controls; track beneficiaryFormGroup; let index = $index; let last = $last) {
            <li>
              <h2 class="h2-heading">Beneficiary {{ index + 1 }}</h2>

              <app-relationship-form-group
                nameControlLabel="Name of Beneficiary"
                [formGroup]="beneficiaryFormGroup"
              />
            </li>

            @if (!last) {
              <li>
                <app-divider margin="24px 0"/>
              </li>
            }
          }
        } @else if (beneficiaryDetailsForm.controls.beneficiaryType.value === IndividualCorporateTrustee.Corporate) {
          <app-corporate-relationship-form-group
            [hideIsOverseasCompany]="true"
            [formGroup]="beneficiaryDetailsForm.controls.corporateBeneficiary"
          />
        }
      </ul>
    </section>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.DistributionDetails"
      [hidden]="currentStep !== StepsEnum.DistributionDetails"
    >
      <h1 class="h1-heading">{{ stepsSignal()[currentStepIndex].label }}</h1>

      <div class="form-group">
        <app-select
          label="Financial Year Ending"
          formControlName="financialYear"
          [options]="financialYearEndingOptions"
          [valuePrimitive]="true"
        />
      </div>

      <ul class="list-unstyled" formArrayName="beneficiaries">
        @for (beneficiaryFormGroup of distributionDetailsForm.controls.beneficiaries.controls; track beneficiaryFormGroup; let index = $index) {
          <li [formGroup]="beneficiaryFormGroup">
            @if (index > 0) {
              <div class="d-flex justify-content-end">
                <app-button iconClass="icon ic-md ic-close" (clickBtn)="removeDistributionFormGroup(index)"/>
              </div>
            }

            <div class="form-group">
              <app-select
                label="Distribution Type"
                formControlName="distributionType"
                [options]="filteredDistributionTypes[beneficiaryFormGroup.controls.distributionType.value ?? '']"
                [valuePrimitive]="true"
                (select)="onSelectDistributionType($event.value)"
              />
            </div>

            <div class="form-group">
              <app-radio
                label=""
                formControlName="disctributionMeasurement"
                [options]="disctributionMeasurementSelectOptions"
              />
            </div>

            <ul class="list-unstyled" formGroupName="beneficiaries">
              @for (f of beneficiaryFormGroup.controls.beneficiaries.controls | keyvalue; track f.key) {
                <li>
                  <div class="form-group">
                    <app-input-number
                      [label]="names[f.key]"
                      [formControlName]="f.key"
                    />
                  </div>
                </li>
              }
            </ul>

            <app-validation-error
              [control]="beneficiaryFormGroup"
              [customErrors]="{ percentsAreNotFit: 'Ensure the total percentage equals 100%.' }"
            />
          </li>
        }
      </ul>

      <div class="d-flex justify-content-end">
        <app-button
          iconClass="icon ic-md ic-plus"
          text="Add Distribution type"
          [isDisabled]="isLoading || distributionDetailsForm.controls.beneficiaries.length >= this.distributionTypeSelectOptions.length"
          (click)="addDistributionFormGroup()"
        />
      </div>
    </section>
  </div>
</app-stepper-form>
