import { Component, Input, inject, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../../services/auth.service';
import { Company } from '../../../models/company';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { UserBrief } from "../../../models/uiProfile";
import { SelectOption } from "../../../models/selectOptions";
import { InputComponent } from "../../components/common/input/input.component";
import { DatePickerComponent } from "../../components/common/date-picker/date-picker.component";
import { DividerComponent } from "../../components/common/divider/divider.component";
import { SelectComponent } from "../../components/common/select/select.component";
import { ButtonComponent } from "../../components/common/button/button.component";
import { CustomFormValidators } from "../../../custom-form-validators/custom-form-validators";
import { corporateKeyMsg } from "../../../validators/validatorMessages/custom-form-validators-messages";

@Component({
  selector: 'app-company-edit',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputComponent,
    DatePickerComponent,
    DividerComponent,
    SelectComponent,
    ButtonComponent
  ],
  templateUrl: './company-edit.component.html',
  styleUrl: './company-edit.component.scss'
})
export class CompanyEditComponent implements OnInit {
  private activeModal = inject(NgbActiveModal);
  private authService = inject(AuthService);
  private fb: FormBuilder = inject(FormBuilder);

  @Input() public editModel!: Company;
  @Input() public $confirm!: () => Observable<unknown>;

  protected readonly corporateKeyMsg = corporateKeyMsg;

  form!: FormGroup;
  users: UserBrief[] = [];
  usersOption: SelectOption[] = [];
  saving = false;

  ngOnInit(): void {
    this.activeModal.update({ size: 'lg' });
    this.createUserOptions();
    this.initForm();
  }

  private createUserOptions(): void {
    this.users = this.authService.currentUserProfile()?.users ?? [];
    this.usersOption = this.users.map(user => {
      return {
        value: user.id,
        label: user.fullName
      };
    })
  }

  private initForm(): void {
    this.form = this.fb.group({
      corporateKey: [this.editModel.corporateKey, [CustomFormValidators.CorporateKeyValidator]],
      nextAnnualReviewDate: [this.editModel.nextAnnualReviewDate],
      partnerManagerId: [this.editModel.partnerManagerId],
      accountManagerId: [this.editModel.accountManagerId],
      referenceNumber: [this.editModel.referenceNumber],
      familyGroup: [this.editModel.familyGroup],
    });
  }

  private updateModel(): void {
    for (const key in this.form.getRawValue()) {
      this.editModel[key] = (this.form.getRawValue() as Partial<Company>)[key];
    }
  }

  close(): void {
    this.activeModal.close();
  }

  $submit(): void {
    this.updateModel();

    this.saving = true;
    this.$confirm().subscribe({
      complete: () => this.close(),
      error: () => this.saving = false
     });
  }
}
