<button
  type="button"
  class="tab-button"
  [class.active]="isActive()"
  [class.disabled]="isDisabled()"
  (click)="tabClicked.emit($event)"
  [disabled]="isDisabled()"
>
  @if (iconClass()) {
    <i [class]="'icon ic-md ' + iconClass()"></i>
  }
  <span class="content">
    <ng-content></ng-content>
  </span>
</button>
