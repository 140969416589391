@if(label) {
  <div class="label">{{label}}</div>
}
<div class="number-range-container">
  <input
    class="input"
    type="number"
    [placeholder]="placeholderMin"
    [(ngModel)]="min"
    (ngModelChange)="onChangeMin($event)"
  >
  <input
    class="input"
    type="number"
    [placeholder]="placeholderMax"
    [(ngModel)]="max"
    (ngModelChange)="onChangeMax($event)"
  >
</div>

