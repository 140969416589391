import { Component, inject, Input } from '@angular/core';
import { EntityNameNumberRecordComponent } from '../../../entity-name-number-record/entity-name-number-record.component';
import { Trust } from '../../../../../../models/trust';
import { ICellRendererParams } from 'ag-grid-community';
import { ABNPipe } from "../../../../../../pipes/abnPipe";
import { Router } from "@angular/router";

type ActionEvent = (data: Trust | undefined) => void;

type CellRendererParams = ICellRendererParams<Trust> & {
  openTrustProfile: ActionEvent,
};

@Component({
    selector: 'app-ag-trust-name-abn',
    standalone: true,
    imports: [
      EntityNameNumberRecordComponent,
      ABNPipe
  ],
    templateUrl: './ag-trust-name-abn.component.html',
    styleUrl: './ag-trust-name-abn.component.scss'
})
export class AgTrustNameAbnComponent {
  @Input() name = '';
  @Input() abn = '';
  private router = inject(Router);

  protected params!: CellRendererParams;

  agInit(params: CellRendererParams): void {
    this.params = params;
    this.name = this.params.data?.name || '';
    this.abn = this.params.data?.abn || '';
  }

  refresh(params: CellRendererParams): boolean {
    this.params = params;
    return true;
  }

  onOpenTrustProfile(e: MouseEvent): void {
    if (e.ctrlKey) {
      e.preventDefault();
      window.open(`/trust-profile/${this.params.data?.entityId}`, '_blank');
    } else {
      void this.router.navigate(['/trust-profile', this.params.data?.entityId]);
    }
  }
}
