import { Component, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { distinctUntilChanged } from 'rxjs';
import {
  DatePickerFormValidators,
  DatepickerHelper
} from '../../../../../../custom-form-validators/date-picker-form-validators';
import { Document } from '../../../../../../models/document';
import { CompanySubtype } from '../../../../../../models/enums/companyEnums';
import { SelectOption } from '../../../../../../models/selectOptions';
import { BaseStepperFormComponent } from '../../../../stepper-form/base-stepper-component/base-stepper-form.component';
import { CompanyChangeSpecialPurpose } from './company-status-change-form.model';
import { StepperFormComponent } from "../../../../stepper-form/stepper-form.component";
import {
  StepperFormDescriptionComponent
} from "../../../../stepper-form/stepper-form-description/stepper-form-description.component";
import { DatePickerComponent } from "../../../../../components/common/date-picker/date-picker.component";
import { SelectComponent } from "../../../../../components/common/select/select.component";

export enum SpecialPurposeChangeStepsEnum {
  FormDescription = 0,
  CompanyStatusChange = 1
}

export const availableChangeTypesOptions: SelectOption[] = [
  { label: 'Commerce', value: 'Commerce' },
  { label: 'Cease', value: CompanySubtype.PROP },
];

export const companyStatusChangeOptions: SelectOption[] = [
  { label: 'Home unit company', value: CompanySubtype.HUNT },
  { label: 'Superannuation trustee company', value: CompanySubtype.PSTC },
  { label: 'For charitable purposes only', value: CompanySubtype.PNPC },
];

@Component({
  selector: 'app-b3-company-status-change',
  standalone: true,
  templateUrl: './b3-company-status-change.component.html',
  styleUrl: '../../../../stepper-form/base-stepper-component/base-stepper-form.component.scss',
  imports: [
    ReactiveFormsModule,
    StepperFormComponent,
    StepperFormDescriptionComponent,
    DatePickerComponent,
    SelectComponent,
  ]
})
export class B3CompanyStatusChangeComponent extends BaseStepperFormComponent<SpecialPurposeChangeStepsEnum, CompanyChangeSpecialPurpose> implements OnInit {
  override readonly StepsEnum: typeof SpecialPurposeChangeStepsEnum = SpecialPurposeChangeStepsEnum;

  availableChangeTypesOptions: SelectOption[] = [];
  statusOptions = companyStatusChangeOptions;
  minDate!: NgbDateStruct;
  maxDate!: NgbDateStruct;

  form = new FormGroup({
    newCompanySubtype: new FormControl<CompanySubtype | string | null>(null, [Validators.required]),
    specialPurposeType: new FormControl<CompanySubtype | string | null>(null, [Validators.required]),
    changeDate: new FormControl<Date | null>(null, [Validators.required, DatePickerFormValidators.dateFormatValidator]),
  });

  override stepperForm = new FormGroup({
    [SpecialPurposeChangeStepsEnum.FormDescription]: new FormGroup({}),
    [SpecialPurposeChangeStepsEnum.CompanyStatusChange]: this.form
  });

  isChangeTypeCeaseOrNull = signal(true);

  constructor() {
    super();
    this.form.controls.changeDate.setValue(DatepickerHelper.getToday());
    this.setupSteps(SpecialPurposeChangeStepsEnum);
  }

  ngOnInit(): void {
    this.minDate = DatepickerHelper.getDateOfEstablishmentMinDate(this.companyChangeData.dateOfEstablishment);
    this.maxDate = DatepickerHelper.getNextNYearsStruct(10);
    this.setupAvailableChangeTypesOptions();
    this.isChangeTypeCeaseOrNull.set(this.formModel.newCompanySubtype === CompanySubtype.PROP || this.form.value.newCompanySubtype === null);
    this.listenNewCompanySubtypeChanges();
    this.setupChange();
  }

  override setupChange(): void {
    if (!this.isEdit)
      return;

    this.form.patchValue({
      ...this.formModel,
      newCompanySubtype: this.formModel.newCompanySubtype === CompanySubtype.PROP ? CompanySubtype.PROP : 'Commerce',
      specialPurposeType: this.formModel.newCompanySubtype
    });
  }

  override buildDocument(): Document | null {
    const formValue = this.form.value;
    const changes = new CompanyChangeSpecialPurpose({
      changeDate: formValue.changeDate!,
      description: this.formModel.description,
      newCompanySubtype: (formValue.newCompanySubtype === 'Commerce'
        ? formValue.specialPurposeType
        : formValue.newCompanySubtype) as unknown as CompanySubtype
    });

    try {
      return new Document({
        changes: [changes],
        entityId: this.companyChangeData.entityId,
        type: 'c:484',
        documentId: this.companyChangeData?.documentId,
      });
    } catch (error) {
      console.warn(error);
      return null;
    }
  }

  private listenNewCompanySubtypeChanges(): void {
    this.form.controls.newCompanySubtype.valueChanges
      .pipe(distinctUntilChanged((prev, current) => prev === current), takeUntilDestroyed(this.destroyRef))
      .subscribe((newCompanySubtype) => {
        const isCeaseChangeType = newCompanySubtype === CompanySubtype.PROP;

        if (isCeaseChangeType) {
          this.form.controls.specialPurposeType.setValue(newCompanySubtype);
          this.form.controls.specialPurposeType.disable();
        } else {
          this.form.controls.specialPurposeType.enable();
        }

        this.isChangeTypeCeaseOrNull.set(newCompanySubtype === CompanySubtype.PROP || newCompanySubtype === null);
      });
  }

  private setupAvailableChangeTypesOptions(): void {
    const isCommerceType = this.formModel.newCompanySubtype !== CompanySubtype.PROP;

    if (isCommerceType) {
      this.availableChangeTypesOptions = availableChangeTypesOptions.filter((option) => option.value !== CompanySubtype.PROP);
    } else {
      this.availableChangeTypesOptions = availableChangeTypesOptions.filter((option) => option.value === CompanySubtype.PROP);
    }
  }
}
