import { DocumentSelection } from "../../../../models/document-selection";
import { DocumentationType, DocumentSelectionType } from "../../../../models/enums/documentConfirmEnums";

export const isDocumentVisible = (documentSelection: DocumentSelection, documentationType?: DocumentationType): boolean => {
  if (documentationType === DocumentationType.Minutes && documentSelection.documentType !== DocumentSelectionType.Resolution) {
    return true;
  } else if (documentationType === DocumentationType.Resolution && documentSelection.documentType !== DocumentSelectionType.Minutes) {
    return true;
  } else if (documentationType === DocumentationType.FormOnly && documentSelection.documentType !== DocumentSelectionType.Minutes && documentSelection.documentType !== DocumentSelectionType.Resolution) {
    return true;
  }
  return false;
}
