import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  standalone: true,
  name: 'officerStatus'
})
export class OfficerStatusPipe implements PipeTransform {
  transform(value?: Date): string {
    if(value == undefined)
        return "Appointed"
    
    return 'Resigned';
  }
}