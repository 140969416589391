import { Component, DestroyRef, inject, input, signal } from '@angular/core';
import { interval } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-dots-loader',
  standalone: true,
  imports: [],
  templateUrl: './dots-loader.component.html',
  styleUrl: './dots-loader.component.scss'
})
export class DotsLoaderComponent {
  label = input('');
  dots = signal<null[]>([]);
  #destroyRef = inject(DestroyRef);

  constructor() {
    interval(500).pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(() => {
      this.dots.update((value) => value.length === 3 ? [] : [...value, null])
    });
  }
}
