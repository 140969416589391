import { SelectOption } from "../../../../models/selectOptions";
import { ChangeDictionaryHelper, IDictionaryItem } from "../../../../models/shared/change-dictionary-helper.model";
import { EntityChangeData } from "../../../../models/entityChangeData";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Document } from "../../../../models/document";
import { Company } from "../../../../models/company";
import { FormTrustDistributionComponent } from "./form-trust-distribution.component";
import { IPDFSection } from "../../../../models/shared/pdf-section.interface";
import { Relationship } from "../../../../models/relationship";

export enum DisctributionMeasurement {
  Percentage = 0,
  Amount = 1,
}

export enum DistributionType {
  DistributionOfFrankedDividends = 0,
  DistributionOfCapitalGains = 1,
  DistributionOfDiscountedCapitalGains = 2,
  Under18NoDisability = 3,
  Under18Disability = 4,
  BalanceOfTrustIncome = 5,
  CustomDistribution = 6,
}

export class DistributionModel {
  distributionType: DistributionType;
  disctributionMeasurement: DisctributionMeasurement;
  beneficiaries: Record<string, number>;

  constructor(data: Partial<DistributionModel> = {}) {
    this.distributionType = data.distributionType!;
    this.disctributionMeasurement = data.disctributionMeasurement!;
    this.beneficiaries = data.beneficiaries ?? {};
  }

  toDictionary(): IDictionaryItem[] {
    const dict = new ChangeDictionaryHelper();
    const distributionType = distributionTypeSelectOptions.find((type) => type.value == this.distributionType)?.label ?? '';

    dict.add('Distribution Type', distributionType);
    const beneficiariesEntries = Object.entries(this.beneficiaries);
    if (this.disctributionMeasurement == DisctributionMeasurement.Percentage) {
      beneficiariesEntries.forEach(([relationshipId, value]) => dict.add(relationshipId, `${ value }%`));
    } else {
      beneficiariesEntries.forEach(([relationshipId, value]) => dict.addMoney(relationshipId, value));
    }

    return dict.dictionary;
  }
}

export class TrustDistribution extends EntityChangeData {
  static override $type = 'TrustDistribution';

  trustDeedIncomeDetermination: string;
  powerToDistributeIncome: string;
  trustees: Relationship[];
  beneficiaries: Relationship[];
  financialYear: number;
  distributions: DistributionModel[];

  constructor(data: Partial<TrustDistribution> = {}) {
    super(data);

    this.trustDeedIncomeDetermination = data.trustDeedIncomeDetermination ?? '';
    this.powerToDistributeIncome = data.powerToDistributeIncome ?? '';
    this.trustees = data.trustees?.map((trustee) => new Relationship(trustee)) ?? [];
    this.beneficiaries = data.beneficiaries?.map((trustee) => new Relationship(trustee)) ?? [];
    this.financialYear = data.financialYear ?? 0;
    this.distributions = data.distributions?.map((distribution) => new DistributionModel(distribution)) ?? [];
  }

  override toDictionary(): IDictionaryItem[] {
    const dict = new ChangeDictionaryHelper();
    const addRelationships = (relationships: Relationship[], label: string) => relationships
      .forEach((r) => dict.add(
        label,
        `${ r?.individualDataOverride?.fullName ?? r?.entityDataOverride?.name } - ${ r.individualDataOverride?.address?.normalizedFullAddress ?? r.entityDataOverride?.registeredAddress?.normalizedFullAddress }`
      ));

    // Group 1:
    //  financial year ending,
    //  trustDeedIncomeDetermination,
    //  powerToDistributeIncome
    dict
      .add('Financial Year Ending', this.financialYear)
      .add('Determination of income under the Trust Deed', this.trustDeedIncomeDetermination)
      .add('Power to distribute income and classes of income', this.powerToDistributeIncome);
    dict.addDivider();

    // Group 2:
    //  related trustee - name-address
    addRelationships(this.trustees, 'Trustee');
    dict.addDivider();

    // Group 3:
    //  related beneficiaries - name-address
    addRelationships(this.beneficiaries, 'Beneficiary');
    dict.addDivider();

    // Group 4:
    //  distribution

    this.distributions.forEach((distribution: DistributionModel) => {
      distribution.toDictionary().forEach((dictionaryItem) => dict.add(dictionaryItem.key, dictionaryItem.value));
      dict.addDivider();
    });

    return dict.dictionary;
  }

  override prepareToPdf(): IPDFSection[] {
    const dict = new ChangeDictionaryHelper();
    return dict.sections;
  }

  override fillModal(modalRef: NgbModalRef, document: Document, actualCompany: Company): NgbModalRef {
    modalRef = this.fillModalWithCompany(modalRef, actualCompany, true);
    (modalRef.componentInstance as FormTrustDistributionComponent).companyChangeData.documentId = document.documentId;
    return modalRef;
  }

  override fillModalWithCompany(modalRef: NgbModalRef, company: Company, isEdit: boolean): NgbModalRef {
    const instance = modalRef.componentInstance as FormTrustDistributionComponent;
    instance.isEdit = isEdit;
    instance.formModel = structuredClone(this);
    return modalRef;
  }
}

export enum TrustDistributionStepsEnum {
  TrustDetails = 0,
  TrusteeDetails = 1,
  BeneficiaryDetails = 2,
  DistributionDetails = 3,
}

export function getFinancialYearEndingOptions(year: number): SelectOption[] {
  year += 1;
  const years: number[] = [];
  const yearsQuantity = 8;

  for (let i = 0; i < yearsQuantity; i++) {
    years.push(year - i);
  }

  return years.map((year) => ({ label: year.toString(), value: year }));
}

export const distributionTypeSelectOptions = [
  {
    label: "Distribution of Franked Dividends",
    value: DistributionType.DistributionOfFrankedDividends
  },
  {
    label: 'Distribution of Capital Gains',
    value: DistributionType.DistributionOfCapitalGains
  },
  {
    label: 'Distribution of Discounted Capital Gains',
    value: DistributionType.DistributionOfDiscountedCapitalGains
  },
  {
    label: 'Under 18 (No Disability/No Testamentary or Will) Distribution',
    value: DistributionType.Under18NoDisability
  },
  {
    label: 'Under 18 (Disability/Testamentary or Will) Distribution',
    value: DistributionType.Under18Disability
  },
  {
    label: 'Balance of Trust Income',
    value: DistributionType.BalanceOfTrustIncome
  },
  {
    label: 'Custom Distribution',
    value: DistributionType.CustomDistribution
  },
];

export const disctributionMeasurementSelectOptions: SelectOption[] = [
  {
    label: '$',
    value: DisctributionMeasurement.Amount
  },
  {
    label: '%',
    value: DisctributionMeasurement.Percentage
  }
];
