import { Component, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { IntegrationsService } from '../../../../../../services/integrations.service';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputComponent } from '../../../../../components/common/input/input.component';
import { CommonModalFormComponent } from '../../../../../modals/common-modal-form/common-modal-form.component';
import { AnnatureRegisterAccount } from '../../../../../../models/annatureRigisterAccount';
import { ConnectionStatusEnum } from '../../../../../../models/enums/connectionStatusEnum';
import { ConnectionResponse } from '../../../../../../models/integrationConnectionResponse';

@Component({
  selector: 'app-annature-register-modal',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputComponent,
    CommonModalFormComponent,
  ],
  templateUrl: './annature-register-modal.component.html',
  styleUrl: './annature-register-modal.component.scss'
})
export class AnnatureRegisterModalComponent {

  private activeModal = inject(NgbActiveModal);
  private integrationsSerivice = inject(IntegrationsService);
  private toastr = inject(ToastrService);
  isLoading: boolean = false;

  form = new FormGroup({
    name: new FormControl<string | null>(null, [Validators.required]),
    email: new FormControl<string | null>(null, [Validators.required, Validators.email]),
    business: new FormControl<string | null>(null, [Validators.required]),
    number: new FormControl<string | null>(null, []),
  });


  confirm(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const payload = new AnnatureRegisterAccount(this.form.value as Partial<AnnatureRegisterAccount>);

    this.isLoading = true;
    this.form.disable();

   this.integrationsSerivice.registerAnnatureAccount(payload)
      .subscribe({
        next: (response) => {
          this.showResponseToaster(response);
          this.activeModal.close(response.status);
        },
        error: () => {
          this.isLoading = false;
          this.form.enable();
          this.toastr.success('Registration Failed!', 'Error');
        }
      });
  }

  close(): void {
    this.activeModal.dismiss();
  }

  showResponseToaster(response: ConnectionResponse): void {
    switch (response.status) {
      case ConnectionStatusEnum.Connected:
      case ConnectionStatusEnum.WaitForVerification:
        this.toastr.success('Registration Successful!', 'Success');
        break;
      case ConnectionStatusEnum.ConnectionFailed:
      case ConnectionStatusEnum.NotConnected:
        this.toastr.error(response.message ? response.message : 'Registration Failed!' , 'Error');
        break;
    }
  }

}
