<app-common-modal-form
  [header]="'Generate a Payslip'"
  [icon]="'icon ic-note'"
  [confirmText]="'Download'"
  [closeText]="'Cancel'"
  [isInvalid]="form.invalid || this.form.get('paidAmount')?.value === '$'"
  (close)="close()"
  (confirm)="downloadPayslip()"
>
  <div #modalElement class="body-container d-flex flex-column gap-24 mb-3" [formGroup]="form">
    <app-autocomplete
      formControlName="reason"
      [label]="'Reason'"
      [placeholder]="'Select reason'"
      [isCombobox]="true"
      [valuePrimitive]="false"
      [options]="reasonOptions"
    ></app-autocomplete>

    <app-input
      formControlName="paidAmount"
      appDollar
      [label]="'Amount to Add'"
      [placeholder]="'Enter amount to add'"
    ></app-input>
  </div>
</app-common-modal-form>
