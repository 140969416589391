<app-stepper-form
  header="Registered Agent Direct Debit Request"
  subheader="Form RA04"
  [showSteps]="true"
  [steps]="steps"
  [currentStep]="currentStep"
  [currentStepIndex]="currentStepIndex"
  [isLoading]="isLoading"
  [form]="stepperForm"
>
  <div class="step-content" [formGroup]="stepperForm">
    <app-stepper-form-description
      formKey="RA04"
      [hidden]="currentStep !== StepsEnum.FormDescription"
    ></app-stepper-form-description>

    <section
      class="step-section notification-type"
      [formGroup]="stepperForm.controls[StepsEnum.Customer]"
      [hidden]="currentStep !== StepsEnum.Customer"
    >
      <h1 class="h1-heading">{{ steps[currentStepIndex].label }}</h1>

      <app-app-individual-or-company
        [formGroup]="stepperForm.controls[StepsEnum.Customer].controls.individualOrCompany"
        [acn]="formModel.companyAcn"
        [companyName]="formModel.companyName"
        [individualData]="formModel.individual"
        [hiddenIndividualControls]="hiddenIndividualControls"
      ></app-app-individual-or-company>
    </section>

    <section
      class="step-section notification-type"
      [formGroupName]="StepsEnum.DetailsOfTheAccountToBeDebited"
      [hidden]="currentStep !== StepsEnum.DetailsOfTheAccountToBeDebited"
    >
      <h1 class="h1-heading">{{ steps[currentStepIndex].label }}</h1>

      <div class="form-group">
        <app-input
          label="Name and branch of financial institution"
          formControlName="bankName"
        ></app-input>
      </div>

      <div class="form-group">
        <app-bsb-masked-input
          label="BSB Number"
          formControlName="bankBSB"
        ></app-bsb-masked-input>
      </div>

      <div class="form-group">
        <app-input-number
          label="Account Number"
          formControlName="bankAccountNumber"
        ></app-input-number>
      </div>

      <div class="form-group">
        <app-input
          label="Account Name"
          formControlName="bankAccountName"
        ></app-input>
      </div>


      <div class="form-group">
        <h3 class="h3-heading">Payment Details</h3>

        <app-input-number
          label="Daily ASIC Credit Limit"
          formControlName="dailyCreditLimit"
          [min]="0"
          [max]="100000"
        ></app-input-number>
      </div>
    </section>
  </div>
</app-stepper-form>
