import { EmailStatus } from "../enums/emailStatus";

export class EmailRecord {
  emailMessageId: string;
  createdOn: string;
  modifiedOn: string;
  from: string;
  replyTo: string;
  to: string;
  cc?: string;
  bcc?: string;
  subject: string;
  status: EmailStatus;
  errorMessage?: string;
  code?: string;
  hasAttachments: boolean;

  constructor(data: Partial<EmailRecord> = {}) {
    this.emailMessageId = data.emailMessageId ?? '';
    this.createdOn = data.createdOn ?? '';
    this.modifiedOn = data.modifiedOn ?? '';
    this.from = data.from ?? '';
    this.replyTo = data.replyTo ?? '';
    this.to = data.to ?? '';
    this.cc = data.cc;
    this.bcc = data.bcc;
    this.subject = data.subject ?? '';
    this.status = data.status ?? EmailStatus.Created;
    this.errorMessage = data.errorMessage;
    this.code = data.code;
    this.hasAttachments = data.hasAttachments ?? false;
  }
}
