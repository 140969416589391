import { NgClass, NgTemplateOutlet } from '@angular/common';
import { booleanAttribute, Component, EventEmitter, inject, Input, Optional, Output } from '@angular/core';
import { NgControl } from '@angular/forms';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SecurityType } from '../../../../models/securityType';
import { DividerComponent } from '../../common/divider/divider.component';
import {
  ShareClassTypeLabelComponent
} from '../../common/grid/components/ag-share-class-type/share-class-type-label/share-class-type-label.component';
import { LinkComponent } from '../../common/link/link.component';
import { ValidationErrorComponent } from '../../common/validation-error/validation-error.component';
import {
  AddShareTypeModalComponent
} from '../share-security-holdings-dropdown/add-share-type-modal/add-share-type-modal.component';

@Component({
  selector: 'app-share-security-types-dropdown',
  standalone: true,
  imports: [
    DividerComponent,
    LinkComponent,
    NgTemplateOutlet,
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
    ShareClassTypeLabelComponent,
    ValidationErrorComponent,
    NgClass
  ],
  templateUrl: './share-security-types-dropdown.component.html',
  styleUrl: '../share-security-holdings-dropdown/share-security-holdings-dropdown.component.scss'
})
export class ShareSecurityTypesDropdownComponent {
  private modalService = inject(NgbModal);

  @Input() width = '100%';
  @Input() placeholder = 'Select Share Type';
  @Input() label = 'Share Type';
  @Input() link = '';
  @Input() text = '';
  @Input() helperText = '';
  @Input() noOptionsText = 'There are no available share types.';
  @Input({ required: true }) options: SecurityType[] = [];
  @Input({ transform: booleanAttribute }) disabled = false;
  @Input({ transform: booleanAttribute }) isDisabled = false;
  @Input({ transform: booleanAttribute }) allowAddNewShareType = true;

  @Output() select = new EventEmitter<SecurityType>();
  @Output() addShareType = new EventEmitter<SecurityType>();

  #value: SecurityType | null = null;
  dropdownOpen = false;

  constructor(@Optional() protected ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  get value(): SecurityType | null {
    return this.#value;
  }

  set value(value: SecurityType | null) {
    this.#value = value;
    this.onChange(this.#value?.securityTypeId ?? null);
  }


  get displayValidationError(): boolean {
    return !!(this.ngControl && this.ngControl.touched && this.ngControl.errors);
  }

  selectOption(selectedOption: SecurityType): void {
    this.value = selectedOption;
    this.select.emit(selectedOption);
  }

  onChange: any = (): void => {
  };
  onTouched: any = (): void => {
  };

  writeValue(value: string): void {
    this.value = this.options.find((SecurityType) => SecurityType.securityTypeId === value) ?? null;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  getDropdownState(dropdownState: boolean): void {
    this.dropdownOpen = dropdownState;
  }

  openCreateShareTypeModal(): void {
    const modalRef = this.modalService.open(
      AddShareTypeModalComponent,
      {
        size: 'sm',
        centered: true,
      }
    );

    modalRef.componentInstance.securityTypes = this.options;

    modalRef.result.then(
      (result: SecurityType) => this.addShareType.emit(result),
      () => false
    );
  }
}

