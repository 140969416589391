import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DatepickerHelper } from '../../../custom-form-validators/date-picker-form-validators';
import { Company } from '../../../models/company';
import { Document } from '../../../models/document';
import { EntityChangeData } from '../../../models/entityChangeData';
import { CompanyChangeData } from '../../../models/enums/companyChangeData';
import { SelectOption } from '../../../models/selectOptions';
import { ChangeDictionaryHelper } from '../../../models/shared/change-dictionary-helper.model';
import { ShareCancellationComponent } from '../share/share-cancellation/share-cancellation.component';
import { BaseStepperFormComponent } from '../stepper-form/base-stepper-component/base-stepper-form.component';

export class CompanyForm2560 extends EntityChangeData {
  static override readonly $type = 'CompanyForm2560';
  dateOfProposedMeeting: Date;
  section: Form2560SectionsEnum;
  isListedCompany: boolean;
  section249hAppliesForUnlistedCompany: boolean;

  constructor(data: Partial<CompanyForm2560> = {}) {
    super(data);
    this.dateOfProposedMeeting = DatepickerHelper.toDate(data.dateOfProposedMeeting);
    this.section = data.section ?? Form2560SectionsEnum.Section256C1;
    this.isListedCompany = data.isListedCompany ?? false;
    this.section249hAppliesForUnlistedCompany = data.section249hAppliesForUnlistedCompany ?? false;
  }

  override toDictionary(): { key: string; value: string }[] {
    const dict = new ChangeDictionaryHelper();

    dict
      .addDate('Date Of Proposed Meeting', this.dateOfProposedMeeting)
      .add('Section', Form2560SectionsEnum[this.section])
      .addYesNo('Is Listed Company', this.isListedCompany)
      .addYesNo('Applies ss.249H(2) applies', this.section249hAppliesForUnlistedCompany);

    return dict.dictionary;
  }

  override fillModal(modalRef: NgbModalRef, document: Document, actualCompany: Company): NgbModalRef {
    modalRef = this.fillModalWithCompany(modalRef, actualCompany, true);
    (modalRef.componentInstance as ShareCancellationComponent).companyChangeData.documentId = document.documentId;
    return modalRef;
  }

  override fillModalWithCompany(modalRef: NgbModalRef, company: Company, isEdit: boolean): NgbModalRef {
    const instance = modalRef.componentInstance as BaseStepperFormComponent<any, any>;
    instance.isEdit = isEdit;
    instance.companyChangeData = new CompanyChangeData(company);
    instance.formModel = this;

    return modalRef;
  }

  override prepareToRequest(): CompanyForm2560 {
    return {
      ...this,
      ...super.prepareToRequest(),
      dateOfProposedMeeting: DatepickerHelper.buildDateString(this.dateOfProposedMeeting as Date),
    };
  }
}

export enum Form2560SectionsEnum {
  Section256C1 = 0,
  Section256C2 = 1
}

export const Form2560SectionSectionOptions: SelectOption[] = [
  {
    label: 'Subsection 256C(1) - Shareholder approval for reduction in share capital for equal reduction',
    value: Form2560SectionsEnum.Section256C1
  },
  {
    label: 'Subsection 256C(2) - Shareholder approval for reduction in share capital for selective reduction',
    value: Form2560SectionsEnum.Section256C2
  },
];
