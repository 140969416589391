import { Relationship } from "../../../../models/relationship";

export enum FormEditAppointorSteps {
  ExistingTrusteeDetails = 0,
  ExistingAppointorDetails = 1,
  NewAppointorDetails = 2
}

export class TrustChangeAppointor {
  corporateAppointor: Relationship | null;
  corporateAppointorDirectors: Relationship[];
  individualAppointors: Relationship[];

  constructor(data: Partial<TrustChangeAppointor>) {
    this.corporateAppointor = data.corporateAppointor ?? null;
    this.corporateAppointorDirectors = data.corporateAppointorDirectors?.map(r => new Relationship(r)) ?? [];
    this.individualAppointors = data.individualAppointors?.map(r => new Relationship(r)) ?? [];
  }
}
