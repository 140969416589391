import { ChangeDetectorRef, Component, inject, Input, OnInit, signal } from '@angular/core';
import { AutocompleteComponent } from "../../../components/common/autocomplete/autocomplete.component";
import { DatePickerComponent } from "../../../components/common/date-picker/date-picker.component";
import {
    DinMaskedInputComponent
} from "../../../components/common/masked-input/specific-masked-inputs/din-masked-input/din-masked-input.component";
import { FormControl, FormGroup, ReactiveFormsModule, UntypedFormGroup, Validators } from "@angular/forms";
import { SelectComponent } from "../../../components/common/select/select.component";
import { StepperFormComponent } from "../../stepper-form/stepper-form.component";
import {
    StepperFormDescriptionComponent
} from "../../stepper-form/stepper-form-description/stepper-form-description.component";
import { TextareaComponent } from "../../../components/common/textarea/textarea.component";
import { YesNoControlComponent } from "../../../components/common/yes-no-control-component/yes-no-control.component";
import { OfficerRelationshipDetails, Relationship } from "../../../../models/relationship";
import { SelectOption } from "../../../../models/selectOptions";
import { RelationshipType } from "../../../../models/enums/relationshipTypeEnum";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { DatepickerHelper } from "../../../../custom-form-validators/date-picker-form-validators";
import { CompanyChangeOfficerType } from "../../../../models/enums/companyChangeOfficerType";
import { EntityChangeData } from "../../../../models/entityChangeData";
import { CompanyChangeOfficer } from "../../../../models/сompanyChangeOfficer";
import { Document } from "../../../../models/document";
import { IndividualData } from "../../../../models/individualData";
import { Address} from "../../../../models/address";
import { Guid } from "../../../helpers/guid.helper";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { setControlDisabled } from "../../../../functions/set-control-disabled";
import { OfficerAppointmentType } from "../../../../models/officerAppointmentForm";
import { autocompleteServiceToken } from "../../../../services/autocomplete.service";
import { AuxiliaryService } from "../../../../services/auxiliary.service";
import { BaseBulkStepperFormComponent } from "../../stepper-form/base-bulk-stepper-form/base-bulk-stepper-form.component";
import { BulkCompanyChangeOfficer } from "./BulkCompanyChangeOfficer.model";
import { DividerComponent } from "../../../components/common/divider/divider.component";
import { InputComponent } from "../../../components/common/input/input.component";
import {
  AddressControlComponent,
  AddressFormGroup
} from "../../../components/common/address-control/address-control.component";
import { CountriesService } from "../../../../services/countries.service";
import { DocumentationType } from "../../../../models/enums/documentConfirmEnums";
import { RadioComponent } from "../../../components/common/radio/radio.component";
import { Company } from "../../../../models/company";
import { ColDef, RowClassRules } from "ag-grid-community";
import {
  AgEntityNameAcnAbnComponent
} from "../../../components/common/grid/components/ag-entity-name-acn-abn/ag-entity-name-acn-abn.component";
import { ListGridComponent } from "../../../components/common/grid/components/list-grid/list-grid.component";
import { catchError, forkJoin, of } from "rxjs";
import { CompaniesService } from "../../../../services/companies.service";
import { OfficeHoldersByCompany } from "../../../../models/bulkChangeData";
import { ChangeAuthorisation } from "../../../../models/changeAuthorisation";
import {
  RelationshipFormGroup
} from "../../../components/reusable-form-groups/relationship-form-group/relationship-form-group.component";
import { BulkAddressChangeStepsEnum } from "../../../../models/enums/bulkAddressChangeStepsEnum";
import { NgClass } from "@angular/common";
import {
  AgSelectChangeNameComponent
} from "../../../components/common/grid/components/ag-select-change-name/ag-select-change-name.component";
import { CustomFormValidators } from "../../../../custom-form-validators/custom-form-validators";
import { directorFullNameValidatorMsg } from "../../../../validators/validatorMessages/custom-form-validators-messages";
import { positionTypesOptions } from '../../officer-appointment/officer-appointment.component';
import { ParsedName } from "../../../../models/parsedName";
import { parseFullName } from "../../../../functions/parse-fullname";

enum BulkOfficeholderAppointmentStepsEnum {
  FormDescription = 0,
  AppointOfficer = 1,
  Summary = 2,
}

interface OfficerAppointmentControls {
  changeDate: FormControl<Date | null>;
  isNewOfficeholder: FormControl<boolean | null>;
  appointedOfficerId: FormControl<string | null>;
  type: FormControl<RelationshipType | OfficerAppointmentType | null>;
  din: FormControl<string | null>;
  newOfficeholder: RelationshipFormGroup;
  fullName: FormControl<string | null>;
  formerFullName: FormControl<string | null>;
  formerName: FormControl<ParsedName | null>;
  address: AddressFormGroup;
  dob: FormControl<Date | null>;
  birthCity: FormControl<string | null>;
  birthCountry: FormControl<string | null>;
  changeDateError: FormControl<boolean | null>;
}

type OfficerAppointmentFormGroup = FormGroup<OfficerAppointmentControls>;

interface AppliedChanges {
  name: string,
  acn: string,
  fullName: string,
  entityId: string;
  selectedOption: SelectOption;
  options: SelectOption[];
  error: string,
}

@Component({
  selector: 'app-bulk-appointment-officeholder',
  standalone: true,
  imports: [
    AutocompleteComponent,
    DatePickerComponent,
    DinMaskedInputComponent,
    ReactiveFormsModule,
    SelectComponent,
    StepperFormComponent,
    StepperFormDescriptionComponent,
    TextareaComponent,
    YesNoControlComponent,
    DividerComponent,
    InputComponent,
    AddressControlComponent,
    RadioComponent,
    ListGridComponent,
    NgClass
  ],
  templateUrl: './bulk-appointment-officeholder.component.html',
  styleUrls: ['bulk-appointment-officeholder.component.scss', '../../stepper-form/base-bulk-stepper-form/base-bulk-stepper-form.component.scss'],
  providers: [{ provide: autocompleteServiceToken, useClass: AuxiliaryService }],
})
export class BulkAppointmentOfficeholderComponent extends BaseBulkStepperFormComponent<BulkOfficeholderAppointmentStepsEnum, BulkCompanyChangeOfficer> implements OnInit {
  cdr = inject(ChangeDetectorRef);
  countriesService = inject(CountriesService);
  companiesService = inject(CompaniesService);

  @Input() officers: Record<string, Relationship[]> = {};
  @Input() officersSelectOptions: SelectOption[] = [];
  @Input() allDirectorsOptions: SelectOption[] = [];
  @Input() individualData?: IndividualData | null;
  @Input() useInternationalAddresses = false;

  individualDataFormLabels = {
    fullName: 'Full Name',
    formerName: 'Former Name (if any)',
    dob: 'Date of Birth',
    birthCity: 'City of Birth',
    birthCountry: 'Country of Birth',
    address: 'Address',
  };

  public rowClassRules: RowClassRules = {
    'error-row-bg': (params: { data: { error: string }}) => !!params.data.error,
  };

  documentTypeOptions: SelectOption[] = [
    { label: 'Minutes', value: DocumentationType.Minutes, disabled: true },
    { label: 'Resolution', value: DocumentationType.Resolution, disabled: true },
    { label: 'ASIC Form only', value: DocumentationType.FormOnly, disabled: false },
  ];

  generateDocumentsInfo = 'If you need to create a Resolution or Minutes, you must open and edit the forms individually.';

  colDefs: ColDef[] = [
    {
      headerName: 'Entity Name',
      field: 'name',
      sort: 'asc',
      flex: 0.6,
      cellRenderer: AgEntityNameAcnAbnComponent,
      menuTabs: []
    },
    {
      headerName: 'Authorising Officeholder',
      field: 'fullName',
      flex: 1,
      cellRenderer: AgSelectChangeNameComponent,
      cellRendererParams: {
        selectName: this.selectName.bind(this),
      },
      menuTabs: []
    },
  ];
  private readonly colDefsWithErrorLength = 3;

  rowHasError = false;
  rows: AppliedChanges[] = [];

  protected readonly directorFullNameValidatorMsg = directorFullNameValidatorMsg;
  override readonly StepsEnum = BulkOfficeholderAppointmentStepsEnum;
  readonly OfficerAppointmentType = OfficerAppointmentType;
  relationships: Relationship[] = [];
  officeHoldersByCompany: OfficeHoldersByCompany[] = [];
  changeAuthorisationByCompany: { companyId: string, changeAuthorisation: ChangeAuthorisation }[] = [];
  positionTypesOptions = positionTypesOptions;

  form = new UntypedFormGroup({
    changeDate: new FormControl<Date | null>(null),
    type: new FormControl<RelationshipType | null>(null, [Validators.required]),
    din: new FormControl<string | null>(null),
    fullName: new FormControl<string | null>(null, [Validators.required, CustomFormValidators.directorFullNameValidator]),
    formerFullName: new FormControl<string | null>(null, [CustomFormValidators.directorFullNameValidator]),
    formerName: new FormControl<ParsedName | null>(null),
    address: new FormControl<Address | null>(null),
    dob: new FormControl<Date | null>(null),
    birthCity: new FormControl<string | null>(null),
    birthCountry: new FormControl<string | null>(null, [Validators.required]),
    documentationType: new FormControl<DocumentationType>(DocumentationType.FormOnly, [Validators.required]),
    changeDateError: new FormControl<boolean | null>(false, [Validators.required])
  }) as OfficerAppointmentFormGroup;
  override stepperForm = new FormGroup({
    [BulkOfficeholderAppointmentStepsEnum.FormDescription]: new FormGroup({}),
    [BulkOfficeholderAppointmentStepsEnum.AppointOfficer]: this.form,
    [BulkOfficeholderAppointmentStepsEnum.Summary]: this.form,
  });

  selectedPositionSignal = signal<OfficerAppointmentType | null>(null);

  minDate!: NgbDateStruct;
  maxDate!: NgbDateStruct;
  minDobDate: NgbDateStruct = DatepickerHelper.getDefaultMinDateStruct();
  maxDobDate: NgbDateStruct = DatepickerHelper.getMatureDateStruct();
  countryOptions = this.countriesService.getCountriesAndDivisions();
  expandedAddressForm = false;
  companiesList: Partial<Company>[] = [];

  constructor() {
    super();

    this.setupSteps(BulkOfficeholderAppointmentStepsEnum);
  }

  ngOnInit(): void {
    this.minDate = DatepickerHelper.getDefaultMinDateStruct();
    this.maxDate = DatepickerHelper.getTodayStruct();
    this.formModel.actionType = CompanyChangeOfficerType.Appointment;
    this.form.controls.changeDate.setValue(DatepickerHelper.getToday());

    this.listenPositionChanges();
    this.listenFullNameChanges();
    this.listenChangeDate();
    this.listenBirthCountry();
    this.setupChange();

    this.getOfficeHoldersByCompany();
  }

  override afterSubmit(changes: EntityChangeData[]) {
    this.setupChange(changes[0] as BulkCompanyChangeOfficer);
  }

  override setupChange(change: BulkCompanyChangeOfficer = this.formModel): void {
    if (!this.isEdit && change === this.formModel) return;

    this.companiesList = change.companiesList;
    this.companiesList.forEach(company => {
      this.rows.push({
        name: company.name ?? '',
        fullName: '',
        acn: company.acn ?? '',
        entityId: company.entityId ?? '',
        selectedOption: { label: '', value: '' },
        options: [],
        error: ''
      });
    });
  }

  override buildDocument(): Document[] | null {
    const formValue = this.form.value;
    const change = new CompanyChangeOfficer({ changeDate: formValue.changeDate! });
    const names = parseFullName(formValue?.fullName ?? '');
    const formerName = parseFullName(formValue?.formerFullName ?? '') as ParsedName;

    const newOfficerIndividualData = new IndividualData({
      ...names,
      formerName,
      dob: formValue?.dob ?? undefined,
      address: new Address({
        ...formValue?.address,
        normalizedFullAddress: formValue.address?.normalizedFullAddress,
      } as Address),
      birthCity: formValue.birthCity ?? '',
      birthCountry: CountriesService.getCountryName(formValue.birthCountry ?? ''),
    });

    return this.changeAuthorisationByCompany.map(item => {
      const newOfficer = new Relationship({
        relationshipId: Guid.generate(),
        start: formValue.changeDate!,
        entityId: item.companyId,
        organisationId: item.changeAuthorisation.authorisingParty.organisationId,
        individualDataOverride: newOfficerIndividualData,
        type: formValue.type as RelationshipType
      });

      change.offices[0] = newOfficer;

      change.offices = change.offices.map(relationship => {
        if (formValue.type && formValue.type !== OfficerAppointmentType.Secretary && relationship.individualDataOverride) {
          relationship.individualDataOverride.din = formValue.din ?? '';
        }
        if (formValue.type === OfficerAppointmentType.AlternativeDirector) {
          relationship.details = new OfficerRelationshipDetails();
        }

        return relationship;
      });

      delete item.changeAuthorisation.authorisingParty.entity;

      return new Document({
        changes: [change],
        changeAuthorisation: item.changeAuthorisation,
        entityId: item.companyId,
        type: 'c:484',
        documentId: this.companyChangeData?.documentId,
        documentationType: this.form.get('documentationType')?.value
      });
    });
  }

  listenPositionChanges(): void {
    this.form.controls.type.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((type) => {
        setControlDisabled(this.form.controls.din, type === null || type === OfficerAppointmentType.Secretary);
        this.selectedPositionSignal.set(type as OfficerAppointmentType ?? null);

        this.form.updateValueAndValidity();
        this.cdr.detectChanges();
      });
  }

  listenFullNameChanges(): void {
    this.form.get('fullName')?.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(fullName => {
      if(fullName?.length) {
        this.rows.forEach(row => row.fullName = fullName);
        this.rows = [...this.rows];
      }
    });
  }

  private getOfficeHoldersByCompany(): void {
    const getOfficeHolders = this.companiesList
      .map(company => this.companiesService.officeHolders(company.entityId ?? ''));

    forkJoin(getOfficeHolders)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        catchError(error => {
          console.error('Error getting office holders:', error);
          return of([] as { isSigningContact: boolean, relationship: Relationship }[][]);
        })
      )
      .subscribe(officeHolders => {
        if(officeHolders.length) {
          this.officeHoldersByCompany = officeHolders.map((item) => {
            return ({ companyId: item[0].relationship.entityId, officeHolders: item });
          });

          this.changeAuthorisationByCompany = this.officeHoldersByCompany.map((officeHolder, index) => {
            const changeAuthorisation = new ChangeAuthorisation();

            const relationshipOfficeHolders = officeHolder.officeHolders.map(item => item.relationship);
            const signingContact = officeHolder.officeHolders.find(item => item.isSigningContact)?.relationship;
            const directors = relationshipOfficeHolders.filter(o => o.type === RelationshipType.Director);
            const authOfficeHolder = directors[0];
            changeAuthorisation.authorisingParty = signingContact ? signingContact : authOfficeHolder;
            changeAuthorisation.signingManager = changeAuthorisation.authorisingParty.relationshipId;
            changeAuthorisation.authorisingParty.entityId = relationshipOfficeHolders[0].entityId;
            changeAuthorisation.documentationType = DocumentationType.FormOnly;

            const changeDate = this.form.get('changeDate')?.value as unknown as Date;
            if(relationshipOfficeHolders[0].entity?.dateOfEstablishment) {
              this.rows[index].error = changeDate <= new Date(relationshipOfficeHolders[0].entity?.dateOfEstablishment)
                ? 'Date of change cannot be earlier or same as the company registration date'
                : '';
            }

            this.rows[index].fullName = changeAuthorisation.authorisingParty.individualDataOverride?.fullName ?? '';
            this.rows[index].selectedOption = { label: this.rows[index].fullName ?? '', value: changeAuthorisation.authorisingParty.relationshipId };
            this.rows[index].options = directors.map(director => ({ label: director.individualDataOverride?.fullName ?? '', value: director.relationshipId }) );

            return { companyId: relationshipOfficeHolders[0].entityId, changeAuthorisation };
          });
        }
      });
  }

  selectName(data: {  entityId: string, selectedId: string }): void {
    let selectedOfficeholder: Relationship | undefined;
    this.officeHoldersByCompany.forEach(item => {
      if(item.companyId === data.entityId) {
        selectedOfficeholder = item.officeHolders.find(officeholder => {
          return officeholder.relationship.relationshipId === data.selectedId
        })?.relationship;
      }
    });

    this.changeAuthorisationByCompany.forEach(c => {
      if(c.companyId === data.entityId) {
        if(selectedOfficeholder) {
          c.changeAuthorisation.authorisingParty = selectedOfficeholder;
        }

        c.changeAuthorisation.signingManager = data.selectedId;
      }
    });
  }

  onStepChange(stepIndex: BulkAddressChangeStepsEnum): void {
    if(stepIndex === BulkAddressChangeStepsEnum.Summary) {
      if(this.form.get('changeDateError')?.value) {
        this.form.get('changeDateError')?.patchValue(null);
      }
    }
  }

  private addErrorToRows(): void {
    this.rowHasError = this.rows.some(row => row.error);

    if (this.rowHasError && !this.colDefs.some(c => c.field === 'error')) {
      this.colDefs.push({
        headerName: 'Error',
        field: 'error',
        flex: 1.2,
        cellRenderer: (params: { value: string }) => {
          const error = params.value;
          if (error) {
            return `<div style="color: #CE5252; line-height: 2px; padding-top: 8px;">${error.slice(0, Math.floor(error.length / 2))}</div>
                    <div style="color: #CE5252;">${error.slice(Math.floor(error.length / 2), error.length)}</div>`;
          } else {
            return `<div>${error}</div>`;
          }
        },
        menuTabs: []
      });
    } else if(!this.rowHasError && this.colDefs.length === this.colDefsWithErrorLength) {
      this.colDefs.pop();
    }

    this.colDefs = [...this.colDefs];

    if(this.rows.some(row => row.error)) {
      this.form.get('changeDateError')?.setValue(true);
    } else {
      this.form.get('changeDateError')?.setValue(false);
    }
  }

  private listenChangeDate(): void {
    this.form.controls.changeDate.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(changeDate => {
        if(changeDate) {
          this.officeHoldersByCompany.map((officeHolder, index) => {
            const relationshipOfficeHolders = officeHolder.officeHolders.map(item => item.relationship);

            if(relationshipOfficeHolders[0].entity?.dateOfEstablishment) {
              this.rows[index].error = changeDate <= new Date(relationshipOfficeHolders[0].entity?.dateOfEstablishment)
                ? 'Date of change cannot be earlier or same as the company registration date'
                : '';
            }
          });

          this.rows = [...this.rows];
          this.addErrorToRows();
        }
      });
  }

  private listenBirthCountry(): void {
    this.form.controls.birthCountry.valueChanges.subscribe(birthCountry => {
      if (birthCountry?.startsWith('AU')) {
        this.form.controls.birthCity.setValidators([Validators.required]);
      } else {
        this.form.controls.birthCity.setValidators([]);
      }
      this.form.controls.birthCity.updateValueAndValidity();
    });
  }
}
