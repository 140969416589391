import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DatepickerHelper } from '../../../../../custom-form-validators/date-picker-form-validators';
import { Document } from '../../../../../models/document';
import {
  asicDocumentNumberValidators,
  documentNumberCustomError
} from '../../../../../validators/document-number.validator';
import { BaseStepperFormComponent } from '../../../stepper-form/base-stepper-component/base-stepper-form.component';
import { CompanyMistakeCorrection, CompanyMistakeCorrectionType } from './request-correction-change.model';
import { StepperFormComponent } from "../../../stepper-form/stepper-form.component";
import {
  StepperFormDescriptionComponent
} from "../../../stepper-form/stepper-form-description/stepper-form-description.component";
import { InputComponent } from "../../../../components/common/input/input.component";
import { DatePickerComponent } from "../../../../components/common/date-picker/date-picker.component";
import { DisclaimerComponent } from "../../../../components/common/disclaimer/disclaimer.component";
import { TextareaComponent } from "../../../../components/common/textarea/textarea.component";
import { AutocompleteComponent } from "../../../../components/common/autocomplete/autocomplete.component";
import { allowedFormCodes, allowedFormCodesLabels, allowedFormCodesOptions } from "./form-codes.constant";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

export enum RequestForCorrectionStepsEnum {
  FormDescription = 0,
  RequestForCorrection = 1,
}

@Component({
  selector: 'app-form492-request-correction',
  standalone: true,
  templateUrl: './form492-request-correction.component.html',
  styleUrl: '../../../stepper-form/base-stepper-component/base-stepper-form.component.scss',
  imports: [
    StepperFormComponent,
    ReactiveFormsModule,
    StepperFormDescriptionComponent,
    InputComponent,
    DatePickerComponent,
    DisclaimerComponent,
    TextareaComponent,
    AutocompleteComponent
  ]
})
export class Form492RequestCorrectionComponent extends BaseStepperFormComponent<RequestForCorrectionStepsEnum, CompanyMistakeCorrection> implements OnInit {
  @Input() documentId = '';
  @Input() header: 'Request for Correction' | 'Notification of Supplementary Information' = 'Request for Correction';
  @Input() formNumber: '492' | '902' = '492';

  override readonly StepsEnum = RequestForCorrectionStepsEnum;
  readonly allowedFormCodesOptions = allowedFormCodesOptions;
  readonly documentNumberCustomError = documentNumberCustomError;

  minDate!: NgbDateStruct;
  maxDate!: NgbDateStruct;

  form = new FormGroup({
    documentNumber: new FormControl<string | null>(null, asicDocumentNumberValidators()),
    formCode: new FormControl<string | null>(null, [Validators.required]),
    lodgementDate: new FormControl<Date | null>(null, [Validators.required]),
    documentTitle: new FormControl<string | null>(null, [Validators.required]),
    correctionDetails: new FormControl<string | null>(null, [Validators.required]),
  });

  override stepperForm = new FormGroup({
    [RequestForCorrectionStepsEnum.FormDescription]: new FormGroup({}),
    [RequestForCorrectionStepsEnum.RequestForCorrection]: this.form
  });

  constructor() {
    super();
    this.setupSteps(RequestForCorrectionStepsEnum);
    this.redirectAfterSubmit = true;
  }

  ngOnInit(): void {
    this.minDate = DatepickerHelper.getDateOfEstablishmentMinDate(this.companyChangeData.dateOfEstablishment);
    this.maxDate = DatepickerHelper.getNextNYearsStruct(10);

    if (this.documentId)
      this.form.controls.documentNumber.setValue(this.documentId);

    this.header = this.formModel.correctionType === CompanyMistakeCorrectionType.F492 ? 'Request for Correction' : 'Notification of Supplementary Information';
    this.formNumber = this.formModel.correctionType === CompanyMistakeCorrectionType.F492 ? '492' : '902';

    this.setupChange();
    this.listenFormCodeChange();
  }

  override setupChange(): void {
    if (!this.isEdit)
      return;

    this.form.patchValue(this.formModel);
  }

  override buildDocument(): Document | null {
    const requestCorrectionChange = new CompanyMistakeCorrection({
      ...this.form.value as Partial<CompanyMistakeCorrection>,
      correctionType: this.formModel.correctionType
    });

    try {
      return new Document({
        changes: [requestCorrectionChange],
        entityId: this.companyChangeData?.entityId,
        type: `c:${ this.formNumber }`,
        documentId: this.companyChangeData?.documentId,
      });
    } catch (error) {
      console.warn(error);
      this.toastr.error('Failed to create Document.', 'Error');
      return null;
    }
  }

  private listenFormCodeChange(): void {
    this.form.controls.formCode.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((formCode) => {
        if (formCode && allowedFormCodesLabels[formCode]) {
          this.form.controls.documentTitle.setValue(allowedFormCodesLabels[formCode]);
        }
      });
  }

  get showForm201AlertMessage(): boolean {
    return !!this.form.controls.formCode.value && this.form.controls.formCode.value.includes('201');
  }

  get pickedFormCodeNotFromList(): string {
    if (this.form.controls.formCode.value && !allowedFormCodes.includes(this.form.controls.formCode.value)) {
      return `This form code may not be eligible for correction using Form ${this.formNumber}. Please verify with ASIC to ensure the correct process is followed for your specific form.`;
    }
    return '';
  }

  get alertMessageIfFormCode201(): string {
    return `Please note that Form ${ this.formNumber } cannot be lodged electronically if the correction relates to Form 201. In such cases, you must print and mail the completed Form ${ this.formNumber } to ASIC for processing.`;
  }
}
