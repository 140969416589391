export function filterUniqueElements<T>(key: keyof T, array: T[]): T[] {
  const elementsKeysValue = new Set<unknown>();

  return array.filter((element: T) => {
    if (elementsKeysValue.has(element[key])) {
      return false;
    }

    elementsKeysValue.add(element[key]);
    return true;
  });
}
