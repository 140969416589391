<app-common-modal-form
  header="Edit Organisation Details"
  confirmText="Save"
  closeText="Cancel"
  [isLoading]="isLoading"
  [formGroup]="form"
  (confirm)="confirm()"
  (close)="close()"
>
  @if (organisationSetupRequired) {
    <app-disclaimer icon="ic-alert-triangle">Please fill in Organisation details to proceed.</app-disclaimer>
  }

  <div class="two-piece-input-wrapper">
    <app-input
      label="Account Name"
      formControlName="name"
    />

    <app-input-phone-number
      formControlName="phone"
    />
  </div>

  <div class="form-group">
    <app-input
      label="Email Address"
      formControlName="email"
    />
  </div>

  <div class="form-group">
    <app-address-form-group [formGroup]="form.controls.address"/>
  </div>

  <div class="form-group">
    <app-input
      label="Website"
      formControlName="website"
      [customErrors]="urlPatternValidatorCustomError"
    />
  </div>

  <app-divider margin="24px 0"/>

  <div class="two-piece-input-wrapper">
    <app-select
      label="Primary Contact"
      formControlName="primaryContactId"
      [options]="accountsSelectOptions"
      [valuePrimitive]="true"
    />

    <app-select
      label="Billing Contact"
      formControlName="billingContactId"
      [options]="accountsSelectOptions"
      [valuePrimitive]="true"
    />
  </div>
</app-common-modal-form>
