import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  signal,
  WritableSignal
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { TextareaComponent } from "../../../../../components/common/textarea/textarea.component";
import { CustomFormValidators } from "../../../../../../custom-form-validators/custom-form-validators";
import { ButtonComponent } from "../../../../../components/common/button/button.component";
import {
  LoaderStandaloneComponent
} from "../../../../../components/common/loader-standalone/loader-standalone.component";
import { DividerComponent } from "../../../../../components/common/divider/divider.component";
import { TextMessageTemplate } from "../../../../../../models/text-messages/textMessageTemplate";
import { TemplateCodePreview, TextMessagesHelper } from "../../../../../../models/text-messages/textMessagesHelper";
import {
  TextMessagesTemplateSettingsBase
} from "../../../../../../models/text-messages/textMessagesTemplateSettingsBase";
import {
  AgActionIconButtonComponent
} from "../../../../../components/common/grid/components/ag-action-icon-button/ag-action-icon-button.component";
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle } from "@ng-bootstrap/ng-bootstrap";
import { AppCopyDirective } from "../../../../../../directives/copy.directive";
import { TemplatesLegendTableComponent } from "../sms-templates-keys-table/templates-legend-table.component";
import { setControlDisabled } from "../../../../../../functions/set-control-disabled";
import { TextMessagePayloadBase } from "../../../../../../models/text-messages/payload/TextMessagePayloadBase";

export enum TextMessagesTemplateComponentState {
  Preview = 0,
  Edit = 1
}

@Component({
  selector: 'app-edit-text-message-template',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TextareaComponent,
    ButtonComponent,
    LoaderStandaloneComponent,
    DividerComponent,
    AgActionIconButtonComponent,
    NgbDropdown,
    NgbDropdownMenu,
    NgbDropdownToggle,
    AppCopyDirective,
    TemplatesLegendTableComponent,
  ],
  templateUrl: './edit-text-message-template.component.html',
  styleUrl: './edit-text-message-template.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTextMessageTemplateComponent implements OnInit {
  @Input() textMessageTemplate!: TextMessageTemplate;
  @Input() textMessageDefaultTemplatesRecord: Record<string, TextMessagesTemplateSettingsBase> = {};
  @Input('isLoading') set isLoadingSetter(isLoading: boolean) {
    this.setIsLoading(isLoading);
  }

  @Output() save = new EventEmitter<TextMessageTemplate>();
  @Output() testTextMessageTemplate = new EventEmitter<TextMessageTemplate>();
  @Output() showErrorMessage = new EventEmitter<string[]>();
  @Output() startEditing = new EventEmitter<void>();

  readonly TextMessagesTemplateComponentState = TextMessagesTemplateComponentState;
  readonly headers = TextMessagesHelper.TemplateHeadersRecord;
  readonly customErrors = { maxLength: "You've exceeded the 1000 character limit." };

  isLoading = false;
  currentState: WritableSignal<TextMessagesTemplateComponentState> = signal(TextMessagesTemplateComponentState.Preview);
  templateControl = new FormControl<string | null>(null, [CustomFormValidators.maxLength(1000)]);
  legendPreview: TemplateCodePreview = [];
  templatePayload!: TextMessagePayloadBase;
  keysMatchErrorMessage = '';

  ngOnInit(): void {
    this.templateControl.reset(this.textMessageTemplate.template);
    this.templatePayload = TextMessagesHelper.buildTextMessageTemplatePayload(this.textMessageTemplate.code);
    this.legendPreview = TextMessagesHelper.buildPreview(this.templatePayload);
  }

  onStartEditing(): void {
    if (this.currentState() === TextMessagesTemplateComponentState.Edit) return;

    this.templateControl.setValue(this.textMessageTemplate.template);
    this.currentState.set(TextMessagesTemplateComponentState.Edit);
    this.startEditing.emit();
  }

  cancelEditing(): void {
    this.currentState.set(TextMessagesTemplateComponentState.Preview);
    this.templateControl.reset(this.textMessageTemplate.template);
  }

  onSave(): void {
    if (this.isLoading) return;
    const textMessagePayload = this.createTextMessageTemplatePayload();
    if (!textMessagePayload)
      return;

    if (!this.keysMatch())
      return;

    if (textMessagePayload.template === this.textMessageTemplate.template) {
      this.cancelEditing();
      return;
    }

    this.setIsLoading();

    this.save.emit(textMessagePayload);
    this.currentState.set(TextMessagesTemplateComponentState.Preview);
    this.keysMatchErrorMessage = '';
  }

  onTestTextMessageTemplate(): void {
    if (this.isLoading) return;
    const textMessageTemplatePayload = this.createTextMessageTemplatePayload();
    if (!textMessageTemplatePayload)
      return;
    this.testTextMessageTemplate.emit(textMessageTemplatePayload);
  }

  resetTemplateToDefault(): void {
    if (this.isLoading) return;
    const defaultTemplate = this.textMessageDefaultTemplatesRecord[TextMessagesHelper.TextMessageCodeToType[this.textMessageTemplate.code]];
    this.templateControl.patchValue(defaultTemplate.defaultTemplate ?? '');
    this.keysMatchErrorMessage = '';
  }

  private keysMatch(): boolean {
    const text = this.templateControl.value ?? '';
    const obj = this.templatePayload;
    const regex = /{{(.*?)}}/g; // Регулярное выражение для поиска шаблонов
    const matches = text.matchAll(regex);
    const nonMatchingKeys: string[] = [];

    for (const match of matches) {
      const keyFromText = match[1];
      const matchPascalCase = keyFromText.charAt(0) === keyFromText.charAt(0).toUpperCase();
      const keyInCamelCase = keyFromText.charAt(0).toLowerCase() + keyFromText.slice(1);

      if (!(matchPascalCase && keyInCamelCase in obj)) {
        nonMatchingKeys.push(keyFromText);
      }
    }

    if (nonMatchingKeys.length) {
      this.keysMatchErrorMessage = `Keys <b>${ nonMatchingKeys.map((key) => `{{${ key }}}`).join(', ') }</b> can't be used in this template.`;
      return false;
    } else {
      this.keysMatchErrorMessage = '';
      return true;
    }
  }

  private createTextMessageTemplatePayload(): TextMessageTemplate | null {
    if (!this.templateControl.value) {
      this.showErrorMessage.emit(['Please add template text', 'Error']);
      return null;
    }

    if (this.templateControl.invalid) {
      this.templateControl.markAsTouched();
      return null;
    }

    return new TextMessageTemplate({ ...this.textMessageTemplate, template: this.templateControl.value ?? '' });
  }

  private setIsLoading(isLoading = true): void {
    this.isLoading = isLoading;
    setControlDisabled(this.templateControl, isLoading);
  }
}
