import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { map, Observable, tap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { downloadBase64File } from '../../../../functions/download-base64-file';
import { FileCreateDto } from '../../../../models/files';
import { Note, NoteRecord } from '../../../../models/note';
import { PageResult } from '../../../../models/pageResult';

@Injectable({
  providedIn: 'root'
})
export class NotesService {
  constructor(
    private http: HttpClient,
    private toastr: ToastrService
  ) {
  }

  getNotes(entityIdOrIndividualId : string, startRow = 0, endRow = 10): Observable<PageResult<Note>> {
    const options = { params: { startRow, endRow } };
    return this.http.get<PageResult<Note>>(`${ environment.api_url }/notes/${ entityIdOrIndividualId  }/list`, options)
      .pipe(map((response) => {
        response.records = response.records.map((note) => new Note(note));
        return response;
      }));
  }

  getNotesTotal(entityId: string): Observable<number> {
    return this.getNotes(entityId, 0, 0)
      .pipe(map(response => response.total ?? 0));
  }

  createEditNote(note: NoteRecord): Observable<Note> {
    return this.http.post<Note>(`${ environment.api_url }/notes`, note);
  }

  downloadNoteFile(noteId: string, filename: string): Observable<string> {
    const options = { params: { filename } };
    return this.http.get<string>(`${ environment.api_url }/notes/${ noteId }/download`, options)
      .pipe(tap({
        next: (res) => downloadBase64File(res, filename),
        error: (error) => {
          console.warn('[NotesService] downloadNoteFile: ', error);
          this.toastr.error(`Failed to download file "${ filename }"`);
        }
      }));
  }

  deleteNoteFile(noteId: string, filename: string): Observable<void> {
    const options = { params: { filename } };
    return this.http.delete<void>(`${ environment.api_url }/notes/${ noteId }/attachment`, options);
  }

  uploadNoteFile(noteId: string, file: FileCreateDto): Observable<void> {
    return this.http.post<void>(`${ environment.api_url }/notes/${ noteId }/attachment`, file);
  }

  deleteNote(noteId: string): Observable<void> {
    return this.http.delete<void>(`${ environment.api_url }/notes/${ noteId }`);
  }
}
