import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { IndividualData } from '../../../../../../../models/individualData';
import CompanyNameAcnComponent from '../../../../../../components/common/company-name-acn/company-name-acn.component';
import { SelectComponent } from '../../../../../../components/common/select/select.component';
import {
  isAgentIndividualOptions
} from '../../ra01-register-cease-change-agent/register-cease-change-agent-form.model';
import {
  IndividualDataFormGroup,
  IndividualDataFormGroupComponent, IndividualDataFormGroupControls
} from "../../../../../../components/reusable-form-groups/individual-data-form-group/individual-data-form-group.component";
import {
  CompanyNameAcnFormGroup,
  CompanyNameAcnFormGroupComponent
} from "../../../../../../components/reusable-form-groups/company-name-acn-form-group/company-name-acn-form-group.component";
import {
  ReusableFormGroupComponent
} from "../../../../../../components/reusable-form-groups/reusable-form-group/reusable-form-group.component";

export interface IndividualOrCompanyFormControls {
  isIndividual: FormControl<boolean | null>,
  individual: IndividualDataFormGroup,
  company: CompanyNameAcnFormGroup,
}

export type IndividualOrCompanyFormGroup = FormGroup<IndividualOrCompanyFormControls>;

@Component({
  selector: 'app-app-individual-or-company',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CompanyNameAcnComponent,
    SelectComponent,
    IndividualDataFormGroupComponent,
    CompanyNameAcnFormGroupComponent,
  ],
  templateUrl: './app-individual-or-company.component.html',
})
export class AppIndividualOrCompanyComponent extends ReusableFormGroupComponent<IndividualOrCompanyFormGroup> implements OnInit {
  destroyRef = inject(DestroyRef);
  cdr = inject(ChangeDetectorRef);

  @Input() controlName = 'individualOrCompany';
  @Input() individualData?: IndividualData | null = null;
  @Input() companyName?: string | null = null;
  @Input() acn?: string | null = null;
  @Input() hiddenIndividualControls: (keyof IndividualDataFormGroupControls)[] = [];

  readonly isAgentIndividualOptions = isAgentIndividualOptions;

  override ngOnInit(): void {
    super.ngOnInit();
    this.listenIsIndividualChanges();
    const isIndividualSelected = Boolean(this.individualData);
    const isCompanySelected = Boolean(this.companyName && this.acn);
    const isIndividual = isIndividualSelected
      ? true
      : isCompanySelected
        ? false
        : null;
    this.form.controls.isIndividual.setValue(isIndividual);
    this.updateCompanyOrIndividual(isIndividual);
  }

  private listenIsIndividualChanges(): void {
    this.form.controls.isIndividual.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isIndividual) => this.updateCompanyOrIndividual(isIndividual));
  }

  private updateCompanyOrIndividual(isIndividual: boolean | null): void {
    if (isIndividual === true) {
      this.form.controls.company.disable();
      this.form.controls.individual.enable();

      this.hiddenIndividualControls.forEach((key) => {
        this.form.controls.individual.controls[key].disable();
      });
    } else if (isIndividual === false) {
      this.form.controls.individual.disable();
      this.form.controls.company.enable();
    }

    this.cdr.detectChanges();
  }

  static override defineForm(): IndividualOrCompanyFormGroup {
    return new FormGroup({
      isIndividual: new FormControl<boolean | null>(null, [Validators.required]),
      individual: IndividualDataFormGroupComponent.defineForm(), // IndividualData without address
      company: CompanyNameAcnFormGroupComponent.defineForm(),    // ACN and company name
    });
  }
}
