import { Pipe, PipeTransform } from '@angular/core';
import { RelationshipType, RelationshipTypeLabels } from '../../models/enums/relationshipTypeEnum';

@Pipe({
  standalone: true,
  name: 'relationshipType'
})
export class RelationshipTypePipe implements PipeTransform {
  transform(value?: RelationshipType): string {
    if (value != undefined)
      return RelationshipTypeLabels[value];

    return '';
  }
}
