import { Component } from '@angular/core';
import { ICellRendererParams } from "ag-grid-community";
import { CorporateHolderModel, IndividualHolderModel } from "../../../../../../../models/securityRegistryRecord";
import { NgClass } from "@angular/common";

@Component({
  selector: 'app-ag-share-holder',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './ag-share-holder.component.html',
  styleUrl: './ag-share-holder.component.scss'
})
export class AgShareHolderComponent {
  shareHolder!: (IndividualHolderModel | CorporateHolderModel);

  agInit(params: ICellRendererParams<(IndividualHolderModel | CorporateHolderModel), (IndividualHolderModel | CorporateHolderModel)>): void {
    if (params.value !== null && params.value !== undefined) {
      this.shareHolder = params.value;
    }
  }

  refresh(params: ICellRendererParams<(IndividualHolderModel | CorporateHolderModel), (IndividualHolderModel | CorporateHolderModel)>): boolean {
    if (params.value !== null && params.value !== undefined) {
      this.shareHolder = params.value;
    }

    return true;
  }
}
