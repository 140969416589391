@if (documentsSelection.length) {
  <div class="documents-container">
    <div>{{title}}</div>
    <div [dragula]="groupName" [(dragulaModel)]="documentsSelection">
      @for (documentSelection of documentsSelection; track documentSelection.fileId) {
        @if(isDocumentVisible(documentSelection, documentationType)) {
          <div
            class="d-flex align-items-center mb-2"
            [ngClass]="(dragulaService.drag(groupName) | async) ? 'cursor-move' : 'cursor-grab'"
          >
              <span class="icon ic ic-drag-and-drop ic-tiny"></span>
              <app-checkbox
                class="mr-2"
                [disabled]="documentSelection.mandatory"
                [value]="documentSelection.included || documentSelection.mandatory"
                (change)="onCheckboxChange($event, documentSelection)"
              ></app-checkbox>
              <div>{{documentSelection.fileName}}</div>
          </div>
        }
      }
    </div>
  </div>
}
