import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function acnValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if (!value) {
            return null;
        }

        let valueStr = '';
        if (value instanceof String) {
            valueStr = value.toString();
        }
        else {
            valueStr = value;
        }

        valueStr = valueStr.replace(/[^0-9]/g, ""); // Remove whitespace


        // Check if the value contains only numbers
        if (valueStr.length != 9 || isNaN(parseInt(valueStr)))
            return { acn: true };

        const weighting = [8, 7, 6, 5, 4, 3, 2, 1];
        let weighted = 0;
        for (let i = 0; i < weighting.length; i++) {
            weighted += (parseInt(valueStr.charAt(i)) * weighting[i]);
        }
        let checkDigit = 10 - (weighted % 10);
        checkDigit = checkDigit === 10 ? 0 : checkDigit;
        const isValid = checkDigit === parseInt(valueStr[8], 10);
        return isValid ? null : { acn: true };
    };
}
