<div
  class="dept-tag"
  [ngClass]="{
    'plus': (amountOwing) && amountOwing < 0,
    'minus': (amountOwing) && amountOwing > 0,
    'zero': amountOwing === 0
  }"
>
  <span>{{ amountOwing | currency : "$" : 'code' : '1.2-2' | replaceStr: '-':'CR ' }}</span>
</div>
