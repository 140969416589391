import { Pipe, PipeTransform } from '@angular/core';
import { AlertStatus } from "../../models/enums/alertEnums";

@Pipe({
  name: 'alertsStatusPipe',
  standalone: true
})
export class AlertsStatusPipe implements PipeTransform {
  transform(value: AlertStatus | undefined | null): string {
    if (value != undefined)
      return AlertStatus[value];

    return '-';
  }
}
