import { Pipe, PipeTransform } from '@angular/core';
import { MaskedInputComponent } from "../app/components/common/masked-input/masked-input.component";
import { InputPhoneNumberComponent } from "../app/components/common/input-phone-number/input-phone-number.component";

@Pipe({
  standalone: true,
  name: 'phone'
})
export class PhonePipe implements PipeTransform {
  transform(value: string | undefined): string {
    if (!value?.trim())
      return "";

    return MaskedInputComponent.applyMask(value, InputPhoneNumberComponent.getPhoneMask(value)).replaceAll('_', '').trim();
  }
}
