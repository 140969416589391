import { Component, DestroyRef, inject, Input, OnInit, Optional } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NgControl,
  ReactiveFormsModule,
  ValidatorFn
} from "@angular/forms";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { ValidationErrorComponent } from "../validation-error/validation-error.component";
import { NgClass } from "@angular/common";
import { DateFormatPipe } from "../../../../pipes/dateFormatPipe";
import { AgActionIconButtonComponent } from "../grid/components/ag-action-icon-button/ag-action-icon-button.component";
import { DatePickerComponent } from "../date-picker/date-picker.component";
import {
  DatePickerFormValidators,
  DatepickerHelper
} from "../../../../custom-form-validators/date-picker-form-validators";
import { setControlDisabled } from "../../../../functions/set-control-disabled";
import { debounceTime, Subject } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-multiple-input-datepicker',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    DateFormatPipe,
    DatePickerComponent,
    AgActionIconButtonComponent,
    ValidationErrorComponent,
  ],
  templateUrl: './multiple-input-datepicker.component.html',
  styleUrl: './multiple-input-datepicker.component.scss'
})
export class MultipleInputDatepickerComponent implements OnInit, ControlValueAccessor {
  @Optional() protected ngControl = inject(NgControl);
  destroyRef = inject(DestroyRef);

  @Input() label = '';
  @Input() tip = 'Separate dates by semicolon';
  @Input() readonly = false;
  @Input() customDatepickerErrors: Record<string, string> = DatePickerFormValidators.errorMessages;
  @Input() allowDatesDuplicates = true;
  @Input() customErrors: Record<string, string> = {
    someDatesSmaller: 'Date of Variation cannot be earlier or same as Commencement Date.',
    someDatesBigger: 'Date of Variation cannot be later than Vesting Date.'
  };

  @Input('minDateStruct') set minDateStructSetter(value: NgbDateStruct) {
    this.updateMinDate(value);
  }

  @Input('maxDateStruct') set maxDateStructSetter(value: NgbDateStruct) {
    this.updateMaxDate(value);
  }

  disabled = false;
  dates: Date[] = [];
  datesValidity: boolean[] = [];
  editedDateControl = new FormControl<Date | null>(null);

  minDateStruct: NgbDateStruct = { year: 1900, month: 1, day: 1 };
  maxDateStruct: NgbDateStruct = DatepickerHelper.getDefaultMaxDateStruct();
  minDate = DatepickerHelper.getDateFromStruct(this.minDateStruct);
  maxDate = DatepickerHelper.getDateFromStruct(this.maxDateStruct);
  datepickerInputHandler = new Subject<string | null>();

  constructor() {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.setControlValidators();
    this.defineDatepickerHandler();
  }

  submit(submitEvent: SubmitEvent): void {
    submitEvent.preventDefault();
    submitEvent.stopPropagation();
    this.addDate(this.editedDateControl.value!);
  }

  defineDatepickerHandler(): void {
    const allowedSeparators = [',', ';'];
    this.datepickerInputHandler
      .pipe(
        debounceTime(10),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((symbol) => {
        if (symbol && allowedSeparators.includes(symbol)) {
          this.addDate(this.editedDateControl.value!);
        }
      });
  }

  handleDatepickerInput(inputCharacter: string | null): void {
    this.datepickerInputHandler.next(inputCharacter);
  }

  addDate(date: Date): void {
    if (!date) {
      return;
    }
    const stringDate = DatepickerHelper.toString(date);
    const isDateAdded = this.dates.some((d) => DatepickerHelper.toString(d) === stringDate);
    if (isDateAdded && !this.allowDatesDuplicates) {
      return;
    }
    this.dates.push(date);
    this.sortDates();
    this.editedDateControl.reset();
    this.onChange(this.dates ?? []);
    this.checkSelectedDatesValidation();
  }

  removeDate(index: number): void {
    this.dates.splice(index, 1);
    this.sortDates();
    this.onChange(this.dates ?? []);
  }

  onChange: any = (): void => {
  };
  onTouched: any = (): void => {
  };

  writeValue(dates: Date[]): void {
    this.dates = dates;
    this.sortDates();
    this.checkSelectedDatesValidation();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
    this.readonly = disabled;
    setControlDisabled(this.editedDateControl, disabled);
  }

  private sortDates(): void {
    this.dates = this.dates.sort((a, b) => a.getTime() - b.getTime());
  }

  private updateMinDate(minDate: NgbDateStruct): void {
    this.minDateStruct = minDate;
    this.minDate = DatepickerHelper.getDateFromStruct(minDate);
    this.checkSelectedDatesValidation();
  }

  private updateMaxDate(maxDate: NgbDateStruct): void {
    this.maxDateStruct = maxDate;
    this.maxDate = DatepickerHelper.getDateFromStruct(maxDate);
    this.checkSelectedDatesValidation();
  }

  private checkSelectedDatesValidation(): void {
    this.datesValidity = this.dates.map((date) => date > this.minDate && date <= this.maxDate);

    this.control?.updateValueAndValidity();
    this.editedDateControl?.updateValueAndValidity();
  }

  private setControlValidators(): void {
    if (!this.ngControl)
      return;

    const control = this.ngControl.control as FormControl;

    const someDatesSmaller: ValidatorFn = () => {
      return this.dates.some((date) => date <= this.minDate)
        ? { someDatesSmaller: true }
        : null;
    };

    const someDatesBigger: ValidatorFn = () => {
      return this.dates.some((date) => date > this.maxDate)
        ? { someDatesBigger: true }
        : null;
    };

    control.addValidators([
      someDatesSmaller,
      someDatesBigger,
    ]);
  }

  get control(): FormControl {
    return this.ngControl.control as FormControl;
  }

  get shouldShowErrors(): boolean {
    return !!this.control?.errors;
  }
}
