import { inject, Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  private hubConnection: signalR.HubConnection;
  private authService = inject(AuthService);

  constructor() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl('https://localhost:7189/signalrHub', { accessTokenFactory: () => this.authService.getToken().then(token => token!) })
      .build();
  }

  startConnection(): Observable<void> {
    return new Observable<void>((observer) => {
      this.hubConnection
        .start()
        .then(() => {
          console.log('Connection established with SignalR hub');
          observer.next();
          observer.complete();
        })
        .catch((error) => {
          console.error('Error connecting to SignalR hub:', error);
          observer.error(error);
        });
    });
  }

  receive(): Observable<string> {
    return new Observable<string>((observer) => {
      this.hubConnection.on('Receive', (message: string) => {
        observer.next(message);
      });
    });
  }

  send(message: string): void {
    void this.hubConnection.invoke('Send', message);
  }
}