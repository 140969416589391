import { Component, inject, Input } from '@angular/core';
import { ICellRendererParams } from "ag-grid-community";
import { ACNPipe } from "../../../../../../pipes/acnPipe";
import { Company } from "../../../../../../models/company";
import { EntityNameNumberRecordComponent } from '../../../entity-name-number-record/entity-name-number-record.component';
import { Router } from "@angular/router";

type ActionEvent = (data: Company | undefined) => void;

type CellRendererParams = ICellRendererParams<Company> & {
  openCompanyProfile: ActionEvent,
};

@Component({
  selector: 'app-ag-company-name-acn',
  standalone: true,
  imports: [
    ACNPipe,
    EntityNameNumberRecordComponent
  ],
  templateUrl: './ag-company-name-acn.component.html',
  styleUrl: './ag-company-name-acn.component.scss'
})
export class AgCompanyNameAcnComponent {
  @Input() name = '';
  @Input() acn = '';

  private router = inject(Router);
  protected params!: CellRendererParams;

  agInit(params: CellRendererParams): void {
    this.params = params;
    this.name = this.params.data?.name ?? '';
    this.acn =  this.params.data?.acn ?? '';
  }

  refresh(params: CellRendererParams): boolean {
    this.params = params;
    return true;
  }

  onOpenCompanyProfile(e: MouseEvent): void {
    if (e.ctrlKey) {
      e.preventDefault();
      window.open(`/company-profile/${this.params.data?.entityId}`, '_blank');
    } else {
      void this.router.navigate(['/company-profile', this.params.data?.entityId]);
    }
  }
}
