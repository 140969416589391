<div class="card-body">
  <app-list-grid
    [rowData]="pendingDocuments"
    [colDefs]="$any(colDefs)"
    [rowSelection]="rowSelection"
    [suppressRowClickSelection]="true"
    [suppressCellFocus]="true"
    [pagination]="true"
    [paginationPageSize]="defaultPageSize"
    [paginationPageSizeSelector]="true"
    (select)="selectDocuments($event)"
  ></app-list-grid>
</div>
