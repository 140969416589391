import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Note } from '../../../../../models/note';
import { DateFormatPipe } from '../../../../../pipes/dateFormatPipe';
import {
  AgActionIconButtonComponent
} from '../../../common/grid/components/ag-action-icon-button/ag-action-icon-button.component';
import { AttachedFileLabelComponent } from '../attached-file-label/attached-file-label.component';

@Component({
  selector: 'app-note-list-element',
  standalone: true,
  imports: [
    AttachedFileLabelComponent,
    DateFormatPipe,
    DatePipe,
    AgActionIconButtonComponent
  ],
  templateUrl: './note-list-element.component.html',
  styleUrl: './note-list-element.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoteListElementComponent {
  @Input() note!: Note;
  @Input() disabled = false;

  @Output() editNote = new EventEmitter<void>();
  @Output() deleteNote = new EventEmitter<void>();
  @Output() downloadFile = new EventEmitter<string>();
  @Output() deleteFile = new EventEmitter<string>();

  onEditNote(): void {
    this.editNote.emit();
  }

  onDeleteNote(): void {
    this.deleteNote.emit();
  }

  onDownloadFile(filename: string): void {
    this.downloadFile.emit(filename);
  }

  onDeleteFile(filename: string): void {
    this.deleteFile.emit(filename);
  }
}
