<div class="form-group" [formGroup]="form">
  <div class="input-row">
    <div class="form-group">
      <app-input label="First Name" formControlName="firstName"/>
    </div>

    <div class="form-group">
      <app-input label="Last Name" formControlName="lastName"/>
    </div>
  </div>

  <div class="input-row">
    <div class="form-group">
      <app-input label="Email Address" formControlName="email"/>
    </div>

    <div class="form-group">
      <app-input-phone-number label="Phone Number" formControlName="phone"/>
    </div>
  </div>
</div>
