import { Component } from '@angular/core';
import { ICellRendererParams } from "ag-grid-community";
import { SecurityTransactionType } from "../../../../../../../models/enums/securityTransactionTypeEnum";
import { NgClass } from "@angular/common";
import { SecurityTransactionTypePipe } from "../../../../../../../pipes/enumsPipes/securityTransactionTypePipe";
import { SplitByCapitalLetterPipePipe } from "../../../../../../../pipes/split-by-capital-letter-pipe.pipe";

@Component({
  selector: 'app-ag-share-transaction-type',
  standalone: true,
  imports: [
    NgClass,
    SecurityTransactionTypePipe,
    SplitByCapitalLetterPipePipe
  ],
  templateUrl: './ag-share-transaction-type.component.html',
  styleUrl: './ag-share-transaction-type.component.scss'
})
export class AgShareTransactionTypeComponent {
  transactionType!: number;

  agInit(params: ICellRendererParams<number, number>): void {
    if (params.value !== null && params.value !== undefined) {
      this.transactionType = params.value;
    }
  }

  refresh(params: ICellRendererParams<number, number>): boolean {
    if (params.value !== null && params.value !== undefined) {
      this.transactionType = params.value;
    }

    return true;
  }

  protected readonly SecurityTransactionType = SecurityTransactionType;
}
