import { Address } from './address';
import { AsicBankData } from './asicBankData';
import { AsicAgentStatus } from './enums/asicAgentStatus';
import { DeepCopy } from './shared/deep-copy.model';

export class AsicAgentData {
  contactPerson: string | null;
  status: AsicAgentStatus;
  phone: string;
  email: string;
  agentNumber: string;
  edgeLogin: string;
  edgePassword: string;
  edgeFuturePassword: string;
  firmName: string;
  postalAddress: Address;
  streetAddress: Address;
  bankData = new AsicBankData();

  constructor(data: Partial<AsicAgentData> = {}) {
    this.contactPerson = data.contactPerson ?? null;
    this.status = data.status ?? AsicAgentStatus.Ok;
    this.phone = data.phone ?? '';
    this.email = data.email ?? '';
    this.agentNumber = data.agentNumber ?? '';
    this.edgeLogin = data.edgeLogin ?? '';
    this.edgePassword = data.edgePassword ?? '';
    this.edgeFuturePassword = data.edgeFuturePassword ?? '';
    this.firmName = data.firmName ?? '';
    this.postalAddress = new Address(data.postalAddress);
    this.streetAddress = new Address(data.streetAddress);
    this.bankData = new AsicBankData(data.bankData);
  }

  patch(source: AsicAgentData): AsicAgentData {
    return DeepCopy.mergeDeep(this, source);
  }
}
