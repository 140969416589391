import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Company } from '../../../models/company';
import { Document } from '../../../models/document';
import { EntityChangeData } from '../../../models/entityChangeData';
import { CompanyChangeData } from '../../../models/enums/companyChangeData';
import { SelectOption } from '../../../models/selectOptions';
import { ChangeDictionaryHelper } from '../../../models/shared/change-dictionary-helper.model';
import { ABNPipe } from '../../../pipes/abnPipe';
import { CompanyNameChangeComponent } from './company-name-change.component';

export const privateLegalElementsOptions: SelectOption[] = [
  'Pty Ltd',
  'Pty. Ltd.',
  'Pty Limited',
  'Pty. Limited.',
].map(legalElement => ({ label: legalElement, value: legalElement }));

export const publicLegalElementsOptions: SelectOption[] = [
  'Pty Ltd',
  'Pty. Ltd.',
  'Pty Limited',
  'Pty. Limited.',
].map(legalElement => ({ label: legalElement, value: legalElement }));

export const legalElements: Record<string, SelectOption[]> = {
  public: publicLegalElementsOptions,
  proprietary: privateLegalElementsOptions
};

export class CompanyChangeName extends EntityChangeData {
  static override readonly $type = 'CompanyChangeName';
  newCompanyName: string;
  useAcnAsNewCompanyName = false;
  newLegalElementsIfAcnIsUsed: string;
  resolutionText: string;
  newNameReservedWithForm410: boolean;
  isProposedNameIdenticalToRegisteredBusinessName: boolean;
  businessNameHolderAbn: string;
  manualReviewRequested: boolean;
  manualProcessingReason: string;
  paymentMethod: PaymentMethod;
  reservationNumber: string;

  constructor(data: Partial<CompanyChangeName> = {}) {
    super(data);

    this.newCompanyName = data.newCompanyName ?? '';
    this.newLegalElementsIfAcnIsUsed = data.newLegalElementsIfAcnIsUsed ?? '';
    this.resolutionText = data.resolutionText ?? '';
    this.newNameReservedWithForm410 = data.newNameReservedWithForm410 ?? false;
    this.isProposedNameIdenticalToRegisteredBusinessName = data.isProposedNameIdenticalToRegisteredBusinessName ?? false;
    this.businessNameHolderAbn = data.businessNameHolderAbn ?? '';
    this.manualReviewRequested = data.manualReviewRequested ?? false;
    this.manualProcessingReason = data.manualProcessingReason ?? '';
    this.paymentMethod = data.paymentMethod ?? PaymentMethod.INV;
    this.reservationNumber = data.reservationNumber ?? '';
    this.useAcnAsNewCompanyName = this.nameIncludesAcnAndLegalElements();
  }

  override toDictionary(): { key: string; value: string; }[] {
    const dict = new ChangeDictionaryHelper();

    dict
      .addYesNo('Use ACN as new company name', this.useAcnAsNewCompanyName)
      .add('Old Company Name', ChangeDictionaryHelper.COMPANY_NAME)
      .add('New Company Name', this.newCompanyName);

    if (!this.useAcnAsNewCompanyName) {
      // Use ACN as proposed company name? === No
      dict
        .addYesNo('Has a name reservation been lodged to reserve the proposed new company name?', this.newNameReservedWithForm410)
        .addIfValueIsNotEmpty('Reservation Number', this.reservationNumber)
        .addYesNo('Is the proposed name identical to a registered business name(s)?', this.isProposedNameIdenticalToRegisteredBusinessName)

      if (this.isProposedNameIdenticalToRegisteredBusinessName) {
        dict
          .addIfValueIsNotEmpty('ABN', this.businessNameHolderAbn ? new ABNPipe().transform(this.businessNameHolderAbn) : '')
          .addYesNo('Would you like ASIC to manually review the name change?', this.manualReviewRequested)

        if (this.manualReviewRequested && this.manualProcessingReason)
          dict.addIfValueIsNotEmpty('Manual Processing Reason', this.manualProcessingReason);
      }
    }

    dict
      .add('Payment Method', paymentMethodsNames[this.paymentMethod])
      .addDate('Resolution Date', this.changeDate)
      .add('Special Resolution', this.resolutionText);

    return dict.dictionary;
  }

  override fillModal(modalRef: NgbModalRef, document: Document, actualCompany: Company): NgbModalRef {
    modalRef = this.fillModalWithCompany(modalRef, actualCompany, true);
    (modalRef.componentInstance as CompanyNameChangeComponent).companyChangeData.documentId = document.documentId;
    return modalRef;
  }

  override fillModalWithCompany(modalRef: NgbModalRef, company: Company, isEdit: boolean): NgbModalRef {
    const instance = modalRef.componentInstance as CompanyNameChangeComponent;

    instance.isEdit = isEdit;
    instance.companyChangeData = new CompanyChangeData(company);
    instance.formModel = structuredClone(this);

    return modalRef;
  }

  private nameIncludesAcnAndLegalElements(): boolean {
    const acnWithoutSpaces = /\d{9}/;
    const acnWithSpaces = /\d{3} \d{3} \d{3}/;
    const includesACN = acnWithoutSpaces.test(this.newCompanyName) || acnWithSpaces.test(this.newCompanyName);

    return Boolean(this.newLegalElementsIfAcnIsUsed.trim()) &&
      this.newCompanyName.includes(this.newLegalElementsIfAcnIsUsed)
      && includesACN;
  }
}

// Payment method used for the form 205A
export enum PaymentMethod {
  DD = 0, // Direct debit
  INV = 1 // Invoice
}

export const paymentMethodsNames: Record<PaymentMethod, string> = {
  [PaymentMethod.DD]: 'Direct debit',
  [PaymentMethod.INV]: 'Invoice',
};

export const paymentMethodOptions: SelectOption[] = [
  { label: paymentMethodsNames[PaymentMethod.DD], value: PaymentMethod.DD },
  { label: paymentMethodsNames[PaymentMethod.INV], value: PaymentMethod.INV },
];
