import { Component, DestroyRef, EventEmitter, inject, Input, Output } from '@angular/core';
import { catchError, EMPTY, filter, Observable, of, Subject, switchMap } from "rxjs";
import { DocumentsService } from "../../../../services/documents.service";
import { DividerComponent } from "../../../components/common/divider/divider.component";
import { CurrencyPipe } from "@angular/common";
import { ButtonComponent } from "../../../components/common/button/button.component";
import { NotificationComponent } from "../../../components/common/notification/notification.component";
import {
    AttachedFileLabelComponent
} from "../../../components/notes/components/attached-file-label/attached-file-label.component";
import { FileUploadComponent } from "../../../components/common/file-upload/file-upload.component";
import { DatePickerComponent } from "../../../components/common/date-picker/date-picker.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RadioComponent } from "../../../components/common/radio/radio.component";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { DocumentSigning } from "../../../../models/documentEnteties/document-signing";
import { HttpErrorResponse } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { Operation } from "fast-json-patch";
import { LodgementDeadline } from "../../../../models/documents";
import { DocumentStatusEnum } from "../../../../models/enums/documentStatusEnum";
import { SendOption } from "../../../../models/enums/annualStatementEnums";
import { Document } from "../../../../models/document";

@Component({
  selector: 'app-lodge-documents',
  standalone: true,
  imports: [
    DividerComponent,
    CurrencyPipe,
    ButtonComponent,
    NotificationComponent,
    AttachedFileLabelComponent,
    FileUploadComponent,
    DatePickerComponent,
    FormsModule,
    ReactiveFormsModule,
    RadioComponent
  ],
  templateUrl: './lodge-documents.component.html',
  styleUrl: './lodge-documents.component.scss'
})
export class LodgeDocumentsComponent {
  @Input() documentStatus: DocumentStatusEnum | undefined;
  @Input() sendBy: SendOption | undefined;
  @Input() documentId: string | undefined;
  @Input() lodgement: LodgementDeadline | undefined;
  @Input() documentSigning: DocumentSigning | null | undefined;
  @Input() confirmAction$: Subject<boolean> | undefined;
  @Output() nextStep = new EventEmitter<boolean>();
  @Output() updateDocuments = new EventEmitter<boolean>();

  private documentsService = inject(DocumentsService);
  private toastr = inject(ToastrService);
  private destroyRef = inject(DestroyRef);

  readonly lateFeeText = '"Late Fee" is a term referring to an additional charge imposed when a payment is not made by the due date.' +
    ' It\'s a penalty enforced by various service providers, such as banks, utility companies, or rental agencies,' +
    ' to incentivize timely payments and compensate for the inconvenience caused by delays.' +
    ' Late fees can vary in amount and are typically outlined in the terms and conditions of contracts or agreements.';

  readonly prepareLodgementInfo = 'In the "Prepare Lodgement" step,' +
    ' the verification of signed document status is conducted to ensure adherence to regulatory requirements.' +
    ' This pivotal phase involves thorough review to confirm the completeness of signatures,' +
    ' thereby certifying the readiness of documents for submission or subsequent processing.';

  amountFee = 0;

  readonly changeDocumentStatusOperation: Operation[] = [
    {
      path: '/documentStatus',
      op: 'replace',
      value: DocumentStatusEnum.Completed,
    }
  ];

  constructor() {
    this.toastr.toastrConfig.positionClass = 'toast-top-right';
  }

  ngOnInit(): void {
    this.amountFee = this.lodgement?.lateFee ? this.lodgement.lateFee : 0;
    this.disableHeaderBtn(false);
    this.listenHeaderButton();
  }

  private listenHeaderButton(): void {
    this.confirmAction$?.pipe(
      filter(confirm => confirm),
      switchMap(() => {
        if(this.sendBy === SendOption.Sign || this.sendBy === SendOption.Paper) {
          return this.sendToAsic();
        } else {
          return EMPTY;
        }
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((res) => {
      if(res !== 'ERR') {
        this.toastr.success('Document sent for lodgement', 'Success');
        this.nextStep.emit(true);
        this.updateDocuments.emit(true);
        this.confirmAction$?.next(false);
      }
    });
  }

  private sendToAsic(): Observable<string | string[] | Document> {
    const documentId = this.documentId ?? '';
    return this.documentsService.lodgeDocument(documentId).pipe(
      switchMap((res) => {
        if(!this.documentStatus || this.documentStatus < DocumentStatusEnum.Completed) {
          return this.documentsService.patchDocumentOperation(documentId, this.changeDocumentStatusOperation);
        }

        return of(res);
      }),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        this.toastr.error("Error lodging documents", "Error");
        return of('ERR');
      }),
    );
  }

  disableHeaderBtn(value: boolean): void {
    this.documentsService.disabledHeaderBtn.set(value);
  }
}
