<div class="modal-header">
    <p class="mb-0">Request for Company Details</p>
  </div>
  <div class="modal-body">
    <div class="no-animate">
      <app-company-name-acn [companyName]="formModel.copmanyName" [acn]="formModel.acn" #nameAcnForm></app-company-name-acn>
      <div class="form-group">
        <label>Payment Method</label>
        <select class="form-control" [(ngModel)]="formModel.requestedInformation">
          <option value="AS">Re-issue Annual Statement of Details</option>
          <option value="CS">Current Company Statement</option>
          <option value="CS">Data Download</option>
          <option value=""></option>
        </select>
      </div>
    </div>
    <div class="d-flex justify-content-center" style="margin-top: 1.5rem;">
      <button class="btn btn-default" (click)="activeModal.close()">Close</button>
      <button class="btn btn-primary no-animate" (click)="confirm()">Generate documents</button>
    </div>
  </div>