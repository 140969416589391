export class TextMessageTemplate {
  static $type = 'TextMessageTemplate';

  textMessageTemplateId: string;
  organisationId: string;
  code: string;
  template: string;

  constructor(data: Partial<TextMessageTemplate> = {}) {
    this.textMessageTemplateId = data.textMessageTemplateId ?? '';
    this.organisationId = data.organisationId ?? '';
    this.code = data.code ?? '';
    this.template = data.template ?? '';
  }
}
