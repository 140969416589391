import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppSpinnerDirective } from '../../../directives/spinner.directive';
import { FormsModule } from "@angular/forms";
import {
  AgActionIconButtonComponent
} from "../../components/common/grid/components/ag-action-icon-button/ag-action-icon-button.component";
import { ButtonComponent } from "../../components/common/button/button.component";

@Component({
  selector: 'app-common-modal-form',
  standalone: true,
  imports: [
    AppSpinnerDirective,
    NgClass,
    FormsModule,
    AgActionIconButtonComponent,
    ButtonComponent
  ],
  templateUrl: './common-modal-form.component.html',
  styleUrl: './common-modal-form.component.scss'
})
export class CommonModalFormComponent {
  @Input() header = '';
  @Input() subheader = '';
  @Input() icon = 'ic-form';
  @Input() isLoading = false;
  @Input() isInvalid = false;
  @Input() closeText = 'Close';
  @Input() confirmText = 'Confirm';
  @Input() footerClass = '';
  @Input() isCustomFooter = false;

  @Output() confirm = new EventEmitter<void>();
  @Output() close = new EventEmitter<void>();

  onConfirm() {
    this.confirm.emit();
  }

  onClose() {
    this.close.emit();
  }
}
