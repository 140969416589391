<app-inline-edit-input
  [ngClass]="{'text-danger' : !details.beneficialOwner && !details.isBeneficialOwner }"
  [controlLike]="controlLike"
  [value]="details.beneficialOwner"
  [onSave]="updateBeneficialOwner(relationshipId)"
>
  <!-- todo: replace with trust profile when ready -->
  @if (showOpenIcon) {
    <app-span-with-open-icon
      [route]="details.beneficialOwnerEntityId ? ['/company-profile', details.beneficialOwnerEntityId!] : []">
      {{ details.beneficialOwner | defaultValue : 'Not Supplied' }}
    </app-span-with-open-icon>
  } @else {
    {{ details.beneficialOwner | defaultValue : 'Not Supplied' }}
  }
</app-inline-edit-input>
