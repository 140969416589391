import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { DividerComponent } from "../../components/common/divider/divider.component";
import { ButtonComponent } from "../../components/common/button/button.component";
import { CheckboxComponent } from "../../components/common/checkbox/checkbox.component";
import { ColDef } from "ag-grid-enterprise";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ExportTypeEnum } from "../../../models/enums/exportTypeEnum";

interface CustomColDef extends ColDef {
  field?: string;
  headerName?: string;
  hide?: boolean;
}

@Component({
  selector: 'app-export-company-list',
  standalone: true,
  imports: [
    DividerComponent,
    ButtonComponent,
    CheckboxComponent
  ],
  templateUrl: './export-company-list.component.html',
  styleUrl: './export-company-list.component.scss'
})
export class ExportCompanyListComponent {
  @Input() title = 'Export Company List';
  @Input() subTitle = 'companies selected';
  @Input() colDefs: CustomColDef[] = [];
  @Input() numberOfCompanies = 0;
  @Input() exportType: ExportTypeEnum = ExportTypeEnum.EXCEL;
  @Output() confirm = new EventEmitter<string[]>();
  @Output() resetPagination = new EventEmitter<boolean>();

  modal = inject(NgbActiveModal);

  selectAll = false;

  get numberSelectedColumns(): number {
    return this.colDefs?.filter(c => !c.hide && c.headerName)?.length ?? 0;
  }

  onExport(): void {
    const columnsForExport = this.colDefs?.filter(c => !c.hide && c.headerName)
      .map(c => c.field) as string[];
    this.confirm.emit(columnsForExport);
    this.modal.close();
  }

  onClose(): void {
    this.resetPagination.emit(true);
    this.modal.close();
  }

  onCheckboxChange(index: number): void {
    if(this.colDefs?.length) {
      this.colDefs[index].hide = !this.colDefs[index].hide;
    }

    const columnWithHeaderName = this.colDefs?.filter(c => c.headerName) ?? [];
    const selectedColumnNumber = columnWithHeaderName?.filter(c => !c.hide)?.length ?? 0;

    this.selectAll = columnWithHeaderName?.length === selectedColumnNumber;
  }

  onSelectAllChange(selected: boolean): void {
    this.colDefs?.forEach(c => {
      if(c.headerName) {
        c.hide = !selected;
      }
    });

    this.selectAll = selected;
  }
}
