import { Component } from '@angular/core';
import { ICellRendererParams } from "ag-grid-community";
import { DocumentStatusEnum } from "../../../../../../models/enums/documentStatusEnum";
import { CompanyStatus } from "../../../../../../models/enums/companyEnums";
import { CompanyStatusPipe } from "../../../../../../pipes/enumsPipes/companyStatusPipe";
import { DocumentStatusPipe } from "../../../../../../pipes/enumsPipes/documentStatusPipe";
import { LowerCasePipe, NgClass } from "@angular/common";

@Component({
  selector: 'app-ag-document-status',
  standalone: true,
  imports: [
    CompanyStatusPipe,
    DocumentStatusPipe,
    LowerCasePipe,
    NgClass
  ],
  templateUrl: './ag-document-status.component.html',
  styleUrl: './ag-document-status.component.scss'
})
export class AgDocumentStatusComponent {
  status = 0;
  DocumentStatus = DocumentStatusEnum;

  agInit(params: ICellRendererParams): void {
    this.status = params.value as number;
  }

  refresh(params: ICellRendererParams): boolean {
    this.status = params.value as number;
    return true;
  }

  protected readonly CompanyStatus = CompanyStatus;
}
