import {
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  Optional,
  Output
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { SelectOption } from '../../../../models/selectOptions';
import { LinkComponent } from '../link/link.component';
import { ValidationErrorComponent } from '../validation-error/validation-error.component';

export type RadioControl<T extends unknown> = FormControl<T | null>;

@Component({
  selector: 'app-radio',
  standalone: true,
  imports: [
    LinkComponent,
    ValidationErrorComponent,
  ],
  templateUrl: './radio.component.html',
  styleUrl: './radio.component.scss',
})
export class RadioComponent implements ControlValueAccessor {
  cdr = inject(ChangeDetectorRef);

  @Input() label = '';
  @Input() link = '';
  @Input() text = '';
  @Input() helperText = '';
  @Input() horizontalAlign = true;
  @Input({ required: true }) options: SelectOption[] = [];
  @Input({ transform: booleanAttribute }) disabled = false;
  @Input() customErrors: Record<string, string> = { required: 'Field is required' };

  @Output() select = new EventEmitter<{ value: SelectOption, index: number }>();

  #value: unknown | null = null;
  controlsName = Math.random().toString(36).substring(7);

  constructor(@Optional() protected ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  get displayValidationError(): boolean {
    return !!(this.ngControl && this.ngControl.touched && this.ngControl.errors);
  }

  get value(): unknown | null {
    return this.#value;
  }

  set value(value: unknown) {
    this.#value = value;
    this.onChange(this.#value);
  }

  onChange: any = (): void => {};
  onTouched: any = (): void => {};

  selectRadio(option: SelectOption, index: number): void {
    this.select.emit({ value: option, index });
    this.value = option.value;
    this.onTouched();
  }

  writeValue(value: SelectOption): void {
    this.value = value;
    this.cdr.markForCheck();
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }
}
