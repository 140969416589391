import { Component, inject } from '@angular/core';
import { ButtonComponent } from "../../../components/common/button/button.component";
import { LoaderComponent } from "../../../components/common/loader/loader.component";
import { PageTitleComponent } from "../../../components/common/page-title/page-title.component";
import { AppCopyDirective } from "../../../../directives/copy.directive";
import { DefaulValuePipe } from "../../../../pipes/enumsPipes/defaultValuePipe";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  EditDocumentSettingsModalComponent
} from "./edit-document-settings-modal/edit-document-settings-modal.component";
import {
  DocumentSettingsModel,
  NotifyWhenAllPartiesSignedDocumentLabelRecord
} from "./edit-document-settings-modal/edit-document-settings-modal.constant";
import { DocumentationType } from "../../../../models/enums/documentConfirmEnums";

@Component({
  selector: 'app-document-settings-page',
  standalone: true,
  imports: [
    ButtonComponent,
    LoaderComponent,
    PageTitleComponent,
    AppCopyDirective,
    DefaulValuePipe
  ],
  templateUrl: './document-settings-page.component.html',
  styleUrls: ['./document-settings-page.component.scss', '../../settings-styles.scss']
})
export class DocumentSettingsPageComponent {
  private modalService = inject(NgbModal);

  isLoading = false;
  documentSettings = new DocumentSettingsModel();

  readonly NotifyWhenAllPartiesSignedDocumentLabelRecord = NotifyWhenAllPartiesSignedDocumentLabelRecord;
  readonly DocumentationType = DocumentationType;

  openEditDocumentSettingsModal(): void {
    const modalRef = this.modalService.open(EditDocumentSettingsModalComponent, { size: 'lg' });
    const instance = modalRef.componentInstance as EditDocumentSettingsModalComponent;
    instance.documentSettings = this.documentSettings;

    modalRef.result.then(
      () => this.loadDocumentSettings(),
      () => {
      });
  }

  loadDocumentSettings(): void {
    this.isLoading = true;

    this.isLoading = false;
  }
}
