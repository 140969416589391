import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DatepickerHelper } from '../../../../../custom-form-validators/date-picker-form-validators';
import { Document } from '../../../../../models/document';
import { EntityChangeData } from '../../../../../models/entityChangeData';
import { BaseStepperFormComponent } from '../../../stepper-form/base-stepper-component/base-stepper-form.component';
import { CompanyForm361 } from './CompanyForm361';
import {
  CompanyNameAcnFormGroupComponent
} from "../../../../components/reusable-form-groups/company-name-acn-form-group/company-name-acn-form-group.component";
import { setControlDisabled } from "../../../../../functions/set-control-disabled";
import { DatePickerComponent } from "../../../../components/common/date-picker/date-picker.component";
import { StepperFormComponent } from "../../../stepper-form/stepper-form.component";
import {
  StepperFormDescriptionComponent
} from "../../../stepper-form/stepper-form-description/stepper-form-description.component";

export enum RegisteredAgentCeasingStepsEnum {
  FormDescription = 0,
  RegisteredAgentCeasing = 1
}

@Component({
  selector: 'app-form361-registered-agent-ceasing',
  standalone: true,
  templateUrl: './form361-registered-agent-ceasing.component.html',
  styleUrl: '../../../stepper-form/base-stepper-component/base-stepper-form.component.scss',
  imports: [
    ReactiveFormsModule,
    DatePickerComponent,
    StepperFormComponent,
    StepperFormDescriptionComponent,
    CompanyNameAcnFormGroupComponent,
  ]
})
export class Form361RegisteredAgentCeasingComponent extends BaseStepperFormComponent<RegisteredAgentCeasingStepsEnum, CompanyForm361> implements OnInit {
  override readonly StepsEnum = RegisteredAgentCeasingStepsEnum;
  minDate!: NgbDateStruct;
  maxDate!: NgbDateStruct;

  form = new FormGroup({
    ...CompanyNameAcnFormGroupComponent.defineFormControls(),
    changeDate: new FormControl<Date | null>(DatepickerHelper.getToday(), [Validators.required]),
  });

  override stepperForm = new FormGroup({
    [RegisteredAgentCeasingStepsEnum.FormDescription]: new FormGroup({}),
    [RegisteredAgentCeasingStepsEnum.RegisteredAgentCeasing]: this.form,
  });

  constructor() {
    super();

    this.setupSteps(RegisteredAgentCeasingStepsEnum);
    this.redirectAfterSubmit = true;
  }

  ngOnInit(): void {
    this.minDate = DatepickerHelper.getDateOfEstablishmentMinDate(this.companyChangeData.dateOfEstablishment);
    this.maxDate = DatepickerHelper.getNextNYearsStruct(10);

    this.form.controls.name.setValue(this.companyChangeData?.companyName ?? '');
    this.form.controls.acn.setValue(this.companyChangeData?.companyAcn ?? '');
    this.setupChange();
  }

  override afterSubmit(changes: EntityChangeData[]): void {
    this.setupChange(changes[0] as CompanyForm361);
  }

  override setupChange(change: CompanyForm361 = this.formModel): void {
    this.form.controls.changeDate.setValue(change.changeDate ?? new Date());
    setControlDisabled(this.form.controls.name);
    setControlDisabled(this.form.controls.acn);
  }

  override buildDocument(): Document | null {
    const change = new CompanyForm361({ changeDate: this.form.controls.changeDate.value! });

    try {
      return new Document({
        changes: [change],
        entityId: this.companyChangeData.entityId,
        type: 'c:361',
        documentId: this.companyChangeData?.documentId,
      });
    } catch (error) {
      console.warn(error);
      this.toastr.error('Failed to create Document.', 'Error');
      return null;
    }
  }
}
