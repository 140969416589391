import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DatepickerHelper } from '../../../../../../custom-form-validators/date-picker-form-validators';
import { AsicBankData } from '../../../../../../models/asicBankData';
import { Company } from '../../../../../../models/company';
import { Document } from '../../../../../../models/document';
import { EntityChangeData } from '../../../../../../models/entityChangeData';
import { CompanyChangeData } from '../../../../../../models/enums/companyChangeData';
import { IndividualData } from '../../../../../../models/individualData';
import { ChangeDictionaryHelper } from '../../../../../../models/shared/change-dictionary-helper.model';
import { Ra04DirectDebitRequestComponent } from './ra04-direct-debit-request.component';

export enum Ra04DirectDebitRequestStepsEnum {
  FormDescription = 0,
  Customer = 1,
  DetailsOfTheAccountToBeDebited = 2,
}

export class AsicFormRA04 extends EntityChangeData {
  static override readonly $type = 'AsicFormRA04';

  date: Date;
  companyName: string | null;
  companyAcn: string | null;
  individual: IndividualData | null;
  bankData: AsicBankData;
  ecrForm: boolean;
  otherForms: boolean;

  constructor(data: Partial<AsicFormRA04> = {}) {
    super(data);

    // TODO: Add ChangeType
    this.date = data.date ?? new Date();
    this.companyName = data.companyName ?? null;
    this.companyAcn = data.companyAcn ?? null;
    this.individual = data.individual ?? null;
    this.bankData = data.bankData ?? new AsicBankData();
    this.ecrForm = data.ecrForm ?? false;
    this.otherForms = data.otherForms ?? false;
  }

  override toDictionary(): { key: string; value: string; }[] {
    const dict = new ChangeDictionaryHelper();

    dict
      .add('Name and branch of financial institution', this.bankData.bankName)
      .add('BSB Number', this.bankData.bankBSB)
      .add('Account Number', this.bankData.bankAccountNumber)
      .add('Account Name', this.bankData.bankAccountName)
      .add('Daily ASIC Credit Limit', this.bankData.dailyCreditLimit)
      .add('Agent Type', this.individual ? 'Individual' : 'Company');

    if (this.individual) {
      dict
        .add('Name', this.individual.fullName)
        .add('Former Name', this.individual.formerName)
    } else {
      dict
        .addAcn('ACN', this.companyAcn)
        .add('Company Name', this.companyName);
    }

    return dict.dictionary;
  }

  override fillModal(modalRef: NgbModalRef, document: Document, actualCompany: Company): NgbModalRef {
    modalRef = this.fillModalWithCompany(modalRef, actualCompany, true);
    (modalRef.componentInstance as Ra04DirectDebitRequestComponent).companyChangeData.documentId = document.documentId;

    return modalRef;
  }

  override fillModalWithCompany(modalRef: NgbModalRef, company: Company, isEdit: boolean): NgbModalRef {
    const instance = modalRef.componentInstance as Ra04DirectDebitRequestComponent;

    instance.isEdit = isEdit;
    instance.formModel = structuredClone(this);
    instance.companyChangeData = new CompanyChangeData(company);

    return modalRef;
  }

  override prepareToRequest(): AsicFormRA04 {
    return {
      ...this,
      ...super.prepareToRequest(),
      date: DatepickerHelper.buildDateString(this.date),
    };
  }
}
