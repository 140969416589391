import { ChangeDetectorRef, Component, DestroyRef, EventEmitter, inject, Input, Output } from '@angular/core';
import { CommonModalFormComponent } from "../../common-modal-form/common-modal-form.component";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { InputComponent } from "../../../components/common/input/input.component";
import { InputPhoneNumberComponent } from "../../../components/common/input-phone-number/input-phone-number.component";
import { SelectComponent } from "../../../components/common/select/select.component";
import { ButtonComponent } from "../../../components/common/button/button.component";
import { DividerComponent } from "../../../components/common/divider/divider.component";
import { NgbActiveModal, NgbDropdown, NgbDropdownMenu, NgbDropdownToggle } from "@ng-bootstrap/ng-bootstrap";
import { AsyncPipe, NgOptimizedImage } from "@angular/common";
import { RadioComponent } from "../../../components/common/radio/radio.component";
import { SelectOption } from "../../../../models/selectOptions";
import { Router } from "@angular/router";
import {
  BehaviorSubject, catchError,
  combineLatest,
  Observable, of,
  ReplaySubject,
  shareReplay,
  switchMap,
  tap
} from "rxjs";
import { FilesResponse}  from "../../../../models/files";
import { AnnualStatement} from "../../../../models/annualStatement";
import { DocumentStatusEnum } from '../../../../models/enums/documentStatusEnum';
import { AnnualStatementStatus, SendOption } from '../../../../models/enums/annualStatementEnums';
import { FilesService } from "../../../../services/files.service";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { ShadowDomViewerComponent } from "../../../components/common/shadow-dom-viewer/shadow-dom-viewer.component";
import { EmailPreviewComponent } from "../../../components/email-preview/email-preview.component";
import { AnnualStatementsService } from "../../../../services/annual-statements.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { EmailTemplatesService } from "../../../../services/email-templates.service";
import { downloadBase64File } from "../../../../functions/download-base64-file";
import { ESignBody } from "../../../../models/signDocuments";
import { ESignService } from "../../../../services/e-sign.service";
import { ToastrService } from "ngx-toastr";
import { EmailBulkSendRequest } from "../../../../models/email-templates/emailBulkSendRequest";
import { EmailPayloadBase } from "../../../../models/email-templates/emailPayloadBase";

@Component({
  selector: 'app-annual-view',
  standalone: true,
  imports: [
    CommonModalFormComponent,
    FormsModule,
    InputComponent,
    InputPhoneNumberComponent,
    ReactiveFormsModule,
    SelectComponent,
    ButtonComponent,
    DividerComponent,
    NgbDropdown,
    NgbDropdownMenu,
    NgbDropdownToggle,
    AsyncPipe,
    RadioComponent,
    NgOptimizedImage,
    NgxSkeletonLoaderModule,
    ShadowDomViewerComponent,
    EmailPreviewComponent
  ],
  providers: [EmailTemplatesService],
  templateUrl: './annual-view.component.html',
  styleUrl: './annual-view.component.scss'
})
export class AnnualViewComponent {
  @Input() documentId = '';
  @Input() partnerManagerName = '';
  @Output() confirm = new EventEmitter();

  private filesService = inject(FilesService);
  private annualStatementsService =  inject(AnnualStatementsService);
  private emailTemplatesService = inject(EmailTemplatesService);
  private eSignService = inject(ESignService);
  private router = inject(Router);
  private fb: FormBuilder = inject(FormBuilder);
  private activeModal = inject(NgbActiveModal);
  private toastr = inject(ToastrService);
  private cdr = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);

  public loading = false;
  public annualLoading = false;
  protected annual: AnnualStatement | undefined;
  protected emailBody: Partial<ESignBody> | undefined;
  private readonly documentId$ = new ReplaySubject<string>(1);
  private readonly refreshList$ = new BehaviorSubject(false);
  protected readonly DocumentStatusEnum = DocumentStatusEnum;
  protected readonly AnnualStatementStatus = AnnualStatementStatus;
  protected documentsList$: Observable<FilesResponse> = combineLatest([this.refreshList$, this.documentId$]).pipe(
    tap(() => { this.loading = true; this.cdr.detectChanges(); }),
    switchMap(([_, documentId]) => this.filesService.getFiles(documentId)),
    tap(() => this.loading = false),
    shareReplay(1),
  );

  protected templates$ = this.documentId$.pipe(
    tap(() => { this.loading = true; this.cdr.detectChanges(); }),
    switchMap(documentId => this.emailTemplatesService.loadEmailTemplates()),
    tap(() => this.loading = false),
    shareReplay(1),
  );

  sendByOptions: SelectOption[] = [
    { label: 'E-Sign', value: SendOption.Sign },
    { label: 'Email', value: SendOption.Email },
  ];

  form!: FormGroup;

  ngOnInit(): void {
    this.documentId$.next(this.documentId);
    this.annualLoading = true;
    this.annualStatementsService.getAnnualById(this.documentId).pipe(
      catchError(err => {
        console.error(err);
        return of(null);
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(res => {
      if(res) {
        this.annual = res;
      }

      this.annualLoading = false;
    });
    this.initForm();
  }

  initForm(): void {
    this.form = this.fb.group({
      sendBy: [SendOption.Sign],
    });
  }

  send(): void {
    if(this.form.get('sendBy')?.value === SendOption.Sign) {
      this.sendToESign();
    } else if (this.form.get('sendBy')?.value === SendOption.Email) {
      this.sendEmail();
    }
  }

  sendToESign(): void {
    const documentId = this.annual?.documentId;
    let eSignBody: ESignBody | undefined;
    if(this.emailBody?.payloadModel && this.emailBody?.emailTemplate) {
      eSignBody = {
        signatures: this.annual?.documentSigning?.signatures ?? [],
        carbonCopyList: [],
        approver: null,
        carbonCopyEmails: [],
        payloadModel: this.emailBody.payloadModel,
        emailTemplate: this.emailBody.emailTemplate
      };
    }

    if(!documentId || !eSignBody) {
      return;
    }

    this.eSignService.sendToESign(documentId, eSignBody).pipe(
      catchError(err => {
        this.toastr.error('E-sign error', 'Error');
        console.error(err);
        return of(null)
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  sendEmail(): void {
    let emailSendRequest: EmailBulkSendRequest | undefined;
    if(this.emailBody?.payloadModel && this.emailBody?.emailTemplate) {
      emailSendRequest = {
        recipientsList: this.annual?.documentSigning?.signatures.map(item => item.email) ?? [],
        payloadModel: this.emailBody.payloadModel as EmailPayloadBase,
        template: this.emailBody.emailTemplate,
        attachedNameFilesToSend: [],
        logo: '',
        brand: null,
      };
    }

    if(!emailSendRequest) { return; }

    this.emailTemplatesService.sendBulkEmailTemplate(emailSendRequest, this.documentId).pipe(
      catchError(err => {
        this.toastr.error('Send email error', 'Error');
        console.error(err);
        return of('ERR')
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(res => {
      if(res && res !== 'ERR') {
        this.toastr.error('Email has been successfully sent', 'Success');
      }
    });
  }

  changeEmail(emailBody: Partial<ESignBody>): void {
    this.emailBody = emailBody;
  }

  customize(): void {
    if(this.documentId) {
      void this.router.navigate(['/annual-statement', this.documentId]);
    }
  }

  close(): void {
    this.activeModal.dismiss();
  }

  downloadFile(fileName: string, subFolder: string): void {
    this.filesService.downloadFile(this.annual!.documentId, fileName, subFolder).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((res) => {
      downloadBase64File(res, fileName);
    });
  }

  get isNotValidForSend(): boolean {
    return !this.annual?.documentSigning?.signatures?.some(item => item.email);
  }
}
