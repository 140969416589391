import { Address } from "./address";

export class OrganisationModel {
  name: string | null;
  email: string | null;
  phone: string | null;
  website: string | null;
  primaryContactId: string | null;
  billingContactId: string | null;
  address: Address | null;

  constructor(data: Partial<OrganisationModel> = {}) {
    this.name = data.name ?? null;
    this.email = data.email ?? null;
    this.phone = data.phone ?? null;
    this.website = data.website ?? null;
    this.primaryContactId = data.primaryContactId ?? null;
    this.billingContactId = data.billingContactId ?? null;
    this.address = data.address ? new Address(data.address) : null;
  }
}
