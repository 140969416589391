<app-stepper-form
  header="Copy of Financial Statements and Reports"
  subheader="Form 388"
  [steps]="steps"
  [currentStep]="currentStep"
  [currentStepIndex]="currentStepIndex"
  [isLoading]="isLoading"
  [form]="stepperForm"
>
  <div class="step-content" [formGroup]="stepperForm">
    <app-stepper-form-description
      formKey="388"
      [hidden]="currentStep !== StepsEnum.FormDescription"
    ></app-stepper-form-description>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.ReasonsOfLodgement"
      [hidden]="currentStep !== StepsEnum.ReasonsOfLodgement"
    >
      <h1 class="h1-heading">{{ steps[currentStepIndex].label }}</h1>

      <div class="form-group">
        <app-select
          label="Reason for lodgement of statement and reports"
          formControlName="lodgementReason"
          [options]="lodgementReasons"
          [valuePrimitive]="true"
        ></app-select>
      </div>

      <div class="form-group">
        <app-date-picker
          label="Financial year begins"
          formControlName="financialYearBegins"
          [minDate]="minDate"
          [maxDate]="DatepickerHelper.getStructFromDateOrNull(reasonsOfLodgementForm.controls.financialYearEnds.value) ?? maxDate"
          [customErrors]="customDatepickerErrors"
        ></app-date-picker>
      </div>

      <div class="form-group">
        <app-date-picker
          label="Financial year ends"
          formControlName="financialYearEnds"
          [minDate]="DatepickerHelper.getStructFromDateOrNull(reasonsOfLodgementForm.controls.financialYearBegins.value) ?? minDate"
          [maxDate]="maxDate"
        ></app-date-picker>
      </div>
    </section>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.DetailsOfLargeProprietaryCompany"
      [hidden]="currentStep !== StepsEnum.DetailsOfLargeProprietaryCompany"
    >
      <h1 class="h1-heading">{{ steps[currentStepIndex].label }}</h1>

      <div class="form-group">
        <app-yes-no-control
          label="Is this a large proprietary company?"
          formControlName="isCompanyLarge"
        ></app-yes-no-control>
      </div>

      <div class="large-proprietary-company-info" [hidden]="largeProprietaryCompanyForm.value.isCompanyLarge !== true">
        <div class="form-group">
          <app-input-number
            label="What is the consolidated revenue of the large proprietary company and entities that it controls?"
            formControlName="consolidatedRevenueIfLarge"
          ></app-input-number>
        </div>

        <div class="form-group">
          <app-input-number
            label="What is the value of the consolidated gross assets of the large proprietary company and the entities that it controls?"
            formControlName="consolidatedGrossAssetsValueIfLarge"
          ></app-input-number>
        </div>

        <div class="form-group">
          <app-input-number
            label="How many employees are employed by the large proprietary company and the entities that it controls?"
            formControlName="employeesQuantityIfLarge"
          ></app-input-number>
        </div>

        <div class="form-group">
          <app-input-number
            label="How many members does the large proprietary company have?"
            formControlName="membersQuantityIfLarge"
          ></app-input-number>
        </div>
      </div>
    </section>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.ReportOfAuditorOrReviewer"
      [hidden]="currentStep !== StepsEnum.ReportOfAuditorOrReviewer"
    >
      <h1 class="h1-heading">{{ steps[currentStepIndex].label }}</h1>

      <div class="form-group">
        <app-select
          label="Were the financial statements audited or reviewed?"
          formControlName="financialStatementsAuditedOrReviewed"
          [options]="financialStatementOptions"
          [valuePrimitive]="true"
        ></app-select>
      </div>

      <div class="form-group"
           [hidden]="reportOfAuditorOrReviewerForm.value.financialStatementsAuditedOrReviewed !== FinancialStatement.NO">
        <app-yes-no-control
          label="Is there a class or other order exemption current?"
          formControlName="isThereExemptionCurrent"
        ></app-yes-no-control>
      </div>

      <div [hidden]="reportOfAuditorOrReviewerForm.value.financialStatementsAuditedOrReviewed !== FinancialStatement.REV">
        <div class="form-group">
          <app-yes-no-control
            label="Is the reviewer a registered company auditor, or member of The Institute of Chartered Accountants in Australia, CPA Australia Ltd, or Institute of Public Accountants and holds a practising certificate issued by one of those bodies?"
            formControlName="isReviewerRegistered"
          ></app-yes-no-control>
        </div>
      </div>

      <div [hidden]="reportOfAuditorOrReviewerForm.value.financialStatementsAuditedOrReviewed === FinancialStatement.NO">
        <h1 class="h1-heading">Is the opinion/conclusion in the report:</h1>

        <div class="form-group">
          <app-yes-no-control
            label="Modified? (The opinion/conclusion in the report is qualified, adverse or disclaimed)"
            formControlName="isOpinionConclusionReportModified"
          ></app-yes-no-control>
        </div>

        <div class="form-group">
          <app-yes-no-control
            label="Does the report contain an Emphasis of Matter and/or Other Matter paragraph?"
            formControlName="reportContainEmphasis"
          ></app-yes-no-control>
        </div>
      </div>
    </section>
  </div>
</app-stepper-form>
