<section [formGroup]="formGroup">
  <div class="form-group">
    <app-select-groups
      label="Shareholder Type"
      placeholder="Select shareholder type"
      formControlName="shareholderType"
      [optionGroups]="shareholderTypesOptionGroups"
    ></app-select-groups>
  </div>

  <div
    class="form-group"
    [hidden]="formGroup.value.shareholderType !== ShareholderTypesEnum.ExistingShareholder"
  >
    <app-select-shareholder
      label="Current Shareholders"
      formControlName="securityRegistryRecordIndex"
      [securityRegistryRecords]="securityRegistryRecords"
    ></app-select-shareholder>
  </div>

  <div
    class="form-group"
    [hidden]="formGroup.value.shareholderType !== ShareholderTypesEnum.Individual"
    [formGroup]="formGroup"
  >
    <app-individual-data-form-group
      [formGroup]="formGroup.controls.newIndividualShareholder"
      [individualDataFormLabels]="individualDataFormLabels"
      [hiddenControls]="hiddenIndividualControls"
      [useInternationalAddresses]="true"
    />
  </div>

  <div
    [hidden]="formGroup.value.shareholderType !== ShareholderTypesEnum.Corporate"
  >
    <div class="form-group">
      <app-company-name-acn-form-group
        [formGroup]="formGroup"
        [shouldLookForCompanyName]="!formGroup.value.isOverseasCompany"
        [hideAcn]="!!formGroup.value.isOverseasCompany"
      >
        <div class="form-group">
          <app-checkbox
            label="This is an overseas company or the company doesn't have an ACN"
            formControlName="isOverseasCompany"
          ></app-checkbox>
        </div>
      </app-company-name-acn-form-group>
    </div>

    <div class="form-group">
      <app-address-form-group
        [formGroup]="formGroup.controls.registeredAddress"
        [useInternationalAddresses]="formGroup.value.isOverseasCompany ?? false"
        [addressFormLabels]="addressFormLabels"
     />
    </div>
  </div>

  <div
    class="form-group"
    [hidden]="formGroup.value.shareholderType !== ShareholderTypesEnum.JointShareholder"
  >
    <app-share-joint-security-holder formControlName="joint"></app-share-joint-security-holder>
  </div>

  <div
    [hidden]="formGroup.value.shareholderType === null || formGroup.value.shareholderType === ShareholderTypesEnum.ExistingShareholder">
    <div class="form-group">
      <app-yes-no-control
        label="Are the shares owned on behalf of another individual, company, trust or fund?"
        formControlName="isBeneficialOwner"
      ></app-yes-no-control>
    </div>

    <div class="form-group" [hidden]="!formGroup.value.isBeneficialOwner">
      <app-input
        label="Shares are owned on behalf of:"
        formControlName="beneficialOwner"
      ></app-input>
    </div>
  </div>
</section>
