/// <summary>
/// Security type may be created as a result of limited types of transactions:
/// Issue of shares
/// Division / Consolidation of shares
/// Conversion of shares
/// Payment of shares
/// </summary>

import { DatepickerHelper } from '../custom-form-validators/date-picker-form-validators';
import { SecurityTransactionType } from './enums/SecurityTransactionType';
import { IMustHaveOrganisationId } from './mustHaveOrganisationId';
import { IPostable } from './postable';
import { SecurityType } from './securityType';

export abstract class SecurityTransaction implements IMustHaveOrganisationId, IPostable {
  securityTransactionId: string | null;
  entityId: string | null;
  organisationId: string;
  organisation: any | null;
  documentId: string | null;
  changeId: string | null;
  transactionDate: Date;
  securityHoldingId: string | null;
  relationshipIds: string[];
  securityTypeId: string | null;
  securityType: SecurityType;
  sequentialNumber: number;
  considerationPaid: number;
  numberIncrease: number;
  paidIncrease: number;
  unpaidIncrease: number;
  transactionType: SecurityTransactionType;
  pairTransactionId: string | null;
  pairTransaction: SecurityTransaction | null;
  shareCertificateNumber: string | null;
  shareCertificateQR: number | null;
  isPosted = true;

  constructor(data: Partial<SecurityTransaction> = {}) {
    this.securityTransactionId = data.securityTransactionId ?? null;
    this.entityId = data.entityId ?? null;
    this.organisationId = data.organisationId ?? '';
    this.organisation = data.organisation ?? null;
    this.documentId = data.documentId ?? null;
    this.changeId = data.changeId ?? null;
    this.transactionDate = DatepickerHelper.toDate(data.transactionDate);
    this.securityHoldingId = data.securityHoldingId || null;
    this.relationshipIds = data.relationshipIds ?? [];
    this.securityTypeId = data.securityTypeId ?? null;
    this.securityType = new SecurityType(data.securityType);
    this.sequentialNumber = data.sequentialNumber ?? 0;
    this.considerationPaid = data.considerationPaid ?? 0;
    this.numberIncrease = data.numberIncrease ?? 0;
    this.paidIncrease = data.paidIncrease ?? 0;
    this.unpaidIncrease = data.unpaidIncrease ?? 0;
    this.transactionType = data.transactionType ?? SecurityTransactionType.Issue;
    this.pairTransactionId = data.pairTransactionId ?? null;
    this.pairTransaction = data.pairTransaction ?? null;
    this.shareCertificateNumber = data.shareCertificateNumber ?? null;
    this.shareCertificateQR = data.shareCertificateQR ?? null;
    this.isPosted = data.isPosted ?? true;
  }

  prepareToRequest(): SecurityTransaction {
    delete (this as any).securityTransactionId;
    delete (this as any).pairTransactionId;

    return {
      ...this,
      transactionDate: DatepickerHelper.buildDateString(this.transactionDate)
    }
  }
}
