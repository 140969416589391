import { Component } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from 'ag-grid-community';
import { NgClass } from "@angular/common";
import { CompanyStatus } from '../../../../../../models/enums/companyEnums';
import { CompanyStatusPipe } from "../../../../../../pipes/enumsPipes/companyStatusPipe";

@Component({
  selector: 'app-ag-company-status',
  standalone: true,
  imports: [
    NgClass,
    CompanyStatusPipe
  ],
  templateUrl: './ag-company-status.component.html',
  styleUrl: './ag-company-status.component.scss'
})
export class AgCompanyStatusComponent implements ICellRendererAngularComp {
  status = 0;
  CompanyStatus = CompanyStatus;

  agInit(params: ICellRendererParams): void {
    this.status = params.value as number;
  }

  refresh(params: ICellRendererParams): boolean {
    this.status = params.value as number;
    return true;
  }
}
