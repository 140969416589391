import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { InputComponent } from "../../common/input/input.component";
import { ReusableFormGroupComponent } from "../reusable-form-group/reusable-form-group.component";
import { InputPhoneNumberComponent } from "../../common/input-phone-number/input-phone-number.component";
import { Contact } from "../../../../models/contact";

export interface ContactFormGroupControls {
  firstName: FormControl<string | null>;
  lastName: FormControl<string | null>;
  email: FormControl<string | null>;
  phone: FormControl<string | null>;
}

export type ContactFormGroup = FormGroup<ContactFormGroupControls>;

@Component({
  selector: 'app-contact-form-group',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputComponent,
    InputPhoneNumberComponent,
  ],
  templateUrl: './contact-form-group.component.html',
})
export class ContactFormGroupComponent extends ReusableFormGroupComponent<ContactFormGroup> {
  static override defineForm(): ContactFormGroup {
    return new FormGroup<ContactFormGroupControls>({
      firstName: new FormControl<string | null>(null, [Validators.required]),
      lastName: new FormControl<string | null>(null, [Validators.required]),
      email: new FormControl<string | null>(null, [Validators.required, Validators.email]),
      phone: new FormControl<string | null>(null, [Validators.required]),
    });
  }

  static buildContact(contactForm: ContactFormGroup, contact: Partial<Contact> = {}): Contact {
    return new Contact({
      ...contact,
      ...contactForm.value as Partial<Contact>,
    })
  }
}
