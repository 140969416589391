import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Company } from '../../../../../../models/company';
import { Document } from '../../../../../../models/document';
import { EntityChangeData } from '../../../../../../models/entityChangeData';
import { CompanyChangeData } from '../../../../../../models/enums/companyChangeData';
import {
  CompanyChangeHoldingCompanyActionDictionary,
  CompanyChangeHoldingCompanyActionEnum
} from '../../../../../../models/enums/CompanyChangeHoldingCompanyActionEnum';
import { ChangeDictionaryHelper } from '../../../../../../models/shared/change-dictionary-helper.model';
import { A3HoldingCompanyChangeComponent } from './a3-holding-company-change.component';

export class CompanyChangeHoldingCompany extends EntityChangeData {
  static override readonly $type = 'CompanyChangeHoldingCompany';
  holdingCompanyName: string;
  holdingCompanyAcn: string;
  action: CompanyChangeHoldingCompanyActionEnum;
  holdingCompanyPlaceOfIncorporation: string;
  holdingCompanyAbn?: string;

  constructor(data: Partial<CompanyChangeHoldingCompany> = {}) {
    super(data);

    this.holdingCompanyName = data.holdingCompanyName ?? '';
    this.holdingCompanyAcn = data.holdingCompanyAcn ?? '';
    this.action = data.action ?? CompanyChangeHoldingCompanyActionEnum.AP;
    this.holdingCompanyPlaceOfIncorporation = data.holdingCompanyPlaceOfIncorporation ?? '';
    this.holdingCompanyAbn = data.holdingCompanyAbn;
  }

  override toDictionary(): { key: string; value: string; }[] {
    const dict = new ChangeDictionaryHelper();

    dict
      .addDate('Date of Change', this.changeDate)
      .add('Change Type', CompanyChangeHoldingCompanyActionDictionary[this.action.toString()])
      .add('Holding Company Name', this.holdingCompanyName);

    if (this.holdingCompanyPlaceOfIncorporation === 'AU') {
      dict.addAcn('Holding Company ACN', this.holdingCompanyAcn);
    } else if (this.holdingCompanyAbn) {
      dict.addAbn('Holding Company ABN', this.holdingCompanyAbn);
    }

    if (this.action !== CompanyChangeHoldingCompanyActionEnum.NO) {
      dict.addCountry('Place of Incorporation', this.holdingCompanyPlaceOfIncorporation);
    }

    return dict.dictionary;
  }

  override fillModal(modalRef: NgbModalRef, document: Document, actualCompany: Company): NgbModalRef {
    modalRef = this.fillModalWithCompany(modalRef, actualCompany, true);
    (modalRef.componentInstance as A3HoldingCompanyChangeComponent).companyChangeData.documentId = document.documentId;
    return modalRef;
  }

  fillModalWithCompany(modalRef: NgbModalRef, company: Company, isEdit = false): NgbModalRef {
    const instance = modalRef.componentInstance as A3HoldingCompanyChangeComponent;
    const uhcExist = company.ultimateHoldingCompany?.exists || company.ultimateHoldingCompany?.name;

    if (uhcExist) {
      this.holdingCompanyName = company.ultimateHoldingCompany?.name ?? '';
      this.holdingCompanyAcn = company.ultimateHoldingCompany?.acnOrArbn ?? '';
      this.holdingCompanyPlaceOfIncorporation = company.ultimateHoldingCompany?.jurisdiction ?? '';
      this.holdingCompanyAbn = company.ultimateHoldingCompany?.acnOrArbn ?? '';
    }

    instance.isEdit = isEdit;
    instance.companyChangeData = new CompanyChangeData(company);
    instance.formModel = structuredClone(this);

    return modalRef;
  }
}
