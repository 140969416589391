import { Component } from '@angular/core';
import { ICellRendererParams } from "ag-grid-community";
import { SelectComponent } from "../../../select/select.component";
import { SelectOption } from "../../../../../../models/selectOptions";
import { FormsModule } from "@angular/forms";
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from "@ng-bootstrap/ng-bootstrap";
import { NgClass } from "@angular/common";

interface AppliedChanges {
  name: string;
  acn: string;
  entityId: string;
  fullName: string;
  newFullName?: string;
  selectedOption: SelectOption;
  options: SelectOption[]
}

type ActionEvent = (data: unknown) => void;

type CellRendererParams = ICellRendererParams<AppliedChanges> & {
  selectName: ActionEvent,
}

@Component({
  selector: 'app-ag-select-change-name',
  standalone: true,
  imports: [
    SelectComponent,
    FormsModule,
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
    NgClass,
  ],
  templateUrl: './ag-select-change-name.component.html',
  styleUrl: './ag-select-change-name.component.scss'
})
export class AgSelectChangeNameComponent {
  protected params!: CellRendererParams;
  protected fullName = '';
  protected entityId = '';
  protected newFullName = '';
  selectedOption: SelectOption | undefined;
  options: SelectOption[] = [];

  selectedName: unknown = '';
  selectedValue: unknown = '';
  dropdownOpen = false;

  agInit(params: CellRendererParams): void {
    this.params = params;
    this.entityId = params.data?.entityId ?? '';
    this.selectedOption = params.data?.selectedOption;
    this.selectedName = this.selectedOption?.label;
    this.selectedValue = this.selectedOption?.value;
    this.options = params.data?.options ?? [];
    this.newFullName = params.data?.newFullName ?? '';
  }

  refresh(params: CellRendererParams): boolean {
    this.params = params;
    return true;
  }

  get sortedOptions(): SelectOption[] {
    const sorted = this.options
      .filter(option => option.value !== this.selectedOption?.value)
      .sort((a, b) => a.label.localeCompare(b.label));

    if (this.selectedOption?.value) {
      return [this.selectedOption, ...sorted];
    }

    return sorted;
  }

  selectOption(option: SelectOption): void {
    this.selectedName = option.label;
    this.selectedValue = option.value;
    this.selectedOption = { label: option.label, value: option.value };
    this.params.selectName({ entityId: this.entityId, selectedId: this.selectedValue });
  }

  getDropdownState(dropdownState: boolean): void {
    this.dropdownOpen = dropdownState;
  }
}
