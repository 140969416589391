import { Component, Input } from '@angular/core';
import { CorporateHolderModel, IndividualHolderModel } from '../../../../../models/securityRegistryRecord';

@Component({
  selector: 'app-security-holder-icon-label',
  standalone: true,
  imports: [],
  templateUrl: './security-holder-icon-label.component.html',
  styleUrl: './security-holder-icon-label.component.scss'
})
export class SecurityHolderIconLabelComponent {
  @Input() holder!: IndividualHolderModel | CorporateHolderModel;
}
