<app-stepper-form
  header="Notice of Intention to Carry Out a Share Buy-Back"
  subheader="Form 281"
  [showSteps]="false"
  [steps]="steps"
  [currentStep]="currentStep"
  [currentStepIndex]="currentStepIndex"
  [isLoading]="isLoading"
  [form]="stepperForm"
>
  <div class="step-content" [formGroup]="stepperForm">
    <app-stepper-form-description
      formKey="281"
      [hidden]="currentStep !== StepsEnum.FormDescription"
    ></app-stepper-form-description>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.TheChange"
      [hidden]="currentStep !== StepsEnum.TheChange"
    >
      <h1 class="h1-heading">{{ steps[currentStepIndex].label }}</h1>

      <div class="form-group">
        <app-select
          label="Type of share buy-back"
          formControlName="shareBuyBackType"
          [options]="shareBuyBackTypeOptions"
          [valuePrimitive]="true"
        ></app-select>
      </div>

      <div class="form-group" [hidden]="!dateControlsTypes['marketBuyBackFrom'].includes(+theChangeForm.value.shareBuyBackType!)">
        <app-date-picker
          label="From"
          formControlName="marketBuyBackFrom"
          [minDate]="minDate"
          [maxDate]="maxDate"
        ></app-date-picker>
      </div>

      <div class="form-group" [hidden]="!dateControlsTypes['marketBuyBackTo'].includes(+theChangeForm.value.shareBuyBackType!)">
        <app-date-picker
          label="To"
          formControlName="marketBuyBackTo"
          [minDate]="minDate"
          [maxDate]="maxDate"
        ></app-date-picker>
      </div>

      <div class="form-group" [hidden]="theChangeForm.value.shareBuyBackType === null || !dateControlsTypes['proposedDateBuyBackAgreementEnter'].includes(+theChangeForm.value.shareBuyBackType!)">
        <app-date-picker
          label="Proposed date for buy-back agreement to be entered into"
          formControlName="proposedDateBuyBackAgreementEnter"
          [minDate]="minDate"
          [maxDate]="maxDate"
        ></app-date-picker>
      </div>

      <div class="form-group" [hidden]="!dateControlsTypes['proposedDatePassingResolutionBuyBackApprove'].includes(+theChangeForm.value.shareBuyBackType!)">
        <app-date-picker
          label="Proposed date for passing the resolution to approve the buy-back"
          formControlName="proposedDatePassingResolutionBuyBackApprove"
          [minDate]="minDate"
          [maxDate]="maxDate"
        ></app-date-picker>
      </div>
    </section>
  </div>
</app-stepper-form>
