export class CompanyUhcDetails {
    exists: boolean;
    name: string;
    acnOrArbn: string;
    jurisdiction: string;

    constructor(details: Partial<CompanyUhcDetails> = {}) {
        this.exists = details.exists ?? false;
        this.name = details.name ?? '';
        this.acnOrArbn = details.acnOrArbn ?? '';
        this.jurisdiction = details.jurisdiction ?? '';
    }
}