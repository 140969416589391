<div class="card settings-page">
  <app-page-title
    header="Email Templates"
    iconClass="ic-mail"
    backButtonPath="settings"
  >
    <app-search-input
      placeholder="Search"
      [searchDelayTime]="0"
      [formControl]="searchControl"
    ></app-search-input>
  </app-page-title>

  <div class="card-body">
    @if (!isLoading) {
      <app-list-grid
        [rowData]="filteredTemplatesList()"
        [colDefs]="$any(colDefs)"
        [suppressRowClickSelection]="true"
        [pagination]="true"
        [paginationPageSizeSelector]="true"
        [suppressCellFocus]="true"
      ></app-list-grid>
    }

    <app-loader [loading]="isLoading"></app-loader>
  </div>
</div>
