import { NgClass } from '@angular/common';
import { booleanAttribute, Component, computed, EventEmitter, Input, Optional, Output, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, FormControl, FormsModule, NgControl, ReactiveFormsModule } from '@angular/forms';
import {
  NgbDropdown,
  NgbDropdownItem,
  NgbDropdownMenu,
  NgbDropdownToggle,
  NgbTypeahead
} from '@ng-bootstrap/ng-bootstrap';
import { distinctUntilChanged } from 'rxjs';
import { SelectOption, SelectOptionPrimitive } from '../../../../models/selectOptions';
import { FormSwitchButtonComponent } from '../form-switch-button/form-switch-button.component';
import { LinkComponent } from '../link/link.component';
import { ValidationErrorComponent } from '../validation-error/validation-error.component';

@Component({
  selector: 'app-autocomplete',
  standalone: true,
  imports: [
    FormsModule,
    NgbTypeahead,
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
    LinkComponent,
    NgClass,
    FormSwitchButtonComponent,
    ValidationErrorComponent,
    ReactiveFormsModule
  ],
  templateUrl: './autocomplete.component.html',
  styleUrl: './autocomplete.component.scss'
})
export class AutocompleteComponent implements ControlValueAccessor {
  @Input() placeholder = '';
  @Input() label = '';
  @Input() link = '';
  @Input() text = '';
  @Input() helperText = '';
  @Input() isCombobox = false;
  @Input({ required: true }) options: SelectOption[] = [];
  @Input({ transform: booleanAttribute }) disabled = false;
  @Input({ transform: booleanAttribute }) valuePrimitive = false;
  @Input({ transform: booleanAttribute }) allowTypeAnyValue = false;
  @Input() customErrors = {};

  @Output() select = new EventEmitter<{ value: SelectOption, index: number }>();

  searchControl = new FormControl<string>('');
  #value: SelectOption | null = null;
  dropdownOpen = false;

  searchControlValueSignal = signal('');
  filteredOptionsSignal = computed(() => {
    const searchControlValue = this.searchControlValueSignal();
    if (!searchControlValue)
      return this.options;

    const searchValue = (searchControlValue ?? '')?.toLowerCase();
    return this.options
      .filter((option) => option.label && option.label.toLowerCase().includes(searchValue)
      );
  });

  constructor(@Optional() protected ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }

    this.searchControl.valueChanges
      .pipe(distinctUntilChanged(), takeUntilDestroyed())
      .subscribe((value) => {
        this.searchControlValueSignal.set(value ?? '');
        this.value = null;

        if (value) {
          if (this.allowTypeAnyValue) {
            this.value = { label: value, value };
            this.searchControl.patchValue(this.value?.label ?? null);
            this.select.emit({ value: this.value, index: 0 });
            return;
          }

          const index = this.options.findIndex((option) => option.label === value);
          const coincidence = this.options[index];
          this.value = coincidence ?? null;

          if (coincidence)
            this.select.emit({ value: coincidence, index });
        }
      });
  }

  get value(): SelectOption | null {
    return this.#value;
  }

  set value(value: SelectOption | null) {
    if(!value && this.searchControl.value && this.isCombobox) {
      const inputValue = this.searchControl.value;
      this.#value = { label: inputValue, value: inputValue };
      this.onChange(this.valuePrimitive ? this.#value?.value : this.#value);
    } else {
      if(!value) return;
      this.#value = value;
      this.onChange(this.valuePrimitive ? this.#value?.value : this.#value);
    }
  }

  get displayValidationError(): boolean {
    return !!(this.ngControl && this.ngControl.touched && this.ngControl.errors && this.searchControl.touched);
  }

  selectOption(selectedOption: SelectOption, index: number): void {
    this.dropdownOpen = false;
    if (this.searchControl.value !== selectedOption.value) {
      this.searchControl.patchValue(selectedOption.label);
    }
    this.value = selectedOption;
    this.select.emit({ value: selectedOption, index });
  }

  onChange: any = (): void => {
  };
  onTouched: any = (): void => {
  };

  writeValue(value: SelectOption): void {
    if (this.allowTypeAnyValue) {
      this.value = value;
      this.searchControl.patchValue(this.value as unknown as string ?? null);
      return;
    }

    if (this.valuePrimitive) {
      this.value = this.options.find((option) => option.value === value as unknown as SelectOptionPrimitive)!;
    } else {
      this.value = value;
    }

    this.searchControl.patchValue(this.value?.label ?? null);
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
    this.searchControl.registerOnChange(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    isDisabled ? this.searchControl.disable() : this.searchControl.enable();
  }

  onDropdownChange(opened: boolean): void {
    this.searchControlValueSignal.set('');
  }

  protected readonly oninput = oninput;
}
