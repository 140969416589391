export class EmailTemplate {
  emailTemplateId: string;
  organisationId: string;
  code: string;
  subjectTemplate: string;
  bodyTemplate: string;
  footerTemplate: string;

  constructor(data: Partial<EmailTemplate> = {}) {
    this.emailTemplateId = data.emailTemplateId ?? '';
    this.organisationId = data.organisationId ?? '';
    this.code = data.code ?? '';
    this.subjectTemplate = data.subjectTemplate ?? '';
    this.bodyTemplate = data.bodyTemplate ?? '';
    this.footerTemplate = data.footerTemplate ?? '';
  }
}
