import { Component } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { MinutesMeetingPresence } from "../../../../../../models/enums/documentConfirmEnums";
import { ICellRendererParams } from "ag-grid-community";
import { CompanyStatusPipe } from "../../../../../../pipes/enumsPipes/companyStatusPipe";
import { LowerCasePipe, NgClass } from "@angular/common";
import { MinutesMeetingPresencePipe } from "../../../../../../pipes/enumsPipes/minutesMeetingPresencePipe";
import { SelectComponent } from "../../../select/select.component";
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from "@ng-bootstrap/ng-bootstrap";
import { Attendee } from "../../../../../../models/generateDocuments";
import { DocumentStatusEnum } from "../../../../../../models/enums/documentStatusEnum";

type ActionEvent = (data: Attendee | undefined) => void;

type CellRendererParams = ICellRendererParams<Attendee> & {
  onSelectDirectorMeetingStatus: ActionEvent,
};

@Component({
  selector: 'app-ag-director-meeting-status',
  standalone: true,
  imports: [
    CompanyStatusPipe,
    NgClass,
    MinutesMeetingPresencePipe,
    SelectComponent,
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
    LowerCasePipe
  ],
  templateUrl: './ag-director-meeting-status.component.html',
  styleUrl: './ag-director-meeting-status.component.scss'
})
export class AgDirectorMeetingStatusComponent implements ICellRendererAngularComp {
  protected params!: CellRendererParams;
  status: MinutesMeetingPresence | undefined;
  MinutesMeetingPresence = MinutesMeetingPresence;
  selectOptions = Object.values(MinutesMeetingPresence)
    .map(v => v)
    .filter(item => typeof item === 'number') as number[];

  agInit(params: CellRendererParams): void {
    this.params = params;
    this.status = params.value as MinutesMeetingPresence | undefined;
  }

  refresh(params: CellRendererParams): boolean {
    this.params = params;
    this.status = params.value as MinutesMeetingPresence | undefined;
    return true;
  }

  onSelectDirectorMeetingStatus(status: MinutesMeetingPresence | undefined): void {
    if (this.params.data) {
      this.params.data.presence = status;
      this.status = status;
      this.params.onSelectDirectorMeetingStatus(this.params.data);
    }
  }

  protected readonly DocumentStatusEnum = DocumentStatusEnum;
}
