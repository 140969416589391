import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { ButtonComponent } from "../components/common/button/button.component";
import { DividerComponent } from "../components/common/divider/divider.component";
import { TextareaComponent } from "../components/common/textarea/textarea.component";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { CheckboxComponent } from "../components/common/checkbox/checkbox.component";
import { GridComponent } from "../components/common/grid/components/grid/grid.component";
import { ColDef, GridApi } from "ag-grid-community";
import { ActivatedRoute, Router } from "@angular/router";
import { tap } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ListGridComponent } from "../components/common/grid/components/list-grid/list-grid.component";
import { DocumentRecord } from "../../models/documentRecord";
import { BulkSignRow } from "../../models/signDocuments";
import {
  AgAnnualSignEditEmailComponent
} from "../components/common/grid/components/annual/ag-annual-sign-edit-email/ag-annual-sign-edit-email.component";
import {
  AgAnnualSignEditPhoneComponent
} from "../components/common/grid/components/annual/ag-annual-sign-edit-phone/ag-annual-sign-edit-phone.component";
import {
  AgAnnualDocumentsForSignComponent
} from "../components/common/grid/components/annual/ag-annual-documents-for-sign/ag-annual-documents-for-sign.component";

export interface BulkSendDocumentsConfirmControls {
  message: FormControl<string | null>,
  confirm: FormControl<boolean | null>,
}

@Component({
  selector: 'app-bulk-send-documents-for-signing',
  standalone: true,
  imports: [
    ButtonComponent,
    DividerComponent,
    TextareaComponent,
    ReactiveFormsModule,
    CheckboxComponent,
    GridComponent,
    ListGridComponent
  ],
  templateUrl: './bulk-send-documents-for-signing.component.html',
  styleUrl: './bulk-send-documents-for-signing.component.scss'
})
export class BulkSendDocumentsForSigningComponent implements OnInit {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private destroyRef = inject(DestroyRef);

  readonly pageTitle = 'Bulk Send Documents for Signing';
  readonly pageSubTitle = 'Please enter the signatories contact details';
  readonly confirmText = 'I have read and understood the above disclaimer and I confirm that all reasonable steps' +
    ' have been taken to satisfy the requirements of any relevant laws in relation to the of the E-Signing' +
    ' Functionality fir the relevant document and I proceed on this basis.';
  readonly rowsNumber = 4;
  readonly maxMessageLen = 10;
  readonly phoneMinLen = 6;

  readonly defaultPageSize = 20;
  loading = false;
  invalidData = false;
  pageSizeList = [20, 50];
  documentsList: Document[] = [];
  colDefs: ColDef[] = [];
  gridApi!: GridApi;

  form = new FormGroup<BulkSendDocumentsConfirmControls>({
    message: new FormControl<string | null>(null, Validators.maxLength(this.maxMessageLen)),
    confirm: new FormControl<boolean | null>(null, Validators.requiredTrue),
  });

  rows: BulkSignRow[] = [
    { fullName: 'Luna Blackwood', email: 'luna.blackwood@innovatechsolutions.com', phone: '61400882983', documentsForSign: [{ name: 'smith-tech-pty-ltd-minutes-for-cease=of-officeholder.pdf' }, { name: 'smith-tech-pty-ltd-minutes-for-cease=of-officeholder.pdf' }] },
    { fullName: 'Aurora Blackwood', email: 'aurora.blackwood@innovatechsolutions.com', phone: '61400880092', documentsForSign: [{ name: 'smith-tech-pty-ltd-form484-a1.pdf' }] },
    { fullName: 'Dominic Hawthorne', email: 'dominic.hawthorne@innovatechsolutions.com ', phone: '61400883467', documentsForSign: [{ name: 'nexus-dynamics-pty-ltd-form484.pdf' }, { name: 'nexus-dynamics-pty-ltd-form484-a1.pdf' }, { name: 'nexus-dynamics-pty-ltd-form484-a1.pdf' }] }
  ];

  ngOnInit(): void {
    this.route.queryParams.pipe(
      tap((params: { documents?: string }) => {
        if(params?.documents) {
          const documents = JSON.parse(params.documents) as DocumentRecord[];
        }
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();

    this.setGridConfig();
  }

  onGridReady(gridApi: GridApi): void {
    this.gridApi = gridApi;
  }

  public setGridConfig(): void {
    this.colDefs = [
      {
        headerName: 'Name',
        field: 'fullName',
        flex: 1,
        menuTabs: [],
      },
      {
        headerName: 'Email',
        field: 'email',
        flex: 1,
        cellRenderer: AgAnnualSignEditEmailComponent,
        cellRendererParams: {
          edit: this.edit.bind(this),
        },
        menuTabs: [],
      },
      {
        headerName: 'Phone',
        field: 'phone',
        flex: 1,
        cellRenderer: AgAnnualSignEditPhoneComponent,
        cellRendererParams: {
          edit: this.edit.bind(this),
        },
        menuTabs: [],
      },
      {
        headerName: 'Documents For Signing',
        field: 'documentsForSign',
        flex: 2,
        editable: false,
        cellRenderer: AgAnnualDocumentsForSignComponent,
        menuTabs: [],
      },
    ];
  }

  edit(data: { value: string, index: number; column: string }): void {
    let emailIsRequired = false;
    let invalidPhone = false;

    if(data.column === 'email') {
      this.rows[data.index].email = data.value;
      emailIsRequired = !this.rows?.every(item => item.email);
    } else if(data.column === 'phone') {
      this.rows[data.index].phone = data.value;
      invalidPhone = this.rows.filter(row => row.phone)?.some(item => item.phone.length < this.phoneMinLen);
    }

    this.invalidData = emailIsRequired || invalidPhone;
  }

  backToDocuments(): void {
    void this.router.navigate(['/documents']);
  }

  bulkSendForSign(): void {
  }
}
