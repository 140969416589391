export class AnnatureRegisterAccount{
    name: string;
    email: string;
    business: string;
    number: string | null;
    timezone: string | null;
    password: string | null;
  
    constructor(data: Partial<AnnatureRegisterAccount> = {}) {
      this.name = data.name || "";
      this.email = data.email || "";
      this.business = data.business || "";
      this.number = data.number || null;
      this.timezone = data.timezone || null;
      this.password = data.password || null;
    }
  }