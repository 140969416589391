import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  Form362AppointCeaseAgentComponent
} from '../app/modals/documents/asic-forms/ra362-appoint-cease-agent/form362-appoint-cease-agent.component';
import { Company } from './company';
import { Document } from './document';
import { EntityChangeData } from './entityChangeData';
import { CompanyChangeData } from './enums/companyChangeData';
import { ChangeDictionaryHelper } from './shared/change-dictionary-helper.model';

export enum Form362Type {
  Appointment = 0,
  Cessation = 1
}

export class CompanyAppointChange extends EntityChangeData {
  static override readonly $type = 'CompanyForm362';
  companyACN: string;
  companyName: string;
  actionType: Form362Type;

  constructor(data: Partial<CompanyAppointChange> = {}) {
    super(data);
    this.companyACN = data.companyACN ?? '';
    this.companyName = data.companyName ?? '';
    this.actionType = Form362Type.Appointment;
  }

  override toDictionary(): { key: string; value: string; }[] {
    const dict = new ChangeDictionaryHelper();

    dict
      .add('Company Name', this.companyName)
      .addAcn('ACN', this.companyACN);

    return dict.dictionary;
  }

  override fillModal(modalRef: NgbModalRef, document: Document, actualCompany = new Company()): NgbModalRef {
    modalRef = this.fillModalWithCompany(modalRef, actualCompany, true);
    (modalRef.componentInstance as Form362AppointCeaseAgentComponent).companyChangeData.documentId = document.documentId;
    return modalRef;
  }

  fillModalWithCompany(modalRef: NgbModalRef, company: Company, isEdit: boolean): NgbModalRef {
    const instance = modalRef.componentInstance as Form362AppointCeaseAgentComponent;

    instance.isEdit = isEdit;
    instance.companyChangeData = new CompanyChangeData(company);
    instance.formModel = this;
    instance.address = company.registeredAddress ?? company.principalAddress;

    return modalRef;
  }

  override prepareToRequest(): CompanyAppointChange {
    return {
      ...this,
      ...super.prepareToRequest(),
    };
  }
}
