import { Component } from '@angular/core';
import { ICellRendererParams } from "ag-grid-community";
import { KeyValuePipe, NgClass } from "@angular/common";
import { CorporateHolderModel, HolderModelBase, IndividualHolderModel, SecurityRegistryRecord } from '../../../../../../models/securityRegistryRecord';
import { SpanWithOpenIconComponent } from '../../../span-with-open-icon/span-with-open-icon.component';
import { DefaulValuePipe } from "../../../../../../pipes/enumsPipes/defaultValuePipe";

@Component({
    selector: 'app-ag-holder-beneficial-owner',
    standalone: true,
    templateUrl: './ag-holder-beneficial-owner.component.html',
    styleUrl: './ag-holder-beneficial-owner.component.scss',
    imports: [
        NgClass,
        KeyValuePipe,
        SpanWithOpenIconComponent,
        DefaulValuePipe
    ]
})
export class AgHolderBeneficialOwnerComponent {
  holderGroups: Record<string, HolderModelBase[]> = {};

  CorporateHolderModel = CorporateHolderModel;
  IndividualHolderModel = IndividualHolderModel;

  agInit(params: ICellRendererParams): void {
    this.holderGroups = (params.data as SecurityRegistryRecord).beneficialOwnerGroups;
  }

  refresh(params: ICellRendererParams): boolean {
    this.holderGroups = (params.data as SecurityRegistryRecord).beneficialOwnerGroups;
    return true;
  }

}
